export const WIDGET_FILTER_TYPES = {
  'normal-filter': 'Standard (No Coverage)',
  'user-filter': 'User Coverage',
  'company-filter': 'Company',
  'assigned-to-self': 'Assigned to User',
  'user-and-assigned-to-self': 'User Coverage and Assigned To User',
  'company-and-assigned': 'Company & Assigned',
};

export const INVENTORY_ADJUSTMENT_PROJECT_TABLE = 'Project';
export const INVENTORY_ADJUSTMENT_TYPE_PROJECT = 'Project.adjustment_type';
export const INVENTORY_ADJUSTMENT_TYPE = 'InventoryAdjustment.adjustment_type';
export const INVENTORY_ADJUSTMENT_PROJECT_OBJECT_ID =
  'Project.adjustment_object_id';
export const INVENTORY_ADJUSTMENT_TABLE = 'InventoryAdjustment';
export const INVENTORY_ADJUSTMENT_OBJECT_ID =
  'InventoryAdjustment.adjustment_object_id';
export const CHARGEBACK_KEY = 'Chargebacks';
export const PROJECT_KEY = 'project';

export const INVENTORY_ADJUSTMENT_TYPES = {
  DISPATCHED: 'Dispatched',
  ALLOCATED: 'Allocated',
  ON_ORDER: 'OnOrder',
};

export const CUBE_LABELS = {
  USER_DETAILS: 'User Details',
  USER_META: 'UserMeta',
};

export const ORDER_BY = [
  { value: 'ASC', name: 'asc' },
  { value: 'DESC', name: 'desc' },
];

export const DASHBOARD_RETRY_LIMIT = 2;
