import { URL_CONSTANTS } from '../../../../constants/urlConstants';
import ApiService from '../../../../services/api.service';

export const routeAvailability = async (userId, payload) => {
  try {
    if (userId) {
      const routingAvailability = await ApiService.patch(
        `/auth/user/manage/routing-preferences/${userId}`,
        payload
      );
      return routingAvailability;
    }
  } catch (error) {
    console.error(error);
  }
};
export const getServiceTime = async (userId, paginator, type, category) => {
  try {
    let baseListURL = `/auth/user/manage/service-time/${userId}/${paginator.first}/${paginator.rows}`;
    let queryParams = [];
    if (type) {
      queryParams.push(`typeId=${type?.project_type_id}`);
    }
    if (category && category.length > 0) {
      const categoryIds = category
        .map(cat => cat.project_category_id)
        .join(',');
      queryParams.push(`categoryId=${categoryIds}`);
    }
    if (queryParams.length > 0) {
      baseListURL += '?' + queryParams.join('&');
    }
    const getData = await ApiService.get(baseListURL);
    return getData;
  } catch (error) {
    console.error(error);
  }
};
export const UpdateServiceTime = async (userId, recordId, payload) => {
  try {
    if (userId) {
      const getServiceTimeResponce = await ApiService.patch(
        `/auth/user/manage/service-time/${userId}/${recordId}`,
        payload
      );
      return getServiceTimeResponce;
    }
  } catch (error) {
    console.error(error);
  }
};
export const bulkUpdateServiceTime = async (userId, payload) => {
  try {
    if (userId) {
      const getServiceTimeResponce = await ApiService.put(
        `/auth/user/manage/service-time/${userId}/bulk-update`,
        payload
      );
      return getServiceTimeResponce;
    }
  } catch (error) {
    console.error(error);
  }
};
export const deleteServiceTime = async (userId, payload) => {
  try {
    if (userId) {
      const deleteServiceTimeRow = await ApiService.delete(
        `/auth/user/manage/service-time/${userId}/${payload}`
      );
      return deleteServiceTimeRow;
    }
  } catch (error) {
    console.error(error);
  }
};
export const getCapacity = async userId => {
  try {
    if (userId) {
      const getServiceTimeResponce = await ApiService.get(
        `/auth/user/manage/routing-preferences/${userId}`
      );
      return getServiceTimeResponce;
    }
  } catch (error) {
    console.error(error);
  }
};
export const editCapacity = async (userId, payload) => {
  try {
    if (userId) {
      const getServiceTimeResponce = await ApiService.patch(
        `/auth/user/manage/routing-preferences/${userId}`,
        { routing_preference: payload }
      );
      return getServiceTimeResponce;
    }
  } catch (error) {
    console.error(error);
  }
};
export const getJobCoverage = async (
  userId,
  paginator,
  type,
  category,
  coverage_type,
  coverage_value
) => {
  try {
    if (userId) {
      let baseListURL = `/auth/user/manage/job-coverage/${userId}`;
      let queryParams = [];
      if (paginator?.first && paginator?.rows) {
        queryParams.push(`limit=${paginator?.rows}&offset=${paginator?.first}`);
      }
      if (type) {
        queryParams.push(`&type=${type?.project_type_id}`);
      }
      if (category && category.length > 0) {
        const categoryIds = category
          .map(cat => cat.project_category_id)
          .join(',');
        queryParams.push(`&category=${categoryIds}`);
      }
      if (coverage_type) {
        queryParams.push(`&coverage_type=${coverage_type?.id}`);
      }
      if (coverage_value?.length) {
        queryParams.push(`&coverage_value=${coverage_value}`);
      }
      if (queryParams.length > 0) {
        paginator.first = 0;
        baseListURL += '?' + queryParams;
      }
      const getData = await ApiService.getWithDiffBaseUrl(baseListURL);
      const userCoveragePreference = getData?.data?.queryResponse?.map(item => {
        return {
          type_coverage_id: item.project_type_id,
          category_coverage_ids: item.project_category_ids,
          coverage: {
            type: item.coverage_by,
            values:
              item.coverage_by == 1
                ? item.zipcode_or_store_ids?.split(',')
                : item.zipcode_or_store_ids,
          },
          rotation_percentage: item?.rotation_percentage,
          service_time: item?.service_time,
          user_job_coverage_id: item?.user_job_coverage_id,
          user_id: item.user_id,
        };
      });
      const userCoveragePreferenceData = getData?.data?.queryResponse;
      return {
        userCoveragePreference: userCoveragePreference,
        totalCount: getData?.data?.count,
        userCoveragePreferenceData: userCoveragePreferenceData,
      };
    }
  } catch (error) {
    console.error(error);
  }
};
export const addAndCloneJobCoverage = async (userId, payload) => {
  try {
    if (userId) {
      const getData = await ApiService.post(
        `/auth/user/manage/job-coverage/${userId}`,
        payload
      );
      return getData;
    }
  } catch (error) {
    console.error(error);
  }
};
export const editJobCoverage = async (userId, payload) => {
  try {
    if (userId) {
      const getData = await ApiService.put(
        `/auth/user/manage/job-coverage/${userId}`,
        payload
      );
      return getData;
    }
  } catch (error) {
    console.error(error);
  }
};
export const deleteJobCoverage = async (userId, recordId) => {
  try {
    const response = await ApiService.delete(
      `/auth/user/manage/job-coverage/${userId}/delete/${recordId}`
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};
export const bulkDelete = async (userId, payload) => {
  try {
    const response = await ApiService.delete(
      `/auth/user/manage/job-coverage/${userId}/group-delete?preferenceIds=${payload.join(',')}`
    );
    return response.status;
  } catch (error) {
    console.error(error);
  }
};
export const groupCloneJobCoverage = async (userId, payload) => {
  try {
    const response = await ApiService.post(
      `/auth/user/manage/job-coverage/${userId}/group-clone`,
      payload
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};
export const copyCoveragePreference = async (userId, payload) => {
  try {
    const response = await ApiService.put(
      `/auth/user/manage/job-coverage/copy-access-coverage/${userId}`,
      { isCopyCoverage: payload }
    );
    return response.status;
  } catch (error) {
    console.error(error);
  }
};
export const getRotationPercentList = async (
  userId,
  first,
  rows,
  type,
  category,
  userList
) => {
  try {
    if (userId) {
      let baseListURL = `/auth/user/manage/rotation-percent-detail-list/${userId}/${first}/${rows}`;
      let queryParams = [];
      if (type && type?.project_type_id) {
        queryParams.push(`typeId=${type?.project_type_id}`);
      }
      if (category && category.length > 0) {
        const ids = category.map(cat => cat?.project_category_id).join(',');
        queryParams.push(`categoryId=${ids}`);
      }
      if (userList) {
        queryParams.push(`userId=${userList}`);
      }
      if (queryParams.length > 0) {
        baseListURL += '?' + queryParams.join('&');
      }
      const getData = await ApiService.get(baseListURL);
      return getData?.data;
    }
  } catch (error) {
    console.error(error);
  }
};
export const getInstallersList = async user => {
  try {
    const userList = await ApiService.get(
      `/auth/user/manage/rotation-percentage/${user}/user-list`
    );
    return userList;
  } catch (error) {
    console.error(error);
  }
};
export const deleteRotationPercentage = async (userId, coverageId) => {
  try {
    const response = await ApiService.delete(
      `/auth/user/manage/rotation-percent/${userId}/${coverageId}`
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};
export const deleteGroupRotationPercentage = async (userId, preferenceIds) => {
  try {
    const response = await ApiService.patch(
      `/auth/user/manage/group-rotation-percent/${userId}?preferenceIds=[${preferenceIds}]`
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};
export const udpateRotationPercentage = async (
  userId,
  preferenceIds,
  payload
) => {
  try {
    const response = await ApiService.put(
      `/auth/user/manage/rotation-percent/${userId}/${preferenceIds}`,
      payload
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};
export const getListByJobCoverageTypeId = async (userId, typeId) => {
  try {
    const response = await ApiService.get(
      `/auth/user/manage/job-coverage-list/${userId}/${typeId}`
    );
    const getAllJobCoverageById = response?.data?.queryResponse?.map(item => {
      return {
        type_coverage_id: item.project_type_id,
        category_coverage_ids: item.project_category_ids,
        coverage: {
          type: item.coverage_by,
          values:
            item.coverage_by == 1
              ? item.zipcode_or_store_ids?.split(',')
              : item.zipcode_or_store_ids,
        },
        rotation_percentage: item?.rotation_percentage,
        service_time: item?.service_time,
        user_job_coverage_id: item?.user_job_coverage_id,
        user_id: item.user_id,
      };
    });
    return {
      getAllJobCoverageById: getAllJobCoverageById,
    };
  } catch (error) {
    console.error(error);
  }
};
export const getFilteredJobCoverageList = async (userId, type) => {
  try {
    const jobCoverageList = ApiService.get(
      `/auth/user/manage/job-coverage-list/${userId}/${type}`
    );
    return jobCoverageList;
  } catch (error) {
    console.error(error);
  }
};
//Delete All Job Coverage
export const deleteAllJobCoverage = async (userId, payload) => {
  try {
    if (userId) {
      const response = await ApiService.delete(
        `/auth/user/manage/job-coverage/${userId}/delete-all`
      );
      return response;
    }
  } catch (error) {
    console.error(error);
  }
};
export const getUserJobCoverageValueWithSlotsCount = async (
  userId,
  userJobCoverageId,
  coverageValue
) => {
  const response = await ApiService.postWithDiffBaseUrl(
    ApiService.formatUrl(
      URL_CONSTANTS.USER_MANAGEMENT.endPoints.userJobCoverageSlotsCount,
      userId,
      userJobCoverageId
    ),
    {
      coverage_value: coverageValue,
    },
    URL_CONSTANTS.API.BASE_URL
  );
  return response;
};
export const userJobCoverageSlots = async (userId, params) => {
  return await ApiService.getWithDiffBaseUrl(
    ApiService.formatUrl(
      URL_CONSTANTS.USER_MANAGEMENT.endPoints.userJobCoverageSlots,
      userId
    ),
    params,
    URL_CONSTANTS.API.BASE_URL
  );
};
export const userJobCoverageSlotsByDay = async (
  userId,
  userJobCoverageId,
  coverageValue,
  day
) => {
  return await ApiService.getWithDiffBaseUrl(
    ApiService.formatUrl(
      URL_CONSTANTS.USER_MANAGEMENT.endPoints.userJobCoverageSlotsByDay,
      userId,
      userJobCoverageId,
      coverageValue,
      day
    ),
    null,
    URL_CONSTANTS.API.BASE_URL
  );
};
export const addUserJobCoverageSlotInDay = async (
  userId,
  userJobCoverageId,
  coverageValue,
  day,
  payload
) => {
  return await ApiService.postWithDiffBaseUrl(
    ApiService.formatUrl(
      URL_CONSTANTS.USER_MANAGEMENT.endPoints.addUserJobCoverageSlotInDay,
      userId,
      userJobCoverageId,
      coverageValue,
      day
    ),
    payload,
    URL_CONSTANTS.API.BASE_URL
  );
};
export const updateUserJobCoverageSlotBySlotId = async (
  userId,
  slotId,
  payload
) => {
  return await ApiService.putWithDiffBaseUrl(
    ApiService.formatUrl(
      URL_CONSTANTS.USER_MANAGEMENT.endPoints.updateUserJobCoverageSlotBySlotId,
      userId,
      slotId
    ),
    payload,
    URL_CONSTANTS.API.BASE_URL
  );
};
export const deleteUserJobCoverageSlotBySlotId = async (userId, slotId) => {
  return await ApiService.deleteWithDiffBaseUrl(
    ApiService.formatUrl(
      URL_CONSTANTS.USER_MANAGEMENT.endPoints.deleteUserJobCoverageSlotBySlotId,
      userId,
      slotId
    ),
    null,
    URL_CONSTANTS.API.BASE_URL
  );
};
export const mergeUserJobCoverageSlotByDay = async (
  userId,
  userJobCoverageId,
  coverageValue,
  day
) => {
  return await ApiService.postWithDiffBaseUrl(
    ApiService.formatUrl(
      URL_CONSTANTS.USER_MANAGEMENT.endPoints.mergeUserJobCoverageSlotByDay,
      userId,
      userJobCoverageId,
      coverageValue,
      day
    ),
    null,
    URL_CONSTANTS.API.BASE_URL
  );
};
