import axios from 'axios';

import { CONFIG } from '../../../../constants';
import { toTitleCase } from '../../../../utils/Helpers';
import apiService from '../../../../services/api.service';

export const formateNumber = value =>
  Math.round(Number(parseFloat(isNaN(value || 0) ? 0 : value || 0)) * 100) /
  100; /* Number formatting with rounding */
export const quotesBreadcrumb = (
  customerId,
  opportunityId,
  opportunityNumber = undefined,
  customerName,
  quoteNumber = undefined,
  quoteId = undefined
) => {
  return [
    {
      text: 'Clients',
      link: `/project/clients`,
    },
    {
      text: toTitleCase(customerName),
      link: `/crm/view/customer/${customerId}`,
    },
    {
      text: 'Lead',
      link: `/crm/view/customer/${customerId}`,
    },
    ...(opportunityNumber
      ? [
          {
            text: `${opportunityNumber}`,
            link: `/crm/${customerId}/lead/view/${opportunityId}`,
          },
        ]
      : []),
    {
      text: 'Quotes',
      link: `/crm/${customerId}/quotes/${opportunityId}`,
    },
    ...(quoteNumber
      ? [
          {
            text: `${quoteNumber}`,
          },
        ]
      : []),
  ];
};

export const clientInformation = () => {
  let main = [
    {
      field: 'client_information',
      header: 'Client Information',
      body: (rowData, option) => <div>Hi</div>,
    },
  ];

  return main;
};

export const createQuote = async payload => {
  let url = `${CONFIG?.API_CRM_URL}/api/${payload?.customerId}/${payload?.opportunityId}/quote/create `;
  return await axios.post(url, payload);
};

export const getQuoteById = async (customerId, opportunityId, id) => {
  let url = `${CONFIG?.API_CRM_URL}/api/${customerId}/${opportunityId}/quote/${id} `;
  return await axios.get(url);
};

export const updateQuote = async (payload, quoteId) => {
  let url = `${CONFIG?.API_CRM_URL}/api/${payload?.customerId}/${payload?.opportunityId}/quote/${quoteId} `;
  return await axios.put(url, payload);
};

export const previewQuote = async (
  customerId,
  opportunityId,
  quoteId,
  type
) => {
  let url = `${CONFIG?.API_CRM_URL}/api/${customerId}/${opportunityId}/quote/preview/${quoteId}?type=${type}`;
  return await axios.get(url);
};

export const sendQuote = async (customerId, opportunityId, quoteId, type) => {
  let url = `${CONFIG?.API_CRM_URL}/api/${customerId}/${opportunityId}/quote/send/${quoteId}?type=${type}`;
  return await axios.get(url);
};

export const downloadQuote = async (
  customerId,
  opportunityId,
  quoteId,
  type
) => {
  let url = `${CONFIG?.API_CRM_URL}/api/${customerId}/${opportunityId}/quote/download/${quoteId}?type=${type}`;
  return await axios.get(url);
};

export const putTurnOnTime = async (leadId, quoteId, projectId) => {
  return await apiService.getWithDiffBaseUrl(
    `/api/update-turn-time`,
    {
      leadId,
      quoteId,
      projectId,
    },
    CONFIG?.API_CRM_URL
  );
};
