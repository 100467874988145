import React, { useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment-timezone';
import {
  Typography,
  Grid,
  CircularProgress,
  Tooltip,
  Modal,
  Button,
  TextField,
  DialogContentText,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import rrulePlugin from '@fullcalendar/rrule';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import momentPlugin from '@fullcalendar/moment';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Autocomplete } from '@material-ui/lab';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Calendar } from 'primereact/calendar';
import { Toast } from 'primereact/toast';
import { Image } from 'primereact/image';

import GenericConfirmationDialog from '../shared/GenericConfirmationDialog/GenericConfirmationDialog';
import { useAlerts } from '../shared/Alerts/alertsService';
import {
  TEXT_COLOR_BLACK,
  scheduleInfoUserSkipAction,
  scheduleInfoUserWipeAction,
  scheduleInfoUserFillAction,
  scheduleInfoUserAction,
  scheduleInfoUserCustomAction,
  CX_SCHEDULED_STATUS,
  CLIENT_DETAILS,
} from '../../constants';
import { setInstallerViewCalendar } from '../../redux/slices/installer-view-calendar.slice';
import { addNotes } from '../ProjectManagement/Notes/Notes.service';
import GenericDialog from '../shared/Dialog/GenericDialog';
import {
  checkPermission,
  ellipsisString,
  getDefaultTechnicianSchedulerView,
  getPercentageValue,
  getUniqueArrayItemsByKey,
  selectedRapidSlot,
  getPeriod,
} from '../../utils/Helpers';
import permissions from '../../config/permissions';
import { getSystemTenants } from '../Admin/Configuration/RestrictAccessbyRole.service';
import PFInputNumber from '../shared/PFPrime/PFInputNumber';
import {
  firstRowData,
  SchedulerSkeleton,
  secondRowData,
} from '../shared/Loader/schedulerSkeleton';

import InstallerViewBlockCalendar from './installer-view-block-calendar';
import { useStyles } from './CalenderContainer.styles';
import { getAllInstallersList } from './new-scheduler/service/new-scheduler.service';
import {
  distanceInMileage,
  getCalendarData,
  updateInstallerSchedule,
} from './Scheduler.service';
import ProjectScheduleCard from './ProjectScheduleCard';
import { EVENT_TYPES, EVENT_COLORS } from './constants/constants';
import TechnicianAvatar from './TechnicianAvatar';
import JobDistanceOverlay from './JobDistanceOverlay';
import { mapProjectTypeToColor } from './helpers/helpers';

const CalenderContainer = ({
  viewEditProjectDetails,
  calendarFilterData,
  setCalendarFilterData,
  setReloadList,
  projectDataLoading,
  reloadCalender,
  setSelected,
  reportData,
  statusOptions,
  filterCalenderData,
  setFilterCalenderData,
  calendarDataResetData,
  schedulerLoader,
  setInitialLoader,
  initialLoader,
  resetInstallerFormik,
  view,
  calendarFullScreen,
  setCalendarFullScreen,
  setChildGetParms,
  datePeriodForCalenderView,
  setDatePeriodForCalenderView,
  isScheduler,
  globalFilters,
  selectedRow,
}) => {
  const classes = useStyles();
  const calendarRef = useRef();
  const [calendarData, setCalendarData] = useState([]);
  const [originalCalendarData, setOriginalCalendarData] = useState([]);
  const [reloadCalendar, setReloadCalendar] = useState(false);
  const [calendarLoading, setCalendarLoading] = useState(false);
  const [, setEventLoading] = useState(false);
  const { setAlert } = useAlerts();
  const [infoData, setInfoData] = useState();
  const [dropTimeOffRequestIds, setDropTimeOffRequestIds] = useState();
  const [confirmDialog, setConfirmDialog] = useState({
    header: '',
    title: '',
    subtitle: '',
  });
  const [calenderParams, setCalenderParams] = useState(null);
  const [dropModalValue, setDropModalValue] = useState(false);
  const [tableRowDropData, setTableRowDropData] = useState('');
  const [dropData, setDropData] = useState('');
  const [statusId, setStatusId] = useState(7);
  let calendarApi;
  const dispatch = useDispatch();
  const [dialogSettings, setDialogSettings] = useState({
    title: 'Reschedule Confirmation',
    button1Text: 'Skip',
    button2Text: 'Continue',
    showButton1: true,
    showButton2: true,
  });
  const [isOpen, setIsOpen] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [startDateStr, setStartDateStr] = useState('');
  const [endDateStr, setEndDateStr] = useState('');
  const [infoValue, setInfoValue] = useState({});
  const [isUpdateSchedule, setIsUpdateSchedule] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isStartOrEndDateInvalid, setIsStartOrEndDateInvalid] = useState(false);
  const [technicianAction, setTechnicianAction] = useState(null);

  const [cxSchedulerIcon, setCxSchedulerIcon] = useState(null);
  const [isBlockTime, setIsBlockTime] = useState(false);
  const [schedulePercentage, setSchedulePercentage] = useState(0);
  const [schedulerCardView, setSchedulerCardView] = useState(null);
  const toast = useRef(null);

  const selectedViewMore = useSelector(
    state => state.viewMoreEventDropdown.selectedViewMore
  );

  useEffect(() => {
    const clientDetails = localStorage.getItem('client_details');
    if (clientDetails) {
      try {
        const Config = JSON.parse(clientDetails);
        if (Config?.cx_scheduling_module && Config?.job_identifier)
          setCxSchedulerIcon(Config?.job_identifier);
        if (
          Config?.scheduler_config.calender_view &&
          Config?.scheduler_config.calender_view?.card_visibility
        ) {
          setSchedulerCardView(
            Config?.scheduler_config.calender_view?.card_visibility
          );
        }
      } catch (ex) {
        /* empty */
      }
    }
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      const timeDiff = endDate.getTime() - startDate.getTime();
      if (timeDiff <= 0) {
        setIsStartOrEndDateInvalid(true);
      } else {
        setIsStartOrEndDateInvalid(false);
      }
    } else {
      setIsStartOrEndDateInvalid(false);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    try {
      getSystemTenants().then(clientConfiguration => {
        if (clientConfiguration) {
          const { included_permissions, allocation_percentage } =
            clientConfiguration.tenant_permissions[0];

          const allocateItemKeys = Object.keys(scheduleInfoUserAction);
          const alreadyAllocatePermission = included_permissions?.filter(perm =>
            allocateItemKeys.includes(perm)
          );
          setTechnicianAction(
            scheduleInfoUserAction[alreadyAllocatePermission?.[0]]
          );
          setSchedulePercentage(allocation_percentage);
        }
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    if (!schedulerLoader) {
      let draggableEl = document.getElementsByClassName('MuiTableBody-root');
      new Draggable(draggableEl[0], {
        itemSelector: '.MuiTableRow-root',
        eventData: function (eventEl) {
          return {
            title: eventEl.getAttribute('data-description'),
            id: eventEl.getAttribute('data-event'),
            create: false,
          };
        },
      });
    }
  }, [schedulerLoader]);

  useEffect(async () => {
    if (filterCalenderData || calendarFilterData) {
      let params = {
        date: calendarApi
          ? moment(calendarApi?.getDate()).format()
          : moment().format(),
        period: calendarApi
          ? getPeriod(calendarApi?.view?.type)
          : getPeriod(datePeriodForCalenderView),
      };
      if (calendarFilterData) {
        params.projectTypeIds = calendarFilterData?.project_type_id || null;
        params.projectCategoryId =
          calendarFilterData?.project_category_id || null;
        params.projectStoreId = calendarFilterData?.project_store_id || null;
        params.workRoomIds = calendarFilterData?.workRoomIds || null;
        params.districts = calendarFilterData?.districts?.length
          ? calendarFilterData?.districts
          : null;
        if (
          calendarFilterData?.project_technician_id ||
          globalFilters?.techniciansVal.length > 0
        ) {
          params.installerIds =
            globalFilters?.techniciansVal.length > 0
              ? globalFilters?.techniciansVal?.map(val => val?.user_id)
              : calendarFilterData?.project_technician_id;
        }
        if (calendarFilterData?.isJobCoverage)
          params.isJobCoverage = calendarFilterData?.isJobCoverage;
        if (globalFilters?.userType.length > 0)
          params.userTypeIds = globalFilters?.userType?.map(
            val => val?.user_type_id
          );
      }

      setCalenderParams(params);
      isScheduler
        ? calendarFilterData?.projectClick || params?.projectClick
          ? await getCalendarData(
              params,
              setCalendarData,
              setCalendarLoading,
              setAlert,
              params.projectTypeIds ? null : filterCalenderData,
              setInitialLoader
            )
          : setCalendarLoading(false)
        : await getCalendarData(
            params,
            setCalendarData,
            setCalendarLoading,
            setAlert,
            params.projectTypeIds ? null : filterCalenderData,
            setInitialLoader
          );
      setInitialLoader(false);
      setCalendarLoading(false);
      setChildGetParms(params);
    }
  }, [calendarFilterData, reloadCalender, filterCalenderData]);

  useEffect(() => {
    if (calendarDataResetData?.length > 0) {
      setCalendarData(calendarDataResetData);
    }
  }, [calendarDataResetData]);

  //Mapping calendar data to eventData and Resource Data

  // Grey boxes for approved time off
  const [
    timeoffApprovedEvents,
    timeoffTentativeEvents,
    blockedEvents,
    eventData,
    resourceData,
  ] = useMemo(() => {
    let result = originalCalendarData;
    if (Array.isArray(calendarData) && calendarData.length && !isOpen) {
      const timeoffApprovedEventsLocal = {};
      const timeoffTentativeEventsLocal = {};
      const blockedEventsLocal = [];
      const eventDataLocal = [];
      const resourceDataLocal = {};
      let index = -1;
      for (const cal of calendarData) {
        ++index;
        if (
          cal?.time_off_start_date &&
          cal?.time_off_end_date &&
          cal?.is_blocked_time !== 1
        ) {
          const key = `${cal.time_off_start_date}__${cal.time_off_end_date}__${cal?.project_number}`;
          const time_off_start_date = cal.time_off_start_date;
          const time_off_end_date = cal.time_off_end_date;
          const unformattedEndDate = moment(time_off_start_date);
          const endDateHour = moment(time_off_end_date).get('hour');
          const endDateMinute = moment(time_off_end_date).get('minute');
          const endDate = unformattedEndDate.set({
            hour: endDateHour,
            minute: endDateMinute,
          });
          const startDate = moment(time_off_start_date);
          const durationInHour = moment.duration(endDate.diff(startDate));
          const splitHours = durationInHour.asHours().toString().split('.');
          const unformattedHours = splitHours[0];
          let minute = '00';

          if (splitHours.length > 1) {
            minute = Math.ceil(parseFloat('0.' + splitHours[1]) * 60);
          }
          const duration = unformattedHours
            .padStart(2, '0')
            .concat(':', minute);
          const availabilityUpdate = {
            title: cal?.time_off_note || '',
            resourceId: cal?.installer_id,
            border: 'none',
            installer_id: cal?.installer_id,
            project_id: cal?.project_id,
            start: cal?.time_off_start_date
              ? moment(cal?.time_off_start_date).format()
              : '',
            end: cal?.time_off_end_date
              ? moment(cal?.time_off_end_date).format()
              : '',
            allDay: cal?.full_day == 1 ? true : false,
            eventType: 'timeoff',
            rrule: {
              freq: 'weekly',
              interval: cal?.is_recurring_every_other_week,
              byweekday: [
                ...(cal?.sun ? ['su'] : []),
                ...(cal?.mon ? ['mo'] : []),
                ...(cal?.tue ? ['tu'] : []),
                ...(cal?.wed ? ['we'] : []),
                ...(cal?.thus ? ['th'] : []),
                ...(cal?.fri ? ['fr'] : []),
                ...(cal?.sat ? ['sa'] : []),
              ],
              until: moment(cal?.time_off_end_date).format(),
              dtstart: moment(cal?.time_off_start_date).format(),
            },
            overlap: true,
            isDisabled: true,
            duration,
            timeOffRequestId: cal?.user_time_off_request_id,
            is_customer_requested: cal?.is_customer_requested,
          };
          if (cal?.status_id === 88 && !timeoffApprovedEventsLocal[key]) {
            const timeoffApprovedEventsColor =
              EVENT_COLORS[Math.floor(Math.random() * EVENT_COLORS.length)];
            const timeoffApprovedEventsLocalAvailabilityUpdate = {
              ...availabilityUpdate,
              backgroundColor: 'rgb(161, 161, 161, 0.4)',
              textColor: timeoffApprovedEventsColor.textColor,
            };
            timeoffApprovedEventsLocal[key] =
              timeoffApprovedEventsLocalAvailabilityUpdate;
          }
          if (cal?.status_id === 86 && !timeoffTentativeEventsLocal[key]) {
            const timeoffApprovedEventsColor =
              EVENT_COLORS[Math.floor(Math.random() * EVENT_COLORS.length)];
            const timeoffApprovedEventsLocalAvailabilityUpdate = {
              ...availabilityUpdate,
              background:
                'repeating-linear-gradient(135deg, #696969 10px,#696969 12px,transparent 12px,transparent 20px)',
              textColor: timeoffApprovedEventsColor.textColor,
              classNames: [classes.tentativeTimeOff],
            };
            timeoffApprovedEventsLocal[key] =
              timeoffApprovedEventsLocalAvailabilityUpdate;
          }
        }
        if (cal?.is_blocked_time === 1) {
          const blockedEventsColor = EVENT_COLORS[4];
          blockedEventsLocal.push({
            title: cal?.time_off_note || '',
            resourceId: cal?.installer_id,
            backgroundColor: blockedEventsColor?.bgColor,
            textColor: blockedEventsColor?.textColor,
            start: cal?.time_off_start_date
              ? moment(cal?.time_off_start_date).format()
              : '',
            end: cal?.time_off_end_date
              ? moment(cal?.time_off_end_date).format()
              : '',
            allDay: cal?.full_day === 1 ? true : false,
            eventType: EVENT_TYPES.BLOCK,
            overlap: true,
            isDisabled: true,
            installerId: cal?.installer_id,
            timeOffRequestId: cal?.user_time_off_request_id,
            editable: false,
            durationEditable: false,
            is_customer_requested: cal?.is_customer_requested,
          });
        }
        let eventDataLocalEventColor = EVENT_COLORS[1];
        if (
          !!cal.project_type &&
          (cal?.config_color_code || cal?.project_color_code)
        ) {
          eventDataLocalEventColor = mapProjectTypeToColor(
            cal?.config_color_code || cal?.project_color_code,
            cal?.project_type,
            cal?.project_status,
            cal?.color_code
          );
        }
        eventDataLocal.push({
          id: cal?.project_id,
          title: cal?.project_desc || '',
          resourceId: cal?.installer_id,
          backgroundColor: eventDataLocalEventColor.bgColor,
          textColor: eventDataLocalEventColor.textColor,
          border: 'none',
          installer_id: cal?.installer_id,
          project_id: cal?.project_id,
          start: cal?.schedule_date,
          end: cal?.schedule_end_date,
          project_desc: cal?.project_desc,
          store_number: cal?.store_number,
          project_type: cal?.project_type,
          source_system_id: cal?.source_system_id,
          category: cal?.category,
          project_status: cal?.project_status,
          project_number: cal?.project_number,
          client_name: cal?.client_name,
          Time:
            moment(cal?.schedule_date).format('hh:mm A') +
            ' - ' +
            moment(cal?.schedule_end_date).format('hh:mm A'),
          mobile_phone: cal?.mobile_phone,
          address: cal?.address,
          timeoff_status: cal?.status_id,
          time_off_start_date: cal?.time_off_start_date,
          time_off_end_date: cal?.time_off_end_date,
          total_sale_amount: cal?.total_sale_amount,
          total_revenue: cal?.total_revenue,
          confirmation_status: cal?.confirmation_status,
          is_customer_requested: cal?.is_customer_requested,
          overlap: true,
          background:
            'repeating-linear-gradient(135deg, #696969 10px,#696969 12px,transparent 12px,transparent 20px)',

          schedule_date: cal?.schedule_date,
          schedule_end_date: cal?.schedule_end_date,
          installer_profile_image_url: cal?.installer_profile_image_url,
          basic_labor_sum: cal?.basic_labor_sum,
        });
        if (cal?.installer_name) {
          const resourceValue = {
            id: cal.installer_id,
            is_customer_requested: cal.is_customer_requested,
            title: cal?.installer_name?.trim(),
            email: cal?.installer_email,
            phone: cal?.installer_phone,
            companyName: cal?.companyname,
            avatarIcon: cal?.installer_profile_image_url,
            sortOrder:
              cal.installer_name === 'Unassigned Technician' ? 0 : index + 1,
            installerbio: cal?.installerbio,
          };
          if (resourceDataLocal[resourceValue.title]) {
            resourceDataLocal[resourceValue.title].push(resourceValue);
          } else {
            resourceDataLocal[resourceValue.title] = [resourceValue];
          }
        }
      }

      result = [
        Object.values(timeoffApprovedEventsLocal),
        Object.values(timeoffTentativeEventsLocal),
        blockedEventsLocal,
        [
          ...eventDataLocal,
          ...getUniqueArrayItemsByKey(
            Object.values(timeoffApprovedEventsLocal),
            'timeOffRequestId'
          ),
          ...getUniqueArrayItemsByKey(
            Object.values(timeoffTentativeEventsLocal),
            'timeOffRequestId'
          ),
          ...getUniqueArrayItemsByKey(blockedEventsLocal, 'timeOffRequestId'),
        ],
        Object.values(resourceDataLocal).flat(),
      ];
      setOriginalCalendarData(result);
    }
    return result;
  }, [calendarData, isOpen]);

  function renderEventContent(eventInfo) {
    const jobDistancePermission =
      CLIENT_DETAILS?.is_capture_job_distance_enabled;
    if (
      eventInfo?.event?.extendedProps?.eventType === 'timeoff' ||
      eventInfo?.event?.extendedProps?.eventType === 'blockedTime'
    ) {
      return (
        <Tooltip
          title={renderBlockTimeTooltip(eventInfo)}
          arrow
          placement="top"
        >
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            className="h-6rem"
          >
            <Grid
              item
              container
              justifyContent="center"
              className={classes.eventLoadingIndicator}
              id={`eventLoading-${eventInfo?.event?.id}`}
            >
              <CircularProgress />
            </Grid>
            <Grid item xs={12} className={`event-${eventInfo?.event?.id}`}>
              <Typography noWrap align="center" spacing={2}>
                {eventInfo?.event?.extendedProps.eventType !== 'timeoff' &&
                  eventInfo?.event?.extendedProps.eventType !== 'blockedTime' &&
                  eventInfo.timeText}
              </Typography>
              <Typography noWrap className={classes.title} spacing={2}>
                {eventInfo.event.title || ''}
              </Typography>
            </Grid>
          </Grid>
        </Tooltip>
      );
    }

    return (
      <div className="grid w-full grid-nogutter">
        <div
          className={`col-12 ${classes.eventLoadingIndicator}`}
          id={`eventLoading-${eventInfo.event.id}`}
        >
          <CircularProgress />
        </div>
        <div className={`w-12 event-${eventInfo.event.id}`}>
          {jobDistancePermission && isScheduler ? (
            <>
              <JobDistanceOverlay
                selectedRow={selectedRow}
                eventInfo={eventInfo}
                localProp={null}
              />
            </>
          ) : null}

          <div className="flex justify-content-end">
            <span>
              <Tooltip
                title={renderTooltipContent(
                  eventInfo.event.extendedProps,
                  eventInfo?.event?.extendedProps?.Time
                )}
                arrow
                placement="right"
              >
                <i className="pi pi-exclamation-circle absolute right-0 mt-2 mr-3 z-5"></i>
              </Tooltip>
            </span>
          </div>

          <ProjectScheduleCard
            eventInfo={eventInfo}
            schedulerCardView={schedulerCardView}
            cxSchedulerIcon={cxSchedulerIcon}
            period={calenderParams?.period}
          />
        </div>
      </div>
    );
  }

  function renderResourceContent(eventInfo) {
    return (
      <>
        <div className="grid w-12 m-0 technicians-info"></div>
        <div className="resource-container w-full">
          <div className="border-yellow"></div>
          <div className="border-blue"></div>
          <div className="card">
            <div className="info flex flex-column justify-content-start align-items-start flex-grow-1 pl-4 pt-4">
              <div className="name mb-2 ml-1 line-height-2">
                <div
                  className={`grid w-12 m-0 align-items-center relative scrollable-container ${eventInfo?.resource?._resource?.extendedProps?.installerbio ? 'cursor-pointer' : 'cursor-auto'}`}
                >
                  {eventInfo?.resource?._resource?.extendedProps
                    ?.installerbio && (
                    <>
                      <div className="installerbio-tooltip text-white absolute">
                        <div className="description">
                          {
                            eventInfo?.resource?._resource?.extendedProps
                              ?.installerbio
                          }
                        </div>
                      </div>
                    </>
                  )}
                  <div className="col-12 md:col-6 lg:col-1 p-0 w-15rem">
                    {eventInfo?.fieldValue}
                  </div>
                </div>
              </div>

              <div className="detail text-xs ml-1">
                <i className="pi pi-envelope text-xs mr-1" />
                <a
                  href={`mailto:${eventInfo?.resource?._resource?.extendedProps?.email}`}
                  className="text-color"
                >
                  {eventInfo?.resource?._resource?.extendedProps?.email}
                </a>
              </div>

              <div className="detail text-xs ml-1">
                <i className="pi pi-phone text-xs mr-1" />
                <a
                  href={`tel:${eventInfo?.resource?._resource?.extendedProps?.phone}`}
                  className="text-color"
                >
                  {eventInfo?.resource?._resource?.extendedProps?.phone}
                </a>
              </div>

              <div className="detail text-xs ml-1">
                {eventInfo?.resource?._resource?.extendedProps?.companyName && (
                  <>
                    <i className="pi pi-building text-xs mr-1" />
                    <strong>
                      {
                        eventInfo?.resource?._resource?.extendedProps
                          ?.companyName
                      }
                    </strong>
                  </>
                )}
              </div>
            </div>
            <div className="avatar pl-2 w-2 h-full pt-1 mt-2 mr-2">
              <TechnicianAvatar
                src={eventInfo?.resource?._resource?.extendedProps?.avatarIcon}
                alt={eventInfo.fieldValue}
              />
            </div>
          </div>
        </div>
      </>
    );
  }

  const renderTooltipContent = (eventInfo, time) => {
    return (
      <>
        <table className="w-full vertical-align-top text-xs">
          {eventInfo?.basic_labor_sum ? (
            <tr>
              <td className="minWidth-130">
                <strong>Qty</strong>
              </td>
              <td>{eventInfo?.basic_labor_sum}</td>
            </tr>
          ) : null}
          {time ? (
            <tr>
              <td className="minWidth-130">
                <strong>Time</strong>
              </td>
              <td>{time}</td>
            </tr>
          ) : null}
          {eventInfo?.store_number ? (
            <tr>
              <td className="minWidth-130">
                <strong>Store #</strong>
              </td>
              <td>{eventInfo?.store_number}</td>
            </tr>
          ) : null}
          {eventInfo?.client_name ? (
            <tr>
              <td className="minWidth-130">
                <strong>Client Name</strong>
              </td>
              <td>{eventInfo?.client_name}</td>
            </tr>
          ) : null}
          {eventInfo?.category ? (
            <tr>
              <td className="minWidth-130">
                <strong>Category</strong>
              </td>
              <td className="word_break">{eventInfo?.category}</td>
            </tr>
          ) : null}
          {eventInfo?.address ? (
            <tr>
              <td className="minWidth-130 word_break">
                <strong>Address</strong>
              </td>
              <td>{eventInfo?.address}</td>
            </tr>
          ) : null}
          {eventInfo?.total_sale_amount &&
            checkPermission(
              permissions?.projectItems?.viewProjectLevelCostField
            ) && (
              <tr>
                <td className="minWidth-130">
                  <strong>Total Sale $</strong>
                </td>
                <td>{`$ ${eventInfo?.total_sale_amount}`}</td>
              </tr>
            )}
          {eventInfo?.total_revenue &&
            checkPermission(
              permissions?.projectItems?.viewProjectLevelCostField
            ) && (
              <tr>
                <td className="minWidth-130">
                  <strong>Total Revenue $</strong>
                </td>
                <td>{`$ ${eventInfo?.total_revenue}`} </td>
              </tr>
            )}
          {eventInfo?.project_desc ? (
            <tr>
              <td className="minWidth-130">
                <strong>Description</strong>
              </td>
              <td className="word_break">
                {ellipsisString(eventInfo?.project_desc, 45)}
              </td>
            </tr>
          ) : null}
          {eventInfo?.project_type ? (
            <tr>
              <td className="minWidth-130">
                <strong>Type</strong>
              </td>
              <td>{eventInfo?.project_type}</td>
            </tr>
          ) : null}
          {eventInfo?.project_status ? (
            <tr>
              <td className="minWidth-130">
                <strong>Status</strong>
              </td>
              <td>{eventInfo?.project_status}</td>
            </tr>
          ) : null}
          {eventInfo?.project_number ? (
            <tr>
              <td className="minWidth-130">
                <strong>Project No</strong>
              </td>
              <td>{eventInfo?.project_number}</td>
            </tr>
          ) : null}
          {eventInfo?.mobile_phone ? (
            <tr>
              <td className="minWidth-130">
                <strong>Phone</strong>
              </td>
              <td>{eventInfo?.mobile_phone}</td>
            </tr>
          ) : null}
          {eventInfo?.confirmation_status ? (
            <tr>
              <td className="minWidth-130">
                <strong>Confirmation Status</strong>
              </td>
              <td>{eventInfo?.confirmation_status}</td>
            </tr>
          ) : null}
        </table>
      </>
    );
  };

  const renderBlockTimeTooltip = eventInfo => {
    return <div>{eventInfo?.event?.title}</div>;
  };

  const makeTodayBtnActive = active => {
    if (active) {
      let todayButton = document.getElementsByClassName('fc-today-button')[0];

      todayButton?.removeAttribute('disabled');

      todayButton.addEventListener('click', () => {
        onViewChange('today');
      });
    } else {
      document
        .getElementsByClassName('fc-today-button')[0]
        .setAttribute('disabled', true);
    }
  };
  const makeResetBtnActive = active => {
    active
      ? document
          .getElementsByClassName('fc-resetButton-button')[0]
          ?.removeAttribute('disabled')
      : document
          .getElementsByClassName('fc-resetButton-button')[0]
          .setAttribute('disabled', true);
  };

  const updateDragSchedule = async info => {
    if (info?.event?.extendedProps?.eventType === EVENT_TYPES.BLOCK) {
      return;
    }
    calendarApi = calendarRef?.current?.getApi();
    const elementsByClass = document.getElementsByClassName(
      `event-${info?.event?.id}`
    );
    if (elementsByClass?.[0]?.style) {
      elementsByClass[0].style.display = 'none';
    }

    const elementById = document.getElementById(
      `eventLoading-${info?.event?.id}`
    );
    if (elementById?.style) {
      elementById.style.display = 'flex';
    }
    const statusId = statusOptions?.find(
      item => item?.status === info?.event?.extendedProps?.project_status
    );
    let updateObj = {
      installerId: parseInt(info.event.extendedProps.installer_id),
      projectId: parseInt(info.event.extendedProps.project_id),
      projectStartDateTime: moment(info.event.startStr),
      projectEndDateTime: moment(info.event.endStr),
      old_installerId: info.event.extendedProps.installer_id.toString(),
      old_startDateTime: moment(info.oldEvent.startStr),
      old_endDateTime: moment(info.oldEvent.endStr),
      status_id: statusId?.status_id || '',
    };
    await updateInstallerSchedule(
      updateObj,
      setAlert,
      setCalendarLoading,
      setReloadList,
      setButtonDisabled,
      setIsOpen
    );
    setReloadCalendar(!reloadCalendar);
    calendarApi.refetchEvents();
  };

  const updateSchedule = info => {
    if (info?.event?.extendedProps?.eventType === EVENT_TYPES.BLOCK) {
      return;
    }
    setIsUpdateSchedule(true);
    setStartDateStr(info?.event?.startStr);
    setEndDateStr(info?.event?.endStr);
    setInfoValue(info);
    notesFormik.setValues({
      type: '',
      note_text: '',
      follow_up_date: null,
    });
    setDialogSettings(prevState => ({
      ...prevState,
      title: 'Reschedule Confirmation',
      showButton2: true,
    }));

    setIsOpen(true);
    notesFormik?.handleReset();
  };
  const preferenceParam = {
    projectTypeIds: calendarFilterData?.project_type_id,
    projectCategoryId: calendarFilterData?.project_category_id,
    projectStoreId: calendarFilterData?.project_store_id,
    isJobCoverage: calendarFilterData?.isJobCoverage,
  };

  const onViewChange = view => {
    let params;
    calendarApi = calendarRef?.current?.getApi();
    setCalendarLoading(true);
    switch (view) {
      case 'prev':
        calendarApi?.prev();
        params = {
          date: moment(calendarApi?.getDate()).format(),
          period: getPeriod(calendarApi?.view?.type),
          ...(isScheduler && preferenceParam),
        };
        break;
      case 'next':
        calendarApi?.next();
        params = {
          date: moment(calendarApi?.getDate()).format(),
          period: getPeriod(calendarApi?.view?.type),
          ...(isScheduler && preferenceParam),
        };
        break;
      case 'resourceTimelineDay':
        calendarApi?.changeView('resourceTimelineDay');
        if (!moment().diff(calendarApi.getDate(), 'days')) {
          makeTodayBtnActive(false);
        }
        params = {
          date: moment(calendarApi?.getDate()).format(),
          period: 'D',
          ...(isScheduler && preferenceParam),
        };
        break;
      case 'resourceTimelineWeek':
        calendarApi?.changeView('resourceTimelineWeek');
        makeTodayBtnActive(true);
        params = {
          date: moment(calendarApi?.getDate()).format(),
          period: 'W',
          ...(isScheduler && preferenceParam),
        };
        break;
      case 'resourceTimelineMonth':
        calendarApi?.changeView('resourceTimelineMonth');
        makeTodayBtnActive(true);
        params = {
          date: moment(calendarApi?.getDate()).format(),
          period: 'M',
          ...(isScheduler && preferenceParam),
        };
        break;
      case 'today':
        calendarApi?.changeView('resourceTimelineDay');
        calendarApi?.today();
        makeTodayBtnActive(false);
        params = {
          date: moment(calendarApi?.getDate()).format(),
          period: 'D',
          ...(isScheduler && preferenceParam),
        };
        break;
    }
    setCalenderParams(params);
    getCalendarData(
      params,
      setCalendarData,
      setCalendarLoading,
      setAlert,
      filterCalenderData
    );
    setChildGetParms(params);
  };
  const handleUpdateEventDrop = async info => {
    if (info?.event?.extendedProps?.eventType === EVENT_TYPES.BLOCK) {
      return;
    }
    const infoResources = info.event.getResources();
    const resourceId = infoResources[0]._resource.id;
    const statusId = statusOptions?.find(
      item => item?.status === info.event.extendedProps.project_status
    );
    setEventLoading(true);
    setReloadList(false);
    document.getElementsByClassName(
      `event-${info?.event?.id}`
    )[0].style.display = 'none';
    document.getElementById(`eventLoading-${info?.event?.id}`).style.display =
      'flex';
    let updateObj = {
      installerId: parseInt(info?.newResource?.id) || parseInt(resourceId),
      projectId: parseInt(info.event.extendedProps.project_id),
      projectStartDateTime: moment(info.event.startStr),
      projectEndDateTime: moment(info.event.endStr),
      old_installerId: info?.oldResource?.id || resourceId,
      old_startDateTime: moment(info.oldEvent.startStr),
      old_endDateTime: moment(info.oldEvent.endStr),
      status_id: statusId?.status_id || '',
    };

    // return false;

    await updateInstallerSchedule(
      updateObj,
      setAlert,
      setCalendarLoading,
      setReloadList,
      setButtonDisabled,
      setIsOpen
    );

    setReloadCalendar(!reloadCalendar);
    calendarApi.refetchEvents();
  };

  const NotesFormikSchema = Yup.object().shape({
    note_text: Yup.string().trim(),
  });

  const notesFormik = useFormik({
    initialValues: {
      type: 6,
      note_text: '',
      follow_up_date: null,
    },
    validationSchema: NotesFormikSchema,
    onSubmit: async values => {
      if (values?.note_text?.length > 0) {
        await addNotes(
          parseInt(infoValue?.event?.extendedProps?.project_id),
          values,
          () => {},
          () => {},
          () => {},
          () => {}
        );
      }
      if (isUpdateSchedule) {
        updateDragSchedule(infoValue);
      } else {
        handleUpdateEventDrop(infoValue);
      }
    },
    enableReinitialize: true,
  });

  const handleEventDrop = info => {
    if (info?.event?.extendedProps?.eventType === EVENT_TYPES.BLOCK) {
      return;
    }
    setStartDateStr(info?.event?.startStr);
    setEndDateStr(info?.event?.endStr);
    setInfoValue(info);
    notesFormik.setValues({
      type: '',
      note_text: '',
      follow_up_date: null,
    });
    setDialogSettings(prevState => ({
      ...prevState,
      title: 'Reschedule Confirmation',
      showButton2: true,
    }));
    setIsOpen(true);
    notesFormik?.handleReset();
  };
  //Continue scheduling
  const continueScheduling = async () => {
    const projectData = reportData.find(
      data =>
        data.project_id ===
        parseInt(infoData?.draggedEl?.attributes['data-event']?.value)
    );
    const statusId =
      statusOptions &&
      statusOptions.length > 0 &&
      statusOptions?.find(item => item?.status === projectData?.project_status);

    let updateObj = {
      installerId: parseInt(infoData.resource.id),
      projectId: parseInt(infoData.draggedEl.attributes['data-event']?.value),
      projectStartDateTime: moment(infoData.dateStr),
      projectEndDateTime:
        calendarApi?.view?.type == 'resourceTimelineWeek' ||
        calendarApi?.view?.type == 'resourceTimelineMonth'
          ? moment(infoData.dateStr).endOf('day')
          : moment(infoData.dateStr).add(1, 'hours'),
      old_installerId: null,
      old_startDateTime: null,
      old_endDateTime: null,
      status_id: statusId?.status_id || '',
      user_time_off_request_ids: dropTimeOffRequestIds,
    };
    await updateInstallerSchedule(
      updateObj,
      setAlert,
      setCalendarLoading,
      setReloadList,
      setButtonDisabled,
      setIsOpen
    );
    setReloadCalendar(!reloadCalendar);
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
  };
  const confirmScheduling = info => {
    setInfoData(info);
    setConfirmDialog({
      ...confirmDialog,
      isOpen: true,
      title:
        'This technician applied leave for this period. Do you still want to assign the job?',
      header: 'Confirm Scheduling',
      button2Text: 'Continue',
    });
  };

  const saveDropCalendarData = async () => {
    let updateObj = {
      installerId: parseInt(tableRowDropData.resource.id),
      projectId: parseInt(
        tableRowDropData.draggedEl.attributes['data-event']?.value
      ),
      projectStartDateTime: startDate ? startDate?.toISOString() : undefined,
      projectEndDateTime: endDate ? endDate?.toISOString() : undefined,
      old_installerId: null,
      old_startDateTime: null,
      old_endDateTime: null,
      status_id: statusId,
      userAction: technicianAction,
      percentage:
        technicianAction === scheduleInfoUserCustomAction
          ? schedulePercentage
          : 0,
    };

    const updateInstallerResponse = await updateInstallerSchedule(
      updateObj,
      setAlert,
      setCalendarLoading,
      setReloadList,
      setButtonDisabled,
      setIsOpen
    );
    setSchedulePercentage(0);
    if (updateInstallerResponse?.status === 206) {
      toast.current.show({
        severity: 'warn',
        summary: 'Warning',
        detail: updateInstallerResponse?.message,
      });
    }

    setReloadCalendar(!reloadCalendar);
    handleDropModalClose();
  };
  const fetchInstallerList = async () => {
    dispatch(setInstallerViewCalendar({ isLoading: true }));
    try {
      const allInstallersList = await getAllInstallersList();
      dispatch(
        setInstallerViewCalendar({
          installersList: allInstallersList,
        })
      );
    } catch (error) {
      /* empty */
    } finally {
      dispatch(setInstallerViewCalendar({ isLoading: false }));
    }
  };
  useEffect(() => {
    if (isBlockTime) {
      fetchInstallerList();
    }
  }, [isBlockTime]);
  const handleSelect = selectInfo => {
    const { minutesDifference, startSlotTime } = selectedRapidSlot(selectInfo);

    dispatch(
      setInstallerViewCalendar({
        isSelectDialogOpen: true,
        newEventInfo: {
          start: selectInfo?.start,
          end: minutesDifference > 0 ? selectInfo?.end : startSlotTime,
        },
        initialInstaller: selectInfo.resource._resource.id,
      })
    );
  };
  const calenderRefreshHandler = async () => {
    !!calenderParams &&
      (await getCalendarData(
        calenderParams,
        setCalendarData,
        setCalendarLoading,
        setAlert,
        filterCalenderData
      ));
  };

  useEffect(() => {
    calenderRefreshHandler();
  }, [reloadCalendar]);

  const viewScheduleReport = projectId => {
    window.open(`/project/view/${projectId}`, '_blank');
  };

  const confirmBlockTimeWarning = info => {
    setInfoData(info);
    setConfirmDialog({
      ...confirmDialog,
      isOpen: true,
      title:
        'This slot is already blocked for the technician, Do you still want to assign the job?',
      header: 'Confirm Scheduling',
      button2Text: 'Continue',
    });
  };

  const handleDropModalClose = () => {
    setDropModalValue(false);
    setStartDate(null);
    setEndDate(null);
    setIsStartOrEndDateInvalid(false);
  };

  return (
    <>
      {(initialLoader && !isScheduler) ||
      (initialLoader && calendarFilterData?.projectClick) ? (
        <SchedulerSkeleton
          rows={[firstRowData, secondRowData, secondRowData]}
        />
      ) : (
        <div className="p-2">
          {calendarLoading && !projectDataLoading && (
            <Grid
              container
              justifyContent="center"
              className={`${classes.calendarLoadingIndicator} mt-8`}
            >
              <CircularProgress className="mt-8" />
            </Grid>
          )}
          <Toast ref={toast} />

          <FullCalendar
            timeZone="local"
            plugins={[
              rrulePlugin,
              resourceTimelinePlugin,
              momentPlugin,
              interactionPlugin,
            ]}
            initialView={datePeriodForCalenderView}
            headerToolbar={{
              left: 'prevButton title nextButton today resetButton',
              right:
                'resourceTimelineDay resourceTimelineWeek resourceTimelineMonth refreshButton fullScreenButton',
            }}
            customButtons={{
              resetButton: {
                text: 'Reset',
                click: function () {
                  resetInstallerFormik();
                  makeResetBtnActive(false);
                  setCalendarFilterData(null);
                  setFilterCalenderData('');
                  if (calendarFilterData?.length > 0) {
                    makeResetBtnActive(true);
                    setCalendarFilterData(null);
                    setSelected(null);
                    setFilterCalenderData('');
                  }
                },
              },
              prevButton: {
                icon: 'fc-icon-chevron-left',
                click: () => onViewChange('prev'),
              },
              nextButton: {
                icon: 'fc-icon-chevron-right',
                click: () => onViewChange('next'),
              },
              resourceTimelineDay: {
                text: 'Day',
                click: () => onViewChange('resourceTimelineDay'),
              },
              resourceTimelineWeek: {
                text: 'Week',
                click: () => onViewChange('resourceTimelineWeek'),
              },
              resourceTimelineMonth: {
                text: 'Month',
                click: () => onViewChange('resourceTimelineMonth'),
              },
              today: {
                text: 'Today',
                click: () => onViewChange('today'),
              },
              refreshButton: {
                text: 'Refresh',
                click: calenderRefreshHandler,
              },
              fullScreenButton: {
                text: calendarFullScreen ? 'Minimize' : 'Full Screen',
                click: () => setCalendarFullScreen(!calendarFullScreen),
              },
            }}
            resourceOrder="title"
            resources={resourceData}
            ref={calendarRef}
            events={eventData}
            nowIndicator={true}
            now={new Date().toISOString()}
            displayEventTime={true}
            droppable={true}
            eventDrop={handleEventDrop}
            selectAllow={() => {
              if (
                checkPermission(
                  permissions?.viewEditProject?.actionEditScheduler
                )
              ) {
                return true;
              } else {
                return false;
              }
            }}
            drop={async function (info) {
              let showWarningMessage = false;
              let showBlockTimeWarning = false;
              const getInfoDateEnd = () => {
                let infoDateEnd;
                const add1Hour = input => {
                  const inputDate = new Date(input);
                  inputDate.setHours(inputDate.getHours() + 1);
                  return inputDate;
                };
                if (info?.view?.type === 'resourceTimelineDay') {
                  infoDateEnd = add1Hour(info?.dateStr);
                }
                return infoDateEnd;
              };
              const eventList = [
                ...timeoffTentativeEvents,
                ...timeoffApprovedEvents,
              ];
              for (let i = 0; i < eventList.length; i++) {
                const timeoff = eventList[i];
                if (
                  (timeoff.resourceId == info.resource.id &&
                    moment(info.dateStr).isBetween(
                      moment(timeoff.start),
                      moment(timeoff.end)
                    )) ||
                  moment(info.dateStr).isSame(moment(timeoff.start)) ||
                  moment(info.dateStr).isSame(moment(timeoff.end))
                ) {
                  if (timeoff?.rrule) {
                    if (
                      moment(info.dateStr).isBetween(
                        moment(timeoff.rrule.dtstart),
                        moment(timeoff.rrule.until)
                      ) ||
                      moment(info.dateStr).isSame(
                        moment(timeoff.rrule.dtstart)
                      ) ||
                      moment(info.dateStr).isSame(moment(timeoff.rrule.until))
                    ) {
                      if (
                        info.allDay &&
                        timeoff?.rrule?.byweekday?.includes(
                          moment(info.dateStr).format('dd').toLowerCase()
                        )
                      ) {
                        showWarningMessage = true;
                        break;
                      }
                      if (!info.allDay) {
                        const newStartHour = moment(timeoff.rrule.dtstart).get(
                          'hour'
                        );
                        const newStartMinute = moment(
                          timeoff.rrule.dtstart
                        ).get('minute');
                        const newEndHour = moment(timeoff.rrule.until).get(
                          'hour'
                        );
                        const newEndMinute = moment(timeoff.rrule.until).get(
                          'minute'
                        );
                        const newStartDate = moment(info.dateStr).set({
                          hour: newStartHour,
                          minute: newStartMinute,
                        });
                        const newEndDate = moment(info.dateStr).set({
                          hour: newEndHour,
                          minute: newEndMinute,
                        });
                        if (
                          (newStartDate < moment(info.dateStr) &&
                            moment(info.dateStr) < newEndDate) ||
                          (newStartDate <
                            moment(info.dateStr).add(1, 'hours') &&
                            moment(info.dateStr).add(1, 'hours') < newEndDate)
                        ) {
                          showWarningMessage = true;
                          break;
                        }
                      }
                      if (info.allDay && !timeoff?.rrule?.byweekday.length) {
                        showWarningMessage = true;
                        break;
                      }
                    }
                  }
                }
              }
              const blockEventsOfDroppedInstaller = blockedEvents?.filter(
                i => i.resourceId === parseInt(info?.resource?.id)
              );
              const blockTimeRequests = [];
              for (const blockTimeEvent of blockEventsOfDroppedInstaller) {
                if (
                  moment(info?.dateStr).isBetween(
                    moment(blockTimeEvent?.start),
                    moment(blockTimeEvent?.end)
                  ) ||
                  moment(getInfoDateEnd()).isBetween(
                    moment(blockTimeEvent?.start),
                    moment(blockTimeEvent?.end)
                  ) ||
                  moment(info?.dateStr).isSame(moment(blockTimeEvent?.start)) ||
                  moment(info?.dateStr).isSame(moment(blockTimeEvent?.end))
                ) {
                  showBlockTimeWarning = true;
                  blockTimeRequests.push(blockTimeEvent?.timeOffRequestId);
                }
              }
              setDropTimeOffRequestIds(blockTimeRequests);
              if (showWarningMessage) {
                confirmScheduling(info);
              } else {
                if (showBlockTimeWarning) {
                  confirmBlockTimeWarning(info);
                } else {
                  setDropModalValue(true);
                  setTableRowDropData(info);
                  setStatusId(7);
                  const dataFind = reportData?.find(
                    data =>
                      data.project_id ===
                      parseInt(info?.draggedEl?.attributes['data-event']?.value)
                  );
                  setDropData(dataFind);
                  const startDateObj = structuredClone(info?.date);
                  let endDateObj = structuredClone(info?.date);
                  const calendarRefObj = calendarRef?.current?.getApi();
                  switch (calendarRefObj?.view?.type) {
                    case 'resourceTimelineDay':
                      endDateObj.setHours(endDateObj.getHours() + 1);
                      setStartDate(startDateObj);
                      setEndDate(endDateObj);
                      break;
                    case 'resourceTimelineWeek':
                    case 'resourceTimelineMonth':
                      endDateObj.setDate(endDateObj.getDate() + 1);
                      setStartDate(startDateObj);
                      setEndDate(endDateObj);
                      break;
                    default:
                      break;
                  }
                }
              }
            }}
            loading={bool => {
              if (!bool) {
                calendarApi = calendarRef?.current?.getApi();
              }
            }}
            eventMinHeight={50}
            eventResize={info => updateSchedule(info)}
            navLinks={true}
            eventContent={renderEventContent}
            resourceLabelContent={renderResourceContent}
            schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
            editable={event => {
              event?.extendedProps?.eventType === EVENT_TYPES?.BLOCK
                ? false
                : true;
            }}
            selectOverlap={event => {
              return (
                event?.extendedProps?.eventType !== EVENT_TYPES?.BLOCK &&
                event?.extendedProps?.eventType !== EVENT_TYPES.TIME_OFF
              );
            }}
            eventResourceEditable={true}
            eventResizableFromStart={true}
            resourceAreaHeaderContent="Technicians"
            resourceAreaWidth="400px"
            eventMaxStack={selectedViewMore}
            height={700}
            eventTimeFormat={{
              hour: '2-digit',
              minute: '2-digit',
            }}
            eventBorderColor="none"
            eventClick={info => {
              if (!info.event.extendedProps?.isDisabled) {
                window.open(
                  `/project/view/${info?.event?.extendedProps?.project_id}`,
                  '_blank'
                );
              }
              if (info.event.extendedProps?.eventType === 'blockedTime') {
                dispatch(
                  setInstallerViewCalendar({
                    isSelectDialogOpen: true,
                    newEventInfo: {
                      start: info?.event?.start,
                      end: info?.event?.end,
                      note: info?.event?.title,
                      timeOffRequestId:
                        info?.event?.extendedProps?.timeOffRequestId,
                    },
                    initialInstaller: info?.event?.extendedProps?.installerId,
                    mode: 'edit',
                  })
                );
              }
            }}
            refetchResourcesOnNavigate={true}
            views={{
              resourceTimelineDay: {
                slotDuration: '00:15',
                buttonText: 'Day',
                slotLabelFormat: 'LT',
                titleFormat: 'dddd, D MMMM YYYY',
                slotMinWidth: 40,
                scrollTime: '08:00',
                eventMinWidth: 40,
                eventMinHeight: 150,
              },
              resourceTimelineWeek: {
                buttonText: 'Week',
                type: 'timeline',
                duration: { weeks: 1 },
                slotDuration: { days: 1 },
                slotLabelFormat: [{ day: 'numeric', weekday: 'short' }],
                titleFormat: 'D MMMM YYYY',
                slotMinWidth: 150,
                scrollTime: '08:00',
                eventMinWidth: 150,
                eventMinHeight: 150,
              },
              resourceTimelineMonth: {
                buttonText: 'Month',
                slotLabelFormat: [{ day: '2-digit', weekday: 'short' }],
                titleFormat: 'MMMM YYYY',
                slotMinWidth: 180,
                eventMinWidth: 180,
                eventMinHeight: 150,
              },
            }}
            selectable={true}
            select={handleSelect}
          />
          {/* Confirmation dialog for delete */}
          <GenericConfirmationDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
            onConfirmDialog={continueScheduling}
          />
          {dropModalValue ? (
            <Modal
              open={dropModalValue}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              onClose={handleDropModalClose}
              className={classes.modal}
            >
              <Grid
                container
                item
                direction="row"
                xs={3}
                justifyContent="flex-end"
                spacing={2}
                className={classes.boxWidth}
              >
                <Grid container item xs={12} direction="row" m={4}>
                  <Typography className={classes.detailLabelModal}>
                    Store #
                  </Typography>
                  <Typography className={classes.colon}>:</Typography>
                  <Typography className={classes.detail}>
                    {dropData?.store_number}{' '}
                  </Typography>
                </Grid>

                <Grid container item xs={12} direction="row" m={4}>
                  <Typography className={classes.detailLabelModal}>
                    Client Name{' '}
                  </Typography>
                  <Typography className={classes.colon}>:</Typography>
                  <Typography className={classes.detail}>
                    {dropData?.client_name}{' '}
                  </Typography>
                </Grid>

                <Grid container item xs={12} direction="row" m={4}>
                  <Typography className={classes.detailLabelModal}>
                    Category
                  </Typography>
                  <Typography className={classes.colon}>:</Typography>
                  <Typography className={classes.detail}>
                    {dropData?.category}{' '}
                  </Typography>
                </Grid>

                <Grid container item xs={12} direction="row" m={4}>
                  <Typography className={classes.detailLabelModal}>
                    Address
                  </Typography>
                  <Typography className={classes.colon}>:</Typography>
                  <Typography className={classes.detail}>
                    {dropData?.installation_address}{' '}
                  </Typography>
                </Grid>

                <Grid container item xs={12} direction="row" m={4}>
                  <Typography className={classes.detailLabelModal}>
                    Type
                  </Typography>
                  <Typography className={classes.colon}>:</Typography>
                  <Typography className={classes.detail}>
                    {dropData?.project_type}{' '}
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  direction="row"
                  m={4}
                  className="align-items-center"
                >
                  <Typography className={classes.detailLabelModal}>
                    Schedule Start Date
                  </Typography>
                  <Typography className={classes.colon}>:</Typography>
                  <div className={classes.detail}>
                    <Calendar
                      id="startDate"
                      className="w-full"
                      value={startDate}
                      onChange={e => setStartDate(e.value)}
                      showTime
                      hourFormat="12"
                      appendTo="self"
                      stepMinute={15}
                    />
                  </div>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  direction="row"
                  m={4}
                  className="align-items-center"
                >
                  <Typography className={classes.detailLabelModal}>
                    Schedule End Date
                  </Typography>
                  <Typography className={classes.colon}>:</Typography>
                  <div className={classes.detail}>
                    <Calendar
                      id="endDate"
                      className="w-full"
                      value={endDate}
                      onChange={e => setEndDate(e.value)}
                      showTime
                      hourFormat="12"
                      appendTo="self"
                      stepMinute={15}
                    />
                    {isStartOrEndDateInvalid && (
                      <div className="mt-1 text-red-600">
                        End date must be after start date
                      </div>
                    )}
                  </div>
                </Grid>

                <Grid container item xs={12} direction="row" m={4}>
                  <Typography className={classes.detailLabelModal}>
                    Status
                  </Typography>
                  <Typography className={classes.colon}>:</Typography>
                  <Autocomplete
                    id="status"
                    disableListWrap
                    options={statusOptions}
                    getOptionLabel={option => option && option.status}
                    onChange={(event, value) => {
                      setStatusId(value?.status_id);
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                    classes={{ input: classes.statusInput }}
                    value={statusOptions.find(
                      status => status.status_id === statusId
                    )}
                  />
                </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  direction="row"
                  m={4}
                  className="grid"
                >
                  <Typography className="col-3 pl-0">
                    Default Technician Action
                  </Typography>
                  <Typography className="col-1 text-center">:</Typography>
                  <Typography className="col-8">
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      value={technicianAction}
                      name="radio-buttons-group"
                      onChange={e => setTechnicianAction(e?.target.value)}
                    >
                      <FormControlLabel
                        value={scheduleInfoUserWipeAction}
                        control={<Radio />}
                        label="Full Allocation"
                      />
                      <div className="flex align-items-center">
                        <FormControlLabel
                          value={scheduleInfoUserCustomAction}
                          control={<Radio />}
                          label="Custom Allocation"
                        />
                        {technicianAction === scheduleInfoUserCustomAction && (
                          <PFInputNumber
                            id="allocate_percentage"
                            value={schedulePercentage}
                            onChange={(event, value) => {
                              const percentage = getPercentageValue(value);
                              setSchedulePercentage(percentage);
                            }}
                            suffix={'%'}
                            placeholder="%"
                            className="w-36"
                            pt={{
                              root: {
                                className: 'h-2rem pb-2 pt-1',
                              },
                              input: {
                                root: {
                                  className:
                                    'w-full border-noround border-bottom-1 border-top-none border-x-none p-0  shadow-none border-gray-500',
                                },
                              },
                            }}
                            min={0}
                            maxLength={6}
                            max={100}
                          />
                        )}
                      </div>

                      <FormControlLabel
                        value={scheduleInfoUserFillAction}
                        control={<Radio />}
                        label="Allocate Remaining"
                      />

                      <FormControlLabel
                        value={scheduleInfoUserSkipAction}
                        control={<Radio />}
                        label="Leave Unassigned"
                      />
                    </RadioGroup>
                  </Typography>
                </Grid>

                <Grid container item xs={12} direction="row" m={4}>
                  <Typography className={classes.detailLabelModal}>
                    Source Status
                  </Typography>
                  <Typography className={classes.colon}>:</Typography>
                  <Typography className={classes.detail}>
                    {dropData?.IMS_status}{' '}
                  </Typography>
                </Grid>

                <Grid container item xs={12} direction="row" m={4}>
                  <Typography className={classes.detailLabelModal}>
                    Project No
                  </Typography>
                  <Typography className={classes.colon}>:</Typography>
                  <Typography className={classes.detail}>
                    {dropData?.project_number}{' '}
                  </Typography>
                </Grid>

                <Grid container item xs={12} direction="row" m={4}>
                  <Typography className={classes.detailLabelModal}>
                    Mobile Number
                  </Typography>
                  <Typography className={classes.colon}>:</Typography>
                  <Typography className={classes.detail}>
                    {dropData?.home_phone}{' '}
                  </Typography>
                </Grid>

                <Grid container item xs={12} direction="row" m={4}>
                  <Typography className={classes.detailLabelModal}>
                    Alternate Number
                  </Typography>
                  <Typography className={classes.colon}>:</Typography>
                  <Typography className={classes.detail}>
                    {dropData?.mobile_phone}{' '}
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => viewScheduleReport(dropData?.project_id)}
                  >
                    GoTo Job
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={saveDropCalendarData}
                    disabled={isStartOrEndDateInvalid}
                  >
                    Save
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleDropModalClose}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Modal>
          ) : null}

          <form>
            <GenericDialog
              fullwidth
              isOpen={isOpen}
              handleClose={() => {
                setIsOpen(false);
                notesFormik.handleReset();
                setButtonDisabled(false);
              }}
              disabledButton2={buttonDisabled}
              disabledButton1={buttonDisabled}
              dialogSettings={dialogSettings}
              handleSave={() => notesFormik.handleSubmit()}
            >
              <Grid container spacing={2} direction="column">
                <Grid item>
                  <DialogContentText>
                    <b>
                      Please confirm, if you would like to reschedule this job
                      for {moment(startDateStr).format('YYYY-MM-DD hh:mm A')} -{' '}
                      {moment(endDateStr).format('YYYY-MM-DD hh:mm A')}
                    </b>
                  </DialogContentText>
                </Grid>
                <Grid item>
                  <TextField
                    id="note_text"
                    name="note_text"
                    label="Note"
                    multiline
                    onChange={notesFormik.handleChange}
                    onBlur={notesFormik.handleBlur}
                    value={notesFormik?.values?.note_text}
                  />
                </Grid>
              </Grid>
            </GenericDialog>
          </form>
          <InstallerViewBlockCalendar
            calenderRefreshHandler={calenderRefreshHandler}
            setIsBlockTime={setIsBlockTime}
          />
        </div>
      )}
      <ConfirmDialog />
    </>
  );
};
export default CalenderContainer;
