import axios from 'axios';
import moment from 'moment';

import { CONFIG } from '../../../constants';
import apiService from '../../../services/api.service';
import { URL_CONSTANTS } from '../../../constants/urlConstants';

// Mobile app follows this same pattern to stay in sync
const getDocNameSuffix = () => {
  const docNameSuffix = new Date()
    .toISOString()
    .replace('T', '_')
    .replace('-', '_')
    .replace(/:/g, '_')
    .replace(/\..+/, '');
  return docNameSuffix;
};

//Additional charges API calls
export const getListOfDocuments = async (
  projectId,
  setDocuments,
  setLoading,
  documentTypeId
) => {
  try {
    setLoading(true);
    const requestDocuments = axios.get(
      `${CONFIG.API_BASE_URL}/projects/${projectId}/documents?dtype=${documentTypeId}`
    );
    const requestTemplates = axios.get(
      `${CONFIG.API_BASE_URL}/projects/${projectId}/templates`
    );

    const [responseDocuments, responseTemplates] = await Promise.all([
      requestDocuments,
      requestTemplates,
    ]);
    //mapping response
    const documentsData = responseDocuments?.data?.items
      ?.filter(doc => parseInt(doc.document_type_id) !== 2) // filter out template docs if any
      .map(document => {
        return {
          projectId: document.project_id,
          documentId: document.document_id,
          documetTypeId: document.document_type_id,
          documentName: document.document_name,
          documentUrl: document.aws_doc_url,
          docTemplateId: document.document_template?.template_id,
          documentThumbnail: `/thumbnail.PNG`,
          documentStoreType: document?.document_store_type || null,
          docCreatedDate: moment(document.created_at).format(
            'MM-DD-YYYY hh:mm A'
          ),
          docCreatedBy: document?.created_by_full_name || null,
          is_installer_visibility: document.is_installer_visibility,
          is_proj_docu_visibility: document.is_proj_docu_visibility,
          is_rel_proj_docu_visibility: document.is_rel_proj_docu_visibility,
          is_customer_visible: document?.is_customer_visible,
          is_signed: document?.is_signed,
          is_signature_required: document?.is_signature_required,
          category: document?.category,
          reviewedBy: document?.reviewedBy,
          reviewedAt: document?.reviewed_at,
          epa_lead_details: document?.epa_lead_details || null,
          is_source_uploaded: document?.is_source_uploaded || 0,
          is_highlight_document: document?.is_highlight_document || 0,
          is_signed_sms_sent: document?.is_signed_sms_sent,
          is_sms_loading: false,
        };
      });
    const templatesData = responseTemplates?.data?.items?.map(template => {
      return {
        projectId,
        documentId: template.template_id,
        documetTypeId: 2,
        templateName: template.template_name,
        documentName: template.template_file,
        documentUrl: template.template_aws_url,
        docTemplateId: template.template_id,
        documentThumbnail: `/thumbnail.PNG`,
        docCreatedDate: moment(template.created_at).format(
          'MM-DD-YYYY hh:mm A'
        ),
        epa_lead_details: template?.epa_lead_details || null,
        is_source_uploaded: template?.is_source_uploaded || 0,
        is_highlight_document: template?.is_highlight_document || 0,
      };
    });
    setDocuments([...documentsData, ...templatesData]);
  } catch (error) {
  } finally {
    setLoading(false);
  }
};

export const generateDocumentUrl = async (projectId, documentId) => {
  const body = {
    documentId,
  };
  try {
    const res = await axios.post(
      `${URL_CONSTANTS?.API?.BASE_URL}/projects/${projectId}/documents/generate-e-sign-url`,
      body
    );
    return res?.data || {};
  } catch (e) {
    console.error(
      'docs.service',
      'catetoryCreateService',
      e && e.message ? e.message : e
    );
    return {};
  }
};
export const getDocumentById = async (
  projectId,
  documentId,
  handleSetDocumentData,
  setAnnotationData,
  setLoading,
  setAlert,
  docType
) => {
  setLoading(true);
  try {
    let response = null;
    if (parseInt(docType) === 2) {
      response = await axios.get(
        `${CONFIG.API_BASE_URL}/documents/templates/${documentId}`
      );
    } else {
      response = await axios.get(
        `${CONFIG.API_BASE_URL}/projects/${projectId}/documents/${documentId}`
      );
    }
    if (response.data) {
      handleSetDocumentData(response?.data);
    } else {
      handleSetDocumentData(null);
    }

    if (response.data?.design_config_new) {
      setAnnotationData(response.data.design_config_new);
    } else {
      setAnnotationData({});
    }
  } catch (error) {
    setAlert('error', 'Failed to fetch Annotations', '');
  } finally {
    setLoading(false);
  }
};

export const updateDocumentById = async (
  projectId,
  documentId,
  documentName,
  type,
  documents,
  fileType,
  setDocuments,
  setLoading,
  setAlert,
  setReloadForm,
  isSigned = undefined
) => {
  setLoading(true);
  try {
    let response = null;
    response = await axios.patch(
      `${CONFIG.API_BASE_URL}/projects/${projectId}/documents/${documentId}`,
      {
        document_name: documentName || null,
        document_store_type: type || null,
        isSigned: isSigned,
      }
    );
    let docData = null;
    if (response?.data) {
      docData = {
        projectId: response?.data?.project_id,
        documentId: response?.data?.document_id,
        documetTypeId: response?.data?.document_type_id,
        documentName: response?.data?.document_name,
        documentUrl: response?.data?.aws_doc_url,
        docTemplateId: response?.data?.document_template?.template_id || null,
        documentThumbnail: `/thumbnail.PNG`,
        documentStoreType: response?.data?.document_store_type || null,
        docCreatedDate: moment(response?.data?.created_at).format(
          'MM-DD-YYYY hh:mm A'
        ),
        docCreatedBy: response?.data?.created_by_full_name || null,
        is_installer_visibility: response?.data?.is_installer_visibility,
        is_proj_docu_visibility: response?.data?.is_proj_docu_visibility,
        is_rel_proj_docu_visibility:
          response?.data?.is_rel_proj_docu_visibility,
      };
      const objIndex = documents.findIndex(
        obj => obj.documentId === documentId && obj.projectId === projectId
      );
      documents[objIndex] = docData;
      setDocuments(documents);
      setReloadForm(true);
      setAlert(
        'success',
        `Project ${fileType} status has been updated successfully.`,
        ''
      );
    }
  } catch (error) {
    setAlert('error', 'Failed to update store type.', '');
  } finally {
    setLoading(false);
  }
};

export const getProjectTemplateById = async (
  templateId,
  projectId,
  setLoading,
  setAlert,
  setDocumentData
) => {
  setLoading(true);
  try {
    const response = await axios.get(
      `${CONFIG.API_BASE_URL}/documents/templates/${templateId}/project/${projectId}/render`
    );
    if (response) {
      setDocumentData(response?.data);
      //loadPdf(response?.data?.template_aws_url);
    }
  } catch (error) {
    setAlert('error', 'Failed to fetch template', '');
  } finally {
    setLoading(false);
  }
};

//get document types
export const getDocumentTypes = async setdocumentTypes => {
  try {
    const response = await axios.get(
      `${CONFIG.API_BASE_URL}/system/document-types`
    );
    setdocumentTypes(response?.data);
  } catch (error) {}
};

export const createProjectDocumentFromTemplateDocument = async (
  projectId,
  fileBlob,
  fileName,
  setLoading,
  setAlert,
  history,
  storeType = '',
  hdDocNumber,
  llDocType,
  is_customer_visible,
  is_signature_required,
  documentId,
  uploadContextValue = {}
) => {
  try {
    setLoading(true);
    const formData = new FormData();
    var file = new File([fileBlob], `${fileName}_${getDocNameSuffix()}.pdf`, {
      type: 'application/pdf',
      lastModified: Date.now(),
    });

    formData.append('file', file);
    formData.append('document_name', `${fileName}_${getDocNameSuffix()}.pdf`);
    formData.append(
      'document_store_type',
      uploadContextValue?.document_store_type
    );
    formData.append('document_type_id', 1);
    !!hdDocNumber && formData.append('hd_doc_cd_number', hdDocNumber);
    !!llDocType && formData.append('ll_document_type', llDocType);

    formData.append('is_signature_required', is_signature_required);
    formData.append(
      'document_category_id',
      uploadContextValue?.document_category_id?.document_category_id
    );
    formData.append(
      'is_customer_visible',
      uploadContextValue?.is_customer_visible
    );
    formData.append(
      'is_installer_visibility',
      uploadContextValue?.is_installer_visibility
    );
    formData.append(
      'is_proj_docu_visibility',
      uploadContextValue?.is_proj_docu_visibility
    );
    formData.append('reviewed_status', uploadContextValue?.reviewed_status);
    formData.append('is_editable', uploadContextValue?.is_editable);

    !!documentId && formData.append('template_id', documentId);
    let headers = {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
    };

    const response = await axios.post(
      `${CONFIG.API_BASE_URL}/projects/${projectId}/documents`,
      formData,
      { headers: headers }
    );

    if (response?.data) {
      history.push(`/project/view/${projectId}/#tab=docs`);
      setAlert('success', 'Project Document saved successfully.', '');
    }
  } catch (error) {
    return Promise.reject('Error');
  } finally {
    setLoading(false);
  }
};

// eslint-disable-next-line prettier/prettier
export const updateProjectDocument = async (
  uploadContextValue = {},
  storeType,
  projectId,
  documentId,
  fileBlob,
  setLoading,
  setAlert,
  history,
  hdDocNumber,
  llDocType
) => {
  try {
    setLoading(true);
    const formData = new FormData();
    const uploadFileName = `${projectId}_${documentId}.pdf`;
    var file = new File([fileBlob], uploadFileName, {
      type: 'application/pdf',
      lastModified: Date.now(),
    });

    storeType && formData.append('document_store_type', storeType);

    formData.append('file', file);
    !!hdDocNumber && formData.append('hd_doc_cd_number', hdDocNumber);
    !!llDocType && formData.append('ll_document_type', llDocType);

    formData.append('is_editable', uploadContextValue?.is_editable);

    let headers = {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
    };

    const response = await axios.patch(
      `${CONFIG.API_BASE_URL}/projects/${projectId}/documents/${documentId}`,
      formData,
      { headers: headers }
    );

    if (response?.data) {
      setAlert('success', 'Project Document updated successfully', '');
      history.push(`/project/view/${projectId}/#tab=docs`);
    }
  } catch (error) {
    return Promise.reject('Error');
  } finally {
    setLoading(false);
  }
};

export const upLoadDocumentOrPhoto = async (
  projectId,
  uploadObj,
  setLoading,
  setAlert,
  setReloadList,
  setReloadForm,
  setDocOrPhotosLoader
) => {
  setLoading(true);
  try {
    const formData = new FormData();
    if (Array.isArray(uploadObj?.file)) {
      uploadObj?.file?.forEach(fileObj => {
        formData.append(`file`, fileObj?.file);
      });
    } else {
      formData.append('file', uploadObj?.file);
      formData.append('document_name', uploadObj?.document_name || null);
    }

    formData.append('document_type_id', uploadObj?.document_type_id || null);
    formData.append(
      'document_store_type',
      uploadObj?.document_store_type || null
    );
    !!uploadObj?.hd_doc_cd_number &&
      formData.append('hd_doc_cd_number', uploadObj?.hd_doc_cd_number || null);
    !!uploadObj?.ll_document_type &&
      formData.append('ll_document_type', uploadObj?.ll_document_type || null);

    formData.append(
      'document_category_id',
      uploadObj?.document_category_id?.document_category_id || null
    );

    formData.append(
      'is_customer_visible',
      uploadObj?.is_customer_visible || '0'
    );
    formData.append(
      'is_installer_visibility',
      uploadObj?.is_installer_visibility || '0'
    );
    formData.append(
      'is_proj_docu_visibility',
      uploadObj?.is_proj_docu_visibility || '0'
    );
    formData.append('reviewed_status', uploadObj?.reviewed_status || '0');
    formData.append('is_editable', uploadObj?.is_editable || '0');
    let headers = {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
    };
    const response = await axios.post(
      `${CONFIG.API_BASE_URL}/projects/${projectId}/documents`,
      formData,
      { headers: headers }
    );
    if (response?.data) {
      setReloadList(true);
      let successMessage;
      switch (uploadObj.document_type_id) {
        case 1:
          successMessage = 'Project document added successfully';
          break;
        case 2:
          successMessage = 'Template document added successfully';
          break;
        case 3:
          successMessage = 'Photo added successfully';
          break;
        default:
          successMessage = 'Document added successfully';
      }
      setAlert('success', successMessage, '');
    }
    setReloadForm(true);
  } catch (error) {
    setAlert('error', 'Unable to upload the file', ' please try again!');
  } finally {
    setLoading(false);
    if (typeof setDocOrPhotosLoader === 'function') {
      setDocOrPhotosLoader(false);
    }
  }
};

export const applyInstallerVisibilityDocuments = async (
  projectId,
  type,
  installerVisibleDocuments,
  setLoading,
  setAlert,
  setReloadList,
  tabPhotosAddPermission
) => {
  try {
    setLoading(true);

    const response = await axios.post(
      `${CONFIG.API_BASE_URL}/projects/${projectId}/documents/settings?type=${type}`,
      installerVisibleDocuments
    );

    if (response?.data) {
      setReloadList(true);
      if (tabPhotosAddPermission) {
        setAlert('success', 'Project photo saved successfully.', '');
      } else {
        setAlert('success', 'Project document saved successfully.', '');
      }
    }
  } catch (error) {
    return Promise.reject('Error');
  } finally {
    setLoading(false);
  }
};

export const getRelatedDocuments = async (
  projectId,
  setDocuments,
  setLoading,
  documentTypeId
) => {
  try {
    setLoading(true);
    const response = await axios.get(
      `${CONFIG.API_BASE_URL}/projects/${projectId}/documents?dtype=${documentTypeId}&type=related`
    );
    //mapping response
    const documentsData = response?.data?.items?.map(document => {
      return {
        projectId: document.project_id,
        documentId: document.document_id,
        documetTypeId: document.document_type_id,
        documentName: document.document_name,
        documentUrl: document.aws_doc_url,
        docTemplateId: document.document_template?.template_id,
        documentThumbnail: `/thumbnail.PNG`,
        docCreatedDate: moment(document.created_at).format(
          'MM-DD-YYYY hh:mm A'
        ),
        is_installer_visibility: document.is_installer_visibility,
        is_proj_docu_visibility: document.is_proj_docu_visibility,
        is_rel_proj_docu_visibility: document.is_rel_proj_docu_visibility,
      };
    });
    setDocuments(documentsData);
  } catch (error) {
  } finally {
    setLoading(false);
  }
};

//Get Documents from Source
export const getFromSource = async (
  projectId,
  setLoading,
  setAlert,
  setReloadList
) => {
  try {
    setLoading(true);
    const response = await axios.get(
      `${CONFIG.API_BASE_URL}/projects/${projectId}/documents/get-from-source`
    );
    if (response) {
      setReloadList(true);
      setAlert(
        'success',
        'Documents will be fetched from the source in sometime, please check it again after a few minutes.',
        ''
      );
    }
  } catch (error) {
    setAlert('error', 'Error while fetching documents from the Source.', '');
    setLoading(false);
  }
};

// load template annotation data using template id
export const getDocumentAnnotations = async (
  documentId,
  projectId,
  pdfUrl,
  setAnnotationData,
  setLoading,
  setAlert
) => {
  setLoading(true);
  try {
    let response = null;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        client_id: `${localStorage.getItem('client_id')}`,
      },
    };
    response = await axios.post(
      `${URL_CONSTANTS.DOCUMENT.baseUrl}/pdf/${documentId}`,
      {
        token: localStorage.getItem('cubejsAuthorizationToken'),
        projectId: projectId,
        clientId: localStorage.getItem('client_id'),
        documentUrl: pdfUrl,
      },
      config
    );
    if (response?.data?.data?.psPdfData)
      setAnnotationData(response.data.data.psPdfData);
    else setAnnotationData({});
  } catch (error) {
    setAlert('error', 'No Annotations Found', '');
    setAnnotationData({});
  } finally {
    setLoading(false);
  }
};

// get processed document
export const getProcessedDocument = async (
  documentId,
  projectId,
  pdfUrl,
  setAnnotatedPdf,
  setLoading,
  setAlert
) => {
  setLoading(true);
  try {
    let response = null;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        client_id: `${localStorage.getItem('client_id')}`,
      },
    };
    response = await axios.post(
      `${URL_CONSTANTS.DOCUMENT.baseUrl}/pdf/${documentId}/generate`,
      {
        token: localStorage.getItem('cubejsAuthorizationToken'),
        projectId: projectId,
        clientId: localStorage.getItem('client_id'),
        documentUrl: pdfUrl,
      },
      config
    );
    if (response?.data?.status) {
      setAlert('info', 'PDF generated', '');
    } else {
      setAlert('error', 'Unable to generate ', '');
    }
  } catch (error) {
    setAlert('error', 'Unable to load pdf annotations');
  } finally {
    setLoading(false);
  }
};

export const getProjectConfigDetails = async projectId => {
  const projectConfigDetails = await apiService.get(
    `/projects/${projectId}/config-details`
  );
  return projectConfigDetails;
};

export const getProjectMaterials = async projectId => {
  const projectConfigDetails = await apiService.get(
    `/projects/${projectId}/materials`
  );
  return projectConfigDetails;
};

export const copyRelatedDocuments = async paload => {
  const copyDocuments = await apiService.post(
    `/projects/${paload?.copyFrom}/documents/copy-related-documents`,
    paload
  );
  return copyDocuments;
};

export const deleteProjectDocument = async (projectId, documentId, type) => {
  const deleteStatus = await apiService.delete(
    `/projects/${projectId}/documents/${documentId}/${type || ''}`
  );
  return deleteStatus;
};

export const categoryListService = async search => {
  try {
    const res = await axios.get(
      `${URL_CONSTANTS?.API?.BASE_URL}/projects/document-category/get-document-category${search ? `?search=${search}` : ''}`
    );
    return res?.data || {};
  } catch (e) {
    console.error(
      'docs.service',
      'catetoryListService',
      e && e.message ? e.message : e
    );
    return {};
  }
};

export const categoryCreateService = async body => {
  try {
    const res = await axios.post(
      `${URL_CONSTANTS?.API?.BASE_URL}/projects/document-category/create-document-category`,
      body
    );
    return res?.data || {};
  } catch (e) {
    console.error(
      'docs.service',
      'catetoryCreateService',
      e && e.message ? e.message : e
    );
    return {};
  }
};

export const bulkUpdateService = async (project_id, body) => {
  try {
    const res = await axios.patch(
      `${URL_CONSTANTS?.API?.BASE_URL}/projects/${project_id}/documents/bulk-update`,
      body
    );
    return res?.data || {};
  } catch (e) {
    console.error(
      'docs.service',
      'catetoryCreateService',
      e && e.message ? e.message : e
    );
    return {};
  }
};
