import React from 'react';
import PFDropdown from '../../../shared/PFPrime/PFDropdown';
import PFFormikError from '../../../shared/PFPrime/PFFormikError';
const DiscountTypeEditor = ({
  rowData,
  options,
  editingRows,
  submitAction,
  onBlurDiscountType,
  retailerPercentage,
}) => {
  const { values, touched, errors, setFieldValue, setValues } = submitAction;
  const isEditing = editingRows[rowData.rowIndex];
  return isEditing ? (
    <div className="vertical-align-top">
      <span className="p-float-label">
        <PFDropdown
          options={[
            { label: 'Amount', value: 'Amount' },
            { label: 'Percent', value: 'Percentage' },
          ]}
          optionLabel="label"
          optionValue="value"
          name="discountType"
          filter={false}
          value={values.discountType || ''}
          onChange={(name, value) => {
            onBlurDiscountType(value, values, setValues, retailerPercentage);
          }}
          className={`${
            touched?.discountType && errors?.discountType
              ? 'error p-inputtext-sm quote-items'
              : 'p-inputtext-sm quote-items'
          }`}
        />
      </span>
      <PFFormikError touched={touched} errors={errors} field="discountType" />
    </div>
  ) : (
    <div className="pt-2">{`${rowData?.discountType === 'Amount' ? 'Amount' : 'Percent'}`}</div>
  );
};

export default DiscountTypeEditor;
