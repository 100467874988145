// **** React Imports ****
import React, { useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

// **** External Utilities ****
import { Grid, IconButton, Button } from '@material-ui/core';
import moment from 'moment';

// **** Custom Components ****
import {
  Visibility as VisibilityIcon,
  Edit as EditIcon,
  FileCopy as FileCopyIcon,
  Delete as DeleteIcon,
  Remove as RemoveIcon,
  Add as AddIcon,
  PostAdd as EditDashboardIcon,
} from '@material-ui/icons';

import PageHeader from '../../shared/PageHeader/PageHeader';
import GenericDataTable from '../../shared/GenericDataTable/GenericDataTable';
import { Alert } from '../../shared/Alerts/Alert';
import GenericConfirmationDialog from '../../shared/GenericConfirmationDialog/GenericConfirmationDialog';

// **** Styles/Images/Icons ****

// **** Services *****

import { useAlerts } from '../../shared/Alerts/alertsService';
import { checkPermission } from '../../../utils/Helpers';
import permissions from '../../../config/permissions';
import CheckboxList from '../../shared/CheckboxList/checkboxList';
import TableColumnsLoader from '../../shared/Loader/tableColumnsLoader';

import { getDashboardList, deleteDashboard } from './Dashboards.service';
import { useStyles } from './DashboardList.styles';
import CreateDashboardDialog from './CreateDashboardDialog';

const schedulerBreadcrumb = [
  {
    text: 'Admin',
  },
  {
    link: '/admin/dashboards',
    text: 'Dashboards',
  },
];

function handleRowHover(event, row, rowIndex) {
  let control = document.getElementById(rowIndex);
  if (control.style.display) control.style.display = 'block';
}

function handleRowHoverLeave(event, row, rowIndex) {
  let control = document.getElementById(rowIndex);
  if (control?.style?.display) control.style.display = 'none';
}

const DashboardList = ({
  source,
  setHomeLoading,
  dashboardData,
  linkDashboard,
  detachDashboard,
  selectedItems,
  setSelectedItems,
}) => {
  const detachDashboardPermission = checkPermission(
    permissions?.home?.detachDashboard
  );
  const attachDashboardPermission = checkPermission(
    permissions?.home?.attachDashboard
  );

  const [loading, setLoading] = React.useState(true);
  const [searchQuery, setSearchQuery] = React.useState({
    limit: 100,
    offset: 0,
  });
  const [dashboardListData, setDashboardListData] = React.useState([]);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const history = useHistory();
  const [confirmDialog, setConfirmDialog] = React.useState({
    header: '',
    title: '',
    subtitle: '',
    isOpen: false,
  });
  const [rowIndex, setRowIndex] = React.useState();
  // To show the message
  const { alert, setAlert, clearAlert } = useAlerts();
  const [isClone, setIsClone] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [reloadList, setReloadList] = React.useState(false);

  const [searchTerm, setSearchTerm] = useState('');
  const [filterBy, setFilterBy] = useState({ key: '', value: '' });
  const [sortBy, setSortBy] = useState('');
  const [sortOrder, setSortOrder] = useState('null');

  const handleCheckboxChange = item => {
    if (selectedItems.some(i => i.name === item.name)) {
      setSelectedItems(selectedItems.filter(i => i.name !== item.name));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  const handleSearch = term => setSearchTerm(term);
  const handleFilter = filter => setFilterBy(filter);
  const handleSort = sortKey => setSortBy(sortKey);

  const columns = [
    {
      key: 'name',
      label: 'Name',
      showLabel: true,
      isTitle: true,
      isSubTitle: false,
      bold: false,
      italic: false,
    },
    {
      key: 'description',
      label: 'Description',
      showLabel: false,
      isTitle: false,
      isSubTitle: true,
      bold: false,
      italic: false,
    },
    {
      key: 'created_by',
      label: 'Created By',
      showLabel: false,
      isTitle: false,
      isSubTitle: false,
      bold: true,
      italic: false,
    },
    {
      key: 'created_at',
      label: 'Date Created',
      showLabel: false,
      isTitle: false,
      isSubTitle: false,
      bold: false,
      italic: true,
    },
  ];

  const handleSubmit = ({ searchTerm, filterBy, sortBy, sortOrder }) => {
    setSearchTerm(searchTerm);
    setFilterBy(filterBy);
    setSortBy(sortBy);
    setSortOrder(sortOrder);
  };

  React.useEffect(() => {
    clearAlert();
  }, []);

  React.useEffect(() => {
    getDashboardList(searchQuery, setLoading, setDashboardListData, source);
    setReloadList(false);
  }, [searchQuery, reloadList]); /*
  if (source === 'home') {
    !dashboardListData?.items?.length ? setHomeLoading(true) : setHomeLoading(false);
  } */

  const classes = useStyles();
  const handleDelete = dataIndex => {
    setRowIndex(dataIndex);
    setConfirmDialog({
      ...confirmDialog,
      isOpen: true,
      title:
        'Are you sure you want to delete the Dashboard? You will not be able to recover this again?',
      header: 'Delete Dashboard',
    });
  };
  const onConfirmDialog = () => {
    deleteDashboard(
      dashboardListData?.items[rowIndex].dashboard_id,
      searchQuery,
      setLoading,
      setConfirmDialog,
      setDashboardListData,
      setAlert
    );
  };
  const handleClone = dataIndex => {
    setRowIndex(dataIndex);
    setDialogOpen(true);
    setIsClone(true);
  };
  const handleEdit = dataIndex => {
    setRowIndex(dataIndex);
    setDialogOpen(true);
    setIsEdit(true);
  };
  let dataTableColumns = [
    {
      name: '',
      options: {
        sort: false,
        filter: false,
        viewColumns: false,

        setCellProps: () => ({
          style: { minWidth: '180px', maxWidth: '180px' },
        }),
        ...(!loading
          ? {
              customBodyRenderLite: dataIndex => {
                return (
                  <span style={{ display: 'none' }} id={dataIndex}>
                    {checkPermission(
                      permissions?.dashboardManagement?.menu
                    ) && (
                      <span>
                        <IconButton classes={{ root: classes.actionIcons }}>
                          <VisibilityIcon
                            onClick={() =>
                              history.push(
                                `/admin/dashboard/view/${dashboardListData?.items[dataIndex].dashboard_id}`
                              )
                            }
                          />
                        </IconButton>
                      </span>
                    )}
                    {checkPermission(
                      permissions?.dashboardManagement?.editDashboard
                    ) && (
                      <span>
                        <IconButton classes={{ root: classes.actionIcons }}>
                          <EditIcon
                            onClick={() =>
                              history.push(
                                `/admin/dashboard/edit/${dashboardListData?.items[dataIndex].dashboard_id}?list=1`
                              )
                            }
                          />
                        </IconButton>
                      </span>
                    )}
                    {checkPermission(
                      permissions?.dashboardManagement?.copyDashboard
                    ) && (
                      <span>
                        <IconButton classes={{ root: classes.actionIcons }}>
                          <EditDashboardIcon
                            onClick={() => handleEdit(dataIndex)}
                          />
                        </IconButton>
                      </span>
                    )}
                    {checkPermission(
                      permissions?.dashboardManagement?.copyDashboard
                    ) && (
                      <span>
                        <IconButton classes={{ root: classes.actionIcons }}>
                          <FileCopyIcon
                            onClick={() => handleClone(dataIndex)}
                          />
                        </IconButton>
                      </span>
                    )}

                    {checkPermission(
                      permissions?.dashboardManagement?.deleteDashboard
                    ) && (
                      <span>
                        <IconButton classes={{ root: classes.actionIcons }}>
                          <DeleteIcon onClick={() => handleDelete(dataIndex)} />
                        </IconButton>
                      </span>
                    )}
                  </span>
                );
              },
            }
          : {}),
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: { setCellProps: () => ({ style: { width: '20%' } }) },
    },
    {
      name: 'description',
      label: 'Description',
      options: { setCellProps: () => ({ style: { width: '35%' } }) },
    },
    {
      name: 'created_by',
      label: 'Created By',
      options: { setCellProps: () => ({ style: { width: '12%' } }) },
    },
    {
      name: 'created_at',
      label: 'Date Created',
      options: { setCellProps: () => ({ style: { width: '20%' } }) },
    },
  ];

  const tableLoader = useMemo(() => {
    return TableColumnsLoader(dataTableColumns, {
      rows: searchQuery?.limit,
      isValue: false,
    });
  }, [dataTableColumns]);

  if (source === 'home') {
    dataTableColumns.unshift({
      name: 'icons',
      label: 'Add/Remove',
      options: { setCellProps: () => ({ style: { width: '8%' } }) },
    });
  }

  let dataArray = dashboardListData?.items?.map((val, index) => {
    return {
      icons: dashboardData?.filter(
        value => value?.dashboard_id === val?.dashboard_id
      )?.length ? (
        <Grid
          container
          justifyContent="center"
          className="mui-remove-dashboard"
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setLoading(true);
              detachDashboard(val?.dashboard_id);
            }}
            disabled={!detachDashboardPermission}
            endIcon={<RemoveIcon />}
            classes={{ root: classes.actionRemoveButton }}
          >
            Remove
          </Button>
        </Grid>
      ) : (
        <Grid container justifyContent="center" className="mui-add-dashboard">
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setLoading(true);
              linkDashboard(val?.dashboard_id);
            }}
            disabled={!attachDashboardPermission}
            endIcon={<AddIcon />}
            classes={{ root: classes.actionAddButton }}
          >
            Add
          </Button>
        </Grid>
      ),
      created_at: val.created_at
        ? moment(val?.created_at).format('MM-DD-YYYY hh:mm A')
        : null,
      name: val.name,
      description: val.description,
      created_by: val.created_by,
      id: val.dashboard_id,
    };
  });

  let intervalVal = '';
  const handleSearchQuery = searchInputVal => {
    const searchString = searchInputVal?.trim();

    /** Timeout will help to let multiple characters in the TextInput,* and API call would be optimized to get Values for Input of few chars at once,* rather than for every single character*/
    try {
      clearTimeout(intervalVal);
    } catch (err) {
      console.log(err);
    }
    intervalVal = setTimeout(() => {
      setLoading(true);
      setSearchQuery(prevState => ({
        ...prevState,
        offset: 0,
        search_query: searchString,
      }));
    }, 500);
  };
  const viewPermission = checkPermission(
    permissions?.dashboardManagement?.menu
  );
  const dataTableOptions = {
    download: false,
    print: false,
    filter: false,
    selectableRows: false,
    selectToolbarPlacement: 'none',
    viewColumns: false,
    responsive: 'standard',
    tableBodyMinHeight: '300px',
    count: dashboardListData?.count,
    serverSide: true,
    textLabels: {
      body: {
        noMatch: !loading && !dataArray?.length && 'No records found',
      },
    },
    setRowProps: (row, dataIndex, rowIndex) => {
      return {
        onMouseEnter: e => {
          !source && handleRowHover(e, row, rowIndex);
        },
        onMouseLeave: e => !source && handleRowHoverLeave(e, row, rowIndex),
        ...(viewPermission
          ? {
              onDoubleClick: () => {
                !source &&
                  history.push(
                    `/admin/dashboard/view/${
                      dashboardListData?.items.length &&
                      dashboardListData?.items[rowIndex].dashboard_id
                    }?list=1`
                  );
              },
            }
          : {}),
      };
    },
    onChangeRowsPerPage: numberOfRows => {
      setLoading(true);
      setSearchQuery(prevState => ({
        ...prevState,
        limit: numberOfRows,
        offset: 0,
      }));
    },

    onChangePage: currentPage => {
      setLoading(true);
      setSearchQuery(prevState => ({
        ...prevState,
        offset: currentPage * searchQuery.limit,
      }));
    },
    onColumnSortChange: (changedColumn, direction) => {
      setLoading(true);
      setSearchQuery(prevState => ({
        ...prevState,
        sortKey: changedColumn,
        sortDirection: direction,
      }));
    },

    rowsPerPage: searchQuery.limit,
    rowsPerPageOptions: [10, 20, 50, 100],
    onSearchChange: searchText => {
      handleSearchQuery(searchText);
    },
    pagination: !loading,
  };

  return (
    <Grid container spacing={2} direction="column">
      {!dialogOpen && alert.exists && (
        <Grid item>
          {' '}
          <Alert />
        </Grid>
      )}
      {!source && (
        <Grid
          container
          item
          justifyContent="space-between"
          direction="row"
          alignItems="center"
        >
          <Grid item>
            <PageHeader
              pageTitle="Dashboards"
              breadCrumbArray={schedulerBreadcrumb}
            />
          </Grid>

          {checkPermission(
            permissions?.dashboardManagement?.createDashboard
          ) && (
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={() => setDialogOpen(true)}
              >
                Add New
              </Button>
              <CreateDashboardDialog
                dialogOpen={dialogOpen}
                setDialogOpen={setDialogOpen}
                cloneValues={
                  dashboardListData?.items?.length &&
                  dashboardListData?.items[rowIndex]
                }
                isClone={isClone}
                isEdit={isEdit}
                setIsClone={setIsClone}
                setIsEdit={setIsEdit}
                setReloadList={setReloadList}
                setAlert={setAlert}
              />
            </Grid>
          )}
        </Grid>
      )}
      <Grid item classes={{ root: classes.dataTableWrapper }}>
        {source !== 'home' && (
          <GenericDataTable
            columns={dataTableColumns}
            data={loading ? tableLoader : dataArray}
            options={dataTableOptions}
          />
        )}
        {source === 'home' && (
          <CheckboxList
            columns={columns}
            data={dataArray}
            selectedItems={selectedItems}
            onCheckboxChange={handleCheckboxChange}
            onSearch={handleSearch}
            onFilter={handleFilter}
            onSort={handleSort}
            searchTerm={searchTerm}
            filterBy={filterBy}
            sortBy={sortBy}
            sortOrder={sortOrder}
            onSubmit={handleSubmit}
            selectedBackground="primary" // You can change this to any color you want
            unselectedBackground="white"
            loading={loading} // You can change this to any color you want
          />
        )}
      </Grid>
      {/* Confirmation dialog for delete */}
      <GenericConfirmationDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        onConfirmDialog={onConfirmDialog}
      />
    </Grid>
  );
};

export default DashboardList;
