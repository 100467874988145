import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { useParams } from 'react-router';

import PFDataTable from '../../../shared/PFPrime/PFDataTable';
import usePFDataTable from '../../../../hooks/PFDatatable';
import PFButton from '../../../shared/PFPrime/PFButton';
import { quoteGridColumns, quoteGridData } from '../helpers/QuoteListing';
import PFTableLoader from '../../../shared/Loader/PFTableLoader';

const QuoteListing = (
  isGlobalLevel,
  showOldQuotes = false,
  quoteId = 0,
  mainQuoteId = 0
) => {
  const history = useHistory();
  const toast = useRef(null);
  const {
    layout: TableLayout,
    columns: DataTableColumns,
    totalRecords,
    data: tableData,
    rows,
    page,
    sortBy,
    orderBy,
    setDefaultTableProps,
  } = usePFDataTable();

  useEffect(() => {
    setDefaultTableProps(pre => ({
      ...pre,
      tableStyle: { minWidth: '100rem' },
    }));
    DataTableColumns(quoteGridColumns(isGlobalLevel, showOldQuotes));
  }, []);

  const [loading, setLoading] = useState(false);
  const { customerId, opportunityId } = useParams();

  const quoteList = () => {
    setLoading(true);
    quoteGridData(
      customerId,
      opportunityId,
      rows,
      page,
      sortBy,
      orderBy,
      isGlobalLevel,
      showOldQuotes,
      quoteId,
      mainQuoteId
    )
      .then(res => {
        if (res?.status === 200) {
          tableData(res?.data?.data?.result);
          totalRecords(res?.data?.data?.count);
          setLoading(false);
        }
      })
      .catch(error => {
        if (error?.response?.status === 404 && history) {
          return history.push('/page-not-found');
        }
      });
  };

  useEffect(() => {
    quoteList();
  }, [rows, page, sortBy, orderBy, mainQuoteId]);

  useEffect(() => {
    return () => {
      tableData([]);
      totalRecords(0);
    };
  }, []);

  const quoteListLayout = props => (
    <div>
      <Toast ref={toast}></Toast>
      <div className="grid flex">
        <div className="col-12 md:col-6 lg:col-6">
          {!props?.isGlobalLevel && (
            <div className="w-full flex justify-content-start pt-2">
              <span className="text-1xl font-bold">
                <h4>Quotes</h4>
              </span>
            </div>
          )}
        </div>
        <div className="col-12 md:col-6 lg:col-6">
          <div className="w-full flex justify-content-end">
            <PFButton
              icon="pi pi-refresh"
              outlined={true}
              label="Refresh"
              className="mr-2"
              onClick={quoteList}
            />
            {!props?.isGlobalLevel && (
              <PFButton
                label="Create"
                onClick={() =>
                  history.push(
                    `/crm/${customerId}/${opportunityId}/quote/create`
                  )
                }
                icon="pi pi-plus"
              />
            )}
          </div>
        </div>
      </div>
      {loading ? <PFTableLoader /> : <TableLayout />}
    </div>
  );

  return {
    quoteListLayout: quoteListLayout,
    apiCallQuoteList: quoteList,
  };
};

export default QuoteListing;
