import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Card } from 'primereact/card';
import { Toast } from 'primereact/toast';

import PFButton from '../../../shared/PFPrime/PFButton';
import PFPickList from '../../../shared/PFPrime/PFPickList';
import PageHeader from '../../../shared/PageHeader/PageHeader';
import {
  getClientData,
  multiPartUpdateTenantData,
} from '../service/TenantConfig.service';
import BlockLoader from '../../../shared/Loader/BlockLoader';

const RelatedPOs = () => {
  const { id: client_id = localStorage.getItem('client_id') } = useParams();
  const toast = useRef(null);
  const [source, setSource] = useState([]);
  const [target, setTarget] = useState([]);
  const [isDisable, setIsDisable] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const columns = [
    {
      field: 'number_of_addcharge',
      header: '# of Exceptions',
    },
    {
      field: 'pr_number',
      header: '#of PR Numbers',
    },
    {
      field: 'project_type.project_type',
      header: 'Type',
    },
    {
      field: 'project_category.category',
      header: 'Category',
    },
    {
      field: 'date_sold',
      header: 'Date Sold',
    },
    {
      field: 'technician_name',
      header: 'Technician Name',
    },
    {
      field: 'scheduled_date_time',
      header: 'Schedule Date and Time',
    },
    {
      field: 'completion_date',
      header: 'Completion Date',
    },
    {
      field: 'project_number',
      header: 'Project#',
    },
    {
      field: 'po_number',
      header: 'PO#',
    },
    {
      field: 'project_status',
      header: 'Status',
    },
    {
      field: 'source_status',
      header: 'Source Status',
    },
    {
      field: 'soruce_portal_url',
      header: 'Source portal URL',
    },
    {
      field: 'check_date',
      header: 'Check Date',
    },
    {
      field: 'check_number',
      header: 'Check Number',
    },
    {
      field: 'check_amount',
      header: 'Check Amount',
    },
    {
      field: 'total_sale_amount',
      header: 'Total Sale Amount',
    },
    {
      field: 'total_labor_amount',
      header: 'Total Labor Amount',
    },
    {
      field: 'installation_address',
      header: 'Install Street',
    },
  ];

  const sourceItems = [
    {
      field: 'total_labor_amount',
      header: 'Total Labor Amount',
    },
    {
      field: 'installation_address',
      header: 'Install Street',
    },
  ];

  const accessBreadcrumb = [
    {
      text: 'Admin',
    },
    {
      link: '/admin/configuration',
      text: 'Company Configurations',
    },
    {
      text: `Related POs`,
    },
  ];

  const fetchData = async client_id => {
    try {
      const response = await getClientData(client_id);
      if (response?.data?.client?.related_po_columns?.length > 0) {
        const sourceData = getNonSimilarData(
          columns,
          response?.data?.client?.related_po_columns
        );

        setSource(sourceData);
        setTarget(response?.data?.client?.related_po_columns);
      } else {
        const targetData = getNonSimilarData(columns, sourceItems);
        setTarget(targetData);
        setSource(sourceItems);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getNonSimilarData = (colData, targetData) => {
    const nonMatchingData = colData?.filter(
      item =>
        !targetData.some(
          targetItem => targetItem && targetItem?.field === item?.field
        )
    );

    return nonMatchingData;
  };

  useEffect(() => {
    if (client_id) {
      fetchData(client_id);
    }
  }, [client_id]);

  const handleSubmit = async () => {
    setIsDisable(true);
    setIsLoading(true);
    try {
      const payload = {
        client_id: client_id,
        related_po_columns: target,
      };
      const apiResponse = await multiPartUpdateTenantData(payload, true);
      if (apiResponse?.data?.status) {
        toast.current.show({
          severity: 'success',
          summary: 'Record Updated Successfully',
          life: 1500,
        });
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Error occurred while Updating record',
          life: 1500,
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 422) {
        toast.current.show({
          severity: 'error',
          detail: 'Something Went Wrong',
          life: 3000,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onChange = event => {
    setSource(event.source);
    setTarget(event.target);
    setIsDisable(false);
  };

  return (
    <div className="w-full">
      <PageHeader
        pageTitle={`Related POs`}
        breadCrumbArray={accessBreadcrumb}
      />
      <Card className="mt-4" pt={{ body: { className: 'pt-0' } }}>
        <Toast ref={toast}></Toast>
        <form>
          <div className="col-12 flex flex-wrap justify-content-end">
            <div className="col-12 md:col-2 lg:col-2">
              <PFButton
                onClick={handleSubmit}
                className="w-full"
                label="Save"
                disabled={isDisable || target?.length === 0}
              ></PFButton>
            </div>
          </div>
          <>
            <div className="col-12">
              {isLoading && <BlockLoader />}
              <PFPickList
                source={source}
                target={target}
                onChange={onChange}
                itemTemplate={item => <div>{item?.header}</div>}
                filter={true}
                filterBy="header"
                dataKey="field"
                breakpoint="1280px"
                sourceHeader="Source"
                targetHeader="Target"
                sourceStyle={{ height: '24rem' }}
                targetStyle={{ height: '24rem' }}
                sourceFilterPlaceholder="Search by name"
                targetFilterPlaceholder="Search by name"
              />
            </div>
          </>
        </form>
      </Card>
    </div>
  );
};
export default RelatedPOs;
