import React, { useState, useEffect, useRef, useMemo } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Card } from 'primereact/card';
import { OverlayPanel } from 'primereact/overlaypanel';
import { InputSwitch } from 'primereact/inputswitch';
import { useHistory } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { Tooltip } from 'primereact/tooltip';
import { Paginator } from 'primereact/paginator';

import TableLoader from '../shared/Loader/TableLoader';
import { routes } from '../../config/routes';
import PageHeader from '../shared/PageHeader/PageHeader';
import TableColumnsLoader from '../shared/Loader/tableColumnsLoader';

import DescriptionDialog from './DescriptionDialog';
import {
  getPageMetaUpdateTable,
  putEditPageMetaInfo,
} from './services/PageMeta.services';
import EditPageMeta from './EditPageMeta';

const PageMetaUpdate = () => {
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [editDialogVisible, setEditDialogVisible] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [isIconStyleOpen, setIconStyleOpen] = useState(false);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [update, setUpdate] = useState(true);
  const [serialNumber, setSerialNumber] = useState(1);
  const [overlayContent, setOverlayContent] = useState('');
  const [dialogVisible, setDialogVisible] = useState(false);
  const [dialogHeader, setDialogHeader] = useState('');
  const [descriptionDialogVisible, setDescriptionDialogVisible] =
    useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [buttonTooltip, setButtonTooltip] = useState();
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const overlayPanelRef = useRef(null);
  const history = useHistory();

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await getPageMetaUpdateTable(first, rows);
      setData(
        response?.data?.rows?.map(item => ({
          page_identifier: item.page_identifier,
          title: item.title,
          header: item.title,
          description: item.description,
          status: item.status,
        }))
      );
      setTotalRecords(response?.data?.count);
      setLoading(false);
    } catch (error) {
      setError('Error fetching data from the API');
      setLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const onPageChange = event => {
    const offset = event.page * event.rows;
    const limit = event.rows;
    setFirst(offset);
    setRows(limit);
    setUpdate(true);
  };

  useEffect(() => {
    if (update === true) {
      fetchData();
      setUpdate(false);
    }
  }, [update, first, rows]);

  const breadcrumbItems = [{ label: 'User Guide' }];
  const breadcrumbHome = { label: 'Admin' };

  const formik = useFormik({
    initialValues: {
      Identifier: '',
      header: '',
      description: '',
      status: '',
    },
  });

  const handleClose = () => {
    setEditDialogVisible(false);
    setSelectedRowData(null);
  };

  const editPageMetaDialog = rowData => {
    setEditDialogVisible(true);
    setSelectedRowData(rowData);
  };

  const onSaveEdit = (rowData, updatedData) => {
    setUpdate(prev => !prev);
    setEditDialogVisible(false);
    setSelectedRowData(null);
  };

  const handleIconClick = () => {
    setIconStyleOpen(!isIconStyleOpen);
  };

  const itemTemplate = rowData => {
    if (rowData?.page_identifier) {
      return rowData?.page_identifier;
    } else {
      return null;
    }
  };

  const headerTemplate = rowData => {
    if (rowData?.header) {
      return rowData?.header;
    } else {
      return null;
    }
  };

  const moduleTemplate = rowData => {
    if (rowData?.module) {
      return rowData?.module;
    } else {
      return null;
    }
  };

  // Function to URL decode a string
  const urlDecode = str => {
    return decodeURI(str);
  };

  const handleCloseDialog = () => {
    setDialogVisible(false);
  };

  const descriptionTemplate = (rowData, column) => {
    const decodedHtml = urlDecode(rowData?.description);
    setOverlayContent(decodedHtml);
    setDialogHeader(rowData?.header || '');
    setDialogVisible(true);
  };

  const statusTemplate = (rowData, column) => {
    return (
      <div>
        <InputSwitch
          aria-label="Filter"
          checked={rowData.status}
          onChange={async e => {
            try {
              if (!rowData || !rowData.page_identifier) {
                console.error('Invalid rowData:', rowData);
                return;
              }
              const updatedData = {
                page_identifier: rowData.page_identifier,
                title: rowData.title,
                status: e.value,
              };

              const resp = await putEditPageMetaInfo(updatedData);
              if (resp?.data?.status === true) {
                setUpdate(true);
              }
            } catch (error) {
              console.error('Error updating status:', error);
            }
          }}
        />
      </div>
    );
  };

  const actionTemplate = (rowData, column) => {
    const identifier = rowData?.page_identifier;
    const route = routes?.find(route => route?.identifier === identifier);
    const path = route?.path;

    const showTooltip = path && path.includes('/:');

    return (
      <>
        <Tooltip target={`.custom-tooltip-${identifier}`} />
        <Button
          icon="pi pi-eye"
          text
          aria-label="Filter"
          className="p-1 w-2rem"
          onClick={() => descriptionTemplate(rowData, column)}
        />

        <Button
          icon="pi pi-pencil"
          text
          aria-label="Filter"
          className="p-1 w-2rem"
          onClick={() => editPageMetaDialog(rowData)}
        />

        {showTooltip ? (
          <Button
            icon="pi pi-arrow-up-right"
            text
            className="p-1 w-2rem "
            tooltip="Editing pages cannot be redirected"
            tooltipOptions={{ position: 'bottom' }}
          />
        ) : (
          <Button
            icon="pi pi-arrow-up-right"
            text
            className="p-1 w-2rem "
            onClick={() => {
              try {
                if (route) {
                  if (path && !path.includes('/:')) {
                    history.push(path);
                  }
                } else {
                  console.error(`Invalid identifier: ${identifier}`);
                }
              } catch (error) {
                console.error(error);
              }
            }}
          />
        )}
      </>
    );
  };

  const columns = [
    {
      header: '',
      ...(!isLoading
        ? {
            body: actionTemplate,
          }
        : {}),

      style: { width: '130px' },
    },

    {
      header: 'Page Title',
      ...(!isLoading
        ? {
            body: headerTemplate,
          }
        : {}),
      field: 'title',
      sortable: true,
      filter: true,
    },
    {
      header: 'Module',
      ...(!isLoading
        ? {
            body: moduleTemplate,
          }
        : {}),
      field: 'Module',
      sortable: true,
      filter: true,
      style: { width: '300px' },
    },
    {
      header: 'Status',
      field: 'Status',
      ...(!isLoading
        ? {
            body: statusTemplate,
          }
        : {}),
      style: { width: '120px' },
    },
  ];

  const tableLoader = useMemo(() => {
    return TableColumnsLoader(columns, {
      rows: rows,
      isValue: false,
    });
  }, [columns]);

  return (
    <>
      <Card title="User Guide" className="w-full">
        <div className="card grid w-12 flex-column flex-nowrap ">
          <div className="grid w-12 flex justify-content-between align-items-center pt-2 overlay-x-hidden">
            <BreadCrumb
              model={breadcrumbItems}
              home={breadcrumbHome}
              className="border-none"
            />
          </div>

          <>
            <DataTable
              value={isLoading ? tableLoader : data}
              className="mt-3 w-full p-datatable-striped"
            >
              {columns.map((col, index) => (
                <Column
                  key={index}
                  field={col.field ? col.field : ''}
                  header={col.header ? col.header : ''}
                  {...(!isLoading
                    ? {
                        body: col.body ? col.body : () => {},
                      }
                    : {})}
                  pt={{
                    headerCell: { className: 'bg-primary' },
                  }}
                  className={col.className ? col.className : ''}
                  style={col.style ? col.style : {}}
                  sortable={col.sortable ? col.sortable : false}
                ></Column>
              ))}
            </DataTable>
            {!isLoading ? (
              <Paginator
                first={first}
                rows={rows}
                totalRecords={totalRecords}
                onPageChange={onPageChange}
                rowsPerPageOptions={[5, 10, 25, 50]}
              ></Paginator>
            ) : null}
          </>

          <DescriptionDialog
            dialogVisible={dialogVisible}
            dialogHeader={dialogHeader}
            overlayContent={overlayContent}
            setDialogVisible={setDialogVisible}
          />
          {editDialogVisible && (
            <EditPageMeta
              visible={editDialogVisible}
              onCancelBtnClickHandler={handleClose}
              onSave={onSaveEdit}
              rowData={selectedRowData}
              onDescriptionChange={value =>
                setSelectedRowData(prevData => ({
                  ...prevData,
                  description: value || '',
                }))
              }
            />
          )}
        </div>
      </Card>
    </>
  );
};

export default PageMetaUpdate;
