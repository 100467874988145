import React, { useState } from 'react';
import PFDropdown from '../../../shared/PFPrime/PFDropdown';
import PFFormikError from '../../../shared/PFPrime/PFFormikError';
const ItemEditor = ({
  rowData,
  options,
  getItemList,
  setSelectedlineItem,
  setLineItems,
  editingRows,
  lineItems,
  submitAction,
}) => {
  const { values, touched, errors } = submitAction;
  const isEditing = editingRows[rowData.rowIndex];
  const [value, setValue] = useState(values?.itemId || '');
  const [listOptions, setListOptions] = useState(lineItems || []);
  const handleFilter = event => {
    const filterValue = event.filter;

    filterValue && getItemList(setListOptions, filterValue); // Call debounced API function
  };
  return isEditing ? (
    <div className="vertical-align-top">
      <span className="p-float-label">
        <PFDropdown
          options={listOptions}
          optionLabel="option_label"
          optionValue="item_id"
          name="itemId"
          filter={true}
          onFilter={handleFilter}
          value={value || ''}
          onChange={(name, value) => {
            setValue(value);
          }}
          onBlur={() => {
            const selectedItem = listOptions?.find(i => i?.item_id === value);
            setSelectedlineItem(selectedItem || null);
            setLineItems(listOptions);
          }}
          className={`${
            touched?.itemId && errors?.itemId
              ? 'error p-inputtext-sm quote-items'
              : 'p-inputtext-sm quote-items'
          }`}
          pt={{
            panel: {
              className: 'max-w-25rem',
            },
          }}
        />
      </span>
      <PFFormikError touched={touched} errors={errors} field="itemId" />
    </div>
  ) : (
    <div className="pt-2">{`${rowData?.itemNumber || ''}`}</div>
  );
};

export default ItemEditor;
