import React from 'react';
import { Dialog } from 'primereact/dialog';
import { ListBox } from 'primereact/listbox';
import { TEMP_CONFIG } from '../../../constants/email-template.constant';
export const SelectedValuesList = ({ isOpen, onClose, selectedConfigList }) => {
  const { selectedValues, selectedValue, selectedLabel, title } =
    selectedConfigList;
  return (
    <Dialog
      header={title}
      visible={isOpen}
      onHide={onClose}
      className="w-11 lg:w-4"
    >
      <ListBox
        listStyle={{ maxHeight: '250px' }}
        value={selectedValues}
        options={selectedValues.map(value => ({
          label:
            title === TEMP_CONFIG.STORES
              ? `${value?.store_number}-${value?.store_name}`
              : value[selectedLabel],
          value: value[selectedValue],
        }))}
        optionLabel="label"
        className="w-full"
      />
    </Dialog>
  );
};
