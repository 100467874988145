import React from 'react';
import {
  Grid,
  Typography,
  Button,
  Paper,
  Divider,
  Checkbox,
  TextField,
  FormControlLabel,
  CircularProgress,
  RadioGroup,
  Radio,
  Switch,
  Select,
  MenuItem,
  InputLabel,
  Input,
  FormControl,
  Tooltip,
  Chip,
} from '@material-ui/core';

import {
  notificationConfirmationValue,
  workAreasContainedValue,
} from '../../../../constants';
import { Skeleton } from 'primereact/skeleton';
const PresumedLead = ({ LrrpConfigurationFormik, setBtnDisables, loading }) => {
  const [selectedWorkAreasContained, setSelectedWorkAreasContained] =
    React.useState([]);

  const handleTypeChange = event => {
    const {
      target: { value },
    } = event;

    const filterForAll = value?.filter(sel => sel !== null);
    setSelectedWorkAreasContained(filterForAll);
    LrrpConfigurationFormik.setFieldValue('presumed_lead', {
      ...LrrpConfigurationFormik.values.presumed_lead,
      work_areas_contained:
        filterForAll.length > 0 ? filterForAll.map(val => val) : null,
    });
    setBtnDisables(false);
  };

  React.useEffect(() => {
    if (
      typeof LrrpConfigurationFormik.values?.presumed_lead
        ?.work_areas_contained === 'string'
    ) {
      if (
        !workAreasContainedValue.includes(
          LrrpConfigurationFormik.values?.presumed_lead?.work_areas_contained
        )
      ) {
        setSelectedWorkAreasContained([]);
      } else {
        setSelectedWorkAreasContained([
          LrrpConfigurationFormik.values?.presumed_lead?.work_areas_contained,
        ]);
      }
    } else {
      if (LrrpConfigurationFormik.values?.presumed_lead?.work_areas_contained) {
        setSelectedWorkAreasContained(
          LrrpConfigurationFormik.values?.presumed_lead?.work_areas_contained
        );
      }
    }
  }, [
    LrrpConfigurationFormik.values?.presumed_lead?.work_areas_contained &&
      LrrpConfigurationFormik.values?.presumed_lead?.work_areas_contained ===
        null,
  ]);

  return (
    <Grid container item direction="row" spacing={2}>
      <Grid item xs={12} md={6} lg={3}>
        {loading ? (
          <Skeleton className="w-full" height="3rem" />
        ) : (
          <>
            <label>Notification Confirmation :</label>
            <Select
              label="Notification Confirmation"
              value={
                LrrpConfigurationFormik.values?.presumed_lead
                  ?.notification_confirmation
              }
              onChange={event => {
                LrrpConfigurationFormik.setFieldValue('presumed_lead', {
                  ...LrrpConfigurationFormik.values.presumed_lead,
                  notification_confirmation: event.target.value,
                });
                setBtnDisables(false);
              }}
            >
              {notificationConfirmationValue.map(notificationValue => (
                <MenuItem key={notificationValue} value={notificationValue}>
                  {notificationValue}
                </MenuItem>
              ))}
            </Select>
          </>
        )}
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        {loading ? (
          <Skeleton className="w-full" height="3rem" />
        ) : (
          <>
            <label>Is Common Area Involved :</label>
            <Select
              label="LRRP Activity Auto Complete"
              value={
                LrrpConfigurationFormik.values?.presumed_lead
                  ?.is_common_area_involved
              }
              onChange={event => {
                LrrpConfigurationFormik.setFieldValue('presumed_lead', {
                  ...LrrpConfigurationFormik.values.presumed_lead,
                  is_common_area_involved: event.target.value,
                });
                setBtnDisables(false);
              }}
            >
              <MenuItem value={'yes'}>Yes</MenuItem>
              <MenuItem
                value={
                  LrrpConfigurationFormik.values?.presumed_lead
                    ?.is_common_area_involved === 'no'
                    ? 'no'
                    : 'N/A'
                }
              >
                N/A
              </MenuItem>
            </Select>
          </>
        )}
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        {loading ? (
          <Skeleton className="w-full" height="3rem" />
        ) : (
          <TextField
            label="Reason Common Area"
            name="Reason Common Area"
            value={
              LrrpConfigurationFormik.values?.presumed_lead?.reason_common_area
            }
            onChange={event => {
              LrrpConfigurationFormik.setFieldValue('presumed_lead', {
                ...LrrpConfigurationFormik.values.presumed_lead,
                reason_common_area: event.target.value,
              });
              setBtnDisables(false);
            }}
          />
        )}
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        {loading ? (
          <Skeleton className="w-full" height="3rem" />
        ) : (
          <>
            <label
              style={{ color: '#777e98', fontSize: '1em', fontWeight: 500 }}
            >
              Work Area Signage :
            </label>
            <Select
              label="Work Area Signage"
              value={
                LrrpConfigurationFormik.values?.presumed_lead?.work_area_signage
              }
              onChange={event => {
                LrrpConfigurationFormik.setFieldValue('presumed_lead', {
                  ...LrrpConfigurationFormik.values.presumed_lead,
                  work_area_signage: event.target.value,
                });
                setBtnDisables(false);
              }}
            >
              <MenuItem value={'yes'}>Yes</MenuItem>
              <MenuItem
                value={
                  LrrpConfigurationFormik.values?.presumed_lead
                    ?.work_area_signage === 'no'
                    ? 'no'
                    : 'N/A'
                }
              >
                N/A
              </MenuItem>
            </Select>
          </>
        )}
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        {loading ? (
          <Skeleton className="w-full" height="3rem" />
        ) : (
          <TextField
            label="Signage Explanation"
            name="Signage Explanation"
            value={
              LrrpConfigurationFormik.values?.presumed_lead?.signage_explanation
            }
            onChange={event => {
              LrrpConfigurationFormik.setFieldValue('presumed_lead', {
                ...LrrpConfigurationFormik.values.presumed_lead,
                signage_explanation: event.target.value,
              });
              setBtnDisables(false);
            }}
          />
        )}
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        {loading ? (
          <Skeleton className="w-full" height="3rem" />
        ) : (
          <>
            <label>
              Work Areas Contained <span className="text-red-500">*</span>
            </label>
            <Select
              label="Work Areas Contained"
              value={
                LrrpConfigurationFormik?.values?.presumed_lead
                  ?.work_areas_contained
              }
              onChange={event => {
                LrrpConfigurationFormik?.setFieldValue('presumed_lead', {
                  ...LrrpConfigurationFormik?.values?.presumed_lead,
                  work_areas_contained: event?.target?.value,
                });
              }}
            >
              <MenuItem key="placeholder" value="placeholder" disabled>
                <span style={{ color: '#9E9E9E' }}>
                  {' '}
                  Please make a selection
                </span>
              </MenuItem>
              {workAreasContainedValue?.map(reason => (
                <MenuItem key={reason} value={reason}>
                  {reason}
                </MenuItem>
              ))}
            </Select>
          </>
        )}
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        {loading ? (
          <Skeleton className="w-full" height="3rem" />
        ) : (
          <>
            <label
              style={{ color: '#777e98', fontSize: '1em', fontWeight: 500 }}
            >
              Prohibited Practices :
            </label>
            <Select
              label="Prohibited Practices"
              value={
                LrrpConfigurationFormik.values?.presumed_lead
                  ?.prohibited_practices
              }
              onChange={event => {
                LrrpConfigurationFormik.setFieldValue('presumed_lead', {
                  ...LrrpConfigurationFormik.values.presumed_lead,
                  prohibited_practices: event.target.value,
                });
                setBtnDisables(false);
              }}
            >
              <MenuItem value={'yes'}>Yes</MenuItem>
              <MenuItem
                value={
                  LrrpConfigurationFormik.values?.presumed_lead
                    ?.prohibited_practices === 'no'
                    ? 'no'
                    : 'N/A'
                }
              >
                N/A
              </MenuItem>
            </Select>
          </>
        )}
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        {loading ? (
          <Skeleton className="w-full" height="3rem" />
        ) : (
          <>
            <label
              style={{ color: '#777e98', fontSize: '1em', fontWeight: 500 }}
            >
              Waste Contained :
            </label>
            <Select
              label="Waste Contained"
              value={
                LrrpConfigurationFormik.values?.presumed_lead?.waste_contained
              }
              onChange={event => {
                LrrpConfigurationFormik.setFieldValue('presumed_lead', {
                  ...LrrpConfigurationFormik.values.presumed_lead,
                  waste_contained: event.target.value,
                });
                setBtnDisables(false);
              }}
            >
              <MenuItem value={'yes'}>Yes</MenuItem>
              <MenuItem
                value={
                  LrrpConfigurationFormik.values?.presumed_lead
                    ?.waste_contained === 'no'
                    ? 'no'
                    : 'N/A'
                }
              >
                N/A
              </MenuItem>
            </Select>
          </>
        )}
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        {loading ? (
          <Skeleton className="w-full" height="3rem" />
        ) : (
          <>
            <label
              style={{ color: '#777e98', fontSize: '1em', fontWeight: 500 }}
            >
              Worksite Properly Cleaned :
            </label>
            <Select
              label="Worksite Properly Cleaned"
              value={
                LrrpConfigurationFormik.values?.presumed_lead
                  ?.worksite_properly_cleaned
              }
              onChange={event => {
                LrrpConfigurationFormik.setFieldValue('presumed_lead', {
                  ...LrrpConfigurationFormik.values.presumed_lead,
                  worksite_properly_cleaned: event.target.value,
                });
                setBtnDisables(false);
              }}
            >
              <MenuItem value={'yes'}>Yes</MenuItem>
              <MenuItem
                value={
                  LrrpConfigurationFormik.values?.presumed_lead
                    ?.worksite_properly_cleaned === 'no'
                    ? 'no'
                    : 'N/A'
                }
              >
                N/A
              </MenuItem>
            </Select>
          </>
        )}
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        {loading ? (
          <Skeleton className="w-full" height="3rem" />
        ) : (
          <TextField
            label="Post Renovation Cleaning Method"
            name="Post Renovation Cleaning Method"
            value={
              LrrpConfigurationFormik.values?.presumed_lead
                ?.post_renovation_cleaning_method
            }
            onChange={event => {
              LrrpConfigurationFormik.setFieldValue('presumed_lead', {
                ...LrrpConfigurationFormik.values.presumed_lead,
                post_renovation_cleaning_method: event.target.value,
              });
              setBtnDisables(false);
            }}
          />
        )}
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        {loading ? (
          <Skeleton className="w-full" height="3rem" />
        ) : (
          <TextField
            type="number"
            label="Number of Wet Cloths"
            name="Number of Wet Cloths"
            value={
              LrrpConfigurationFormik.values?.presumed_lead
                ?.number_of_wet_cloths
            }
            onChange={event => {
              LrrpConfigurationFormik.setFieldValue('presumed_lead', {
                ...LrrpConfigurationFormik.values.presumed_lead,
                number_of_wet_cloths: event.target.value,
              });
              setBtnDisables(false);
            }}
          />
        )}
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        {loading ? (
          <Skeleton className="w-full" height="3rem" />
        ) : (
          <TextField
            type="number"
            label="Number of Dry Cloths"
            name="Number of Dry Cloths"
            value={
              LrrpConfigurationFormik.values?.presumed_lead
                ?.number_of_dry_cloths
            }
            onChange={event => {
              LrrpConfigurationFormik.setFieldValue('presumed_lead', {
                ...LrrpConfigurationFormik.values.presumed_lead,
                number_of_dry_cloths: event.target.value,
              });
              setBtnDisables(false);
            }}
          />
        )}
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        {loading ? (
          <Skeleton className="w-full" height="3rem" />
        ) : (
          <TextField
            label="Cleaning Verification Results"
            name="Cleaning Verification Results"
            value={
              LrrpConfigurationFormik.values?.presumed_lead
                ?.cleaning_verification_results
            }
            onChange={event => {
              LrrpConfigurationFormik.setFieldValue('presumed_lead', {
                ...LrrpConfigurationFormik.values.presumed_lead,
                cleaning_verification_results: event.target.value,
              });
              setBtnDisables(false);
            }}
          />
        )}
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        {loading ? (
          <Skeleton className="w-full" height="3rem" />
        ) : (
          <>
            <label
              style={{ color: '#777e98', fontSize: '1em', fontWeight: 500 }}
            >
              Is child occupied facility :
            </label>
            <Select
              label="Is child occupied facility"
              value={
                LrrpConfigurationFormik.values?.presumed_lead
                  ?.is_child_occupied_facility
              }
              onChange={event => {
                LrrpConfigurationFormik.setFieldValue('presumed_lead', {
                  ...LrrpConfigurationFormik.values.presumed_lead,
                  is_child_occupied_facility: event.target.value,
                });
                setBtnDisables(false);
              }}
            >
              <MenuItem value={'yes'}>Yes</MenuItem>
              <MenuItem
                value={
                  LrrpConfigurationFormik.values?.presumed_lead
                    ?.is_child_occupied_facility === 'no'
                    ? 'no'
                    : 'N/A'
                }
              >
                N/A
              </MenuItem>
            </Select>
          </>
        )}
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        {loading ? (
          <Skeleton className="w-full" height="3rem" />
        ) : (
          <TextField
            label="Reason Child Facility"
            name="Reason Child Facility"
            value={
              LrrpConfigurationFormik.values?.presumed_lead
                ?.reason_child_facility
            }
            onChange={event => {
              LrrpConfigurationFormik.setFieldValue('presumed_lead', {
                ...LrrpConfigurationFormik.values.presumed_lead,
                reason_child_facility: event.target.value,
              });
              setBtnDisables(false);
            }}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default PresumedLead;
