import React, { useEffect, useState, useRef, useMemo } from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { useSelector, useDispatch } from 'react-redux';
import { confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Image } from 'primereact/image';

import FileUploadTemplate from '../../shared/file-upload/file-upload-template';
import SkeletonLoader from '../../shared/Loader/skeleton';
import { setUserTeamReviewForm } from '../../../redux/slices/user-form-team-review.slice';
import TableLoader from '../../shared/Loader/TableLoader';

import { domHandlerCall } from './common';
import { uploadUserFile } from './services/user-form-service';
import {
  editTeamReview,
  getUserTeamReviewData,
} from './services/user-form-team-review.service';
import TableColumnsLoader from '../../shared/Loader/tableColumnsLoader';

const emptyRow = {
  type: '',
  description: '',
  file: '',
};

const mapTableDataForPayload = tableData => {
  const mappedPayload = tableData
    .map(row => {
      if (!row?.type || !row?.description) {
        return null;
      } else {
        return {
          type: row.type?.team_review_types_id,
          description: row?.description,
          files: [row?.file],
        };
      }
    })
    .filter(i => i !== null);
  return mappedPayload;
};

const UserFormTeamReviews = () => {
  const { isLoading, isFileUploading, teamReviewTypeList, teamReviewData } =
    useSelector(state => state.userFormTeamReview);
  const { userId } = useSelector(state => state.userForm);
  const dispatch = useDispatch();
  const toast = useRef(null);
  const [isUploaderVisible, setIsUploaderVisible] = useState(false);
  const [newUserDocument, setNewUserDocument] = useState();
  const [fileUploaderRowIndex, setFileUploaderRowIndex] = useState();
  const [isDisableAddButton, setIsDisableAddButton] = useState(false);

  const fetchInitiationData = async () => {
    dispatch(setUserTeamReviewForm({ isLoading: true }));
    try {
      const initializationData =
        !!userId && (await getUserTeamReviewData(userId));
      const { teamReviewTypeList, teamReviews } = initializationData;
      const mappedData = teamReviews.map(row => {
        return {
          type: teamReviewTypeList.find(
            item => item?.team_review_types_id == row?.type
          ),
          description: row?.description,
          file: row?.files[0],
        };
      });
      dispatch(
        setUserTeamReviewForm({
          teamReviewData: mappedData,
          teamReviewTypeList: teamReviewTypeList,
        })
      );
    } catch (error) {
    } finally {
      dispatch(setUserTeamReviewForm({ isLoading: false }));
    }
  };

  useEffect(() => {
    fetchInitiationData();
    setIsDisableAddButton(false);
  }, [userId]);

  const handleDelete = async rowIndex => {
    let editedData = structuredClone(teamReviewData);
    editedData.splice(rowIndex, 1);
    dispatch(
      setUserTeamReviewForm({ teamReviewData: editedData, isLoading: true })
    );
    let deleteResponse;
    try {
      const payload = mapTableDataForPayload(editedData);
      deleteResponse = await editTeamReview(userId, payload);
      if (deleteResponse?.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Deleted row',
          life: 2000,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setUserTeamReviewForm({ isLoading: false }));
    }
  };

  const handleDeleteCancel = () => {
    disableEditButton(false);
  };

  const handleOnRowEditCancel = async e => {
    confirmDialog({
      message:
        'Please confirm if you would like to cancel your unsaved changes or delete this record?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle text-xl',
      acceptClassName: 'p-button-primary p-button-sm',
      rejectClassName: 'p-button-sm p-button-outlined',
      accept: () => handleDelete(e?.index),
      reject: handleDeleteCancel,
      onHide: handleDeleteCancel,
      rejectLabel: 'Cancel',
      acceptLabel: 'Delete',
      className: 'text-sm w-11 md:w-8 lg:w-5',
    });
  };

  const TeamReviewTypeTemplate = rowData => {
    return <span>{rowData?.type?.team_review_types}</span>;
  };

  const FileTemplate = rowData => {
    return (
      <a
        href={rowData?.file?.url}
        target="_blank"
        rel="noreferrer"
        download={rowData?.file?.name}
      >
        {rowData?.file?.name}
      </a>
    );
  };

  const nameEditor = options => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={e => options.editorCallback(e.target.value)}
      />
    );
  };

  const reviewTypeEditor = options => {
    return (
      <Dropdown
        value={options?.value}
        optionLabel="team_review_types"
        options={teamReviewTypeList}
        onChange={e => options.editorCallback(e.value)}
        placeholder="Select a Type"
      />
    );
  };

  const handleUploaderClick = e => {
    setIsUploaderVisible(true);
  };

  const handleUserDocumentUpload = async e => {
    try {
      dispatch(setUserTeamReviewForm({ isFileUploading: true }));
      setIsUploaderVisible(false);
      if (e?.files && e?.files?.length > 0) {
        setNewUserDocument(e.files[0]);
        const fileUpload = await uploadUserFile(
          userId,
          'team_reviews',
          e.files[0]
        );
        if (fileUpload?.name && fileUpload?.url) {
          let editedData = structuredClone(teamReviewData);
          const rowData = editedData[fileUploaderRowIndex];
          rowData['file'] = fileUpload;
          editedData[fileUploaderRowIndex] = rowData;
          dispatch(setUserTeamReviewForm({ teamReviewData: editedData }));
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(setUserTeamReviewForm({ isFileUploading: false }));
    }
  };

  const fileUploadEditor = options => {
    setFileUploaderRowIndex(options?.rowIndex);
    return (
      <>
        <Image
          src={teamReviewData[options?.rowIndex]?.file?.url}
          zoomSrc={teamReviewData[options?.rowIndex]?.file?.url}
          alt="Image"
          width="60"
          height="60"
          preview
        />
        {isFileUploading ? (
          <ProgressSpinner
            style={{ width: '30px', height: '30px' }}
            strokeWidth="6"
            fill="var(--surface-ground)"
            animationDuration=".5s"
          />
        ) : (
          <i
            className="ml-2 pi pi-upload cursor-pointer"
            onClick={handleUploaderClick}
          ></i>
        )}
      </>
    );
  };

  const handleAddNewRow = () => {
    dispatch(
      setUserTeamReviewForm({
        teamReviewData: [emptyRow, ...teamReviewData],
      })
    );
    setTimeout(() => {
      const edit = domHandlerCall('p-row-editor-init');
      edit?.handler[0].click();
    }, 300);
  };
  const disableEditButton = status => {
    setTimeout(() => {
      const edit = domHandlerCall('p-row-editor-init');
      edit?.handler?.forEach(row => {
        row.disabled = status;
      });
      setIsDisableAddButton(status);
    }, 300);
  };

  const onRowEditComplete = async e => {
    let editedTeamReview = structuredClone(teamReviewData);
    let { data, newData, index } = e;
    editedTeamReview[index] = {
      file: data?.file,
      description: newData?.description,
      type: newData?.type,
    };
    dispatch(
      setUserTeamReviewForm({
        teamReviewData: editedTeamReview,
        isLoading: true,
      })
    );
    let editedResponse;
    try {
      const payload = mapTableDataForPayload(editedTeamReview);
      editedResponse = await editTeamReview(userId, payload);
      if (editedResponse?.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Updated Team Review',
          life: 2000,
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(setUserTeamReviewForm({ isLoading: false }));
    }
    disableEditButton(false);
    setIsDisableAddButton(false);
  };

  const handleRowValidation = (e, f) => {
    const isValid = !!e?.type?.team_review_types && !!e?.description;
    return isValid;
  };

  const teamReviewTableColumns = [
    {
      field: 'type',
      header: 'Type',
      editor: options => reviewTypeEditor(options),
      ...(!isLoading
        ? {
            body: rowData => TeamReviewTypeTemplate(rowData),
            bodyStyle: { width: '250px' },
          }
        : {}),
    },
    {
      field: 'description',
      header: 'Description',
      editor: options => nameEditor(options),
    },
    {
      field: 'file',
      header: 'File',
      editor: options => fileUploadEditor(options),
      ...(!isLoading
        ? {
            body: rowData => FileTemplate(rowData),
            bodyStyle: { width: '100px' },
          }
        : {}),
    },
    {
      field: 'edit',
      header: '',
      ...(!isLoading
        ? {
            rowEditor: true,
            headerStyle: { width: '10%', minWidth: '7rem' },
            bodyStyle: { textAlign: 'center', width: '150px' },
          }
        : {}),
    },
  ];

  const teamReviewTableLoader = useMemo(() => {
    return TableColumnsLoader(teamReviewTableColumns, {
      rows: 4,
      isValue: false,
    });
  }, [teamReviewTableColumns]);

  return (
    <>
      <div className="flex flex-row justify-content-between align-items-center py-3">
        <h4>Team Review</h4>
        <Button
          label="Add Team Review"
          size="small"
          severity="primary"
          onClick={handleAddNewRow}
          disabled={isDisableAddButton}
        />
      </div>
      <DataTable
        value={isLoading ? teamReviewTableLoader : teamReviewData}
        editMode="row"
        rowEditValidator={handleRowValidation}
        onRowEditComplete={onRowEditComplete}
        onRowEditCancel={handleOnRowEditCancel}
        onRowEditInit={prop => {
          disableEditButton(true);
        }}
      >
        {teamReviewTableColumns?.map(col => (
          <Column key={col.field} {...col} />
        ))}
      </DataTable>
      <Dialog
        header="Upload Document"
        visible={isUploaderVisible}
        style={{ width: '50vw' }}
        className="w-11 md:w-6"
        onHide={() => setIsUploaderVisible(false)}
      >
        <FileUploadTemplate
          onFileUpload={handleUserDocumentUpload}
          acceptedFormat="image/*, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        />
      </Dialog>

      <Toast ref={toast} />
    </>
  );
};

export default UserFormTeamReviews;
