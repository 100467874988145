import React, { useCallback, useRef, useState, useMemo } from 'react';

/*Shared Components*/
/*Custom Components*/
/*Prime Imports*/
import { OverlayPanel } from 'primereact/overlaypanel';
import moment from 'moment';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { CSVLink } from 'react-csv';
import { Toast } from 'primereact/toast';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { debounce } from 'lodash';
import { Skeleton } from 'primereact/skeleton';
import dayjs from 'dayjs';

import { formatCurrency } from '../../../utils/Helpers';
import PFButton from '../../shared/PFPrime/PFButton';
import { generateUrlParams } from '../../CRM/Communications/helper/helper';
import { UTCConvertor } from '../../../utils/date.helper';
import PFInputText from '../../CRM/PFPrime/PFInputText';
import PFDataTable from '../../CRM/PFPrime/PFDataTable';
import TableColumnsLoader from '../../shared/Loader/tableColumnsLoader';

import {
  addEditChargeback,
  getChargebackList,
} from './services/ChargebackService';

const ChargebackList = ({ project_id }) => {
  const [loading, setLoading] = useState(false);
  const [dataTableState, setDataTableState] = useState({
    limit: 10,
    page: 1,
    project_id: project_id,
    sortField: 'created_at',
    sortOrder: 1,
  });
  const [chargebackList, setChargebackList] = React.useState([]);
  const exportOverlayRef = useRef(null);
  const dt = useRef(null);
  const [globalSearch, setGlobalSearch] = useState('');
  let history = useHistory();
  const chargBackTableColumns = [
    {
      field: 'action_buttons',
      header: '',
      sortable: false,
      ...(!loading
        ? {
            body: params => params?.action_buttons,
          }
        : {}),
    },
    {
      field: 'created_at',
      header: 'Creation Date',
      sortable: true,
      ...(!loading
        ? {
            body: params =>
              params?.created_at
                ? dayjs(new Date(params?.created_at)).format('MM-DD-YYYY')
                : null,
          }
        : {}),
    },
    {
      field: 'total_technician',
      header: 'Chargeback Amount',
      sortable: true,
      ...(!loading
        ? {
            body: params =>
              !isNaN(params?.total_technician)
                ? formatCurrency(params?.total_technician)
                : null,
          }
        : {}),
    },
    {
      field: 'payout_amount',
      header: 'Payment Amount',
      sortable: true,
      ...(!loading
        ? {
            body: params =>
              !isNaN(params?.payout_amount)
                ? formatCurrency(params?.payout_amount)
                : null,
          }
        : {}),
    },
    {
      field: 'payment_count',
      header: 'No. of Payments',
      sortable: true,
      ...(!loading
        ? {
            body: params => params?.payment_count,
          }
        : {}),
    },
    {
      field: 'payment_amount_balance',
      header: 'Remaining Balance',
      sortable: true,
      ...(!loading
        ? {
            body: params =>
              !isNaN(params?.payment_amount_balance)
                ? formatCurrency(params?.payment_amount_balance)
                : null,
          }
        : {}),
    },
    {
      field: 'debit_user',
      header: 'Debit Technician',
      sortable: true,
      ...(!loading
        ? {
            body: params =>
              params?.debit_user?.first_name
                ? `${params?.debit_user?.first_name} ${params?.debit_user?.last_name}`
                : null,
          }
        : {}),
    },
    {
      field: 'credit_user',
      header: 'Credit Technician',
      sortable: true,
      ...(!loading
        ? {
            body: params =>
              params?.credit_user?.first_name
                ? `${params?.credit_user?.first_name} ${params?.credit_user?.last_name}`
                : null,
          }
        : {}),
    },
    {
      field: 'chargeback_status',
      header: 'Chargeback Status',
      sortable: true,
      ...(!loading
        ? {
            body: params => params?.chargeback_status,
          }
        : {}),
    },
  ];

  const toast = useRef();
  // Fetch Chargeback list
  const fetchInitiationData = async searchValue => {
    try {
      setLoading(true);
      const urlParams = {
        ...dataTableState,
        sortBy: dataTableState.sortField,
        sortOrder: dataTableState.sortOrder === 1 ? 'asc' : 'desc',
      };
      if (searchValue || globalSearch)
        urlParams.globalSearch = searchValue || globalSearch;
      const initializationData = await getChargebackList(urlParams);
      const { result } = initializationData?.data;
      const chargebackListArray = result?.map(row => {
        return {
          chargeback_id: row?.chargeback_id,
          created_at: row?.created_at,
          payment_amount_balance: row?.payment_amount_balance,
          payment_count: row?.payment_count,
          debit_user: row?.debit_user,
          credit_user: row?.credit_user,
          chargeback_status: row?.chargeback_status?.status,
          total_technician: row?.total_technician,
          project_id: row?.project?.project_id,
          payout_amount: row?.payout_amount,
          is_editable: row?.is_editable,
        };
      });
      setChargebackList(chargebackListArray);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchInitiationData();
  }, [dataTableState]);

  const debouncedOnChange = useCallback(debounce(fetchInitiationData, 500), []);

  const onSort = event => {
    setDataTableState(prev => ({
      ...prev,
      sortField: event?.sortField,
      sortOrder: event?.sortOrder,
    }));
  };

  const handleOnDelete = async value => {
    confirmDialog({
      message: 'Please confirm if you would like to delete this record?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => deleteChargeback(value),

      rejectLabel: 'Cancel',
      acceptLabel: 'Delete',
      acceptIcon: 'pi pi-trash',
    });
  };

  const handleOnEdit = val => {
    if (val?.is_editable) {
      history.push(
        `/admin/chargeback/edit/project/${val?.project_id}/chargeback/${val?.chargeback_id}`
      );
    }
  };

  const handleOnView = val => {
    history.push(
      `/admin/chargeback/view/project/${val?.project_id}/chargeback/${val?.chargeback_id}`
    );
  };

  // Helper for delete chargeback
  const deleteChargeback = async chargebackId => {
    setLoading(true);

    let editedResponse;
    let body = { summary: { chargeback_id: chargebackId } };
    try {
      editedResponse = await addEditChargeback(body, chargebackId, true);

      if (editedResponse?.statusCode === 200) {
        toast.current.show({
          severity: 'success',
          summary: editedResponse?.message,
          life: 2000,
        });
        fetchInitiationData();
      }
    } catch (err) {
      toast.current.show({
        severity: 'error',
        summary: 'Something went wrong',
        life: 2000,
      });
    } finally {
      setLoading(false);
    }
  };

  let dataArray = chargebackList?.map(val => {
    return {
      ...val,
      action_buttons: (
        <div className="flex flex-row" style={{ gap: '10px' }}>
          <i
            className="pi pi-eye cursor-pointer"
            onClick={e => handleOnView(val)}
          ></i>
          <i
            className={
              val?.is_editable
                ? 'pi pi-pencil cursor-pointer'
                : 'pi pi-pencil text-300'
            }
            onClick={e => {
              handleOnEdit(val);
            }}
          ></i>
          <i
            className={
              val?.is_editable
                ? 'pi pi-trash cursor-pointer'
                : 'pi pi-trash text-300'
            }
            onClick={e => {
              if (val?.is_editable) {
                handleOnDelete(val?.chargeback_id);
              }
            }}
          ></i>
        </div>
      ),
    };
  });

  const cols = [
    {
      field: 'created_at',
      header: 'Creation Date',
    },

    {
      field: 'total_technician',
      header: 'Chargeback Amount',
    },
    {
      field: 'payout_amount',
      header: 'Payment Amount',
    },
    {
      field: 'payment_count',
      header: 'No. of Payments',
    },
    {
      field: 'payment_amount_balance',
      header: 'Remaining Balance',
    },
    {
      field: 'debit_user',
      header: 'Debit Technician',
    },
    {
      field: 'credit_user',
      header: 'Credit Technician',
    },

    {
      field: 'chargeback_status',
      header: 'Approval Status',
    },
  ];

  const exportColumns = cols.map(col => ({
    title: col.header,
    dataKey: col.field,
  }));
  // Data to be exported in pdf/csv
  const exportData = chargebackList?.map(val => ({
    created_at: val?.created_at
      ? dayjs(new Date(val?.created_at)).format('MM-DD-YYYY')
      : null,
    total_technician: val?.total_technician
      ? formatCurrency(val?.total_technician)
      : null,
    payout_amount: val?.payout_amount
      ? formatCurrency(val?.payout_amount)
      : null,
    payment_count: val?.payment_count,
    payment_amount_balance: val?.payment_amount_balance
      ? formatCurrency(val?.payment_amount_balance)
      : null,
    debit_user: `${val?.debit_user?.first_name} ${val?.credit_user?.last_name}`,
    credit_user: `${val?.credit_user?.first_name} ${val?.credit_user?.last_name}`,
    chargeback_status: val?.chargeback_status,
  }));

  // Helper to export pdf file
  const exportPdf = () => {
    import('jspdf').then(jsPDF => {
      import('jspdf-autotable').then(() => {
        const doc = new jsPDF.default(0, 0);

        doc.autoTable(exportColumns, exportData);
        doc.save('chargebackData.pdf');
        exportOverlayRef?.current?.hide();
      });
    });
  };
  // Helper to export CSV file
  const exportCSV = selectionOnly => {
    exportOverlayRef?.current?.hide();
  };
  // Page change event
  const handleOnPage = event => {
    setDataTableState(prevState => ({
      ...prevState,
      limit: event.rows,
      offset: event.first,
    }));
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-between">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <PFInputText
            className="p-inputtext-sm"
            placeholder="Search keyword"
            value={globalSearch}
            onChange={e => {
              setGlobalSearch(e.target.value);
              debouncedOnChange(e.target.value);
            }}
          />
        </span>
        <div className="flex gap-2 align-items-center">
          <div className="icon-round">
            <i
              className="icon pi pi-download cursor-pointer"
              onClick={e => exportOverlayRef?.current?.toggle(e)}
            />
            <OverlayPanel ref={exportOverlayRef}>
              <div className="flex flex-column gap-2">
                <CSVLink data={exportData} filename={'Chargeback'}>
                  <PFButton
                    color="primary"
                    outlined
                    onClick={() => exportCSV(false)}
                    label="Export in CSV"
                  />
                </CSVLink>

                <PFButton
                  color="primary"
                  outlined
                  onClick={exportPdf}
                  label="Export in PDF"
                />
              </div>
            </OverlayPanel>
          </div>
          <div className="icon-round">
            <i
              className="icon pi pi-refresh cursor-pointer"
              onClick={() => fetchInitiationData()}
            />
          </div>
        </div>
      </div>
    );
  };

  const header = renderHeader();

  let dataTableParams = {
    ref: dt,
    sortField: dataTableState?.sortField,
    sortOrder: dataTableState?.sortOrder,
    header: header,
  };

  const tableLoader = useMemo(() => {
    return TableColumnsLoader(chargBackTableColumns, {
      rows: 10,
      isValue: false,
    });
  }, [chargBackTableColumns]);

  return (
    <div className="card w-12 pt-2">
      <Toast ref={toast} />
      <PFDataTable
        dataTableParams={dataTableParams}
        data={loading ? tableLoader : dataArray}
        columns={chargBackTableColumns}
        paginator={!loading}
        rows={10}
        totalRecords={chargebackList?.count}
        dataKey="id"
        header={header}
        onSort={onSort}
      ></PFDataTable>
      <ConfirmDialog />
    </div>
  );
};

export default ChargebackList;
