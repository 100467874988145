import React, { useState } from 'react';
import { useParams } from 'react-router';
import { Grid, CircularProgress } from '@material-ui/core';

import { tableValueFormatter } from '../../../utils/Helpers';
import PageHeader from '../../shared/PageHeader/PageHeader';
import GenericDataTable from '../../shared/GenericDataTable/GenericDataTable';
import { Alert } from '../../shared/Alerts/Alert';
import { getUserProfile } from '../../ProjectManagement/ProjectInfo/ProjectDetails.service';
import { useAlerts } from '../../shared/Alerts/alertsService';
import PFTableSkeleton from '../../shared/Loader/PFTableSkeleton';

import {
  getCountOfOrders,
  getCountOfIncomingSms,
  getCountOfOutgoingSms,
} from './TenantBilling.services';
import { useStyles } from './TenantBilling.styles';
import { TENNANT_SKELETON_COLUMNS } from './constants/skeleton.constants';

const TenantBilling = () => {
  const { payment_request_id } = useParams();
  const billingBreadcrumb = [
    {
      text: 'Admin',
    },
    {
      text: 'Tenant Billing',
    },
  ];
  const [loading, setLoading] = React.useState(true);
  const { alert, setAlert, clearAlert } = useAlerts();

  const [searchQuery, setSearchQuery] = React.useState({
    limit: 10,
    offset: 0,
    sortKey: 'created_at',
    sortDirection: 'DESC',
  });
  const classes = useStyles();
  const [billingDetails, setBillingDetails] = useState({});
  const [incomingCount, setIncomingCount] = useState({});
  const [outgoingCount, setOutgoingCount] = useState({});
  const [userProfile, setUserProfile] = useState({});

  React.useEffect(() => {
    getCountOfOrders(searchQuery, setLoading, setBillingDetails, setAlert);
    getCountOfIncomingSms(searchQuery, setLoading, setIncomingCount, setAlert);
    getCountOfOutgoingSms(searchQuery, setLoading, setOutgoingCount, setAlert);
  }, [searchQuery]);

  React.useEffect(() => {
    clearAlert();
    getUserProfile(setUserProfile);
  }, []);

  let dataArray = [];
  billingDetails?.data?.rows?.map((val, index) => {
    const record = {};
    billingDetails?.data?.tableConfig?.table_configuration?.forEach(headers => {
      let value = tableValueFormatter(headers, val);

      record[headers.name] = value;
    });
    dataArray.push(record);
  }) || [];

  let incomingCountDataArray = [];
  incomingCount?.data?.rows?.map((val, index) => {
    const incomingRecord = {};
    incomingCount?.data?.tableConfig?.table_configuration?.forEach(headers => {
      let value = tableValueFormatter(headers, val);

      incomingRecord[headers.name] = value;
    });
    incomingCountDataArray.push(incomingRecord);
  }) || [];

  let outgoingCountDataArray = [];
  outgoingCount?.data?.rows?.map((val, index) => {
    const outgoingRecord = {};
    outgoingCount?.data?.tableConfig?.table_configuration?.forEach(headers => {
      let value = tableValueFormatter(headers, val);

      outgoingRecord[headers.name] = value;
    });
    outgoingCountDataArray.push(outgoingRecord);
  }) || [];

  let intervalVal = '';
  const handleSearch = searchInputVal => {
    const searchString = searchInputVal?.trim();

    /** Timeout will help to let multiple characters in the TextInput,* and API call would be optimized to get Values for Input of few chars at once,* rather than for every single character*/
    try {
      clearTimeout(intervalVal);
    } catch (err) {
      console.log(err);
    }
    intervalVal = setTimeout(() => {
      setLoading(true);
      setSearchQuery(prevState => ({
        ...prevState,
        offset: 0,
        searchString: searchString,
      }));
    }, 500);
  };

  const dataTableOptions = {
    download: false,
    print: false,
    resizableColumns: false,
    selectableRows: false,
    responsive: 'standard',
    filter: false,
    search: false,
    rowsPerPageOptions: [10, 20, 50, 100],
  };

  return (
    <>
      <Grid container spacing={2} direction="column">
        {alert.exists && (
          <Grid item>
            {' '}
            <Alert />
          </Grid>
        )}
        <Grid
          container
          item
          direction="row"
          justifyContent="space-between"
          spacing={2}
        >
          <Grid item>
            <PageHeader
              pageTitle="Tenant Billing"
              breadCrumbArray={billingBreadcrumb}
            />
          </Grid>
        </Grid>
        {loading &&
          !billingDetails?.data?.tableConfig?.table_configuration?.length && (
            <div className="mt-3">
              <PFTableSkeleton
                numRows={10}
                dataTableColumns={TENNANT_SKELETON_COLUMNS}
                filterCount={0}
                rowHeight="60px"
              />
            </div>
          )}
        {billingDetails?.data?.tableConfig?.table_configuration &&
          (userProfile?.client_name === 'ProjectsForce LLC' ||
            userProfile?.client_name === 'Vista Installations') && (
            <Grid item classes={{ root: classes.dataTableWrapper }}>
              <GenericDataTable
                title="Count of Orders"
                columns={billingDetails?.data?.tableConfig?.table_configuration}
                data={dataArray}
                options={dataTableOptions}
              />
            </Grid>
          )}
        {incomingCount?.data?.tableConfig?.table_configuration &&
          (userProfile?.client_name === 'ProjectsForce LLC' ||
            userProfile?.client_name === 'Vista Installations') && (
            <Grid
              item
              classes={{ root: classes.dataTableWrapper }}
              style={{ marginTop: '25px' }}
            >
              <GenericDataTable
                title="Count of Incoming SMS"
                columns={incomingCount?.data?.tableConfig?.table_configuration}
                data={incomingCountDataArray}
                options={dataTableOptions}
              />
            </Grid>
          )}
        {outgoingCount?.data?.tableConfig?.table_configuration &&
          (userProfile?.client_name === 'ProjectsForce LLC' ||
            userProfile?.client_name === 'Vista Installations') && (
            <Grid
              item
              classes={{ root: classes.dataTableWrapper }}
              style={{ marginTop: '25px' }}
            >
              <GenericDataTable
                title="Count of Outgoing SMS"
                columns={outgoingCount?.data?.tableConfig?.table_configuration}
                data={outgoingCountDataArray}
                options={dataTableOptions}
              />
            </Grid>
          )}
      </Grid>
    </>
  );
};

export default TenantBilling;
