import axios from 'axios';

import { URL_CONSTANTS } from '../../../../constants/urlConstants';
import ApiService from '../../../../services/api.service';

export const getUserAvailability = async userId => {
  try {
    if (userId) {
      const userAvailabilityResponse = await ApiService.get(
        `/auth/user/manage/availability-schedule/${userId}`
      );
      return {
        userAvailabilityData:
          userAvailabilityResponse?.data?.availability || [],
        start_location:
          userAvailabilityResponse?.data?.routingPreferenceRes
            ?.start_location || '',
        end_location:
          userAvailabilityResponse?.data?.routingPreferenceRes?.end_location ||
          '',
        user_timezone:
          userAvailabilityResponse?.data?.routingPreferenceRes?.user_timezone ||
          [],
      };
    }
  } catch (error) {
    console.error(error);
  }
};

export const editUserAvailability = async (userId, availabilityData) => {
  try {
    const response = await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/auth/user/manage/availability-schedule/${userId}`,
      {
        availability: JSON.stringify(availabilityData?.availability),
        start_location: availabilityData?.start_location,
        end_location: availabilityData?.end_location,
        user_timezone: availabilityData?.user_timezone,
      }
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};
export const getWorkHours = async userId => {
  return await ApiService.get(`/auth/user/manage/work-schedule/${userId}`);
};
export const addWorkHours = async (userId, payload) => {
  return await ApiService.post(
    `/auth/user/manage/work-schedule/${userId}`,
    payload
  );
};

export const getWorkScheduleSlotsByHourId = async (userId, hourId) => {
  return await ApiService.getWithDiffBaseUrl(
    ApiService.formatUrl(
      URL_CONSTANTS.USER_MANAGEMENT.endPoints.workScheduleSlotsByHourId,
      userId,
      hourId
    ),
    null,
    URL_CONSTANTS.API.BASE_URL
  );
};
export const addWorkScheduleSlotInHour = async (userId, hourId, payload) => {
  return await ApiService.postWithDiffBaseUrl(
    ApiService.formatUrl(
      URL_CONSTANTS.USER_MANAGEMENT.endPoints.addWorkScheduleSlotInHour,
      userId,
      hourId
    ),
    payload,
    URL_CONSTANTS.API.BASE_URL
  );
};
export const updateWorkScheduleSlotBySlotId = async (
  userId,
  slotId,
  payload
) => {
  return await ApiService.putWithDiffBaseUrl(
    ApiService.formatUrl(
      URL_CONSTANTS.USER_MANAGEMENT.endPoints.updateWorkScheduleSlotBySlotId,
      userId,
      slotId
    ),
    payload,
    URL_CONSTANTS.API.BASE_URL
  );
};
export const deleteWorkScheduleSlotBySlotId = async (userId, slotId) => {
  return await ApiService.deleteWithDiffBaseUrl(
    ApiService.formatUrl(
      URL_CONSTANTS.USER_MANAGEMENT.endPoints.deleteWorkScheduleSlotBySlotId,
      userId,
      slotId
    ),
    null,
    URL_CONSTANTS.API.BASE_URL
  );
};
export const mergeWorkScheduleSlotsByHourId = async (userId, hourId) => {
  return await ApiService.postWithDiffBaseUrl(
    ApiService.formatUrl(
      URL_CONSTANTS.USER_MANAGEMENT.endPoints.mergeWorkScheduleSlotsByHourId,
      userId,
      hourId
    ),
    null,
    URL_CONSTANTS.API.BASE_URL
  );
};
export const mergeAllWorkScheduleSlots = async userId => {
  return await ApiService.postWithDiffBaseUrl(
    ApiService.formatUrl(
      URL_CONSTANTS.USER_MANAGEMENT.endPoints.mergeAllWorkScheduleSlots,
      userId
    ),
    null,
    URL_CONSTANTS.API.BASE_URL
  );
};
