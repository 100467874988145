// **** React Imports ****
import React, { useMemo } from 'react';
import { useHistory, Link } from 'react-router-dom';

// **** External Utilities ****
import {
  Grid,
  IconButton,
  Icon,
  Typography,
  Button,
  Box,
  TextField,
} from '@material-ui/core';
import {
  Visibility as VisibilityIcon,
  Edit as EditIcon,
} from '@material-ui/icons';

// **** Custom Components ****
import { Autocomplete } from '@material-ui/lab';

import PageHeader from '../../shared/PageHeader/PageHeader';
import GenericDataTable from '../../shared/GenericDataTable/GenericDataTable';
import { checkPermission } from '../../../utils/Helpers';
import permissions from '../../../config/permissions';
import { Alert } from '../../shared/Alerts/Alert';

// **** Services *****
import { useAlerts } from '../../shared/Alerts/alertsService';
import PFTableLoader from '../../shared/Loader/PFTableLoader';
import TableColumnsLoader from '../../shared/Loader/tableColumnsLoader';

import { getStoresList, getStoreTableFiltersOptions } from './Stores.service';

// **** Styles/Images/Icons ****
import { useStyles } from './Stores.styles';

function handleRowHover(event, row, rowIndex) {
  let control = document.getElementById(rowIndex);
  if (control) control.style.display = 'block';
}

function handleRowHoverLeave(event, row, rowIndex) {
  let control = document.getElementById(rowIndex);
  if (control?.style?.display) control.style.display = 'none';
}

const Stores = () => {
  const clientsBreadcrumb = [
    {
      text: 'Admin',
    },
    {
      text: 'Stores',
    },
  ];
  const [loading, setLoading] = React.useState(true);
  const [searchQuery, setSearchQuery] = React.useState({
    limit: 10,
    offset: 0,
  });
  const history = useHistory();
  const classes = useStyles();
  const [storeListData, setStoreListData] = React.useState([]);
  const [districtOptions, setDistrictOptions] = React.useState([]);
  const [regionOptions, setRegionOptions] = React.useState([]);
  const [selectedFilters, setSelectedFilters] = React.useState();
  const { alert, clearAlert } = useAlerts();
  React.useEffect(() => {
    clearAlert();
  }, []);
  React.useEffect(() => {
    getStoresList(searchQuery, selectedFilters, setLoading, setStoreListData);
  }, [searchQuery, selectedFilters]);

  React.useEffect(() => {
    getStoreTableFiltersOptions(setDistrictOptions, setRegionOptions);
  }, []);

  const dataTableColumns = [
    {
      name: '',
      options: {
        sort: true,
        filter: false,
        viewColumns: false,
        setCellProps: () => ({
          style: { minWidth: '100px', maxWidth: '100px' },
        }),
        ...(!loading
          ? {
              customBodyRenderLite: dataIndex => {
                return (
                  <span style={{ display: 'none' }} id={dataIndex}>
                    {checkPermission(permissions?.stores?.menu) && (
                      <span>
                        <IconButton classes={{ root: classes.actionIcons }}>
                          <Link
                            to={`/stores/view/${dataArray && dataArray[dataIndex]?.store_id}`}
                          >
                            <VisibilityIcon />
                          </Link>
                        </IconButton>
                      </span>
                    )}
                    {checkPermission(permissions?.stores?.storesEdit) && (
                      <span>
                        <IconButton classes={{ root: classes.actionIcons }}>
                          <Link
                            to={`/stores/edit/${dataArray && dataArray[dataIndex]?.store_id}`}
                          >
                            <EditIcon />
                          </Link>
                        </IconButton>
                      </span>
                    )}
                  </span>
                );
              },
            }
          : {}),
      },
    },
    {
      name: 'source_name',
      label: 'Source',

      options: {
        sort: true,
        filter: false,
        viewColumns: false,
        setCellProps: () => ({
          style: { minWidth: '120px', maxWidth: '120px' },
        }),
      },
    },
    {
      name: 'store_name',
      label: 'Store Name',
      options: {
        sort: true,
        filter: false,
      },
      setCellProps: () => ({ style: { minWidth: '145px', maxWidth: '145px' } }),
    },
    {
      name: 'store_number',
      label: 'Store Number',
      options: {
        setCellProps: () => ({
          style: { minWidth: '135px', maxWidth: '135px' },
        }),
        sort: true,
        filter: false,
      },
    },
    {
      name: 'states',
      label: 'District',
      options: {
        setCellProps: () => ({
          style: { width: '15%' },
        }),
        sort: true,
        filter: true,
        filterType: 'custom',
        customFilterListOptions: {
          render: v => v.map(val => val['district']),
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            return (
              <Autocomplete
                name="states"
                multiple
                options={districtOptions}
                onChange={(event, value) => {
                  filterList[index] = value?.map(val => val);
                  onChange(filterList[index], index, column);
                }}
                value={filterList[index]}
                getOptionLabel={option => option && option['district']}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="District"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            );
          },
        },
        serverSide: true,
      },
    },
    {
      name: 'regions',
      label: 'Region',
      options: {
        setCellProps: () => ({
          style: { width: '15%' },
        }),
        sort: true,
        filter: true,
        filterType: 'custom',
        customFilterListOptions: {
          render: v => v.map(val => val['region']),
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            return (
              <Autocomplete
                name="region"
                multiple
                options={regionOptions}
                onChange={(event, value) => {
                  filterList[index] = value?.map(val => val);
                  onChange(filterList[index], index, column);
                }}
                value={filterList[index]}
                getOptionLabel={option => option && option['region']}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Region"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            );
          },
        },
        serverSide: true,
      },
    },
    {
      name: 'is_deleted',
      label: 'Status',
      options: {
        sort: true,
        filter: false,
        setCellProps: () => ({
          style: { width: '15%' },
        }),
        customBodyRender: (value, tableMeta, updateValue) => (
          <Typography
            variant="subtitle2"
            className={
              (value === 'Active' && classes.statusColorActive) ||
              (value === 'Inactive' && classes.statusColorInactive)
            }
          >
            {value}
          </Typography>
        ),
      },
    },
    {
      name: 'workroom_label',
      label: 'Workroom',
      options: {
        sort: true,
        filter: false,
      },
      setCellProps: () => ({ style: { minWidth: '145px', maxWidth: '145px' } }),
    },
  ];

  let intervalVal = '';
  const handleSearch = searchInputVal => {
    const searchString = searchInputVal?.trim();

    /** Timeout will help to let multiple characters in the TextInput,* and API call would be optimized to get Values for Input of few chars at once,* rather than for every single character*/
    try {
      clearTimeout(intervalVal);
    } catch (err) {
      console.log(err);
    }
    intervalVal = setTimeout(() => {
      setLoading(true);
      setSearchQuery(prevState => ({
        ...prevState,
        offset: 0,
        search_string: searchString,
      }));
    }, 500);
  };

  const handleFilterSubmit = applyNewFilters => {
    let appliedFiltersList = applyNewFilters();

    let selectedFilterObject = {};
    appliedFiltersList.map((data, index) => {
      if (data?.length) {
        let columnKey = dataTableColumns[index]?.name;
        let selectedFilterOptionsKey = [];
        switch (columnKey) {
          case 'regions':
            selectedFilterOptionsKey = data.map(val => val['region']);
            break;
          case 'states':
            selectedFilterOptionsKey = data.map(val => val['district']);
            break;

          default:
            selectedFilterOptionsKey = [];
        }
        selectedFilterObject = {
          ...selectedFilterObject,
          [columnKey]: selectedFilterOptionsKey,
        };
      }
    });
    setSelectedFilters(selectedFilterObject);
  };

  let dataArray =
    storeListData?.items?.map((val, index) => {
      return {
        source_name: val.logo_url ? (
          <Icon fontSize="large" component="span">
            <img width="40" height="30" alt="ProjectForce" src={val.logo_url} />
          </Icon>
        ) : (
          <Box className={classes.sourceName}>
            <Typography variant="subtitle2">{val.source_name || ''}</Typography>
          </Box>
        ),
        store_id: val.store_id,
        store_name: val.store_name,
        store_number: val.store_number,
        states: val?.district,
        regions: val?.region,
        is_deleted: !val.is_deleted ? 'Active' : 'Inactive',
        workroom_label: val?.workroom_label,
      };
    }) || [];

  const viewPermission = checkPermission(permissions?.stores?.menu);
  const dataTableOptions = {
    download: false,
    print: false,
    resizableColumns: false,
    //jumpToPage: true,
    selectableRows: 'none',
    responsive: 'standard',
    filterType: 'multiselect',
    tableBodyMinHeight: '300px',
    count: storeListData?.count || 0,
    serverSide: true,
    textLabels: {
      body: {
        noMatch: !loading && !dataArray.length && 'No records found',
      },
    },
    onChangeRowsPerPage: numberOfRows => {
      setLoading(true);
      setSearchQuery(prevState => ({
        ...prevState,
        limit: numberOfRows,
        offset: 0,
      }));
    },
    onChangePage: currentPage => {
      setLoading(true);
      setSearchQuery(prevState => ({
        ...prevState,
        offset: currentPage * searchQuery.limit,
      }));
    },
    onColumnSortChange: (changedColumn, direction) => {
      setLoading(true);
      setSearchQuery(prevState => ({
        ...prevState,
        sort_key: changedColumn,
        sort_direction: direction.toUpperCase(),
      }));
    },
    searchProps: {
      onKeyUp: e => {
        if (e.target.defaultValue && e.keyCode === 13) {
          handleSearch(e.target.defaultValue);
        }
      },
    },
    onSearchChange: searchText => {
      handleSearch(searchText);
    },
    onSearchClose: () => {
      handleSearch('');
    },
    rowsPerPage: searchQuery.limit,
    rowsPerPageOptions: [10, 20, 50, 100],
    setRowProps: (row, dataIndex, rowIndex) => {
      return {
        onMouseEnter: e => handleRowHover(e, row, rowIndex),
        onMouseLeave: e => handleRowHoverLeave(e, row, rowIndex),
        ...(viewPermission
          ? {
              onDoubleClick: () => {
                history.push(
                  `/stores/view/${dataArray ? dataArray[dataIndex].store_id : ''}`
                );
              },
            }
          : {}),
      };
    },
    confirmFilters: true,

    // Calling the applyNewFilters parameter applies the selected filters to the table
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
      return (
        <div style={{ marginTop: '40px' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleFilterSubmit(applyNewFilters)}
          >
            Apply Filters
          </Button>
        </div>
      );
    },

    onFilterChange: (
      column,
      filterList,
      type,
      changedColumnIndex,
      displayData
    ) => {
      let selectedFilterOptionsKey = [];
      switch (column) {
        case 'region':
          selectedFilterOptionsKey = filterList[changedColumnIndex].map(
            val => val['region']
          );
          break;
        case 'states':
          selectedFilterOptionsKey = filterList[changedColumnIndex].map(
            val => val['district']
          );
          break;

        default:
          selectedFilterOptionsKey = [];
      }
      setSelectedFilters(prevState => {
        return { ...prevState, [column]: selectedFilterOptionsKey };
      });
    },
    pagination: !loading,
  };

  const tableLoader = useMemo(() => {
    return TableColumnsLoader(dataTableColumns, {
      rows: searchQuery?.limit,
      isValue: false,
    });
  }, [dataTableColumns]);

  return (
    <>
      <Grid container spacing={2} direction="column">
        {alert.exists && (
          <Grid item>
            {' '}
            <Alert />
          </Grid>
        )}
        <Grid
          container
          item
          direction="row"
          justifyContent="space-between"
          spacing={2}
        >
          <Grid item>
            <PageHeader
              pageTitle="Stores"
              breadCrumbArray={clientsBreadcrumb}
            />
          </Grid>

          {checkPermission(permissions?.stores?.storesAdd) && (
            <Grid item classes={{ root: classes.selfAlignGrid }}>
              <Grid item>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => history.push(`/stores/add`)}
                >
                  Add New
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item classes={{ root: classes.dataTableWrapper }}>
          <GenericDataTable
            columns={dataTableColumns}
            data={loading ? tableLoader : dataArray}
            options={dataTableOptions}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Stores;
