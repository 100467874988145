import { Tooltip } from 'primereact/tooltip';
import { Tag } from 'primereact/tag';
import moment from 'moment';

import PFButton from '../../../shared/PFPrime/PFButton';
import { isMeasureSquareEnabled } from '../../../../utils/Helpers';

export const opportunityGridColumns = ({ customerId, history }) => {
  let main = [
    {
      field: '',
      header: 'Actions',
      sortable: false,
      filter: false,
      body: (rowData, option) => (
        <div className="flex w-max">
          <Tooltip target=".quote-button" />
          <Tooltip target=".project-button" />
          <PFButton
            icon="pi pi-eye"
            outlined
            text
            className="text-white border-none"
            severity="primary"
            aria-label="View"
            onClick={() =>
              history.push(
                `/crm/${customerId}/lead/view/${rowData.opportunityId}`
              )
            }
          />
          <PFButton
            icon="pi pi-pencil"
            outlined
            text
            className="text-white border-none"
            severity="primary"
            aria-label="Edit"
            onClick={() =>
              history.push(
                `/crm/${customerId}/lead/edit/${rowData.opportunityId}`
              )
            }
          />
          <span
            className="quote-button"
            data-pr-tooltip="Quote"
            data-pr-position="top"
          >
            <PFButton
              icon="pi pi-file"
              outlined
              text
              className="text-white border-none"
              severity="secondary"
              aria-label="Quote"
              onClick={() => {
                history.push(
                  `/crm/${customerId}/quotes/${rowData.opportunityId}?private=${true}`
                );
              }}
            />
          </span>
          {isMeasureSquareEnabled(
            rowData?.from_external_source || rowData?.from_private_lead
          ) && (
            <span
              className="project-button"
              data-pr-tooltip={
                rowData?.from_private_lead
                  ? 'View Private Lead Project'
                  : 'View Measurement Project'
              }
              data-pr-position="top"
            >
              <PFButton
                icon="pi pi-file-import"
                outlined
                text
                className="text-white border-none"
                severity="secondary"
                aria-label="Projects"
                onClick={() => {
                  window.open(
                    `/crm/${rowData?.customerId}/${rowData?.opportunityId}/project/view/${rowData?.external_project_id}`
                  );
                }}
              />
            </span>
          )}
        </div>
      ),
    },
    {
      field: 'opportunityNumber',
      header: 'Lead#',
      sortable: true,
      filter: false,
    },
    {
      field: 'description',
      header: 'Description',
      sortable: true,
      filter: false,
    },
    {
      field: 'category',
      header: 'Category',
      sortable: true,
      filter: false,
    },
    {
      field: 'createdAt',
      header: 'Created On',
      sortable: true,
      filter: false,
      body: data => (
        <div className="w-10rem">
          {data.createdAt
            ? moment(data.createdAt).format('MM-DD-YYYY hh:mm A')
            : null}
        </div>
      ),
    },
    {
      field: 'follow_up_date',
      header: 'Follow Up Date',
      sortable: true,
      filter: false,
      body: data =>
        data.follow_up_date
          ? moment.utc(data.follow_up_date).local().format('MM-DD-YYYY')
          : null,
    },
    {
      field: 'sourceName',
      header: 'Source',
      sortable: true,
      filter: false,
    },
    {
      field: 'status',
      header: 'Status',
      sortable: true,
      filter: false,
      body: data => {
        return <Tag value={data.status} severity="info"></Tag>;
      },
    },
  ];

  return main;
};
