import moment from 'moment-timezone';
import jwt from 'jwt-decode';
import { parseISO } from 'date-fns';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import dayJsTimezone from 'dayjs/plugin/timezone';
import isBetween from 'dayjs/plugin/isBetween';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import {
  REACT_APP_AUTH_CLIENT_ID,
  REACT_APP_PF_ADMIN_ID,
} from '../constants/envConstants';
import { SESSION_LINKS } from '../components/Admin/Dashboards/constants/constants';
import {
  dateFilters,
  globalDashboardDefaultFilters,
  globalFilters,
} from '../constants';

dayjs.extend(utc);
dayjs.extend(dayJsTimezone);
dayjs.extend(customParseFormat);
dayjs.extend(isBetween);

const DEFAULT_TIMEZONE = 'US/Eastern';

export const setMomentTimezone = (timezone = DEFAULT_TIMEZONE) => {
  moment.tz.setDefault(timezone);
  dayjs.tz.setDefault(timezone);
};

export const getLocalTimeZone = () =>
  localStorage.getItem('client_timezone') || DEFAULT_TIMEZONE;

export const momentTz = moment;

export const dayjsTz = dayjs.tz;

// *** Format Date in YYYY-MM-DD ***
export const formatDate = date =>
  date
    ? momentTz(parseISO(date?.toString()?.slice(0, 10))).format('MM-DD-YYYY')
    : '';

export const formatDateDefault = (date, divider = '/') => {
  if (typeof date === 'object') {
    const dt = date?.getDate();
    const month = date?.getMonth() + 1;
    const year = date?.getFullYear();
    return `${month}${divider}${dt}${divider}${year}`;
  } else if (typeof date === 'string' && date !== 'Invalid Date') {
    if (date.includes(',')) {
      date = date.split(',')[0];
    }
    return date;
  } else {
    return null;
  }
};

export const formatDateTimeMDY = date => {
  return date ? momentTz(date).format('MM-DD-YYYY') : '';
};
export const formatDateTimeMDYHM = date => {
  return date ? momentTz(date).format('MM-DD-YYYY hh:mm:ss A') : '';
};
export const formatTimeHM = date => {
  return date ? momentTz(date).format('hh:mm A') : '';
};
export const formatDateTime = date => {
  return date ? momentTz(date).format('MM-DD-YYYY hh:mm A') : '';
};

export const dayjsFormatDateTimeMDY = date => {
  return date ? dayjsTz(date).format('MM-DD-YYYY') : '';
};
export const dayjsFormatDateTimeMDYHM = date => {
  return date ? dayjsTz(date).format('MM-DD-YYYY hh:mm:ss A') : '';
};
export const dayjsFormatTimeHM = date => {
  return date ? dayjsTz(date).format('hh:mm A') : '';
};
export const dayjsFormatDateTime = date => {
  return date ? dayjsTz(date).format('MM-DD-YYYY hh:mm A') : '';
};

export const roundToX = (num, digitsToRoundoff) => {
  return +(Math.round(num + 'e+' + digitsToRoundoff) + 'e-' + digitsToRoundoff);
};

/**
 * Decode User Token.
 *
 * @param {*} encodedToken
 * @returns
 */
export const decodeUserToken = encodedToken => {
  return jwt(encodedToken);
};

/**
 * Check Permissions for User.
 *
 * @param {*} value Format Example : Projects.Items.add
 * Refer config/permissions.js
 * @returns boolean
 */
export const checkPermission = (
  value,
  pageTitle = null,
  permissionList = []
) => {
  const clientId = localStorage.getItem('client_id');
  const tokenPermissions = permissionList.length
    ? permissionList
    : JSON.parse(localStorage.getItem('permissions'));
  if (tokenPermissions?.includes(value)) {
    const localAuthClient = REACT_APP_AUTH_CLIENT_ID === 'test';
    if (pageTitle === 'Tenant Billing') {
      if (localAuthClient) {
        return clientId === '11MT97PY' ? true : false;
      } else {
        return clientId === REACT_APP_PF_ADMIN_ID ? true : false;
      }
    }
    return true;
  } else {
    return false;
  }
};

/**
 * Check whether email address is gmail or not.
 *
 * @param {*} value Email address
 * Refer config/permissions.js
 * @returns boolean
 */
export const checkGmail = value => {
  var regex = /^([a-zA-Z0-9_.+-])+@((gmail)+.)+([a-zA-Z0-9]{2,4})+$/;
  if (value) {
    return regex.test(value);
  } else {
    return false;
  }
};

export const support = () => {
  if (!window.DOMParser) return false;
  var parser = new DOMParser();
  try {
    parser.parseFromString('x', 'text/html');
  } catch (err) {
    return false;
  }
  return true;
};

export const textToHTML = str => {
  // check for DOMParser support
  if (support()) {
    var parser = new DOMParser();
    var doc = parser.parseFromString(str, 'text/html');
    return doc.body.innerText;
  }
  // Otherwise, create div and append HTML
  var dom = document.createElement('div');
  dom.innerText = str;
  return dom;
};

export const isIsoDate = str => {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
  const d = new Date(str);
  return d instanceof Date && !isNaN(d) && d.toISOString() === str; // valid date
};
export const isDateWithoutZ = str => {
  return /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}/.test(str); // To check date format of YYYY-MM-DDT00:00:00.000
};

export const getUrlExtension = url => {
  return url.split(/[#?]/)[0].split('.').pop().trim();
};

export const downloadFile = (
  docDownloadUrl,
  documentName,
  setDownloadDocumentLoading
) => {
  if (docDownloadUrl && documentName) {
    setDownloadDocumentLoading(true);
    fetch(docDownloadUrl).then(response => {
      response.blob().then(blob => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = documentName;
        a.click();
        setDownloadDocumentLoading(false);
      });
    });
  }
};

export const checkDollarSign = str => {
  var pattern = new RegExp('[$]');
  if (!pattern.test(str)) return false;
  const d = String(str);
  return d === str; // valid string
};

const accessKeyFromObject = (obj, key) => {
  if (key.includes('.')) {
    let newValue = obj;
    key.split('.').forEach(element => {
      if (newValue) {
        newValue = newValue[element];
      }
    });
    return newValue;
  }
  return obj[key];
};

export const tableValueFormatter = (headers, val) => {
  let value = headers.value_accessor
    ? accessKeyFromObject(val, headers.value_accessor)
    : '';
  if (headers.prefix && accessKeyFromObject(val, headers.value_accessor)) {
    value = `${headers.prefix} ${accessKeyFromObject(val, headers.value_accessor)}`;
  }
  if (
    headers.type === 'date' &&
    accessKeyFromObject(val, headers.value_accessor)
  ) {
    value = formatDate(val[headers.value_accessor]);
  }
  if (
    headers.fixToDecimal &&
    accessKeyFromObject(val, headers.value_accessor)
  ) {
    value = parseFloat(
      accessKeyFromObject(val, headers.value_accessor)
    )?.toFixed(parseInt(headers.fixToDecimal));
  }
  if (
    headers.fixToDecimal &&
    headers.prefix &&
    accessKeyFromObject(val, headers.value_accessor)
  ) {
    value = `${headers.prefix} ${parseFloat(
      accessKeyFromObject(val, headers.value_accessor)
    )?.toFixed(parseInt(headers.fixToDecimal))}`;
  }
  return value;
};

export const random_rgba = () => {
  return 'rgb(0, 0, ' + Math.floor(Math.random() * 255) + ')';
};

// For displaying time in tables
export const convert24HourTimeTo12 = timeString => {
  if (timeString) {
    let [hours, minutes] = timeString?.split(':');
    const meridiem = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    return hours + ':' + minutes + ' ' + meridiem;
  }
  return null;
};

// For setting timePicker values from API response
export const convertTimeForTimePickerValues = timeString => {
  if (timeString) {
    const [hours, minutes] = timeString?.split(':');
    return hours + ':' + minutes;
  }
  return null;
};

// For setting datetime-locale format value from date string
export const convertDateToLocaleString = dateString => {
  return momentTz(dateString).format('YYYY-MM-DDTHH:mm');
};

export const getBulletinMessage = bulletinObj => {
  if (bulletinObj?.is_important && bulletinObj?.url) {
    return (
      <>
        <span className="text-color">{bulletinObj.message}</span>
        <a
          href={bulletinObj.url}
          target="_blank"
          className="text-xs ml-2 text-primary"
          rel="noreferrer"
        >
          Read more
        </a>
      </>
    );
  }
  if (bulletinObj.is_important) {
    return <b>{bulletinObj.message}</b>;
  }
  if (bulletinObj.url) {
    return (
      <>
        <span className="text-color">{bulletinObj.message}</span>
        <a
          href={bulletinObj.url}
          target="_blank"
          className="text-xs ml-2 text-primary"
          rel="noreferrer"
        >
          Read more
        </a>
      </>
    );
  }
  return bulletinObj.message;
};

export const getLoginUserId = () => localStorage.getItem('user_id');

export const getCurrentClientId = () => localStorage.getItem('client_id');

export const generateGradient = () => {
  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const colors = [getRandomColor(), getRandomColor()];

  const angle = Math.floor(Math.random() * 361); // Random angle between 0 and 360 degrees

  return `linear-gradient(${angle}deg, ${colors[0]}80, ${colors[1]}80)`; // Added "80" after the colors for 50% opacity
};

export const toTitleCase = inputString => {
  const words = inputString?.split(' ');
  const titleCaseWords = words?.map(word => {
    if (word?.length === 1) {
      return word?.toUpperCase();
    }
    return word?.charAt(0)?.toUpperCase() + word?.slice(1)?.toLowerCase();
  });
  const titleCaseString = titleCaseWords?.join(' ');
  return titleCaseString;
};

export const getUniqueArrayItemsByKey = (inputArray, key) => {
  if (Array.isArray(inputArray) && inputArray?.length > 0) {
    const uniqueTimeOffRequests = {};
    const result = inputArray?.filter(item => {
      if (!uniqueTimeOffRequests[item?.timeOffRequestId]) {
        uniqueTimeOffRequests[item?.timeOffRequestId] = true;
        return true;
      }
      return false;
    });
    return result;
  } else {
    return [];
  }
};

export const isValidHttpUrl = string => {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
};

const findCategoryType = (addressComponents, type) => {
  return addressComponents.find(address => address.types.includes(type));
};

export const transformAddressFromGeocode = addressComponents => {
  const address = {
    addressLineOne: findCategoryType(addressComponents, 'street_number')
      ?.long_name,
    addressLineTwo: findCategoryType(addressComponents, 'route')?.long_name,
    city: findCategoryType(addressComponents, 'locality')?.long_name,
    state: findCategoryType(addressComponents, 'administrative_area_level_1')
      ?.long_name,
    zipcode: findCategoryType(addressComponents, 'postal_code')?.long_name,
  };

  return address;
};

export const isRoEnabled = () => {
  const clientDetails = JSON.parse(localStorage.getItem('client_details'));
  if (clientDetails?.routing_module) {
    return true;
  }
  return false;
};

export const validateCoordinates = (latitude, longitude) => {
  if (
    isNaN(latitude) ||
    isNaN(longitude) ||
    latitude < -90 ||
    latitude > 90 ||
    longitude < -180 ||
    longitude > 180
  ) {
    return false;
  }
  return true;
};

export const hasSpecialCharacters = inputString => {
  var specialCharacterPattern = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\|\-=]/;
  return specialCharacterPattern.test(inputString);
};

export const containsNumbers = inputString => {
  const regex = /\d/;
  return regex.test(inputString);
};

export const getInitials = name => {
  if (!name) return '';
  const rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');
  const initials = [...name.matchAll(rgx)] || [];
  return (
    (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
  )?.toUpperCase();
};

export const removeExtraSpaces = inputString => {
  if (!inputString) return '';
  let cleanedString = inputString.replace(/\s+/g, ' ');
  cleanedString = cleanedString.trim();
  return cleanedString;
};

export const isValidObject = data =>
  !Array.isArray(data) &&
  data instanceof Object &&
  data.constructor.name == 'Object';

const handleQueryParamValue = value => {
  if (Array.isArray(value)) return value.join(',');
  return value;
};
export const convertToQueryParams = params => {
  let queryParams = '',
    isFirst = true;
  if (isValidObject(params)) {
    for (const key in params) {
      if (isFirst) {
        queryParams += `?${key}=${handleQueryParamValue(params[key])}`;
        isFirst = false;
      } else {
        queryParams += `&${key}=${handleQueryParamValue(params[key])}`;
      }
    }
  }
  return queryParams;
};
export const isCrmEnabled = () => {
  const clientDetails = JSON.parse(localStorage.getItem('client_details'));
  if (clientDetails?.crm_module) {
    return true;
  }
  return false;
};

export const isWarrantyEnabled = () => {
  const clientDetailsString = localStorage.getItem('client_details');
  if (clientDetailsString) {
    try {
      const clientDetails = JSON.parse(clientDetailsString);

      if (clientDetails && clientDetails.warranty_module) {
        return true;
      }
    } catch (error) {
      return false;
    }
  }
  return false;
};

export const convertHexToRgb = hexColor => {
  // remove # symbol from the hex color code
  let hex = hexColor.replace('#', '');
  // convert the hex color to RGB color
  let red = parseInt(hex.substring(0, 2), 16);
  let green = parseInt(hex.substring(2, 4), 16);
  let blue = parseInt(hex.substring(4, 6), 16);
  // set the RGB color value
  return [red, green, blue];
};

export const checkIfNull = value => {
  return value === null;
};

export const capitalizeFirstLetter = string => {
  if (!string) return null;
  const lowerString = string.toLowerCase();
  return lowerString.charAt(0).toUpperCase() + lowerString.slice(1);
};

export const getUserTimeZone = () => {
  const localStorageTimeZone = localStorage.getItem('client_timezone');
  return localStorageTimeZone?.length > 0
    ? localStorageTimeZone
    : moment.tz.guess();
};

export const getConvertedDate = (timeZone, inputDate) => {
  if (!inputDate) return null;
  const resultDate = timeZone
    ? moment(inputDate).tz(timeZone)
    : moment(inputDate);
  return resultDate.format('MM-DD-YYYY');
  try {
  } catch (error) {
    return null;
  }
};

export const calculateAllocationPercentage = (laborItems, projectItemId) => {
  try {
    let sum = 0;
    for (
      let projectIndex = 0;
      projectIndex < laborItems.length;
      projectIndex++
    ) {
      const pi = laborItems[projectIndex];
      if (pi.project_item_id === projectItemId) {
        sum += pi.project_item_user?.percentage
          ? pi.project_item_user?.percentage
          : 0;
      }
    }
    return sum;
  } catch (error) {
    return 0;
  }
};

export const check100PercentAllocation = laborItems => {
  const laborSet = new Set();
  for (let i = 0; i < laborItems?.length; i++) {
    const li = laborItems[i];
    if (!laborSet.has(li.project_item_id)) {
      laborSet.add(li.project_item_id);
      const allocationPercentage = calculateAllocationPercentage(
        laborItems,
        li.project_item_id
      );
      if (allocationPercentage !== 100) {
        return false;
      }
    }
  }
  return true;
};

export const formatCurrency = input => {
  // Parse the input as a number
  const number = parseFloat(input);

  // Check if the input is a valid number
  if (isNaN(number)) {
    return 'Invalid input';
  }

  // Format the number as a currency string with commas
  const formattedCurrency = number.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD', // You can change the currency code as needed
  });

  return formattedCurrency;
};

export const formatNumber = (
  value,
  decimalPlaces = 2,
  forceDecimal = false
) => {
  const parsedValue = parseFloat(value);
  if (!isNaN(parsedValue)) {
    if (Number.isInteger(parsedValue)) {
      if (forceDecimal) return `${parseInt(value)}.00`;
      else return parseInt(value);
    }

    const decimalStr = parsedValue.toString();
    const parts = decimalStr.split('.');
    if (parts.length < 2) {
      return value;
    }

    if (parts[1].length === 1) parts[1] = `${parts[1]}0`;
    if (parts[1].length === 0) parts[1] = `00`;

    const decimalPart = `${parts[0]}.${parts[1].slice(0, 2)}`;
    return decimalPart;
  }
  return null;
};

export const openNewWindow = url => {
  window.open(url, '_blank', 'noopener,noreferrer');
};

export const isPaymentEnabled = () => {
  const clientDetails = JSON.parse(localStorage.getItem('client_details'));
  if (
    clientDetails?.clientOnboarding &&
    clientDetails?.clientOnboarding?.onboarding_status_id &&
    clientDetails?.clientOnboarding?.onboarding_status_id == 342
  ) {
    return true;
  }
  return false;
};
export const redirectPage = (url, redirectionMethod = '_blank') => {
  if (redirectionMethod === '_self') {
    window.location.replace(url);
  } else {
    window.open(url, '_blank', 'noopener,noreferrer');
  }
};

//Function to validate email
export const isEmail = email => {
  const regex =
    /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  if (!regex.test(email)) {
    return false;
  } else {
    return true;
  }
};

export const floatParser = value => {
  if (!isNaN(value)) {
    const no = parseFloat(value);
    return no > 0 ? no.toFixed(2) : '0.00';
  } else {
    return '0.00';
  }
};

// Function to check given value in time format
export const isValidTimeFormat = inputString => {
  // Regular expression to match the format HH:MM:SS
  const timeRegex = /^\d{2}:\d{2}:\d{2}$/;
  return timeRegex.test(inputString);
};

export const onlyLettersAndNumbers = str => {
  const regexPattern =
    /^[0-9a-f]{8}-[0-9a-f]{4}-11ee-[0-9a-f]{4}-[0-9a-f]{12}$/i;
  return regexPattern.test(str);
};
export const isMeasureSquareEnabled = (isRequired = 0) => {
  let res = false;
  const clientDetails = JSON.parse(localStorage.getItem('client_details'));
  if (
    (clientDetails?.is_measuresquare ||
      clientDetails?.create_project_private_lead) &&
    Boolean(parseInt(isRequired))
  ) {
    res = true;
  }

  return res;
};

export const isMeasureSquareHDPOEnabled = () => {
  let res = false;
  const clientDetails = JSON.parse(localStorage.getItem('client_details'));
  if (
    clientDetails?.is_measuresquare &&
    clientDetails?.project_linkage?.hd_po
  ) {
    res = true;
  }
  return res;
};

export const isMeasureSquareHDLeadEnabled = () => {
  let res = false;
  const clientDetails = JSON.parse(localStorage.getItem('client_details'));
  if (
    clientDetails?.is_measuresquare &&
    clientDetails?.project_linkage?.hd_lead
  ) {
    res = true;
  }
  return res;
};

export const lockGlobalBar = () => {
  let res = false;
  const clientDetails = JSON.parse(localStorage.getItem('client_details'));
  if (clientDetails?.fixed_top_bar && clientDetails?.fixed_top_bar) {
    res = true;
  }
  return res;
};

export const shippingAndReceivingModule = () => {
  let res = false;
  const clientDetails = JSON.parse(localStorage.getItem('client_details'));
  if (clientDetails?.shipping_receiving && clientDetails?.shipping_receiving) {
    res = true;
  }
  return res;
};

export const lockProjectBar = () => {
  let res = false;
  const clientDetails = JSON.parse(localStorage.getItem('client_details'));
  if (clientDetails?.fixed_project_bar && clientDetails?.fixed_project_bar) {
    res = true;
  }
  return res;
};

export const addDays = (date, days) => {
  date.setDate(date.getDate() + days);
  return date;
};

// Helper to format date using dayjs
export const dateFormatter = (value, dateFormat) => {
  return dayjs(value).format(dateFormat);
};

export const selectedRapidSlot = selectInfo => {
  const startSlotTime = new Date(selectInfo?.start);
  const endSlotTime = new Date(selectInfo?.end);
  startSlotTime?.setMinutes(startSlotTime?.getMinutes() + 60);

  const timeDifference = endSlotTime?.getTime() - startSlotTime?.getTime();
  const minutesDifference = timeDifference / (1000 * 60);
  return { minutesDifference, startSlotTime };
};
export const createDateFromTimeString = timeString => {
  if (timeString.includes('undefined')) {
    return null;
  }
  const [hours, minutes] = timeString?.split(':')?.map(Number);
  const currentDate = new Date();
  currentDate?.setHours(hours);
  currentDate?.setMinutes(minutes);
  currentDate?.setSeconds(0);
  return currentDate;
};

export const getTimeFromDate = date => {
  const hours = String(date?.getHours())?.padStart(2, '0');
  const minutes = String(date?.getMinutes())?.padStart(2, '0');
  const formattedTime = `${hours}:${minutes}`;
  return formattedTime;
};

export const convertTo12HourFormat = time24Hour => {
  const [hours, minutes] = time24Hour.split(':').map(Number);
  const meridiem = hours >= 12 ? 'PM' : 'AM';
  let hours12Hour = hours % 12;
  hours12Hour = hours12Hour === 0 ? 12 : hours12Hour;
  const formattedTime = `${hours12Hour}:${String(minutes).padStart(2, '0')} ${meridiem}`;
  return formattedTime;
};

export const convertToMinutes = time => {
  const [hours, minutes] = time.split(':').map(Number);
  return hours * 60 + minutes;
};

export const timeDifference = (time1, time2) => {
  const minutes1 = convertToMinutes(time1);
  const minutes2 = convertToMinutes(time2);
  const differenceInMinutes = Math.abs(minutes1 - minutes2);
  const hoursDifference = Math.floor(differenceInMinutes / 60);
  const minutesDifference = differenceInMinutes % 60;
  const formattedDifference =
    (hoursDifference < 10 ? '0' : '') +
    hoursDifference +
    ':' +
    (minutesDifference < 10 ? '0' : '') +
    minutesDifference;
  return formattedDifference;
};

export const isStartTimeLessThanEndTime = (start, end) => {
  const [startHours, startMinutes] = start.split(':').map(Number);
  const [endHours, endMinutes] = end.split(':').map(Number);
  if (
    startHours < endHours ||
    (startHours === endHours && startMinutes < endMinutes)
  ) {
    return true;
  }
  return false;
};

export const updateArrayWithMatchingDays = (firstArray, secondArray) => {
  firstArray.forEach((item, index) => {
    const matchingItem = secondArray.find(
      secondItem => secondItem.day === item.day
    );
    if (matchingItem) {
      firstArray[index] = { ...matchingItem };
    }
  });
  return firstArray;
};
// Helper to check future date
export const checkFutureDate = value => {
  return dayjs(value).isAfter(dayjs());
};

export const findTechnician = value => {
  const stringValue = JSON?.stringify(value);
  return /\btechnician\b/.test(stringValue);
};

export const checkDefaultPo = value => {
  return value?.includes('default-po');
};

export const showFirst30Characters = value => {
  if (value?.length > 30) {
    return value?.substring(0, 30) + '...';
  }
  return value;
};

export const getLocalClientDetails = () => {
  return JSON.parse(localStorage.getItem('client_details'));
};
export const checkUrlString = (value, source) => {
  return value?.includes(source);
};
export const disabledLoadMore = totalCount => {
  return Math.ceil(totalCount / 10);
};
export const getDefaultSchedulerView = () => {
  let defaultView = 'day';
  const client_details = getLocalClientDetails();
  if (client_details?.default_calender_view) {
    return (defaultView = client_details?.default_calender_view);
  }
  return defaultView;
};

export const getDefaultTechnicianSchedulerView = () => {
  let defaultView = 'resourceTimelineDay';
  const client_details = getLocalClientDetails();
  if (client_details?.default_calender_view) {
    switch (client_details.default_calender_view.toLowerCase()) {
      case 'day':
        defaultView = 'resourceTimelineDay';
        break;
      case 'week':
        defaultView = 'resourceTimelineWeek';
        break;
      case 'month':
        defaultView = 'resourceTimelineMonth';
        break;
      default:
        defaultView = 'resourceTimelineDay';
    }
  }
  return defaultView;
};

export const getPeriod = type => {
  let period;
  switch (type) {
    case 'resourceTimelineDay':
      period = 'D';
      break;
    case 'resourceTimelineWeek':
      period = 'W';
      break;
    case 'resourceTimelineMonth':
      period = 'M';
      break;
  }
  return period;
};

export const ellipsisString = (value, maxValue) => {
  if (value?.length > maxValue) {
    return value?.substring(0, maxValue) + '...';
  }
  return value;
};

export const getPercentageValue = value => {
  const percentageValue = value > 100 ? 0 : Math.max(0, value);
  return percentageValue;
};

export const getInitialsPR = name => {
  let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');
  let initials = [...name.matchAll(rgx)] || [];
  initials = (
    (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
  ).toUpperCase();
  return initials;
};

export const handleMasterSearch = (searchString, dataList, key) => {
  const value = (searchString || '')?.toLowerCase();
  if (value?.length > 0) {
    const filteredData =
      dataList?.length > 0 &&
      dataList?.filter(item => item[key]?.toLowerCase()?.includes(value));
    return filteredData;
  } else {
    return Array.isArray(dataList) ? dataList?.slice(0, 10) : [];
  }
};
export const doesKeyExistInArray = (value, key) => {
  if (value) {
    const isKeyPresent = value?.some(item => item?.hasOwnProperty(key));
    if (isKeyPresent) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};
export const openWindowInNewTab = url => {
  if (url) {
    window.open(url, '_blank');
  } else {
    console.error('No URL provided');
  }
};

export const removeLinkSession = () => {
  sessionStorage.removeItem(SESSION_LINKS.VIEW_LINKS);
  sessionStorage.removeItem(SESSION_LINKS.EDIT_LINKS);
};

export const formatPhoneNumber = phone => {
  return phone ? phone.replace(/[^0-9]/g, '') : '';
};

export const formatNagativeCurrency = value => {
  const num = parseFloat(value);
  if (num < 0) {
    return `-$${Math.abs(num).toFixed(2)}`;
  } else {
    return `$${num.toFixed(2)}`;
  }
};
export const hasAnyValue = filters => {
  return Object.values(filters)?.some(
    value => value !== '' && value !== null && value !== undefined
  );
};

export const prepareDashboardFilters = values => {
  const filterMap = new Map();
  const dateFilterMap = new Map();
  // Handle filters
  const filterArray = globalFilters;
  const defaultFilterArray = globalDashboardDefaultFilters;

  if (Object.keys(values?.defaultFilter)?.length) {
    defaultFilterArray?.map(filter => {
      if (
        Array.isArray(values?.defaultFilter?.[filter?.value]) &&
        values?.defaultFilter?.[filter?.value]?.length > 0
      ) {
        filterMap.set(filter?.key, {
          member: filter?.key,
          values: values?.defaultFilter?.[filter?.value]?.map(item =>
            item?.toString()
          ),
          operator: 'equals',
        });
      }
    });
  } else {
    filterArray?.map(filter => {
      if (
        Array.isArray(values?.[filter?.value?.[0]]) &&
        values?.[filter?.value?.[0]]?.length > 0
      ) {
        filterMap.set(filter?.key, {
          member: filter?.key,
          values: values?.[filter?.value?.[0]]?.map(item =>
            item?.[filter?.value?.[1]]?.toString()
          ),
          operator: 'equals',
        });
      }
    });
  }

  // Handle Date Filters
  const dateFiltersArray = dateFilters;
  dateFiltersArray?.map(item => {
    if (
      values[item?.value?.[0]]?.length > 0 ||
      values[item?.value?.[1]].length > 0
    ) {
      const startDate =
        values[item?.value?.[0]]?.length > 0
          ? dateFormatter(values[item?.value?.[0]], 'YYYY-MM-DD')
          : null;
      const endDate = dateFormatter(values[item?.value?.[1]], 'YYYY-MM-DD');

      const newCondition = {
        dimension: item?.key,
        dateRange: [startDate, endDate],
      };
      dateFilterMap.set(item?.key, newCondition);
    }
  });

  const appliedFilters = Array.from(filterMap.values());
  const appliedDateFilters = Array.from(dateFilterMap.values());

  return [appliedFilters, appliedDateFilters];
};
export const sendTentativeScheduleNoteToSource = () => {
  let res = false;
  const clientDetails = JSON.parse(localStorage.getItem('client_details'));
  if (
    clientDetails?.send_tentative_schedule_note_to_source &&
    clientDetails?.send_tentative_schedule_note_to_source
  ) {
    res = true;
  }
  return res;
};

export const splitAndGetValues = value => {
  const splitValues = typeof value === 'string' ? value.split(' / ') : [];

  const newValue = splitValues?.map(value => ({
    id: value,
    name: value,
    is_split: 1,
  }));
  return newValue;
};
