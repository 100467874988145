import React, { useState, forwardRef, useImperativeHandle, memo } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import {
  convertToRaw,
  ContentState,
  EditorState,
  Modifier,
  SelectionState,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const TextEditor = memo(
  forwardRef(({ value, handleTextEditiorChange, readOnly }, ref) => {
    const prepareDraft = value => {
      const draft = htmlToDraft(value || '');
      const contentState = ContentState.createFromBlockArray(
        draft.contentBlocks,
        draft.entityMap
      );
      return EditorState.createWithContent(contentState);
    };

    const [editorState, setEditorState] = useState(
      value ? prepareDraft(value) : EditorState.createEmpty()
    );

    const onEditorStateChange = editorState => {
      const value = draftToHtml(convertToRaw(editorState.getCurrentContent()));
      handleTextEditiorChange(value);
      setEditorState(editorState);
    };

    const insertPlaceholderAtCursor = placeholder => {
      const selection = editorState.getSelection();
      const contentState = editorState.getCurrentContent();

      if (selection.isCollapsed()) {
        const contentStateWithPlaceholder = Modifier.insertText(
          contentState,
          selection,
          `{{${placeholder}}}`
        );
        const newEditorState = EditorState.push(
          editorState,
          contentStateWithPlaceholder,
          'insert-characters'
        );
        setEditorState(newEditorState);
        handleTextEditiorChange(
          draftToHtml(convertToRaw(contentStateWithPlaceholder))
        );
      }
    };

    useImperativeHandle(ref, () => ({
      setValue(newValue) {
        const newEditorState = prepareDraft(newValue);
        setEditorState(newEditorState);
      },
      insertPlaceholder(placeholder) {
        insertPlaceholderAtCursor(placeholder);
      },
    }));

    return (
      <Editor
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        readOnly={readOnly}
        editorStyle={{ height: '150px', opacity: readOnly ? 0.6 : 1 }}
      />
    );
  })
);

export default TextEditor;
