export const TECHNICIAN_COLUMNDATA = [
  {
    id: 'installer',
    value: 'Technician',
  },
  {
    id: 'type',
    value: 'Type',
  },
  {
    id: 'scheduleDate',
    value: 'Schedule Date and Time',
  },
  {
    id: 'projectEndDate',
    value: 'Project End Date and Time',
  },
  {
    id: 'installer_arrival_start_time',
    value: 'Arrival Start Time',
  },
  {
    id: 'installer_arrival_end_time',
    value: 'Arrival End Time',
  },
  {
    id: 'laborAmount',
    value: 'Labor Amount',
  },
];
