// **** React Imports ****
import React, { Fragment, useMemo } from 'react';

// **** Utilities ****
import { Grid, Button, IconButton, CircularProgress } from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import moment from 'moment';

import GenericDataTable from '../../shared/GenericDataTable/GenericDataTable';
import TableColumnsLoader from '../../shared/Loader/tableColumnsLoader';

import { getProjectEmailData } from './EmailNotifications.service';

// **** Styles *****
import { useStyles } from './EmailNotifications.styles';

const EmailListView = React.memo(
  ({ project_id, showPanel, setShowPanel, setMessageId, setAlert }) => {
    const classes = useStyles();
    const [apiRunning, setApiRunning] = React.useState(false);
    const [searchQuery, setSearchQuery] = React.useState({
      limit: 10,
      offset: 0,
    });
    const [emailList, setEmailList] = React.useState({ count: 0, items: [] });

    React.useEffect(() => {
      getProjectEmailData(
        project_id,
        setEmailList,
        setApiRunning,
        searchQuery,
        setAlert
      );
    }, [searchQuery, showPanel]);

    let intervalVal = '';
    const handleSearch = searchInputVal => {
      const search = searchInputVal?.trim();

      /** Timeout will help to let multiple characters in the TextInput,* and API call would be optimized to get Values for Input of few chars at once,* rather than for every single character*/
      try {
        clearTimeout(intervalVal);
      } catch (err) {
        console.log(err);
      }
      intervalVal = setTimeout(() => {
        setApiRunning(true);
        setSearchQuery(prevState => ({
          ...prevState,
          offset: 0,
          search: search,
        }));
      }, 500);
    };

    function handleRowHover(event, row, rowIndex) {
      let control = document.getElementById(rowIndex);
      control.style.display = 'block';
    }

    function handleRowHoverLeave(event, row, rowIndex) {
      let control = document.getElementById(rowIndex);
      if (control?.style?.display) control.style.display = 'block';
    }
    let dataArray =
      emailList?.items?.map((email, index) => {
        return {
          name: email?.LastEmail?.from[0]['name'] || '',
          subject: email?.subject || '',
          date: email?.created_at
            ? moment(email?.created_at).format('MM-DD-YYYY hh:mm A')
            : '',
          message_id: email?.id,
        };
      }) || [];

    const dataTableOptions = {
      download: false,
      print: false,
      filter: false,
      viewColumns: false,
      selectableRows: 'none',
      responsive: 'standard',
      filterType: 'multiselect',
      tableBodyMinHeight: '300px',
      count: emailList?.count,
      serverSide: true,
      textLabels: {
        body: {
          noMatch:
            (apiRunning && '') ||
            (!apiRunning && !dataArray.length && 'No records found'),
        },
      },
      onChangeRowsPerPage: numberOfRows => {
        setApiRunning(true);
        setSearchQuery(prevState => ({
          ...prevState,
          limit: numberOfRows,
          offset: 0,
        }));
      },
      onChangePage: currentPage => {
        setApiRunning(true);
        setSearchQuery(prevState => ({
          ...prevState,
          offset: currentPage * searchQuery.limit,
        }));
      },
      onColumnSortChange: (changedColumn, direction) => {
        setApiRunning(true);
        setSearchQuery(prevState => ({
          ...prevState,
          sortKey: changedColumn,
          sortDirection: direction.toUpperCase(),
        }));
      },
      onSearchChange: searchText => {
        handleSearch(searchText);
      },
      onSearchClose: () => {},
      searchProps: {
        onKeyUp: e => {
          if (e.target.defaultValue && e.keyCode === 13) {
            setApiRunning(true);
            setSearchQuery(prevState => ({
              ...prevState,
              search: e.target.defaultValue,
              offset: 0,
            }));
          }
        },
      },
      onFilterChange: (changedColumn, filterList) => {
        console.log(changedColumn, filterList);
      },
      rowsPerPage: searchQuery.limit,
      rowsPerPageOptions: [10, 20, 50, 100],
      setRowProps: (row, dataIndex, rowIndex) => {
        return {
          onMouseEnter: e => handleRowHover(e, row, rowIndex),
          onMouseLeave: e => handleRowHoverLeave(e, row, rowIndex),
          onDoubleClick: () => {
            setMessageId(dataArray[dataIndex]?.message_id);
            setShowPanel('detailView');
          },
        };
      },
      pagination: !apiRunning,
    };

    const dataTableColumns = [
      {
        name: 'name',
        label: 'Name',
        options: {
          filter: true,
          sort: false,
        },
      },
      {
        name: 'subject',
        label: 'Subject',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'date',
        label: 'Date',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: '',
        options: {
          sort: false,
          filter: false,
          viewColumns: false,
          ...(!apiRunning
            ? {
                customBodyRenderLite: dataIndex => {
                  return (
                    <span className={classes.listReviewed}>
                      {emailList?.items[dataIndex]?.LastEmail?.reviewed
                        ? 'Reviewed'
                        : null}
                    </span>
                  );
                },
              }
            : {}),
        },
      },
      {
        name: '',
        options: {
          sort: false,
          filter: false,
          viewColumns: false,
          ...(!apiRunning
            ? {
                customBodyRenderLite: dataIndex => {
                  return (
                    <span style={{ display: 'inline' }} id={dataIndex}>
                      {emailList?.items[dataIndex]?.LastEmail?.attachments
                        ?.length ? (
                        <span>
                          <IconButton classes={{ root: classes.actionIcons }}>
                            <LinkIcon />
                          </IconButton>
                        </span>
                      ) : null}
                    </span>
                  );
                },
              }
            : {}),
        },
      },
    ];

    const tableLoader = useMemo(() => {
      return TableColumnsLoader(dataTableColumns, {
        rows: searchQuery?.limit,
        isValue: false,
      });
    }, [dataTableColumns]);
    return (
      <Fragment>
        <Grid item>
          <Grid container direction="row" justifyContent="flex-end">
            <Button
              size="small"
              color="primary"
              variant="outlined"
              disableRipple
              classes={{ root: classes.buttonRoot }}
              onClick={() => {
                setShowPanel('createView');
              }}
            >
              Add New
            </Button>
          </Grid>
        </Grid>
        <br />
        <Grid item classes={{ root: classes.dataTableWrapper }}>
          <GenericDataTable
            title="Emails"
            columns={dataTableColumns}
            data={apiRunning ? tableLoader : dataArray}
            options={dataTableOptions}
          />
        </Grid>
      </Fragment>
    );
  }
);
export default EmailListView;
