import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Calendar } from 'primereact/calendar';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { Checkbox } from 'primereact/checkbox';
import dayjs from 'dayjs';
import * as Yup from 'yup';

import {
  addHoliday,
  getWorkHours,
  updateHolidayByHolidayId,
} from '../service/BusinessHourConfigService';
import { DAYS } from '../../../../constants';
import {
  dayjsFormatDateTime,
  isStartTimeLessThanEndTime,
} from '../../../../utils/Helpers';
import PFInputText from '../../../shared/PFPrime/PFInputText';
import PFButton from '../../../shared/PFPrime/PFButton';
import PFBlockUI from '../../../shared/PFPrime/PFBlockUI';

const AddHolidayCalendarDialog = ({
  clientId,
  isVisible,
  setIsVisible,
  holidayDetail,
  setHolidayCalendar,
}) => {
  if (!clientId) return <></>;
  const isEdit = !!holidayDetail?.holiday_id;
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);
  const validation = Yup.object().shape({
    date: Yup.date(),
    event_name: Yup.string(),
    start_time: Yup.date(),
    end_time: Yup.date(),
    is_full_day: Yup.boolean(),
  });

  const validateInput = (timeType, value) => {
    if ('event_name' === timeType) {
      if (!value || (typeof value === 'string' && !value.trim())) {
        formik.setErrors({
          event_name: 'Required',
        });
        return false;
      } else if (!isNaN(value)) {
        formik.setErrors({
          event_name: 'Invalid Event Name',
        });
        return false;
      } else return true;
    } else if ('date' === timeType) {
      if (!value) {
        formik.setErrors({
          date: 'Required',
        });
        return false;
      } else if (new Date(value).toDateString() === 'Invalid Date') {
        formik.setErrors({
          date: 'Invalid Date',
        });
        return false;
      } else return true;
    } else if ('is_full_day' === timeType) {
      if (!value && !formik?.values?.start_time && !formik?.values?.end_time) {
        formik.setErrors({
          is_full_day:
            'Start Time and End Time is required when Full Day is not selected',
        });
        return false;
      }
      return true;
    }
    if (!value && !formik?.values?.is_full_day) {
      formik.setErrors({
        [timeType]: 'Required',
      });
      return false;
    } else if (
      !formik?.values?.is_full_day &&
      new Date(value).toDateString() === 'Invalid Date'
    ) {
      formik.setErrors({
        [timeType]: 'Invalid Date',
      });
      return false;
    }
    const day = DAYS[dayjs(formik.values.date).day()];
    if (
      Array.isArray(formik.values.workHours) &&
      formik.values.workHours.length
    ) {
      const time = formik.values.workHours.find(eachDay => eachDay.day === day);
      if ('start_time' === timeType && !formik?.values?.is_full_day) {
        const formattedStartTime = dayjs(value).format('HH:mm');
        if (formik?.values?.end_time) {
          const et = dayjs(formik?.values?.end_time).format('HH:mm');
          if (isStartTimeLessThanEndTime(et, formattedStartTime)) {
            formik.setErrors({
              start_time: 'Start Time must be less than End Time',
            });
            return false;
          }
        }
        if (time && time.start_time) {
          if (isStartTimeLessThanEndTime(formattedStartTime, time.start_time)) {
            formik.setErrors({
              start_time: `Start Time cannot be less than the Start Time of ${day} Work Hour`,
            });
            return false;
          }
          if (time && time.end_time) {
            if (isStartTimeLessThanEndTime(time.end_time, formattedStartTime)) {
              formik.setErrors({
                start_time: `Start Time cannot be greater than the End Time of ${day} Work Hour`,
              });
              return false;
            }
          }
        }
      } else if ('end_time' === timeType && !formik?.values?.is_full_day) {
        const formattedEndTime = dayjs(value).format('HH:mm');
        if (formik?.values?.start_time) {
          const st = dayjs(formik?.values?.start_time).format('HH:mm');
          if (isStartTimeLessThanEndTime(formattedEndTime, st)) {
            formik.setErrors({
              end_time: 'End Time must be greater than Start Time',
            });
            return false;
          }
        }
        if (time && time.end_time) {
          if (isStartTimeLessThanEndTime(time.end_time, formattedEndTime)) {
            formik.setErrors({
              end_time: `End Time cannot be greater than the End Time of ${day} Work Hour`,
            });
            return false;
          }
          if (time && time.start_time) {
            if (isStartTimeLessThanEndTime(formattedEndTime, time.start_time)) {
              formik.setErrors({
                end_time: `End Time cannot be less than the Start Time of ${day} work hour`,
              });
              return false;
            }
          }
        }
      }
    }
    return true;
  };

  const handleSubmit = async values => {
    try {
      if (
        !validateInput('event_name', values.event_name) ||
        !validateInput('date', values.date) ||
        !validateInput('start_time', values.start_time) ||
        !validateInput('end_time', values.end_time) ||
        !validateInput('is_full_day', values.is_full_day)
      ) {
        return;
      }
      setLoading(true);
      const formattedDate = dayjs(values.date).format('MM-DD-YYYY');
      const formattedStartTime = dayjs(values.start_time).format('HH:mm:00');
      const formattedEndTime = dayjs(values.end_time).format('HH:mm:00');
      const payload = {
        event_name: values.event_name,
        start_date: `${formattedDate} ${formattedStartTime}`,
        end_date: `${formattedDate} ${formattedEndTime}`,
        is_full_day: !!values?.is_full_day,
      };
      if (payload.is_full_day) {
        const day = DAYS[dayjs(formik.values.date).day()];
        if (
          Array.isArray(formik.values.workHours) &&
          formik.values.workHours.length
        ) {
          const time = formik.values.workHours.find(
            eachDay => eachDay.day === day
          );
          if (time && time.start_time && time.end_time) {
            payload.start_date = `${formattedDate} ${time.start_time}`;
            payload.end_date = `${formattedDate} ${time.end_time}`;
          }
        }
      }
      const apiResponse = await (isEdit
        ? updateHolidayByHolidayId(holidayDetail?.holiday_id, payload)
        : addHoliday(clientId, payload));
      if (apiResponse?.status) {
        toast.current.show({
          severity: 'success',
          summary: apiResponse?.message || 'Record added Successfully',
          life: 3000,
        });
        if (isEdit) {
          setHolidayCalendar(prev => {
            const updated = prev.map(e => {
              if (holidayDetail?.holiday_id === e?.holiday_id)
                return apiResponse.data;
              return e;
            });
            return updated;
          });
        } else {
          setHolidayCalendar(prev => {
            prev.push(apiResponse.data);
            return prev;
          });
        }
        setTimeout(() => setIsVisible(false), 1000);
      } else {
        toast.current.show({
          severity: 'error',
          summary: apiResponse.message || 'Error occurred while adding record',
          life: 3000,
        });
      }
    } catch (ex) {
      console.error(ex);
      toast.current.show({
        severity: 'error',
        detail: (ex.response && ex.response.message) || 'Something went wrong',
        life: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (name, value) => {
    switch (name) {
      case 'event_name':
        formik.setFieldValue(name, value);
        break;
      case 'date':
        formik.setFieldValue(name, value);
        if (value) {
          const day = DAYS[dayjs(value).day()];
          let isTimeFound = false;
          if (
            Array.isArray(formik.values.workHours) &&
            formik.values.workHours.length
          ) {
            const time = formik.values.workHours.find(
              eachDay => eachDay.day === day
            );
            if (time) {
              const st = new Date(
                `${dayjs(value).format('MM-DD-YYYY')} ${time.start_time}`
              );
              const et = new Date(
                `${dayjs(value).format('MM-DD-YYYY')} ${time.end_time}`
              );
              formik.setFieldValue('start_time', st);
              formik.setFieldValue('end_time', et);
              isTimeFound = true;
            }
          }
          if (!isTimeFound) {
            const st = new Date(
              `${dayjs(value).format('MM-DD-YYYY')} 08:00:00`
            );
            const et = new Date(
              `${dayjs(value).format('MM-DD-YYYY')} 17:00:00`
            );
            formik.setFieldValue('start_time', st);
            formik.setFieldValue('end_time', et);
          }
        }
        break;
      case 'start_time':
        formik.setFieldValue(name, value);
        break;
      case 'end_time':
        formik.setFieldValue(name, value);
        break;
      case 'is_full_day':
        formik.setFieldValue(name, value);
        if (value && formik.values.date) {
          const day = DAYS[dayjs(formik.values.date).day()];
          if (
            Array.isArray(formik.values.workHours) &&
            formik.values.workHours.length
          ) {
            const time = formik.values.workHours.find(
              eachDay => eachDay.day === day
            );
            if (time) {
              const st = new Date(
                `${dayjs(formik.values.date).format('MM-DD-YYYY')} ${time.start_time}`
              );
              const et = new Date(
                `${dayjs(formik.values.date).format('MM-DD-YYYY')} ${time.end_time}`
              );
              formik.setFieldValue('start_time', st);
              formik.setFieldValue('end_time', et);
            }
          }
        }
        break;
      default:
        return;
    }
  };

  const handleHide = () => {
    setIsVisible(false);
  };

  const callGetWorkHours = async () => {
    setLoading(true);
    try {
      const apiResponse = await getWorkHours(clientId);
      if (apiResponse?.status && Array.isArray(apiResponse.data)) {
        const workingDays = [];
        apiResponse.data.forEach(eachDay => {
          if (!eachDay.is_working) workingDays.push(DAYS.indexOf(eachDay.day));
        });
        formik.setFieldValue('disableDays', workingDays);
        formik.setFieldValue('workHours', apiResponse.data);
      }
    } catch (ex) {
      console.error(ex);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (clientId) {
      callGetWorkHours();
    }
  }, [clientId]);

  const formik = useFormik({
    initialValues: {
      event_name: holidayDetail?.event_name,
      date: holidayDetail?.start_date
        ? new Date(dayjsFormatDateTime(holidayDetail?.start_date))
        : holidayDetail?.start_date,
      start_time: holidayDetail?.start_date
        ? new Date(dayjsFormatDateTime(holidayDetail?.start_date))
        : holidayDetail?.start_date,
      end_time: holidayDetail?.end_date
        ? new Date(dayjsFormatDateTime(holidayDetail?.end_date))
        : holidayDetail?.end_date,
      is_full_day: !!holidayDetail?.is_full_day,
      disableDays: [],
      workHours: [],
    },
    validationSchema: validation,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });
  const isAnythingChanged = useMemo(() => {
    for (const key in formik.initialValues) {
      switch (key) {
        case 'event_name':
          if (formik.initialValues.event_name != formik.values.event_name)
            return true;
        case 'date':
          if (formik.initialValues.date != formik.values.date) return true;
        case 'start_time':
          if (formik.initialValues.start_time != formik.values.start_time)
            return true;
        case 'end_time':
          if (formik.initialValues.end_time != formik.values.end_time)
            return true;
        case 'is_full_day':
          if (formik.initialValues.is_full_day != formik.values.is_full_day)
            return true;
      }
    }
    return false;
  }, [formik.values]);
  return (
    <Dialog
      header={`${isEdit ? 'Edit' : 'Add'} Holiday`}
      visible={isVisible}
      onHide={handleHide}
      style={{
        overflow: 'auto',
      }}
      className="w-6 lg:w-4"
    >
      <Toast ref={toast} />
      <PFBlockUI blocked={loading}>
        <div className="w-full flex flex-column gap-3 flex-wrap mt-2">
          <div className="w-full p-float-label flex flex-column">
            <PFInputText
              className={`w-full ${formik.errors.event_name ? 'border-red-300' : ''}`}
              id="event_name"
              name="event_name"
              value={formik.values?.event_name || ''}
              onChange={e => handleChange('event_name', e.target.value)}
              // onBlur={() => validateInput('event_name', formik.values.event_name)}
              aria-describedby="event_name_error"
            />
            <label htmlFor="event_name">
              Event Name<span style={{ color: 'red' }}>*</span>
            </label>
            {formik.errors.event_name && (
              <span id="event_name_error" className="text-red-400 text-xs pl-1">
                {formik.errors.event_name}
              </span>
            )}
          </div>
          <div className="w-full p-float-label flex flex-column">
            <Calendar
              className="w-full"
              id="date"
              name="date"
              minDate={new Date()}
              value={formik.values?.date}
              onChange={e => handleChange('date', e.value)}
              disabledDays={formik.values.disableDays}
              aria-describedby="date_error"
              dateFormat="mm-dd-yy"
              touchUI
              inputClassName={formik.errors.date ? 'border-red-300' : ''}
              panelStyle={{
                minWidth: '30vw',
                minHeight: '15vh',
              }}
            />
            <label htmlFor="date">
              Holiday Date<span style={{ color: 'red' }}>*</span>
            </label>
            {formik.errors.date && (
              <span id="date_error" className="text-red-400 text-xs pl-1">
                {formik.errors.date}
              </span>
            )}
          </div>
          {formik.values?.date && (
            <>
              <div className="w-full p-float-label flex flex-column">
                <Calendar
                  className="w-full"
                  id="start_time"
                  name="start_time"
                  timeOnly
                  showTime
                  hourFormat="12"
                  value={formik.values?.start_time}
                  onChange={e => handleChange('start_time', e.value)}
                  disabled={!!formik.values.is_full_day}
                  aria-describedby="start_time_error"
                  inputClassName={
                    formik.errors.start_time ? 'border-red-300' : ''
                  }
                />
                <label htmlFor="start_time">Start Time</label>
                {formik.errors.start_time && (
                  <span
                    id="start_time_error"
                    className="text-red-400 text-xs pl-1"
                  >
                    {formik.errors.start_time}
                  </span>
                )}
              </div>
              <div className="w-full p-float-label flex flex-column">
                <Calendar
                  className="w-full"
                  id="end_time"
                  name="end_time"
                  timeOnly
                  showTime
                  hourFormat="12"
                  value={formik.values?.end_time}
                  onChange={e => handleChange('end_time', e.value)}
                  disabled={!!formik.values.is_full_day}
                  invalid={!!formik.errors.end_time}
                  aria-describedby="end_time_error"
                  inputClassName={
                    formik.errors.end_time ? 'border-red-300' : ''
                  }
                />
                <label htmlFor="end_time">End Time</label>
                {formik.errors.end_time && (
                  <span
                    id="end_time_error"
                    className="text-red-400 text-xs pl-1"
                  >
                    {formik.errors.end_time}
                  </span>
                )}
              </div>
              <div className="w-full flex gap-2 align-items-center">
                <Checkbox
                  label="Full Day"
                  checked={!!formik.values?.is_full_day}
                  onChange={e => {
                    handleChange('is_full_day', e.target.checked);
                  }}
                />
                <label htmlFor="is_full_day">Full day</label>
              </div>
              {formik.errors.is_full_day && (
                <span
                  id="is_full_day_error"
                  className="text-red-400 text-xs pl-1"
                >
                  {formik.errors.is_full_day}
                </span>
              )}
            </>
          )}
          <div className="w-full flex justify-content-end gap-2 mt-2">
            <PFButton
              label="Save"
              size="small"
              onClick={formik.handleSubmit}
              disabled={!isAnythingChanged || !formik.isValid || loading}
            />
          </div>
        </div>
      </PFBlockUI>
    </Dialog>
  );
};

export default AddHolidayCalendarDialog;
