// **** React Imports ****
import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
// **** External Utilities ****
import { Grid, Typography, Button, Paper, Divider } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { debounce } from 'lodash';

// **** Custom Components ****
import { Edit as EditIcon } from '@material-ui/icons';

import PageHeader from '../../shared/PageHeader/PageHeader';
import { Alert } from '../../shared/Alerts/Alert';
import Footer from '../../shared/Footer/Footer';
import { useAlerts } from '../../shared/Alerts/alertsService';
import PFTableLoader from '../../shared/Loader/PFTableLoader';

import ProfileDetails from './ProfileDetails';
import LaborProfileItems from './LaborProfileItems';

// **** Services *****
import {
  getlaborProfileItemsDataByInstallerId,
  addNewLaborProfile,
  updateLaborProfile,
} from './LaborProfiles.service';

// **** Styles/Images/Icons ****
import { useStyles } from './AddViewEditLaborProfileDetails.styles';

const AddViewEditLaborProfileDetails = () => {
  const { installerId } = useParams();
  const action = window.location.pathname.split('/')[2];
  const history = useHistory();
  const { alert, setAlert, clearAlert } = useAlerts();
  const classes = useStyles();
  const [laborProfileItemsData, setLaborProfileItemsData] = React.useState();
  const [reloadLaborProfileItemsList, setReloadLaborProfileItemsList] =
    React.useState(false);

  const [loading, setLoading] = React.useState(false);
  const [profileItemSearch, setProfileItemSearch] = React.useState('');
  const profileDetailsBreadcrumb = [
    {
      text: 'Admin',
    },
    {
      link: '/admin/laborprofiles',
      text: 'Labor Profiles',
    },
    {
      text: action === 'add' ? 'New Profile' : 'Profile Details',
    },
  ];

  const debouncedFetchLaborProfileItems = React.useCallback(
    debounce(searchQuery => {
      getlaborProfileItemsDataByInstallerId(
        installerId,
        null,
        setAlert,
        setLaborProfileItemsData,
        searchQuery
      );
    }, 500),
    [installerId, setAlert, setLaborProfileItemsData]
  );

  React.useEffect(() => {
    if (profileItemSearch) {
      debouncedFetchLaborProfileItems(profileItemSearch);
    } else {
      debouncedFetchLaborProfileItems('');
    }
    return () => {
      debouncedFetchLaborProfileItems.cancel();
    };
  }, [profileItemSearch, debouncedFetchLaborProfileItems]);

  React.useEffect(() => {
    // clearAlert();
  }, [action]);

  React.useEffect(() => {
    if (action !== 'add') {
      getlaborProfileItemsDataByInstallerId(
        installerId,
        setLoading,
        setAlert,
        setLaborProfileItemsData
      );
      setReloadLaborProfileItemsList(false);
    }
  }, [installerId, reloadLaborProfileItemsList]);

  // **** Form Validation Schema ****
  const laborProfileFormValidationSchema = Yup.object().shape({
    installer_id: Yup.string().trim().required('Required'),
    default_percent_paid: Yup.string().trim().required('Required').min(0),
  });

  const formik = useFormik({
    initialValues: {
      installer_id:
        action === 'add'
          ? ''
          : (laborProfileItemsData?.installer_detail?.user?.first_name || '') +
            (laborProfileItemsData?.installer_detail?.user?.middle_name || '') +
            (' ' + laborProfileItemsData?.installer_detail?.user?.last_name ||
              ''),
      default_percent_paid:
        action === 'add'
          ? ''
          : laborProfileItemsData?.default_percent_paid?.toString() + '%',
      installer_labour_profile_items:
        action === 'add'
          ? ''
          : laborProfileItemsData?.installer_labour_profile_items,
      cloned_from: action === 'add' ? '' : laborProfileItemsData?.cloned_from,
    },
    validationSchema: laborProfileFormValidationSchema,
    onSubmit: values => {
      setLoading(true);
      if (action === 'edit') {
        updateLaborProfile(
          installerId,
          laborProfileItemsData.installer_labor_profile_id,
          values,
          history,
          setLoading,
          setAlert
        );
      } else {
        addNewLaborProfile(values, history, setLoading, setAlert, history);
      }
    },
    enableReinitialize: true,
  });
  return (
    <>
      <form className={classes.formWrapper}>
        <Grid container direction="column" spacing={2}>
          {alert.exists && (
            <Grid item>
              {' '}
              <Alert />
            </Grid>
          )}
          <Grid
            container
            item
            direction="row"
            justifyContent="space-between"
            spacing={2}
          >
            <Grid item>
              <PageHeader
                pageTitle={action === 'add' ? 'New Profile' : 'Labor Profiles'}
                breadCrumbArray={profileDetailsBreadcrumb}
              />
            </Grid>

            <Grid item classes={{ root: classes.selfAlignGrid }}>
              <Grid
                container
                item
                direction="row"
                justifyContent="flex-start"
                spacing={2}
              >
                {action === 'view' && (
                  <>
                    <Grid item>
                      {action == 'view' && (
                        <Button
                          startIcon={<EditIcon />}
                          color="primary"
                          variant="outlined"
                          onClick={() =>
                            history.push(`/laborprofiles/edit/${installerId}`)
                          }
                        >
                          Edit
                        </Button>
                      )}
                    </Grid>
                    <Grid item>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => history.push(`/laborprofiles/add`)}
                      >
                        Add New
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>

          {loading || laborProfileItemsData || action === 'add' ? (
            <>
              <Grid item spacing={2} xs={12}>
                <Paper square className={classes.paperRoot} elevation={0}>
                  <Grid container direction="column">
                    <Grid
                      container
                      item
                      direction="row"
                      justifyContent="space-between"
                      className={classes.containerHeader}
                      xs={12}
                    >
                      <Grid item>
                        <Typography variant="h3">
                          {action === 'add' ? 'New Profile' : 'Labor Profiles'}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider />
                    <Grid item className={classes.containerContent}>
                      <ProfileDetails
                        setLoading={setLoading}
                        setAlert={setAlert}
                        installerId={installerId}
                        formik={formik}
                        action={action}
                        loading={loading}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item spacing={2} xs={12}>
                {(action === 'view' || action === 'edit') && (
                  <LaborProfileItems
                    labourProfileItems={
                      formik?.values?.installer_labour_profile_items
                    }
                    installerId={installerId}
                    setReloadLaborProfileItemsList={
                      setReloadLaborProfileItemsList
                    }
                    profileItemSearch={profileItemSearch}
                    setProfileItemSearch={setProfileItemSearch}
                  />
                )}
              </Grid>
            </>
          ) : (
            <Paper square className={classes.paperRoot} elevation={0}>
              <Grid className={classes.clientNotFound}>
                <Typography variant="h6"> Labor Profile Not found</Typography>
              </Grid>
            </Paper>
          )}
        </Grid>
      </form>
      {!loading && action !== 'add' && (
        <Grid container className={classes.footer}>
          <Footer
            createdBy={
              laborProfileItemsData?.created_user?.first_name ||
              laborProfileItemsData?.created_user?.last_name
                ? `${laborProfileItemsData?.created_user?.first_name || null}  ${
                    laborProfileItemsData?.created_user?.last_name || null
                  }`
                : null
            }
            createdAt={laborProfileItemsData?.created_at || null}
            modifiedBy={
              laborProfileItemsData?.modified_user?.first_name ||
              laborProfileItemsData?.modified_user?.last_name
                ? `${laborProfileItemsData?.modified_user?.first_name || null}  ${
                    laborProfileItemsData?.modified_user?.last_name || null
                  }`
                : null
            }
            modifiedAt={laborProfileItemsData?.modified_at || null}
          />
        </Grid>
      )}
    </>
  );
};

export default AddViewEditLaborProfileDetails;
