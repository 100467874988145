import React, { useEffect, useRef } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import usePFDataTable from '../../hooks/PFDatatable';
import PFButton from '../shared/PFPrime/PFButton.js';
import { WAREHOUSE_BIN } from './constants.js';
const BinsTable = ({
  bins,
  onEditRow,
  binListing,
  onDeleteRow,
  wareHouseName,
}) => {
  const {
    layout: BinsTable,
    data: BinsTableData,
    columns,
    totalRecords,
    rows,
    page,
    sortBy,
    orderBy,
    setDefaultTableProps,
  } = usePFDataTable();

  const binsGridColumns = () => {
    let main = [
      {
        header: 'Action',
        headerStyle: {
          width: '10%',
        },
        body: (rowData, option) => (
          <div>
            <PFButton
              icon="pi pi-pencil"
              outlined
              text
              className="text-white border-none"
              severity="primary"
              aria-label="Edit"
              onClick={() => {
                onEditRow(rowData);
              }}
            />
            {!rowData?.isDefault && (
              <>
                <PFButton
                  icon="pi pi-trash"
                  outlined
                  text
                  className="text-white border-none"
                  severity="primary"
                  aria-label="Delete"
                  onClick={() => {
                    deleteConfirm(rowData);
                  }}
                />
              </>
            )}
          </div>
        ),
      },
      {
        field: 'binCode',
        header: 'Bin Code',
        filter: false,
        sortable: true,
      },
      {
        field: 'binName',
        header: 'Bin Name',
        filter: false,
        sortable: true,
      },
    ];

    return main;
  };

  useEffect(() => {
    columns(binsGridColumns());
  }, []);

  useEffect(() => {
    BinsTableData(bins?.data || []);
    totalRecords(bins?.count || (bins?.data || []).length);
  }, [bins]);

  useEffect(() => {
    const orderByColumn = orderBy === 1 ? 'ASC' : 'DESC';
    const params = {
      limit: rows || 1,
      offset: page + 1,
      sortKey: sortBy,
      sortOrder: orderByColumn || 'DESC',
    };
    binListing(params);
  }, [rows, page, sortBy, orderBy]);

  const deleteConfirm = rowData => {
    confirmDialog({
      message: WAREHOUSE_BIN.BIN_DELETE_CONFIRMATION(
        `${rowData?.binCode}-${rowData?.binName}`,
        wareHouseName
      ),
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      defaultFocus: 'accept',
      accept: () => onDeleteRow(rowData),
      reject: () => false,
    });
  };

  return (
    <div>
      <ConfirmDialog />
      <BinsTable />
    </div>
  );
};

export default BinsTable;
