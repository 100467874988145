import axios from 'axios';

import { URL_CONSTANTS } from '../../../../constants/urlConstants';
import ApiService from '../../../../services/api.service';

export const getUserCredentialsData = async userId => {
  try {
    const credentials = await ApiService.getWithDiffBaseUrl(
      `/api/user-credential/${userId}`,
      '',
      URL_CONSTANTS.USER_MANAGEMENT.baseUrl
    );
    return credentials?.data;
  } catch (error) {
    console.error(error);
  }
};

export const getUserCredentialsTypeData = async () => {
  try {
    const credentialsTypeRequest = await ApiService.get(
      `/system/credential-type`
    );
    return credentialsTypeRequest;
  } catch (error) {
    console.error(error);
  }
};
export const editUserCredentials = async (userId, credentialsInfo) => {
  try {
    const response = await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/auth/user/manage/${userId}`,
      {
        credentials: JSON.stringify(credentialsInfo),
      }
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const addUserCredentials = async (userId, credentialsInfo) => {
  try {
    const response = await axios.post(
      `${URL_CONSTANTS.USER_MANAGEMENT.baseUrl}/api/user-credential/${userId}`,
      credentialsInfo
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const updateUserCredentials = async (userId, id, credentialsInfo) => {
  try {
    const response = await axios.patch(
      `${URL_CONSTANTS.USER_MANAGEMENT.baseUrl}/api/user-credential/${userId}/credentials/${id}`,
      credentialsInfo
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const deleteUserCredentials = async credentialId => {
  try {
    const response = await axios.delete(
      `${URL_CONSTANTS.USER_MANAGEMENT.baseUrl}/api/user-credential/${credentialId}`
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};
