import React, { useEffect, useContext, useState } from 'react';
import moment from 'moment';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';

import usePFDataTable from '../../../../hooks/PFDatatable';

import {
  deleteReceiptInfoService,
  getAllReceiptService,
} from '../services/receiving.service';

import { ReceivingContext } from '../ReceivingContainer';
import {
  RECEIPT_TYPE_RECEIVING,
  YES_NO_OPTIONS,
} from '../constants/constants.js';
import PFButton from '../../../shared/PFPrime/PFButton.js';
import PFTableLoader from '../../../shared/Loader/PFTableLoader.js';

export const ReceiptTable = () => {
  const { receivingContextValue, setReceivingContextValue } =
    useContext(ReceivingContext);
  const [loading, setLoading] = useState(true);
  const {
    layout: TableLayout,
    columns,
    totalRecords,
    data: tableData,
    rows,
    page,
    sortBy,
    orderBy,
  } = usePFDataTable();

  const getAllReceipt = async () => {
    try {
      setLoading(true);
      const res = await getAllReceiptService(
        receivingContextValue?.project_id,
        receivingContextValue?.projectReceivingData?.project_receiving_id,
        rows,
        page,
        sortBy,
        orderBy
      );
      if (res?.status) {
        tableData(res?.data?.rows || []);
        totalRecords(res?.data?.count || 0);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    receivingContextValue?.project_id &&
      receivingContextValue?.projectReceivingData?.project_receiving_id &&
      getAllReceipt();
  }, [
    receivingContextValue?.project_id,
    receivingContextValue?.projectReceivingData?.project_receiving_id,
    rows,
    page,
    sortBy,
    orderBy,
  ]);

  useEffect(() => {
    if (receivingContextValue?.reloadList) {
      getAllReceipt();
      setReceivingContextValue(preValue => ({
        ...preValue,
        reloadList: false,
      }));
    }
  }, [receivingContextValue?.reloadList]);

  const deleteReceipt = async (
    project_id,
    project_receiving_id,
    project_receiving_receipt_id
  ) => {
    const res = await deleteReceiptInfoService(
      project_id,
      project_receiving_id,
      project_receiving_receipt_id
    );
    if (res?.status) {
      setReceivingContextValue(preValue => ({
        ...preValue,
        reloadList: true,
      }));
      receivingContextValue?.toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Receipt deleted successfully',
      });
    }
  };

  const deleteConfirmation = (
    event,
    project_id,
    project_receiving_id,
    project_receiving_receipt_id
  ) => {
    confirmPopup({
      target: event.currentTarget,
      message: 'Are you sure you want to delete this?',
      icon: 'pi pi-exclamation-triangle',
      defaultFocus: 'accept',
      accept: () => {
        deleteReceipt(
          project_id,
          project_receiving_id,
          project_receiving_receipt_id
        );
      },
      reject: () => false,
    });
  };

  useEffect(() => {
    columns([
      {
        header: '',
        body: data => {
          return (
            <div className="flex">
              <PFButton
                icon="pi pi-eye"
                outlined
                text
                className="text-white border-none"
                severity="primary"
                aria-label="View"
                onClick={() => {
                  receivingContextValue?.updateContextValue({
                    visibleMode: {
                      visible: 'view',
                      data: data,
                      disabled: true,
                    },
                  });
                  receivingContextValue?.hide();
                }}
              />
              <PFButton
                icon="pi pi-pencil"
                outlined
                text
                className="text-white border-none"
                severity="primary"
                aria-label="Edit"
                onClick={() => {
                  receivingContextValue?.updateContextValue({
                    visibleMode: {
                      visible: 'edit',
                      data: data,
                      disabled: false,
                    },
                  });
                  receivingContextValue?.hide();
                }}
              />
              <PFButton
                icon="pi pi-trash"
                outlined
                text
                className="text-white border-none"
                severity="primary"
                aria-label="Delete"
                onClick={e =>
                  deleteConfirmation(
                    e,
                    data?.project_id,
                    data?.project_receiving_id,
                    data?.project_receiving_receipt_id
                  )
                }
              />
            </div>
          );
        },
      },
      {
        field: 'receipt_type',
        header: 'Type',
        body: data => {
          return (
            RECEIPT_TYPE_RECEIVING?.find(
              item => item?.value == data?.receipt_type
            )?.label || ''
          );
        },
      },
      {
        field: 'needed',
        header: 'Needed',
        body: data => {
          return (
            YES_NO_OPTIONS?.find(item => item?.value == data?.needed)?.label ||
            null
          );
        },
      },
      {
        field: 'receipt_date',
        header: 'Date',
        body: data => (
          <div className="w-10rem">
            {data?.receipt_date &&
            moment(data?.receipt_date, 'YYYY-MM-DD', true).isValid()
              ? moment(data?.receipt_date).format('MM-DD-YYYY')
              : null}
          </div>
        ),
      },
      {
        header: 'Technician',
        body: data =>
          `${data?.technician?.first_name || ''} ${data?.technician?.last_name || ''}`,
      },
      {
        header: 'Warehouse',
        body: data => `${data?.returnWarehouse?.name || ''}`,
      },
      {
        header: 'Location',
        body: data => `${data?.binLocation?.bin_location || ''}`,
      },
      {
        header: 'Still in Warehouse',
        body: data => {
          return (
            YES_NO_OPTIONS?.find(
              item => item?.value == data?.is_still_in_warehouse
            )?.label || null
          );
        },
      },
      {
        header: 'Received by Technician',
        body: data => {
          return (
            YES_NO_OPTIONS?.find(
              item => item?.value == data?.is_received_by_technician
            )?.label || null
          );
        },
      },
      {
        header: 'Transfer From',
        body: data => `${data?.fromWarehouse?.name || ''}`,
      },
      {
        header: 'Transfer To',
        body: data => `${data?.toWarehouse?.name || ''}`,
      },
      {
        field: 'box_count',
        header: 'Box Count',
      },
      {
        field: 'receipt_note',
        header: 'Note',
      },
    ]);
    getAllReceipt();
  }, []);

  return (
    <div className="grid">
      <div className="col-12">
        <ConfirmPopup />
        {loading ? <PFTableLoader /> : <TableLayout />}
      </div>
    </div>
  );
};
