import React from 'react';
import { BlockUI } from 'primereact/blockui';

const blockHeaderTemplate = (
  <div className="flex align-items-center gap-2">
    <i
      className="pi pi-spin pi-spinner h-1rem"
      style={{ fontSize: '1rem', color: '#0074d9' }}
    ></i>
    <h3 style={{ color: '#0074d9', fontSize: '1rem' }}>Please wait...</h3>
  </div>
);
const PFBlockUI = ({ children = [], ...rest }) => {
  return (
    <BlockUI
      template={blockHeaderTemplate}
      className="opacity-80 w-full"
      pt={{ mask: { className: 'gray-bg-700' } }}
      {...rest}
    >
      {children}
    </BlockUI>
  );
};

export default PFBlockUI;
