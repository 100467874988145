/* eslint-disable spellcheck/spell-checker */
/* eslint-disable no-prototype-builtins */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  customers: [],
  stores: [],
  projectTypes: [],
  projectCategories: [],
  projectWorkrooms: [],
  projectStatus: [],
  districtId: [],
  defaultFilter: [],
  techniciansVal: [],
  sourceStatus: [],
  userType: [],
  dateScheduledFilterStart: [],
  dateScheduledFilterEnd: [],
  dateCompletionFilterStart: [],
  dateCompletionFilterEnd: [],
  checkDateFromFilter: [],
  checkDateToFilter: [],
  dateSoldFromFilter: [],
  dateSoldToFilter: [],
  isDashboardApplicable: 0,
};

const globalFiltersSlice = createSlice({
  name: 'globalFilters',
  initialState: initialState,
  reducers: {
    setGlobalFilters(state, { payload }) {
      state.customers = payload.hasOwnProperty('customers')
        ? payload.customers
        : state.customers;
      state.stores = payload.hasOwnProperty('stores')
        ? payload.stores
        : state.stores;
      state.projectTypes = payload.hasOwnProperty('projectTypes')
        ? payload.projectTypes
        : state.projectTypes;
      state.projectCategories = payload.hasOwnProperty('projectCategories')
        ? payload.projectCategories
        : state.projectCategories;
      state.projectWorkrooms = payload.hasOwnProperty('projectWorkrooms')
        ? payload.projectWorkrooms
        : state.projectWorkrooms;
      state.projectStatus = payload.hasOwnProperty('projectStatus')
        ? payload.projectStatus
        : state.projectStatus;
      state.districtId = payload.hasOwnProperty('districtId')
        ? payload.districtId
        : state.districtId;
      state.defaultFilter = payload.hasOwnProperty('defaultFilter')
        ? payload.defaultFilter
        : state.defaultFilter;
      state.techniciansVal = payload.hasOwnProperty('techniciansVal')
        ? payload.techniciansVal
        : state.techniciansVal;
      state.sourceStatus = payload.hasOwnProperty('sourceStatus')
        ? payload.sourceStatus
        : state.sourceStatus;
      state.userType = payload.hasOwnProperty('userType')
        ? payload.userType
        : state.userType;
      state.dateScheduledFilterStart = payload.hasOwnProperty(
        'dateScheduledFilterStart'
      )
        ? payload.dateScheduledFilterStart
        : state.dateScheduledFilterStart;
      state.dateScheduledFilterEnd = payload.hasOwnProperty(
        'dateScheduledFilterEnd'
      )
        ? payload.dateScheduledFilterEnd
        : state.dateScheduledFilterEnd;
      state.dateCompletionFilterStart = payload.hasOwnProperty(
        'dateCompletionFilterStart'
      )
        ? payload.dateCompletionFilterStart
        : state.dateCompletionFilterStart;
      state.dateCompletionFilterEnd = payload.hasOwnProperty(
        'dateCompletionFilterEnd'
      )
        ? payload.dateCompletionFilterEnd
        : state.dateCompletionFilterEnd;
      state.checkDateFromFilter = payload.hasOwnProperty('checkDateFromFilter')
        ? payload.checkDateFromFilter
        : state.checkDateFromFilter;
      state.checkDateToFilter = payload.hasOwnProperty('checkDateToFilter')
        ? payload.checkDateToFilter
        : state.checkDateToFilter;
      state.dateSoldFromFilter = payload.hasOwnProperty('dateSoldFromFilter')
        ? payload.dateSoldFromFilter
        : state.dateSoldFromFilter;
      state.dateSoldToFilter = payload.hasOwnProperty('dateSoldToFilter')
        ? payload.dateSoldToFilter
        : state.dateSoldToFilter;
      state.isDashboardApplicable = payload.hasOwnProperty(
        'isDashboardApplicable'
      )
        ? payload.isDashboardApplicable
        : state.isDashboardApplicable;
    },
  },
});

export const { setGlobalFilters } = globalFiltersSlice.actions;

export const globalFiltersSliceReducer = globalFiltersSlice.reducer;
