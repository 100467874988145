export const TENNANT_SKELETON_COLUMNS = [
  {
    name: 'client name',
    label: 'Client Name',
  },
  {
    name: 'month',
    label: 'Month',
  },
  {
    name: 'year',
    label: 'Year',
  },
  {
    name: 'count',
    label: 'Count',
  },
];
