import { useDispatch } from 'react-redux';

import { clearTechnicians } from './technician.slice';
import { clearTypes } from './types.slice';
import { clearCategories } from './categories.slice';
import { clearStore } from './projectStores.slice';
import { clearDistricts } from './districts.slice';
import { clearUserTypes } from './userTypes.slice';
import { clearWorkrooms } from './workrooms.slice';
import { clearInstallerWorkType } from './installerWorkType.slice';
import { clearSourceStatusType } from './sourceStatusType.slice';
import { clearProjectStatusType } from './projectStatusType.slice';
import { clearTechnicianLimitList } from './technicianLimit.slice';

const clearMasterDataHook = () => {
  const dispatch = useDispatch();

  const clearMasterData = () => {
    dispatch(clearTechnicians());
    dispatch(clearTypes());
    dispatch(clearCategories());
    dispatch(clearStore());
    dispatch(clearDistricts());
    dispatch(clearUserTypes());
    dispatch(clearWorkrooms());
    dispatch(clearInstallerWorkType());
    dispatch(clearSourceStatusType());
    dispatch(clearProjectStatusType());
    dispatch(clearTechnicianLimitList());
  };

  return clearMasterData;
};

export default clearMasterDataHook;
