import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import moment from 'moment';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { useSelector, useDispatch } from 'react-redux';
import { Card } from 'primereact/card';
import { Toast } from 'primereact/toast';

import PageHeader from '../../../shared/PageHeader/PageHeader';
import {
  createInvoice,
  getInvoice,
  invoiceBreadcrumb,
  updateInvoice,
} from '../helpers/Invoice';
import ClientInfomation from '../../Quotes/components/ClientInfomation';
import OpportunityInfomation from '../../Quotes/components/OpportunityInfomation';
import PFButton from '../../../shared/PFPrime/PFButton';
import { setCRMInvoiceItems } from '../../../../redux/slices/crmInvoiceItems.slice';
import apiService from '../../../../services/api.service';
import { URL_CONSTANTS } from '../../../../constants/urlConstants';

import ItemResult from './ItemResult';
import InvoiceTabs from './InvoiceTabs';

const Invoice = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const queryParams = useParams();
  const {
    items: projectItems,
    customerId,
    opportunityId,
    quoteId,
    projectId,
    quoteNumber,
    projectNumber,
    opportunityNumber,
  } = useSelector(state => state.crm?.projectInvoice?.project);

  const { mode, actionId } = queryParams;
  const toast = useRef(null);

  const [items, setItems] = useState([]);
  const [laborItems, setLaborItems] = useState([]);
  const [merchandiseItems, setMerchandiseItems] = useState([]);
  const [addtionalItems, setAddtionalItems] = useState([]);
  const [invoiceData, setInvoiceData] = useState(null);
  const [invoiceStatus, setInvoiceStatus] = useState([]);
  const [opportunityDetails, globalOpportunityDetails] = useState({});
  const [advanceAmount, setAdvanceAmount] = useState('0.0');
  const [customerDetails, setCustomerDetails] = useState(null);
  const [aggregate, setAggregate] = useState({
    totalQuoteAmount: 0,
    totalDiscountAmount: 0,
    totalTaxAmount: 0,
    advanceAmount: 0,
  });

  const [expiryDate, setExpiryDate] = useState('');
  const [statusId, setStatusId] = useState('');
  const [quoteCreateAt, setQuoteCreateAt] = useState('');
  const [breadCrumbData, setBreadCrumbData] = useState({
    customerId: customerId,
    opportunityId: opportunityId,
    opportunityNumber: opportunityNumber,
    quoteId: quoteId,
    quoteNumber: quoteNumber,
    projectId: projectId,
  });

  const findSum = key => {
    return items.reduce((sum, item) => sum + parseFloat(item[key] || 0), 0);
  };

  useEffect(() => {
    if (mode === 'create') {
      if (projectItems && projectItems.length > 0) {
        const laborItem = projectItems?.filter(
          item => item?.itemType === 'Labor'
        );
        const productItem = projectItems?.filter(
          item => item?.itemType !== 'Labor'
        );
        setLaborItems(laborItem);
        setMerchandiseItems(productItem);
      }
    }
  }, [projectItems]);

  useEffect(() => {
    setAggregate({
      ...aggregate,
      totalQuoteAmount: findSum('totalPrice'),
      totalDiscountAmount: findSum('discountAmount'),
      totalTaxAmount: findSum('taxAmount'),
    });
    setFormPayload(preValues => ({
      ...preValues,
      lineItems: { items: items },
    }));
  }, [items]);

  useEffect(() => {
    setItems([...laborItems, ...merchandiseItems, ...addtionalItems]);
  }, [laborItems, merchandiseItems, addtionalItems]);

  const accept = () => {
    return history.push(
      `/crm/${customerId}/${opportunityId}/project/view/${projectId}`
    );
  };

  const reject = () => false;

  const calcelConfirm = event => {
    confirmDialog({
      target: event.currentTarget,
      message: 'Do you want to cancel this?',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-info',
      accept: () =>
        history.push(
          `/crm/${customerId}/${opportunityId}/project/view/${projectId}`
        ),
      reject,
      header: 'Confirmation',
      pt: { content: { className: 'py-2' } },
    });
  };

  const [formPayload, setFormPayload] = useState({});

  useEffect(() => {
    setFormPayload(preValues => ({
      ...preValues,
      expiry_date: expiryDate
        ? moment.utc(expiryDate).local().format('YYYY-MM-DD')
        : '',
      advance_amount: advanceAmount,
    }));
  }, [expiryDate, advanceAmount]);

  const message = invoice_no => {
    return mode === 'create'
      ? `Invoice ${invoice_no} created successfully.`
      : `Invoice ${invoice_no} update successfully.`;
  };

  const invoiceCreate = body => {
    const saveInvoice = createInvoice(body);
    saveInvoice
      .then(invoice => {
        dispatch(setCRMInvoiceItems({}));

        if (invoice?.status) {
          toast.current.show({
            severity: 'success',
            summary: 'success',
            detail: message(invoice?.data?.data?.invoice_no || ''),
          });
          return history.push(
            `/crm/invoice/view/${invoice?.data?.data?.invoice_id}`
          );
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  const invoiceUpdate = (payload, invoiceId) => {
    const upateInvoice = updateInvoice(payload, invoiceId);
    upateInvoice
      .then(invoice => {
        dispatch(setCRMInvoiceItems({}));
        if (invoice?.status) {
          toast.current.show({
            severity: 'success',
            summary: 'success',
            detail: message(invoice?.data?.data?.invoice_no || ''),
          });
          return history.push(
            `/crm/invoice/view/${invoice?.data?.data?.invoice_id}`
          );
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  const storeInvoice = event => {
    if (!formPayload?.expiry_date) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please select valid until date for this quote.',
      });
    } else if (formPayload?.advance_amount === '') {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please enter advance amount.',
      });
    } else {
      const reqBody = { ...formPayload };
      reqBody.total_discount = aggregate?.totalDiscountAmount;
      reqBody.total_tax = aggregate?.totalTaxAmount;
      reqBody.total_invoice_amount = aggregate?.totalQuoteAmount;
      reqBody.lineItems = reqBody?.lineItems?.items.map(item => {
        const {
          itemId: item_id,
          itemDescription: item_desc,
          quantity,
          unitPrice: unit_price,
          discountType: discount_type,
          discount,
          discountAmount: discount_amount,
          tax,
          taxAmount: tax_amount,
          net_cost: net_price,
          project_id,
          totalPrice,
          itemNumber: item_number,
          itemType: item_type,

          ...rest
        } = item;

        const itemObject = {
          item_id,
          item_desc,
          quantity,
          unit_price,
          discount_type,
          discount,
          discount_amount,
          tax,
          tax_amount,
          net_amount: net_price || totalPrice,
          project_id: project_id ? project_id : null,
          item_number: item_number ? item_number : null,
          item_type: rest?.item?.item_type?.item_type_id
            ? rest?.item?.item_type?.type_name
            : null,
          item_type_id: rest?.item?.item_type?.item_type_id
            ? rest?.item?.item_type?.item_type_id
            : null,
          project_item_id: rest.project_item_id || null,
        };
        itemObject.total_price = itemObject.quantity * itemObject.unit_price;
        return itemObject;
      });

      const currentStatus = invoiceStatus.find(
        item => item.status_id === statusId
      );
      if (currentStatus?.status === 'Cancelled') {
        confirmPopup({
          target: event.currentTarget,
          message:
            'All of the invoice items from the project will be de-linked if this invoice is cancelled. Please confirm that you wish to move forward ?',
          icon: 'pi pi-info-circle',
          acceptClassName: 'p-button-info',
          accept: () => {
            if (mode === 'create') {
              reqBody.customer_id = customerId;
              reqBody.quote_number = quoteNumber;
              reqBody.project_number = projectNumber;
              reqBody.project_id = projectId;
              reqBody.opportunity_id = parseInt(opportunityId);
              reqBody.opportunity_number = opportunityNumber;
              reqBody.quote_id = quoteId;
              const status = invoiceStatus.find(
                item => item.status.toLowerCase() === 'open'
              );
              reqBody.status = status?.status;
              reqBody.status_id = status?.status_id;

              invoiceCreate(reqBody);
            }
            if (mode === 'edit') {
              const status = invoiceStatus.find(
                item => item.status_id === statusId
              );
              reqBody.status = status?.status;
              reqBody.status_id = status?.status_id;
              invoiceUpdate(reqBody, invoiceData?.invoice_id);
            }
          },
          reject: () => {
            return false;
          },
          rejectLabel: 'Cancel',
          header: 'Confirmation',
        });
      } else {
        if (mode === 'create') {
          reqBody.customer_id = customerId;
          reqBody.quote_number = quoteNumber;
          reqBody.project_number = projectNumber;
          reqBody.project_id = projectId;
          reqBody.opportunity_id = parseInt(opportunityId);
          reqBody.opportunity_number = opportunityNumber;
          reqBody.quote_id = quoteId;
          const status = invoiceStatus.find(
            item => item.status.toLowerCase() === 'open'
          );
          reqBody.status = status?.status;
          reqBody.status_id = status?.status_id;

          invoiceCreate(reqBody);
        }
        if (mode === 'edit') {
          const status = invoiceStatus.find(
            item => item.status_id === statusId
          );
          reqBody.status = status?.status;
          reqBody.status_id = status?.status_id;
          invoiceUpdate(reqBody, invoiceData?.invoice_id);
        }
      }
    }
  };

  useEffect(() => {
    if (mode !== 'create') {
      setLaborItems([]);
      setMerchandiseItems([]);

      const invoiceData = getInvoice(actionId);
      invoiceData
        .then(invoice => {
          if (invoice?.status === 200) {
            const invoiceResult = invoice?.data?.data;
            const storeObject = {
              customerId: invoiceResult?.customer_id,
              opportunityId: invoiceResult?.opportunity_id,
              quoteId: invoiceResult?.quote_id,
              quoteNumber: invoiceResult?.quote_number,
              projectId: invoiceResult?.project_id,
              projectNumber: invoiceResult?.project_number,
              opportunityNumber: invoiceResult?.opportunity_number || null,
              items: [],
            };

            setAdvanceAmount(invoiceResult?.advance_amount || '0.0');
            dispatch(setCRMInvoiceItems(storeObject));
            setInvoiceData(invoiceResult);
          }
        })
        .catch(error => {
          console.error(error);
        });
    }
  }, [mode]);

  useEffect(() => {
    if (invoiceData) {
      if (invoiceData?.invoice_no) {
        setBreadCrumbData(prev => ({
          ...prev,
          invoiceNumber: invoiceData?.invoice_no,
          invoiceId: invoiceData?.invoice_id,
        }));
      }
      if (invoiceData?.status_id) {
        setStatusId(invoiceData?.status_id);
      }

      if (invoiceData?.lineItems?.length > 0) {
        let laborItems = [];
        let productItems = [];
        let additionalItems = [];
        const laborItem = invoiceData?.lineItems?.map(item => {
          const objectData = {
            itemId: item?.item_id,
            itemType: item?.item_type,
            itemNumber: item?.item_number,
            itemDescription: item?.item_desc,
            quantity: item?.quantity,
            unitPrice: item?.unit_price,
            discountType: item?.discount_type || 'Amount',
            tax: item?.tax,
            taxAmount: item?.tax_amount,
            discount: item?.discount,
            discountAmount: item?.discount_amount,
            totalPrice: item?.net_amount,
            project_id: item?.project_id,
            project_item_id: item?.project_item_id,
            item: {
              item_type: {
                item_type_id: item?.item_type_id,
                type_name: item?.item_type,
              },
            },
          };
          if (item?.item_type === 'Labor') {
            laborItems = [...laborItems, objectData];
          } else if (!item?.item_id) {
            additionalItems = [...additionalItems, objectData];
          } else {
            productItems = [...productItems, objectData];
          }
        });

        setLaborItems(laborItems);
        setMerchandiseItems(productItems);
        setAddtionalItems(additionalItems);
      }

      if (invoiceData?.invoice_terms_condtions?.length > 0) {
        setFormPayload(preValues => ({
          ...preValues,
          termAndConditions: invoiceData?.invoice_terms_condtions,
        }));
      }

      if (invoiceData?.invoicePaymentTerms?.length > 0) {
        setFormPayload(preValues => ({
          ...preValues,
          paymentTerms: invoiceData?.invoicePaymentTerms,
        }));
      }
    }
  }, [invoiceData]);

  const onPreview = async () => {
    try {
      const preview = await apiService.getWithDiffBaseUrl(
        `/api/invoice/preview/${invoiceData?.invoice_id}`,
        {},
        URL_CONSTANTS.CRM.baseUrl
      );
      const newWindow = window.open('quote-preview', '_blank');
      newWindow.document.write(preview);
      newWindow.document.close();
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Something went wrong.',
      });
    }
  };
  const onDownload = async () => {
    try {
      const downloadResponse = await apiService.getWithDiffBaseUrl(
        `/api/invoice/download/${invoiceData?.invoice_id}`,
        {},
        URL_CONSTANTS.CRM.baseUrl
      );
      const link = document.createElement('a');
      link.href = downloadResponse.data;
      link.target = '_blank';
      link.download = `${invoiceData.invoice_number}.pdf`;
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        link.remove();
      }, 2000);

      toast.current.show({
        severity: 'success',
        summary: 'success',
        detail: downloadResponse.message,
      });
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Something went wrong.',
      });
    }
  };
  const handelSendInvoice = async (clientId, clientName) => {
    try {
      const sendResponse = await apiService.getWithDiffBaseUrl(
        `/api/invoice/send/${invoiceData?.invoice_id}`,
        {},
        URL_CONSTANTS.CRM.baseUrl
      );
      toast.current.show({
        severity: 'success',
        summary: 'success',
        detail: sendResponse.message,
      });
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Something went wrong.',
      });
    }
  };
  const onSend = () => {
    confirmDialog({
      message: 'Are you sure want to send invoice to customer?',
      header: 'Confirmation',
      icon: 'pi pi-info-circle text-lg',
      acceptClassName: 'p-button-primary',
      accept: handelSendInvoice,
      reject: () => {},
      rejectLabel: 'Cancel',
      acceptLabel: 'Send',
      acceptClassName: 'p-button-primary p-button-sm',
      rejectClassName: 'p-button-sm p-button-outlined',
    });
  };

  useEffect(() => {
    if (invoiceData?.status === 'Cancelled' && mode === 'edit') {
      return history.push('/page-not-found');
    }
  }, [invoiceData?.status]);

  return (
    <>
      <div className="w-full crm">
        <Toast ref={toast}></Toast>
        <ConfirmPopup />
        <ConfirmDialog />
        <div className="grid w-full crm flex">
          <div className="col-12 md:col-12 lg:col-6 p-0 mt-1">
            <PageHeader
              pageTitle={`${_.capitalize(mode)} Invoice`}
              breadCrumbArray={invoiceBreadcrumb(
                customerId,
                mode,
                breadCrumbData
              )}
            />
          </div>
          <div className="col-12 md:col-12 lg:col-6 p-0 mt-1">
            <div className="mb-2 align-items-end justify-content-end flex">
              <PFButton
                label={`Cancel`}
                className="mr-1"
                severity="primary"
                outlined={true}
                onClick={
                  mode !== 'view'
                    ? calcelConfirm
                    : () =>
                        history.push(
                          `/crm/${customerId}/${opportunityId}/project/view/${projectId}`
                        )
                }
              />
              {mode === 'view' && (
                <>
                  {invoiceData?.status !== 'Cancelled' && (
                    <PFButton
                      label={`Send`}
                      className="ml-1"
                      severity="primary"
                      outlined={false}
                      onClick={event => {
                        customerDetails?.customer_emails?.length
                          ? onSend()
                          : confirmPopup({
                              target: event.currentTarget,
                              message:
                                'Kindly update the Customer profile with the Email ID to send this Invoice.',
                              icon: 'pi pi-info-circle',
                              footer: <></>,
                              pt: {
                                content: { className: 'py-2' },
                              },
                            });
                      }}
                    />
                  )}
                  <PFButton
                    label={`Preview`}
                    className="ml-1"
                    severity="primary"
                    outlined={false}
                    onClick={onPreview}
                  />
                  <PFButton
                    label={`Download`}
                    className="mr-1 ml-1"
                    severity="primary"
                    outlined={false}
                    onClick={onDownload}
                  />
                </>
              )}

              {mode === 'view' ? (
                <>
                  {invoiceData?.status !== 'Cancelled' && (
                    <PFButton
                      label={`Edit`}
                      className="ml-1"
                      onClick={() =>
                        history.push(
                          `/crm/invoice/edit/${invoiceData?.invoice_id}`
                        )
                      }
                    />
                  )}
                </>
              ) : (
                <PFButton
                  label={`Save`}
                  className="ml-2"
                  disabled={items?.length > 0 ? false : true}
                  onClick={storeInvoice}
                />
              )}
            </div>
          </div>
        </div>

        <div className="grid w-full crm flex py-2">
          <div className="col-12 md:col-12 lg:col-8 pl-0">
            <ClientInfomation
              customerId={customerId}
              setBreadCrumbData={setBreadCrumbData}
              globalCustomerDetails={setCustomerDetails}
            />
          </div>
          <div className="col-12 md:col-12 lg:col-4 pl-0 pr-1">
            <OpportunityInfomation
              opportunityId={opportunityId}
              customerId={customerId}
              globalOpportunityDetails={globalOpportunityDetails}
            />
          </div>
          <div className="col-12 md:col-12 lg:col-10 pl-0">
            <Card style={{ boxShadow: 'none' }}>
              <InvoiceTabs
                setLaborItems={setLaborItems}
                setMerchandiseItems={setMerchandiseItems}
                laborItems={laborItems}
                merchandiseItems={merchandiseItems}
                mode={mode}
                customerId={customerId}
                opportunityId={opportunityId}
                quoteId={quoteId}
                addtionalItems={addtionalItems}
                setAddtionalItems={setAddtionalItems}
                invoiceId={invoiceData?.invoice_id}
                formPayload={formPayload}
                setFormPayload={setFormPayload}
                primaryEmailAddress={opportunityDetails?.primaryEmail}
                invoicePaymentStatus={invoiceData?.status}
                invoicePendingAmount={
                  invoiceData?.total_invoice_amount - invoiceData?.paid_amount
                }
                customerDetails={customerDetails}
              />
            </Card>
          </div>
          <div className="col-12 md:col-12 lg:col-2  pl-0 pr-1">
            <Card style={{ boxShadow: 'none' }}>
              <div className="mt-2">
                <ItemResult
                  aggregate={aggregate}
                  setExpiryDate={setExpiryDate}
                  setStatusId={setStatusId}
                  expiryDate={invoiceData?.expiry_date || null}
                  statusId={statusId}
                  quoteId={quoteId}
                  quoteNumber={quoteNumber}
                  projectId={projectId}
                  projectNumber={projectNumber}
                  invoiceCreateAt={invoiceData?.created_at || null}
                  customerId={customerId}
                  opportunityId={opportunityId}
                  invoiceId={invoiceData?.invoice_no || ''}
                  setStatus={setInvoiceStatus}
                  advanceAmount={advanceAmount}
                  setAdvanceAmount={setAdvanceAmount}
                  invoicePendingAmount={
                    invoiceData?.total_invoice_amount - invoiceData?.paid_amount
                  }
                  paidAmount={invoiceData?.paid_amount}
                />
              </div>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default Invoice;
