export const ACTIONS = {
  VIEW: 'view',
  EDIT: 'edit',
  DELETE: 'delete',
  CREATE: 'create',
};

export const TEMP_CONFIG = {
  SOURCE_STATUS: 'Selected Source Status',
  SOURCE: 'Selected Source',
  TYPES: 'Selected Types',
  CATEGORY: 'Selected Category',
  INTERNAL_STATUS: 'Selected Status',
  COVERAGE_BY: 'Selected Coverage By',
  DISTRICT: 'Selected District',
  WORKROOM: 'Selected Workroom',
  STORES: 'Selected Stores',
};
export const PLACEHOLDERS = [
  { name: 'Project#' },
  { name: 'PO#' },
  { name: 'Type' },
  { name: 'Category' },
  { name: 'Client Name' },
  { name: 'Installation Address' },
  { name: 'City' },
  { name: 'State' },
  { name: 'Zip' },
  { name: 'Technician First Name' },
  { name: 'Date Scheduled Start' },
  { name: 'Date Scheduled End' },
  { name: 'Technician Arrival Start Time' },
  { name: 'Technician Arrival End Time' },
  { name: 'Store#' },
  { name: 'Description' },
];
