import React from 'react';
import moment from 'moment';
import { store } from '../../../redux';
import { addTransfer } from './reducers/InventoryTransferSlice';
import {
  PFCalendar,
  PFDropdown,
  DomHandlerCall,
  PFNumberBox,
  PFTextBox,
} from '../pf-prime/Prime';
import apiService from '../../../services/api.service';
import { getProduct } from './ProductDetails.model';
import { Bin } from './Bin';
import { URL_CONSTANTS } from '../../../constants/urlConstants';

const getErrorMessageByField = (validationMessages, field) => {
  const errorMessage = validationMessages.find(error => error.field === field);
  return errorMessage ? errorMessage.message : '';
};

const dateVisible = row => {
  const date = new Date(row.date);
  return <div>{moment(date).format('MM-DD-YYYY')}</div>;
};

const emptyObject = (index, productId) => ({
  productId: productId,
  index: index,
  date: new Date(),
  transferFromWarehouseId: null,
  transferToWarehouseId: null,
  quantity: null,
  receiverName: null,
  note: null,
  newRow: true,
});

const addNewRow = (data, setData, setRowInserted, productInfo) => {
  const { productId, _id } = productInfo;
  const { listItems, ...rest } = data;
  const newObject = emptyObject(listItems?.length, _id);
  const newSet = { ...data, listItems: [newObject, ...listItems] };
  setData(newSet);
  setRowInserted(true);
};
let editRowCount = 0;

export const resetCount = () => {
  editRowCount = 0;
};
const onEdit = (e, options, setIsDisabled = null) => {
  editRowCount++;
  setIsDisabled && setIsDisabled(true);
  return options.rowEditor?.onInitClick && options.rowEditor?.onInitClick(e);
};

const onSave = (e, options, setIsDisabled = null) => {
  if (editRowCount >= 1) {
    editRowCount = editRowCount - 1;
  }
  if (editRowCount === 0) {
    setIsDisabled && setIsDisabled(false);
  }

  return options.rowEditor?.onSaveClick && options.rowEditor?.onSaveClick(e);
};

const onDelete = (
  e,
  rowData,
  options,
  setIsDisabled = null,
  setDeletedRow = null
) => {
  if (editRowCount >= 1) {
    editRowCount = editRowCount - 1;
  }
  if (editRowCount === 0) {
    setIsDisabled && setIsDisabled(false);
  }

  setDeletedRow(rowData);
  return (
    options.rowEditor?.onCancelClick && options.rowEditor?.onCancelClick(e)
  );
};

const onCancel = (e, rowData, options, setIsDisabled = null, setOnCancel) => {
  if (editRowCount >= 1) {
    editRowCount = editRowCount - 1;
  }
  if (editRowCount === 0) {
    setIsDisabled && setIsDisabled(false);
  }
  setOnCancel(true);
  return (
    options.rowEditor?.onCancelClick && options.rowEditor?.onCancelClick(e)
  );
};
let fromWarehouseId = null;
let toWarehouseId = null;
const transferData = (
  onRowEditComplete,
  setIsDisabled,
  setDeletedRow,
  setOnCancel = () => {},
  setSelectedWarehouse,
  selectedWarehouse
) => {
  return {
    listItems: [],
    optionFrom: [],
    optionTo: [],
    dataTableConfig: {
      editMode: 'row',
      dataKey: 'index',
      onRowEditComplete: onRowEditComplete,
      setIsDisabled: setIsDisabled,
      tableStyle: { minWidth: '50rem' },
      onEdit: (e, options) => onEdit(e, options, setIsDisabled),
      onSave: (e, options) => onSave(e, options, setIsDisabled),
      onDelete: (e, rowData, options) =>
        onDelete(e, rowData, options, setIsDisabled, setDeletedRow),
      onCancel: (e, rowData, options) =>
        onCancel(e, rowData, options, setIsDisabled, setOnCancel),
    },
    columns: [
      {
        field: 'date',
        header: 'Date',
        editor: options => {
          return (
            <>
              <PFCalendar
                value={options?.value}
                onChange={e =>
                  options.editorCallback(e ? e.toString() : new Date())
                }
                appendTo={null}
                className="w-12rem"
              />
              <span style={{ color: 'red', height: '39.97px !important' }}>
                {!options?.value &&
                  getErrorMessageByField(
                    options?.rowData?.errorTransfer || [],
                    'date'
                  )}
              </span>
            </>
          );
        },
        body: dateVisible,
        style: { minWidth: '250px' },
      },
      {
        field: 'transferFromWarehouseId',
        header: 'Transfer From',
        editor: options => {
          if (!options?.value) {
            fromWarehouseId = null;
          }
          const locationOptions =
            store.getState().inventoryManagment?.Warehouse?.allWarehouses;
          return (
            <>
              <PFDropdown
                value={options?.value}
                placeholder="Transfer From"
                options={locationOptions}
                optionLabel="name"
                optionValue="_id"
                onChange={e => {
                  fromWarehouseId = e;
                  setSelectedWarehouse(Math.random());
                  options.editorCallback(e);
                }}
                filter={true}
                disabled={options?.rowData?._id ? true : false}
                floatLabel={false}
              />
              <span style={{ color: 'red' }}>
                {getErrorMessageByField(
                  options?.rowData?.errorTransfer || [],
                  'transferFromWarehouseId'
                )}
              </span>
            </>
          );
        },
        body: options => {
          let warehouseFrom =
            store.getState().inventoryManagment?.Warehouse.listOfWarehouses;
          let selectedWarehouseFrom = warehouseFrom.find(
            item => item.warehouseId === options?.transferFromWarehouseId
          );

          return selectedWarehouseFrom?.warehouseName;
        },

        style: { minWidth: '150px' },
      },
      {
        field: 'transferFromBinId',
        header: 'Bin From',
        editor: options => (
          <>
            <Bin
              field="transferFromBinId"
              options={options}
              warehouseId={fromWarehouseId}
              defaultSetting={{
                placeholder: 'Select Bin',
                optionLabel: 'binName',
                optionValue: '_id',
                reqFrom: 'transfer',
              }}
            />
            <span className="text-red-500">
              {!options?.value &&
                getErrorMessageByField(
                  options?.rowData?.errorTransfer || [],
                  'transferFromBinId'
                )}
            </span>
          </>
        ),
        body: options => {
          return options?.transferFromBinData?.[0]?.binName || '';
        },

        style: { minWidth: '150px' },
      },
      {
        field: 'transferToWarehouseId',
        header: 'Transfer To',
        editor: options => {
          if (!options?.value) {
            toWarehouseId = null;
          }
          const locationOptions =
            store.getState().inventoryManagment?.Warehouse?.allWarehouses;
          return (
            <>
              <PFDropdown
                value={options?.value}
                placeholder="Transfer To"
                options={locationOptions}
                optionLabel="name"
                optionValue="_id"
                onChange={e => {
                  toWarehouseId = e;
                  setSelectedWarehouse(Math.random());
                  return options.editorCallback(e);
                }}
                filter={true}
                disabled={options?.rowData?._id ? true : false}
                floatLabel={false}
              />
              <span style={{ color: 'red' }}>
                {getErrorMessageByField(
                  options?.rowData?.errorTransfer || [],
                  'transferToWarehouseId'
                )}
              </span>
            </>
          );
        },
        body: options => {
          const locationOptions =
            store.getState().inventoryManagment?.Warehouse?.allWarehouses;
          return locationOptions.find(
            item => item._id === options?.transferToWarehouseId
          )?.name;
        },
        style: { minWidth: '150px' },
      },
      {
        field: 'transferToBinId',
        header: 'Bin To',
        editor: options => (
          <>
            <Bin
              field="transferToBinId"
              options={options}
              warehouseId={toWarehouseId}
              defaultSetting={{
                placeholder: 'Select Bin',
                optionLabel: 'binName',
                optionValue: '_id',
                reqFrom: 'transfer',
              }}
            />
            <span className="text-red-500">
              {!options?.value &&
                getErrorMessageByField(
                  options?.rowData?.errorTransfer || [],
                  'transferToBinId'
                )}
            </span>
          </>
        ),
        body: options => {
          return options?.transferToBinData?.[0]?.binName || '';
        },
        style: { minWidth: '150px' },
      },
      {
        field: 'quantity',
        header: 'Quantity',
        editor: options => (
          <>
            <PFNumberBox
              value={options?.value}
              placeholder="Quantity"
              onChange={e => {
                return options.editorCallback(e);
              }}
              className="p-inputtext-sm"
              minFractionDigits={2}
              maxFractionDigits={2}
            />

            <span style={{ color: 'red' }}>
              {!options?.value &&
                getErrorMessageByField(
                  options?.rowData?.errorTransfer || [],
                  'quantity'
                )}
            </span>
          </>
        ),
        style: { minWidth: '150px' },
      },
      {
        field: 'unit',
        header: 'Unit',
        editor: options => {
          let { unit } =
            store.getState().inventoryManagment.ProductInfo.itemInfo;
          unit = _.cloneDeep(unit);
          unit = unit.sort((a, b) => {
            const nameA = a.name.toLowerCase();
            const nameB = b.name.toLowerCase();

            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
          });
          return (
            <>
              <PFDropdown
                value={options?.value}
                placeholder="Select Unit"
                options={unit}
                optionLabel="name"
                optionValue="name"
                selectedOption={unit[0]?.name}
                onChange={e => options.editorCallback(e)}
                disabled={options?.rowData?._id ? true : false}
                floatLabel={false}
              />
              <span style={{ color: 'red', position: 'relative' }}>
                {!options?.value &&
                  getErrorMessageByField(
                    options?.rowData?.errorTransfer || [],
                    'unit'
                  )}
              </span>
            </>
          );
        },

        style: { minWidth: '150px' },
      },

      {
        field: 'receiverName',
        header: 'Receiver Name',
        editor: options => (
          <>
            <PFTextBox
              value={options?.value}
              placeholder="Receiver Name"
              onChange={e => options.editorCallback(e)}
            />
            <span style={{ color: 'red' }}>
              {!options?.value &&
                getErrorMessageByField(
                  options?.rowData?.errorTransfer || [],
                  'receiverName'
                )}
            </span>
          </>
        ),
        style: { minWidth: '150px' },
      },
      {
        field: 'note',
        header: 'Note',
        editor: options => (
          <>
            <PFTextBox
              value={options?.value}
              placeholder="Note"
              onChange={e => options.editorCallback(e)}
            />
            <span style={{ color: 'red' }}>
              {!options?.value &&
                getErrorMessageByField(
                  options?.rowData?.errorTransfer || [],
                  'note'
                )}
            </span>
          </>
        ),
        style: { minWidth: '150px' },
      },
    ],
  };
};

const updateRowData = (
  updateRow,
  data,
  setData,
  crud = null,
  dispatch = null,
  toast,
  setDataTableloading,
  setTransferError = () => {}
) => {
  if (crud && crud === 'U' && updateRow) {
    let { index, newData } = updateRow;
    newData['validateTransfer'] = true;
    newData['errorTransfer'] = [];
    if (!newData?.date) {
      newData['validateTransfer'] = false;
      newData['errorTransfer'] = [
        ...newData['errorTransfer'],
        { field: 'date', message: 'Required' },
      ];
    }
    if (!newData?.transferFromWarehouseId) {
      newData['validateTransfer'] = false;
      newData['errorTransfer'] = [
        ...newData['errorTransfer'],
        { field: 'transferFromWarehouseId', message: 'Required' },
      ];
    }
    if (!newData?.transferFromBinId) {
      newData['validateTransfer'] = false;
      newData['errorTransfer'] = [
        ...newData['errorTransfer'],
        { field: 'transferFromBinId', message: 'Required' },
      ];
    }

    if (!newData?.transferToWarehouseId) {
      newData['validateTransfer'] = false;
      newData['errorTransfer'] = [
        ...newData['errorTransfer'],
        { field: 'transferToWarehouseId', message: 'Required' },
      ];
    }

    if (!newData?.transferToBinId) {
      newData['validateTransfer'] = false;
      newData['errorTransfer'] = [
        ...newData['errorTransfer'],
        { field: 'transferToBinId', message: 'Required' },
      ];
    }

    if (newData?.transferFromBinId && newData?.transferToBinId) {
      if (newData?.transferFromBinId === newData?.transferToBinId) {
        newData['validateTransfer'] = false;
        newData['errorTransfer'] = [
          ...newData['errorTransfer'],
          {
            field: 'transferToWarehouseId',
            message: 'Transfer locations should not be same.',
          },
        ];
        newData['errorTransfer'] = [
          ...newData['errorTransfer'],
          {
            field: 'transferFromWarehouseId',
            message: 'Transfer locations should not be same.',
          },
        ];
      }
    } else {
      if (
        newData?.transferFromWarehouseId === newData?.transferToWarehouseId &&
        !newData?.transferFromBinId &&
        !newData?.transferToBinId
      ) {
        newData['validateTransfer'] = false;
        newData['errorTransfer'] = [
          ...newData['errorTransfer'],
          {
            field: 'transferToWarehouseId',
            message: 'Transfer locations should not be same.',
          },
        ];
        newData['errorTransfer'] = [
          ...newData['errorTransfer'],
          {
            field: 'transferFromWarehouseId',
            message: 'Transfer locations should not be same.',
          },
        ];
      }
    }

    if (!newData?.quantity) {
      newData['validateTransfer'] = false;
      newData['errorTransfer'] = [
        ...newData['errorTransfer'],
        { field: 'quantity', message: 'Required' },
      ];
    }

    if (!newData?.unit) {
      newData['validateTransfer'] = false;
      newData['errorTransfer'] = [
        ...newData['errorTransfer'],
        { field: 'unit', message: 'Required' },
      ];
    }

    if (!newData?.receiverName) {
      newData['validateTransfer'] = false;
      newData['errorTransfer'] = [
        ...newData['errorTransfer'],
        { field: 'receiverName', message: 'Required' },
      ];
    }

    if (!newData?.note) {
      newData['validateTransfer'] = false;
      newData['errorTransfer'] = [
        ...newData['errorTransfer'],
        { field: 'note', message: 'Required' },
      ];
    }
    if (!newData?.validateTransfer) {
      setDataTableloading(false);
      editDataTableRow('init-index-' + newData?.index);

      const { listItems, ...rest } = data;
      if (newData?.validateTransfer) {
        newData['newRow'] = false;
      }
      const updateList = listItems.map((item, i) =>
        i === index ? newData : item
      );
      setData(s => ({ ...s, listItems: updateList }));
    } else {
      let memoryRow = Object.assign({}, newData);
      delete newData.newRow;
      delete newData.validateTransfer;
      delete newData.errorTransfer;
      delete newData.index;
      const res = addUpdateTransfer(newData, dispatch, toast);

      res
        .then(result => {
          if (!result?.status) {
            toast?.current?.show({
              severity: 'error',
              summary: 'Error!',
              detail: result?.message,
              life: 3000,
            });

            const { listItems, ...rest } = data;
            const updateList = listItems.map((item, i) =>
              i === index ? memoryRow : item
            );
            setData(s => ({ ...s, listItems: updateList }));
            setDataTableloading(false);
            setTransferError({ editId: memoryRow?.index });
          } else {
            const { listItems, ...rest } = data;
            if (newData?.validateTransfer) {
              newData['newRow'] = false;
            }
            const updateList = listItems.map((item, i) =>
              i === index ? newData : item
            );
            setData(s => ({ ...s, listItems: updateList }));
          }
        })
        .catch(error => {
          console.error('error ', error);
        });
    }
  } else if (crud && crud === 'D' && updateRow) {
    if (updateRow?._id) {
      deleteTransfer(updateRow?._id, updateRow?.productId, dispatch, toast);
    } else {
      data.listItems.shift();
      setData(s => ({ ...s, listItems: data.listItems }));
      setDataTableloading(false);
    }
  }
};

export const editDataTableRow = className => {
  const errorId = DomHandlerCall(className);
  setTimeout(() => {
    errorId?.handler[0].click();
  }, 500);
};

const displayDelayed = (rowInserted, setRowInserted) => {
  if (rowInserted) {
    const edit = DomHandlerCall('p-row-editor-init');
    setTimeout(() => {
      edit?.handler[0].click();
    }, 500);

    setRowInserted(false);
  }
};

const addUpdateTransfer = async (data, dispatch, toast) => {
  try {
    let addUpdateTransfer = null;
    if (data?._id) {
      addUpdateTransfer = await apiService.patchWithDiffBaseUrl(
        `/stock-transfer/update/${data?._id}`,
        { ...data },
        URL_CONSTANTS.INVENTORY.baseUrl
      );
    } else {
      addUpdateTransfer = await apiService.postWithDiffBaseUrl(
        `/stock-transfer/create`,
        { ...data },
        URL_CONSTANTS.INVENTORY.baseUrl
      );
    }

    if (addUpdateTransfer?.status) {
      const { productId, _id } =
        store.getState().inventoryManagment.ProductInfo.itemInfo;
      getProduct(productId, dispatch);
      data?._id && toast
        ? toast.current.show({
            severity: 'success',
            summary: 'Success!',
            detail: 'Transfer Updated successfully',
            life: 3000,
          })
        : toast.current.show({
            severity: 'success',
            summary: 'Success!',
            detail: 'Transfer Added successfully',
            life: 3000,
          });
    }
    return addUpdateTransfer;
  } catch (error) {
    return { status: false, message: error.message };
  }
};

const deleteTransfer = async (id, productId, dispatch, toast) => {
  try {
    const deleteTransfer = await apiService.deleteWithDiffBaseUrl(
      `/stock-transfer/delete/${id}`,
      null,
      URL_CONSTANTS.INVENTORY.baseUrl
    );
    if (deleteTransfer?.status) {
      const item = store.getState().inventoryManagment.ProductInfo.itemInfo;
      productId = item.productId;
      getProduct(productId, dispatch);
      toast &&
        toast.current.show({
          severity: 'success',
          summary: 'Success!',
          detail: 'Adjustment deleted successfully',
          life: 3000,
        });
    }
    return deleteTransfer;
  } catch (error) {
    console.error('error ', error);
    return [];
  }
};

const getTansfers = async (productId, dispatch, setDataTableloading) => {
  try {
    const listOfTransfers = await apiService.getWithDiffBaseUrl(
      `/stock-transfer/get-all/${productId}`,
      null,
      URL_CONSTANTS.INVENTORY.baseUrl
    );
    if (listOfTransfers?.status) {
      const list = listOfTransfers.data?.map((item, i) => {
        const newKeys = {
          index: i,
          validateAdjustment: true,
          errorAdjustment: [],
        };
        return { ...item, ...newKeys };
      });
      dispatch(addTransfer({ item: list }));
      setDataTableloading(false);
    }

    return listOfTransfers;
  } catch (error) {
    console.error('error ', error);
    return [];
  }
};
export {
  transferData,
  getErrorMessageByField,
  dateVisible,
  emptyObject,
  addNewRow,
  updateRowData,
  displayDelayed,
  getTansfers,
};
