// **** React Imports ****
import React, { useCallback, useMemo } from 'react';
import { useHistory, Link } from 'react-router-dom';

// **** Utilities ****
import { Grid, Button, IconButton } from '@material-ui/core';
import {
  Visibility as VisibilityIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  FileCopy as CopyIcon,
} from '@material-ui/icons';

// **** Custom Components *****
import { useSelector, useDispatch } from 'react-redux';

import GenericDataTable from '../../../shared/GenericDataTable/GenericDataTable';
import PageHeader from '../../../shared/PageHeader/PageHeader';
import {
  WidgetTypeOptions,
  SUPER_CLIENTS,
  ALLOWED_CLIENTS_FOR_WIDGET_CREATION,
} from '../../../../constants';
import { Alert } from '../../../shared/Alerts/Alert';
import GenericConfirmationDialog from '../../../shared/GenericConfirmationDialog/GenericConfirmationDialog';
import GenericInputDialog from '../../../shared/GenericInputDialog/GenericInputDialog';
import { setAssignWidgets } from '../../../../redux/slices/assign-widgets.slice';

// **** Services *****
import { getWidgetsList } from '../../Dashboards/Dashboards.service';
import {
  cloneWidget,
  deleteWidget,
} from '../../Dashboards/Widgets/WidgetTemplate.service';
import { useAlerts } from '../../../shared/Alerts/alertsService';

// **** Styles *****

import { checkPermission } from '../../../../utils/Helpers';
import permissions from '../../../../config/permissions';
import { WIDGET_FILTER_TYPES } from '../../Widget/constant/constant';
import TableColumnsLoader from '../../../shared/Loader/tableColumnsLoader';

import { useStyles } from './WidgetTemplateList.styles';
import AssignWidgets from './components/AssignWidgets';
import AssignWidgetsValidate from './components/AssignWidgetsValidate';

const widgetTemplateBreadcrumb = [
  {
    text: 'Admin',
  },
  {
    link: '/admin/widgets',
    text: 'Widgets',
  },
];
const user_id = localStorage.getItem('user_id');
function handleRowHover(event, row, rowIndex) {
  let control = document.getElementById(rowIndex);
  if (control) control.style.visibility = 'visible';
}

function handleRowHoverLeave(event, row, rowIndex) {
  let control = document.getElementById(rowIndex);
  if (control?.style?.visibility) control.style.visibility = 'hidden';
}

const WidgetTemplateList = () => {
  const [loadingWidgetList, setLoadingWidgetList] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState({
    limit: 100,
    offset: 0,
  });
  const [widgetsListData, setWidgetsListData] = React.useState();
  const [cloneModalState, setCloneModalState] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState();
  const [confirmDialog, setConfirmDialog] = React.useState({
    title: 'Are you sure you still want to delete the Widget?',
    header: 'Delete Widget',
    subtitle: '',
    isOpen: false,
  });
  const [isClientAllowedToCreateWidget, setIsClientAllowedToCreateWidget] =
    React.useState(() => {
      let currentClientId;
      if (localStorage?.getItem('client_id')) {
        currentClientId = localStorage?.getItem('client_id');
      }
      return ALLOWED_CLIENTS_FOR_WIDGET_CREATION?.includes(currentClientId);
    });
  const [isClientAllowedToAssignWidgets, setIsClientAllowedToAssignWidgets] =
    React.useState(() => {
      let currentClientId;
      if (localStorage?.getItem('client_id')) {
        currentClientId = localStorage?.getItem('client_id');
      }
      return SUPER_CLIENTS?.includes(currentClientId);
    });
  const [selectedRows, setSelectedRows] = React.useState([]);

  const classes = useStyles();
  const history = useHistory();
  const systemUser = 'System';

  const { alert, setAlert } = useAlerts();
  const dispatch = useDispatch();
  const { isAssignWidgetValidateDialogVisible, clientsList } = useSelector(
    state => state.assignWidgets
  );

  React.useEffect(() => {
    getWidgetsList(searchQuery, setLoadingWidgetList, setWidgetsListData);
  }, [searchQuery]);

  React.useEffect(() => {
    setIsClientAllowedToCreateWidget(
      ALLOWED_CLIENTS_FOR_WIDGET_CREATION?.includes(
        localStorage?.getItem('client_id')
      )
    );
    setIsClientAllowedToAssignWidgets(
      SUPER_CLIENTS?.includes(localStorage?.getItem('client_id'))
    );
  }, [localStorage?.getItem('client_id')]);

  const handleCloneWidget = useCallback(
    cloneWidgetName => {
      const cloneWidgetCall = async () => {
        const clonedObj = await cloneWidget(
          widgetsListData.items[selectedRow],
          cloneWidgetName,
          setLoadingWidgetList,
          setAlert
        );
        if (clonedObj) {
          setWidgetsListData({
            items: [...widgetsListData.items, clonedObj],
            count: widgetsListData.count + 1,
          });
        }
      };
      setCloneModalState(false);
      cloneWidgetCall();
    },
    [widgetsListData, selectedRow]
  );

  const openCloneModal = index => {
    setSelectedRow(index);
    setCloneModalState(true);
  };

  const onConfirmDialog = () => {
    const deleteWidgetCall = async () => {
      const res = await deleteWidget(
        widgetsListData.items[selectedRow].widget_template_id,
        setLoadingWidgetList,
        setAlert
      );
      if (res) {
        setWidgetsListData({
          items: [
            ...widgetsListData.items.slice(0, selectedRow),
            ...widgetsListData.items.slice(selectedRow + 1),
          ],
          count: widgetsListData.count - 1,
        });
      }
    };
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    deleteWidgetCall();
  };

  const handleDeleteWidget = index => {
    setSelectedRow(index);
    setConfirmDialog({
      ...confirmDialog,
      isOpen: true,
    });
  };

  const checkEditPermission = obj => {
    if (!obj?.editable) {
      return true;
    }

    if (obj.owner_user_id == user_id) {
      return true;
    }
    return false;
  };
  const dataTableColumns = [
    {
      name: '',
      options: {
        sort: false,
        filter: false,
        viewColumns: false,
        setCellProps: () => ({
          style: { minWidth: '200px', maxWidth: '200px' },
        }),
        ...(!loadingWidgetList
          ? {
              customBodyRenderLite: dataIndex => {
                return (
                  <span style={{ visibility: 'hidden' }} id={dataIndex}>
                    {checkPermission(permissions?.widgetManagement?.menu) && (
                      <span>
                        <IconButton classes={{ root: classes.actionIcons }}>
                          <Link
                            to={`/admin/widget/view/${dataArray && dataArray[dataIndex]?.widgetId}`}
                          >
                            <VisibilityIcon />
                          </Link>
                        </IconButton>
                      </span>
                    )}
                    {checkPermission(
                      permissions?.widgetManagement?.editWidget
                    ) && dataArray
                      ? checkEditPermission(dataArray[dataIndex]) && (
                          <span>
                            <IconButton classes={{ root: classes.actionIcons }}>
                              <Link
                                to={`/admin/widget/edit/${dataArray && dataArray[dataIndex]?.widgetId}`}
                              >
                                <EditIcon />
                              </Link>
                            </IconButton>
                          </span>
                        )
                      : null}
                    {checkPermission(
                      permissions?.widgetManagement?.deleteWidget
                    ) && ( // isClientAllowedToCreateWidget &&
                      <span>
                        <IconButton
                          classes={{ root: classes.actionIcons }}
                          onClick={index => openCloneModal(dataIndex)}
                        >
                          <CopyIcon />
                        </IconButton>
                      </span>
                    )}
                    {checkPermission(
                      permissions?.widgetManagement?.deleteWidget
                    )
                      ? checkEditPermission(dataArray[dataIndex]) && (
                          <span>
                            <IconButton
                              classes={{ root: classes.actionIcons }}
                              onClick={() => handleDeleteWidget(dataIndex)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </span>
                        )
                      : null}
                  </span>
                );
              },
            }
          : {}),
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        sort: true,
        setCellProps: () => ({ style: { width: '25%' } }),
      },
    },
    {
      name: 'description',
      label: 'Description',
      options: {
        sort: true,
        setCellProps: () => ({ style: { width: '31%' } }),
      },
    },
    {
      name: 'widgetType',
      label: 'Widget Type',
      options: {
        sort: false,
        setCellProps: () => ({ style: { width: '15%' } }),
      },
    },
    {
      name: 'widgetFilterType',
      label: 'Widget Filter Type',
      options: {
        sort: true,
        setCellProps: () => ({ style: { width: '15%' } }),
      },
    },
  ];

  let dataArray = [];

  widgetsListData?.items?.map((val, index) => {
    dataArray.push({
      name: val.widget_name,
      description: val?.widget_description,
      dataSource: val?.data_source[0],
      widgetId: val?.widget_template_id,
      widgetType: WidgetTypeOptions.map(widgetType =>
        val?.widget_display_type?.includes(widgetType?.key)
          ? widgetType?.value
          : null
      )
        .filter(widget => widget !== null)
        ?.toString(),
      editable: val?.editable,
      owner_name: val?.owner_name,
      owner_user_id: val?.owner_user_id,
      widgetFilterType: WIDGET_FILTER_TYPES[val?.widget_filter_type],
    });
  });

  let intervalVal = '';
  const handleSearch = searchInputVal => {
    const searchString = searchInputVal?.trim();

    /** Timeout will help to let multiple characters in the TextInput,* and API call would be optimized to get Values for Input of few chars at once,* rather than for every single character*/
    try {
      clearTimeout(intervalVal);
    } catch (err) {
      console.log(err);
    }
    intervalVal = setTimeout(() => {
      setLoadingWidgetList(true);
      setSearchQuery(prevState => ({
        ...prevState,
        offset: 0,
        search_query: searchString,
      }));
    }, 500);
  };
  const getSortKey = changedColumn => {
    let col;
    switch (changedColumn) {
      case 'name':
        col = 'widget_name';
        break;
      case 'description':
        col = 'widget_description';
        break;
      case 'widgetFilterType':
        col = 'widget_filter_type';
        break;
      default:
        col = changedColumn;
        break;
    }
    return col;
  };

  const handleWidgetListChange = () => {
    setSelectedRows([]);
    dispatch(
      setAssignWidgets({
        selectedWidgets: [],
      })
    );
  };
  const dataTableOptions = {
    download: false,
    print: false,
    filter: false,
    viewColumns: true,
    responsive: 'standard',
    selectableRows: isClientAllowedToAssignWidgets ? 'multiple' : 'none',
    tableBodyMinHeight: '300px',
    selectableRowsHeader: true,
    serverSide: true,
    count: widgetsListData?.count,
    textLabels: {
      body: {
        noMatch:
          !setLoadingWidgetList && !dataArray.length && 'No records found',
      },
    },
    setRowProps: (row, dataIndex, rowIndex) => {
      return {
        onMouseEnter: e => handleRowHover(e, row, rowIndex),
        onMouseLeave: e => handleRowHoverLeave(e, row, rowIndex),
        onDoubleClick: () => {
          // history.push(
          //   `/project/edit/${
          //     tableData[0]?.data[dataIndex] && tableData[0]?.data[dataIndex]['Project.project_id']
          //   }`
          // );
        },
      };
    },
    onChangeRowsPerPage: numberOfRows => {
      setLoadingWidgetList(true);
      setSearchQuery(prevState => ({
        ...prevState,
        limit: numberOfRows,
        offset: 0,
      }));
      handleWidgetListChange();
    },
    onChangePage: currentPage => {
      setLoadingWidgetList(true);
      setSearchQuery(prevState => ({
        ...prevState,
        offset: currentPage * searchQuery.limit,
      }));
      handleWidgetListChange();
    },
    onColumnSortChange: (changedColumn, direction) => {
      setLoadingWidgetList(true);
      setSearchQuery(prevState => ({
        ...prevState,
        sortKey: getSortKey(changedColumn),
        sortDirection: direction.toUpperCase(),
      }));
      handleWidgetListChange();
    },
    rowsPerPage: searchQuery.limit,
    rowsPerPageOptions: [10, 20, 50, 100],
    onSearchChange: searchText => {
      handleSearch(searchText);
      handleWidgetListChange();
    },
    selectToolbarPlacement: 'none',
    onRowSelectionChange: (
      currentRowsSelected,
      allRowsSelected,
      rowsSelected
    ) => {
      const checkedWidgets = dataArray
        .filter((row, index) => rowsSelected?.includes(index))
        ?.map(widget => {
          return { widgetId: widget?.widgetId, widgetName: widget?.name };
        });
      setSelectedRows(rowsSelected);
      dispatch(
        setAssignWidgets({
          selectedWidgets: checkedWidgets,
        })
      );
    },
    rowsSelected: selectedRows,
    pagination: !loadingWidgetList,
  };
  const editTemplateDetails = (action, index, rowData) => {
    // setFormAction('edit');
    // templateFormik?.setValues({
    //   template_id: rowData?.template_id,
    //   template_name: rowData?.template_name,
    //   source_system_id: rowData?.source_system_id,
    //   project_type_id: rowData?.project_type_id,
    //   project_category_id: rowData?.project_category_id,
    //   is_custom: rowData?.is_custom,
    // });
    // setDialogSettings((prevState) => ({
    //   ...prevState,
    //   showButton2: true,
    //   button2Text: 'Save',
    //   title: 'Edit Template',
    // }));
    // setFileName(rowData.template_file);
    // setIsOpen(true);
  };

  const handleAssignWidgets = e => {
    dispatch(
      setAssignWidgets({
        isAssignWidgetValidateDialogVisible: true,
      })
    );
    setSelectedRows([]);
  };

  const tableLoader = useMemo(() => {
    return TableColumnsLoader(dataTableColumns, {
      rows: searchQuery?.limit,
      isValue: false,
    });
  }, [dataTableColumns]);

  return (
    <Grid container spacing={2}>
      {alert?.exists && (
        <Grid item>
          <Alert />
        </Grid>
      )}
      <Grid
        container
        item
        justifyContent="space-between"
        direction="row"
        alignItems="center"
      >
        <Grid item>
          <PageHeader
            pageTitle="Widgets"
            breadCrumbArray={widgetTemplateBreadcrumb}
          />
        </Grid>
        <Grid item>
          {isClientAllowedToAssignWidgets && clientsList?.length > 0 && (
            <Button
              color="primary"
              variant="outlined"
              onClick={handleAssignWidgets}
              disabled={selectedRows?.length < 1}
              className="ml-2"
            >
              Assign Widgets
            </Button>
          )}

          {checkPermission(permissions?.widgetManagement?.createWidget) && ( // isClientAllowedToCreateWidget &&
            <Button
              color="primary"
              variant="contained"
              className="ml-2"
              onClick={() => history.push('/admin/widget/create')}
            >
              Add New
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid item classes={{ root: classes.dataTableWrapper }}>
        <GenericDataTable
          columns={dataTableColumns}
          data={loadingWidgetList ? tableLoader : dataArray}
          options={dataTableOptions}
        />
      </Grid>
      <GenericInputDialog
        title="Clone Widget"
        label="Widget Name"
        isOpen={cloneModalState}
        onClose={() => setCloneModalState(false)}
        onConfirm={handleCloneWidget}
      />
      <GenericConfirmationDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        onConfirmDialog={onConfirmDialog}
      />
      <AssignWidgetsValidate />
      <AssignWidgets />
    </Grid>
  );
};

export default WidgetTemplateList;
