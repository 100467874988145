import { useEffect, useState } from 'react';
import { Grid, TextField, Button, Paper, Typography } from '@material-ui/core';
import {
  AddCircleOutline as AddCircleOutlineIcon,
  RemoveCircleOutline as RemoveCircleOutlineIcon,
} from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

import PageHeader from '../../shared/PageHeader/PageHeader';
import { VALID_MEASURES, WidgetTypeOptions } from '../../../constants';
import { useAlerts } from '../../shared/Alerts/alertsService';
import { getDashboardList } from '../Dashboards/Dashboards.service';
import { isRoEnabled, isWarrantyEnabled } from '../../../utils/Helpers';
import PFTableLoader from '../../shared/Loader/PFTableLoader';

import ActionButtons from './components/ActionButtons';
import BasicSection from './components/BasicSection';
import WidgetDetails from './components/WidgetDetails';
import TableDetails from './components/TableDetails';
import WidgetRenderer from './components/WidgetRenderer';
import WidgetTableRenderer from './components/WidgetTableRenderer';
import FilterSection from './components/FilterSection';
import OrderBySection from './components/OrderBySection';
import WidgetTypeFilter from './components/WidgetTypeFilter';
import ButtonWidget from './components/ButtonWidget';
import {
  getDataSource,
  getQueryResult,
  createWidget,
  getWidgetDataById,
  editWidget,
  getFilterDetails,
} from './service/widget.service';
import {
  getChartQuery,
  getTableQuery,
  mapResponseToFormik,
  prepareQueryWithFilters,
  prepareChartQueryWithFilters,
  routingWidgetDefaultCondition,
} from './helpers/query';
import { useStyles } from './WidgetContainer.styles';
import {
  getWidgetFieldsValidationSchema,
  widgetsFormInitialValues,
} from './helpers/widget-form-validations';
import RouteConfiguration from './components/RouteConfiguration';
import GroupBySection from './components/GroupBySection';
import WidgetGroupedTableRenderer from './components/WidgetGroupedTableRenderer';
import { CUBE_LABELS } from './constant/constant';
import { Skeleton } from 'primereact/skeleton';

const initialOrderBy = {
  columns: '',
  direction: 1,
};
const WidgetContainer = () => {
  const classes = useStyles();
  const history = useHistory();
  const { alert, setAlert, clearAlert } = useAlerts();
  const action = window.location.pathname.split('/')[3];
  const widgetId = window.location.pathname.split('/')[4];
  const createWidgetTemplateBreadcrumb = [
    {
      text: 'Admin',
    },
    {
      link: '/admin/widgets',
      text: 'Widgets',
    },
    {
      link: '/admin/widget/create',
      text: action === 'Create' ? 'Create New Widget' : 'Widget Details',
    },
  ];

  const [cubeList, setCubeList] = useState([]);
  const [dimensionList, setDimensionList] = useState([]);
  const [chartMeasures, setChartMeasures] = useState([]);
  const [tableMeasures, setTableMeasures] = useState([]);

  const [tableList, setTableList] = useState([]);
  const [cubeResponse, setCubeResponse] = useState([]);
  const [cubeTableResponse, setCubeTableResponse] = useState([]);
  const [tablePagination, setTablePagination] = useState({
    filters: null,
  });
  const [paginator, setPaginator] = useState({
    first: 0,
    currentPage: 0,
    perPage: 10,
  });
  const [orderBy, setOrderBy] = useState(initialOrderBy);
  const [totalCount, setTotalCount] = useState(0);
  const [cubeQuery, setCubeQuery] = useState({});
  const [loading, setLoading] = useState(false);
  const [hidePreview, setHidePreview] = useState(true);

  const [selectedTable, setSelectedTable] = useState('Project');
  const [showChartFilter, setShowChartFilter] = useState(false);
  const [showTableFilter, setShowTableFilter] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [dashboardListData, setDashboardListData] = useState({});
  const [selectedChartTable, setSelectedChartTable] = useState('Project');
  const [filterDetails, setFilterDetails] = useState(null);
  const [isCopyUse, setIsCopyUse] = useState(true);
  const [visible, setVisible] = useState(false);
  const [showButtonPreview, setShowButtonPreview] = useState(false);

  const widgetFormik = useFormik({
    initialValues: widgetsFormInitialValues,
    validationSchema: getWidgetFieldsValidationSchema(
      dimensionList,
      selectedTable
    ),
    onSubmit: async values => {
      setSaveLoading(true);
      if (action === 'edit') {
        const response = await editWidget(values, widgetId);
        if (response.status) {
          setAlert('success', 'Widget updated successfully', false, true);
          history.push('/admin/widgets');
        }
      }
      if (action === 'create') {
        const response = await createWidget(values);
        if (response.status) {
          setAlert('success', 'Widget created successfully', false, true);
          history.push('/admin/widgets');
        }
      }
      setSaveLoading(false);
    },
    enableReinitialize: true,
  });

  const routingAcceptHandler = () => {
    setShowChartFilter(false);
    setHidePreview(true);
    setSelectedChartTable('Project');
    setSelectedTable('Project');
    updateTableFilter({
      ...widgetFormik.values,
      custom_properties: {
        ...widgetFormik.values.custom_properties,
        selectedReportTable: 'Project',
        reportTablePrimaryKey: 'Project.project_id',
        selectedChartTable: 'Project',
      },
    });
  };
  const routingRejectHandler = () => {
    widgetFormik.setFieldValue('route_widget', 0);
  };
  const setRoutingWidgetHandler = isRouting => {
    if (isRouting) {
      if (selectedChartTable != 'Project' || selectedTable != 'Project') {
        setVisible(true);
      }
    }
  };

  const setSelectedChartTableValue = (chartTableValue, formikData) => {
    setSelectedChartTable(chartTableValue);
    const newValues = {
      ...formikData,
      chart_filter_conditions: {
        condition: 'and',
        filters: [
          {
            condition: 'and',
            fields: [{ tableField: '', operator: '', value: '' }],
          },
        ],
      },
      chart_configuration: {
        dimension: '',
        measure: '',
        measureSummarizedBy: '',
        measureTarget: 100,
        groupBy: [],
      },
    };
    widgetFormik.setValues(newValues);
    setShowChartFilter(false);
    setHidePreview(true);
  };

  const updateTableFilter = formikData => {
    const newValues = {
      ...formikData,
      table_configuration: [{ measureName: '', alice: '' }],
      table_filter_conditions: {
        condition: 'and',
        filters: [
          {
            condition: 'and',
            fields: [{ tableField: '', operator: '', value: '' }],
          },
        ],
      },
      order_by: {
        orderField: '',
        orderBy: 'asc',
      },
    };
    widgetFormik.setValues(newValues);
    setShowTableFilter(false);
    setHidePreview(true);
  };

  const fetchQubeJsData = async () => {
    const response = await getDataSource();
    const filteredCubes = response?.filter(cube => {
      if (
        cube?.title === CUBE_LABELS.USER_DETAILS &&
        cube?.name !== CUBE_LABELS.USER_META
      ) {
        return false;
      } else {
        return true;
      }
    });

    setCubeList(filteredCubes);
    const formattedDimensions = [];
    const tableList = [];
    if (Array.isArray(filteredCubes))
      filteredCubes.forEach(option => {
        const primaryKeyMeasure = option.dimensions.find(
          item => item.meta && item.meta.primaryKey
        );
        tableList.push({
          key: option.name,
          value: option.title || option.name,
          title: option.title || option.name,
          primaryKey: primaryKeyMeasure ? primaryKeyMeasure.name : undefined,
        });
        option.dimensions.forEach(measureItem => {
          if (!measureItem?.meta?.foreignKeyField) {
            formattedDimensions.push({
              firstLetter: option.title || option.name,
              key: measureItem.name,
              value: measureItem.shortTitle,
              primaryKey: primaryKeyMeasure
                ? primaryKeyMeasure.name
                : undefined,
              primaryKeyMeasures: primaryKeyMeasure
                ? primaryKeyMeasure.measures
                : [],
              ...measureItem,
            });
          }
        });
      });
    setTableList(tableList);
    setDimensionList(formattedDimensions);
  };

  useEffect(async () => {
    if (action === 'create') {
      fetchQubeJsData();
    }
    const filterResponse = await getFilterDetails();
    setFilterDetails(filterResponse);
    return;
  }, []);
  useEffect(() => {
    setHidePreview(true);
  }, [widgetFormik.values]);
  useEffect(() => {
    if (Array.isArray(dimensionList) && dimensionList.length) {
      if (VALID_MEASURES[selectedChartTable]) {
        const selectedChartTableDimensions = dimensionList.filter(
          ({ firstLetter, name }) =>
            selectedChartTable === firstLetter &&
            VALID_MEASURES[selectedChartTable].includes(name)
        );
        setChartMeasures(selectedChartTableDimensions);
      }
    }
  }, [selectedChartTable, dimensionList]);
  useEffect(() => {
    if (Array.isArray(dimensionList) && dimensionList.length) {
      if (VALID_MEASURES[selectedTable]) {
        const selectedTableDimensions = dimensionList.filter(
          ({ firstLetter, name }) =>
            selectedTable === firstLetter &&
            VALID_MEASURES[selectedTable].includes(name)
        );
        setTableMeasures(selectedTableDimensions);
      }
    }
  }, [selectedTable, dimensionList]);

  const fetchData = async () => {
    if (
      widgetFormik.values.widget_type == 'external-link-button' &&
      ['urlWidget', 'dashboardWidget', 'readOnlyWidget'].includes(
        widgetFormik?.values?.custom_properties?.buttonWidgetType
      )
    ) {
      setHidePreview(false);
      setShowButtonPreview(true);
    } else {
      if (
        (widgetFormik.values.widget_type == 'external-link-button' &&
          ['tabularWidget', 'routingWidget'].includes(
            widgetFormik?.values?.custom_properties?.buttonWidgetType
          )) ||
        widgetFormik.values.widget_type == 'card'
      ) {
        setShowButtonPreview(true);
      }

      setTablePagination({
        filters: null,
      });
      setPaginator({ first: 0, currentPage: 0, perPage: 10 });
      setLoading(true);
      const apiList = [fetchTableData()];
      if (widgetFormik.values.widget_type != 'external-link-button') {
        apiList.push(fetchChartData());
      }
      Promise.all(apiList).then(() => {
        setLoading(false);
        setHidePreview(false);
      });
    }
  };

  useEffect(() => {
    Promise.all([fetchTableData(tablePagination, orderBy)]).then(() =>
      setLoading(false)
    );
  }, [tablePagination]);
  useEffect(() => {
    Promise.all([fetchTableData(tablePagination, orderBy)]).then(() =>
      setLoading(false)
    );
  }, [orderBy, paginator]);

  const fetchChartData = async () => {
    const formattedQuery = getChartQuery(widgetFormik.values);
    const query = prepareChartQueryWithFilters({
      query: widgetFormik.values,
      formattedQuery,
      filterDetails,
    });
    setCubeQuery(query);
    if (widgetFormik?.values?.route_widget) {
      query.filters.push(routingWidgetDefaultCondition());
    }
    const response = await getQueryResult(query, widgetFormik.values);
    setCubeResponse(response);
  };

  const onTableChangeHandler = params => {
    setTablePagination(params);
  };

  const fetchTableData = async (
    params = tablePagination,
    orderByConfiguration = orderBy
  ) => {
    setIsDataLoaded(false);
    const formattedQuery = getTableQuery(widgetFormik.values);
    const query = prepareQueryWithFilters({
      tablePagination: params,
      query: widgetFormik.values,
      formattedQuery,
      cubeResponse: cubeTableResponse,
      filterDetails: filterDetails,
      orderByConfiguration,
      paginator,
    });
    query.total = true;
    const response = await getQueryResult(query);
    setIsDataLoaded(true);
    setCubeTableResponse(response);
    setTotalCount(response?.total);
  };

  const fetchWidgetDetails = async () => {
    setSaveLoading(true);
    await fetchQubeJsData();
    const response = await getWidgetDataById(widgetId);
    const formikValues = mapResponseToFormik(response);
    setSelectedTable(formikValues.custom_properties.selectedReportTable);
    setSelectedChartTable(formikValues.custom_properties.selectedChartTable);
    widgetFormik.setValues(formikValues, false);
    if (
      formikValues?.chart_filter_conditions?.filters?.[0]?.fields?.[0]
        ?.tableField
    ) {
      setShowChartFilter(true);
    }
    if (
      formikValues?.table_filter_conditions?.filters?.[0]?.fields?.[0]
        ?.tableField
    ) {
      setShowTableFilter(true);
    }
    setSaveLoading(false);
  };

  const getDashBoardData = () => {
    getDashboardList('', () => {}, setDashboardListData);
  };

  useEffect(() => {
    getDashBoardData();
    if (action === 'create') {
      return;
    }
    fetchWidgetDetails();
  }, []);

  const copyFilter = () => {
    widgetFormik.setFieldValue(
      'chart_filter_conditions',
      JSON.parse(JSON.stringify(widgetFormik.values.table_filter_conditions))
    );
    setIsCopyUse(!isCopyUse);
  };
  const copyFilterChart = () => {
    widgetFormik.setFieldValue(
      'table_filter_conditions',
      JSON.parse(JSON.stringify(widgetFormik.values.chart_filter_conditions))
    );
    setIsCopyUse(!isCopyUse);
  };

  return (
    <Grid container>
      <Grid
        container
        item
        justifyContent="space-between"
        direction="row"
        alignItems="center"
      >
        <Grid item xs={12} sm={6} className="py-2">
          <PageHeader
            pageTitle="Widget Details"
            breadCrumbArray={createWidgetTemplateBreadcrumb}
          />
        </Grid>
        <ActionButtons
          action={action}
          widgetFormik={widgetFormik}
          dashboardListData={dashboardListData}
          saveLoading={saveLoading}
        />
      </Grid>

      <>
        <Paper elevation={0} className={`${classes.paper} mt-2`}>
          <BasicSection
            action={action}
            WidgetTypeOptions={WidgetTypeOptions}
            widgetFormik={widgetFormik}
            setHidePreview={setHidePreview}
            loading={saveLoading}
          />
        </Paper>
        {widgetFormik.values.widget_type && widgetFormik.values.widget_name && (
          <>
            <Paper elevation={0} className={classes.paper}>
              {saveLoading ? (
                <Skeleton className="w-full" height="45px" />
              ) : (
                <WidgetTypeFilter action={action} widgetFormik={widgetFormik} />
              )}
            </Paper>

            {isRoEnabled() &&
              !['external-link-button', 'card'].includes(
                widgetFormik.values.widget_type
              ) && (
                <Paper elevation={0} className={classes.paper}>
                  {saveLoading ? (
                    <Skeleton className="w-full" height="45px" />
                  ) : (
                    <RouteConfiguration
                      dimensionList={dimensionList?.filter(
                        item =>
                          (item.type == 'string' || item.primaryKey) &&
                          item.firstLetter == selectedChartTable
                      )}
                      measureList={dimensionList?.filter(
                        item =>
                          item.type == 'number' &&
                          item.firstLetter == selectedChartTable
                      )}
                      action={action}
                      widgetFormik={widgetFormik}
                      tableList={tableList}
                      setSelectedChartTable={setSelectedChartTable}
                      selectedChartTable={selectedChartTable}
                      setRoutingWidgetHandler={setRoutingWidgetHandler}
                    />
                  )}
                </Paper>
              )}

            {['external-link-button', 'card'].includes(
              widgetFormik.values.widget_type
            ) && (
              <Paper elevation={0} className={classes.paper}>
                <ButtonWidget
                  action={action}
                  widgetFormik={widgetFormik}
                  hideUrl={
                    widgetFormik.values.widget_type == 'external-link-button'
                  }
                  isTabularReport={
                    widgetFormik.values.widget_type == 'tabular-report'
                  }
                  setRoutingWidgetHandler={setRoutingWidgetHandler}
                />
              </Paper>
            )}
            {![
              'table-report',
              'button-report',
              'tabular-report',
              'external-link-button',
            ].includes(widgetFormik.values.widget_type) && (
              <Paper elevation={0} className={classes.paper}>
                <WidgetDetails
                  dimensionList={dimensionList?.filter(item => {
                    let flag = true;
                    if (item?.meta?.tenantPermission) {
                      flag = isWarrantyEnabled();
                    }
                    return (
                      (item?.type == 'string' || item?.primaryKey) &&
                      item?.firstLetter == selectedChartTable &&
                      !item?.meta?.hideInDimension &&
                      flag
                    );
                  })}
                  measureList={chartMeasures}
                  action={action}
                  widgetFormik={widgetFormik}
                  tableList={tableList}
                  setSelectedChartTableValue={setSelectedChartTableValue}
                  selectedChartTable={selectedChartTable}
                  route_widget={widgetFormik.values.route_widget}
                />
                <Grid item xs={12} className={classes.margin}>
                  <Grid container direction="row">
                    <Grid
                      container
                      direction="row"
                      style={{ marginTop: '15px' }}
                    >
                      <Grid item>
                        <Typography variant="h4">
                          Chart Filter <span style={{ color: 'red' }}> *</span>
                        </Typography>
                      </Grid>
                      {selectedTable == selectedChartTable && (
                        <Grid item>
                          <Button
                            color="primary"
                            variant="outlined"
                            style={{ left: '12px', bottom: '6px' }}
                            onClick={() => copyFilter()}
                          >
                            Copy from Report
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                    {action !== 'view' && (
                      <Grid item>
                        {showChartFilter ? (
                          <RemoveCircleOutlineIcon
                            color={
                              widgetFormik.values.chart_filter_conditions
                                ?.filters?.[0]?.fields?.[0]?.tableField &&
                              showChartFilter
                                ? 'disabled'
                                : 'primary'
                            }
                            fontSize="large"
                            onClick={() => {
                              if (
                                !(
                                  widgetFormik.values.chart_filter_conditions
                                    ?.filters?.[0]?.fields?.[0]?.tableField &&
                                  showChartFilter
                                )
                              ) {
                                setShowChartFilter(false);
                              }
                            }}
                          />
                        ) : (
                          <AddCircleOutlineIcon
                            color="primary"
                            fontSize="large"
                            onClick={() => {
                              setShowChartFilter(true);
                            }}
                          />
                        )}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                {showChartFilter && (
                  <FilterSection
                    key={selectedChartTable}
                    widgetFormik={widgetFormik}
                    filterConditions={
                      widgetFormik.values.chart_filter_conditions
                    }
                    action={action}
                    dimensionList={dimensionList?.filter(item => {
                      let flag = true;
                      if (item?.meta?.tenantPermission) {
                        flag = isWarrantyEnabled();
                      }
                      return (
                        item?.firstLetter == selectedChartTable &&
                        !item?.meta?.hideInFilter &&
                        flag
                      );
                    })}
                    filterFor="chart_filter_conditions"
                    isCopyUse={isCopyUse}
                  />
                )}
              </Paper>
            )}
            {(!['button-report', 'external-link-button', 'card'].includes(
              widgetFormik.values.widget_type
            ) ||
              ['tabularWidget', 'routingWidget'].includes(
                widgetFormik?.values?.custom_properties?.buttonWidgetType
              )) && (
              <Paper elevation={0} className={classes.paper}>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={12} lg={4}>
                    <Typography variant="h3">Report Configuration</Typography>
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <Autocomplete
                      fullWidth
                      options={tableList}
                      getOptionLabel={option => option && option.value}
                      renderInput={params => (
                        <TextField {...params} label="Select Report" required />
                      )}
                      disabled={
                        action === 'view' || widgetFormik.values.route_widget
                          ? true
                          : false
                      }
                      value={
                        selectedTable === CUBE_LABELS.USER_DETAILS
                          ? tableList.find(
                              item => item.key === CUBE_LABELS.USER_META
                            )
                          : tableList.find(
                              item =>
                                item.key === selectedTable.replace(/ /g, '') ||
                                item.title === selectedTable.replace(/ /g, '')
                            )
                      }
                      onChange={(event, value) => {
                        widgetFormik.setFieldValue('report_group_by', null);
                        widgetFormik.setFieldValue(
                          'report_group_by_aggr',
                          null
                        );
                        setSelectedTable(value?.value || '');
                        updateTableFilter({
                          ...widgetFormik.values,
                          custom_properties: {
                            ...widgetFormik.values.custom_properties,
                            selectedReportTable: value?.value || '',
                            reportTablePrimaryKey: value?.primaryKey || '',
                          },
                        });
                      }}
                    />
                  </Grid>
                  <TableDetails
                    dimensionList={dimensionList?.filter(item => {
                      let flag = true;
                      if (item?.meta?.tenantPermission) {
                        flag = isWarrantyEnabled();
                      }
                      return (
                        item?.firstLetter ==
                          selectedTable?.replace(/([a-z](?=[A-Z]))/g, '$1 ') &&
                        item?.name != item?.primaryKey &&
                        !item?.meta?.hideInDimension &&
                        flag
                      );
                    })}
                    action={action}
                    widgetFormik={widgetFormik}
                  />
                  <Grid item xs={12}>
                    <Typography variant="h4">Order By</Typography>
                  </Grid>
                  <OrderBySection
                    table_configuration={
                      widgetFormik.values.table_configuration
                    }
                    action={action}
                    widgetFormik={widgetFormik}
                  />
                  <Grid item xs={12}>
                    <Grid container direction="row" spacing={2}>
                      <Grid
                        container
                        direction="row"
                        style={{ marginTop: '15px' }}
                      >
                        <Grid item>
                          <Typography
                            variant="h4"
                            style={{ marginLeft: '8px' }}
                          >
                            Report Filter{' '}
                            <span style={{ color: 'red' }}> *</span>
                          </Typography>
                        </Grid>
                        {selectedTable == selectedChartTable &&
                          widgetFormik.values.widget_type != 'table-report' && (
                            <Grid item>
                              <Button
                                color="primary"
                                variant="outlined"
                                style={{ left: '12px', bottom: '6px' }}
                                onClick={() => copyFilterChart()}
                              >
                                Copy from Chart
                              </Button>
                            </Grid>
                          )}
                      </Grid>
                      {action !== 'view' && (
                        <Grid item xs={4}>
                          {showTableFilter ? (
                            <RemoveCircleOutlineIcon
                              color={
                                widgetFormik.values.table_filter_conditions
                                  ?.filters?.[0]?.fields?.[0]?.tableField &&
                                showTableFilter
                                  ? 'disabled'
                                  : 'primary'
                              }
                              fontSize="large"
                              onClick={() => {
                                if (
                                  !(
                                    widgetFormik.values.table_filter_conditions
                                      ?.filters?.[0]?.fields?.[0]?.tableField &&
                                    showTableFilter
                                  )
                                ) {
                                  setShowTableFilter(false);
                                }
                              }}
                            />
                          ) : (
                            <AddCircleOutlineIcon
                              color="primary"
                              fontSize="large"
                              onClick={() => {
                                setShowTableFilter(true);
                              }}
                            />
                          )}
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  {showTableFilter && (
                    <FilterSection
                      widgetFormik={widgetFormik}
                      filterConditions={
                        widgetFormik.values.table_filter_conditions
                      }
                      action={action}
                      dimensionList={dimensionList?.filter(item => {
                        let flag = true;
                        if (item?.meta?.tenantPermission) {
                          flag = isWarrantyEnabled();
                        }
                        return (
                          item?.firstLetter == selectedTable &&
                          !item?.meta?.hideInFilter &&
                          flag
                        );
                      })}
                      filterFor="table_filter_conditions"
                    />
                  )}
                </Grid>
                {tableMeasures.length > 0 && (
                  <GroupBySection
                    action={action}
                    widgetFormik={widgetFormik}
                    setHidePreview={setHidePreview}
                    setOrderBy={setOrderBy}
                    orderBy={initialOrderBy}
                    selectedTable={selectedTable}
                    cubeList={cubeList}
                    dimensionList={dimensionList?.filter(
                      item => item.firstLetter == selectedTable
                    )}
                    measures={tableMeasures}
                  />
                )}
              </Paper>
            )}

            <Button
              className={classes.previewButton}
              color="primary"
              variant="contained"
              onClick={fetchData}
              disabled={!widgetFormik?.dirty || !widgetFormik?.isValid}
            >
              Preview
            </Button>

            <Paper elevation={0} className={classes.paper}>
              {loading ? (
                <Grid container xs={12} item justifyContent="center">
                  <PFTableLoader />
                </Grid>
              ) : (
                !hidePreview && (
                  <Grid container direction="flex" spacing={2}>
                    {!showButtonPreview && (
                      <Typography fontSize="10px">
                        <i>
                          * Please ensure that the Widget and Report Filters are
                          selected to filter the data.
                        </i>
                        {widgetFormik.values.route_widget ? (
                          <i>
                            <br />* Completed, closed, rejected and refunded
                            project will not appear in this list
                          </i>
                        ) : null}
                      </Typography>
                    )}
                    <Grid container xs={12} item justifyContent="center">
                      {cubeResponse ||
                      [
                        'button-report',
                        'tabular-report',
                        'external-link-button',
                        'card',
                      ].includes(widgetFormik.values.widget_type) ? (
                        <Grid
                          xs={12}
                          justifyContent="center"
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            maxHeight: '350px',
                            height:
                              !['button-report', 'tabular-report'].includes(
                                widgetFormik.values.widget_type
                              ) && '300px',
                          }}
                        >
                          <WidgetRenderer
                            cubeQuery={cubeQuery}
                            widget_type={widgetFormik.values.widget_type}
                            cubeResponse={cubeResponse?.data}
                            target={
                              widgetFormik?.values?.chart_configuration
                                ?.measureTarget
                            }
                            annotation={cubeResponse?.annotation}
                            formikData={widgetFormik.values}
                            showButtonPreview={showButtonPreview}
                          />
                        </Grid>
                      ) : (
                        <Typography variant="h4">
                          Chart preview not available
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                )
              )}
            </Paper>

            {(!['external-link-button'].includes(
              widgetFormik.values.widget_type
            ) ||
              ['tabularWidget', 'routingWidget'].includes(
                widgetFormik?.values?.custom_properties?.buttonWidgetType
              )) && (
              <Paper elevation={0} className={classes.paper}>
                {loading ? (
                  <Grid container xs={12} item justifyContent="center">
                    <PFTableLoader />
                  </Grid>
                ) : (
                  !hidePreview && (
                    <Grid container direction="flex" spacing={2}>
                      <Grid item xs={12}>
                        <hr />
                        {cubeTableResponse?.data && cubeTableResponse?.data ? (
                          widgetFormik.values.report_group_by ? (
                            <>
                              <WidgetGroupedTableRenderer
                                widgetDetails={widgetFormik.values}
                                data={cubeTableResponse?.data}
                                tablePagination={tablePagination}
                                onTableChangeHandler={onTableChangeHandler}
                                annotation={
                                  cubeTableResponse?.annotation?.dimensions
                                }
                                totalCount={totalCount}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                paginator={paginator}
                                setPaginator={setPaginator}
                                filterDetails={filterDetails}
                              />
                            </>
                          ) : (
                            <WidgetTableRenderer
                              table_configuration={
                                widgetFormik.values.table_configuration
                              }
                              data={cubeTableResponse.data}
                              tablePagination={tablePagination}
                              fetchTableData={fetchTableData}
                              onTableChangeHandler={onTableChangeHandler}
                              annotation={
                                cubeTableResponse?.annotation?.dimensions
                              }
                              totalCount={totalCount}
                              orderBy={orderBy}
                              setOrderBy={setOrderBy}
                              paginator={paginator}
                              setPaginator={setPaginator}
                            />
                          )
                        ) : (
                          isDataLoaded && (
                            <Grid
                              container
                              xs={12}
                              item
                              justifyContent="center"
                            >
                              <Typography variant="h4">
                                Table preview not available
                              </Typography>
                            </Grid>
                          )
                        )}
                      </Grid>
                    </Grid>
                  )
                )}
              </Paper>
            )}
            <ConfirmDialog
              visible={visible}
              onHide={() => setVisible(false)}
              message="Routing only available with project table, do you want to change table to project?"
              header="Confirmation"
              icon="pi pi-exclamation-triangle"
              accept={routingAcceptHandler}
              reject={routingRejectHandler}
              className="w-11 md:w-6 lg:w-5"
            />
          </>
        )}
      </>
    </Grid>
  );
};

export default WidgetContainer;
