import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Column, InputText } from 'primereact';
import { TreeTable } from 'primereact/treetable';
import { Skeleton } from 'primereact/skeleton';
import { Toast } from 'primereact/toast';
import { Chips } from 'primereact/chips';
import { Button } from 'primereact/button';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

import PFDataTable from '../../shared/PFPrime/PFDataTable';
import PFButton from '../../shared/PFPrime/PFButton';
import PFDropdown from '../../shared/PFPrime/PFDropdown';
import PFInputSwitch from '../../shared/PFPrime/PFInputSwitch';
import PFInputText from '../../shared/PFPrime/PFInputText';
import PFMultiSelect from '../../shared/PFPrime/PFMultiSelect';
import SkeletonLoader from '../../shared/Loader/skeleton';
import PFDialog from '../../shared/PFPrime/PFDialog';

import {
  getUserCoveragePreferences,
  searchDistrict,
} from './services/user-form-coverage.service';
import {
  bulkDelete,
  copyCoveragePreference,
  deleteJobCoverage,
  getFilteredJobCoverageList,
  getJobCoverage,
  getListByJobCoverageTypeId,
  deleteAllJobCoverage,
} from './services/user-form-job-assign-coverage.service';
import SelectedJobCoverageValueList from './SelectedJobCoverageValueList';
import { COVERAGE_TYPE, transformCoverageData, ACTION } from './Helper';
import CoverageDelete from './components/CoverageDelete';
import CoverageClone from './components/CoverageClone';

const coverageTypeList = [
  { id: 1, label: 'Zip Code' },
  { id: 2, label: 'Store' },
  { id: 3, label: 'District' },
];
const ALL_CATEGORY_OPTION = {
  project_category_id: null,
  category: 'All',
};
const UserJobAssignCoverage = () => {
  const [paginator, setPaginator] = useState({
    first: 0,
    rows: 10,
    page: 1,
    totalDataCount: null,
  });
  const [optionsListData, setOptionsListData] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOptionsLoading, setIsOptionsLoading] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [groupDelete, setGroupDelete] = useState(0);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [copyCoverage, setCopyCoverage] = useState(false);
  const [type, setType] = useState(null);
  const [category, setCategory] = useState([]);
  const [coverageType, setCoverageType] = useState(null);
  const [categoryValue, setCategoryValue] = useState([]);
  const [coverageDialogVisible, setCoverageDialogVisible] = useState(false);
  const [selectedCoverageValues, setSelectedCoverageValues] = useState([]);
  const [isCloneOrEdit, setIsCloneOrEdit] = useState(0);
  const { user } = useParams();
  const toast = useRef(null);
  const [options, setOptions] = useState({
    typeList: [],
    Categories: [],
    stores: [],
    accessCoverageTotalCount: 0,
  });
  const [visible, setVisible] = useState(false);
  const [expandedKeys, setExpandedKeys] = useState({});
  const [toggle, setToggle] = useState(false);

  const fetchOptionsData = async () => {
    setIsOptionsLoading(true);
    try {
      const [optionsList, districtRequest] = await Promise.all([
        getUserCoveragePreferences(user, paginator),
        searchDistrict(),
      ]);
      setOptionsListData(optionsList);
      setOptions({
        typeList: optionsList.typesList,
        Categories: optionsList?.categoryList,
        stores: optionsList?.storeList,
        accessCoverageTotalCount: optionsList?.totalCount,
      });
      setDistrictList(districtRequest);
    } catch (error) {
      console.error(error);
    } finally {
      setIsOptionsLoading(false);
    }
  };

  const fetchListData = async localPaginator => {
    setIsLoading(true);
    try {
      const userJobCoveragePreference = await getJobCoverage(
        user,
        localPaginator || paginator,
        type,
        category,
        coverageType,
        categoryValue
      );
      setPaginator(prevPaginator => ({
        ...prevPaginator,
        totalDataCount: userJobCoveragePreference?.totalCount,
      }));
      if (
        userJobCoveragePreference?.userCoveragePreferenceData &&
        userJobCoveragePreference?.userCoveragePreferenceData?.length > 0
      ) {
        const tableValue = transformCoverageData(
          userJobCoveragePreference?.userCoveragePreferenceData,
          options?.Categories
        );

        setTableData(tableValue);
      } else {
        setTableData([]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchOptionsData();
  }, []);
  useEffect(() => {
    if (options?.Categories?.length) fetchListData();
  }, [options?.Categories]);

  const handleReset = () => {
    setType(undefined);
    setCategory([]);
    setCoverageType(null);
    setCategoryValue([]);
    fetchListData();
  };
  const onGroupDeleteButtonClick = async rowData => {
    setGroupDelete(1);
    setItemToDelete(rowData);
  };
  const headerTemplate = rowData => {
    return (
      <>
        {rowData?.children ? (
          <span>
            <PFButton
              icon="pi pi-pencil"
              type="button"
              outlined
              className="border-none px-0 text-500"
              pt={{
                button: { className: 'text-500 vertical-align-middle' },
                root: { className: 'vertical-align-middle' },
              }}
              onClick={() => {
                setSelectedRecord(rowData);
                setIsCloneOrEdit(ACTION.GROUP_EDIT);
              }}
            />
            <PFButton
              icon="pi pi-clone"
              type="button"
              outlined
              className="border-none px-0 text-500"
              onClick={() => {
                setSelectedRecord(rowData);
                setIsCloneOrEdit(ACTION.GROUP_CLONE);
              }}
            />
            <PFButton
              icon="pi pi-trash"
              type="button"
              outlined
              className="border-none px-0 text-500"
              onClick={() => onGroupDeleteButtonClick(rowData)}
            />
          </span>
        ) : null}
      </>
    );
  };
  const onDeleteButtonClick = rowData => {
    setItemToDelete(rowData);
  };
  const actionButtonTemplate = rowData => {
    if (rowData?.children) {
      return <>{rowData?.data?.project_type}</>;
    } else {
      return (
        <span>
          <PFButton
            icon="pi pi-pencil"
            type="button"
            outlined
            className="border-none p-0 text-500"
            pt={{ button: { className: 'text-500' } }}
            onClick={() => handleEditRow(rowData)}
          />
          <PFButton
            icon="pi pi-clone"
            type="button"
            outlined
            className="border-none p-0 text-500"
            onClick={() => handleCloneRow(rowData)}
          />
          <PFButton
            icon="pi pi-trash"
            type="button"
            outlined
            className="border-none p-0 text-500"
            onClick={() => onDeleteButtonClick(rowData)}
          />
        </span>
      );
    }
  };
  const CoverageTypeTemplate = rowData => {
    if (rowData?.children) {
      return <></>;
    } else {
      let coverageType = null;
      if (rowData?.data?.coverage_by === COVERAGE_TYPE.STORE) {
        coverageType = 'Store';
      } else if (rowData?.data?.coverage_by === COVERAGE_TYPE.ZIP_CODE) {
        coverageType = 'Zip Code';
      } else if (rowData?.data?.coverage_by === COVERAGE_TYPE.DISTRICT) {
        coverageType = 'District';
      }
      return <>{coverageType}</>;
    }
  };
  const CoverageValueTemplate = rowData => {
    if (rowData?.children) {
      return <></>;
    } else {
      let parts = null;
      let counts = null;
      let coverageValue = null;
      let total = null;
      if (rowData?.data?.coverage_by === COVERAGE_TYPE.STORE) {
        const coverageValuesArray = rowData?.data?.coverage_values?.map(value =>
          Number(value)
        );
        const FilteredCoverageValue = options?.stores?.filter(item => {
          return coverageValuesArray?.includes(item?.store_id);
        });
        coverageValue = FilteredCoverageValue;
        parts = coverageValue.map(
          part => `${part.store_number}- ${part.store_name}`
        );
        counts = parts.length;
        total = options?.stores.length;
      } else if (rowData?.data?.coverage_by === COVERAGE_TYPE.ZIP_CODE) {
        coverageValue = Array.isArray(rowData?.data?.coverage_values)
          ? rowData?.data?.coverage_values?.map(items => items).join(', ')
          : rowData?.data?.coverage_values;
        parts = coverageValue.split(',').map(part => part.trim());
        counts = parts.length;
      } else if (rowData?.data?.coverage_by === COVERAGE_TYPE.DISTRICT) {
        coverageValue = Array.isArray(rowData?.data?.coverage_values)
          ? rowData?.data?.coverage_values?.map(items => items)
          : rowData?.data?.coverage_values;
        parts = coverageValue.map(part => part.trim());
        counts = parts.length;
        total = districtList.length;
      }
      return (
        <div
          style={{
            cursor: 'pointer',
            color: 'blue',
            textDecoration: 'underline',
          }}
        >
          <Button
            label={
              rowData?.data?.coverage_by === COVERAGE_TYPE.ZIP_CODE
                ? `${counts} Selected`
                : `${counts} / ${total} Selected`
            }
            size="small"
            text
            className="md:mb-2"
            onClick={() => {
              setSelectedCoverageValues(parts);
              setCoverageDialogVisible(true);
            }}
          />
        </div>
      );
    }
  };
  const SlotsTemplate = rowData => {
    if (rowData?.children) {
      return <></>;
    } else {
      let parts = {};
      if (rowData?.data?.coverage_by === COVERAGE_TYPE.STORE) {
        const coverageValuesArray = rowData?.data?.coverage_values?.map(value =>
          Number(value)
        );
        options?.stores?.forEach(item => {
          if (coverageValuesArray?.includes(item?.store_id)) {
            parts[item.store_id] = `${item.store_number}- ${item.store_name}`;
          }
        });
      } else if (rowData?.data?.coverage_by === COVERAGE_TYPE.ZIP_CODE) {
        const coverageValue = Array.isArray(rowData?.data?.coverage_values)
          ? rowData?.data?.coverage_values?.join(', ')
          : rowData?.data?.coverage_values;
        coverageValue
          .split(',')
          .forEach(part => (parts[part.trim()] = part.trim()));
      } else if (rowData?.data?.coverage_by === COVERAGE_TYPE.DISTRICT) {
        const coverageValue = rowData?.data?.coverage_values;
        coverageValue?.forEach(part => (parts[part.trim()] = part.trim()));
      }
      return (
        <PFButton
          label="Slots"
          onClick={() => {
            setSelectedCoverageValues({ parts, raw: rowData?.data });
            setCoverageDialogVisible(true);
          }}
          size="small"
        />
      );
    }
  };
  const handleCopyCoveragePref = async () => {
    try {
      const copyResponse = await copyCoveragePreference(user, true);
      if (copyResponse) {
        setCopyCoverage(false);
        fetchListData();
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'The records has been copied successfully.',
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'An error occurred while copying coverage.',
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleEditRow = rowData => {
    const record = {
      ...rowData,
      children: [
        {
          data: {
            coverage_by: rowData.data.coverage_by,
            project_categoryId: rowData.data.project_categoryId,
            coverage_values: rowData.data.coverage_values,
            user_coverage_preference_id:
              rowData.data.user_coverage_preference_id,
          },
        },
      ],
    };
    record.rowEdit = true;
    setIsCloneOrEdit(ACTION.ROW_EDIT);
    setSelectedRecord(record);
  };
  const handleCloneRow = rowData => {
    const record = {
      ...rowData,
      data: { ...rowData.data },
      children: [
        {
          data: {
            coverage_by: rowData.data.coverage_by,
            project_categoryId: rowData.data.project_categoryId,
            coverage_values: rowData.data.coverage_values,
            user_coverage_preference_id:
              rowData.data.user_coverage_preference_id,
          },
        },
      ],
    };
    record.rowClone = true;
    setIsCloneOrEdit(ACTION.ROW_CLONE);
    setSelectedRecord(record);
  };
  const rowClassName = rowData => {
    return {
      'p-highlight': rowData?.children && rowData?.children?.length > 0,
    };
  };
  const toggleAll = checked => {
    setToggle(checked);
    const newExpandedKeys = {};
    if (checked) {
      tableData?.forEach(node => {
        newExpandedKeys[node.key] = true;
        if (node?.children) {
          node.children.forEach(child => {
            newExpandedKeys[child.key] = true;
          });
        }
      });
    }
    setExpandedKeys(newExpandedKeys);
  };
  const deleteAllCoverageHandler = async () => {
    confirmDialog({
      message: 'Are you sure you want to delete all the coverage?',
      header: 'Delete Confirmation',
      icon: 'pi pi-exclamation-triangle',
      // acceptClassName: 'p-button-danger',
      accept: async () => {
        setIsLoading(true);
        const result = await deleteAllJobCoverage(user);
        if (result) {
          toast?.current?.show({
            severity: 'success',
            summary: 'Records deleted',
            life: 2000,
          });
        }
        fetchListData();
      },
      reject: () => {},
    });
  };
  return (
    <>
      <Toast ref={toast} />
      {isOptionsLoading || isLoading ? (
        <>
          <div className="flex justify-content-between mb-3">
            <Skeleton width="10rem" height="4rem" className="m-2"></Skeleton>
            <div className="flex  justify-content-between">
              <Skeleton width="10rem" height="3rem" className="m-2"></Skeleton>
              <Skeleton width="12rem" height="3rem" className="m-2"></Skeleton>
              <Skeleton width="10rem" height="3rem" className="m-2"></Skeleton>
            </div>
          </div>
          <div className="flex justify-content-between">
            <div className="flex  justify-content-between">
              <Skeleton width="10rem" height="4rem" className="m-2"></Skeleton>
              <Skeleton width="10rem" height="3rem" className="m-2"></Skeleton>
              <Skeleton width="12rem" height="3rem" className="m-2"></Skeleton>
              <Skeleton width="10rem" height="3rem" className="m-2"></Skeleton>
            </div>
            <div className="flex  justify-content-between">
              <Skeleton width="5rem" height="3rem" className="m-2"></Skeleton>
              <Skeleton width="5rem" height="3rem" className="m-2"></Skeleton>
            </div>
          </div>
          <Skeleton height="2rem" width="15rem" className="m-2"></Skeleton>
          <SkeletonLoader columnCount={20} columnWidth="24%" />
        </>
      ) : (
        <>
          <div className="m-2">
            <div className="flex justify-content-between py-3">
              <h3 className="col-12 md:col-4 lg:col-6">Filters</h3>
              <div className="grid flex w-full justify-content-end py-3 mx-1">
                <div className="flex md:text-right pb-0">
                  <PFButton
                    label="Delete All"
                    outlined
                    size="small"
                    severity="primary"
                    onClick={() => {
                      deleteAllCoverageHandler();
                    }}
                    disabled={paginator?.totalDataCount === 0}
                    className="mx-2 md:mb-2"
                  />
                  <PFButton
                    label="Copy From Coverage"
                    outlined
                    size="small"
                    severity="primary"
                    onClick={() => {
                      if (paginator?.totalDataCount > 0) {
                        setCopyCoverage(true);
                      } else {
                        handleCopyCoveragePref();
                      }
                    }}
                    disabled={!options?.accessCoverageTotalCount}
                    className="mx-2 md:mb-2"
                  />
                  <PFButton
                    label="Add New Coverage"
                    onClick={() => {
                      setVisible(true);
                      setIsCloneOrEdit(ACTION.CREATE);
                    }}
                    size="small"
                    severity="primary"
                    className="md:mb-2"
                  />
                </div>
              </div>
            </div>
            <div className="grid mt-0">
              <div className="col-12 md:col-4 lg:col-8">
                <div className="grid">
                  <div className="col-12 md:col-4 lg:col-3 mb-3 py-0">
                    <span className="p-float-label">
                      <PFDropdown
                        value={type}
                        optionLabel="project_type"
                        name="project_type"
                        options={optionsListData?.typesList}
                        onChange={(name, e) => setType(e)}
                        placeholder="Select a Type"
                        className="w-full h-40rem"
                        filter={true}
                        style={{ height: '40px' }}
                        pt={{
                          input: {
                            className: 'p-inputtext p-inputtext-sm ',
                          },
                        }}
                      />
                      <label htmlFor="client_timezone">Type</label>
                    </span>
                  </div>
                  <div className="col-12 md:col-4 lg:col-3  py-0">
                    <span className="p-float-label">
                      <PFMultiSelect
                        value={category}
                        optionLabel="category"
                        maxSelectedLabels={1}
                        name="category"
                        filter={true}
                        options={optionsListData?.categoryList}
                        onChange={event => {
                          setCategory(event?.value);
                        }}
                        placeholder="Select a Category"
                        style={{ height: '40px' }}
                        className="w-full"
                        pt={{
                          input: {
                            className: 'p-inputtext p-inputtext-sm',
                          },
                        }}
                      />
                      <label htmlFor="client_timezone">Category</label>
                    </span>
                  </div>
                  <div className="col-12 md:col-4 lg:col-3 mb-3 py-0">
                    <span className="p-float-label">
                      <PFDropdown
                        key="coverageTypeList"
                        className="mr-3 w-full"
                        name="coverage_type"
                        value={coverageType}
                        options={coverageTypeList}
                        style={{ height: '40px' }}
                        onChange={(name, event) => {
                          setCoverageType(event);
                          setCategoryValue([]);
                        }}
                      />
                      <label htmlFor="client_timezone">Coverage Type</label>
                    </span>
                  </div>
                  {coverageType && (
                    <div className="col-12 md:col-4 lg:col-3 mb-3 py-0">
                      <span className="p-float-label">
                        {coverageType?.id === COVERAGE_TYPE.ZIP_CODE ? (
                          <InputText
                            value={categoryValue}
                            style={{ height: '40px' }}
                            onChange={event => {
                              setCategoryValue(event?.target?.value);
                            }}
                            disabled={!coverageType}
                          />
                        ) : (
                          <PFMultiSelect
                            value={categoryValue}
                            options={
                              coverageType?.id === COVERAGE_TYPE.STORE
                                ? optionsListData.storeList.map(store => ({
                                    label: `${store.store_number} - ${store.store_name}`,
                                    value: store.store_id,
                                    store_number: store.store_number,
                                  }))
                                : coverageType?.id === 3
                                  ? districtList?.map(dist => dist?.district)
                                  : []
                            }
                            maxSelectedLabels={1}
                            filter={true}
                            style={{ height: '40px' }}
                            className="mr-3 w-12"
                            placeholder="Select coverage value"
                            onChange={event => {
                              setCategoryValue(event.value);
                            }}
                            disabled={!coverageType}
                            pt={{
                              input: {
                                className: 'p-inputtext p-inputtext-sm',
                              },
                            }}
                          />
                        )}
                        <label htmlFor="coverage_value">Coverage value</label>
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-12 md:col-3 lg:col-4">
                <div className="grid">
                  <div className="col-12 md:col-4 lg:col-3 col-offset-6 py-0 ">
                    <PFButton
                      label="Reset"
                      size="small"
                      outlined
                      severity="primary"
                      onClick={handleReset}
                      className="w-full"
                    />
                  </div>
                  <div className="col-12 md:col-4 lg:col-3  py-0  ">
                    <PFButton
                      label="Apply"
                      size="small"
                      severity="primary"
                      className="w-full"
                      onClick={() => fetchListData()}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex align-items-center mb-1">
              <PFInputSwitch
                className="mx-2 md:mb-2"
                checked={toggle}
                onChange={e => toggleAll(e.value)}
              />
              <label className="mr-2 mb-2">Enable Expanded View</label>
            </div>

            <TreeTable
              value={tableData}
              columnResizeMode="expand"
              tableStyle={{ minWidth: '60rem' }}
              rowClassName={rowClassName}
              lazy
              paginator
              first={paginator.first}
              rows={paginator.rows}
              onPage={event => {
                setPaginator(event);
                fetchListData(event);
              }}
              totalRecords={paginator.totalDataCount}
              pageLinkSize={6}
              expandedKeys={expandedKeys}
              onToggle={e => setExpandedKeys(e.value)}
              scrollable
              scrollHeight="500px"
            >
              <Column
                body={rowData => headerTemplate(rowData)}
                className="w-15rem"
                expander={true}
              />
              <Column
                field="project_type"
                body={rowData => actionButtonTemplate(rowData)}
                header="Type"
              />
              <Column field="category" header="Category" />
              <Column
                field=""
                header="Coverage Type"
                body={CoverageTypeTemplate}
              />
              <Column
                field=""
                header="Coverage Value"
                body={CoverageValueTemplate}
              />
              <Column field="" header="Slots" body={SlotsTemplate} />
            </TreeTable>

            <PFDialog
              header="Confirmation"
              show={copyCoverage}
              style={{ width: '450px' }}
              hide={() => setCopyCoverage(false)}
              footer={
                <div>
                  <PFButton
                    label="No"
                    text
                    outlined
                    onClick={() => setCopyCoverage(false)}
                  />
                  <PFButton label="Yes" onClick={handleCopyCoveragePref} />
                </div>
              }
              draggable={false}
              BodyComponent={
                <div>
                  Copying From Coverage will overwrite the existing Job
                  coverages. Do you want to proceed?
                </div>
              }
            ></PFDialog>
            {coverageDialogVisible && (
              <SelectedJobCoverageValueList
                coverageDialogVisible={coverageDialogVisible}
                setCoverageDialogVisible={setCoverageDialogVisible}
                selectedCoverageValues={selectedCoverageValues}
              />
            )}
            {selectedRecord || visible ? (
              <CoverageClone
                selectedRecord={selectedRecord}
                categoryList={options?.Categories}
                typesList={options?.typeList}
                coverageTypeList={coverageTypeList}
                storeList={options?.stores}
                districtList={districtList}
                setSelectedRecord={setSelectedRecord}
                isCloneOrEdit={isCloneOrEdit}
                setVisible={setVisible}
                userId={user}
                setRefreshCoverageData={() => fetchListData()}
                refreshCoverageData={false}
                isJob={true}
              />
            ) : null}
            {itemToDelete && (
              <CoverageDelete
                closeDeleteConfirmationPopup={() => {
                  setGroupDelete(0);
                  setItemToDelete(null);
                }}
                groupDelete={groupDelete}
                setRefreshCoverageData={() => fetchListData()}
                refreshCoverageData={false}
                itemToDelete={itemToDelete}
                userId={user}
                isJob={true}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};
export default UserJobAssignCoverage;
