import React, { useState } from 'react';
import { formateNumber } from '../helpers/Quotes';
import PFInputNumber from '../../../shared/PFPrime/PFInputNumber';
import PFFormikError from '../../../shared/PFPrime/PFFormikError';

const MarginEditor = ({
  rowData,
  options,
  editingRows,
  submitAction,
  setOnChangeMargin,
  onBlurMargin,
  retailerPercentage,
}) => {
  const { values, touched, errors, setFieldValue, setValues } = submitAction;
  const [value, setValue] = useState(formateNumber(values?.margin));
  const isEditing = editingRows[rowData.rowIndex];
  return isEditing ? (
    <div className="vertical-align-top">
      <span className="p-float-label">
        <PFInputNumber
          inputId="margin"
          name="margin"
          minFractionDigits={2}
          value={value > 0 ? value : '0'}
          mode={'decimal'}
          onChange={(name, value) => {
            setValue(value);
            setOnChangeMargin(true);
          }}
          onBlur={() => {
            setFieldValue('margin', value || 0);
            onBlurMargin(value, values, setValues, retailerPercentage);
          }}
          className={touched?.margin && errors?.margin ? 'error' : ''}
          version={2}
          inputStyle={{ width: '100px' }}
          suffix="%"
        />
      </span>
      <PFFormikError touched={touched} errors={errors} field="margin" />
    </div>
  ) : (
    <div className="pt-2">
      <div className="w-full">{`${formateNumber(rowData?.margin || 0).toFixed(2)}%`}</div>
    </div>
  );
};
export default MarginEditor;
