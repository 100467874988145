import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useParams } from 'react-router';
import { Toast } from 'primereact/toast';
import { useHistory } from 'react-router-dom';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { TabView, TabPanel } from 'primereact/tabview';
import { Accordion, AccordionTab } from 'primereact/accordion';
import axios from 'axios';

import '../../style.css';
import PFSelectButton from '../../../shared/PFPrime/PFSelectButton';
import PFToggleButton from '../../../shared/PFPrime/PFToggleButton';
import PFButton from '../../../shared/PFPrime/PFButton';
import Loader from '../../../shared/Loader/Loader';
import PageHeader from '../../../shared/PageHeader/PageHeader';
import {
  customerInfoSubmit,
  customerInfoFormBreadcrumb,
  fetchCustomerInfo,
  getCustomerDetails,
} from '../helpers/CustomerInfo';
import { checkPermission } from '../../../../utils/Helpers';
import permissions from '../../../../config/permissions';
import PFDialog from '../../../shared/PFPrime/PFDialog';
import ChildClientList from '../../../ProjectManagement/Clients/ChildClientList';
import { CRM } from '../../constants';
import { URL_CONSTANTS } from '../../../../constants/urlConstants';

import CustomerOperations from './CustomerOperations';
import CompanyInformation from './CompanyInformation';
import ClientInfo from './ClientInfo';

const CustomerInfo = () => {
  const history = useHistory();

  let { mode, customerId } = useParams();
  mode = mode || window?.location?.pathname?.split('/')?.[2];

  const toast = useRef(null);
  const [customerDetails, setCustomerDetails] = useState('');
  const [loading, setLoading] = useState(false);
  const [validate, setValidate] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);

  const [mergeClientRequest, setMergeClientRequest] = React.useState({
    parent: customerId,
  });
  const [mergeClientPopUpDefaultProps, setMergeClientPopUpDefaultProps] =
    React.useState({
      show: false,
      header: <span className="px-3">Clients</span>,
      hide: e => {
        setMergeClientPopUpDefaultProps(prevState => ({
          ...prevState,
          show: e,
        }));
      },
    });

  const onMergeClientPopUpHide = useCallback(() => {
    setMergeClientRequest(null);
    setMergeClientPopUpDefaultProps(prevState => ({
      ...prevState,
      show: false,
    }));
  }, []);

  useEffect(() => {
    if (!['add', 'edit', 'view'].includes(mode)) {
      history.push('/project/clients');
    }
    if (mode !== 'add' && !customerId) {
      history.push('/project/clients');
    }
  }, [mode]);

  useEffect(() => {
    if (customerId) {
      setLoading(true);
      fetchCustomerInfo(customerId, setCustomerDetails, setLoading);
    }
  }, [customerId]);

  const { handleSubmit, handleChange, values, errors, touched, setFieldValue } =
    customerInfoSubmit(
      customerDetails,
      customerId,
      toast,
      setLoading,
      history,
      setValidate
    );

  const accept = () => {
    history.push('/project/clients');
  };

  const reject = () => false;

  const calcelConfirm = event => {
    confirmDialog({
      target: event.currentTarget,
      message: 'Do you want to cancel this?',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-primary p-button-sm',
      accept,
      reject,
      header: 'Confirmation',
      pt: { content: { className: 'py-2' } },
    });
  };

  const accordionWrapper = component => {
    return (
      <>
        <Accordion>
          <AccordionTab
            header={
              <div className="flex align-items-center p-0">
                <span className="vertical-align-middle ml-3">
                  Company Information
                </span>
              </div>
            }
          >
            {component}
          </AccordionTab>
        </Accordion>
      </>
    );
  };

  useEffect(() => {
    const details = getCustomerDetails(customerId);
    details.then(customer => {
      if (customer?.status === 200 && customer?.data) {
        let { customer_phones } = customer?.data;
        customer_phones = customer_phones.map(item => ({
          customer_phone_id: item.customer_phone_id,
          phone_type_id: item.phone_type_id,
          phone_number: item.phone_number,
          is_primary: item?.is_primary === 1 ? true : false,
        }));
        setFieldValue('customer_phones', customer_phones);
        setIsBlocked(
          customer?.data?.customerAuthinfo?.is_blocked == 1 ? true : false
        );
      }
    });
    setValidate(false);
  }, [validate]);

  const handleLockUnlockUser = async event => {
    confirmPopup({
      target: event.currentTarget,
      message: `Are you sure you want to ${!isBlocked ? 'lock' : 'unlock'} user account?`,
      icon: 'pi pi-exclamation-triangle',
      defaultFocus: 'accept',
      accept: async () => {
        try {
          const result = await axios.put(
            `${URL_CONSTANTS.CRM.baseUrl}/api/customer/block-unblock/${customerId}`,
            {
              is_blocked: isBlocked ? 0 : 1,
            }
          );
          if (!result.status) {
            toast.current.show({
              severity: 'error',
              summary: 'Error',
              detail: `Failed to ${isBlocked ? 'unlock' : 'lock'} user`,
              life: 3000,
            });
          } else {
            setIsBlocked(!isBlocked);
          }
        } catch (error) {
          console.error(error);

          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: `Failed to ${isBlocked ? 'unlock' : 'lock'} user`,
            life: 3000,
          });
        }
      },
      reject: () => {},
    });
  };

  return (
    <>
      {mergeClientRequest?.parent && (
        <PFDialog
          {...mergeClientPopUpDefaultProps}
          BodyComponent={
            <ChildClientList
              mergeClientRequest={mergeClientRequest}
              setMergeClientRequest={setMergeClientRequest}
              onMergeClientPopUpHide={onMergeClientPopUpHide}
              alert={toast}
            />
          }
          pt={{
            root: {
              className: 'w-7',
            },
          }}
        />
      )}
      <Toast ref={toast}></Toast>
      <ConfirmDialog header="Confirmation" />
      <form className="w-full" onSubmit={handleSubmit}>
        <div className="grid w-full crm flex">
          <div className="col-6 md:col-6 lg:col-6 p-0 mt-1">
            <PageHeader
              pageTitle={`${mode[0].toUpperCase() + mode.slice(1)} Client`}
              breadCrumbArray={customerInfoFormBreadcrumb(customerId, mode)}
              icon={
                mode == 'view' ? (
                  <i
                    className={`pi ${isBlocked ? 'pi-lock' : 'pi-lock-open'} ml-2 cursor-pointer`}
                    style={{ fontSize: '1rem' }}
                    onClick={handleLockUnlockUser}
                    onKeyDown={e => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        handleLockUnlockUser(e);
                      }
                    }}
                    role="button"
                    tabIndex={0}
                    aria-label={'Lock user'}
                  ></i>
                ) : null
              }
            />
          </div>

          <>
            <div className="col-6 md:col-6 lg:col-6 p-0 mt-1">
              <div className="mb-2 align-items-end justify-content-end flex">
                <PFButton
                  label={`Cancel`}
                  className="ml-2"
                  severity="primary"
                  outlined={true}
                  onClick={
                    mode !== 'view'
                      ? calcelConfirm
                      : () => history.push('/project/clients')
                  }
                />
                {mode !== CRM.MODE.ADD && (
                  <PFButton
                    label={`Merge`}
                    className="ml-2"
                    severity="primary"
                    onClick={() =>
                      setMergeClientPopUpDefaultProps(preValue => ({
                        ...preValue,
                        show: true,
                      }))
                    }
                  />
                )}

                {mode === 'view' &&
                checkPermission(permissions.clients.editClient) ? (
                  <PFButton
                    onClick={() =>
                      history.push('/crm/edit/customer/' + customerId)
                    }
                    label="Edit"
                    className="ml-2"
                  />
                ) : (
                  checkPermission(permissions.clients.addClient) && (
                    <PFButton
                      onClick={handleSubmit}
                      label="Save"
                      className="ml-2"
                    />
                  )
                )}
              </div>
            </div>
            <div className="col-12 md:col-12 lg:col-12 p-0 mt-1">
              <ClientInfo
                handleChange={handleChange}
                values={values}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                customerId={customerId || ''}
                loading={loading}
              />
              <div className="mt-3">
                {mode !== 'add' ? (
                  accordionWrapper(
                    <CompanyInformation
                      handleChange={handleChange}
                      values={values}
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      loading={loading}
                    />
                  )
                ) : (
                  <CompanyInformation
                    handleChange={handleChange}
                    values={values}
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    loading={loading}
                  />
                )}
              </div>
            </div>
          </>
        </div>
      </form>

      {!loading && mode !== 'add' && (
        <div className="grid w-full crm flex">
          {mode !== 'add' && <CustomerOperations customerId={customerId} />}
        </div>
      )}
      <ConfirmPopup />
    </>
  );
};

export default CustomerInfo;
