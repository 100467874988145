import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import ApiService from '../../../services/api.service';

const initialState = {
  techniciansLimitList: [],
  status: false,
  error: null,
};
export const fetchTechniciansLimitList = createAsyncThunk(
  'master/fetchTechniciansLimit',
  async () => {
    const response = await ApiService.get(
      '/auth/user/report/get-installers-for-global-configuration'
    );
    return response;
  }
);
const techniciansLimitSlice = createSlice({
  name: 'techniciansLimitList',
  initialState,
  reducers: {
    clearTechnicianLimitList: state => {
      state.techniciansLimitList = [];
      state.status = false;
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchTechniciansLimitList.pending, state => {
        state.status = 'loading';
      })
      .addCase(fetchTechniciansLimitList.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.techniciansLimitList = action?.payload || [];
      })
      .addCase(fetchTechniciansLimitList.rejected, (state, action) => {
        if (action.payload === 'Technicians already fetched') {
          state.status = 'succeeded';
        } else {
          state.status = 'failed';
          state.error = action.error.message;
        }
      });
  },
});
export const { clearTechnicianLimitList } = techniciansLimitSlice.actions;

export const techniciansLimitReducer = techniciansLimitSlice.reducer;
