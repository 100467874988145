import { Chip } from 'primereact/chip';
import { useSelector, useDispatch } from 'react-redux';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup'; // Required for grouping
import { Row } from 'primereact/row';
import React from 'react';
import { isValid } from 'date-fns';

import PFDataTable from '../shared/PFPrime/PFDataTable';
import {
  defaultFilterDisplayHeader,
  displayFilterHeader,
} from '../../constants';
import { dateFormatter } from '../../utils/Helpers';
import { setGlobalFilters } from '../../redux/slices/globalFilters.slice.js';

const GlobalFiltersHeader = ({
  globalFilterHeader,
  formik,
  customFilterFormik,
  setClearDefaultFilters,
}) => {
  const dispatch = useDispatch();
  const globalFilters = useSelector(state => state.globalFilters);
  const [dataTableValues, setDataTableValues] = React.useState([]);
  const [defaultDataTableValues, setDefaultDataTableValues] = React.useState(
    []
  );
  const displayFilter = displayFilterHeader;
  const defaultFilterHeader = defaultFilterDisplayHeader;

  const defaultFilterValueColumnDisplayFormat = rowData => {
    return rowData?.value?.map((item, index) => (
      <Chip
        key={index}
        label={
          Array.isArray(defaultFilterHeader[rowData?.key]?.value)
            ? `${item[defaultFilterHeader[rowData?.key]?.value[0]]}-${item[defaultFilterHeader[rowData?.key]?.value[1]]}`
            : item[defaultFilterHeader[rowData?.key]?.value]
        }
        className="m-1"
        pt={{ label: { className: 'm-0 text-sm' } }}
      ></Chip>
    ));
  };

  const defaultFilterTableColumn = [
    {
      field: 'key',
      style: { minWidth: '10px', maxWidth: '10px' },
      pt: { bodycell: { className: 'font-bold' } },
      body: rowData => {
        return defaultFilterHeader[rowData?.key]?.key;
      },
    },
    {
      field: 'value',
      style: { minWidth: '25px', maxWidth: '45px' },
      body: rowData => {
        return (
          <div className="flex flex-wrap flex-row">
            {defaultFilterValueColumnDisplayFormat(rowData)}
          </div>
        );
      },
    },
  ];
  const tableColumn = [
    {
      field: 'key',
      header: 'Custom Filters',
      style: { minWidth: '10px', maxWidth: '10px', minHeight: '10px' },
      pt: { bodycell: { className: 'font-bold' } },
      body: rowData => {
        return displayFilter
          ?.filter(item => item?.key === rowData?.key)?.[0]
          ?.value?.[0]?.toString();
      },
    },
    {
      field: 'value',
      style: { minWidth: '25px', maxWidth: '45px' },
      body: rowData => {
        const objString = displayFilter?.filter(
          item => item?.key === rowData?.key
        )?.[0];
        return (
          <div
            className="flex flex-wrap flex-row"
            style={{
              maxHeight: '100px',
              overflowY: 'auto',
            }}
          >
            {rowData?.value?.length &&
              rowData?.value?.map((val, index) => {
                return (
                  <Chip
                    key={index}
                    label={
                      (Object.keys(val)?.length &&
                        val?.[objString?.value?.[1]]) ||
                      (isValid(val)
                        ? dateFormatter(val, 'MM-DD-YYYY')
                        : typeof val === 'object'
                          ? val?.[0]
                          : val)
                    }
                    className="m-1"
                    pt={{ label: { className: 'm-0 text-sm' } }}
                  ></Chip>
                );
              })}
          </div>
        );
      },
    },
  ];

  React.useEffect(() => {
    if (
      globalFilters?.defaultFilter &&
      Object?.keys(globalFilters?.defaultFilter)?.length &&
      globalFilters?.defaultFilter?.selectedDefaultFiltersValues &&
      Object?.keys(
        globalFilters?.defaultFilter?.selectedDefaultFiltersValues?.value
      )?.length
    ) {
      const defaultFilterData = Object?.entries(
        globalFilters?.defaultFilter?.selectedDefaultFiltersValues?.value
      )?.map(([key, value]) => {
        return value?.length ? { key: key, value: value } : null;
      });
      setDefaultDataTableValues(defaultFilterData?.filter(val => val !== null));
    } else {
      setDefaultDataTableValues([]);
    }
    if (Object?.keys(globalFilters)?.length) {
      const { isDashboardApplicable, ...updatedGlobalFilters } = globalFilters;
      const data = Object?.entries(updatedGlobalFilters)?.map(
        ([key, value]) => {
          return Object.keys(value)?.length
            ? { key: key, value: value }
            : value?.length
              ? { key: key, value: value }
              : null;
        }
      );

      setDataTableValues(
        data?.filter(val => val !== null && val?.key !== 'defaultFilter')
      );
    } else {
      setDataTableValues([]);
    }
  }, [globalFilters, globalFilterHeader]);

  const hasNonEmptyArray = Object.values(globalFilters).some(
    value => Array.isArray(value) && value.length > 0
  );
  const clearFilters = () => {
    localStorage.removeItem('common_filters');
    formik?.setFieldValue('defaultFilter', {});

    formik?.handleReset();
    if (globalFilters?.isDashboardApplicable >= 1) {
      setGlobalFilters({
        isDashboardApplicable: 2,
      });
      formik?.setFieldValue('isDashboardApplicable', 2);
    }
    customFilterFormik?.handleReset();
  };
  const header = (
    <div className="flex justify-content-between w-full">
      <span>Custom Filters</span>
      <div>
        <i
          className={`pi pi-filter-slash cursor-pointer mr-2 ${!hasNonEmptyArray && 'text-500'}`}
          onClick={e => clearFilters()}
        ></i>
        <i
          className="pi pi-times cursor-pointer"
          onClick={e => globalFilterHeader.current.hide(e)}
        ></i>
      </div>
    </div>
  );
  const renderDefaultFilterHeader = () => {
    return (
      <div className="flex align-items-center justify-content-between">
        <span className="flex justify-content-center font-bold">
          Default Filter: (
          {globalFilters?.defaultFilter?.selectedDefaultFiltersValues?.key})
        </span>
        <div>
          <i
            className={`pi pi-filter-slash cursor-pointer mr-2`}
            onClick={e => {
              setClearDefaultFilters(true);
              clearFilters();
            }}
          ></i>
          <i
            className="pi pi-times cursor-pointer"
            onClick={e => globalFilterHeader.current.hide(e)}
          ></i>
        </div>
      </div>
    );
  };
  // Header with colspan
  const headerGroup = val => {
    return (
      <ColumnGroup>
        <Row>
          <Column
            header={val === 'default' ? renderDefaultFilterHeader : header}
            colSpan={2}
            pt={{ headertitle: { className: 'w-full' } }}
          />
        </Row>
      </ColumnGroup>
    );
  };

  const displayFiltersHeader = () => {
    return (
      <OverlayPanel
        ref={globalFilterHeader}
        className="w-4"
        pt={{ content: { className: 'p-1' } }}
      >
        <>
          {Object?.keys(globalFilters?.defaultFilter)?.length ? (
            <PFDataTable
              scrollable={true}
              scrollHeight="400px"
              columns={defaultFilterTableColumn}
              data={defaultDataTableValues}
              stripedRows
              dataKey="id"
              stripedRows={true}
              headerColumnGroup={headerGroup('default')}
            />
          ) : (
            <PFDataTable
              scrollable={true}
              scrollHeight="400px"
              columns={tableColumn}
              data={dataTableValues}
              stripedRows
              dataKey="id"
              stripedRows={true}
              headerColumnGroup={headerGroup('custom')}
            />
          )}
        </>
      </OverlayPanel>
    );
  };
  return displayFiltersHeader();
};

export default GlobalFiltersHeader;
