import React from 'react';
import PFInputText from '../../../shared/PFPrime/PFInputText';

const ItemDescriptionEditor = ({
  rowData,
  options,
  editingRows,
  submitAction,
}) => {
  const { values } = submitAction;
  const isEditing = editingRows[rowData.rowIndex];
  return isEditing ? (
    <div className="vertical-align-top">
      <span className="p-float-label">
        <PFInputText value={values?.itemDescription || ''} disabled={true} />
      </span>
    </div>
  ) : (
    <div className="pt-2">{`${rowData?.itemDescription || ''}`}</div>
  );
};

export default ItemDescriptionEditor;
