// **** React Imports ****
import React, {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

// **** External Utilities ****
import { Grid, CircularProgress, Box } from '@material-ui/core';
import { TabPanel, TabContext } from '@material-ui/lab';
import axios from 'axios';
import { Settings as SettingsIcon, ControlPoint } from '@material-ui/icons';
import { useSelector, useDispatch } from 'react-redux';

import useToken from '../../hooks/useToken';
import PageHeader from '../shared/PageHeader/PageHeader';
import AddViewEditDashboard from '../Admin/Dashboards/AddViewEditDashboard';
import DashboardList from '../Admin/Dashboards/DashboardList';
import { prepareDashboardFilters } from '../../utils/Helpers';
// **** Services *****
import { useAlerts } from '../shared/Alerts/alertsService';
import {
  setPageHeaderComponent,
  setTabs,
  setClientId,
} from '../../redux/slices/page-header.slice';
import DraggableTabs from '../shared/DraggableTabs/DraggableTabs';

import {
  getMyDashboardDetails,
  deleteUnlinkDashboard,
  linkUserDashboard,
  updateTabOrder,
  saveHomeDashboardApi,
} from './HomeDashboard.service';

// **** Styles/Images/Icons ****
import { useStyles } from './HomeDashboard.styles';

const HomeDashboard = () => {
  const schedulingBreadcrumb = [
    {
      link: '/dashboard',
      text: 'Home',
    },
  ];
  const classes = useStyles();
  const installerId = localStorage.getItem('installer_id');
  const pathname = window.location.pathname;
  const { accessToken } = useToken();
  const [loading, setLoading] = React.useState();
  const [dashboardData, setDashboardData] = React.useState([]);
  const [value, setValue] = React.useState('newTab');
  const childRef = useRef();
  const [region, setRegion] = React.useState([]);
  const [storeNumber, setStoreNumber] = React.useState([]);
  const [district, setDistrict] = React.useState([]);
  const [category, setCategory] = React.useState([]);
  const [source, setSource] = React.useState([]);
  const [projectType, setProjectType] = React.useState([]);
  const [installers, setInstallers] = React.useState([]);
  const [status, setStatus] = React.useState([]);
  const [workroom, setWorkroom] = React.useState([]);
  const [installer, setInstaller] = React.useState([]);
  const [scheduleDate, setScheduleDate] = React.useState({
    start: null,
    end: null,
  });
  const [completionDate, setCompletionDate] = React.useState({
    start: null,
    end: null,
  });
  const [checkDate, setCheckDate] = React.useState({ start: null, end: null });
  const [dateSold, setDateSold] = React.useState({ start: null, end: null });
  const [addViewEditDashboardKey, setAddViewEditDashboardKey] =
    React.useState(true);
  const [saveDisabled, setSaveDisabled] = useState(true);
  //Filter variables
  const [applyFilters, setApplyFilters] = React.useState(false);

  const [widgetFilterQuery, setWidgetFilterQuery] = React.useState([]);

  const [attachRemoveDashboardDialogOpen, setAttachRemoveDashboardDialogOpen] =
    React.useState(false);
  const [clearFilters, setClearFilters] = React.useState(false);
  const [homeFilters, setHomeFilters] = React.useState([]);
  const [homeFilterDateDimension, setHomeFilterDateDimension] = React.useState(
    []
  );
  const [disabledDashboard, setDisabledDashboard] = React.useState(false);
  const dispatch = useDispatch();
  const { activeDashboard, tabs, clientId } = useSelector(
    state => state.pageHeader
  );

  const [selectedItems, setSelectedItems] = useState([]);
  // To show the message
  const { setAlert } = useAlerts();

  const toast = useRef(null);
  const [allTabs, setAllTabs] = useState([]);
  const timeoutRef = useRef(null);
  const configureDialogSettings = {
    title: (
      <Grid container alignItems="center">
        <Grid item xs={6}>
          Add Dashboard
        </Grid>{' '}
        {loading && (
          <Grid item>
            <CircularProgress />
          </Grid>
        )}
      </Grid>
    ),
    button1Text: '',
    button2Text: 'Add',
    showButton1: true,
    showButton2: true,
    isScrollHidden: true,
  };
  const sidebarLayout = JSON.parse(localStorage.getItem('sidebar-layout'));
  const [counter, setCounter] = React.useState(30);
  const globalFilters = useSelector(state => state.globalFilters);
  const pageHeader = useSelector(state => state.pageHeader);

  const memoFilterState = useMemo(
    () => ({
      defaultFilter: globalFilters?.defaultFilter?.length,
      stores: globalFilters?.stores?.length,
      projectTypes: globalFilters?.projectTypes?.length,
      projectCategories: globalFilters?.projectCategories?.length,
      projectWorkrooms: globalFilters?.projectWorkrooms?.length,
      projectStatus: globalFilters?.projectStatus?.length,
      districtId: globalFilters?.districtId?.length,
      techniciansVal: globalFilters?.techniciansVal?.length,
      dateScheduledStart: globalFilters?.dateScheduledFilterStart,
      dateScheduledEnd: globalFilters?.dateScheduledFilterEnd,
      dateCompletionStart: globalFilters?.dateCompletionFilterStart,
      dateCompletionEnd: globalFilters?.dateCompletionFilterEnd,
      checkDateFrom: globalFilters?.checkDateFromFilter,
      checkDateTo: globalFilters?.checkDateToFilter,
      dateSoldFrom: globalFilters?.dateSoldFromFilter,
      dateSoldTo: globalFilters?.dateSoldToFilter,
      isDashboardApplicable: globalFilters?.isDashboardApplicable,
    }),
    [
      globalFilters?.defaultFilter,
      globalFilters?.stores,
      globalFilters?.projectTypes,
      globalFilters?.projectCategories,
      globalFilters?.projectWorkrooms,
      globalFilters?.projectStatus,
      globalFilters?.districtId,
      globalFilters?.techniciansVal,
      globalFilters?.dateScheduledFilterStart,
      globalFilters?.dateScheduledFilterEnd,
      globalFilters?.dateCompletionFilterStart,
      globalFilters?.dateCompletionFilterEnd,
      globalFilters?.checkDateFromFilter,
      globalFilters?.checkDateToFilter,
      globalFilters?.dateSoldFromFilter,
      globalFilters?.dateSoldToFilter,
      globalFilters?.isDashboardApplicable,
    ]
  );

  React.useEffect(() => {
    if (disabledDashboard) {
      const intervalId = setInterval(() => {
        setCounter(prevTime => (prevTime > 0 ? prevTime - 1 : 0));
      }, 1000);
    }
  }, [disabledDashboard]);

  React.useEffect(() => {
    if (counter === 0) {
      clearInterval();
      setDisabledDashboard(false);
      setCounter(30);
    }
  }, [counter]);

  React.useEffect(() => {
    if (
      axios.defaults.headers.common['client_id'] &&
      globalFilters?.isDashboardApplicable
    ) {
      const appliedFilters = prepareDashboardFilters(globalFilters);
      setHomeFilters(appliedFilters?.[0]);
      setHomeFilterDateDimension(appliedFilters?.[1]);
      setApplyFilters(true);
      refreshDashboardList();
    }
  }, [
    memoFilterState,
    axios.defaults.headers.common['client_id'],
    pageHeader?.activeDashboard?.id,
  ]);

  React.useEffect(() => {
    document.body.classList.add(classes.paddingBody);
    if (
      axios.defaults.headers.common['client_id'] &&
      pathname !== '/dashboard-widget'
    ) {
      getMyDashboardDetails(setLoading, setDashboardData, handleTabChange);
    }
  }, [axios.defaults.headers.common['client_id']]);

  React.useEffect(() => {
    if (pathname == '/dashboard-widget') {
      setDashboardData([{ ...activeDashboard }]);
    }
    if (clientId === '')
      dispatch(setClientId(String(localStorage.getItem('client_id'))));
  }, []);

  React.useEffect(() => {
    if (axios.defaults.headers.common['client_id'] && !activeDashboard?.name) {
      const currentDashboard = dashboardData?.find(
        dashboard => dashboard?.dashboard_id?.toString() === value
      );
      dispatch(
        setPageHeaderComponent({
          activeDashboard: {
            id: currentDashboard?.dashboard_id,
            name: currentDashboard?.name,
          },
        })
      );
    }
  }, [value]);

  const handleTabChange = newValue => {
    setDisabledDashboard(false);
    const currentDashboard = dashboardData?.find(
      dashboard => dashboard?.dashboard_id?.toString() === newValue
    );
    dispatch(
      setPageHeaderComponent({
        activeDashboard: {
          id: currentDashboard?.dashboard_id,
          name: currentDashboard?.name,
        },
      })
    );
    setValue(newValue);
    childRef?.current?.handleClearAll();
  };

  const detachDashboard = dashboardId => {
    deleteUnlinkDashboard(
      dashboardId,
      setAttachRemoveDashboardDialogOpen,
      setLoading,
      setDashboardData,
      setValue,
      setAlert
    );
  };

  const handleAddIcon = () => {
    setAttachRemoveDashboardDialogOpen(true);
  };

  const sortTabs = tabData => {
    const tempTabData = [...tabData];
    tempTabData.sort((a, b) => {
      return a.sort_order > b.sort_order ? 1 : -1;
    });
    return tempTabData;
  };

  const getDefaultSelectedItems = () => {
    const tempSelecteditem = dashboardData.map(el => {
      return {
        id: el.dashboard_id,
        name: el.name,
        description: el.description,
        created_at: el.created_at,
        sort_order: el.DashboardMappings[0].sort_order,
      };
    });
    const sortedSelectedItem = sortTabs(tempSelecteditem);
    setSelectedItems(sortedSelectedItem);
    if (tabs === null || tabs?.[String(clientId)]?.length === 0) {
      const tabVal = sortedSelectedItem.map((el, idx) => {
        return { name: el.name, value: el.id, id: idx };
      });
      dispatch(setTabs({ [String(clientId)]: tabVal }));
    }
  };

  React.useEffect(() => {
    if (tabs && Object.keys(tabs).includes(clientId) == false)
      dispatch(setTabs({ ...tabs, [String(clientId)]: [] }));
  }, [clientId]);

  React.useEffect(() => {
    if (dashboardData.length > 0) {
      getDefaultSelectedItems();
    } else if (selectedItems.length > 0) {
      setSelectedItems([]);
      dispatch(setTabs({ ...tabs, [String(clientId)]: [] }));
    }
  }, [dashboardData]);

  React.useEffect(() => {
    if (tabs) {
      setAllTabs(tabs?.[String(clientId)]);
    }
  }, [tabs]);

  React.useEffect(() => {
    const selectedDashboard = new Set(selectedItems.map(el => el.id));
    const existingDashboard = new Set(dashboardData.map(el => el.dashboard_id));
    const toRemove = new Set(
      [...existingDashboard].filter(x => !selectedDashboard.has(x))
    );
    const toLink = new Set(
      [...selectedDashboard].filter(x => !existingDashboard.has(x))
    );

    if (toRemove.size > 0 || toLink.size > 0) {
      setSaveDisabled(false);
    } else {
      setSaveDisabled(true);
    }
  }, [selectedItems, dashboardData]);

  const handleApiCall = () => {
    const sortedSelectedItem = sortTabs(selectedItems);
    // Make an API call with the selectedItems
    if (dashboardData.length > 0) {
      const selectedDashboard = new Set(sortedSelectedItem.map(el => el.id));
      if (!selectedDashboard?.size) {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Select atleast one Dashboard',
          life: 3000,
        });
      } else {
        saveHomeDashboard({ dashboardIds: [...selectedDashboard]?.toString() });
        const tabVal = sortedSelectedItem.map((el, idx) => {
          return { name: el.name, value: el.id, id: idx };
        });
        dispatch(setTabs({ ...tabs, [String(clientId)]: tabVal }));
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Updated dashboard list',
          life: 3000,
        });
      }
    } else {
      sortedSelectedItem.forEach(element => {
        linkDashboard(element.id);
      });
      const tabVal = sortedSelectedItem.map((el, idx) => {
        return { name: el.name, value: el.id, id: idx };
      });
      dispatch(setTabs({ ...tabs, [String(clientId)]: tabVal }));
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Updated dashboard list',
        life: 3000,
      });
    }
  };

  const handleTabState = val => {
    dispatch(setTabs({ ...tabs, [String(clientId)]: val }));

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      const updateTabData = val.map((el, idx) => {
        return { dashboard_id: parseInt(el.value), sort_order: idx };
      });
      updateTabOrder(setLoading, updateTabData, setAlert);
    }, 1000);
    return () => clearTimeout(timeoutRef.current);
  };

  const linkDashboard = dashboardId => {
    setLoading(true);
    const allIds =
      allTabs?.length === 0
        ? selectedItems.map((val, index) => val.id)
        : allTabs.map((val, index) => val.value);
    const sortOrder = allIds.indexOf(dashboardId);
    linkUserDashboard(
      dashboardId,
      setAttachRemoveDashboardDialogOpen,
      setLoading,
      setDashboardData,
      setValue,
      setAlert,
      sortOrder
    );
  };

  const saveHomeDashboard = async dashboardObj => {
    setLoading(true);
    const response = await saveHomeDashboardApi(dashboardObj);
    if (response) {
      getMyDashboardDetails(setLoading, setDashboardData, setValue);
      setAttachRemoveDashboardDialogOpen(false);
      setAlert('success', 'Dashboard saved successfully');
    }
  };

  const handleCloseDialogue = () => {
    setAttachRemoveDashboardDialogOpen(false);
    getDefaultSelectedItems();
  };

  const refreshDashboardList = () => {
    setAddViewEditDashboardKey(!addViewEditDashboardKey);
  };

  return !accessToken ? (
    <>Please Log-in to access the application</>
  ) : (
    <Grid container direction="column" wrap="nowrap">
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={12} md={6}>
          <PageHeader
            pageTitle="Dashboard"
            breadCrumbArray={schedulingBreadcrumb}
          />
        </Grid>
        {/* <Grid container item xs={12} md={6}>
          <DashboardFilters
            applyFilters={applyFilters}
            setApplyFilters={setApplyFilters}
            widgetFilterQuery={widgetFilterQuery}
            setWidgetFilterQuery={setWidgetFilterQuery}
            clearFilters={clearFilters}
            setClearFilters={setClearFilters}
            homeFilters={homeFilters}
            setHomeFilters={setHomeFilters}
            region={region}
            setRegion={setRegion}
            storeNumber={storeNumber}
            setStoreNumber={setStoreNumber}
            district={district}
            setDistrict={setDistrict}
            category={category}
            setCategory={setCategory}
            source={source}
            setSource={setSource}
            projectType={projectType}
            setProjectType={setProjectType}
            installers={installers}
            setInstallers={setInstallers}
            status={status}
            setStatus={setStatus}
            workroom={workroom}
            setWorkroom={setWorkroom}
            setScheduleDate={setScheduleDate}
            scheduleDate={scheduleDate}
            setCompletionDate={setCompletionDate}
            completionDate={completionDate}
            checkDate={checkDate}
            setCheckDate={setCheckDate}
            homeFilterDateDimension={homeFilterDateDimension}
            setHomeFilterDateDimension={setHomeFilterDateDimension}
            ref={childRef}
            refreshDashboardList={refreshDashboardList}
            disabledDashboard={disabledDashboard}
            setDisabledDashboard={setDisabledDashboard}
            dateSold={dateSold}
            setDateSold={setDateSold}
          />
        </Grid> */}
        <div className="flex">
          <Button
            type="button"
            icon="pi pi-refresh"
            label={disabledDashboard ? counter : 'Refresh'}
            outlined
            size="small"
            onClick={refreshDashboardList}
            className="mr-2"
            disabled={disabledDashboard || false}
          />
        </div>
      </Grid>

      {dashboardData.length ? (
        <TabContext
          value={activeDashboard?.id ? activeDashboard?.id?.toString() : value}
        >
          {dashboardData.length
            ? dashboardData.map((tab, index) => {
                if (tab?.DashboardMappings?.[0]?.is_display)
                  return (
                    <TabPanel
                      key={index}
                      value={tab.dashboard_id?.toString()}
                      classes={{ root: classes.tabPanelRoot }}
                      className="p-3"
                    >
                      {value ? (
                        <AddViewEditDashboard
                          key={addViewEditDashboardKey}
                          type="home"
                          dashboardId={tab.dashboard_id}
                          setHomeDashboardLoading={setLoading}
                          homeDashboardLoading={loading}
                          setWidgetFilterQuery={setWidgetFilterQuery}
                          applyFilters={applyFilters}
                          widgetFilterQuery={widgetFilterQuery}
                          setApplyFilters={setApplyFilters}
                          clearFilters={clearFilters}
                          setClearFilters={setClearFilters}
                          homeFilters={homeFilters}
                          setHomeFilters={setHomeFilters}
                          region={region}
                          storeNumber={storeNumber}
                          district={district}
                          category={category}
                          source={source}
                          projectType={projectType}
                          installers={installers}
                          homeFilterDateDimension={homeFilterDateDimension}
                          setHomeFilterDateDimension={
                            setHomeFilterDateDimension
                          }
                          status={status}
                          workroom={workroom}
                          scheduleDate={scheduleDate}
                          completionDate={completionDate}
                          checkDate={checkDate}
                          setDisabledDashboard={setDisabledDashboard}
                          refreshDashboardList={refreshDashboardList}
                          dateSold={dateSold}
                        />
                      ) : (
                        <Grid container justifyContent="center">
                          <CircularProgress />
                        </Grid>
                      )}
                    </TabPanel>
                  );
              })
            : null}

          <footer
            className={classes.footerTab}
            style={{
              width:
                sidebarLayout === 0 ? 'calc(98% - 32px)' : 'calc(98% - 290px)',
            }}
          >
            {value ? (
              <Box>
                <DraggableTabs
                  tabsValue={activeDashboard?.id ? activeDashboard?.id : value}
                  tabsOption={{
                    variant: 'scrollable',
                    scrollButtons: 'auto',
                    ariaLabel: 'scrollable auto tabs',
                  }}
                  allTabs={allTabs}
                  onTabClick={handleTabChange}
                  valueProperty="dashboard_id"
                  nameProperty="name"
                  tabClasses={classes}
                  handleAddIcon={handleAddIcon}
                  showAdd={true}
                  handleTabState={handleTabState}
                />
              </Box>
            ) : null}
          </footer>
        </TabContext>
      ) : null}
      <Toast ref={toast} />
      {/* Configure Dialog*/}
      <Dialog
        header="Dashboard List"
        visible={attachRemoveDashboardDialogOpen}
        style={{ minHeight: '80vh', maxWidth: '80vw' }}
        onHide={() => handleCloseDialogue()}
        draggable={false}
        footer={
          <div className="p-2">
            <Button
              label="Save"
              onClick={handleApiCall}
              className="p-button-primary"
              disabled={saveDisabled}
            />
          </div>
        }
      >
        <DashboardList
          source="home"
          dashboardData={dashboardData}
          setHomeLoading={setLoading}
          linkDashboard={linkDashboard}
          detachDashboard={detachDashboard}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
        />
      </Dialog>
    </Grid>
  );
};

export default HomeDashboard;
