import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Column } from 'primereact/column';
import { InputNumber } from 'primereact/inputnumber';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { TreeTable } from 'primereact/treetable';

import PFDataTable from '../../shared/PFPrime/PFDataTable';
import PFButton from '../../shared/PFPrime/PFButton';
import PFDropdown from '../../shared/PFPrime/PFDropdown';
import PFInputSwitch from '../../shared/PFPrime/PFInputSwitch';
import PFMultiSelect from '../../shared/PFPrime/PFMultiSelect';
import PFInputText from '../../shared/PFPrime/PFInputText';
import PFInputNumber from '../../shared/PFPrime/PFInputNumber';

import { getUserCoveragePreferences } from './services/user-form-coverage.service';
import {
  getRotationPercentList,
  getInstallersList,
  deleteGroupRotationPercentage,
  deleteJobCoverage,
  deleteRotationPercentage,
  udpateRotationPercentage,
} from './services/user-form-job-assign-coverage.service';
import PFTableLoader from '../../shared/Loader/PFTableLoader';
import { Skeleton } from 'primereact/skeleton';

const ALL_CATEGORY_OPTION = {
  project_category_id: null,
  category: 'All',
};
const UserJobAssignRotation = () => {
  const [type, setType] = useState(null);
  const [category, setCategory] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [users, setUsers] = useState([]);
  const { user } = useParams();
  const [paginator, setPaginator] = useState({ first: 0, rows: 10, page: 1 });
  const [treeTableData, setTreeTableData] = useState([]);
  const [editingRows, setEditingRows] = useState(false);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [count, setCount] = useState(0);
  const [reloadList, setReloadList] = useState(false);
  const [expandedKeys, setExpandedKeys] = useState({});
  const [nodes, setNodes] = useState([]);
  const [editingKey, setEditingKey] = useState(null);
  const [editValue, setEditValue] = useState(null);
  const [visible, setVisible] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [previousValue, setPreviousValue] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const toast = useRef(null);
  const fetchOptionsData = async () => {
    try {
      const [OptionsListData, installerList] = await Promise.all([
        getUserCoveragePreferences(user, paginator),
        getInstallersList(user),
      ]);
      const { categoryList, typesList } = OptionsListData;
      setTypeList(typesList);
      setCategoriesList(categoryList);
      const coverageUsers = installerList?.data?.userFilterRes?.map(
        (record, index) => {
          return {
            usersList: record?.first_name + ' ' + record?.last_name,
            user_id: record?.job_coverage_user_id,
          };
        }
      );
      setUsers(coverageUsers);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchListData = async () => {
    try {
      setIsLoading(true);
      const response = await getRotationPercentList(
        user,
        first,
        rows,
        type,
        category,
        userList
      );
      if (response.count === 0) {
        setNodes([]);
        setTreeTableData([]);
      } else {
        setTreeTableData(response?.queryResponse);
        setCount(response?.count);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setReloadList(false);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchOptionsData();
  }, []);
  useEffect(() => {
    fetchListData();
  }, [first, reloadList, count]);
  const groupCategoriesByProjectCategoryIds = categories => {
    const grouped = {};
    categories.forEach(category => {
      const key = category.project_category_ids;
      if (!grouped[key]) {
        grouped[key] = [];
      }
      grouped[key].push(category);
    });
    return grouped;
  };
  useEffect(() => {
    if (treeTableData && treeTableData?.length > 0) {
      const treeData = treeTableData?.map((project, projectIndex) => {
        const groupedCategories = groupCategoriesByProjectCategoryIds(
          project.categories
        );
        const childrn = Object.keys(groupedCategories)?.map((key, i) => {
          const categoryUsers = groupedCategories[key];
          return {
            key: `project_${projectIndex}_category_${key}_${i}`,
            data: {
              project_category_ids: key,
              category: categoryUsers[0]?.category,
              user: `${categoryUsers?.length} Users`,
              rotation_percentage: `Balance: ${(
                Math.floor(
                  (100 -
                    categoryUsers?.reduce(
                      (sum, cat) =>
                        sum + parseFloat(cat?.rotation_percentage || 0),
                      0
                    )) *
                    100
                ) / 100
              ).toFixed(2)}`,
            },
            categoryCount: categoryUsers?.length,
            preference_id: categoryUsers?.map(category => {
              return category?.user_job_coverage_id;
            }),
            children: groupedCategories[key]?.map(category => ({
              key: category.user_job_coverage_id,
              data: {
                client_id: category.client_id,
                user_id: category.user_id,
                project_type_id: category.project_type_id,
                user_job_coverage_id: category.user_job_coverage_id,
                project_category_ids: category.project_category_ids,
                category: category.category,
                first_name: category.first_name,
                last_name: category.last_name,
                rotation_percentage: parseFloat(
                  category?.rotation_percentage || 0
                ),
              },
            })),
          };
        });
        return {
          key: project.user_job_coverage_id,
          data: {
            client_id: project.client_id,
            user_id: project.user_id,
            project_type_id: project.project_type_id,
            user_job_coverage_id: project.user_job_coverage_id,
            project_category_ids: project.project_category_ids,
            project_type: project.project_type,
            rotation_percentage: ``,
          },
          children: childrn,
        };
      });
      setNodes(treeData);
    }
  }, [treeTableData]);

  const handleReset = () => {
    setCategory([]);
    setType([]);
    setUserList([]);
    setReloadList(true);
  };
  const userTemplate = node => {
    return (
      <span>
        {node?.children
          ? node?.data?.user
          : `${node?.data?.first_name || ''} ${node?.data?.last_name || ''}`}
      </span>
    );
  };
  const actionTemplate = node => {
    return (
      <span>
        {node?.categoryCount && (
          <PFButton
            icon="pi pi-refresh"
            outlined
            className="border-none px-0 text-500"
            onClick={() => confirmDelete(node)}
          />
        )}
      </span>
    );
  };
  const inputTextEditor = props => {
    return props.node.key === editingKey ? (
      <InputNumber
        inputId="percent"
        type="text"
        value={props.node.data[props.field]}
        mode="decimal"
        minFractionDigits={1}
        onValueChange={e => onEditorValueChange(props, e.value)}
        suffix="%"
        onKeyDown={e => e.stopPropagation()}
        pt={{
          input: {
            className: 'w-full',
          },
        }}
        inputClassName="w-12"
        min={0}
      />
    ) : (
      <span>{`${props.node.data[props.field]}`}</span>
    );
  };
  const rotationTemplate = node => {
    return node?.children ? (
      node?.data?.rotation_percentage && `${node?.data?.rotation_percentage}%`
    ) : (
      <>
        {node?.key === editingKey ? (
          <InputNumber
            value={parseFloat(node?.data?.rotation_percentage)}
            onValueChange={e =>
              onEditorValueChange(
                { node, field: 'rotation_percentage' },
                e?.value
              )
            }
            mode="decimal"
            minFractionDigits={1}
            suffix="%"
            pt={{
              input: {
                className: 'w-full',
              },
            }}
            inputClassName="w-12"
            min={0}
            onKeyDown={e => e?.stopPropagation()}
          />
        ) : (
          `${node?.data?.rotation_percentage}%`
        )}
      </>
    );
  };
  const onEditorValueChange = (props, value) => {
    setEditValue(value);
  };
  const findNodeByKey = (nodes, key) => {
    for (let node of nodes) {
      if (node?.key === key) {
        return node;
      }
      if (node?.children) {
        const childNode = findNodeByKey(node?.children, key);
        if (childNode) {
          return childNode;
        }
      }
    }
    return null;
  };
  const findParentNode = (nodes, key) => {
    for (let node of nodes) {
      if (node?.children) {
        for (let child of node.children) {
          if (child?.key === key) {
            return node;
          }
        }
        const parent = findParentNode(node?.children, key);
        if (parent) {
          return parent;
        }
      }
    }
    return null;
  };
  const onRowEditInit = node => {
    setEditingKey(node?.key);
    setEditValue(node?.data?.rotation_percentage);
    setPreviousValue(node?.data?.rotation_percentage);
  };

  const onRowEditSave = async node => {
    const updatedNodes = [...nodes];
    const targetNode = findNodeByKey(updatedNodes, node?.key);
    const parentNode = findParentNode(updatedNodes, node?.key);
    if (targetNode) {
      const siblingNodes = parentNode.children?.filter(n => n.key !== node.key);
      const totalRotationPercentage = siblingNodes?.reduce(
        (sum, n) => sum + parseFloat(n?.data?.rotation_percentage || 0),
        parseFloat(editValue)
      );
      if (totalRotationPercentage > 100) {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Total rotation percentage exceeds 100%',
        });
        setEditValue(previousValue);
        return;
      }
      targetNode.data.rotation_percentage = parseFloat(editValue);
      const payload = {
        typeId: targetNode?.data?.project_type_id,
        categoryId: parentNode?.data?.project_category_ids,
        rotation_percentage: `${targetNode?.data?.rotation_percentage}`,
        ids: [targetNode?.data?.user_id],
      };
      const response = await udpateRotationPercentage(
        targetNode?.data?.user_id,
        targetNode?.data?.user_job_coverage_id,
        payload
      );
      setNodes(updatedNodes);
      if (response && response?.status) {
        setReloadList(true);
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Rotation percentage updated',
        });
      }
      setEditingKey(null);
      setEditValue(null);
    }
    const totalPercentage = node?.parent?.children?.reduce((sum, child) => {
      if (child?.key === node?.key) {
        return sum + parseFloat(node?.data?.rotation_percentage || 0);
      }
      return sum + parseFloat(child?.data?.rotation_percentage || 0);
    }, 0);
    if (totalPercentage > 100) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Total rotation percentage cannot exceed 100%',
      });
    } else {
      setEditingKey(null);
    }
  };
  const onRowEditCancel = () => {
    setEditingKey(null);
    setEditValue(null);
  };
  const onDelete = async node => {
    const updatedNodes = [...nodes];
    const targetNode = findNodeByKey(updatedNodes, node.key);
    let payload;
    if (targetNode?.preference_id) {
      payload = targetNode?.preference_id;
    } else {
      payload = targetNode?.key;
    }
    if (targetNode) {
      if (targetNode?.children) {
        const response = await deleteGroupRotationPercentage(user, payload);
        if (response && response?.status) {
          setReloadList(true);
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Rotation percentage set to 0%',
          });
        }
      } else {
        const response = await deleteRotationPercentage(
          targetNode?.data?.user_id,
          targetNode?.data?.user_job_coverage_id
        );
        if (response && response?.status) {
          setReloadList(true);
          toast.current.show({
            severity: 'success',
            summary: 'Success',
            detail: 'Rotation percentage set to 0%',
          });
        }
      }
    }
  };
  const confirmDelete = node => {
    setVisible(true);
    confirmDialog({
      message: 'Are you sure you want to reset this record rotation ?',
      header: 'Reset Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: () => onDelete(node),
      reject: () => {
        setVisible(false);
      },
    });
  };
  const onPage = e => {
    setFirst(e.first);
  };
  const rowClassName = node => {
    return { 'p-highlight': node?.children && node?.children?.length > 0 };
  };
  const projectTypeTemplate = node => {
    return (
      <span>
        <span>{node?.data?.project_type}</span>
        <div className="text-right">
          {!node?.children && (
            <>
              {node.key === editingKey ? (
                <>
                  <PFButton
                    icon="pi pi-check"
                    outlined
                    className="border-none px-0 text-500"
                    onClick={() => onRowEditSave(node)}
                  />
                  <PFButton
                    icon="pi pi-times"
                    outlined
                    className="border-none px-0 text-500"
                    onClick={() => onRowEditCancel(node)}
                  />
                </>
              ) : (
                <PFButton
                  icon="pi pi-pencil"
                  outlined
                  className="border-none px-0 text-500"
                  onClick={() => onRowEditInit(node)}
                />
              )}
              <PFButton
                icon="pi pi-refresh"
                outlined
                className="border-none px-0 text-500"
                onClick={() => confirmDelete(node)}
              />
            </>
          )}
        </div>
      </span>
    );
  };
  const toggleAll = checked => {
    setToggle(checked);
    const newExpandedKeys = {};
    if (checked) {
      nodes?.forEach(node => {
        newExpandedKeys[node.key] = true;
        if (node?.children) {
          node.children.forEach(child => {
            newExpandedKeys[child.key] = true;
          });
        }
      });
    }
    setExpandedKeys(newExpandedKeys);
  };
  return (
    <div className="m-2">
      <div className="flex justify-content-between py-3">
        <h3 className="col-12 md:col-4 lg:col-6">Filters </h3>
      </div>
      <div className="grid mt-0">
        <div className="col-12 md:col-4 lg:col-8">
          <div className="grid">
            <div className="col-12 md:col-4 lg:col-4 mb-3 py-0">
              {isLoading ? (
                <Skeleton className="w-full" height="40px" />
              ) : (
                <span className="p-float-label">
                  <PFDropdown
                    value={type}
                    optionLabel="project_type"
                    options={typeList}
                    onChange={(e, value) => {
                      setType(value);
                    }}
                    placeholder="Select a Type"
                    className="w-full h-40rem"
                    filter={true}
                    style={{ height: '40px' }}
                    pt={{
                      input: {
                        className: 'p-inputtext p-inputtext-sm ',
                      },
                    }}
                  />
                  <label htmlFor="client_timezone">Select Type</label>
                </span>
              )}
            </div>
            <div className="col-12 md:col-4 lg:col-4 mb-3 py-0">
              {isLoading ? (
                <Skeleton className="w-full" height="40px" />
              ) : (
                <span className="p-float-label">
                  <PFMultiSelect
                    value={category}
                    optionLabel="category"
                    options={categoriesList}
                    onChange={event => {
                      setCategory(event?.value);
                    }}
                    placeholder="Select a Category"
                    style={{ height: '40px' }}
                    className="w-full"
                    pt={{
                      input: {
                        className: 'p-inputtext p-inputtext-sm',
                      },
                    }}
                    maxSelectedLabels={1}
                    filter
                  />
                  <label htmlFor="client_timezone">Select category</label>
                </span>
              )}
            </div>
            <div className="col-12 md:col-4 lg:col-4 mb-3 py-0">
              {isLoading ? (
                <Skeleton className="w-full" height="40px" />
              ) : (
                <span className="p-float-label">
                  <PFDropdown
                    className="mr-3 w-full"
                    options={users}
                    optionLabel="usersList"
                    optionValue="user_id"
                    filter={true}
                    name="users"
                    style={{ height: '40px' }}
                    value={userList}
                    onChange={(name, value) => {
                      setUserList(value);
                    }}
                  />
                  <label htmlFor="client_timezone">User</label>
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="col-12 md:col-3 lg:col-4">
          <div className="grid">
            <div className="col-12 md:col-4 lg:col-3 col-offset-6 py-0 ">
              {isLoading ? (
                <Skeleton height="40px" width="w-full"></Skeleton>
              ) : (
                <PFButton
                  label="Reset"
                  size="small"
                  outlined
                  severity="primary"
                  className="w-full"
                  outlined
                  onClick={handleReset}
                />
              )}
            </div>
            <div className="col-12 md:col-4 lg:col-3  py-0  ">
              {isLoading ? (
                <Skeleton height="40px" width="w-full"></Skeleton>
              ) : (
                <PFButton
                  label="Apply"
                  size="small"
                  severity="primary"
                  className="w-full"
                  onClick={() => {
                    fetchListData();
                    // setReloadList(true);
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex align-items-center mb-1">
        {isLoading ? (
          <Skeleton height="2rem" width="15rem" className="m-2"></Skeleton>
        ) : (
          <>
            <PFInputSwitch
              className="mx-2 md:mb-2"
              checked={toggle}
              onChange={e => toggleAll(e.value)}
            />
            <label className="mr-2 mb-2">Enable Expanded View</label>
          </>
        )}
      </div>
      <div className="card w-12 pt-2">
        <Toast ref={toast} />
        {isLoading ? (
          <PFTableLoader />
        ) : (
          <TreeTable
            value={nodes}
            editMode="row"
            onRowEditSave={onRowEditSave}
            onRowEditCancel={onRowEditCancel}
            lazy
            paginator
            first={first}
            rows={rows}
            onPage={onPage}
            totalRecords={count}
            pageLinkSize={6}
            tableStyle={{ minWidth: '60rem' }}
            rowClassName={rowClassName}
            columnResizeMode="expand"
            expandedKeys={expandedKeys}
            loading={reloadList}
            onToggle={e => setExpandedKeys(e.value)}
          >
            <Column
              body={node => actionTemplate(node)}
              className="w-7rem"
              expander={true}
            />
            <Column
              field="project_type"
              header="Type"
              body={projectTypeTemplate}
              className="w-12rem"
            />
            <Column
              field="category"
              header="Category"
              className="w-12rem"
            ></Column>
            <Column
              field="user"
              header="User(s)"
              body={userTemplate}
              className="w-10rem"
            ></Column>
            <Column
              field="rotation_percentage"
              header="Rotation%"
              body={rotationTemplate}
              editor={props => inputTextEditor(props)}
              className="w-10rem"
            ></Column>
          </TreeTable>
        )}
      </div>
    </div>
  );
};
export default UserJobAssignRotation;
