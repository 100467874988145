import React, { useState, useEffect } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { useDispatch, useSelector } from 'react-redux';

import { PROJECT_REPORT } from '../../constants';
import PFButton from '../CRM/PFPrime/PFButton';
import SkeletonLoader from '../shared/Loader/skeleton';
import {
  setSelectedViewMore,
  setIsTechFilterApplied,
} from '../../redux/slices/scheduler-view-more-event.slice';

import { VIEW_MORE_JOBS } from './constants/constants';

const SchedulerTabs = ({
  buttonHandler,
  initialLoader,
  view,
  isScheduler,
  calendarFullScreen,
  schedulerComponent,
  isFiltersSelected,
  calendarFilterData,
}) => {
  const dispatch = useDispatch();

  const selectedViewMore = JSON.parse(localStorage.getItem('view-more-jobs'));
  const [count, setCount] = useState(selectedViewMore || 1);

  const isTechFilterApplied = useSelector(
    state => state.viewMoreEventDropdown.isTechFilterApplied
  );

  useEffect(() => {
    localStorage.setItem('view-more-jobs', JSON.stringify(count));
    dispatch(setSelectedViewMore(JSON.stringify(count)));
  }, [count]);

  useEffect(() => {
    dispatch(setIsTechFilterApplied(false));
  }, []);

  return (
    <>
      <div
        className={`grid w-12 py-2 grid-nogutter ${calendarFullScreen ? 'hidden' : ''}`}
      >
        <div className="col-12 lg:col-3 flex align-items-center pl-0"></div>
        {(schedulerComponent &&
          isScheduler &&
          calendarFilterData?.projectClick) ||
        (isFiltersSelected && !isScheduler) ? (
          <>
            <div className="col-12 lg:col-6 mt-2 flex flex-wrap align-items-center sm:justify-content-center md:mt-0 calendar-head">
              {initialLoader ? (
                <div className="flex gap-2">
                  <SkeletonLoader
                    columnCount={1}
                    columnHeight="2.5rem"
                    columnWidth="6rem"
                  />
                  <SkeletonLoader
                    columnCount={1}
                    columnHeight="2.5rem"
                    columnWidth="7rem"
                  />
                  {isScheduler && isFiltersSelected && (
                    <>
                      <SkeletonLoader
                        columnCount={1}
                        columnHeight="2.5rem"
                        columnWidth="4rem"
                      />
                      <SkeletonLoader
                        columnCount={1}
                        columnHeight="2.5rem"
                        columnWidth="5rem"
                      />
                    </>
                  )}
                </div>
              ) : (
                <>
                  <>
                    <PFButton
                      outlined={
                        view === PROJECT_REPORT.FULL_CALENDAR ? false : true
                      }
                      severity="primary"
                      onClick={() =>
                        buttonHandler(PROJECT_REPORT.FULL_CALENDAR)
                      }
                      label="Calendar"
                    />

                    <PFButton
                      outlined={view === PROJECT_REPORT.CALENDAR ? false : true}
                      severity="primary"
                      onClick={() => buttonHandler(PROJECT_REPORT.CALENDAR)}
                      label="Technician View"
                    />

                    {isScheduler && isFiltersSelected ? (
                      <>
                        <PFButton
                          outlined={view === PROJECT_REPORT.MAP ? false : true}
                          severity="primary"
                          onClick={() => buttonHandler(PROJECT_REPORT.MAP)}
                          label="Map"
                        />
                        <PFButton
                          severity="primary"
                          outlined={
                            view === PROJECT_REPORT.SPLIT ? false : true
                          }
                          onClick={() => buttonHandler(PROJECT_REPORT.SPLIT)}
                          label="Split View"
                        />
                      </>
                    ) : (
                      ''
                    )}
                  </>
                </>
              )}
            </div>
            <div className="col-12 lg:col-3 flex justify-content-end">
              {view === PROJECT_REPORT.CALENDAR && (
                <Dropdown
                  placeholder="Select Job Card"
                  value={count}
                  onChange={e => {
                    setCount(e?.value);
                    dispatch(setSelectedViewMore(e?.value));
                  }}
                  optionValue="value"
                  optionLabel="label"
                  options={VIEW_MORE_JOBS}
                  pt={{
                    root: {
                      className: 'border-primary height-40',
                    },
                    input: {
                      className: 'text-primary line-height-1',
                    },
                    dropdownicon: {
                      className: 'text-primary',
                    },
                  }}
                />
              )}
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};

export default SchedulerTabs;
