import React, { useState } from 'react';
import { formateNumber } from '../helpers/Quotes';
import PFInputNumber from '../../../shared/PFPrime/PFInputNumber';
import PFFormikError from '../../../shared/PFPrime/PFFormikError';

const TotalPriceEditor = ({ rowData, options, editingRows, submitAction }) => {
  const { values, touched, errors } = submitAction;
  const [value, setValue] = useState(
    formateNumber((values?.totalPrice || 0) / (values?.quantity || 0))
  );
  const isEditing = editingRows[rowData.rowIndex];
  return isEditing ? (
    <div className="vertical-align-top">
      <span className="p-float-label">
        <PFInputNumber
          inputId="priceEach"
          name="priceEach"
          mode={'currency'}
          currency="USD"
          minFractionDigits={2}
          value={value}
          className={touched?.totalPrice && errors?.totalPrice ? 'error' : ''}
          version={2}
          inputStyle={{ width: '100px' }}
          disabled={true}
        />
      </span>
      <PFFormikError touched={touched} errors={errors} field="totalPrice" />
    </div>
  ) : (
    <div className="pt-2">
      <div className="w-full">{`$${formateNumber(rowData?.totalPrice).toFixed(2)}`}</div>
    </div>
  );
};

export default TotalPriceEditor;
