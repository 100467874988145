import axios from 'axios';

import { URL_CONSTANTS } from '../../../constants/urlConstants';

export const getCalendarResourceData = async params => {
  const queryParam = { params: {} };
  for (const key in params) {
    if (Boolean(params[key]) || params[key] === 0) {
      queryParam.params[key] = params[key];
    }
  }
  if (
    !('isJobCoverage' in queryParam.params) ||
    !queryParam.params.isJobCoverage
  ) {
    queryParam.params.isJobCoverage = true;
  }
  return await axios.get(
    `${URL_CONSTANTS.API.BASE_URL}/scheduler/calendar-view/resources`,
    queryParam
  );
};
export const getCalendarData = async params => {
  const queryParam = { params: {} };
  for (const key in params) {
    if (Boolean(params[key]) || params[key] === 0) {
      queryParam.params[key] = params[key];
    }
  }
  if (
    !('isJobCoverage' in queryParam.params) ||
    !queryParam.params.isJobCoverage
  ) {
    queryParam.params.isJobCoverage = true;
  }
  return await axios.get(
    `${URL_CONSTANTS.API.BASE_URL}/scheduler/calendar-view`,
    queryParam
  );
};
export const updateInstallerSchedule = async updateObj => {
  return await axios.post(
    `${URL_CONSTANTS.API.BASE_URL}/scheduler/calendar-view/update`,
    updateObj
  );
};

export const getAllInstallersList = async () => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/auth/user/report/get-installers-for-global-configuration`
    );
    return response?.data || [];
  } catch (error) {
    console.error(error);
    return [];
  }
};
export const getProjectStatusList = async () => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/system/status?statusType=Project`
    );
    return response?.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getNewSchedulerInitialData = async (
  datePeriodForTechnicianView,
  installerIds
) => {
  try {
    const defaultQueryString = `date=${moment(new Date()).format()}&period=${datePeriodForTechnicianView}&isJobCoverage=${true}&installerIds=${installerIds}`;

    const EventsRequest = axios.get(
      `/scheduler/calendar-view?${defaultQueryString}`
    );

    const eventsResponse = await EventsRequest;
    return {
      eventsList: eventsResponse?.items,
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getNewSchedulerData = async (
  queryParams,
  additionalParams = {}
) => {
  const { date, period } = queryParams;
  try {
    let queryString = `date=${date}&period=${period}`;
    if (
      additionalParams.projectTypeIds &&
      additionalParams.projectCategoryId &&
      additionalParams.projectStoreId
    ) {
      queryString += `&projectTypeIds=${additionalParams.projectTypeIds}`;
      queryString += `&projectCategoryId=${additionalParams.projectCategoryId}`;
      queryString += `&projectStoreId=${additionalParams.projectStoreId}`;
      if (additionalParams.isJobCoverage) {
        queryString += `&isJobCoverage=${additionalParams.isJobCoverage}`;
      }
      if (additionalParams.installerIds.length > 0) {
        queryString += `&installerIds=${additionalParams.installerIds}`;
      }
    } else {
      queryString += `&isJobCoverage=${additionalParams.isJobCoverage}`;
      queryString += `&installerIds=${additionalParams.installerIds}`;
    }

    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/scheduler/calendar-view?${queryString}`
    );
    return response?.data?.items;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getProjectDataById = async projectId => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/scheduler/map/${projectId}/edit-schedule`
    );
    return response?.data;
  } catch (error) {
    console.error(error);
  }
};

export const getRescheduleReasonOptions = async sourceId => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/system/reason?reasonType=Project&sourceSystemId=${sourceId}`
    );
    return response?.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const updateProjectScheduleInfo = async (
  projectId,
  updatedScheduleData
) => {
  try {
    const response = await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/projects/${projectId}`,
      updatedScheduleData
    );
    return response?.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const addInstallerUnavailability = async (
  installerId,
  unavailabilityPayload
) => {
  const eventPayload = {
    ...unavailabilityPayload,
    full_day: false,
    is_recurring: false,
    is_recurring_every_other_week: 0,
    sun: false,
    mon: false,
    tue: false,
    wed: false,
    thus: false,
    fri: false,
    sat: false,
  };
  try {
    const response = await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/installers/${installerId}/availability`,
      eventPayload
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const distanceInMileage = async (source, destination) => {
  try {
    const response = axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/projects/address/distance/${source}/${destination}`
    );
    return response;
  } catch (error) {
    console.error(error);
    return {};
  }
};
