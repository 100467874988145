import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Link } from '@material-ui/core';
import moment from 'moment';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { Skeleton } from 'primereact/skeleton';

import {
  CLIENT_DETAILS,
  globalCalendarFilters,
  globalDefaultCalendarFilters,
  globalRtsReportFilters,
  PROJECT_REPORT,
  scheduleInfoUserAction,
} from '../../constants';
import MapContainer from '../shared/SchedulerMap';
import { useAlerts } from '../shared/Alerts/alertsService';
import GenericDialog from '../shared/Dialog/GenericDialog';
import { Alert } from '../shared/Alerts/Alert';
import Notes from '../ProjectManagement/Notes/Notes';
import Installer from '../ProjectManagement/ScheduleInfo/Installer';
import { useStyles } from '../ProjectManagement/Scheduling/Reports/Reports.styles';
import {
  getAssignedAndUnassignedJobsMap,
  getProjectDataById,
  updateProjectScheduleInfo,
  getRescheduleReasonOptions,
  getStoresData,
} from '../ProjectManagement/Scheduling/Reports/Reports.service';
import {
  checkPermission,
  checkUrlString,
  handleMasterSearch,
} from '../../utils/Helpers';
import permissions from '../../config/permissions';
import {
  setNewSchedulerComponent,
  setRtsFilter,
} from '../../redux/slices/new-scheduler.slice';

import AlertInformation from './components/AlertInformation';
import SchedulerTabs from './components/SchedulerTabs';
import ReadyToScheduleReport from './components/ReadyToScheduleReport';
import SchedulerCalendar from './components/SchedulerCalendar';
import ProjectDetailsViewEditForm from './components/ProjectDetailsViewEditForm';
import CalendarView from './components/CalendarView/CalendarView';
import CustomFilter from './components/CustomFilter/CustomFilter';
import { getCategoryList } from './services/schedulerMasterServices';
import ProjectDetails from './components/ProjectDetails';
import TechnicianFilter from './components/TechnicianFilter';
import { getAllInstallersList } from './services/calendar';

const Scheduler = () => {
  const dispatch = useDispatch();
  const tableRef = useRef(null);
  const calendarRef = useRef(null);
  const targetID = 'Schedule';

  const [data, setData] = useState({});
  const [filter, setFilter] = useState({});

  //Copypaset
  const classes = useStyles();
  const [view, setView] = useState(PROJECT_REPORT.CALENDAR);

  const [installerOptions, setInstallerOptions] = useState([]);

  const [reloadCalender, setReloadCalender] = useState(true);
  const [reloadList, setReloadList] = useState(false);
  const { alert, setAlert } = useAlerts();

  const [projectDataLoading, setProjectDataLoading] = useState(false);
  const [projectData, setProjectData] = useState({});
  const [rescheduleReasonOptions, setRescheduleReasonOptions] = useState();
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);

  const [showErrorMsg, setShowErrorMsg] = useState(false);

  const [showTechnicianFilter, setShowTechnicianFilter] = useState(false);

  const [applyFilter, setApplyFilter] = useState(false);
  const [checkedStores, setCheckedStores] = useState(false);
  const [checkedAssigned, setCheckedAssigned] = useState(1);
  const [checkedUnAssigned, setCheckedUnAssigned] = useState(0);
  const [stores, setStores] = useState([]);
  const [mapData, setMapData] = useState([]);
  const [mapDate, setMapDate] = useState(
    moment(new Date()).format('MM-DD-yyyy hh:mm A')
  );
  const [filterDates, setFilterDates] = useState({
    start_date: moment(new Date()).format('MM-DD-yyyy'),
    end_date: moment(new Date()).format('MM-DD-yyyy'),
  });
  const [searchQuery, setSearchQuery] = useState({
    startDate: moment(new Date()).format('MM-DD-yyyy'),
    endDate: moment(new Date()).format('MM-DD-yyyy'),
    assigned: 1,
    unAssigned: 0,
  });
  const currDate = moment(new Date()).format('MM-DD-YYYY');

  const [isOpen, setIsOpen] = useState(false);
  const [dialogSettings, setDialogSettings] = useState({
    title: 'Edit Project Info',
    button1Text: '',
    button2Text: 'Save',
    showButton1: true,
    showButton2: true,
  });

  const [selected, setSelected] = useState(null);

  const currentPath = window?.location?.href;
  const isScheduler = checkUrlString(currentPath, '/scheduler');
  const [loader, setLoader] = useState({
    tableLoader: false,
    calendarLoader: false,
  });

  const [isFiltersSelected, setIsFiltersSelected] = useState(false);
  const [isProjectScheduler, setIsProjectScheduler] = useState(false);
  const [openScheduleModel, setOpenScheduleModel] = useState(false);
  const { technicians } = useSelector(state => state.technicians);

  const { categories } = useSelector(state => state.categories);

  const { projectStatusType } = useSelector(state => state.projectStatusType);
  const globalFilters = useSelector(state => state.globalFilters);
  const [calendarData, setCalendarData] = useState({
    resourceData: [],
    eventData: [],
    calendarBlock: false,
    calendarFilter: {},
    selectedDate: filter.selectedDate || new Date(),
  });

  const handleSetData = params => {
    if (params && typeof params === 'object' && !Array.isArray(params)) {
      setData({
        ...data,
        ...params,
      });
    }
  };
  const handleSetFilter = params => {
    if (params && typeof params === 'object' && !Array.isArray(params)) {
      setFilter(prevFilter => ({
        ...prevFilter,
        ...params,
      }));

      if (!isScheduler && params?.calendarFilter) {
        setIsFiltersSelected(true);
      }
    }
  };

  const handleSetLoader = params => {
    if (params && typeof params === 'object' && !Array.isArray(params)) {
      setLoader({
        ...loader,
        ...params,
      });
    }
  };

  const setMasterScheduler = () => {
    setInstallerOptions(technicians?.slice(0, 10));

    setCategoryOptions(categories?.slice(0, 10));

    setStatusOptions(projectStatusType);
  };

  const callGetAllInstallerListApi = async () => {
    //setReadyToScheduleLoader(true);

    const result = await getAllInstallersList();
    if (result) {
      const installHashMap = {};
      result.forEach(e => {
        installHashMap[e?.installer_id] = e;
      });
      handleSetFilter({
        installerMap: installHashMap,
      });

      return;
    }
    throw new Error('installer not found');
  };

  useEffect(() => {
    setMasterScheduler();
  }, [projectStatusType]);

  const handleCategorySearch = searchString => {
    const returenedCategory = handleMasterSearch(
      searchString,
      categories,
      'category'
    );
    setCategoryOptions(returenedCategory);
  };

  useEffect(() => {
    setMasterScheduler();
  }, [showTechnicianFilter]);

  useEffect(() => {
    const pathname = window?.location?.pathname;
    if (isScheduler && pathname && pathname.split('/')?.length > 2) {
      setIsProjectScheduler(true);
    }
  }, []);

  useEffect(() => {
    if (alert.scrollWindow) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [alert.scrollWindow]);

  useEffect(() => {
    if (applyFilter) return;
    if (searchQuery && (view === 'map' || view === 'split')) {
      setMapData([]);
      getAssignedAndUnassignedJobsMap({
        setMapData,
        searchQuery,
        setProjectDataLoading,
        setShowErrorMsg,
        stores,
        checkedStores,
      });
    }
  }, [searchQuery, view]);

  useEffect(() => {
    if (applyFilter) {
      setMapData([]);
      getAssignedAndUnassignedJobsMap({
        setMapData,
        searchQuery,
        setProjectDataLoading,
        setShowErrorMsg,
      });
      setApplyFilter(false);
    }
  }, [searchQuery, applyFilter]);

  useEffect(() => {
    if (checkedStores && (view === 'map' || view === 'split')) {
      getStoresData({
        setProjectDataLoading,
        mapData,
        setMapData,
        setStores,
        stores,
      });
    }
  }, [checkedStores]);

  // Toggle Between Map/Calendar/Split View
  const buttonHandler = buttonName => {
    setView(buttonName);
    setMasterScheduler();
    if (
      [
        PROJECT_REPORT.FULL_CALENDAR,
        PROJECT_REPORT.CALENDAR,
        PROJECT_REPORT.SPLIT,
      ].includes(buttonName)
    ) {
      dispatch(
        setNewSchedulerComponent({
          isWeekendsVisible: false,
        })
      );
      dispatch(
        setNewSchedulerComponent({
          selectedInstallers: [],
          isSelectAllInstallers: false,
        })
      );

      if (
        buttonName === PROJECT_REPORT.CALENDAR ||
        buttonName === PROJECT_REPORT.SPLIT ||
        buttonName === PROJECT_REPORT.FULL_CALENDAR
      ) {
        handleSetFilter({
          calendarFilter: {
            ...filter.calendarFilter,
            installerIds: filter?.filterTechnician?.installerIds || undefined,
          },
        });
      } else {
        handleSetFilter({
          calendarFilter: {
            ...filter.calendarFilter,
            installerIds: undefined,
          },
        });
      }

      handleSetLoader({
        calendarLoader: true,
      });
    }
  };

  const changeMapDate = day => {
    const currentDate = new Date(mapDate);

    if (day === 'previous') {
      currentDate.setDate(currentDate.getDate() - 1);
    } else {
      currentDate.setDate(currentDate.getDate() + 1);
    }
    const updatedDate = currentDate.toISOString().split('T')[0];

    setMapDate(updatedDate);
    setSearchQuery(prevState => ({
      ...prevState,
      startDate: updatedDate,
      endDate: updatedDate,
    }));
  };

  // **** Project Details Form Validation Schema ****
  const ProjectDetailsFormikSchema = Yup.object().shape({
    date_scheduled_start: Yup.string()
      .trim()
      .nullable()
      .when('date_scheduled', date_scheduled => {
        if (date_scheduled) {
          return Yup.string().trim().required('Required');
        }
      }),
    date_scheduled_end: Yup.string()
      .trim()
      .nullable()
      .when('date_scheduled', date_scheduled => {
        if (date_scheduled) {
          return Yup.string().trim().required('Required');
        }
      }),
  });
  // **** Project Details Formik Form Values ****
  const projectDetailsFormik = useFormik({
    initialValues: {
      store_number: projectData?.store?.store_number,
      type: projectData?.project_type?.project_type,
      category: projectData?.project_category?.category,
      status: projectData?.status?.status,
      project_number: projectData?.project_number,
      date_sold: projectData?.date_sold,
      client: projectData?.customer
        ? `${projectData?.customer?.first_name} ${projectData?.customer?.last_name}`
        : '',
      address: projectData?.installation_address?.address1,
      mobile_number: projectData?.customer?.customer_phones[0]?.phone_number,
      alternate_number: projectData?.customer?.alternate_phone,
      email: projectData?.customer?.customer_emails[0]?.email,
      ims_status: projectData?.source_status?.status,
      reschedule_reason: projectData?.reschedule_reason,
      rts_follow_up_date: projectData?.rts_follow_up_date,
      date_scheduled_start: projectData?.date_scheduled_start,
      date_scheduled_end: projectData?.date_scheduled_end,
      project_type_id: projectData?.project_type_id,
    },
    onSubmit: (values, { setSubmitting, resetForm }) => {
      updateProjectScheduleInfo(
        projectData.project_id,
        {
          reschedule_reason_id:
            projectDetailsFormik?.values?.reschedule_reason?.reason_id,
          rts_follow_up_date: projectDetailsFormik?.values?.rts_follow_up_date,
          date_scheduled_start:
            projectDetailsFormik?.values?.date_scheduled_start,
          date_scheduled_end: projectDetailsFormik?.values?.date_scheduled_end,
          project_type_id: projectDetailsFormik?.values?.project_type_id,
        },
        setProjectDataLoading,
        setAlert,
        setReloadList,
        setIsOpen,
        resetForm
      );
    },
    validationSchema: ProjectDetailsFormikSchema,
    enableReinitialize: true,
  });

  // *** View/Edit Project Details on Marker Click ***
  const viewEditProjectDetails = (
    reschedule_reason,
    source_system_id,
    project_id
  ) => {
    setMasterScheduler();
    setOpenScheduleModel(true);
    projectDetailsFormik?.handleReset();

    getRescheduleReasonOptions(
      PROJECT_REPORT.PROJECT,
      source_system_id,
      setRescheduleReasonOptions,
      value => {}
    );

    getProjectDataById(
      project_id,
      setProjectDataLoading,
      setProjectData,
      setIsOpen,
      setOpenScheduleModel
    );
    setDialogSettings(prevState => ({
      ...prevState,
      showButton2: true,
      button2Text: 'Save',
      title: 'Edit Project Info',
    }));
  };

  const installerCalendarFilterPopUp = () => {
    setShowTechnicianFilter(true);
  };
  const memoFilterState = useMemo(
    () => ({
      defaultFilter: globalFilters?.defaultFilter?.length,
      stores: globalFilters?.stores?.length,
      projectTypes: globalFilters?.projectTypes?.length,
      projectCategories: globalFilters?.projectCategories?.length,
      projectWorkrooms: globalFilters?.projectWorkrooms?.length,
      projectStatus: globalFilters?.projectStatus?.length,
      districtId: globalFilters?.districtId?.length,
      techniciansVal: globalFilters?.techniciansVal?.length,
      customers: globalFilters?.customers?.length,
      sourceStatus: globalFilters?.sourceStatus?.length,
      userType: globalFilters?.userType?.length,
    }),
    [
      globalFilters?.defaultFilter,
      globalFilters?.stores,
      globalFilters?.projectTypes,
      globalFilters?.projectCategories,
      globalFilters?.projectWorkrooms,
      globalFilters?.projectStatus,
      globalFilters?.districtId,
      globalFilters?.techniciansVal,
      globalFilters?.customers,
      globalFilters?.sourceStatus,
      globalFilters?.userType,
    ]
  );

  useEffect(() => {
    if (globalFilters) {
      const filtersToSet = { ...globalFilters };
      //Custom Filters
      let schedulerQueryObj = {};
      const filterData = globalRtsReportFilters?.map(val => {
        return (
          globalFilters?.[val?.key]?.length > 0 && {
            [val?.value?.[0]]: globalFilters?.[val?.key]?.map(
              item => item?.[val?.value?.[1]] || item
            ),
          }
        );
      });
      // Convert to object
      schedulerQueryObj = filterData.reduce((acc, item) => {
        return { ...acc, ...item };
      }, {});

      //Default Filters
      const keysToRemove = [
        'dashboardWorkroom',
        'selectedDefaultFiltersValues',
        'calendarStoreIds',
        'newInstallerIds',
        'installerIds',
        'newInstallerUserIds',
      ];
      // Keys to be removed
      const updatedDefaultFilters = Object.fromEntries(
        Object.entries(filtersToSet?.defaultFilter).filter(
          ([key]) => !keysToRemove.includes(key)
        )
      );

      //Final filter object
      const updatedFilter =
        Object.keys(filtersToSet?.defaultFilter)?.length > 0
          ? updatedDefaultFilters
          : schedulerQueryObj;

      dispatch(setRtsFilter(updatedFilter));

      //Custom Filters for Calendar
      let calendarQueryObj = {};
      const calendarFilterData = globalCalendarFilters?.map(val => {
        return (
          globalFilters?.[val?.key]?.length > 0 && {
            [val?.value?.[0]]: globalFilters?.[val?.key]
              ?.map(item => item?.[val?.value?.[1]] || item)
              ?.join(','),
          }
        );
      });
      handleSetLoader({ calendarLoader: true });
      // Convert to object
      calendarQueryObj = calendarFilterData.reduce((acc, item) => {
        return { ...acc, ...item };
      }, {});
      //Default Filter object
      let defaultCalendarQueryObj = {};
      const calendarDefaultFilterData = globalDefaultCalendarFilters?.map(
        val => {
          return (
            globalFilters?.defaultFilter?.[val?.key]?.length > 0 && {
              [val?.value]:
                globalFilters?.defaultFilter?.[val?.key]?.toString(),
            }
          );
        }
      );
      // Convert to object
      defaultCalendarQueryObj = calendarDefaultFilterData.reduce(
        (acc, item) => {
          return { ...acc, ...item };
        },
        {}
      );

      setFilter(prevFilter => ({
        ...prevFilter,
        filterTechnician:
          Object.keys(filtersToSet?.defaultFilter)?.length > 0
            ? defaultCalendarQueryObj
            : calendarQueryObj,
        calendarFilter:
          Object.keys(filtersToSet?.defaultFilter)?.length > 0
            ? defaultCalendarQueryObj
            : calendarQueryObj,
      }));
      setCalendarData({
        calendarFilter:
          Object.keys(filtersToSet?.defaultFilter)?.length > 0
            ? defaultCalendarQueryObj
            : calendarQueryObj,
      });

      setIsFiltersSelected(true);
    }
    handleSetLoader({ calendarLoader: true });
  }, [memoFilterState]);

  useEffect(async () => {
    let configData = {};
    try {
      if (CLIENT_DETAILS) {
        try {
          if (
            Array.isArray(CLIENT_DETAILS.tenant_permissions) &&
            CLIENT_DETAILS.tenant_permissions.length
          ) {
            const { included_permissions, allocation_percentage } =
              CLIENT_DETAILS.tenant_permissions[0];

            const allocateItemKeys = Object.keys(scheduleInfoUserAction);
            if (Array.isArray(included_permissions)) {
              const alreadyAllocatePermission = included_permissions?.filter(
                perm => allocateItemKeys.includes(perm)
              );
              configData = {
                ...configData,
                technicianAction:
                  scheduleInfoUserAction[alreadyAllocatePermission?.[0]],
                schedulePercentage: allocation_percentage,
              };
            }
          }
          if (
            CLIENT_DETAILS?.cx_scheduling_module &&
            CLIENT_DETAILS?.job_identifier
          ) {
            configData = {
              ...configData,
              cxSchedulerIcon: CLIENT_DETAILS?.job_identifier,
            };
          }
          if (
            CLIENT_DETAILS?.scheduler_config.calender_view &&
            CLIENT_DETAILS?.scheduler_config.calender_view?.card_visibility
          ) {
            configData = {
              ...configData,
              schedulerCardView:
                CLIENT_DETAILS?.scheduler_config.calender_view?.card_visibility,
            };
          }
          handleSetData(configData);
        } catch (ex) {
          console.log(ex);
        }
      }
    } catch (error) {
      console.log(error);
    }

    await callGetAllInstallerListApi();
    const getCategoryOptions = async () => {
      try {
        const response = await getCategoryList();
        setCategoryOptions(response);
      } catch (error) {
        console.log(error);
      }
    };
    await getCategoryOptions();
  }, []);

  const handleStoresChange = event => {
    setCheckedStores(event.target.checked);
    if (!event.target.checked) {
      const filteredData = mapData?.filter(item => !item.is_store);
      setMapData(filteredData);
    }
  };
  const handleAssignedChange = event => {
    setSearchQuery(prevState => ({
      ...prevState,
      startDate: filterDates?.start_date ? filterDates.start_date : currDate,
      endDate: filterDates?.end_date ? filterDates.end_date : currDate,
      assigned: event.target.checked ? 1 : 0,
      unAssigned: searchQuery?.unAssigned,
    }));
    setCheckedAssigned(event.target.checked);
  };
  const handleUnAssignedChange = event => {
    setSearchQuery(prevState => ({
      ...prevState,
      startDate: filterDates?.start_date ? filterDates.start_date : currDate,
      endDate: filterDates?.end_date ? filterDates.end_date : currDate,
      assigned: searchQuery?.assigned,
      unAssigned: event.target.checked ? 1 : 0,
    }));
    setCheckedUnAssigned(event.target.checked);
  };

  // Filter Techician
  const handleResetFilters = async () => {
    // handleSetFilter({
    //   calendarFilter: filter?.previousData,
    //   filterTechnician: {},
    // });
  };

  return (
    <div className="w-full content-wrapper">
      <div className="grid">
        <div className="col-12">{alert.exists && <Alert />}</div>
      </div>
      <div className="grid w-full grid-nogutter">
        <div className="col-12 md:col-7 flex align-items-center py-0">
          <h2 className="my-0">{isScheduler ? 'Scheduler' : 'Calendar'}</h2>
        </div>
        {view === PROJECT_REPORT.FULL_CALENDAR ? (
          <div className="col-12 xl:col-12 mb-2">
            <AlertInformation view={view} isScheduler={!isScheduler} />
          </div>
        ) : null}
        {!isProjectScheduler && isScheduler ? (
          <div className="col-12 xl:col-12 mb-2">
            <AlertInformation view={view} isScheduler={isScheduler} />
          </div>
        ) : null}
      </div>
      <div className="schedule-container ">
        {isScheduler ? (
          <ReadyToScheduleReport
            filter={filter}
            handleSetFilter={handleSetFilter}
            handleSetLoader={handleSetLoader}
          />
        ) : null}

        {isScheduler && filter?.selectedRow ? (
          <ProjectDetails selectedRow={filter?.selectedRow} />
        ) : null}

        <SchedulerTabs
          buttonHandler={buttonHandler}
          initialLoader={false}
          view={view}
          isScheduler={isScheduler}
          installerCalendarFilterPopUp={installerCalendarFilterPopUp}
          schedulerComponent={true}
          isFiltersSelected={
            isScheduler ? !!filter?.selectedRow : isFiltersSelected
          }
        />
      </div>
      <div
        className={`bg-white w-12 px-2 ${!isScheduler ? 'calendar-page-height' : 'calendar-full-height'}`}
      >
        {view === PROJECT_REPORT.FULL_CALENDAR && (
          <CalendarView
            tableRef={tableRef}
            calendarRef={calendarRef}
            targetID={targetID}
            filter={filter}
            data={data}
            loader={loader}
            handleSetFilter={handleSetFilter}
            handleSetData={handleSetData}
            handleSetLoader={handleSetLoader}
            statusOptions={statusOptions}
            view={view}
            calendarData={calendarData}
            setCalendarData={setCalendarData}
          />
        )}
      </div>
      <div
        className={`grid w-12 grid-nogutter ${!isScheduler ? 'scheduler-page-height' : 'scheduler-full-height'}`}
      >
        {view === PROJECT_REPORT.CALENDAR || view === PROJECT_REPORT.SPLIT ? (
          <div
            className={`col-12 ${
              view === PROJECT_REPORT.SPLIT
                ? 'xl:col-6 border-right-1 border-200'
                : 'xl:col-12 p-0'
            } `}
          >
            {/* {(schedulerComponent && isScheduler) || isFiltersSelected ? ( */}
            {filter?.calendarFilter &&
              (filter?.selectedRow || !isScheduler) && (
                <div
                  className={`bg-white ${isScheduler ? 'scheduler-page' : 'calendar-component'}`}
                >
                  <div className="flex flex-column">
                    <div className="col-12 p-0">
                      <SchedulerCalendar
                        tableRef={tableRef}
                        calendarRef={calendarRef}
                        targetID={targetID}
                        filter={filter}
                        data={data}
                        loader={loader}
                        calendarData={calendarData}
                        setCalendarData={setCalendarData}
                        handleSetData={handleSetData}
                        handleSetFilter={handleSetFilter}
                        handleSetLoader={handleSetLoader}
                        statusOptions={statusOptions}
                        view={view}
                      />
                    </div>
                  </div>
                </div>
              )}

            {/* // ) : null} */}
          </div>
        ) : null}
        {(view === PROJECT_REPORT.MAP || view === PROJECT_REPORT.SPLIT) && (
          <div
            className={`col-12 xl:col-${
              view === PROJECT_REPORT.SPLIT ? 6 : 12
            }`}
          >
            <div
              className="bg-white"
              classes={{ root: classes.mapPaper }}
              elevation={0}
            >
              <div className="flex flex-column">
                <div className="col-12 mb-4">
                  <MapContainer
                    lat={59.95}
                    long={30.33}
                    markers={mapData}
                    viewEditProjectDetails={viewEditProjectDetails}
                    categoryOptions={categoryOptions}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    mapDate={mapDate}
                    setMapDate={setMapDate}
                    changeMapDate={changeMapDate}
                    selected={selected}
                    setSelected={setSelected}
                    showErrorMsg={showErrorMsg}
                    handleStoresChange={handleStoresChange}
                    handleAssignedChange={handleAssignedChange}
                    handleUnAssignedChange={handleUnAssignedChange}
                    checkedAssigned={checkedAssigned}
                    checkedUnAssigned={checkedUnAssigned}
                    checkedStores={checkedStores}
                    setFilterDates={setFilterDates}
                    currDate={currDate}
                    handleCategorySearch={handleCategorySearch}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <GenericDialog
        isOpen={isOpen}
        dialogWidth="lg"
        handleClose={() => {
          setIsOpen(false);
          projectDetailsFormik?.handleReset();
        }}
        handleSave={projectDetailsFormik.handleSubmit}
        dialogSettings={dialogSettings}
        disabledButton2={
          !projectDetailsFormik?.dirty || !projectDetailsFormik?.isValid
        }
        disabledButton1={false}
      >
        <div className='col-12 className="flex flex-column gap-2'>
          <div className="grid">
            <div className="col-12 p-0">{alert.exists && <Alert />}</div>
            <div className="col-12 text-right">
              <Link
                to={`/project/view/${projectData?.project_id}`}
                target="_blank"
                className="border-1 px-3 py-2 border-round-md no-underline border-primary text-primary"
              >
                View Project
              </Link>
            </div>
            <div className="col-12">
              <form onSubmit={projectDetailsFormik.handleSubmit}>
                <ProjectDetailsViewEditForm
                  projectDetailsFormik={projectDetailsFormik}
                  rescheduleReasonOptions={rescheduleReasonOptions}
                />
              </form>
            </div>
            <div className="col-12">
              {isOpen && (
                <Installer
                  formik={{
                    values: {
                      project_id: projectData.project_id,
                      date_scheduled:
                        projectDetailsFormik?.values?.date_scheduled,
                      proj_end_date:
                        projectDetailsFormik?.values?.project_end_date,
                    },
                  }}
                  setReloadCalender={setReloadCalender}
                  reloadCalender={reloadCalender}
                  projectId={projectData.project_id}
                  schedulerEditPermission={
                    !checkPermission(
                      permissions?.viewEditProject?.actionEditScheduler
                    )
                  }
                  installerOptions={installerOptions}
                />
              )}
            </div>
            <div className="col-12">
              <Notes
                formik={{ values: { project_id: projectData.project_id } }}
                action=""
                schedulerEditPermission={
                  !checkPermission(
                    permissions?.viewEditProject?.actionEditScheduler
                  )
                }
              />
            </div>
          </div>
        </div>
      </GenericDialog>
      <TechnicianFilter
        setShowTechnicianFilter={() => {}}
        showTechnicianFilter={() => {}}
        installerFormik={{}}
        handleResetFilters={handleResetFilters}
        installerOptions={[]}
        projectTypeOptions={[]}
        storeOptions={[]}
        categoryOptions={[]}
        districtOptions={[]}
        userTypesOptions={[]}
        setSearchWorkroomText={() => {}}
        handleUserTypeSearch={() => {}}
        userTypeLoader={false}
        calendarLoading={false}
        handleTechnicianSearch={() => {}}
        handleCategorySearch={() => {}}
        handleSetLoader={handleSetLoader}
        handleSetFilter={handleSetFilter}
        filter={filter}
      />
    </div>
  );
};

export default Scheduler;
