import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { Card } from 'primereact/card';
import { Image } from 'primereact/image';
import { FileUpload } from 'primereact/fileupload';
import { useFormik } from 'formik';
import { Column } from 'primereact/column';
import * as Yup from 'yup';
import { InputMask } from 'primereact/inputmask';
import { Toast } from 'primereact/toast';
import { RadioButton } from 'primereact/radiobutton';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { MultiSelect } from 'primereact/multiselect';
import { useSelector } from 'react-redux';
import { InputNumber } from 'primereact/inputnumber';

import { useStyles } from '../Configuration/RestrictAccessbyRole.styles';
import PFCheckbox from '../../shared/PFPrime/PFCheckbox';
import PFButton from '../../shared/PFPrime/PFButton';
import PFTableLoader from '../../shared/Loader/PFTableLoader';
import PFInputSwitch from '../../shared/PFPrime/PFInputSwitch';
import PFInputNumber from '../../shared/PFPrime/PFInputNumber';
import PFGoogleMap from '../../shared/PFPrime/PFGoogleMap';
import LrrpConfiguration from '../Configuration/LrrpConfiguration';
import {
  PF_ADMIN,
  project_linkages,
  ItemLinkageConfigOptions,
  SALES_COMMISSION_TYPE,
  ALLOCATION_PROJECT_ITEMS,
  DOCUMENT_AUTO_PULL_RETRY_FIELDS,
  CLIENT_DETAILS,
} from '../../../constants';
import PFDropdown from '../../shared/PFPrime/PFDropdown';
import PFInputText from '../../shared/PFPrime/PFInputText';
import PageHeader from '../../shared/PageHeader/PageHeader';
import { getPercentageValue } from '../../../utils/Helpers';

import {
  getClientData,
  multiPartUpdateTenantData,
} from './service/TenantConfig.service';
import timezonesData from './timezones.json';
import EditHomeDepotConfig from './EditHomeDepotConfig';
import SchedulerConfig from './SchedularReportConfig/SchedularConfig/SchedualarConfig';
import { SCHEDULER_CONFIG_DATA } from './company-config.constant';
import { Skeleton } from 'primereact/skeleton';
import TableColumnsLoader from '../../shared/Loader/tableColumnsLoader';

export const FEATURE_LIST = [
  {
    key: 'INSTALLER_WITH_ALL_DOCUMENT',
    label: 'Project Documents Visible to Technicians',
    checked: false,
  },
  {
    key: 'ENABLE_TENTATIVE_SCHEDULING',
    label: 'Enable Tentative Scheduling Functionality',
    checked: false,
  },
  {
    key: 'VIEW_ALL_MERCHANDISE_COST',
    label: 'Show Product Items Cost',
    checked: false,
  },
  {
    key: 'CONVERT_ISSUE_TO_LABOR_ITEM',
    label: 'Convert Issues to Labor Items',
    checked: false,
  },
];

const ADDITIONAL_FEATURE_TYPE = {
  ALLOCATION_BEHAVIOUR: 'allocationBehaviour',
  ITEM_LINKAGE_CONFIGURATION: 'itemLinkageConfiguration',
  SALES_COMMISSION_OPTED: 'salesCommissionOpted',
  PROJECT_COLOR_CODE: 'projectColorCode',
  SKIP_TECHNICIAN_ASSIGNMENT_CLONING: 'skipTechnicianAssignmentCloning',
  GROUP_SOURCE_CATEGORY: 'groupSourceCategory',
  FIXED_TOP_BAR: 'fixedTopBar',
  FIXED_PROJECT_BAR: 'fixedProjectBar',
  SCHEDULER_CONFIG: 'schedulerConfig',
  PERSONALIZED_EMAIL_CONFIG: 'personalizedEmailsConfig',
  CHARGEBACK_APPROVAL_MODULE: 'chargebackApprovalModule',
  RESET_PASSWORD_EXPIRE_IN: 'restPasswordExpireIn',
  MFA_EXPIRE_IN_DAYS: 'mfaExpireInDays',
  DOCUMENT_EXPIRE_IN_DAYS: 'document_expire_in_days',
  SHIPPING_AND_RECEIVING_MODULE: 'shipping_receiving',
  HIGHLIGHT_STORE_NOTES: 'highlight_store_notes',
  COPY_TOTAL_SALE: 'copy_total_sales',
  ADMIN_EMAIL: 'admin_email',
  DO_NOT_OVERRIDE_LABOR_COST: 'do_not_override_labor_cost',
  SHOW_LINE_ITEMS_ON_DOCUMENTS: 'show_line_items_on_documents',
  ASSIGN_TECHNICIAN_ON_REVENUE_EXCLUDED_ITEMS:
    'assign_technician_on_revenue_excluded_items',
  EPA_GUARDRAILS_WORKFLOW: 'epa_guardrails_workflow ',
  DOCUMENT_AUTO_PULL_RETRY: 'document_auto_pull_retry',
  PROJECT_NUMBER: 'personalized_private_project_number',
  RELATED_POS: 'related_pos',
  SEND_TENTATIVE_SCHEDULE_NOTE_TO_SOURCE:
    'send_tentative_schedule_note_to_source',
};

const RESET_PASSWORD_DAYS_OPTIONS = [
  { label: '30 Days', value: 30 },
  { label: '60 Days', value: 60 },
  { label: '90 Days', value: 90 },
  { label: '120 Days', value: 120 },
  { label: '180 Days', value: 180 },
];
const MFA_EXPIRE_IN_DAYS_OPTIONS = [
  { label: 'Daily', value: 1 },
  { label: 'Weekly', value: 7 },
  { label: '15 Days', value: 15 },
  { label: 'Monthly', value: 30 },
  { label: '60 Days', value: 60 },
  { label: '90 Days', value: 90 },
  { label: '120 Days', value: 120 },
  { label: '180 Days', value: 180 },
];
const DOCUMENT_EXPIRE_IN_DAYS_OPTIONS = [
  { label: '30 Days', value: 30 },
  { label: '60 Days', value: 60 },
  { label: '90 Days', value: 90 },
  { label: '120 Days', value: 120 },
  { label: '180 Days', value: 180 },
];
const EditCompanyConfig = () => {
  const { id } = useParams();
  const [timeZones, setTimeZones] = useState([]);
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(true);
  const [clientName, setClientName] = useState('');
  const [client, setClientDetail] = useState('');
  const [binaryImage, setBinaryImage] = useState();
  const [featureList, setFeatureList] = useState(FEATURE_LIST);
  const [allocationPermission, setAllocationPermission] = useState(
    'LEAVE_UNASSIGNED_ITEMS'
  );
  const [allocationPercentage, setAllocationPercentage] = useState(0);
  const pfClientsId = localStorage.getItem('client_id');
  const client_id = id;
  const [updatePayload, setUpdatePayload] = useState();
  const [lrrpData, setLrrpData] = useState();
  const [responseData, setResponseData] = useState();
  const { gMapFieldLayout, address: gAddress } = PFGoogleMap();
  const [forceSyncDisabled, setForceSyncDisabled] = useState(true);
  const [systemTenant, setSystemTenant] = useState([
    {
      roleId: 3,
      excluded_permissions: [],
      included_permissions: ['LEAVE_UNASSIGNED_ITEMS'],
    },
  ]);
  const fileUploadRef = useRef();
  const toast = useRef(null);
  const { categories } = useSelector(state => state.categories);
  const [btnDisables, setBtnDisables] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [groupSourceOpt, setGroupSourceOpt] = useState(0);
  const [allocationOption, setAllocationOption] = useState(false);
  const client_details = JSON.parse(localStorage?.getItem('client_details'));

  const clientData = JSON.parse(
    localStorage?.getItem('client_details')
  )?.registeredSources;

  const hasSourceSystemIdTwo = clientData?.some(
    item => item?.source_system_id === 2
  );

  const otherSourceSystemIds = clientData?.filter(
    item => item?.source_system_id !== 2
  );
  const hasOtherSourceSystemIds = otherSourceSystemIds?.length > 0;

  const accessBreadcrumb = [
    {
      text: 'Admin',
    },
    {
      link: '/admin/configuration',
      text: 'Company Configurations',
    },
    {
      text: `${clientName} Configurations`,
    },
  ];

  const validation = Yup.object().shape({
    client_name: Yup.string().required('Client Name is required.'),
    client_timezone: Yup.string().required('Required.'),
    contact_name: Yup.string().max(30, 'Too Long!').nullable(),
    contact_email: Yup.string().email('Invalid email').nullable(),
    personalized_private_project_number: Yup.string().when(
      'is_personalized_private_project',
      {
        is: 1,
        then: Yup.string()
          .matches(/^\d+$/, 'Project number must be a valid number')
          .min(3, 'Project number must be at least 3 characters')
          .max(7, 'Project number must not exceed 7 characters')
          .required('Project number is required'),
        otherwise: Yup.string().nullable(),
      }
    ),
  });

  const onSubmit = async values => {
    const payload = {
      client_id: values.client_id,
      client_name: values.client_name,
      phone_number: values.phone_number,
      email: values.email,
      fax: values.fax,
      contact_name: values.contact_name,
      contact_email: values.contact_email,
      contact_phone_number: values.contact_phone_number,
      logo: values.logo,
      principal_name: values.principal_name,
      installer_number_lowes: values.installer_number_lowes,
      renovator_name: values.renovator_name,
      renovator_certification_number: values.renovator_certification_number,
      client_timezone: values.client_timezone,
      client_geolocation: values.client_geolocation,
      quickbooks_refresh_token: values.quickbooks_refresh_token,
      routing_module: values.routing_module,
      crm_module: values.crm_module,
      warranty_module: values.warranty_module,
      floorsoft_opted: values.floorsoft_opted,
      is_measuresquare: values.is_measuresquare,
      create_project_private_lead: values.create_project_private_lead,
      is_customer_portal: values.is_customer_portal,
      lrrpPayload: lrrpData,
      tenant_permissions: systemTenant,
      is_line_item_visible: values.is_line_item_visible ? 1 : 0,
      due_days: values.due_days,
      city: values.city,
      state: values.state,
      zipcode: values.zipcode,
      address1: values.address1,
      address2: values.address2,
      latitude: values.latitude,
      longitude: values.longitude,
      state_id: '',
      occupant_type: '',
      address_id: values.address_id || '',
      project_linkage: values?.project_linkage,
      sales_commission_opted: values?.sales_commission_opted,
      skip_technician_assignment_cloning:
        values.skip_technician_assignment_cloning,
      sales_commission_criteria: values?.sales_commission_criteria,
      cx_scheduling_module: values?.cx_scheduling_module,
      hd_default_reason: values?.hd_default_reason,
      hd_lrrp_configuration: {
        lswp_followed: values?.hd_lrrp_configuration?.lswp_followed,
        hd_lead_test_result: values?.hd_lrrp_configuration?.hd_lead_test_result,
      },
      capture_live_location: values?.capture_live_location,
      group_source_category_opted: values?.group_source_category_opted,
      personalized_emails: values?.personalized_emails,
      default_calender_view: values?.default_calender_view,
      allocation_percentage: values?.allocation_percentage,
      fixed_top_bar: values?.fixed_top_bar,
      fixed_project_bar: values?.fixed_project_bar,
      scheduler_config: values?.scheduler_config,
      chargeback_approval_module: values?.chargeback_approval_module,
      reset_password_in_days: values?.reset_password_in_days,
      mfa_expire_in_days: values?.mfa_expire_in_days,
      short_url_generate_in_days: values?.short_url_generate_in_days,
      shipping_receiving: values?.shipping_receiving,
      highlight_store_notes: values?.highlight_store_notes,
      copy_total_sales: values?.copy_total_sales,
      admin_email: values?.admin_email,
      do_not_override_labor_cost: values?.do_not_override_labor_cost,
      is_capture_job_distance_enabled: values?.is_capture_job_distance_enabled,
      show_line_items_on_documents: values?.show_line_items_on_documents,
      assign_technician_on_revenue_excluded_items:
        values?.assign_technician_on_revenue_excluded_items,
      epa_guardrails_workflow: values?.epa_guardrails_workflow,
      document_auto_pull_retry: values?.document_auto_pull_retry,
      is_personalized_private_project: values?.is_personalized_private_project,
      personalized_private_project_number:
        values?.personalized_private_project_number,
      send_tentative_schedule_note_to_source:
        values?.send_tentative_schedule_note_to_source,
    };
    try {
      const apiResponse = await multiPartUpdateTenantData(payload);

      if (apiResponse?.data?.status) {
        toast.current.show({
          severity: 'success',
          summary: 'Record Updated Successfully',
          life: 1500,
        });
        setBtnDisables(true);
        if (apiResponse?.data?.data?.client) {
          setGroupSourceOpt(
            apiResponse?.data?.data?.client?.group_source_category_opted
          );
          setResponseData(apiResponse?.data?.data?.client);
          formik.setValues({
            ...formik.values,
            ...apiResponse?.data?.data?.address,
            ...apiResponse?.data?.data?.client,
          });
        }

        return;
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Error occurred while Updating record',
          life: 1500,
        });
        setBtnDisables(true);
        return;
      }
    } catch (error) {
      if (error.response && error.response.status === 422) {
        toast.current.show({
          severity: 'error',
          detail:
            'Uploaded File Type is not supported. Please try with extension .jpg, .jpeg, .PNG, .JFIF ',
          life: 5000,
        });
      } else {
        toast.current.show({
          severity: 'error',
          detail: 'Something Went Wrong',
          life: 3000,
        });
      }
      setBtnDisables(true);
      console.error(error);
    }
    setUpdate(true);
    setLoading(true);
  };

  const formik = useFormik({
    initialValues: {
      client_id: '',
      client_name: '',
      phone_number: '',
      email: '',
      fax: '',
      contact_name: '',
      contact_email: '',
      contact_phone_number: '',
      logo: '',
      principal_name: '',
      installer_number_lowes: '',
      measure_square_opted: '',
      renovator_name: '',
      renovator_certification_number: '',
      client_timezone: '',
      client_geolocation: { lat: '', lng: '' },
      default_lead_response_reason: '',
      quickbooks_refresh_token: '',
      routing_module: '',
      crm_module: '',
      warranty_module: '',
      floorsoft_opted: '',
      is_measuresquare: '',
      create_project_private_lead: '',
      is_customer_portal: '',
      is_capture_job_distance_enabled: 0,
      default_lead_configuration: lrrpData,
      tenant_permissions: updatePayload,
      is_line_item_visible: 0,
      due_days: 0,
      address1: '',
      address2: '',
      city: '',
      state: '',
      zipcode: '',
      address_id: '',
      latitude: '',
      longitude: '',
      project_linkage: project_linkages,
      sales_commission_opted: 0,
      skip_technician_assignment_cloning: 0,
      sales_commission_criteria: '',
      hd_default_reason: '',
      hd_lrrp_configuration: {
        lswp_followed: '',
        hd_lead_test_result: '',
      },
      group_source_category_opted: 0,
      personalized_emails: 0,
      default_calender_view: '',
      allocation_percentage: 0,
      fixed_top_bar: 0,
      fixed_project_bar: 0,
      scheduler_config: SCHEDULER_CONFIG_DATA,
      chargeback_approval_module: 0,
      reset_password_in_days: RESET_PASSWORD_DAYS_OPTIONS[2].value,
      mfa_expire_in_days: MFA_EXPIRE_IN_DAYS_OPTIONS[1].value,
      admin_email: '',
      short_url_generate_in_days: DOCUMENT_EXPIRE_IN_DAYS_OPTIONS[0].value,
      shipping_receiving: 0,
      highlight_store_notes: 0,
      copy_total_sales: 1,
      do_not_override_labor_cost: 0,
      show_line_items_on_documents: 0,
      assign_technician_on_revenue_excluded_items: 0,
      epa_guardrails_workflow: 0,
      document_auto_pull_retry: DOCUMENT_AUTO_PULL_RETRY_FIELDS,
      personalized_private_project_number: 0,
      is_personalized_private_project: 0,
      send_tentative_schedule_note_to_source: 1,
    },
    validationSchema: validation,
    onSubmit: onSubmit,
  });

  useEffect(() => {
    if (gAddress) {
      formik.setFieldValue('city', gAddress.city);
      formik.setFieldValue('state', gAddress.stateCode);
      formik.setFieldValue('zipcode', gAddress.zipcode);

      formik.setFieldValue('client_geolocation', {
        lat: gAddress.latitude || '',
        lng: gAddress.longitude || '',
      });
      formik.setFieldValue('latitude', gAddress.latitude);
      formik.setFieldValue('longitude', gAddress.longitude);

      formik.setFieldValue('address1', gAddress.addressLineOne);
      formik.setFieldValue('address2', gAddress.addressLineTwo);
      setBtnDisables(false);
    }
  }, [gAddress]);

  useEffect(() => {
    formik.setFieldValue('client_geolocation', {
      lat: formik.values.latitude || '',
      lng: formik.values.longitude || '',
    });
  }, [formik.values.longitude, formik.values.latitude]);

  const fetchData = async client_id => {
    setLoading(true);
    try {
      const response = await getClientData(client_id || pfClientsId);
      setClientDetail(response?.data?.client);
      setSystemTenant(response?.data?.client?.tenant_permissions);

      const updatedFeatureList = [...featureList];
      updatedFeatureList.forEach(record => {
        if (
          response?.data?.client?.tenant_permissions?.[0]?.included_permissions?.includes(
            record.key
          )
        ) {
          record.checked = true;
        } else {
          record.checked = false;
        }
      });
      let includedPermissions = '';
      ALLOCATION_PROJECT_ITEMS.forEach(record => {
        if (
          response?.data?.client?.tenant_permissions?.[0].included_permissions?.includes(
            record
          )
        ) {
          includedPermissions = record;
        }
      });
      setAllocationPermission(includedPermissions);
      if (includedPermissions.includes('CUSTOM_ALLOCATION')) {
        setAllocationPercentage(
          response?.data?.client?.tenant_permissions?.[0]?.allocation_percentage
        );
        setAllocationOption(true);
      }
      setFeatureList(updatedFeatureList);
      if (response?.data?.client?.client_id?.length > 0) {
        setResponseData(response?.data?.client);
        formik.setValues({
          ...formik.values,
          ...response?.data?.address,
          ...response?.data?.client,
        });
        setClientName(response?.data?.client?.client_name || '');
        if (response?.data?.client?.group_source_category_opted === null) {
          setGroupSourceOpt(0);
        } else {
          setGroupSourceOpt(
            response?.data?.client?.group_source_category_opted
          );
        }
      }
      setLoading(false);
    } catch (error) {
      return error;
    }
  };

  const handleCheckbox = async (key, checkedState) => {
    const tempPermissions = [...systemTenant];

    tempPermissions.forEach(permissions => {
      if (checkedState) {
        if (permissions.excluded_permissions.includes(key)) {
          permissions.excluded_permissions =
            permissions?.excluded_permissions?.filter(record => record != key);
        }
        permissions.included_permissions.push(key);
      } else {
        if (permissions.included_permissions.includes(key)) {
          permissions.included_permissions =
            permissions?.included_permissions?.filter(record => record != key);
        }
        permissions.excluded_permissions.push(key);
      }
    });

    setFeatureList(
      featureList.map(record => {
        if (record.key == key) {
          record.checked = checkedState;
        }
        return record;
      })
    );
    setSystemTenant(tempPermissions);
  };
  const handleRadioButton = async (key, checkedState) => {
    const tempPermissions = [...systemTenant];
    ALLOCATION_PROJECT_ITEMS.forEach(items => {
      if (tempPermissions?.[0]?.excluded_permissions) {
        tempPermissions[0].excluded_permissions =
          tempPermissions?.[0]?.excluded_permissions?.filter(
            selected => items != selected
          );
      }
      if (tempPermissions?.[0]?.included_permissions) {
        tempPermissions[0].included_permissions =
          tempPermissions?.[0]?.included_permissions?.filter(
            selected => items != selected
          );
      }
    });

    if (key === 'CUSTOM_ALLOCATION') {
      setAllocationOption(true);
    } else {
      setAllocationOption(false);
    }

    tempPermissions?.[0]?.included_permissions?.push(key);
    setSystemTenant(tempPermissions);
  };

  useEffect(() => {
    if (client_id?.length > 0 || pfClientsId?.length > 0) {
      if (update === true) {
        fetchData(client_id || pfClientsId);
        setUpdate(false);
      }
    }
  }, [update, client_id]);

  useEffect(() => {
    const formattedTimeZones = timezonesData.reduce((accumulator, current) => {
      const utcTimeZone = current.utc.map(utc => {
        return {
          label: `${utc} (${current.offset >= 0 ? '+' : ''} ${current.offset}:00)`,
          value: utc,
        };
      });
      accumulator.push(...utcTimeZone);
      return accumulator;
    }, []);
    setTimeZones(formattedTimeZones);
  }, []);

  const ImageValidation = type => {
    if (!type || !type.startsWith('image')) {
      return false;
    } else if (
      type.includes('svg') ||
      type.includes('webp') ||
      type.includes('gif') ||
      type.includes('bmp')
    ) {
      return false;
    } else {
      return true;
    }
  };

  const handleLrrpDataChange = data => {
    setLrrpData(data);
  };

  function convertString(str) {
    return str
      .toLowerCase()
      .split('_')
      .map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(' ');
  }

  const confirmRemove = () => {
    setBinaryImage(null);
    formik.setFieldValue('logo', null);
    setBtnDisables(false);
    setShowConfirmation(false);
  };

  const cancelRemove = () => {
    setShowConfirmation(false);
  };

  const footerContent = (
    <div>
      <PFButton
        label="No"
        size="small"
        severity="primary"
        className="mx-2"
        outlined
        onClick={cancelRemove}
      />
      <PFButton
        label="Yes"
        size="small"
        severity="primary"
        className="mx-2"
        onClick={confirmRemove}
      />
    </div>
  );

  const allProjectCategory = categories => {
    const options =
      categories &&
      categories?.map(item => ({
        name: item.category,
        value: item.project_category_id,
      }));
    return options;
  };

  const categoryOptions = useMemo(
    () => allProjectCategory(categories),
    [categories]
  );
  useEffect(() => {
    if (formik?.values?.project_linkage?.options?.length > 0) {
      setForceSyncDisabled(false);
    } else {
      setForceSyncDisabled(true);
    }
  }, [formik?.values?.project_linkage?.options]);

  useEffect(() => {
    if (!formik?.values?.project_linkage?.enabled) {
      formik.setFieldValue('project_linkage', project_linkages);
    }
  }, [formik?.values?.project_linkage?.enabled]);

  useEffect(() => {
    if (forceSyncDisabled) {
      formik.setFieldValue('project_linkage.force_sync', false);
    }
  }, [forceSyncDisabled]);

  const featureColumnsData = [
    {
      field: 'label',
      header: 'Features',
      style: { width: '70%' },
    },
    {
      ...(!loading
        ? {
            body: rowData => {
              if (
                rowData.type === ADDITIONAL_FEATURE_TYPE.ALLOCATION_BEHAVIOUR
              ) {
                return (
                  <div>
                    {ALLOCATION_PROJECT_ITEMS.map((item, index) => (
                      <div
                        key={index}
                        className="col-6 md:col-6 lg:col-12 mb-3 py-0 px-0 min-h-32 align-items-center flex"
                      >
                        <div className="flex align-items-center">
                          <RadioButton
                            value={item}
                            checked={item === allocationPermission}
                            onChange={e => {
                              handleRadioButton(e.target.value, true);
                              setAllocationPermission(e.target.value);
                              setBtnDisables(false);
                            }}
                          />
                          <label htmlFor={`${index}`} className="ml-2 py-0 ">
                            {convertString(item)}
                          </label>
                          {item === 'CUSTOM_ALLOCATION' && allocationOption && (
                            <PFInputNumber
                              id="allocation_percentage"
                              className="w-36 ml-4"
                              value={allocationPercentage}
                              onChange={(event, value) => {
                                const percentage = getPercentageValue(value);
                                setAllocationPercentage(percentage);
                                formik.setFieldValue(
                                  'allocation_percentage',
                                  percentage
                                );
                                setBtnDisables(false);
                              }}
                              placeholder="%"
                              suffix={'%'}
                              max={100}
                              maxLength={6}
                              min={0}
                              pt={{
                                root: {
                                  className: 'h-2rem pb-2',
                                },
                                input: {
                                  root: {
                                    className:
                                      'w-full border-noround border-bottom-1 border-top-none border-x-none p-0 shadow-none border-gray-500 ',
                                  },
                                },
                              }}
                            />
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                );
              } else if (
                rowData.type === ADDITIONAL_FEATURE_TYPE.SALES_COMMISSION_OPTED
              ) {
                return (
                  <div className="col-12 py-0 px-0 flex">
                    <div className="col-3 pl-0">
                      <PFCheckbox
                        id="sales_commission_opted"
                        name="sales_commission_opted"
                        checked={formik.values.sales_commission_opted == 1}
                        onChange={event => {
                          formik.setFieldValue(
                            'sales_commission_opted',
                            event.checked ? 1 : 0
                          );
                          if (!event?.checked) {
                            formik.setFieldValue(
                              'sales_commission_criteria',
                              ''
                            );
                          }
                          event.checked &&
                          !!formik.values?.sales_commission_criteria
                            ? setBtnDisables(false)
                            : !event.checked
                              ? setBtnDisables(false)
                              : setBtnDisables(true);
                        }}
                        className="pt-1"
                      ></PFCheckbox>
                    </div>
                    {formik?.values?.sales_commission_opted == 1 && (
                      <div className="col-8">
                        <span className="p-float-label">
                          <PFDropdown
                            id="sales_commission_criteria"
                            name="sales_commission_criteria"
                            options={SALES_COMMISSION_TYPE}
                            optionLabel="label"
                            optionValue="value"
                            onChange={(event, value) => {
                              formik.setFieldValue(event, value);
                              setBtnDisables(false);
                            }}
                            value={formik?.values?.sales_commission_criteria}
                          ></PFDropdown>
                          <label>
                            Sales Commission Criteria
                            <span className="text-red-500">*</span>
                          </label>
                        </span>
                      </div>
                    )}
                  </div>
                );
              }

              if (
                rowData.type ===
                ADDITIONAL_FEATURE_TYPE.ITEM_LINKAGE_CONFIGURATION
              ) {
                return (
                  <div className="grid flex-column md:flex-row md:align-items-center">
                    <div className="col-2">
                      <div className="flex align-items-center">
                        <PFCheckbox
                          id="EnabledOrDisabled"
                          name="EnabledOrDisabled"
                          onChange={e => {
                            formik.setFieldValue(
                              'project_linkage.enabled',
                              e?.checked
                            );
                            setBtnDisables(false);
                          }}
                          checked={
                            formik?.values?.project_linkage?.enabled || false
                          }
                        />
                      </div>
                    </div>
                    <div className="col-12 md:col-7">
                      <MultiSelect
                        value={formik?.values?.project_linkage?.options || []}
                        onChange={e => {
                          formik.setFieldValue(
                            'project_linkage.options',
                            e.value
                          );
                          setBtnDisables(false);
                        }}
                        options={ItemLinkageConfigOptions || []}
                        optionLabel="name"
                        placeholder="Set Default Configuration"
                        maxSelectedLabels={3}
                        className="w-full"
                        disabled={!formik?.values?.project_linkage?.enabled}
                      />
                    </div>
                    <div className="col-12 md:col-3">
                      <div className="flex align-items-center">
                        <PFCheckbox
                          id="ForceSync"
                          name="ForceSync"
                          onChange={e => {
                            formik.setFieldValue(
                              'project_linkage.force_sync',
                              e?.checked
                            );
                            setBtnDisables(false);
                          }}
                          checked={
                            formik?.values?.project_linkage?.force_sync || false
                          }
                          disabled={
                            forceSyncDisabled ||
                            !formik?.values?.project_linkage?.enabled
                          }
                        />
                        <label htmlFor="ForceSync" className="ml-2">
                          Force Sync
                        </label>
                      </div>
                    </div>
                  </div>
                );
              }
              if (
                rowData?.type === ADDITIONAL_FEATURE_TYPE.PROJECT_COLOR_CODE
              ) {
                return (
                  <PFButton
                    label="Configure"
                    size="small"
                    outlined
                    onClick={e => {
                      window.open(`/project-color-code`, '_blank');
                    }}
                  />
                );
              }
              if (
                rowData.type ===
                ADDITIONAL_FEATURE_TYPE.SKIP_TECHNICIAN_ASSIGNMENT_CLONING
              ) {
                return (
                  <div className="col-3 pl-0">
                    <PFCheckbox
                      id="skip_technician_assignment_cloning"
                      name="skip_technician_assignment_cloning"
                      checked={
                        formik.values.skip_technician_assignment_cloning == 1
                      }
                      onChange={event => {
                        formik.setFieldValue(
                          'skip_technician_assignment_cloning',
                          event.checked ? 1 : 0
                        );
                        setBtnDisables(false);
                      }}
                      className="pt-1"
                    ></PFCheckbox>
                  </div>
                );
              }

              if (
                rowData?.type === ADDITIONAL_FEATURE_TYPE.GROUP_SOURCE_CATEGORY
              ) {
                return (
                  <div className="col-12 py-0 px-0 flex">
                    <div className="col-3 pl-0">
                      <PFCheckbox
                        id="group_source_category_opted"
                        name="group_source_category_opted"
                        checked={formik.values.group_source_category_opted == 1}
                        onChange={event => {
                          formik.setFieldValue(
                            'group_source_category_opted',
                            event.checked ? 1 : 0
                          );
                          formik.handleSubmit();
                          setBtnDisables(false);
                        }}
                        className="pt-1"
                      ></PFCheckbox>
                    </div>
                    <div className="col-8">
                      <PFButton
                        label="Configure"
                        size="small"
                        outlined
                        onClick={e => {
                          window.open(
                            `/admin/configuration/group-source-category`,
                            '_blank'
                          );
                        }}
                        disabled={
                          groupSourceOpt == 0 ||
                          formik?.values?.group_source_category_opted == 0
                        }
                      />
                    </div>
                  </div>
                );
              }
              if (rowData?.type === ADDITIONAL_FEATURE_TYPE.SCHEDULER_CONFIG) {
                return (
                  <SchedulerConfig
                    formik={formik}
                    setBtnDisables={setBtnDisables}
                    btnDisables={btnDisables}
                  />
                );
              }
              if (
                rowData?.type ===
                  ADDITIONAL_FEATURE_TYPE.PERSONALIZED_EMAIL_CONFIG &&
                parseInt(client?.personalized_emails)
              ) {
                return (
                  <div className="col-12 py-0 px-0 flex">
                    <PFButton
                      label="Configure"
                      size="small"
                      outlined
                      onClick={e => {
                        window.open(
                          `/admin/configuration/personalized-emails`,
                          '_blank'
                        );
                      }}
                      disabled={formik?.values?.personalized_emails == 0}
                    />
                  </div>
                );
              }
              if (rowData?.type === ADDITIONAL_FEATURE_TYPE.RELATED_POS) {
                return (
                  <div className="col-8 pl-0">
                    <PFButton
                      label="Configure"
                      size="small"
                      outlined
                      onClick={e => {
                        window.open(
                          `/admin/company/configuration/related-po/edit/${client_id || pfClientsId}`,
                          '_blank'
                        );
                      }}
                    />
                  </div>
                );
              }

              if (rowData.type === ADDITIONAL_FEATURE_TYPE.FIXED_TOP_BAR) {
                return (
                  <div className="col-3 pl-0">
                    <PFCheckbox
                      id="fixed_top_bar"
                      name="fixed_top_bar"
                      checked={formik.values.fixed_top_bar == 1}
                      onChange={event => {
                        formik.setFieldValue(
                          'fixed_top_bar',
                          event.checked ? 1 : 0
                        );
                        setBtnDisables(false);
                      }}
                      className="pt-1"
                    ></PFCheckbox>
                  </div>
                );
              }
              if (rowData.type === ADDITIONAL_FEATURE_TYPE.FIXED_PROJECT_BAR) {
                return (
                  <div className="col-3 pl-0">
                    <PFCheckbox
                      id="fixed_project_bar"
                      name="fixed_project_bar"
                      checked={formik.values.fixed_project_bar == 1}
                      onChange={event => {
                        formik.setFieldValue(
                          'fixed_project_bar',
                          event.checked ? 1 : 0
                        );
                        setBtnDisables(false);
                      }}
                      className="pt-1"
                    ></PFCheckbox>
                  </div>
                );
              }

              if (
                rowData.type ===
                ADDITIONAL_FEATURE_TYPE.CHARGEBACK_APPROVAL_MODULE
              ) {
                return (
                  <div className="col-3 pl-0">
                    <PFCheckbox
                      id="chargeback_approval_module"
                      name="chargeback_approval_module"
                      checked={formik.values.chargeback_approval_module == 1}
                      onChange={event => {
                        formik.setFieldValue(
                          'chargeback_approval_module',
                          event.checked ? 1 : 0
                        );
                        setBtnDisables(false);
                      }}
                      className="pt-1"
                    ></PFCheckbox>
                  </div>
                );
              }
              if (
                rowData.type ===
                ADDITIONAL_FEATURE_TYPE.RESET_PASSWORD_EXPIRE_IN
              ) {
                return (
                  <div className="col-12 md:col-4 lg:col-4 mb-3 py-0">
                    <span className="p-float-label">
                      <PFDropdown
                        id="reset_password_in_days"
                        name="reset_password_in_days"
                        value={formik.values.reset_password_in_days}
                        options={RESET_PASSWORD_DAYS_OPTIONS}
                        optionValue="value"
                        onChange={(event, value) => {
                          formik.setFieldValue(event, value);
                          setBtnDisables(false);
                        }}
                        optionLabel="label"
                        placeholder="Select days"
                      />
                      <label htmlFor="reset_password_in_days">Days</label>
                    </span>
                    {formik.touched.reset_password_in_days &&
                    formik.errors.reset_password_in_days ? (
                      <div className="text-red-500 text-xs">
                        {formik.errors.reset_password_in_days}
                      </div>
                    ) : null}
                  </div>
                );
              }
              if (rowData.type === ADDITIONAL_FEATURE_TYPE.MFA_EXPIRE_IN_DAYS) {
                return (
                  <div className="col-12 md:col-4 lg:col-4 mb-3 py-0">
                    <span className="p-float-label">
                      <PFDropdown
                        id="mfa_expire_in_days"
                        name="mfa_expire_in_days"
                        value={formik.values.mfa_expire_in_days}
                        options={MFA_EXPIRE_IN_DAYS_OPTIONS}
                        optionValue="value"
                        onChange={(event, value) => {
                          formik.setFieldValue(event, value);
                          setBtnDisables(false);
                        }}
                        optionLabel="label"
                        placeholder="Select days"
                      />
                      <label htmlFor="mfa_expire_in_days">Days</label>
                    </span>
                    {formik.touched.mfa_expire_in_days &&
                    formik.errors.mfa_expire_in_days ? (
                      <div className="text-red-500 text-xs">
                        {formik.errors.mfa_expire_in_days}
                      </div>
                    ) : null}
                  </div>
                );
              }
              if (
                rowData.type === ADDITIONAL_FEATURE_TYPE.DOCUMENT_EXPIRE_IN_DAYS
              ) {
                return (
                  <div className="col-12 md:col-4 lg:col-4 mb-3 py-0">
                    <span className="p-float-label">
                      <PFDropdown
                        id="short_url_generate_in_days"
                        name="short_url_generate_in_days"
                        value={formik.values.short_url_generate_in_days}
                        options={DOCUMENT_EXPIRE_IN_DAYS_OPTIONS}
                        optionValue="value"
                        onChange={(event, value) => {
                          formik.setFieldValue(event, value);
                          setBtnDisables(false);
                        }}
                        optionLabel="label"
                        placeholder="Select days"
                      />
                      <label htmlFor="short_url_generate_in_days">Days</label>
                    </span>
                    {formik.touched.short_url_generate_in_days &&
                    formik.errors.short_url_generate_in_days ? (
                      <div className="text-red-500 text-xs">
                        {formik.errors.short_url_generate_in_days}
                      </div>
                    ) : null}
                  </div>
                );
              }
              if (
                rowData.type ===
                ADDITIONAL_FEATURE_TYPE.SHIPPING_AND_RECEIVING_MODULE
              ) {
                return (
                  <div className="col-3 pl-0">
                    <PFCheckbox
                      id="shipping_receiving"
                      name="shipping_receiving"
                      checked={formik.values.shipping_receiving == 1}
                      onChange={event => {
                        formik.setFieldValue(
                          'shipping_receiving',
                          event.checked ? 1 : 0
                        );
                        setBtnDisables(false);
                      }}
                      className="pt-1"
                    ></PFCheckbox>
                  </div>
                );
              }
              if (
                rowData.type === ADDITIONAL_FEATURE_TYPE.HIGHLIGHT_STORE_NOTES
              ) {
                return (
                  <div className="col-3 pl-0">
                    <PFCheckbox
                      id="highlight_store_notes"
                      name="highlight_store_notes"
                      checked={formik.values.highlight_store_notes == 1}
                      onChange={event => {
                        formik.setFieldValue(
                          'highlight_store_notes',
                          event.checked ? 1 : 0
                        );
                        setBtnDisables(false);
                      }}
                      className="pt-1"
                    ></PFCheckbox>
                  </div>
                );
              }
              if (rowData.type === ADDITIONAL_FEATURE_TYPE.COPY_TOTAL_SALE) {
                return (
                  <div className="col-3 pl-0">
                    <PFCheckbox
                      id="copy_total_sales"
                      name="copy_total_sales"
                      checked={formik.values.copy_total_sales == 1}
                      onChange={event => {
                        formik.setFieldValue(
                          'copy_total_sales',
                          event.checked ? 1 : 0
                        );
                        setBtnDisables(false);
                      }}
                      className="pt-1"
                    ></PFCheckbox>
                  </div>
                );
              }
              if (
                rowData.type ===
                ADDITIONAL_FEATURE_TYPE.DO_NOT_OVERRIDE_LABOR_COST
              ) {
                return (
                  <div className="col-3 pl-0">
                    <PFCheckbox
                      id="do_not_override_labor_cost"
                      name="do_not_override_labor_cost"
                      checked={formik.values.do_not_override_labor_cost == 1}
                      onChange={event => {
                        formik.setFieldValue(
                          'do_not_override_labor_cost',
                          event.checked ? 1 : 0
                        );
                        setBtnDisables(false);
                      }}
                      className="pt-1"
                    ></PFCheckbox>
                  </div>
                );
              }
              if (
                rowData.type === ADDITIONAL_FEATURE_TYPE.EPA_GUARDRAILS_WORKFLOW
              ) {
                return (
                  <div className="col-3 pl-0">
                    <PFCheckbox
                      id="epa_guardrails_workflow"
                      name="epa_guardrails_workflow"
                      checked={
                        formik.values.epa_guardrails_workflow == 1
                          ? true
                          : false
                      }
                      onChange={event => {
                        formik.setFieldValue(
                          'epa_guardrails_workflow',
                          event.checked ? 1 : 0
                        );
                        setBtnDisables(false);
                      }}
                      className="pt-1"
                    ></PFCheckbox>
                  </div>
                );
              }
              if (
                rowData.type ===
                ADDITIONAL_FEATURE_TYPE.DOCUMENT_AUTO_PULL_RETRY
              ) {
                return (
                  <div className="grid flex align-items-center">
                    <div className="col-3">
                      <div className="flex align-items-center">
                        <PFCheckbox
                          id="document_auto_pull_retry"
                          name="document_auto_pull_retry"
                          onChange={e => {
                            formik.setFieldValue(
                              'document_auto_pull_retry.enabled',
                              e?.checked ? 1 : 0
                            );
                            setBtnDisables(false);
                          }}
                          checked={
                            formik?.values?.document_auto_pull_retry
                              ?.enabled === 1
                          }
                          tooltip="This feature will pull docs for Lowes source system"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <MultiSelect
                        value={
                          formik?.values?.document_auto_pull_retry?.options ||
                          []
                        }
                        onChange={e => {
                          formik.setFieldValue(
                            'document_auto_pull_retry.options',
                            e.value
                          );
                          setBtnDisables(false);
                        }}
                        filter
                        options={categoryOptions || []}
                        optionLabel="name"
                        placeholder="Set Category"
                        maxSelectedLabels={3}
                        className="w-full md:w-20rem"
                        disabled={
                          !formik?.values?.document_auto_pull_retry?.enabled
                        }
                      />
                    </div>
                  </div>
                );
              }

              if (
                rowData.type ===
                ADDITIONAL_FEATURE_TYPE.SHOW_LINE_ITEMS_ON_DOCUMENTS
              ) {
                return (
                  <div className="col-3 pl-0">
                    <PFCheckbox
                      id="show_line_items_on_documents"
                      name="show_line_items_on_documents"
                      checked={formik.values.show_line_items_on_documents == 1}
                      onChange={event => {
                        formik.setFieldValue(
                          'show_line_items_on_documents',
                          event.checked ? 1 : 0
                        );
                        setBtnDisables(false);
                      }}
                      className="pt-1"
                    ></PFCheckbox>
                  </div>
                );
              }

              if (
                rowData.type ===
                ADDITIONAL_FEATURE_TYPE.ASSIGN_TECHNICIAN_ON_REVENUE_EXCLUDED_ITEMS
              ) {
                return (
                  <div className="col-3 pl-0">
                    <PFCheckbox
                      id="assign_technician_on_revenue_excluded_items"
                      name="assign_technician_on_revenue_excluded_items"
                      checked={
                        formik.values
                          .assign_technician_on_revenue_excluded_items == 1
                      }
                      onChange={event => {
                        formik.setFieldValue(
                          'assign_technician_on_revenue_excluded_items',
                          event.checked ? 1 : 0
                        );
                        setBtnDisables(false);
                      }}
                      className="pt-1"
                    ></PFCheckbox>
                  </div>
                );
              }

              if (rowData.type === ADDITIONAL_FEATURE_TYPE.PROJECT_NUMBER) {
                return (
                  <div className="grid flex align-items-center mt-1">
                    <div className="col-3">
                      <PFCheckbox
                        id="is_personalized_private_project"
                        name="is_personalized_private_project"
                        onChange={e => {
                          formik.setFieldValue(
                            'is_personalized_private_project',
                            e?.checked ? 1 : 0
                          );
                          if (!e.checked) {
                            formik.setFieldValue(
                              'personalized_private_project_number',
                              responseData?.personalized_private_project_number >
                                0
                                ? responseData?.personalized_private_project_number
                                : '0'
                            );
                          }

                          setBtnDisables(false);
                        }}
                        checked={
                          formik?.values?.is_personalized_private_project === 1
                        }
                        disabled={
                          responseData?.is_personalized_private_project > 0
                        }
                      />
                    </div>
                    <div className="col-6 p-inputgroup height-40 p-0 mt-2">
                      <span className="p-inputgroup-addon">#</span>
                      <InputNumber
                        id="personalized_private_project_number"
                        className="w-12"
                        place
                        value={
                          formik?.values?.personalized_private_project_number
                        }
                        onValueChange={e => {
                          formik.setFieldValue(
                            'personalized_private_project_number',
                            e.value ? Number(e.value) : ''
                          );
                          setBtnDisables(false);
                        }}
                        maxLength={7}
                        minLength={3}
                        useGrouping={false}
                        disabled={
                          responseData?.personalized_private_project_number >
                            0 ||
                          responseData?.is_personalized_private_project > 0 ||
                          !formik?.values?.is_personalized_private_project
                        }
                      />
                    </div>
                    {formik?.values?.is_personalized_private_project &&
                    formik.touched.personalized_private_project_number &&
                    formik.errors.personalized_private_project_number ? (
                      <div className="col-9 col-offset-3 text-red-500 text-xs">
                        {formik.errors.personalized_private_project_number}
                      </div>
                    ) : null}
                  </div>
                );
              }

              if (
                rowData.type ===
                ADDITIONAL_FEATURE_TYPE.SEND_TENTATIVE_SCHEDULE_NOTE_TO_SOURCE
              ) {
                return (
                  <div className="col-3 pl-0">
                    <PFCheckbox
                      id="send_tentative_schedule_note_to_source"
                      name="send_tentative_schedule_note_to_source"
                      checked={
                        formik.values.send_tentative_schedule_note_to_source ==
                        1
                      }
                      onChange={event => {
                        formik.setFieldValue(
                          'send_tentative_schedule_note_to_source',
                          event.checked ? 1 : 0
                        );
                        setBtnDisables(false);
                      }}
                      className="pt-1"
                    ></PFCheckbox>
                  </div>
                );
              }

              return (
                <PFCheckbox
                  inputId={`checkbox_${rowData.key}`}
                  onChange={e => {
                    handleCheckbox(rowData.key, e.checked);
                    setBtnDisables(false);
                  }}
                  checked={rowData.checked}
                />
              );
            },
          }
        : {}),
    },
  ];

  const featureTableLoader = useMemo(() => {
    return TableColumnsLoader(featureColumnsData, {
      rows: 4,
      isValue: false,
    });
  }, [featureColumnsData]);

  return (
    <div className="w-full">
      <Dialog
        header="Delete Confirmation"
        visible={showConfirmation}
        style={{ width: '50vw' }}
        onHide={() => setShowConfirmation(false)}
        footer={footerContent}
        draggable={false}
      >
        <p className="m-0">Are you sure you want to remove Logo?</p>
      </Dialog>
      <div className="flex justify-content-end">
        {pfClientsId === PF_ADMIN ? (
          <div className="grid w-12 flex justify-content-between align-items-center  overlay-x-hidden">
            <PageHeader
              pageTitle={`Edit ${clientName} Configuration`}
              breadCrumbArray={accessBreadcrumb}
            />
          </div>
        ) : null}
        <div className="ml-2">
          <PFButton
            label="Update"
            size="small"
            severity="primary"
            className="mx-2"
            onClick={formik.handleSubmit}
            disabled={
              btnDisables || (allocationOption && allocationPercentage === 0)
            }
          />
        </div>
      </div>
      <Toast ref={toast} />
      <div className="mt-4">
        <Card title="Basic Details" className="mb-3">
          <div className="grid">
            <div className="col-12 xl:col-3 ">
              {loading ? (
                <Skeleton className="sm:w-4 lg:w-8 ml-6" height="14rem" />
              ) : (
                <Image
                  src={
                    binaryImage ||
                    formik?.values?.logo ||
                    'https://placehold.jp/14/d1d5db/666/100x100.png?text=No Image'
                  }
                  alt="Tenant Logo"
                  preview={formik?.values?.logo ? true : false}
                  pt={{
                    image: {
                      className: 'sm:w-4 lg:w-8',
                    },
                    root: {
                      className: 'flex justify-content-center',
                    },
                  }}
                />
              )}
              <div className="col-12 flex justify-content-center px-0">
                {loading ? (
                  <>
                    <Skeleton className="sm:w-2 lg:w-5" height="40px" />
                    <Skeleton className="sm:w-2 lg:w-5 ml-2 " height="40px" />
                  </>
                ) : (
                  <div className="grid my-2">
                    <div className="col-12  lg:col-6 mb-3 py-0 pl-0">
                      <PFButton
                        label="Remove"
                        className=" mx-2 w-full"
                        icon="pi pi-trash mr-1"
                        text
                        outlined
                        onClick={() => setShowConfirmation(true)}
                        disabled={formik?.values?.logo === null}
                      />
                    </div>
                    <div className="col-12 lg:col-6 mb-3 py-0 pr-0">
                      <FileUpload
                        ref={fileUploadRef}
                        mode="basic"
                        accept="image/jpg, image/png, image/jpeg, image/JFIF"
                        name="logo"
                        customUpload
                        auto
                        chooseLabel={'Choose'}
                        chooseOptions={{
                          icon: <i className="pi pi-upload mr-2"></i>,
                          label: null,
                        }}
                        onSelect={e => {
                          if (e?.files && e?.files[0]) {
                            if (ImageValidation(e.files[0].type)) {
                              setBinaryImage(e.files[0].objectURL);
                              formik.setFieldValue('logo', e.files[0]);
                              setBtnDisables(false);
                            } else {
                              toast.current.show({
                                severity: 'error',
                                summary: 'Error',
                                detail:
                                  'Uploaded File Type is not supported. Please try with extension .jpg, .jpeg, .PNG, .JFIF',
                                life: 3000,
                              });
                            }
                          }
                          fileUploadRef.current?.clear();
                        }}
                        pt={{
                          basicbutton: {
                            className: 'p-button-sm p-button-primary w-full ',
                          },
                          label: {
                            className: 'font-sm',
                          },
                          basicbutton: {
                            className: 'height-40',
                          },
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="col-12">
                <div className="grid pl-3 mb-10">
                  <div className="col-12">
                    <Link
                      to={`/admin/company/business-hour-config/edit/${client_id || pfClientsId}`}
                      target="_blank"
                      className="no-underline text-700 hover:text-blue-700"
                    >
                      <span className="p-float-label flex align-items-center gap-2">
                        <i className="pi pi-clock"></i>
                        <span>Business Hours</span>
                      </span>
                    </Link>
                  </div>
                  {!!formik?.values?.cx_scheduling_module && (
                    <div className="col-12">
                      <Link
                        to={`/admin/company/cx-scheduling-config/edit/${
                          client_id || pfClientsId
                        }`}
                        target="_blank"
                        className="no-underline text-700 hover:text-blue-700"
                      >
                        <span className="p-float-label flex align-items-center gap-2">
                          <i className="pi pi-clock"></i>
                          <span>Customer Scheduling</span>
                        </span>
                      </Link>
                    </div>
                  )}
                  <div className="col-12">
                    <Link
                      to={`/admin/company/service-time-config/edit/${client_id || pfClientsId}`}
                      target="_blank"
                      className="no-underline text-700 hover:text-blue-700"
                    >
                      <span className="p-float-label flex align-items-center gap-2">
                        <i className="pi pi-clock"></i>
                        <span>Service Time</span>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 xl:col-9">
              <div className="grid mb-2">
                <div className="col-12 md:col-4 lg:col-4 mb-3  py-0">
                  {loading ? (
                    <Skeleton className="w-full" height="40px" />
                  ) : (
                    <span className="p-float-label  ">
                      <PFInputText
                        id="client_id"
                        className="w-12"
                        value={formik.values.client_id}
                        disabled
                      />
                      <label htmlFor="tenant_id">Client ID</label>
                    </span>
                  )}
                </div>
                <div className="col-12 md:col-8 lg:col-8 mb-3 py-0">
                  {loading ? (
                    <Skeleton className="w-full" height="40px" />
                  ) : (
                    <span className="p-float-label ">
                      <PFInputText
                        id="client_name"
                        className="w-12"
                        value={
                          formik.values.client_name ||
                          (pfClientsId !== PF_ADMIN ? 'Not Available' : '')
                        }
                        onChange={event => {
                          formik.setFieldValue(
                            'client_name',
                            event.target.value
                          );
                          setBtnDisables(false);
                        }}
                        disabled={pfClientsId !== PF_ADMIN}
                      />
                      <label htmlFor="tenant_name">Client Name</label>
                    </span>
                  )}
                  {formik.touched.client_name && formik.errors.client_name ? (
                    <div className="text-red-500 text-xs">
                      {formik.errors.client_name}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="grid  mb-2">
                <div className="col-12 md:col-4 lg:col-4 mb-3 py-0">
                  {loading ? (
                    <Skeleton className="w-full" height="40px" />
                  ) : (
                    <span className="p-float-label   ">
                      <InputMask
                        id="phone_number"
                        mask="(999) 999-9999"
                        className="w-12"
                        value={
                          formik.values.phone_number ||
                          (pfClientsId !== PF_ADMIN ? 'Not Available' : '')
                        }
                        onChange={event => {
                          formik.setFieldValue(
                            'phone_number',
                            event.target.value
                          );
                          setBtnDisables(false);
                        }}
                        disabled={pfClientsId !== PF_ADMIN}
                      />
                      <label htmlFor="phone_number">Phone Number</label>
                    </span>
                  )}
                </div>
                <div className="col-12 md:col-4 lg:col-4 mb-3 py-0">
                  {loading ? (
                    <Skeleton className="w-full" height="40px" />
                  ) : (
                    <span className="p-float-label">
                      <PFInputText
                        id="email"
                        className="w-12 h-5"
                        value={
                          formik.values.email ||
                          (pfClientsId !== PF_ADMIN ? 'Not Available' : '')
                        }
                        onChange={event => {
                          formik.setFieldValue('email', event.target.value);
                          setBtnDisables(false);
                        }}
                        disabled={pfClientsId !== PF_ADMIN}
                      />
                      <label htmlFor="email">Email</label>
                    </span>
                  )}
                </div>
                <div className="col-12 md:col-4 lg:col-4 mb-3 py-0">
                  {loading ? (
                    <Skeleton className="w-full" height="40px" />
                  ) : (
                    <span className="p-float-label">
                      <InputMask
                        id="fax"
                        mask="999-999-9999"
                        className="w-12"
                        value={
                          formik.values.fax ||
                          (pfClientsId !== PF_ADMIN ? 'Not Available' : '')
                        }
                        onChange={event => {
                          formik.setFieldValue('fax', event.target.value);
                          setBtnDisables(false);
                        }}
                        disabled={pfClientsId !== PF_ADMIN}
                      />
                      <label htmlFor="fax">Fax</label>
                    </span>
                  )}
                </div>
              </div>
              <div className="grid mb-2">
                <div className="col-12 md:col-4 lg:col-4 mb-3 py-0">
                  {loading ? (
                    <Skeleton className="w-full" height="40px" />
                  ) : (
                    <span className="p-float-label">
                      <PFInputText
                        id="contact_name"
                        className="w-12"
                        value={
                          formik.values.contact_name ||
                          (pfClientsId !== PF_ADMIN ? 'Not Available' : '')
                        }
                        onChange={event => {
                          formik.setFieldValue(
                            'contact_name',
                            event.target.value
                          );
                          setBtnDisables(false);
                        }}
                        disabled={pfClientsId !== PF_ADMIN}
                      />
                      <label htmlFor="contact_name">Contact Name</label>
                    </span>
                  )}
                  {formik.touched.contact_name && formik.errors.contact_name ? (
                    <div className="text-red-500 text-xs">
                      {formik.errors.contact_name}
                    </div>
                  ) : null}
                </div>
                <div className="col-12 md:col-4 lg:col-4 mb-3 py-0">
                  {loading ? (
                    <Skeleton className="w-full" height="40px" />
                  ) : (
                    <span className="p-float-label">
                      <PFInputText
                        id="contact_email"
                        className="w-12"
                        value={
                          formik.values.contact_email ||
                          (pfClientsId !== PF_ADMIN ? 'Not Available' : '')
                        }
                        onChange={event => {
                          formik.setFieldValue(
                            'contact_email',
                            event.target.value
                          );
                          setBtnDisables(false);
                        }}
                        disabled={pfClientsId !== PF_ADMIN}
                      />
                      <label htmlFor="contact_email">Contact Email</label>
                    </span>
                  )}
                  {formik.touched.contact_email &&
                  formik.errors.contact_email ? (
                    <div className="text-red-500 text-xs">
                      {formik.errors.contact_email}
                    </div>
                  ) : null}
                </div>
                <div className="col-12 md:col-4 lg:col-4 mb-3 py-0">
                  {loading ? (
                    <Skeleton className="w-full" height="40px" />
                  ) : (
                    <span className="p-float-label">
                      <InputMask
                        id="contact_phone_number"
                        className="w-12"
                        mask="(999) 999-9999"
                        value={
                          formik.values.contact_phone_number ||
                          (pfClientsId !== PF_ADMIN ? 'Not Available' : '')
                        }
                        onChange={event => {
                          formik.setFieldValue(
                            'contact_phone_number',
                            event.target.value
                          );
                          setBtnDisables(false);
                        }}
                        disabled={pfClientsId !== PF_ADMIN}
                      />
                      <label htmlFor="contact_phone_number">
                        Contact Phone Number
                      </label>
                    </span>
                  )}
                </div>
              </div>
              <div className="grid ">
                <div className="col-12 md:col-4 lg:col-4 mb-3 py-0">
                  {loading ? (
                    <Skeleton className="w-full" height="40px" />
                  ) : (
                    <span className="p-float-label">
                      {gMapFieldLayout({ disabled: pfClientsId !== PF_ADMIN })}
                    </span>
                  )}
                </div>
                <div className="col-12 md:col-4 lg:col-4 mb-3 py-0">
                  {loading ? (
                    <Skeleton className="w-full" height="40px" />
                  ) : (
                    <span className="p-float-label">
                      <PFInputText
                        id="address1"
                        className="w-12"
                        value={
                          formik.values.address1 ||
                          (pfClientsId !== PF_ADMIN ? 'Not Available' : '')
                        }
                        onChange={event => {
                          formik.setFieldValue('address1', event.target.value);
                          setBtnDisables(false);
                        }}
                        disabled={pfClientsId !== PF_ADMIN}
                      />
                      <label htmlFor="address1">Address 1</label>
                    </span>
                  )}
                </div>
                <div className="col-12 md:col-4 lg:col-4 mb-3 py-0">
                  {loading ? (
                    <Skeleton className="w-full" height="40px" />
                  ) : (
                    <span className="p-float-label">
                      <PFInputText
                        id="address2"
                        className="w-12"
                        value={
                          formik.values.address2 ||
                          (pfClientsId !== PF_ADMIN ? 'Not Available' : '')
                        }
                        onChange={event => {
                          formik.setFieldValue('address2', event.target.value);
                          setBtnDisables(false);
                        }}
                        disabled={pfClientsId !== PF_ADMIN}
                      />
                      <label htmlFor="address2">Address 2</label>
                    </span>
                  )}
                </div>
                <div className="col-12 md:col-4 lg:col-4 mb-3 py-0">
                  {loading ? (
                    <Skeleton className="w-full" height="40px" />
                  ) : (
                    <span className="p-float-label">
                      <PFInputText
                        id="city"
                        className="w-12"
                        value={
                          formik.values.city ||
                          (pfClientsId !== PF_ADMIN ? 'Not Available' : '')
                        }
                        onChange={event => {
                          formik.setFieldValue('city', event.target.value);
                          setBtnDisables(false);
                        }}
                        disabled={pfClientsId !== PF_ADMIN}
                      />

                      <label htmlFor="city">City</label>
                    </span>
                  )}
                </div>
                <div className="col-12 md:col-4 lg:col-4 mb-3 py-0">
                  {loading ? (
                    <Skeleton className="w-full" height="40px" />
                  ) : (
                    <span className="p-float-label">
                      <PFInputText
                        id="state"
                        className="w-12"
                        value={
                          formik.values.state ||
                          (pfClientsId !== PF_ADMIN ? 'Not Available' : '')
                        }
                        onChange={event => {
                          formik.setFieldValue('state', event.target.value);
                          setBtnDisables(false);
                        }}
                        disabled={pfClientsId !== PF_ADMIN}
                      />
                      <label htmlFor="state">State</label>
                    </span>
                  )}
                </div>
                <div className="col-12 md:col-4 lg:col-4 mb-3 py-0">
                  {loading ? (
                    <Skeleton className="w-full" height="40px" />
                  ) : (
                    <span className="p-float-label">
                      <PFInputText
                        id="zipcode"
                        className="w-12"
                        value={
                          formik.values.zipcode ||
                          (pfClientsId !== PF_ADMIN ? 'Not Available' : '')
                        }
                        onChange={event => {
                          formik.setFieldValue('zipcode', event.target.value);
                          setBtnDisables(false);
                        }}
                        disabled={pfClientsId !== PF_ADMIN}
                      />
                      <label htmlFor="zipcode">ZipCode</label>
                      <label htmlFor="zipcode">ZipCode</label>
                    </span>
                  )}
                </div>
                <div className="col-12 md:col-4 lg:col-4 mb-3 py-0">
                  {loading ? (
                    <Skeleton className="w-full" height="40px" />
                  ) : (
                    <span className="p-float-label">
                      <PFInputText
                        id="latitude"
                        className="w-12"
                        type="number"
                        value={formik.values?.latitude || ''}
                        onChange={event => {
                          formik.setFieldValue('latitude', event.target.value);
                          setBtnDisables(false);
                        }}
                      />
                      <label htmlFor="latitude">Latitude</label>
                    </span>
                  )}
                </div>
                <div className="col-12 md:col-4 lg:col-4 mb-3 py-0">
                  {loading ? (
                    <Skeleton className="w-full" height="40px" />
                  ) : (
                    <span className="p-float-label">
                      <PFInputText
                        id="longitude"
                        className="w-12"
                        type="number"
                        value={formik.values?.longitude || ''}
                        onChange={event => {
                          formik.setFieldValue('longitude', event.target.value);
                          setBtnDisables(false);
                        }}
                      />
                      <label htmlFor="longitude">Longitude</label>
                    </span>
                  )}
                </div>
                <div className="col-12 md:col-4 lg:col-4 mb-3 py-0">
                  {loading ? (
                    <Skeleton className="w-full" height="40px" />
                  ) : (
                    <span className="p-float-label">
                      <PFDropdown
                        id="client_timezone"
                        name="client_timezone"
                        value={
                          formik.values.client_timezone ||
                          (pfClientsId !== PF_ADMIN ? 'Not Available' : '')
                        }
                        options={timeZones}
                        optionValue="value"
                        onChange={(event, value) => {
                          formik.setFieldValue(event, value);
                          <span className="p-float-label">
                            <PFDropdown
                              id="client_timezone"
                              name="client_timezone"
                              value={
                                formik.values.client_timezone ||
                                (pfClientsId !== PF_ADMIN
                                  ? 'Not Available'
                                  : '')
                              }
                              options={timeZones}
                              optionValue="value"
                              onChange={(event, value) => (
                                formik.setFieldValue(event, value),
                                setBtnDisables(false)
                              )}
                              optionLabel="label"
                              placeholder="Select a Time Zone"
                              disabled={pfClientsId !== PF_ADMIN}
                            />
                            <label htmlFor="client_timezone">Timezone</label>
                          </span>;
                        }}
                        optionLabel="label"
                        placeholder="Select a Time Zone"
                        disabled={pfClientsId !== PF_ADMIN}
                      />
                      <label htmlFor="client_timezone">Timezone</label>
                    </span>
                  )}
                  {formik.touched.client_timezone &&
                  formik.errors.client_timezone ? (
                    <div className="text-red-500 text-xs">
                      {formik.errors.client_timezone}
                    </div>
                  ) : null}
                </div>
                <div className="col-12 md:col-4 lg:col-4 mb-3 py-0">
                  {loading ? (
                    <Skeleton className="w-full" height="40px" />
                  ) : (
                    <span className="p-float-label">
                      <PFInputText
                        id="quickbooks_refresh_token"
                        className="w-12"
                        value={
                          formik.values.quickbooks_refresh_token ||
                          (pfClientsId !== PF_ADMIN ? 'Not Available' : '')
                        }
                        onChange={event => {
                          formik.setFieldValue(
                            'quickbooks_refresh_token',
                            event.target.value
                          );
                          setBtnDisables(false);
                        }}
                        disabled={pfClientsId !== PF_ADMIN}
                      />
                      <label htmlFor="quickbooks_refresh_token">
                        QuickBooks Refresh Token
                      </label>
                    </span>
                  )}
                </div>

                <div className="col-12 md:col-4 lg:col-4 mb-3 py-0">
                  {loading ? (
                    <Skeleton className="w-full" height="40px" />
                  ) : (
                    <span className="p-float-label">
                      <PFInputText
                        id="installer_number_lowes"
                        className="w-12"
                        value={
                          formik.values.installer_number_lowes ||
                          (pfClientsId !== PF_ADMIN ? 'Not Available' : '')
                        }
                        onChange={event => {
                          formik.setFieldValue(
                            'installer_number_lowes',
                            event.target.value
                          );
                          setBtnDisables(false);
                        }}
                        maxLength={10}
                        disabled={pfClientsId !== PF_ADMIN}
                      />
                      <label htmlFor="installer_number_lowes">
                        Lowes Vendor number
                      </label>
                    </span>
                  )}
                </div>

                <div className="col-12 md:col-4 lg:col-4 mb-3 py-0">
                  {loading ? (
                    <Skeleton className="w-full" height="40px" />
                  ) : (
                    <span className="p-float-label">
                      <PFInputText
                        id="admin_email"
                        className="w-12"
                        value={formik.values.admin_email}
                        onChange={event => {
                          formik.setFieldValue(
                            'admin_email',
                            event.target.value
                          );
                          setBtnDisables(false);
                        }}
                      />
                      <label htmlFor="installer_number_lowes">
                        CX Support Email
                      </label>
                    </span>
                  )}
                  {formik.touched.admin_email && formik.errors.admin_email ? (
                    <div className="text-red-500 text-xs">
                      {formik.errors.admin_email}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </Card>

        {hasOtherSourceSystemIds ? (
          <Card title="Lowes EPA Details" className="mb-3">
            <LrrpConfiguration
              onDataChange={handleLrrpDataChange}
              formik={formik}
              responseData={responseData}
              setBtnDisables={setBtnDisables}
            />
          </Card>
        ) : (
          ''
        )}
        {hasSourceSystemIdTwo ? (
          <Card title="Home Depot EPA Details" className="mb-3">
            <EditHomeDepotConfig
              formik={formik}
              setBtnDisables={setBtnDisables}
            />
          </Card>
        ) : (
          ''
        )}

        <Card title="Additional Features" className="mb-3">
          <div className="grid">
            <div className="col-12 md:col-12 lg:col-12 mb-2 ">
              <DataTable
                value={
                  loading
                    ? featureTableLoader
                    : [
                        ...featureList,
                        {
                          type: ADDITIONAL_FEATURE_TYPE.ALLOCATION_BEHAVIOUR,
                          label: 'Set Default Allocation Behaviour',
                        },
                        {
                          type: ADDITIONAL_FEATURE_TYPE.ITEM_LINKAGE_CONFIGURATION,
                          label: 'Project Linkage Module',
                        },
                        {
                          type: ADDITIONAL_FEATURE_TYPE.SALES_COMMISSION_OPTED,
                          label: 'Sales Commission Opted',
                        },
                        {
                          type: ADDITIONAL_FEATURE_TYPE.PROJECT_COLOR_CODE,
                          label: 'Color Code',
                        },
                        {
                          type: ADDITIONAL_FEATURE_TYPE.SKIP_TECHNICIAN_ASSIGNMENT_CLONING,
                          label: 'Skip Technician Assignment while Cloning',
                        },
                        {
                          type: ADDITIONAL_FEATURE_TYPE.GROUP_SOURCE_CATEGORY,
                          label: 'Group Source Category',
                        },
                        {
                          type: ADDITIONAL_FEATURE_TYPE.SCHEDULER_CONFIG,
                          label: 'Scheduler',
                        },
                        {
                          type: ADDITIONAL_FEATURE_TYPE.RELATED_POS,
                          label: 'Related POs',
                        },

                        {
                          type: ADDITIONAL_FEATURE_TYPE.FIXED_TOP_BAR,
                          label: 'Lock Global Bar',
                        },
                        {
                          type: ADDITIONAL_FEATURE_TYPE.FIXED_PROJECT_BAR,
                          label: 'Lock Project Details Bar',
                        },
                        {
                          type: ADDITIONAL_FEATURE_TYPE.CHARGEBACK_APPROVAL_MODULE,
                          label: 'Chargeback Approval Module',
                        },
                        {
                          type: ADDITIONAL_FEATURE_TYPE.RESET_PASSWORD_EXPIRE_IN,
                          label: 'Reset Password In Days',
                        },
                        {
                          type: ADDITIONAL_FEATURE_TYPE.MFA_EXPIRE_IN_DAYS,
                          label: 'MFA expire In Days',
                        },
                        {
                          type: ADDITIONAL_FEATURE_TYPE.DOCUMENT_EXPIRE_IN_DAYS,
                          label: 'Document expire In Days',
                        },
                        {
                          type: ADDITIONAL_FEATURE_TYPE.SHIPPING_AND_RECEIVING_MODULE,
                          label: 'Shipping and Receiving',
                        },
                        {
                          type: ADDITIONAL_FEATURE_TYPE.HIGHLIGHT_STORE_NOTES,
                          label: 'Highlight Store Notes',
                        },
                        {
                          type: ADDITIONAL_FEATURE_TYPE.COPY_TOTAL_SALE,
                          label: 'Copy Total Sales',
                        },
                        {
                          type: ADDITIONAL_FEATURE_TYPE.DO_NOT_OVERRIDE_LABOR_COST,
                          label: 'Do Not Override Labor Cost',
                        },
                        {
                          type: ADDITIONAL_FEATURE_TYPE.EPA_GUARDRAILS_WORKFLOW,
                          label: 'EPA Guardrails Workflow',
                        },
                        {
                          type: ADDITIONAL_FEATURE_TYPE.DOCUMENT_AUTO_PULL_RETRY,
                          label: 'Document Auto Pull and Retry',
                        },
                        {
                          type: ADDITIONAL_FEATURE_TYPE.SHOW_LINE_ITEMS_ON_DOCUMENTS,
                          label: 'Show Line Items On Documents',
                        },
                        {
                          type: ADDITIONAL_FEATURE_TYPE.PROJECT_NUMBER,
                          label: 'Personalized Private Project #',
                        },
                        ...(parseInt(client?.personalized_emails)
                          ? [
                              {
                                type: ADDITIONAL_FEATURE_TYPE.PERSONALIZED_EMAIL_CONFIG,
                                label: 'Personalized Emails',
                              },
                            ]
                          : []),
                        {
                          type: ADDITIONAL_FEATURE_TYPE.SEND_TENTATIVE_SCHEDULE_NOTE_TO_SOURCE,
                          label: 'Send Tentative Schedule Note to Source',
                        },
                        {
                          type: ADDITIONAL_FEATURE_TYPE.ASSIGN_TECHNICIAN_ON_REVENUE_EXCLUDED_ITEMS,
                          label: 'Assign Technician On Revenue Excluded Items',
                        },
                      ]
                }
                className="p-datatable-striped"
              >
                {featureColumnsData?.map((col, index) => (
                  <Column key={index} {...col} />
                ))}
              </DataTable>
            </div>
          </div>
        </Card>
        {(pfClientsId === PF_ADMIN || formik.values.crm_module == 1) && (
          <Card title="Payment configuration" className="mb-3">
            <div className="grid">
              <div className="col-12 md:col-4 lg:col-4 mb-3 py-0 flex align-items-center">
                {loading ? (
                  <Skeleton className="w-2" height="30px" />
                ) : (
                  <PFInputSwitch
                    name="pf-Inclusive"
                    size="small"
                    onChange={e => {
                      formik.setFieldValue('is_line_item_visible', e.value);
                      setBtnDisables(false);
                    }}
                    checked={formik.values?.is_line_item_visible ? true : false}
                    className="align-items-center mx-2"
                  />
                )}
                <span className="p-float-label">
                  Show line items to customer
                </span>
              </div>

              <div className="col-12 md:col-4 lg:col-6 mb-3 py-0 flex align-items-center justify-content-center ">
                <label htmlFor="due_days" className="w-12 text-right mr-2">
                  Payment Due Days:
                </label>
                {loading ? (
                  <Skeleton className="w-full" height="40px" />
                ) : (
                  <PFInputNumber
                    id="due_days"
                    className="w-12"
                    value={formik.values.due_days}
                    onChange={(event, value) => {
                      formik.setFieldValue('due_days', value);
                      setBtnDisables(false);
                    }}
                  />
                )}
              </div>
            </div>
          </Card>
        )}

        {pfClientsId === PF_ADMIN && (
          <Card Card title="Module" className="mb-2 ">
            <div className="ml-2">
              <div className="col-12  md:col-12 lg:col-12">
                <div className="grid">
                  <div className="col-3 md:col-3 lg:col-3">
                    <div className="grid">
                      <div className="col-2 md:col-2 lg:col-2">
                        <PFCheckbox
                          id="route_optimization"
                          name="route_optimization"
                          checked={formik.values.routing_module == 1}
                          onChange={event => {
                            formik.setFieldValue(
                              'routing_module',
                              event.checked ? 1 : 0
                            );
                            setBtnDisables(false);
                          }}
                        />
                      </div>
                      <div className="col-8 md:col-8 lg:col-8">
                        Route Optimization
                      </div>
                    </div>
                  </div>

                  <div className="col-3 md:col-3 lg:col-3">
                    <div className="grid">
                      <div className="col-2 md:col-2 lg:col-2">
                        <PFCheckbox
                          id="crm"
                          name="crm"
                          checked={formik.values.crm_module == 1}
                          onChange={event => {
                            formik.setFieldValue(
                              'crm_module',
                              event.checked ? 1 : 0
                            );
                            setBtnDisables(false);
                          }}
                        />
                      </div>
                      <div className="col-5 md:col-3 lg:col-10">CRM</div>
                    </div>
                  </div>

                  <div className="col-3 md:col-3 lg:col-3">
                    <div className="grid">
                      <div className="col-2 md:col-2 lg:col-2">
                        <PFCheckbox
                          id="warranty"
                          name="warranty"
                          checked={formik.values.warranty_module == 1}
                          onChange={event => {
                            formik.setFieldValue(
                              'warranty_module',
                              event.checked ? 1 : 0
                            );
                            setBtnDisables(false);
                          }}
                        />
                      </div>
                      <div className="col-5 md:col-3 lg:col-10">Warranty</div>
                    </div>
                  </div>

                  <div className="col-3 md:col-3 lg:col-3">
                    <div className="grid">
                      <div className="col-2 md:col-2 lg:col-2">
                        <PFCheckbox
                          id="floorsoft_opted"
                          name="floorsoft_opted"
                          checked={formik.values.floorsoft_opted == 1}
                          onChange={event => {
                            formik.setFieldValue(
                              'floorsoft_opted',
                              event.checked ? 1 : 0
                            );
                            setBtnDisables(false);
                          }}
                        />
                      </div>
                      <div className="col-5 md:col-3 lg:col-10">FloorSoft</div>
                    </div>
                  </div>

                  <div className="col-3 md:col-3 lg:col-3">
                    <div className="grid">
                      <div className="col-2 md:col-2 lg:col-2">
                        <PFCheckbox
                          id="is_measuresquare"
                          name="is_measuresquare"
                          checked={formik.values.is_measuresquare == 1}
                          onChange={event => {
                            formik.setFieldValue(
                              'is_measuresquare',
                              event.checked ? 1 : 0
                            );
                            setBtnDisables(false);
                          }}
                        />
                      </div>
                      <div className="col-8 md:col-8 lg:col-8">
                        Measure Square
                      </div>
                    </div>
                  </div>

                  <div className="col-3 md:col-3 lg:col-3">
                    <div className="grid">
                      <div className="col-2 md:col-2 lg:col-2">
                        <PFCheckbox
                          id="create_project_private_lead"
                          name="create_project_private_lead"
                          checked={
                            formik.values.create_project_private_lead == 1
                          }
                          onChange={event => {
                            formik.setFieldValue(
                              'create_project_private_lead',
                              event.checked ? 1 : 0
                            );
                            setBtnDisables(false);
                          }}
                        />
                      </div>
                      <div className="col-8 md:col-8 lg:col-8">
                        Project for private Lead
                      </div>
                    </div>
                  </div>

                  <div className="col-3 md:col-3 lg:col-3">
                    <div className="grid">
                      <div className="col-2 md:col-2 lg:col-2">
                        <PFCheckbox
                          id="cx_scheduling_module"
                          name="cx_scheduling_module"
                          checked={formik.values.cx_scheduling_module == 1}
                          onChange={event => {
                            formik.setFieldValue(
                              'cx_scheduling_module',
                              event.checked ? 1 : 0
                            );
                            setBtnDisables(false);
                          }}
                        />
                      </div>
                      <div className="col-8 md:col-8 lg:col-8">
                        Customer Scheduling
                      </div>
                    </div>
                  </div>

                  <div className="col-3 md:col-3 lg:col-3">
                    <div className="grid">
                      <div className="col-2 md:col-2 lg:col-2  mb-3 py-0">
                        <PFCheckbox
                          id="is_customer_portal"
                          name="is_customer_portal"
                          checked={formik.values.is_customer_portal == 1}
                          onChange={event => {
                            formik.setFieldValue(
                              'is_customer_portal',
                              event.checked ? 1 : 0
                            );
                            setBtnDisables(false);
                          }}
                        />
                      </div>
                      <div className="col-8 md:col-8 lg:col-8 mb-3 py-0">
                        Customer Portal
                      </div>
                    </div>
                  </div>

                  <div className="col-3 md:col-3 lg:col-3">
                    <div className="grid">
                      <div className="col-2 md:col-2 lg:col-2">
                        <PFCheckbox
                          id="capture_live_location"
                          name="capture_live_location"
                          checked={formik.values.capture_live_location == 1}
                          onChange={event => {
                            formik.setFieldValue(
                              'capture_live_location',
                              event.checked ? 1 : 0
                            );
                            setBtnDisables(false);
                          }}
                        />
                      </div>
                      <div className="col-8 md:col-8 lg:col-8">
                        Capture Technician Live Location
                      </div>
                    </div>
                  </div>
                  <div className="col-3 md:col-3 lg:col-3">
                    <div className="grid">
                      <div className="col-2 md:col-2 lg:col-2">
                        <PFCheckbox
                          id="is_capture_job_distance_enabled"
                          name="is_capture_job_distance_enabled"
                          checked={
                            formik.values.is_capture_job_distance_enabled == 1
                          }
                          onChange={event => {
                            formik.setFieldValue(
                              'is_capture_job_distance_enabled',
                              event.checked ? 1 : 0
                            );
                            setBtnDisables(false);
                          }}
                        />
                      </div>
                      <div className="col-8 md:col-8 lg:col-8">
                        Capture Jobs Distance
                      </div>
                    </div>
                  </div>
                  <div className="col-3 md:col-3 lg:col-3">
                    <div className="grid">
                      <div className="col-2 md:col-2 lg:col-2">
                        <PFCheckbox
                          id="personalized_emails"
                          name="personalized_emails"
                          checked={formik.values.personalized_emails == 1}
                          onChange={event => {
                            formik.setFieldValue(
                              'personalized_emails',
                              event.checked ? 1 : 0
                            );
                            setBtnDisables(false);
                          }}
                          className="pt-1"
                        ></PFCheckbox>
                      </div>
                      <div className="col-8 md:col-8 lg:col-8">
                        Personalized Emails
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid mt-2 flex align-items-center">
                  <div className="col-3 md:col-3 lg:col-3">Payments</div>
                  <div className="col-9 md:col-9 lg:col-9 flex flex-start">
                    <PFButton
                      severity="primary"
                      className="w-2"
                      label="Configure"
                      size="small"
                      onClick={() => window.open(`/payments-config`, '_blank')}
                    />
                  </div>
                  <div className="col-3 md:col-3 lg:col-3">Audit Log</div>
                  <div className="col-9 md:col-9 lg:col-9 flex flex-start">
                    <PFButton
                      severity="primary"
                      className="w-2"
                      label="Configure"
                      size="small"
                      onClick={() =>
                        window.open(`/audit-log-reports`, '_blank')
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </Card>
        )}
      </div>
    </div>
  );
};

export default EditCompanyConfig;
