export const BILLING_SKELETON_COLUMN_DATA = [
  {
    name: 'project_number',
    label: 'Project Number',
  },
  {
    name: 'payment_number',
    label: 'Payment Number',
  },
  {
    name: 'payment_status_id',
    label: 'Payment Status',
  },
  {
    name: 'payment_type',
    label: 'Payment Type',
  },
  {
    name: 'originating_po',
    label: 'PO',
  },
  {
    name: 'check_date',
    label: 'Check Date',
  },
  {
    name: 'check_amount',
    label: 'Check Amount',
  },
  {
    name: 'check_number',
    label: 'Check Number',
  },
  {
    name: 'requested_amount',
    label: 'Requested Amount',
  },
  {
    name: 'created_at',
    label: 'Date Created',
  },
  {
    name: 'modified_at',
    label: 'Date Updated',
  },
];
