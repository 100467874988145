import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Toast } from 'primereact/toast';

import AddButton from '../shared/OpenFormButton/OpenFormButton';
import { WrapperPRDataTable } from '../shared/GenericDataTable/GenericDataTable';
import PFTableLoader from '../shared/Loader/PFTableLoader';
import PageMeta from '../PageMeta/PageMeta';
import TableColumnsLoader from '../shared/Loader/tableColumnsLoader';

import {
  createNewWarehouse,
  fetchWarehouseList,
  setWarehouseColumns,
  updateNewWarehouse,
  deleteWarehouse,
} from './Warehouse.service';
import AddWarehouse from './AddWarehouse';

const Locations = props => {
  const pageMetaIdentifier = props.identifier ? props.identifier : null;
  const [loading, setLoading] = useState(false);
  const [columns] = useState(setWarehouseColumns());
  const [warehouseList, setWarehouseList] = useState([]);
  const toast = useRef(null);
  const childRef = useRef(null);

  useEffect(() => {
    setLoading(true);
    fetchWarehouseList().then(response => {
      setWarehouseList(response);
      setLoading(false);
    });
  }, []);

  const onRowSelect = ({ data }) => {
    const formatData = {
      ...data,
      addressLineOne: data.address?.split(',')[0],
      addressLineTwo: data.address?.split(',')[1],
      city: data.address?.split(',')[2],
      state: data.address?.split(',')[3],
      zipcode: data.address?.split(',')[4],
    };
    childRef.current.displayEditForm(formatData);
  };

  const createNewWarehouseDetails = async (wareHouseDetails, callback) => {
    setLoading(true);
    const response = await createNewWarehouse(wareHouseDetails);
    if (!response?.status) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: `Error Occurred - ${response?.message}`,
      });
    } else {
      if (callback) {
        callback();
      }
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: `Success! ${response?.data?.name} was created!`,
      });
    }
    const newListResponse = await fetchWarehouseList();
    setWarehouseList(newListResponse);
    setLoading(false);
  };

  const updateNewWarehouseDetails = async (wareHouseDetails, callback) => {
    setLoading(true);
    const response = await updateNewWarehouse(wareHouseDetails);
    if (!response?.status) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: `Error Occurred - ${response?.message}`,
      });
    } else {
      if (callback) {
        callback();
      }
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: `Success! ${response?.data?.name} was Updated!`,
      });
    }
    const newListResponse = await fetchWarehouseList();
    setWarehouseList(newListResponse);
    setLoading(false);
  };

  const deleteWareHouseHandler = async wareHouseDetails => {
    setLoading(true);
    const response = await deleteWarehouse(wareHouseDetails);
    if (!response?.status) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: `${response?.message}`,
      });
    } else {
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: `Success! ${response?.data?.name} was Deleted!`,
      });
    }
    const newListResponse = await fetchWarehouseList();
    setWarehouseList(newListResponse);
    setLoading(false);
  };

  const tableLoader = useMemo(() => {
    return TableColumnsLoader(columns, {
      rows: 5,
      isValue: false,
    });
  }, [columns]);

  return (
    <>
      <Toast ref={toast} />
      <div className="card grid w-12 flex-column flex-nowrap">
        <div className="grid grid-nogutter w-12 flex justify-content-between align-items-center pt-2">
          <div className="flex">
            <PageMeta
              slug={pageMetaIdentifier}
              style={{ marginTop: '12px', marginLeft: '7px' }}
            />
            <h2 className=" text-lg">Warehouse</h2>
          </div>
          <div className="flex">
            <AddButton
              ref={childRef}
              buttonText="Add New"
              headerTitle="New Warehouse"
            >
              <AddWarehouse
                createNewWarehouseDetails={createNewWarehouseDetails}
                updateNewWarehouseDetails={updateNewWarehouseDetails}
                deleteWareHouseHandler={deleteWareHouseHandler}
              />
            </AddButton>
          </div>
        </div>

        <div
          className="mt-1 overflow-auto bg-white p-3"
          style={{ height: 'calc(100vh - 220px)' }}
        >
          <WrapperPRDataTable
            onRowSelect={onRowSelect}
            onRowUnselect={onRowSelect}
            selectionMode="single"
            dataValues={loading ? tableLoader : warehouseList}
            columns={columns}
            rows={5}
            rowsPerPageOptions={[5, 10, 25, 50]}
            paginator={!loading}
          />
        </div>
      </div>
    </>
  );
};

export default Locations;
