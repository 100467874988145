// **** React Imports ****
import React from 'react';

// **** Utilities ****
import {
  Grid,
  CircularProgress,
  TextField,
  Button,
  Typography,
  ListItemIcon,
  ListItemText,
  Paper,
  List,
  ListItem,
  Tooltip,
  Badge,
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';

// **** Custom Components ****
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Archive as IncomingIcon,
  Unarchive as OutgoingIcon,
} from '@material-ui/icons';

import GenericDialog from '../../shared/Dialog/GenericDialog';
import { useAlerts } from '../../shared/Alerts/alertsService';
import GenericConfirmationDialog from '../../shared/GenericConfirmationDialog/GenericConfirmationDialog';

// **** Services *****
import PFTableLoader from '../../shared/Loader/PFTableLoader';

import {
  createScheduledMessage,
  getProjectSMSData,
  editScheduledMessage,
  getProjectSMSHistory,
  deleteScheduledMessage,
  markMessageAsReviewed,
  getSMSCountData,
} from './SmsNotifications.service';

// **** Styles *****
import { useStyles } from './SmsNotifications.styles';
import { Skeleton } from 'primereact/skeleton';

const SmsNotifications = ({ formik, customerDetails }) => {
  const classes = useStyles();
  const { setAlert } = useAlerts();
  const [formAction, setFormAction] = React.useState('add');
  const [dialogSettings, setDialogSettings] = React.useState({
    title: 'Add Scheduled Message',
    button1Text: '',
    button2Text: 'Add',
    showButton1: true,
    showButton2: true,
  });
  const [rowIndex, setRowIndex] = React.useState();

  const SMSLoading = false;
  const [viewSMSLoading, setViewSMSLoading] = React.useState(false);
  const [smsHistoryList, setSMSHistoryList] = React.useState([]);
  const [smsResponse, setSmsResponse] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [, setSMSCountLoading] = React.useState(false);
  const [reloadList, setReloadList] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [smsCount, setSmsCount] = React.useState([]);
  const [confirmDialog, setConfirmDialog] = React.useState({
    header: '',
    title: '',
    subtitle: '',
    isOpen: false,
  });

  let receiver_primary_number =
    customerDetails[0]?.primary_phone ||
    customerDetails[0]?.customer_phones[0]?.phone_number ||
    null;
  if (!receiver_primary_number) {
    receiver_primary_number = formik?.values?.customer?.other_phone || null;
  }
  let client_name = `${customerDetails[0]?.first_name} ${customerDetails[0]?.last_name}`;

  // **** Form Validation Schema ****
  const SMSValidationSchema = Yup.object().shape({
    message: Yup.string().required('Required'),
  });

  const convertGMTToISOString = (value = null) => {
    if (value) {
      try {
        return moment(new Date(`${value} GMT+0000`).toISOString()).format(
          'MM-DD-YYYY hh:mm A'
        );
      } catch (err) {
        console.log('Error while converting Date', err);
        return null;
      }
    }
    return null;
  };
  // **** Formik Form Values ****
  const smsFormik = useFormik({
    initialValues: {
      scheduled_date:
        (formAction !== 'add' && smsResponse[rowIndex]?.requested_date_time) ||
        null,
      message: (formAction !== 'add' && smsResponse[rowIndex]?.message) || '',
    },

    onSubmit: (values, { setSubmitting, resetForm }) => {
      if (formAction === 'add') {
        createScheduledMessage(
          formik?.values?.project_id,
          {
            ...values,
            message: values?.message.trim() || null,
            scheduled_date: values.scheduled_date || null,
            receiver_primary_number: receiver_primary_number || null,
          },
          setLoading,
          setAlert,
          setReloadList,
          setIsOpen,
          resetForm
        );
      } else {
        editScheduledMessage(
          formik?.values?.project_id,
          {
            ...values,
            message: values?.message.trim() || null,
            scheduled_date: values.scheduled_date || null,
            receiver_primary_number: receiver_primary_number || null,
          },
          smsResponse[rowIndex]?.sms_log_id,
          setLoading,
          setAlert,
          setReloadList,
          setIsOpen,
          resetForm
        );
      }
      setReloadList(false);
    },
    validationSchema: SMSValidationSchema,
    enableReinitialize: true,
  });

  React.useEffect(() => {
    if (formik?.values?.project_id) {
      getProjectSMSData(formik?.values?.project_id, setLoading, setSmsResponse);
    }
  }, [formik?.values?.project_id]);

  React.useEffect(() => {
    if (reloadList) {
      getProjectSMSData(formik?.values?.project_id, setLoading, setSmsResponse);
    }
  }, [reloadList]);

  React.useEffect(() => {
    if (formik?.values?.project_id) {
      getSMSCountData(
        formik?.values?.project_id,
        setSMSCountLoading,
        setSmsCount
      );
    }
  }, [reloadList]);

  const addEditScheduledMessage = (action, index) => {
    smsFormik.handleReset();
    setFormAction(action);

    setDialogSettings(prevState => ({
      ...prevState,
      showButton2: true,
      button2Text: 'Save',
      title:
        (action === 'add' && 'Add Scheduled Message') ||
        'Edit Scheduled Message',
    }));
    setRowIndex(index);
    setIsOpen(true);
  };

  const deleteMessageConfirmation = index => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: true,
      title: `Are you sure you want to delete this Scheduled Message?`,
      header: 'Delete Message',
    });
    setRowIndex(index);
  };
  const onMessageDeleteConfirmDialog = async () => {
    setReloadList(false);
    deleteScheduledMessage(
      formik?.values?.project_id,
      smsResponse[rowIndex]?.sms_log_id,
      setLoading,
      setAlert,
      setReloadList,
      setConfirmDialog
    );
  };

  const viewSMSHistory = async () => {
    setSMSHistoryList([]);
    setViewSMSLoading(true);
    setFormAction('view');
    setDialogSettings(prevState => ({
      ...prevState,
      showButton2: false,
      button2Text: '',
      title: 'Message History',
    }));
    setIsOpen(true);

    const smsHistoryResponse = await getProjectSMSHistory(
      formik?.values?.project_id,
      setViewSMSLoading
    );
    smsHistoryResponse &&
      smsHistoryResponse?.length &&
      setSMSHistoryList(smsHistoryResponse);
  };

  const handleSmsHistoryClickReview = async sms_response_log_id => {
    markMessageAsReviewed(sms_response_log_id);
    setSMSHistoryList([]);
    setViewSMSLoading(true);
    const smsHistoryResponse = await getProjectSMSHistory(
      formik?.values?.project_id,
      setViewSMSLoading
    );
    smsHistoryResponse && setSMSHistoryList(smsHistoryResponse);
  };

  const makeSMSPaper = (notification, key, type) => {
    return (
      <Paper
        classes={{
          ...(type === 'parent'
            ? { root: classes.listPaper }
            : type === 'child' && notification?.message != null
              ? { root: classes.listPaperChild }
              : { root: classes.emptyResponse }),
        }}
        elevation={0}
        key={key}
      >
        <Grid
          container
          spacing={1}
          direction="column"
          className={classes.viewSMSGridWraper}
        >
          <Grid item>
            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="flex-start"
            >
              <Grid container direction="row">
                <Typography
                  component="span"
                  variant="h4"
                  className={classes.loggedInUser}
                  color="textPrimary"
                >
                  {type === 'child'
                    ? `Received From: ${client_name}`
                    : `Sent By: ${notification?.Sender_name}`}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="flex-start"
            >
              <Typography
                component="span"
                variant="h4"
                className={classes.inline}
                color="textPrimary"
              >
                {type === 'child' ? `Received on:` : `Delivered on: `}
              </Typography>
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color="textPrimary"
              >
                {type === 'child'
                  ? convertGMTToISOString(notification?.delivered_date_time)
                  : notification?.delivered_date_time
                    ? moment(notification?.delivered_date_time).format(
                        'MM-DD-YYYY hh:mm A'
                      )
                    : null}
              </Typography>
            </Grid>
            <Grid
              container
              spacing={1}
              direction="row"
              className={classes.statusText}
            >
              <Typography
                component="span"
                variant="h4"
                className={classes.inline}
                color="textPrimary"
              >
                Status:
              </Typography>
              <Typography
                component="span"
                variant="body2"
                className={classes.statusResponseText}
                color="textPrimary"
              >
                {`${notification?.status}` === 'New'
                  ? `Scheduled`
                  : `${notification?.status}`}
              </Typography>
            </Grid>
          </Grid>
          <Grid item classes={{ root: classes.tableWrapper }}>
            <Typography
              component="span"
              variant="body2"
              className={classes.inline}
              color="textPrimary"
            >
              {notification?.message}
            </Typography>
            {type === 'child' ? (
              <Typography
                component="div"
                variant="body2"
                className={classes.reviewdSMSClass}
              >
                {notification?.reviewed && notification?.reviewed === 'true' ? (
                  <>
                    <div className={classes.smsReviewed}>Reviewed</div>
                    <Typography
                      className={classes.reviewedByText}
                      component="div"
                      variant="body2"
                    >
                      {' '}
                      {`Reviewed By ${notification?.reviewed_by_user} at ${convertGMTToISOString(
                        notification?.reveiwed_time
                      )}`}
                    </Typography>
                  </>
                ) : (
                  <>
                    <ListItemText
                      primary={
                        <Typography
                          component="span"
                          variant="body2"
                          className={classes.reviewNotificationLink}
                          color="primary"
                          onClick={() => {
                            handleSmsHistoryClickReview(
                              notification?.sms_response_log_id
                            );
                          }}
                        >
                          Review
                        </Typography>
                      }
                    />
                  </>
                )}
              </Typography>
            ) : null}
          </Grid>
        </Grid>
      </Paper>
    );
  };

  const getSMSHistoryList = () => (
    <Grid item xs={12} lg={12} md={12} sm={12}>
      <List className={classes.listRoot}>
        {viewSMSLoading ? (
          <ListItem
            alignItems="center"
            justifyContent="center"
            className={classes.smsLoader}
          >
            <CircularProgress />
          </ListItem>
        ) : smsHistoryList?.length ? (
          smsHistoryList
            .sort(
              (a, b) =>
                new Date(a.delivered_date_time) -
                new Date(b.delivered_date_time)
            )
            .map(notification => {
              let smsHistoryListResponse = makeSMSPaper(
                notification,
                notification?.aws_message_id,
                'parent'
              );
              let smsChildResponse = [];
              try {
                smsChildResponse = JSON.parse(notification?.responses)
                  .sort(
                    (a, b) =>
                      new Date(a.delivered_date_time) -
                      new Date(b.delivered_date_time)
                  )
                  .map((responseNotification, index) => {
                    const responseNotificationKey = `${notification?.aws_message_id}_${index}`;
                    return makeSMSPaper(
                      responseNotification,
                      responseNotificationKey,
                      'child'
                    );
                  });
              } catch (error) {
                setAlert(
                  'error',
                  'Error while parsing SMS response data.',
                  false,
                  true
                );
              }
              return [smsHistoryListResponse, smsChildResponse];
            })
        ) : null}
      </List>
    </Grid>
  );

  const getSMSAddEditForm = () => {
    return (
      <Grid
        container
        spacing={2}
        direction="column"
        className={
          (formAction === 'edit' || formAction === 'add') && classes.formEdit
        }
      >
        <Grid item className="mui-calendar-field">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDateTimePicker
              autoOk
              id="scheduled_date"
              name="scheduled_date"
              variant="inline"
              label="Scheduled Date and Time"
              invalidDateMessage="Invalid Date"
              disablePast={formAction !== 'view'}
              format="MM-dd-yyyy hh:mm a"
              value={smsFormik?.values?.scheduled_date}
              onChange={(date, value) =>
                smsFormik.setFieldValue('scheduled_date', date)
              }
              InputLabelProps={{ shrink: true }}
              helperText={
                <>
                  <Typography className={classes.helperText}>
                    If left blank, the current date and time will be selected.
                  </Typography>
                </>
              }
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item>
          <TextField
            id="message"
            label="Message"
            placeholder="Max 500 characters"
            multiline
            maxRows={5}
            inputProps={{ maxLength: 500 }}
            name="message"
            onChange={smsFormik.handleChange}
            onBlur={smsFormik.handleBlur}
            value={smsFormik?.values?.message}
            InputLabelProps={{ shrink: true }}
            className={classes.input}
            required={formAction === 'edit' || formAction === 'add'}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Grid container spacing={1} direction="column">
        <br />
        <Grid container>
          <Grid container spacing={1} direction="row">
            <Grid item direction="row">
              <Button
                size="small"
                color="primary"
                variant="outlined"
                disableRipple
                classes={{ root: classes.buttonRoot }}
                onClick={() => {
                  viewSMSHistory();
                }}
              >
                View SMS History
              </Button>
            </Grid>
            <Grid item direction="row" className={classes.countGrid}>
              {loading ? (
                <div className="flex gap-4">
                  <Skeleton height="2rem" width="2rem" className="mb-2 " />
                  <Skeleton height="2rem" width="2rem" className="mb-2" />
                </div>
              ) : (
                <>
                  <Badge
                    color="error"
                    badgeContent={
                      smsCount?.sms_response_count
                        ? smsCount?.sms_response_count
                        : 0
                    }
                    showZero
                    className={classes.incomingIcon}
                  >
                    <Tooltip title="Received Messages" arrow>
                      <IncomingIcon className={classes.countIcon} />
                    </Tooltip>
                  </Badge>
                  <Badge
                    color="primary"
                    badgeContent={
                      smsCount?.sms_log_count ? smsCount?.sms_log_count : 0
                    }
                    showZero
                  >
                    <Tooltip title="Sent Messages" arrow>
                      <OutgoingIcon
                        color="primary"
                        className={classes.countIcon}
                      />
                    </Tooltip>
                  </Badge>
                </>
              )}
            </Grid>
            <Grid item justifyContent="center">
              <Button
                size="small"
                color="primary"
                variant="outlined"
                disableRipple
                disabled={receiver_primary_number ? false : true}
                classes={{ root: classes.buttonRoot }}
                onClick={() => addEditScheduledMessage('add')}
              >
                {receiver_primary_number
                  ? 'Add Scheduled Message'
                  : 'Add Contact Or Alternate Number to Add Scheduled Message'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <br />
        <Grid item classes={{ root: classes.tableWrapper }}>
          <Paper classes={{ root: classes.gridPaper }} elevation={0}>
            <Grid container direction="column">
              <Grid item xs={12} lg={12} md={12} sm={12}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  classes={{ root: classes.titleHeaderGrid }}
                >
                  <Grid item>
                    <Typography variant="h3">Scheduled Message</Typography>
                  </Grid>
                  <Grid item></Grid>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                lg={12}
                md={12}
                sm={12}
                classes={{ root: classes.tableContainer }}
              >
                <List className={classes.listRoot}>
                  {loading ? (
                    <Skeleton height="7rem" width="80%" />
                  ) : smsResponse.length ? (
                    smsResponse.map((notification, index) => (
                      <Paper
                        classes={{ root: classes.listPaper }}
                        elevation={0}
                        key={index}
                      >
                        <Grid item>
                          <Typography
                            variant="h4"
                            className={classes.scheduleStaticText}
                          >
                            Status:{' '}
                            {`${notification?.overall_status}` === 'New'
                              ? `Scheduled`
                              : `${notification?.overall_status}`}
                          </Typography>
                        </Grid>
                        <ListItem
                          alignItems="flex-start"
                          className={classes.listItemRoot}
                        >
                          <ListItemText
                            primary={
                              <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                spacing={4}
                              >
                                <Grid
                                  item
                                  direction="row"
                                  justifyContent="flex-start"
                                >
                                  <Typography
                                    component="span"
                                    variant="h6"
                                    className={classes.inline}
                                    color="textSecondary"
                                  >
                                    {`Scheduled Date and Time: `}
                                  </Typography>
                                  {notification?.requested_date_time
                                    ? moment(
                                        notification?.requested_date_time
                                      ).format('MM-DD-YYYY hh:mm A')
                                    : null}
                                </Grid>
                              </Grid>
                            }
                            secondary={
                              <React.Fragment>
                                <Typography
                                  component="span"
                                  variant="body2"
                                  className={classes.inline}
                                  color="textPrimary"
                                >
                                  {notification?.message}
                                </Typography>
                              </React.Fragment>
                            }
                          />
                          <ListItemIcon>
                            <Tooltip title="Edit" arrow>
                              <EditIcon
                                className={classes.editIcon}
                                color="primary"
                                onClick={() => {
                                  addEditScheduledMessage('edit', index);
                                }}
                              />
                            </Tooltip>
                            <Tooltip title="Delete" arrow placement="right">
                              <DeleteIcon
                                className={classes.viewNotificationLink}
                                color="primary"
                                onClick={() => {
                                  deleteMessageConfirmation(index);
                                }}
                              />
                            </Tooltip>
                          </ListItemIcon>
                        </ListItem>
                      </Paper>
                    ))
                  ) : (
                    ''
                  )}
                </List>
                <GenericDialog
                  fullwidth
                  isOpen={isOpen}
                  handleClose={() => {
                    setIsOpen(false);
                    smsFormik?.handleReset();
                  }}
                  handleSave={smsFormik.handleSubmit}
                  dialogSettings={dialogSettings}
                  disabledButton2={!smsFormik?.dirty || !smsFormik?.isValid}
                  disabledButton1={false}
                >
                  {formAction === 'view' ? (
                    getSMSHistoryList()
                  ) : (
                    <form onSubmit={smsFormik.handleSubmit}>
                      {getSMSAddEditForm()}
                    </form>
                  )}
                </GenericDialog>
                {/* Confirmation dialog for deleting messages */}
                <GenericConfirmationDialog
                  confirmDialog={confirmDialog}
                  setConfirmDialog={setConfirmDialog}
                  onConfirmDialog={onMessageDeleteConfirmDialog}
                />
              </Grid>
              <br />
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default SmsNotifications;
