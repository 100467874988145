// **** Custom Components ****
import HomeDashboard from '../components/HomeDashboard/HomeDashboard';
import AddViewEditProjectDetails from '../components/ProjectManagement/ProjectInfo/AddViewEditProjectDetails';
import Scheduling from '../components/ProjectManagement/Scheduling/Scheduling';
import Reports from '../components/ProjectManagement/Scheduling/Reports/Reports';
import ViewProjectDocumentOld from '../components/ProjectManagement/Docs/ViewDocument';
import ViewProjectDocument from '../components/ProjectManagement/Docs/ViewDocumentNew';
import SelectFields from '../components/ProjectManagement/ProjectFields/SelectFields';
import Clients from '../components/ProjectManagement/Clients/Clients';
import AddViewEditClientDetails from '../components/ProjectManagement/Clients/AddViewEditClientDetails';
import Stores from '../components/Admin/Stores/Stores';
import AddViewEditStoreDetails from '../components/Admin/Stores/AddViewEditStoreDetails';
import Templates from '../components/Admin/DocumentCenter/Templates';
import ViewEditTemplate from '../components/Admin/DocumentCenter/ViewEditTemplate';
import ViewEditPdfTemplate from '../components/Admin/DocumentCenter/ViewEditPdfTemplate';
import LaborProfiles from '../components/Admin/LaborProfiles/LaborProfiles';
import AddViewEditLaborProfileDetails from '../components/Admin/LaborProfiles/AddViewEditLaborProfileDetails';
import AvailabilityUpdates from '../components/Personnel/AvailabilityUpdates/AvailabilityUpdates';
import ProjectSearchResults from '../components/shared/ProjectSearchResults/ProjectSearchResults';
import DashboardList from '../components/Admin/Dashboards/DashboardList';
import AddViewEditDashboard from '../components/Admin/Dashboards/AddViewEditDashboard';
import UserManagement from '../components/Admin/UserManagement/UserManagement';
import AddViewEditUserDetails from '../components/Admin/UserManagement/AddViewEditUserDetails';
import RoleManagement from '../components/Admin/RoleManagement/RoleManagement';
import Permissions from '../components/Admin/RoleManagement/Permissions';
import GetStarted from '../components/GetStarted/GetStarted';
import CreateNewProject from '../components/GetStarted/CreateNewProject';
import DeleteInstaller from '../components/GetStarted/DeleteInstaller';
import CreateScheduling from '../components/GetStarted/CreateScheduling';
// import LoginPage from '../components/LoginPage/LoginPage';
import ProjectReport from '../components/Scheduler/ProjectReport';
import Scheduler from '../components/SchedulerBeta/SchedulerContainer';
import ReportDetails from '../components/Admin/Dashboards/ReportDetails';
import WidgetTemplateList from '../components/Admin/Dashboards/Widgets/WidgetTemplateList';
// import CreateWidgetTemplate from '../components/Admin/Dashboards/Widgets/CreateWidgetTemplate';
import RuleEngineLogs from '../components/Admin/RuleEngineLogs/RuleEngineLogs';
import Billing from '../components/Billing/Billing';
import EngineResult from '../components/Admin/EngineResult/EngineResult';
import TenantBilling from '../components/Admin/TennantBilling/TenantBilling';
import LrrpConfiguration from '../components/Admin/Configuration/LrrpConfiguration';
import Bulletin from '../components/Admin/Bulletin/Bulletin';
import Widget from '../components/Admin/Widget/WidgetContainer';
import Warehouse from '../components/Locations/Warehouse.js';
import { EmailTemplates } from '../components/EmailTemplates/EmailTemplatesContainer.jsx';
import UserFormLayout from '../components/Admin/user-form/user-form-layout';
import RoConfiguration from '../components/Admin/Configuration/RoConfiguration';
import Routing from '../components/ProjectManagement/Scheduling/Routing/Routing';
import CustomerContainer from '../components/CRM/Clients/CustomerContainer';
import WarrantyConfiguration from '../components/Admin/Configuration/WarrantyConfiguration.js';
import OpportunityContainer from '../components/CRM/Opportunities/OpportunityContainer';
import { QuoteManagement } from '../components/CRM/QuoteManagement/QuoteListing.view';
import { Quote } from '../components/CRM/QuoteManagement/Quote.view';
import CrmConfiguration from '../components/Admin/Configuration/CrmConfiguration.jsx';
import QuoteContainer from '../components/CRM/Quotes/QuoteContainer';
import QuoteForm from '../components/CRM/Quotes/components/Quote';
import { AccessDenied } from '../components/shared/AccessDenied/AccessDenied.js';
import ChargebackAction from '../components/ProjectManagement/Chargeback/ChargebackAction/ChargebackAction.js';
import PaymentsConfiguration from '../components/Admin/Configuration/PaymentsConfiguration.jsx';
import OnboardSuccess from '../components/shared/common/OnboardSuccess';
import OnboardRefresh from '../components/shared/common/OnboardRefresh';
import PaymentsConfigurationEdit from '../components/Admin/Configuration/components/PaymentsConfigurationEdit';
import Invoice from '../components/CRM/Invoice/components/Invoice.jsx';
import TechnicianPurchaseOrder from '../components/ProjectManagement/ProjectItemsNew/TechnicianPO.js';
import TechnicianPurchaseOrderDefault from '../components/ProjectManagement/ProjectItemsNew/TechnicianPO.js';
import PageMetaUpdate from '../components/PageMeta/PageMetaUpdate.js';
import ItemsNew from '../components/ProjectManagement/Items/ItemsNew.js';
import CompanyConfig from '../components/Admin/NewConfiguration/CompanyConfig.js';
import EditCompanyConfig from '../components/Admin/NewConfiguration/EditCompanyConfig.js';
import ProjectAuditLog from '../components/Admin/Configuration/ProjectAuditLog.js';
import ProjectAuditLogCreate from '../components/Admin/Configuration/ProjectAuditLogCreate.js';
import PayrollContainer from '../components/Admin/Payroll/PayrollContainer.js';
import MeasureSquareConfiguration from '../components/Admin/Configuration/MeasureSquareConfiguration.jsx';
import QuestionnaireComponent from '../components/Admin/Questionnaire/QuestionnaireComponent.js';
import ReportContainer from '../components/Admin/ExportedReports/ReportContainer';
import BusinessHourConfig from '../components/Admin/NewConfiguration/BusinessHourConfig/BusinessHourConfig.js';
import CxSchedulingConfig from '../components/Admin/NewConfiguration/CxSchedulingConfig/CxSchedulingConfig.js';
import ServiceTimeConfig from '../components/Admin/NewConfiguration/ServiceTimeConfig/ServiceTimeConfig.js';
import ProjectColorCode from '../components/Admin/NewConfiguration/ProjectColorCodeConfig/ProjectColorCode';
import DashboardPerformanceAnalysisContainer from '../components/Admin/DashboardPerformanceAnalysis/DashboardPerformanceAnalysisContainer';
import ImportData from '../components/ImportData';
import GroupSourceCategory from '../components/Admin/NewConfiguration/GroupSourceCategory/GroupSourceCatgory';
import AddEditGroupSource from '../components/Admin/NewConfiguration/GroupSourceCategory/AddEditGroupSource';
import SchedulerRTSReportFilter from '../components/Admin/NewConfiguration/SchedularReportConfig/SchedularRTSReportFilter';
import AddEditGroupItems from '../components/ProjectManagement/Items/GroupItems/add-edit-group-item';
import ChangePassword from '../components/Admin/Auth/Login/ChangePassword';
import LoginLogs from '../components/Admin/Logs/LoginLogs/LoginLogsContainer';
import ResetPasswordLogsContainer from '../components/Admin/Logs/ResetPasswordLogs/ResetPasswordLogs';
import CustomerLoginLogs from '../components/Admin/Logs/CustomerLoginLogs/CustomerLoginLogs';
import CustomerResetPasswordLogs from '../components/Admin/Logs/CustomerResetPasswordLogs/CustomerResetPasswordLogs';
import CustomerRegisterLogs from '../components/Admin/Logs/CustomerRegisterLogs/CustomerRegisterLogs';
import RelatedPOs from '../components/Admin/NewConfiguration/RelatedPOsConfig/RelatedPOsConfig';

import permissions from './permissions';
export const routes = [
  {
    path: '/dashboard',
    title: 'Home',
    component: HomeDashboard,
    permissions: permissions.home.menu,
    identifier: 'home',
  },
  {
    path: '/dashboard-widget',
    title: 'Home',
    component: HomeDashboard,
    permissions: permissions.home.menu,
    identifier: 'widget_dashboard',
  },
  {
    path: '/routing',
    title: 'Routing',
    component: Routing,
    identifier: 'routing',
  },
  {
    path: '/getstarted',
    title: 'Get Started',
    component: GetStarted,
    identifier: 'get_started',
  },
  {
    path: '/getstarted/create',
    title: 'Create New Project',
    component: CreateNewProject,
    identifier: 'create_new_project',
  },
  {
    path: '/getstarted/delete/:videoIdid',
    title: 'Delete Installer',
    component: DeleteInstaller,
    identifier: 'delete_installer',
  },
  {
    path: '/getstarted/scheduling',
    title: 'How to Schedule a Project',
    component: CreateScheduling,
    identifier: 'create_scheduling',
  },
  {
    path: '/clients/search/:clientId',
    title: 'Search Results',
    component: ProjectSearchResults,
    permissions: permissions.globalSearch.menu,
    identifier: 'result_search',
  },
  {
    path: '/project/clients',
    title: 'Clients',
    component: Clients,
    permissions: permissions.clients.menu,
    identifier: 'clients',
  },
  {
    path: '/project/items',
    title: 'Items',
    component: ItemsNew,
    permissions: permissions.items.menu,
    identifier: 'items',
  },
  {
    path: `/project/items/group-items/add`,
    title: 'Group Items',
    component: AddEditGroupItems,
  },
  {
    path: '/clients/add',
    title: 'Clients',
    component: AddViewEditClientDetails,
    permissions: permissions.clients.addClient,
    identifier: 'add_clients',
  },
  {
    path: '/clients/view/:clientId',
    title: 'Clients',
    component: AddViewEditClientDetails,
    permissions: permissions.clients.menu,
    identifier: 'view_clients',
  },
  {
    path: '/clients/edit/:clientId',
    title: 'Clients',
    component: AddViewEditClientDetails,
    permissions: permissions.clients.editClient,
    identifier: 'edit_clients',
  },
  {
    path: '/project/:action/:projectId/viewProjectDocument/:documentType/:documentId',
    title: 'View Document',
    component: ViewProjectDocument,
    identifier: 'view_document',
  },
  {
    path: '/project/:action/:projectId/viewProjectDocumentOld/:documentType/:documentId',
    title: 'View Document',
    component: ViewProjectDocumentOld,
    identifier: 'view_document_old',
  },
  {
    path: '/project/readyToScheduleReport',
    title: 'Reports',
    component: Reports,
    identifier: 'schedule_reports',
  },
  {
    path: '/project/report',
    title: 'Reports',
    component: Reports,
    identifier: 'report',
  },
  {
    path: '/project/view/:projectId?',
    title: 'Project Details',
    component: AddViewEditProjectDetails,
    permissions: permissions.project.viewProject,
    identifier: 'view_project_details',
  },
  {
    path: '/project/edit/:projectId?',
    title: 'Project Details',
    component: AddViewEditProjectDetails,
    permissions: permissions.project.editProject,
    identifier: 'edit_project_details',
  },
  {
    path: '/project/add',
    title: 'Project Details',
    component: AddViewEditProjectDetails,
    permissions: permissions.project.addProject,
    identifier: 'add_project_details',
  },
  {
    path: '/project/copy/:projectId?',
    title: 'Project Details',
    component: AddViewEditProjectDetails,
    permissions: permissions.viewEditProject.actionCopyProject,
    identifier: 'copy_project_details',
  },
  {
    path: '/project/add-fields',
    title: 'Project Fields',
    component: SelectFields,
    permissions: permissions.projectFields.viewProjectFields,
    identifier: 'add_project_fields',
  },
  {
    path: '/admin/stores',
    title: 'Stores',
    component: Stores,
    permissions: permissions.stores.menu,
    identifier: 'stores',
  },
  {
    path: '/admin/warehouse',
    title: 'Warehouse',
    component: Warehouse,
    identifier: 'warehouse',
  },
  {
    path: '/admin/configuration/personalized-emails',
    title: 'personalizedEmails',
    component: EmailTemplates,
    identifier: 'personalizedEmails',
  },
  {
    path: '/stores/view/:storeId?',
    title: 'Store Details',
    component: AddViewEditStoreDetails,
    permissions: permissions.stores.menu,
    identifier: 'view_store_details',
  },
  {
    path: '/stores/add',
    title: 'Store Details',
    component: AddViewEditStoreDetails,
    permissions: permissions.stores.storesAdd,
    identifier: 'add_store_details',
  },
  {
    path: '/stores/edit/:storeId?',
    title: 'Store Details',
    component: AddViewEditStoreDetails,
    permissions: permissions.stores.storesEdit,
    identifier: 'edit_store_details',
  },
  {
    path: '/admin/documentcenter',
    title: 'Document Center',
    component: Templates,
    permissions: permissions.documentCenter.menu,
    identifier: 'document_center',
  },
  {
    path: '/admin/documentcenter/view/:templateId',
    title: 'Document Center',
    component: ViewEditPdfTemplate,
    permissions: permissions.documentCenter.menu,
    identifier: 'view_document_center',
  },
  {
    path: '/admin/documentcenter/edit/:templateId',
    title: 'Document Center',
    component: ViewEditPdfTemplate,
    permissions: permissions.documentCenter.editDocTemplate,
    identifier: 'edit_document_center',
  },
  {
    path: '/admin/laborprofiles',
    title: 'Labor Profiles',
    component: LaborProfiles,
    permissions: permissions.laborProfiles.menu,
    identifier: 'labor_profile',
  },

  {
    path: '/laborprofiles/view/:installerId',
    title: 'Labor Profile Details',
    component: AddViewEditLaborProfileDetails,
    permissions: permissions.laborProfiles.menu,
    identifier: 'view_labor_profile_details',
  },
  {
    path: '/laborprofiles/edit/:installerId',
    title: 'Labor Profile Details',
    component: AddViewEditLaborProfileDetails,
    permissions: permissions.laborProfiles.laborProfileEdit,
    identifier: 'edit_labor_profile_details',
  },
  {
    path: '/laborprofiles/add',
    title: 'Labor Profile Details',
    component: AddViewEditLaborProfileDetails,
    permissions: permissions.laborProfiles.laborProfileAdd,
    identifier: 'add_labor_profile_details',
  },
  {
    path: '/admin/usermanagement',
    title: 'User Management',
    component: UserManagement,
    permissions: permissions.userManagement.menu,
    identifier: 'user_management',
  },

  {
    path: '/admin/usermanagement/view/:userId',
    title: 'View User Details',
    component: AddViewEditUserDetails,
    permissions: permissions.userManagement.menu,
    identifier: 'view_user_management',
  },
  {
    path: '/admin/usermanagement/edit/:userId',
    title: 'Edit User Details',
    component: AddViewEditUserDetails,
    permissions: permissions.userManagement.userManagementEdit,
    identifier: 'edit_user_management',
  },
  {
    path: '/admin/usermanagement/add',
    title: 'User Details',
    component: AddViewEditUserDetails,
    permissions: permissions.userManagement.userManagementAdd,
    identifier: 'add_user_management',
  },
  {
    path: '/admin/usermanagement/user-form/add',
    title: 'User Form',
    component: UserFormLayout,
    permissions: permissions.userManagement.userManagementAdd,
    identifier: 'add_user_form',
  },
  {
    path: '/admin/usermanagement/user-form/view/:user',
    title: 'User Form',
    component: UserFormLayout,
    permissions: permissions.userManagement.userManagementEdit,
    identifier: 'view_user_form',
  },
  {
    path: '/admin/usermanagement/user-form/edit/:user',
    title: 'User Form',
    component: UserFormLayout,
    permissions: permissions.userManagement.userManagementEdit,
    identifier: 'edit_user_form',
  },
  {
    path: '/admin/rolemanagement',
    title: 'Role Management',
    component: RoleManagement,
    permissions: permissions.userManagement.userManagementManageRolesView,
    identifier: 'role_management',
  },
  {
    path: '/admin/rolemanagement/:role_id/permissions',
    title: 'Role Permissions',
    component: Permissions,
    identifier: 'role_permission',
  },
  {
    path: '/scheduler',
    title: 'Scheduler',
    component: ProjectReport,
    permissions: permissions.viewEditProject.actionScheduler,
    identifier: 'schedular',
  },
  {
    path: '/scheduler-beta',
    title: 'Scheduler',
    component: Scheduler,
    permissions: permissions.viewEditProject.actionScheduler,
    identifier: 'schedular',
  },
  {
    path: '/calendar',
    title: 'Calendar',
    component: ProjectReport,
    permissions: permissions.viewEditProject.actionScheduler,
    identifier: 'calendar',
  },
  {
    path: '/calendar-beta',
    title: 'Calendar Beta',
    component: Scheduler,
    permissions: permissions.viewEditProject.actionScheduler,
    identifier: 'calendar',
  },
  {
    path: '/scheduler/:projectNumber',
    title: 'Scheduler',
    component: ProjectReport,
    permissions: permissions.viewEditProject.actionScheduler,
    identifier: 'scheduler_by_project_number',
  },
  {
    path: '/scheduler-beta/:projectNumber',
    title: 'Scheduler',
    component: Scheduler,
    permissions: permissions.viewEditProject.actionScheduler,
    identifier: 'scheduler_by_project_number',
  },
  {
    path: '/admin/dashboards',
    title: 'Dashboard',
    component: DashboardList,
    permissions: permissions.dashboardManagement.menu,
    identifier: 'dashboard_list',
  },
  {
    path: '/admin/dashboard/view/:id',
    title: 'View Dashboard',
    component: AddViewEditDashboard,
    permissions: permissions.dashboardManagement.menu,
    identifier: 'view_dashboard',
  },
  {
    path: '/admin/dashboard/edit/:id',
    title: 'Edit Dashboard',
    component: AddViewEditDashboard,
    permissions: permissions.dashboardManagement.editDashboard,
    identifier: 'edit_dashboard',
  },
  {
    path: '/admin/dashboard/create/:id',
    title: 'Create Dashboard',
    component: AddViewEditDashboard,
    permissions: permissions.dashboardManagement.createDashboard,
    identifier: 'create_dashboard',
  },
  {
    path: '/admin/ruleenginelogs',
    title: 'Rule Engine Logs',
    component: RuleEngineLogs,
    permissions: permissions.ruleEngineLog.menu,
    identifier: 'rule_engine_logs',
  },
  {
    path: '/admin/ruleenginelogs/:id',
    title: 'Engine Result',
    component: RuleEngineLogs,
    permissions: permissions.ruleEngineLog.menu,
    identifier: 'rule_engine_logs',
  },
  {
    path: '/admin/engine-result',
    title: 'Engine Result',
    component: EngineResult,
    permissions: permissions.ruleEngineLog.menu,
    identifier: 'engine_results',
  },
  {
    path: '/admin/tenant-billing',
    title: 'Tenant Billing',
    component: TenantBilling,
    permissions: permissions.ruleEngineLog.menu,
    identifier: 'tenant_billing',
  },
  {
    path: '/admin/bulletin',
    title: 'Bulletin',
    component: Bulletin,
    permissions: permissions.ruleEngineLog.menu,
    identifier: 'bulletin',
  },
  {
    path: '/personnel/availabilityupdates',
    title: 'Availability Updates',
    component: AvailabilityUpdates,
    permissions: permissions.availabilityUpdates.menu,
    identifier: 'availability_updates',
  },
  {
    path: '/admin/userguide',
    title: 'Page Updates',
    component: PageMetaUpdate,
    // permissions: permissions.pageMetaUpdates.menu,
    identifier: 'page_updates_userguide',
  },
  {
    path: '/widget/details/:widgetId',
    title: 'Widget Details',
    component: ReportDetails,
    identifier: 'widgets_details',
  },
  {
    path: '/admin/widgets',
    title: 'Widgets',
    component: WidgetTemplateList,
    permissions: permissions.widgetManagement.menu,
    identifier: 'widgets',
  },
  {
    path: '/admin/widget/create',
    title: 'Create Widget',
    component: Widget,
    permissions: permissions.widgetManagement.createWidget,
    identifier: 'create_widgets',
  },
  {
    path: '/admin/widget/view/:widgetId',
    title: 'View Widget',
    component: Widget,
    permissions: permissions.widgetManagement.menu,
    identifier: 'view_widgets',
  },
  {
    path: '/admin/widget/edit/:widgetId',
    title: 'Edit Widget',
    component: Widget,
    permissions: permissions.widgetManagement.editWidget,
    identifier: 'edit_widgets',
  },
  {
    path: '/payment-request/:payment_request_id?',
    title: 'Payment Request',
    component: Billing,
    permissions: permissions.viewEditProject.paymentRequestMenu,
    identifier: 'payment_request',
  },
  {
    path: '/lrrp-configuration',
    title: 'LRRP COnfiguration',
    component: LrrpConfiguration,
    permissions: permissions.ruleEngineLog.menu,
    identifier: 'lrrp_configuration',
  },
  {
    path: '/ro-config',
    title: 'Route Configuration',
    component: RoConfiguration,
    identifier: 'route_config',
  },
  {
    path: '/crm/quote-managment',
    title: 'Quote Management',
    component: QuoteManagement,
  },
  {
    path: '/client/:customerId/crm/:tag/:quoteId/:action',
    title: 'Quote',
    component: Quote,
  },
  {
    path: '/client/:customerId/crm/:tag/create',
    title: 'Create Quote',
    component: Quote,
  },
  {
    path: '/crm/:mode/customer',
    title: 'Clients',
    component: CustomerContainer,
    permissions: permissions.clients.addClient,
    identifier: 'clients_crm_mode',
  },
  {
    path: '/crm/view/customer/:customerId',
    title: 'Clients',
    component: CustomerContainer,
    permissions: permissions.clients.menu,
    identifier: 'clients_crm_mode_by_id',
  },
  {
    path: '/crm/:mode/customer/:customerId',
    title: 'Clients',
    component: CustomerContainer,
    permissions: permissions.clients.addClient,
    identifier: 'clients_crm_mode_by_id',
  },
  {
    path: '/warranty-config',
    title: 'Warranty Configuration',
    component: WarrantyConfiguration,
    identifier: 'warranty_config',
  },
  {
    path: '/crm/:customerId/lead/:mode/:actionId?',
    title: 'Opportunity',
    component: OpportunityContainer,
    permissions: permissions.clients.addClient,
    identifier: 'opportunity',
  },
  {
    path: '/crm-config',
    title: 'CRM Configuration',
    component: CrmConfiguration,
    identifier: 'crm_config',
  },
  {
    path: '/admin/configuration',
    title: 'Company Configuration',
    component: CompanyConfig,
    permissions: permissions.ruleEngineLog.menu,
    identifier: 'new_config',
  },
  {
    path: '/admin/company-config/edit/:id',
    title: 'Edit Company Configuration',
    component: EditCompanyConfig,
    identifier: 'edit_company_config',
  },
  {
    path: '/admin/company/business-hour-config/edit/:id',
    title: 'Business Hour Configurations',
    component: BusinessHourConfig,
    identifier: 'edit_company_business_hour_config',
  },
  {
    path: '/admin/company/cx-scheduling-config/edit/:id',
    title: 'Customer Scheduling Configurations',
    component: CxSchedulingConfig,
    identifier: 'edit_company_cx_scheduling_config',
  },
  {
    path: '/admin/company/service-time-config/edit/:id',
    title: 'Service Time Configurations',
    component: ServiceTimeConfig,
    identifier: 'edit_company_service_time_config',
  },
  {
    path: '/access-denied',
    title: 'Access Denied',
    component: AccessDenied,
    identifier: 'denied_access',
  },
  {
    path: '/crm/:customerId/quotes/:opportunityId?',
    title: 'Quotes',
    component: QuoteContainer,
    permissions: permissions.clients.addClient,
    identifier: 'quotes',
  },
  {
    path: '/crm/:customerId/:opportunityId/quote/:mode/:actionId?',
    title: 'Quote',
    component: QuoteForm,
    permissions: permissions.clients.addClient,
    identifier: 'quotes_by_opportunity_id',
  },
  {
    path: '/admin/chargeback/:action',
    title: 'Chargeback',
    component: ChargebackAction,
    identifier: 'chargeback',
  },
  {
    path: '/admin/chargeback/:action/project/:projectId/chargeback/:chargebackId',
    title: 'Chargeback',
    component: ChargebackAction,
    identifier: 'chargeback_by_project_id',
  },
  {
    path: '/admin/chargeback/:action/chargeback/:chargebackId',
    title: 'Chargeback',
    component: ChargebackAction,
    identifier: 'chargeback_by_id',
  },
  {
    path: '/admin/chargeback/:action/project/:projectId',
    title: 'Chargeback',
    component: ChargebackAction,
    identifier: 'charge_back',
  },
  {
    path: '/access-denied',
    title: 'Access Denied',
    component: AccessDenied,
    identifier: 'denied_access',
  },
  {
    path: '/payments-config',
    title: 'Payments Configuration',
    component: PaymentsConfiguration,
    identifier: 'paymen_config',
  },
  {
    path: '/payments/config/edit',
    title: 'Payments Configuration Edit',
    component: PaymentsConfigurationEdit,
    identifier: 'edit_payment_config',
  },
  {
    path: '/onboarding-success',
    title: 'Onboard Success',
    component: OnboardSuccess,
    identifier: 'onboard_success',
  },
  {
    path: '/onboarding-refresh',
    title: 'Onboard Refresh',
    component: OnboardRefresh,
    identifier: 'onboard_refresh',
  },
  {
    path: '/audit-log-reports',
    title: 'Audit Log Reports',
    component: ProjectAuditLog,
  },
  {
    path: '/create-audit-log-report/add',
    title: 'Create Audit Log Report',
    component: ProjectAuditLogCreate,
  },
  {
    path: '/create-audit-log-report/edit/:auditId',
    title: 'Create Audit Log Report',
    component: ProjectAuditLogCreate,
  },
  {
    path: '/crm/:customerId/:opportunityId/project/view/:projectId?',
    title: 'Project Details',
    component: AddViewEditProjectDetails,
    permissions: permissions.project.viewProject,
  },
  {
    path: '/crm/:customerId/:opportunityId/project/edit/:projectId?',
    title: 'Project Details',
    component: AddViewEditProjectDetails,
    permissions: permissions.project.editProject,
  },
  {
    path: '/crm/:customerId/:opportunityId/invoice/:mode/:actionId?',
    title: 'Invoice',
    component: Invoice,
    permissions: permissions.clients.addClient,
  },
  {
    path: '/crm/invoice/:mode/:actionId?',
    title: 'Invoice',
    component: Invoice,
    permissions: permissions.clients.addClient,
  },
  {
    path: '/:opportunityId/:quoteId/project/:actionId',
    title: 'Project Details',
    component: AddViewEditProjectDetails,
    permissions: permissions.project.addProject,
    identifier: 'project_details_by_opportunity_id',
  },
  {
    path: `/technician-po/:projectId/:technicianId/:technicianUserId`,
    title: 'Technician-po',
    component: TechnicianPurchaseOrder,
  },
  {
    path: `/technician-po/:projectId/default-po`,
    title: 'Technician-po',
    component: TechnicianPurchaseOrderDefault,
  },
  {
    path: '/measure-square-config',
    title: 'MeasureSquare Configuration',
    component: MeasureSquareConfiguration,
    identifier: 'measure_square_config',
  },
  {
    path: '/crm/:customerId/projects/:opportunityId?',
    title: 'Projects',
    component: QuoteContainer,
    identifier: 'projects',
  },
  {
    path: '/crm/add/:opportunityId',
    title: 'Project Details',
    component: AddViewEditProjectDetails,
    permissions: permissions.project.addProject,
  },
  {
    path: '/admin/payroll',
    title: 'Payroll',
    component: PayrollContainer,
  },
  {
    path: '/admin/checklist',
    title: 'Checklist',
    component: QuestionnaireComponent,
    permissions: permissions.questionnaire.view,
  },
  {
    path: '/admin/export/reports',
    title: 'Exported Reports',
    component: ReportContainer,
  },
  {
    path: '/project-color-code',
    title: 'Add New Color',
    component: ProjectColorCode,
  },
  {
    path: '/admin/dashboard-performance-analysis',
    title: 'Dashboard Performance Analysis',
    component: DashboardPerformanceAnalysisContainer,
  },
  {
    path: '/data-import',
    title: 'Data Import',
    component: ImportData,
  },
  {
    path: '/admin/configuration/group-source-category',
    title: 'Group Source Category',
    component: GroupSourceCategory,
  },
  {
    path: '/admin/configuration/group-source-category/add',
    title: 'Group Source Category',
    component: AddEditGroupSource,
  },
  {
    path: '/admin/configuration/group-source-category/edit/:id',
    title: 'Group Source Category',
    component: AddEditGroupSource,
  },
  {
    path: '/admin/configuration/scheduler-rts-report',
    title: 'Scheduler RTS Report Filters',
    component: SchedulerRTSReportFilter,
  },
  {
    path: '/change-password',
    title: 'Change Password',
    component: ChangePassword,
  },
  {
    path: '/logs/login-logs',
    title: 'Login Logs',
    component: LoginLogs,
  },
  {
    path: '/logs/reset-password-logs',
    title: 'Reset Password Logs',
    component: ResetPasswordLogsContainer,
  },
  {
    path: '/logs/customer-login-logs',
    title: 'Customer Login Logs',
    component: CustomerLoginLogs,
  },
  {
    path: '/logs/customer-register-logs',
    title: 'Customer Register Logs',
    component: CustomerRegisterLogs,
  },
  {
    path: '/logs/customer-reset-password-logs',
    title: 'Customer Reset Password Logs',
    component: CustomerResetPasswordLogs,
  },
  {
    path: '/admin/company/configuration/related-po/edit/:id',
    title: 'Related POs',
    component: RelatedPOs,
  },
];
