import React, { useState, useEffect } from 'react';
import { Message } from 'primereact/message';

import usePFDataTable from '../../../../hooks/PFDatatable';
import { getInvoiceList, invoiceGridColumns } from '../helpers/Invoice.js';
import PFTableLoader from '../../../shared/Loader/PFTableLoader';
import { Skeleton } from 'primereact/skeleton';

const InvoiceLising = ({ projectId }) => {
  const {
    layout: TableLayout,
    columns: DataTableColumns,
    totalRecords,
    data: tableData,
    rows,
    page,
    sortBy,
    orderBy,
    setDefaultTableProps,
  } = usePFDataTable();
  const [loading, setLoading] = useState(false);
  const [totalInvoiceAmount, setTotalInvoiceAmount] = useState(0);

  const invoiceApiCall = () => {
    setLoading(true);

    const listing = getInvoiceList(projectId);
    listing
      .then(data => {
        if (data?.status === 200) {
          const listData = data?.data?.data;
          const sum = listData.reduce(
            (sum, item) => sum + (item.total_invoice_amount || 0),
            0
          );
          setTotalInvoiceAmount(sum);
          tableData(listData);
          totalRecords(listData.length || 0);
          setLoading(false);
        }
      })
      .catch(error => console.error(error));
  };

  useEffect(() => {
    setDefaultTableProps(preProps => ({ ...preProps, lazy: false }));
    DataTableColumns(invoiceGridColumns());
    invoiceApiCall();
  }, []);

  return (
    <>
      <div className="pt-2">
        <div className="py-2">
          {loading ? (
            <Skeleton className="h-3rem w-20rem surface-100"></Skeleton>
          ) : (
            <Message
              severity="info"
              text={() => {
                return (
                  <div>
                    <strong>
                      Total Invoice Amount: $ {totalInvoiceAmount?.toFixed(2)}
                    </strong>
                  </div>
                );
              }}
              className="border-primary w-full justify-content-start"
              style={{
                border: 'solid #696cff',
                borderWidth: '0 0 0 6px',
              }}
              pt={{
                root: {
                  className: 'surface-ground',
                },
              }}
            />
          )}
        </div>
        {loading ? (
          <PFTableLoader />
        ) : (
          <div className="mt-3">
            <TableLayout />
          </div>
        )}
      </div>
    </>
  );
};

export default InvoiceLising;
