import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { InputTextarea } from 'primereact/inputtextarea';

import { setNewSchedulerComponent } from '../../../redux/slices/new-scheduler.slice';

import { addInstallerUnavailablilty } from './service/new-scheduler.service';

const BlockInstallerDialog = () => {
  const dispatch = useDispatch();
  const { isSelectDialogOpen, newEventInfo, installersList, viewChangeFlag } =
    useSelector(state => state.newScheduler);
  const { technicians } = useSelector(state => state.technicians);
  const { techniciansLimitList } = useSelector(
    state => state.techniciansLimitList
  );
  const techniciansList = Array.isArray(techniciansLimitList)
    ? techniciansLimitList?.map(item => {
        return {
          name: `${item?.first_name} ${item?.last_name}`,
          id: item?.installer_id,
          installerUserId: item?.user_id,
        };
      })
    : [];
  const toast = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleFormValidate = values => {
    let error = {};
    if (values?.start && values?.end && values?.end < values?.start) {
      error.end = 'End Time must be greater than Start Time.';
    }
    return error;
  };

  const blockInstallerValidationSchema = Yup.object().shape({
    installer: Yup.object()
      .shape({
        installerUserId: Yup.string().required('Required'),
        name: Yup.string().required('Required'),
        id: Yup.number().required('Required'),
      })
      .required('Select a technician'),
    start: Yup.string().required('Required'),
    end: Yup.string().required('Required'),
    note: Yup.string().required('Note is required.'),
  });

  const formik = useFormik({
    initialValues: {
      installer: null,
      start: newEventInfo?.start,
      end: newEventInfo?.end,
      note: '',
    },
    validationSchema: blockInstallerValidationSchema,
    validate: handleFormValidate,
    onSubmit: async values => {
      try {
        setIsLoading(true);
        const unavailabilityPayload = {
          user_id: values?.installer?.installerUserId,
          time_off_start_date: values?.start?.toISOString(),
          time_off_end_date: values?.end?.toISOString(),
          time_off_note: values?.note,
          is_blocked_time: 1,
        };
        const response = await addInstallerUnavailablilty(
          values?.installer?.id,
          unavailabilityPayload
        );
        if (response?.status === 200) {
          toast.current.show({
            severity: 'success',
            summary: 'Confirmed',
            detail: 'New Event created successfully',
            life: 3000,
          });
        } else {
          toast.current.show({
            severity: 'error',
            summary: 'Error',
            detail: 'Event did not save',
            life: 3000,
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
        dispatch(
          setNewSchedulerComponent({
            isSelectDialogOpen: false,
            viewChangeFlag: !viewChangeFlag,
          })
        );
      }
    },
    enableReinitialize: true,
  });

  const handleDialogOnHide = () => {
    formik.resetForm();
    dispatch(
      setNewSchedulerComponent({ isSelectDialogOpen: false, newEventInfo: {} })
    );
  };

  return (
    <>
      <Dialog
        header="Block Technician Time"
        visible={isSelectDialogOpen}
        className="w-11 lg:w-6 xl:w-5"
        onHide={handleDialogOnHide}
        draggable={false}
      >
        {isLoading ? (
          <div className="w-full h-full flex justify-content-center align-items-center">
            <ProgressSpinner />
          </div>
        ) : (
          <div className="flex flex-column gap-2 mt-3">
            <div className="flex flex-row">
              <label
                htmlFor="installer"
                className="w-5 lg:w-3 align-self-center"
              >
                Select Technician
              </label>
              <Dropdown
                id="installer"
                value={formik.values.installer}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                options={techniciansList}
                optionLabel="name"
                className="w-7 lg:w-9"
              />
            </div>
            <div className="flex flex-row">
              <label htmlFor="start" className="w-5 lg:w-3 align-self-center">
                Start
              </label>
              <Calendar
                id="start"
                className="w-7 lg:w-9"
                value={formik.values.start}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                showTime
                hourFormat="12"
              />
            </div>
            <div className="flex flex-column">
              <div className="flex flex-row">
                <label htmlFor="end" className="w-5 lg:w-3 align-self-center">
                  End
                </label>
                <Calendar
                  id="end"
                  className="w-7 lg:w-9"
                  value={formik.values.end}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  showTime
                  hourFormat="12"
                />
              </div>
              {formik.errors?.end && (
                <div className="mt-1 text-red-600">{formik.errors?.end}</div>
              )}
            </div>
            <div className="flex flex-column">
              <div className="flex flex-row">
                <label htmlFor="note" className="w-5 lg:w-3 align-self-center">
                  Note
                </label>
                <InputTextarea
                  id="note"
                  name="note"
                  value={formik?.values?.note}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="w-7 lg:w-9 "
                />
              </div>
              {formik.touched?.note && formik.errors?.note && (
                <div className="mt-1 text-red-600">{formik.errors?.note}</div>
              )}
            </div>
            <div className="flex flex-row mt-4 justify-content-end">
              <Button
                label="Submit"
                size="small"
                severity="primary"
                onClick={formik.handleSubmit}
                disabled={!formik.dirty || !formik.isValid}
              />
            </div>
          </div>
        )}
      </Dialog>
      <Toast ref={toast} />
    </>
  );
};

export default BlockInstallerDialog;
