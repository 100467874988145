import { Grid, CircularProgress } from '@material-ui/core';
import axios from 'axios';
import React, { useState, useEffect, useMemo } from 'react';
import { useHistory, Switch, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
// **** Styles/Images/Icons ****

import dayjs from 'dayjs';
import { Skeleton } from 'primereact/skeleton';

import {
  REACT_APP_LOGIN_AUTH,
  REACT_APP_AUTH_CLIENT_SECRET,
  REACT_APP_AUTH_CLIENT_ID,
  REACT_APP_AUTH_REFRESH_GRANT_TYPE,
  REACT_APP_YOUTUBE_URL,
  REACT_APP_DW_BETA,
} from '../src/constants/envConstants';
import { setSideDrawer } from '../src/redux/slices/page-header.slice';

import { useStyles } from './App.styles';
import '@material-icons/font/css/all.css';
import 'primeflex/primeflex.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-blue/theme.css';
import './components/styles/style.css';
import './components/ResponsiveStyles/ResponsiveStyle.css';

import Topbar from './components/shared/Topbar/Topbar';
import { Routes } from './Routes';
import { generateToken } from './services/auth.service';
import useToken from './hooks/useToken';
import { authURL } from './config/variables';
import { getClientDetails } from './components/Admin/Configuration/RestrictAccessbyRole.service';
import { setMomentTimezone, lockGlobalBar } from './utils/Helpers';
import globalContext from './contexts/globalContext';
import { AuthRoutes } from './components/Admin/Auth/Routes/AuthRoutes';
import { ReturnToLogin } from './components/Admin/Auth/Routes/ReturnToLogin';
import LoginSkeleton from './components/Skeleton/LoginSkeleton';
import ErrorBoundaryComponent from './ErrorBoundaryComponent';
import fetchMasterDataHook from './redux/slices/master/fetchMasterDataHook';
import ChangePassword from './components/Admin/Auth/Login/ChangePassword';
import { StatusColors } from './constants';
import SessionExpiredPopup from './components/LoginPage/SessionExpirePopUp';
import { loadChatScript } from './components/shared/chatbot';

const drawerWidth = 220;
const PASSWORD_EXPIRY_STATUS = {
  EXPIRED: 1,
  EXPIRING: 2,
};
const App = () => {
  const history = useHistory();
  const { accessToken, setToken, expireToken } = useToken();
  const classes = useStyles({ drawerWidth });
  const [loading, setLoading] = useState(true);
  const [passwordExpiryStatus, setPasswordExpiryStatus] = useState(0);
  const [slideDrawer, setSlideDrawer] = useState(
    parseInt(localStorage.getItem('sidebar-layout')) == 0 ? true : false
  );
  const [cubejsAuthorizationToken, setCubejsAuthorizationToken] =
    useState(false);
  const [isLoggedOut, setIsLoggedOut] = useState(false);
  const [loggedOutRes, setIsLoggedOutRes] = useState(null);
  const queryParams = new URLSearchParams(window.location.search);
  const code = queryParams.get('code');
  const state = queryParams.get('state');
  const dispatch = useDispatch();

  const permissionList = useSelector(
    state => state.permissionsList.permissionList
  );

  useEffect(() => {
    dispatch(setSideDrawer(slideDrawer));
  }, [slideDrawer]);

  const DrawerWidths = current => {
    setSlideDrawer(current => {
      return !current;
    });
  };

  const clientDetails = JSON.parse(localStorage.getItem('client_details'));

  useEffect(() => {
    localStorage.setItem(
      'sidebar-visibility',
      !slideDrawer ? 'visible' : 'hidden'
    );
    window.dispatchEvent(new Event('storage'));

    const Data = 'cubejsAuthorizationToken';
  }, [slideDrawer]);

  const fetchMasterData = fetchMasterDataHook();

  useEffect(() => {
    if (accessToken === null || accessToken === '') {
      if (code) {
        generateToken(code, setToken);
        if (state && state !== 'undefined') {
          history.push(decodeURIComponent(state));
        } else {
          window.location = `${document.location.origin}/`;
        }
      }
    }
  }, []);

  const fetchToken = async () => {
    const generatedTokenResponse = await axios.post(
      REACT_APP_LOGIN_AUTH + '/regenerate-token',
      {
        client_id: localStorage.getItem('client_id'),
        user_id: localStorage.getItem('user_id'),
      }
    );
    if (
      generatedTokenResponse?.data &&
      generatedTokenResponse?.data?.access_token
    ) {
      setCubejsAuthorizationToken(true);
      localStorage.setItem(
        'cubejsAuthorizationToken',
        'Bearer ' + generatedTokenResponse.data.access_token
      );
    } else {
      fetchToken();
    }
  };
  const fetchPasswordExpiryDetails = async () => {
    const passwordExpiryDetails = await axios.get(
      REACT_APP_LOGIN_AUTH + '/password-expiry'
    );
    if (passwordExpiryDetails.data.daysPending) {
      if (passwordExpiryDetails.data.daysPending < 0) {
        setPasswordExpiryStatus(PASSWORD_EXPIRY_STATUS.EXPIRED);
      } else if (passwordExpiryDetails.data.daysPending <= 10) {
        setPasswordExpiryStatus(PASSWORD_EXPIRY_STATUS.EXPIRING);
        confirmDialog({
          message: (
            <div className="flex flex-column align-items-center w-full gap-3 surface-border">
              <span>
                <i
                  className="pi pi-exclamation-circle "
                  style={{ color: StatusColors.PF_RED }}
                ></i>{' '}
                {`Your password will expire in 
                  ${passwordExpiryDetails.data.daysPending}
                   days. Please change your password.`}
              </span>
            </div>
          ),
          header: 'Change Password Confirmation',
          position: 'top',
          acceptLabel: 'Change Password',
          rejectLabel: 'Remind Me Later',
          acceptIcon: 'pi pi-arrow-right',
          accept: () => {
            history.push('/change-password');
          },
          reject: () => {
            localStorage.setItem('isShowPasswordChangeConfirmation', false);
          },
        });
      }
    }
  };
  useEffect(() => {
    if (localStorage.getItem('client_id')) {
      fetchToken();
      if (!localStorage.getItem('isShowPasswordChangeConfirmation')) {
        fetchPasswordExpiryDetails();
      }
    }
  }, [localStorage.getItem('client_id')]);

  axios.interceptors.request.use(async config => {
    try {
      const accessToken = localStorage.getItem('access_token');
      const refreshToken = localStorage.getItem('refresh_token');
      const expiresIn = localStorage.getItem('exp');
      if (accessToken && refreshToken) {
        const user = accessToken;
        const isExpired = dayjs.unix(expiresIn).diff(dayjs()) < 1;
        if (isExpired) {
          expireToken();
        }

        if (!isExpired) return config;
        if (config?.data?.grant_type !== REACT_APP_AUTH_REFRESH_GRANT_TYPE) {
          delete axios.defaults.headers.common['Authorization'];
          const response = await axios.post(`${authURL}/token`, {
            refresh_token: refreshToken,
            client_secret: REACT_APP_AUTH_CLIENT_SECRET,
            client_id: REACT_APP_AUTH_CLIENT_ID,
            grant_type: REACT_APP_AUTH_REFRESH_GRANT_TYPE,
          });

          if (
            response?.status === 200 &&
            response.data.refresh_token &&
            response.data.access_token
          ) {
            // clear forgot password and login failed attempt count
            localStorage.removeItem('loginSubmitCount');
            localStorage.removeItem('resetPasswordSubmitCount');
            setToken(response.data);
            if (
              !config?.url.includes(`${authURL}/token`) &&
              !config?.url.includes(REACT_APP_YOUTUBE_URL) &&
              !config?.url.includes(REACT_APP_CUBE_BASEURL)
            ) {
              config.headers['Authorization'] =
                'Bearer ' + response.data.access_token;
              axios.defaults.headers.common.Authorization = `Bearer ${response.data.access_token}`;
              ('Token parsing called');
              setTimeout(() => {
                axios.headers.client_id = localStorage.getItem('client_id');
                window.location.reload();
              }, 2000);
            }
          }
        }
      }
      return config;
    } catch (err) {
      expireToken();
    }
  });

  useEffect(() => {
    axios.interceptors.request.use(async config => {
      try {
        if (
          ![
            `${authURL}/token`,
            `${authURL}/get-all-user-tenants`,
            REACT_APP_YOUTUBE_URL,
          ].includes(config?.url)
        ) {
          axios.defaults.headers.common['client_id'] =
            localStorage.getItem('client_id');
        }
        return config;
      } catch (err) {
        console.error('interceptor', err);
      }
    });
  }, [localStorage.getItem('client_id')]);

  axios.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (error?.status === 406 && !isLoggedOut) {
        setIsLoggedOutRes(error?.response?.data);
        setIsLoggedOut(true);
        return;
      }
      if (
        error.response &&
        error.response.data &&
        (error.response.data?.name === 'TokenExpiredError' ||
          error.response.data?.message === 'jwt expired')
      ) {
        expireToken();
      }
      return Promise.reject(error);
    }
  );

  if (accessToken) {
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  }

  const setClientDetails = async () => {
    setLoading(true);
    const response = await getClientDetails();
    if (response) {
      const isTentativeSchedulingEnabled =
        response?.tenant_permissions[0]?.included_permissions?.includes(
          'ENABLE_TENTATIVE_SCHEDULING'
        );
      localStorage.setItem(
        'isTentativeSchedulingEnabled',
        isTentativeSchedulingEnabled
      );
      localStorage.setItem('client_timezone', response.client_timezone);
      setMomentTimezone(response.client_timezone);
      localStorage.setItem('client_details', JSON.stringify(response));
    }
    setLoading(false);
    fetchMasterData();
  };
  useEffect(() => {
    if (accessToken) {
      if (axios.defaults.headers.common['client_id']) {
        setClientDetails();
        const removeScripts = loadChatScript();
        return () => {
          removeScripts();
        };
      }
    }
  }, [accessToken, permissionList]);
  const globalBarStyle = useMemo(() => {
    return lockGlobalBar();
  }, [clientDetails]);

  const dynamicHeight = useMemo(() => {
    return globalBarStyle
      ? window.cxcScheduleAlert || window.marqueeText
        ? 'margin-top-110'
        : 'mt-8'
      : window.cxcScheduleAlert || window.marqueeText
        ? 'mt-7'
        : 'mt-0';
  }, [globalBarStyle, window.cxcScheduleAlert, window.marqueeText]);
  if (code) {
    if (!loading) {
      const client_id = localStorage.getItem('client_id');
      if (client_id) {
        const allowedClients = REACT_APP_DW_BETA?.split(',') || [];
        const checkNewClient = allowedClients.includes(client_id);
        if (checkNewClient) {
          history.push('/dashboard');
        }
      }
    } else {
      return <LoginSkeleton />;
    }
  }
  if (accessToken) {
    if (history.location.pathname === '/') {
      history.push('/dashboard');
    }
  }

  if (passwordExpiryStatus == PASSWORD_EXPIRY_STATUS.EXPIRED) {
    return (
      <ChangePassword
        message={
          'Your password has expired. Please change your password to continue using your account.'
        }
      />
    );
  }

  if (isLoggedOut) {
    return <SessionExpiredPopup open={isLoggedOut} data={loggedOutRes} />;
  }

  return accessToken ? (
    <globalContext.Provider value={accessToken}>
      <Topbar DrawerWidths={DrawerWidths} slideDrawer={slideDrawer} />
      <div
        style={{ paddingLeft: slideDrawer ? '2rem' : '16rem' }}
        className={`grid grid-nogutter pt-5 pr-5 pb-5 transition-ease-in-out transition-duration-500 light-grey-bg-100 ${dynamicHeight}`}
      >
        <ErrorBoundaryComponent>
          {cubejsAuthorizationToken && <Routes />}{' '}
        </ErrorBoundaryComponent>
      </div>
      <ConfirmDialog />
    </globalContext.Provider>
  ) : (
    <ErrorBoundaryComponent>
      <Switch>
        {AuthRoutes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            exact={true}
            component={route.component}
          />
        ))}
        <Route path="*" exact={true} component={ReturnToLogin} />
      </Switch>
    </ErrorBoundaryComponent>
  );
};

export default App;
