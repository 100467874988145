import React, { useEffect, useState, useRef, useMemo } from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { DomHandler } from 'primereact/utils';
import { useSelector, useDispatch } from 'react-redux';
import { InputNumber } from 'primereact/inputnumber';
import { confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';

import { setUserAddressForm } from '../../../redux/slices/user-form-address.slice';
import SkeletonLoader from '../../shared/Loader/skeleton';
import {
  validateCoordinates,
  hasSpecialCharacters,
  containsNumbers,
} from '../../../utils/Helpers';
import TableLoader from '../../shared/Loader/TableLoader';
import { validateNumber } from '../../../utils/validation';

import { domHandlerCall } from './common';
import {
  deleteUserAddress,
  editUserAddress,
  getUserAddressData,
} from './services/user-form-address.service';
import TableColumnsLoader from '../../shared/Loader/tableColumnsLoader';

const emptyAddressRow = {
  name: '',
  address: '',
  city: '',
  state: '',
  zipcode: null,
};

const UserFormAddress = () => {
  const { isLoading, isDeleteDialogOpen, statesList, addressData } =
    useSelector(state => state.userFormAddress);
  const { isFormDataChanged, mode, userId } = useSelector(
    state => state.userForm
  );
  const dispatch = useDispatch();
  const toast = useRef(null);
  const addressSection = useRef(null);
  const [isDisableAddButton, setIsDisableAddButton] = useState(false);

  const handleDelete = async (rowIndex, id) => {
    let deleteResponse;
    try {
      if (id) {
        dispatch(setUserAddressForm({ isLoading: true }));
        deleteResponse = await deleteUserAddress(id);
        if (deleteResponse?.status === 200) {
          toast.current.show({
            severity: 'success',
            summary: 'Deleted row',
            life: 2000,
          });
        } else {
          toast.current.show({
            severity: 'error',
            summary: 'Something went wrong',
            life: 2000,
          });
          return;
        }
      }
      let editedAddressData = structuredClone(addressData);
      editedAddressData.splice(rowIndex, 1);
      dispatch(setUserAddressForm({ addressData: editedAddressData }));
    } catch (err) {
      toast.current.show({
        severity: 'error',
        summary: 'Something went wrong',
        life: 2000,
      });
    } finally {
      dispatch(setUserAddressForm({ isLoading: false }));
    }
  };

  const processAddressData = (addressData, rowIndex) => {
    const editedAddressData = structuredClone(addressData);
    editedAddressData.splice(rowIndex, 1);
    const rowData = addressData[rowIndex];
    const isRowEmpty =
      !rowData?.name &&
      !rowData?.address &&
      !rowData?.city &&
      !rowData?.state &&
      !rowData?.zipcode;
    return {
      editedAddressData,
      isRowEmpty,
    };
  };

  const handleDeleteCancel = rowIndex => {
    const { editedAddressData, isRowEmpty } = processAddressData(
      addressData,
      rowIndex
    );
    if (isRowEmpty) {
      dispatch(setUserAddressForm({ addressData: editedAddressData }));
    } else {
      dispatch(
        setUserAddressForm({ isDeleteDialogOpen: false, deleteRowIndex: null })
      );
      disableEditButton(false);
    }
  };

  const fetchInitiationData = async () => {
    dispatch(
      setUserAddressForm({ isLoading: true, isDeleteDialogOpen: false })
    );
    try {
      const initializationData = !!userId && (await getUserAddressData(userId));
      const { statesList, addressInfo } = initializationData;
      const mappedAddressData = addressInfo?.map(row => {
        return {
          address_id: row?.address_id,
          name: row?.address_name,
          address: row?.Address?.address1,
          address2: row?.Address?.address2,
          city: row?.Address?.city,
          state: statesList.find(
            item => item?.state_id == row?.Address?.state_id
          ),
          zipcode: row?.Address?.zipcode,
          latitude: row?.Address?.latitude,
          longitude: row?.Address?.longitude,
        };
      });
      dispatch(
        setUserAddressForm({
          statesList,
          addressData: mappedAddressData,
        })
      );
    } catch (error) {
    } finally {
      dispatch(setUserAddressForm({ isLoading: false }));
    }
  };

  useEffect(() => {
    fetchInitiationData();
    setIsDisableAddButton(false);
  }, [userId]);

  const textEditor = options => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={e => options.editorCallback(e.target.value)}
      />
    );
  };

  const numberEditor = options => {
    return (
      <InputNumber
        value={options.value}
        onChange={e => options.editorCallback(e?.value)}
        useGrouping={false}
      />
    );
  };

  const StateTemplate = rowData => {
    return <span>{rowData?.state?.state_name}</span>;
  };

  const stateEditor = options => {
    return (
      <Dropdown
        value={options?.value}
        optionLabel="state_name"
        options={statesList}
        onChange={e => options.editorCallback(e.value)}
        placeholder="Select a State"
      />
    );
  };

  const handleAddNewRow = () => {
    dispatch(
      setUserAddressForm({
        addressData: [emptyAddressRow, ...addressData],
      })
    );
    setTimeout(() => {
      const edit = domHandlerCall('p-row-editor-init', addressSection.current);
      edit?.handler[0].click();
    }, 300);
  };

  const mapTableDataForPayload = row => {
    return {
      ...row,
      state: row?.state?.state_id,
    };
  };

  const onRowEditComplete = async e => {
    let editedAddress = structuredClone(addressData);
    let { newData, index } = e;
    editedAddress[index] = newData;
    dispatch(
      setUserAddressForm({ addressData: editedAddress, isLoading: true })
    );
    let editedResponse;
    try {
      const payload = mapTableDataForPayload(newData);
      editedResponse = await editUserAddress(userId, payload);
      if (editedResponse?.status === 200) {
        toast.current.show({
          severity: 'success',
          summary: 'Updated Address',
          life: 2000,
        });
      }
    } catch (err) {
      toast.current.show({
        severity: 'error',
        summary: 'Something went wrong',
        life: 2000,
      });
    } finally {
      fetchInitiationData();
      dispatch(setUserAddressForm({ isLoading: false }));
    }
    disableEditButton(false);
    setIsDisableAddButton(false);
  };

  const handleRowValidation = (e, f) => {
    const isDuplicate = addressData?.find(record => {
      if (record?.address_id != e?.address_id) {
        return e.name == record.name;
      }
    });

    const validationError = [];

    let isValidZip = false;
    if (validateNumber(e.zipcode) && e?.zipcode?.toString()?.length == 5) {
      isValidZip = true;
    }
    if (!e?.name || hasSpecialCharacters(e?.name) || containsNumbers(e?.name)) {
      validationError.push('Name');
    }
    if (!e?.address) {
      validationError.push('Address Line 1');
    }
    if (!(!!e.zipcode && !!isValidZip)) {
      validationError.push('Zip Code');
    }
    if (!e?.city || hasSpecialCharacters(e?.city)) {
      validationError.push('City');
    }
    if (!e?.state) {
      validationError.push('State');
    }

    const isValid = !validationError.length > 0;
    if (!isValid || isDuplicate) {
      const errorMessage = isDuplicate
        ? `Address already exist`
        : `${validationError.join(',')} invalid`;
      toast.current.show({
        severity: 'error',
        summary: errorMessage,
        life: 2000,
      });
      return false;
    }
    return isValid;
  };

  const handleDeleteDialogHide = rowIndex => {
    const { editedAddressData, isRowEmpty } = processAddressData(
      addressData,
      rowIndex
    );
    if (isRowEmpty) {
      dispatch(setUserAddressForm({ addressData: editedAddressData }));
      disableEditButton(false);
    } else {
      dispatch(setUserAddressForm({ isDeleteDialogOpen: false }));
      disableEditButton(false);
    }
  };

  const handleOnRowEditCancel = async e => {
    dispatch(
      setUserAddressForm({
        isDeleteDialogOpen: true,
        deleteRowIndex: e?.index,
        addressIdToDelete: e?.data?.address_id,
      })
    );
    confirmDialog({
      message:
        'Please confirm if you would like to cancel your unsaved changes or delete this record?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle text-xl',
      acceptClassName: 'p-button-primary p-button-sm',
      rejectClassName: 'p-button-sm p-button-outlined',
      accept: () => handleDelete(e?.index, e?.data?.address_id),
      reject: () => handleDeleteCancel(e?.index),
      onHide: () => handleDeleteDialogHide(e?.index),
      rejectLabel: 'Cancel',
      acceptLabel: 'Delete',
      className: 'text-sm w-11 md:w-8 lg:w-5',
    });
  };
  const disableEditButton = status => {
    setTimeout(() => {
      const edit = domHandlerCall('p-row-editor-init', addressSection.current);
      edit?.handler?.forEach(row => {
        row.disabled = status;
      });
      setIsDisableAddButton(status);
    }, 300);
  };

  const addressTableColumns = [
    {
      field: 'name',
      header: 'Name',
      ...(!isLoading
        ? {
            editor: options => textEditor(options),
          }
        : {}),
    },
    {
      field: 'address',
      header: 'Address Line 1',
      ...(!isLoading
        ? {
            editor: options => textEditor(options),
          }
        : {}),
    },
    {
      field: 'address2',
      header: 'Address Line 2',
      ...(!isLoading
        ? {
            editor: options => textEditor(options),
          }
        : {}),
    },
    {
      field: 'city',
      header: 'City',
      ...(!isLoading
        ? {
            editor: options => textEditor(options),
          }
        : {}),
    },
    {
      field: 'state',
      header: 'State',
      ...(!isLoading
        ? {
            editor: options => stateEditor(options),
            body: rowData => StateTemplate(rowData),
          }
        : {}),
    },
    {
      field: 'zipcode',
      header: 'Zipcode',
      ...(!isLoading
        ? {
            editor: options => textEditor(options),
          }
        : {}),
    },
    {
      field: 'latitude',
      header: 'Latitude',
    },
    {
      field: 'longitude',
      header: 'Longitude',
    },
    {
      field: 'edit',
      header: '',
      ...(!isLoading
        ? {
            rowEditor: true,
          }
        : {}),
      headerStyle: { width: '10%', minWidth: '7rem' },
      bodyStyle: { textAlign: 'center' },
    },
  ];

  const userAddressTableLoader = useMemo(() => {
    return TableColumnsLoader(addressTableColumns, {
      rows: 4,
      isValue: false,
    });
  }, [addressTableColumns]);

  return (
    <>
      <div ref={addressSection}>
        <div className="flex flex-row justify-content-between align-items-center py-3">
          <h4>Address Info</h4>
          <Button
            label="Add Address"
            size="small"
            severity="primary"
            onClick={handleAddNewRow}
            disabled={isDisableAddButton}
          />
        </div>
        <DataTable
          value={isLoading ? userAddressTableLoader : addressData}
          {...(!isLoading
            ? {
                editMode: 'row',
                onRowEditComplete: onRowEditComplete,
                onRowEditCancel: handleOnRowEditCancel,
                rowEditValidator: handleRowValidation,
                onRowEditInit: prop => {
                  disableEditButton(true);
                },
              }
            : {})}
        >
          {addressTableColumns?.map(col => (
            <Column key={col.field} {...col} />
          ))}
        </DataTable>
      </div>

      <Toast ref={toast} />
    </>
  );
};

export default UserFormAddress;
