import React from 'react';
import { Skeleton } from 'primereact/skeleton';

const DocPhotosLoader = ({
  numberOfSkeletons,
  width = '100%',
  height = '225px',
}) => {
  return (
    <div className="flex">
      {Array.from({ length: numberOfSkeletons }).map((_, index) => (
        <div className="grid px-2 w-14rem grid-nogutter" key={index}>
          <Skeleton width={width} height={height} />
        </div>
      ))}
    </div>
  );
};

export default DocPhotosLoader;
