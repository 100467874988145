import React from 'react';
import { TabPanel, TabView } from 'primereact/tabview';

const PFTabView = ({ model, activeIndex }) => {
  const { tabs, components } = model;

  return (
    <TabView
      panelContainerClassName="mb-0"
      style={{ boxShadow: 'none' }}
      {...(activeIndex ? { activeIndex } : {})}
    >
      {tabs &&
        tabs?.map(
          (tab, i) =>
            !tab?.delete && (
              <TabPanel
                key={i}
                header={tab?.header || ''}
                disabled={tab?.disabled || false}
              >
                {components && components[tab?.key || tab?.header]}
              </TabPanel>
            )
        )}
    </TabView>
  );
};

export default PFTabView;
