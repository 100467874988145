import { URL_CONSTANTS } from '../../../../constants/urlConstants';
import ApiService from '../../../../services/api.service';

export const getWorkHours = async clientId => {
  return await ApiService.getWithDiffBaseUrl(
    ApiService.formatUrl(
      URL_CONSTANTS.BUSINESS_HOURS.endPoints.workHours,
      clientId
    ),
    null,
    URL_CONSTANTS.BUSINESS_HOURS.baseUrl
  );
};
export const addWorkHours = async (clientId, payload) => {
  return await ApiService.postWithDiffBaseUrl(
    ApiService.formatUrl(
      URL_CONSTANTS.BUSINESS_HOURS.endPoints.addWorkHours,
      clientId
    ),
    payload,
    URL_CONSTANTS.BUSINESS_HOURS.baseUrl
  );
};

export const getHolidays = async clientId => {
  return await ApiService.getWithDiffBaseUrl(
    ApiService.formatUrl(
      URL_CONSTANTS.BUSINESS_HOURS.endPoints.holidays,
      clientId
    ),
    null,
    URL_CONSTANTS.BUSINESS_HOURS.baseUrl
  );
};
export const addHoliday = async (clientId, payload) => {
  return await ApiService.postWithDiffBaseUrl(
    ApiService.formatUrl(
      URL_CONSTANTS.BUSINESS_HOURS.endPoints.addHoliday,
      clientId
    ),
    payload,
    URL_CONSTANTS.BUSINESS_HOURS.baseUrl
  );
};
export const updateHolidayByHolidayId = async (holidayId, payload) => {
  return await ApiService.putWithDiffBaseUrl(
    ApiService.formatUrl(
      URL_CONSTANTS.BUSINESS_HOURS.endPoints.updateHolidayByHolidayId,
      holidayId
    ),
    payload,
    URL_CONSTANTS.BUSINESS_HOURS.baseUrl
  );
};
export const deleteHolidayByHolidayId = async holidayId => {
  return await ApiService.deleteWithDiffBaseUrl(
    ApiService.formatUrl(
      URL_CONSTANTS.BUSINESS_HOURS.endPoints.deleteHolidayByHolidayId,
      holidayId
    ),
    null,
    URL_CONSTANTS.BUSINESS_HOURS.baseUrl
  );
};

export const getSlotsByDay = async (clientId, day) => {
  return await ApiService.getWithDiffBaseUrl(
    ApiService.formatUrl(
      URL_CONSTANTS.BUSINESS_HOURS.endPoints.slotsByDay,
      clientId,
      day
    ),
    null,
    URL_CONSTANTS.BUSINESS_HOURS.baseUrl
  );
};
export const addSlotInDay = async (clientId, day, payload) => {
  return await ApiService.postWithDiffBaseUrl(
    ApiService.formatUrl(
      URL_CONSTANTS.BUSINESS_HOURS.endPoints.addSlotInDay,
      clientId,
      day
    ),
    payload,
    URL_CONSTANTS.BUSINESS_HOURS.baseUrl
  );
};
export const updateSlotBySlotId = async (slotId, payload) => {
  return await ApiService.putWithDiffBaseUrl(
    ApiService.formatUrl(
      URL_CONSTANTS.BUSINESS_HOURS.endPoints.updateSlotBySlotId,
      slotId
    ),
    payload,
    URL_CONSTANTS.BUSINESS_HOURS.baseUrl
  );
};
export const deleteSlotBySlotId = async slotId => {
  return await ApiService.deleteWithDiffBaseUrl(
    ApiService.formatUrl(
      URL_CONSTANTS.BUSINESS_HOURS.endPoints.deleteSlotBySlotId,
      slotId
    ),
    null,
    URL_CONSTANTS.BUSINESS_HOURS.baseUrl
  );
};