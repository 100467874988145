import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Grid,
  TextField,
  Box,
  Select,
  MenuItem,
  InputLabel,
  CircularProgress,
  InputAdornment,
  Tooltip,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker as MuiKeyboardDatePicker,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import moment from 'moment';
import { parseISO, format } from 'date-fns';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { debounce } from 'lodash';
import NumberFormat from 'react-number-format';
import { useDispatch } from 'react-redux';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { Button as ContactLink } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Skeleton } from 'primereact/skeleton';

import { isValidHex } from '../../../utils/string.helper';
import {
  SOURCE_SYSTEMS,
  YesNoDropdown,
  LL_INVOICE_STATUS_TYPE,
  PROJECT_TYPE,
  ESTIMATED_MAX_LIMIT,
  ProjectReviewStatus,
  AddNewColor,
  projectInfoCategory,
  CLIENT_DETAILS,
  PropertyOccupationDropdown,
} from '../../../constants';
import { checkPermission, formatPhoneNumber } from '../../../utils/Helpers.js';
import PFColorPicker from '../../shared/PFPrime/PFColorPicker';
import permissions from '../../../config/permissions';
import { CLIENT_ID } from '../../../constants/client.constant';
import {
  clearCategories,
  fetchCategories,
} from '../../../redux/slices/master/categories.slice';

import { useStyles } from './ProjectInfo.styles.js';
import {
  getDropdownValues,
  getStatusOptions,
  getCustomerDetails,
  getStoreDetailsByStoreId,
  addNewCategory,
  searchCustomers,
  searchUserList,
  getColorConfigList,
  getWorkRoom,
  getGroupName,
  getPersonalizedProjectNumber,
} from './ProjectDetails.service';
import { callPersonalizedProjectNumber } from './PersonalizedProjectNumber.js';
import { REACT_APP_SYSTEM_USER_ID } from '../../../constants/envConstants';

const ProjectInfo = ({
  formik,
  action,
  loading,
  setLoading,
  setCustomerDetails,
  customerDetails,
  storeDetails,
  setStoreDetails,
  getConfigurationEntities,
  allUserList,
  setAllUserList,
  opportunityInfo,
  setOpportunityInfo,
  projectDetailsData,
  setOpenPopup,
  setEPALeadReasontoUpdate,
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(location.search);
  const clientId = queryParams.get('clientId');

  const isPrivateJob = formik?.values?.source_system?.source_name
    ? formik?.values?.source_system?.source_name === 'Private'
    : false;

  const classes = useStyles({ status: formik?.values?.status?.status });
  const [sourceOptions, setSourceOptions] = React.useState([]);
  const [projectTypeOptions, setProjectTypeOptions] = React.useState([]);
  const [initialCustomerOptions, setInitialCustomerOptions] = React.useState(
    []
  );
  const [customerOptions, setCustomerOptions] = React.useState([]);
  const [categoryOptions, setCategoryOptions] = React.useState([]);
  const [statusOptions, setStatusOptions] = React.useState([]);
  const [storeOptions, setStoreOptions] = React.useState([]);
  const [newCategory, setNewCategory] = React.useState(null);
  const [newCategoryError, setNewCategoryError] = React.useState(null);
  const [newCategoryLoading, setNewCategoryLoading] = React.useState(null);
  const [categoryPopupOpen, setCategoryPopupOpen] = React.useState(false);
  const [storeFilteredOptions, setStoreFilteredOptions] = React.useState([]);
  const [referralSourceOptions, setReferralSourceOptions] = React.useState([]);
  const [colorConfigOptions, setColorConfigOptions] = React.useState([]);
  const [workRoomOptions, setWorkRoomOptions] = React.useState([]);
  const [groupNameOptions, setGroupNameOptions] = React.useState([]);
  const [sourceCategoryOptions, setSourceCategoryOptions] = React.useState([]);
  const [isClicked, setIsClicked] = useState(false);
  const [isClickedAlternateNO, setIsClickedAlternateNO] = useState(false);
  const [isClickedClientName, setIsClickedClientName] = useState(false);
  const [isClickedClientEmail, setIsClickedClientEmail] = useState(false);
  const [projectTypeEnabled, setProjectTypeEnabled] = useState(false);

  useEffect(() => {
    setProjectTypeEnabled(false);
    if (projectDetailsData?.created_by === REACT_APP_SYSTEM_USER_ID) {
      setProjectTypeEnabled(true);
    }
    if (action === 'copy') {
      setProjectTypeEnabled(false);
    }
  }, [projectDetailsData?.created_by, action]);

  const copyToClipboard = textToCopy => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setIsClicked(true);
        setTimeout(() => {
          setIsClicked(false);
        }, 2000);
      })
      .catch(err => {
        console.error('Failed to copy text: ', err);
      });
  };

  const copyToClipboardMulti = (textToCopy, type) => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        if (type == 'alternate_phone') {
          setIsClickedAlternateNO(true);
          setTimeout(() => {
            setIsClickedAlternateNO(false);
          }, 2000);
        } else if (type == 'customer_name') {
          setIsClickedClientName(true);
          setTimeout(() => {
            setIsClickedClientName(false);
          }, 2000);
        } else if (type == 'customer_email') {
          setIsClickedClientEmail(true);
          setTimeout(() => {
            setIsClickedClientEmail(false);
          }, 2000);
        }
      })
      .catch(err => {
        console.error('Failed to copy text: ', err);
      });
  };

  const clientDetails = JSON.parse(
    localStorage.getItem('client_details') || '{}'
  );
  const { group_source_category_opted } = clientDetails;
  const toast = useRef(null);
  const primaryNumberRef = useRef(null);
  const alternateNumberRef = useRef(null);

  const handleClose = () => {
    setCategoryPopupOpen(false);
  };
  const clearMasterData = () => {
    setTimeout(() => {
      dispatch(clearCategories());
    }, 2000);
  };
  const fetchMasterData = () => {
    setTimeout(() => {
      dispatch(fetchCategories());
    }, 2000);
  };
  useEffect(() => {
    if (sourceOptions?.length > 0 && formik?.values?.quoteId) {
      const priveSource = sourceOptions.find(
        item => item.source_name === 'Private'
      );
      if (priveSource) {
        setOpportunityInfo(preValue => ({
          ...preValue,
          source_system: priveSource,
        }));
      }
      if (storeOptions?.length) {
        const stores = storeOptions.filter(
          store => store?.source_system_id === priveSource?.source_system_id
        );
        setStoreFilteredOptions(stores);
      } else {
        setStoreFilteredOptions([]); // Clear the filtered options if storeOptions is null/undefined/empty
      }
    }
  }, [sourceOptions, storeOptions]);
  const getColorConfig = async value => {
    try {
      const response = await getColorConfigList();
      if (response && response?.data) {
        if (
          checkPermission(
            permissions?.viewEditProject?.actionProjectColorCodeAdd
          )
        ) {
          let data = [
            {
              color_config_id: 0,
              color_name: 'Add New Color',
            },
            ...response?.data,
          ];
          setColorConfigOptions(data);
        } else {
          setColorConfigOptions(response?.data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getGroupNameList = async () => {
    try {
      const response = await getGroupName();
      if (response && response?.data) {
        setGroupNameOptions(response?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getWorkRoomList = async value => {
    try {
      const response = await getWorkRoom(value);
      if (response) {
        const allWorkRoom = [...workRoomOptions, ...response];

        const uniqueIds = new Set();
        const filteredList = allWorkRoom?.filter(options => {
          const typeId = options?.type_id;
          if (uniqueIds.has(typeId)) {
            return false;
          }
          uniqueIds.add(typeId);
          return true;
        });
        setWorkRoomOptions(filteredList);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (
      projectDetailsData?.project_category_id &&
      groupNameOptions?.length > 0
    ) {
      const groupId = groupNameOptions?.find(category => {
        return (
          category?.project_category_id ===
          projectDetailsData?.project_category_id
        );
      });

      if (groupId?.parentCategory && groupId?.parentCategory?.length > 0) {
        setSourceCategoryOptions(groupId?.parentCategory);
      } else {
        setSourceCategoryOptions([groupId]);
      }
    }
  }, [projectDetailsData?.project_category_id, groupNameOptions]);
  useEffect(() => {
    if (
      opportunityInfo &&
      opportunityInfo?.source_category_id &&
      groupNameOptions.length > 0
    ) {
      const groupId = groupNameOptions?.find(category => {
        return (
          category?.project_category_id === opportunityInfo?.parent_category_id
        );
      });

      if (groupId?.parentCategory && groupId?.parentCategory?.length > 0) {
        setSourceCategoryOptions(groupId?.parentCategory);
      } else {
        setSourceCategoryOptions([groupId]);
      }
    }
  }, [opportunityInfo, groupNameOptions]);

  // To load independent dropdowns values like Type, Source etc.
  React.useEffect(() => {
    getDropdownValues(
      setLoading,
      setProjectTypeOptions,
      setSourceOptions,
      setCustomerOptions,
      setCategoryOptions,
      setStoreOptions,
      setInitialCustomerOptions,
      setReferralSourceOptions
    );
    getStatusOptions('project', setLoading, setStatusOptions);
    getColorConfig();
    getWorkRoomList('');
    getGroupNameList();
    groupNameOptions;
  }, []);

  React.useEffect(async () => {
    if (action === 'add') {
      const response = await searchUserList();
      setAllUserList(response);
    }
  }, []);

  const handlePersonalizedProjectNumberAPI = async () => {
    const response = await callPersonalizedProjectNumber();
    if (response) {
      formik?.setFieldValue('project_number', `${response}`);
    }
  };
  useEffect(() => {
    if (
      formik?.values?.source_system?.source_name === 'Private' &&
      clientDetails?.is_personalized_private_project &&
      (action === 'add' || action === 'copy')
    ) {
      handlePersonalizedProjectNumberAPI();
    }
  }, [
    clientDetails?.is_personalized_private_project,
    formik?.values?.source_system?.source_name,
    formik?.values?.installation_address?.city,
  ]);

  if (statusOptions.length && !formik?.values?.status && action === 'add') {
    formik?.setFieldValue(
      'status',
      statusOptions.find(status => status.status === 'New')
    );
  }

  const saveNewCategory = () => {
    let categoryName = newCategory.trim();
    if (categoryName) {
      addNewCategory(
        categoryName,
        setNewCategoryLoading,
        setNewCategoryError,
        setCategoryPopupOpen,
        setCategoryOptions,
        clearMasterData,
        fetchMasterData
      );
      getGroupNameList();
    } else {
      setNewCategoryError('Please enter category.');
    }
  };

  let newCategoryOptions = [
    ...[
      {
        category: 'Add New Category',
      },
    ],
    ...groupNameOptions,
  ];

  const getWorkroomLabel = () => {
    if (action === 'add') {
      return storeDetails[0]?.label || storeDetails?.label || '';
    } else {
      return (
        storeDetails[0]?.workroom_label || storeDetails?.workroom_label || ''
      );
    }
  };

  const fetchCustomersDropdown = async event => {
    const searchString = event?.target?.value;
    if (searchString?.length > 3) {
      const customers = await searchCustomers(searchString);
      customers && customers.length > 0 && setCustomerOptions(customers);
    } else {
      if (searchString === '') {
        setCustomerOptions(initialCustomerOptions);
      }
    }
  };
  const fetchUserList = async value => {
    const response = await searchUserList(value);
    const allUsers = [...allUserList, ...response];
    const uniqueUserIds = new Set();
    const filteredUserList = allUsers?.filter(options => {
      const userId = options?.user_id;
      if (uniqueUserIds.has(userId)) {
        return false;
      }
      uniqueUserIds.add(userId);
      return true;
    });
    setAllUserList(filteredUserList);
  };
  const handleRelatedMeasurerChanges = event => {
    if (event?.target?.value) {
      fetchUserList(event?.target?.value);
    }
  };

  if (opportunityInfo?.opportunityCategory) {
    const opportunityCategory = newCategoryOptions?.find(
      item => item?.project_category_id === opportunityInfo?.opportunityCategory
    );
    opportunityCategory &&
      setOpportunityInfo(preValue => ({
        ...preValue,
        opportunityCategory: opportunityCategory,
      }));
  }

  if (opportunityInfo?.opportunityType) {
    const opportunityType = projectTypeOptions?.find(
      item => item?.project_type_id === opportunityInfo?.opportunityType
    );
    opportunityType &&
      setOpportunityInfo(preValue => ({
        ...preValue,
        opportunityType: opportunityType,
      }));
  }

  const handleWorkRoomChanges = event => {
    if (event?.target?.value) {
      getWorkRoomList(event?.target?.value);
    }
  };
  const customerPrimaryEmailAddress =
    customerDetails[0]?.customer_emails?.filter(email => email.is_primary)[0]
      ?.email || '';

  const showContactNumberTemplate = phoneNumber => {
    confirmPopup({
      target: primaryNumberRef.current,
      group: 'ContactNumbertemplating',
      header: 'Confirmation',
      message: (
        <div className="flex flex-column align-items-start w-full gap-3">
          <span className="font-bold text-base">Call Confirmation</span>
          <div className="flex align-items-center w-full gap-2">
            <i className="pi pi-exclamation-triangle text-xl text-primary-500"></i>
            <span>Would you like to place a call to this number?</span>
          </div>
        </div>
      ),
      accept: () => {
        if (phoneNumber) {
          window.location.href = `tel:${phoneNumber}`;
        }
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail:
            'Thanks for confirming, your call would be initiated shortly.',
          life: 3000,
        });
      },
      reject: () => {},
    });
  };

  const showAlternateNumberTemplate = phoneNumber => {
    confirmPopup({
      target: alternateNumberRef.current,
      group: 'templating',
      header: 'Confirmation',
      message: (
        <div className="flex flex-column align-items-start w-full gap-3">
          <span className="font-bold text-base">Call Confirmation</span>
          <div className="flex align-items-center w-full gap-2">
            <i className="pi pi-exclamation-triangle text-xl text-primary-500"></i>
            <span>Would you like to place a call to this number?</span>
          </div>
        </div>
      ),
      accept: () => {
        if (phoneNumber) {
          window.location.href = `tel:${phoneNumber}`;
        }
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail:
            'Thanks for confirming, your call would be initiated shortly.',
          life: 3000,
        });
      },
      reject: () => {},
    });
  };
  const showIsLeadRequiredAlertLowes = () => {
    if (
      (projectDetailsData?.epa_lead_assessment_required === 1 ||
        projectDetailsData?.epa_lead_remediation === 1) &&
      projectDetailsData?.project_lead_response_reason === null
    ) {
      return true;
    } else {
      return false;
    }
  };
  const showIsLeadRequiredAlertHD = () => {
    if (
      (projectDetailsData?.epa_lead_assessment_required === 1 ||
        projectDetailsData?.epa_lead_remediation === 1) &&
      projectDetailsData?.epa_override_reason_code_id === null
    ) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <>
      <Toast ref={toast} />
      <Grid container spacing={2} direction="column">
        <Box display="flex" flexDirection="row" flexWrap="wrap">
          <Box m={1} className={classes.boxColumn}>
            {loading ? (
              <Skeleton className="w-full" height="3rem" />
            ) : (
              <>
                <InputLabel required={action !== 'view'} shrink>
                  Source
                </InputLabel>
                <Select
                  labelId="area-clean-label"
                  name="source_system"
                  value={formik?.values?.source_system || ''}
                  onChange={(event, value) => {
                    if (event.target.value?.source_name != 'Private') {
                      formik?.setFieldValue('project_number', '');
                    }
                    formik?.setFieldValue('source_system', event.target.value);
                    if (event.target.value?.source_system_id === 2) {
                      formik?.setFieldValue('project_category', undefined);
                      formik?.setFieldValue('project_category_id', undefined);
                    }
                    setStoreFilteredOptions(
                      storeOptions?.length
                        ? storeOptions?.filter(
                            store =>
                              store?.source_system_id ===
                              event?.target?.value?.source_system_id
                          )
                        : []
                    );
                  }}
                  onBlur={formik.handleBlur}
                  displayEmpty={true}
                  variant="standard"
                  classes={{ select: classes.sourceSelect }}
                  inputProps={{
                    readOnly: action === 'view' || action === 'edit',
                    tabIndex: 1,
                  }}
                  disabled={action === 'view'}
                  renderValue={value =>
                    value.logo_url ? (
                      <img
                        width="50"
                        height="20"
                        alt={value?.source_name}
                        src={value?.logo_url}
                      />
                    ) : (
                      value?.source_name
                    )
                  }
                >
                  <MenuItem disabled value="">
                    Select
                  </MenuItem>
                  {sourceOptions.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item?.source_name}
                    </MenuItem>
                  ))}
                </Select>
              </>
            )}
          </Box>
          <Box className={classes.boxColumn} m={1}>
            {loading ? (
              <Skeleton className="w-full" height="3rem" />
            ) : (
              <TextField
                id="project_number"
                label="Project#"
                onChange={formik.handleChange}
                InputProps={{
                  readOnly: action === 'view' || action === 'edit',
                }}
                disabled={
                  action === 'view' ||
                  action === 'edit' ||
                  (formik?.values?.source_system?.source_name === 'Private' &&
                    clientDetails?.is_personalized_private_project === 1)
                }
                value={formik?.values?.project_number || ''}
                InputLabelProps={{ shrink: true }}
              />
            )}
          </Box>
          <Box className={classes.boxColumn} m={1}>
            {loading ? (
              <Skeleton className="w-full" height="3rem" />
            ) : (
              <TextField
                id="po_number"
                label="PO#"
                onChange={formik.handleChange}
                InputProps={{
                  readOnly: action === 'view',
                }}
                disabled={action === 'view'}
                value={formik?.values?.po_number || ''}
                InputLabelProps={{ shrink: true }}
              />
            )}
          </Box>
          <Box className={classes.boxColumn} m={1}>
            {loading ? (
              <Skeleton className="w-full" height="3rem" />
            ) : (
              <Autocomplete
                name="project_type"
                options={projectTypeOptions || []}
                onBlur={formik?.handleBlur}
                onChange={(event, value) => {
                  formik?.setFieldValue('project_type', value);
                }}
                getOptionLabel={option => option && option.project_type}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Type"
                    required={action === 'edit' || action === 'add'}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                value={formik?.values?.project_type || ''}
                disabled={action === 'view' || projectTypeEnabled}
              />
            )}
          </Box>

          <Box className={classes.boxColumn} m={1}>
            {loading ? (
              <Skeleton className="w-full" height="3rem" />
            ) : (
              <Autocomplete
                name="project_category"
                onChange={(event, value) => {
                  if (value) {
                    formik?.setFieldValue('project_category', value);
                    formik?.setFieldValue(
                      'project_category_id',
                      value.project_category_id
                    );
                    formik.setFieldValue(
                      'source_category_id',
                      value.project_category_id
                    );
                    if (
                      value?.parentCategory &&
                      value?.parentCategory?.length > 0
                    ) {
                      setSourceCategoryOptions(value?.parentCategory);
                    } else if (
                      value?.category === projectInfoCategory.ADD_CATEGORY
                    ) {
                      setNewCategory(null);
                      setNewCategoryError(null);
                      setCategoryPopupOpen(true);
                      formik.setFieldValue('project_category', '');
                    } else {
                      setSourceCategoryOptions([value]);
                    }
                  } else {
                    formik?.setFieldValue('project_category', value);
                    formik?.setFieldValue('project_category_id', value);
                    setSourceCategoryOptions([]);
                  }
                }}
                options={
                  newCategoryOptions?.filter(
                    data => data?.category?.trim() !== ''
                  ) || []
                }
                getOptionLabel={option => option && option?.category}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Category"
                    required={action === 'edit' || action === 'add'}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                disabled={action === 'view'}
                value={
                  newCategoryOptions?.find(category => {
                    return (
                      category?.project_category_id ===
                      formik?.values?.project_category
                    );
                  }) || formik?.values?.project_category
                }
              />
            )}
          </Box>

          <Box className={classes.boxColumn} m={1}>
            {loading ? (
              <Skeleton className="w-full" height="3rem" />
            ) : (
              <Autocomplete
                disableClearable
                id="customer"
                onInputChange={debounce(fetchCustomersDropdown, 300)}
                onChange={(event, value) => {
                  if (value) {
                    formik.setFieldValue('customer', value);
                    getCustomerDetails(
                      value?.customer_id,
                      setLoading,
                      setCustomerDetails,
                      formik.setFieldValue
                    );
                  } else {
                    setCustomerDetails({});
                  }
                }}
                onBlur={formik?.handleBlur}
                options={customerOptions || []}
                getOptionLabel={option =>
                  option && option.first_name + ' ' + option.last_name
                }
                renderInput={params => (
                  <TextField
                    {...(action !== 'view' && { ...params })}
                    label="Client Name"
                    required={action === 'edit' || action === 'add'}
                    InputLabelProps={{ shrink: true }}
                    InputProps={
                      action === 'view'
                        ? {
                            readOnly: true,
                            startAdornment: formik.values?.customer
                              ?.customer_id && (
                              <a
                                href={`/crm/view/customer/${formik.values?.customer?.customer_id}`}
                                target="_blank"
                                rel="noreferrer"
                                className="text-primary min-w-full no-underline"
                              >
                                {params?.inputProps?.value}
                              </a>
                            ),
                            endAdornment: (
                              <>
                                <span
                                  className={`pi pi-copy copy-icon ${isClickedClientName ? 'clicked' : ''} cursor-pointer copy-icon-customer-name`}
                                  onClick={e => {
                                    e.stopPropagation();
                                    copyToClipboardMulti(
                                      formik.values?.customer.first_name +
                                        ' ' +
                                        formik.values?.customer.last_name,
                                      'customer_name'
                                    );
                                  }}
                                />
                                {isClickedClientName && (
                                  <div className="customer-name-tooltip">
                                    Copied
                                  </div>
                                )}
                              </>
                            ),
                          }
                        : params?.InputProps
                    }
                  />
                )}
                disabled={action === 'edit' && !isPrivateJob}
                value={formik?.values?.customer || ''}
                disabled={clientId ? true : false}
              />
            )}
          </Box>
          <Box className={classes.boxColumn} m={1}>
            {loading ? (
              <Skeleton className="w-full" height="3rem" />
            ) : (
              <TextField
                label="Primary Mode of Contact"
                onChange={formik.handleChange}
                InputProps={{
                  readOnly: true,
                }}
                disabled={true}
                value={customerDetails[0]?.primary_mode_of_contact || ''}
                InputLabelProps={{ shrink: true }}
              />
            )}
          </Box>
          <Box className={classes.boxColumn} m={1}>
            {loading ? (
              <Skeleton className="w-full" height="3rem" />
            ) : (
              <TextField
                label="Contact Number"
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  startAdornment: customerDetails[0]?.primary_phone && (
                    <ContactLink
                      label={customerDetails[0]?.primary_phone || ''}
                      link
                      className="w-full text-left"
                      size="small"
                      ref={primaryNumberRef}
                      onClick={e => {
                        e.preventDefault();
                        showContactNumberTemplate(
                          formatPhoneNumber(customerDetails[0]?.primary_phone)
                        );
                      }}
                      pt={{
                        root: {
                          className:
                            'p-0 outline-none shadow-none cursor-pointer contact-button-w',
                        },
                        label: {
                          className: 'no-underline',
                        },
                      }}
                    />
                  ),
                  endAdornment: (
                    <>
                      <span
                        className={`pi pi-copy copy-icon ${isClicked ? 'clicked' : ''} cursor-pointer`}
                        onClick={() =>
                          copyToClipboard(customerDetails[0]?.primary_phone)
                        }
                      />
                      {isClicked && (
                        <div className="contacttooltip">Copied</div>
                      )}
                    </>
                  ),
                }}
                disabled={true}
                InputLabelProps={{ shrink: true }}
              />
            )}
          </Box>

          <ConfirmPopup
            group="ContactNumbertemplating"
            pt={{
              root: {
                className: ' p-confirm-popup-center ',
              },
              content: {
                className: 'px-2 pt-3 pb-0',
              },
              footer: {
                className: 'px-2 pb-2 pt-0',
              },
              icon: {
                className: '',
              },
              message: {
                className: 'ml-2',
              },
            }}
          />
          <Box className={classes.boxColumn} m={1}>
            {loading ? (
              <Skeleton className="w-full" height="3rem" />
            ) : (
              <TextField
                label="Alternate Number"
                onChange={formik.handleChange}
                InputProps={{
                  readOnly: true,
                  startAdornment: formik?.values?.customer?.alternate_phone && (
                    <ContactLink
                      label={formik?.values?.customer?.alternate_phone || ''}
                      link
                      size="small"
                      ref={alternateNumberRef}
                      onClick={e => {
                        e.preventDefault();
                        showAlternateNumberTemplate(
                          formatPhoneNumber(
                            formik?.values?.customer?.alternate_phone
                          )
                        );
                      }}
                      className="w-full text-left "
                      pt={{
                        root: {
                          className:
                            'p-0  outline-none shadow-none cursor-pointer contact-button-w',
                        },
                        label: {
                          className: 'no-underline',
                        },
                      }}
                    />
                  ),
                  endAdornment: (
                    <>
                      <span
                        className={`pi pi-copy copy-icon ${isClickedAlternateNO ? 'clicked' : ''} cursor-pointer`}
                        onClick={() =>
                          copyToClipboardMulti(
                            formik?.values?.customer?.alternate_phone,
                            'alternate_phone'
                          )
                        }
                      />
                      {isClickedAlternateNO && (
                        <div className="contacttooltip">Copied</div>
                      )}
                    </>
                  ),
                }}
                disabled={true}
                InputLabelProps={{ shrink: true }}
              />
            )}
          </Box>
          <ConfirmPopup
            group="templating"
            pt={{
              root: {
                className: ' p-confirm-popup-center ',
              },
              content: {
                className: 'px-2 pt-3 pb-0',
              },
              footer: {
                className: 'px-2 pb-2 pt-0',
              },
              icon: {
                className: '',
              },
              message: {
                className: 'ml-2',
              },
            }}
          />
          {group_source_category_opted ? (
            <Box className={classes.boxColumn} m={1}>
              {loading ? (
                <Skeleton className="w-full" height="3rem" />
              ) : (
                <Autocomplete
                  name="source_category_id"
                  value={
                    sourceCategoryOptions?.find(item => {
                      const sourceCategoryId =
                        formik?.values?.source_category_id;
                      const projectCategoryId =
                        formik?.values?.project_category?.project_category_id;

                      if (sourceCategoryId) {
                        return item?.project_category_id === sourceCategoryId;
                      }
                      return item?.project_category_id === projectCategoryId;
                    }) || ''
                  }
                  onChange={(event, value) => {
                    formik.setFieldValue(
                      'source_category_id',
                      value?.project_category_id || ''
                    );
                  }}
                  key={
                    sourceCategoryOptions?.find(item => {
                      const sourceCategoryId =
                        formik?.values?.source_category_id;
                      const projectCategoryId =
                        formik?.values?.project_category?.project_category_id;

                      if (sourceCategoryId) {
                        return item?.project_category_id === sourceCategoryId;
                      }
                      return item?.project_category_id === projectCategoryId;
                    })?.project_category_id || ''
                  }
                  options={sourceCategoryOptions || []}
                  getOptionLabel={option => option?.category || ''}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Source Category"
                      required={['edit', 'add'].includes(action)}
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                  disabled={action === 'view'}
                />
              )}
            </Box>
          ) : null}

          <Box className={classes.boxColumn} m={1}>
            {loading ? (
              <Skeleton className="w-full" height="3rem" />
            ) : (
              <Tooltip
                title={customerPrimaryEmailAddress}
                placement="bottom"
                arrow
              >
                <TextField
                  label="Email"
                  InputProps={{
                    readOnly: true,
                    startAdornment: customerPrimaryEmailAddress && (
                      <a
                        href={`mailto:${customerPrimaryEmailAddress}`}
                        className="w-full no-underline"
                      >
                        {customerPrimaryEmailAddress}
                      </a>
                    ),
                    endAdornment: (
                      <>
                        <span
                          className={`pi pi-copy copy-icon ${isClickedClientEmail ? 'clicked' : ''} cursor-pointer`}
                          onClick={() =>
                            copyToClipboardMulti(
                              customerPrimaryEmailAddress,
                              'customer_email'
                            )
                          }
                        />
                        {isClickedClientEmail && (
                          <div className="customer-email-tooltip">Copied</div>
                        )}
                      </>
                    ),
                  }}
                  disabled={true}
                  InputLabelProps={{ shrink: true }}
                  className="overflow-hidden"
                />
              </Tooltip>
            )}
          </Box>
          {formik?.values?.source_system?.source_system_id ===
            SOURCE_SYSTEMS.LUMBER_LIQUIDATOR && (
            <Box className={classes.boxColumn} m={1}>
              {loading ? (
                <Skeleton className="w-full" height="3rem" />
              ) : (
                <TextField
                  label="Client Number"
                  InputProps={{
                    readOnly: true,
                  }}
                  disabled={true}
                  value={customerDetails[0]?.qb_cust_id || ''}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            </Box>
          )}
          <Box className={classes.boxColumn} m={1}>
            {loading ? (
              <Skeleton className="w-full" height="3rem" />
            ) : (
              <TextField
                label="Street"
                onChange={event => {}}
                InputProps={{
                  readOnly: true,
                }}
                disabled={true}
                value={
                  (formik?.values?.customer?.address?.address1 &&
                    `${formik?.values?.customer?.address?.address1?.trim()} ${
                      formik?.values?.customer?.address?.address2
                        ? formik?.values?.customer?.address?.address2?.trim()
                        : ''
                    }`) ||
                  ''
                }
                InputLabelProps={{ shrink: true }}
              />
            )}
          </Box>
          <Box className={classes.boxColumn} m={1}>
            {loading ? (
              <Skeleton className="w-full" height="3rem" />
            ) : (
              <TextField
                label="City"
                onChange={event => {}}
                InputProps={{
                  readOnly: true,
                }}
                disabled={true}
                value={formik?.values?.customer?.address?.city || ''}
                InputLabelProps={{ shrink: true }}
              />
            )}
          </Box>
          <Box className={classes.boxColumn} m={1}>
            {loading ? (
              <Skeleton className="w-full" height="3rem" />
            ) : (
              <TextField
                label="State"
                InputProps={{
                  readOnly: true,
                }}
                disabled={true}
                value={formik?.values?.customer?.address?.state || ''}
                InputLabelProps={{ shrink: true }}
              />
            )}
          </Box>
          <Box className={classes.boxColumn} m={1}>
            {loading ? (
              <Skeleton className="w-full" height="3rem" />
            ) : (
              <TextField
                label="Zip"
                onChange={event => {}}
                InputProps={{
                  readOnly: true,
                }}
                disabled={true}
                value={formik?.values?.customer?.address?.zipcode || ''}
                InputLabelProps={{ shrink: true }}
              />
            )}
          </Box>
          <Box className={classes.boxColumn} m={1}>
            {loading ? (
              <Skeleton className="w-full" height="3rem" />
            ) : (
              <TextField
                id="year_built"
                label="Year Built"
                type="number"
                onChange={(event, value) => {
                  formik.setFieldValue('year_built', event.target?.value);
                }}
                value={formik?.values?.year_built || ''}
                disabled={action === 'view'}
              />
            )}
          </Box>
          <Box className={classes.boxColumn} m={1}>
            {loading ? (
              <Skeleton className="w-full" height="3rem" />
            ) : (
              <Autocomplete
                id="store_id"
                disableListWrap
                onChange={(event, value) => {
                  if (value) {
                    getStoreDetailsByStoreId(
                      value.store_id,
                      setLoading,
                      setStoreDetails
                    );
                    formik.setFieldValue('store_id', value.store_id);
                    formik.setFieldValue('workroom_id', value?.type_id);
                  } else {
                    setStoreDetails([]);
                  }
                }}
                onBlur={formik?.handleBlur}
                options={storeFilteredOptions || []}
                getOptionLabel={option =>
                  option && option?.store_number?.toString()
                }
                renderOption={(option, { inputValue }) => (
                  <AutoCompleteTitle option={option} inputValue={inputValue} />
                )}
                renderInput={params => {
                  return (
                    <TextField
                      {...(action !== 'view' && { ...params })}
                      label="Store#"
                      required={action === 'edit' || action === 'add'}
                      InputLabelProps={{ shrink: true }}
                      InputProps={
                        action === 'view'
                          ? {
                              readOnly: true,
                              startAdornment: formik?.values?.store_id && (
                                <a
                                  href={`/stores/view/${formik?.values?.store_id}`}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="text-primary min-w-full no-underline"
                                >
                                  {params?.inputProps?.value}
                                </a>
                              ),
                            }
                          : params?.InputProps
                      }
                    />
                  );
                }}
                disabled={action === 'edit' && !isPrivateJob}
                value={
                  storeOptions.find(
                    store => store.store_id === formik?.values?.store_id
                  ) || ''
                }
              />
            )}
          </Box>
          <Box className={classes.boxColumn} m={1}>
            {loading ? (
              <Skeleton className="w-full" height="3rem" />
            ) : (
              <TextField
                label="Store Name"
                onChange={event => {}}
                InputProps={{
                  readOnly: true,
                }}
                disabled={true}
                value={
                  storeDetails[0]?.store_name || storeDetails?.store_name || ''
                }
                InputLabelProps={{ shrink: true }}
              />
            )}
          </Box>
          <Box className={classes.boxColumn} m={1}>
            {loading ? (
              <Skeleton className="w-full" height="3rem" />
            ) : (
              <TextField
                label="Region"
                onChange={event => {}}
                InputProps={{
                  readOnly: true,
                }}
                disabled={true}
                value={storeDetails[0]?.region || storeDetails?.region || ''}
                InputLabelProps={{ shrink: true }}
              />
            )}
          </Box>
          <Box className={classes.boxColumn} m={1}>
            {loading ? (
              <Skeleton className="w-full" height="3rem" />
            ) : (
              <TextField
                label="District"
                InputProps={{
                  readOnly: true,
                }}
                disabled={true}
                value={
                  storeDetails[0]?.district || storeDetails?.district || ''
                }
                InputLabelProps={{ shrink: true }}
              />
            )}
          </Box>
          <Box className={classes.boxColumn} m={1}>
            {loading ? (
              <Skeleton className="w-full" height="3rem" />
            ) : (
              <TextField
                label="Market"
                onChange={event => {}}
                InputProps={{
                  readOnly: true,
                }}
                disabled={true}
                value={storeDetails[0]?.market || storeDetails?.market || ''}
                InputLabelProps={{ shrink: true }}
              />
            )}
          </Box>

          <Box className={classes.boxColumn} m={1}>
            {loading ? (
              <Skeleton className="w-full" height="3rem" />
            ) : (
              <Autocomplete
                id="workroom_id"
                options={workRoomOptions || []}
                getOptionLabel={option => (option.label ? option.label : '')}
                renderInput={params => {
                  return (
                    <TextField
                      {...params}
                      label="Workroom"
                      InputLabelProps={{ shrink: true }}
                    />
                  );
                }}
                onChange={(event, value) => {
                  formik.setFieldValue('workroom_id', value?.type_id);
                }}
                value={
                  workRoomOptions?.find(
                    option => option?.type_id === formik.values?.workroom_id
                  ) || null
                }
                onInputChange={debounce(handleWorkRoomChanges, 300)}
                disabled={action === 'view'}
              />
            )}
          </Box>

          <Box className={classes.boxColumn} m={1}>
            {loading ? (
              <Skeleton className="w-full" height="3rem" />
            ) : (
              <Autocomplete
                id="status"
                disableListWrap
                options={statusOptions || []}
                getOptionLabel={option => option && option.status}
                onChange={(event, value) => {
                  if (
                    value?.status === 'Completed' &&
                    clientDetails?.epa_guardrails_workflow === 1
                  ) {
                    const isLeadRequiredShowAlert =
                      projectDetailsData?.source_system_id === 2
                        ? showIsLeadRequiredAlertHD()
                        : showIsLeadRequiredAlertLowes();
                    if (isLeadRequiredShowAlert) {
                      setOpenPopup(true);
                    }
                  } else {
                    setEPALeadReasontoUpdate(false);
                    formik.setFieldValue('status', value);
                    formik.setFieldValue(
                      'completion_date',
                      value.status === 'Completed'
                        ? moment().format('YYYY-MM-DD')
                        : projectDetailsData?.completion_date
                    );
                  }
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Status"
                    required={action === 'edit' || action === 'add'}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                classes={{ input: classes.statusInput }}
                disabled={action === 'view' ? true : false}
                value={
                  (action === 'add' &&
                    statusOptions.find(status => status.status === 'New')) ||
                  formik?.values?.status ||
                  ''
                }
              />
            )}
          </Box>
          {action !== 'add' && (
            <Box className={classes.boxColumn} m={1}>
              {loading ? (
                <Skeleton className="w-full" height="3rem" />
              ) : (
                <TextField
                  label="Source Status"
                  onChange={event => {}}
                  InputProps={{
                    readOnly: true,
                  }}
                  disabled={true}
                  value={formik?.values?.source_status?.status || ''}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            </Box>
          )}
          {action !== 'add' && (
            <Box className={classes.boxColumn} m={1}>
              {loading ? (
                <Skeleton className="w-full" height="3rem" />
              ) : (
                <TextField
                  label="Review Status"
                  InputProps={{
                    classes: { input: classes.reviewInput },
                    readOnly: true,
                  }}
                  disabled={true}
                  InputLabelProps={{ shrink: true }}
                  value={
                    formik?.values?.review_status &&
                    formik?.values?.review_status === 'Contract_Pending'
                      ? 'Contract Pending'
                      : formik?.values?.review_status &&
                          formik?.values?.review_status === 'Contract_Reviewed'
                        ? 'Contract Reviewed'
                        : formik?.values?.review_status &&
                            formik?.values?.review_status ===
                              ProjectReviewStatus.REFUNDREVIEWED
                          ? 'Refund Reviewed'
                          : 'No'
                  }
                />
              )}
            </Box>
          )}
          <Box className={classes.boxColumn} m={1}>
            {loading ? (
              <Skeleton className="w-full" height="3rem" />
            ) : (
              <TextField
                label="Open in Provider Portal"
                InputProps={{
                  readOnly: true,
                  startAdornment: formik?.values?.open_in_provider_portal && (
                    <a
                      href={formik?.values?.open_in_provider_portal}
                      target="_blank"
                      rel="noreferrer"
                      style={{
                        textDecoration: 'none',
                        width: '100%',
                      }}
                    >
                      Click here
                    </a>
                  ),
                }}
                disabled={true}
                InputLabelProps={{ shrink: true }}
              />
            )}
            {/* <TextField
              id="open_in_provider_portal"
              label="Open in Provider Portal"
              onChange={formik?.handleChange}
              InputProps={{
                readOnly: action === 'view' || (action === 'edit' && !isPrivateJob),
              }}
              disabled={true}
              value={formik?.values?.open_in_provider_portal || ''}
              InputLabelProps={{ shrink: true }}
            /> */}
          </Box>
          <Box className={classes.boxColumn} m={1}>
            {loading ? (
              <Skeleton className="w-full" height="3rem" />
            ) : (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <MuiKeyboardDatePicker
                  name="date_sold"
                  label="Date Sold"
                  format="MM-dd-yyyy"
                  value={
                    (formik?.values?.date_sold &&
                      formik?.values?.date_sold?.split('T')?.length &&
                      parseISO(formik?.values?.date_sold?.slice(0, 10))) ||
                    ((action === 'add' || action === 'copy') && new Date()) ||
                    null
                  }
                  onChange={(date, value) =>
                    formik?.setFieldValue(
                      'date_sold',
                      `${moment(date).format('YYYY-MM-DD')}T00:00:00.000Z`
                    )
                  }
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                    disabled: action === 'edit' || action === 'view',
                    style: { display: action === 'view' && 'none' },
                  }}
                  disabled={
                    (action === 'edit' && !isPrivateJob) || action === 'view'
                  }
                  required={action === 'edit' || action === 'add'}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </MuiPickersUtilsProvider>
            )}
          </Box>
          <Box className={classes.boxColumn} m={1}>
            {loading ? (
              <Skeleton className="w-full" height="3rem" />
            ) : (
              <TextField
                label="PSE Email"
                onChange={event => {}}
                InputProps={{
                  readOnly: true,
                }}
                disabled={true}
                value={storeDetails[0]?.pse_email || ''}
                InputLabelProps={{ shrink: true }}
              />
            )}
          </Box>
          {formik?.values?.source_system?.source_name === 'Lowes' &&
            action !== 'add' && (
              <Box className={classes.boxColumn} m={1}>
                {loading ? (
                  <Skeleton className="w-full" height="3rem" />
                ) : (
                  <TextField
                    label="Current Activity & Status"
                    onChange={event => {}}
                    InputProps={{
                      readOnly: true,
                    }}
                    disabled={true}
                    value={`${formik?.values?.current_activity || ''} ${
                      formik?.values?.current_activity_status || ''
                    }`}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              </Box>
            )}
          {formik?.values?.project_type?.project_type === 'Work Order' && (
            <Box className={classes.boxColumn} m={1}>
              {loading ? (
                <Skeleton className="w-full" height="3rem" />
              ) : (
                <TextField
                  id="work_order_number"
                  label="Work Order #"
                  onChange={formik?.handleChange}
                  InputProps={{
                    readOnly:
                      (action === 'edit' && !isPrivateJob) || action === 'view',
                  }}
                  value={formik?.values?.work_order_number || ''}
                  InputLabelProps={{ shrink: true }}
                  disabled={
                    (action === 'edit' && !isPrivateJob) || action === 'view'
                  }
                />
              )}
            </Box>
          )}
          {formik?.values?.project_type?.project_type === 'Quote' && (
            <Box className={classes.boxColumn} m={1}>
              {loading ? (
                <Skeleton className="w-full" height="3rem" />
              ) : (
                <TextField
                  id="quote_number"
                  label="Quote #"
                  onChange={formik?.handleChange}
                  InputProps={{
                    readOnly:
                      (action === 'edit' && !isPrivateJob) || action === 'view',
                  }}
                  value={formik?.values?.quote_number || ''}
                  InputLabelProps={{ shrink: true }}
                  disabled={
                    (action === 'edit' && !isPrivateJob) || action === 'view'
                  }
                />
              )}
            </Box>
          )}
          <Box width="38%" ml={1} mt={1} className="project-info-desc">
            {loading ? (
              <Skeleton className="w-full" height="3rem" />
            ) : (
              <TextField
                label="Description"
                onChange={(event, value) => {
                  formik.setFieldValue('project_desc', event.target.value);
                }}
                multiline
                maxRows={4}
                value={formik?.values?.project_desc || ''}
                InputProps={{
                  readOnly: (action === 'view' && true) || false,
                }}
                disabled={(action === 'view' && true) || false}
                InputLabelProps={{ shrink: true }}
              />
            )}
          </Box>
          {formik?.values?.source_system?.source_system_id ===
            SOURCE_SYSTEMS.LUMBER_LIQUIDATOR && (
            <Box className={classes.boxColumn} m={1}>
              {loading ? (
                <Skeleton className="w-full" height="3rem" />
              ) : (
                <Autocomplete
                  name="ll_invoice_status"
                  onChange={(event, value) => {
                    formik.setFieldValue('ll_invoice_status', value?.value);
                  }}
                  options={LL_INVOICE_STATUS_TYPE}
                  getOptionLabel={option => (option ? option?.value : '')}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="LL Invoice status"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                  disabled={action === 'view'}
                  value={
                    LL_INVOICE_STATUS_TYPE.find(type => {
                      return type?.value === formik?.values?.ll_invoice_status;
                    }) || ''
                  }
                />
              )}
            </Box>
          )}
          {formik?.values?.source_system?.source_system_id ===
            SOURCE_SYSTEMS.LUMBER_LIQUIDATOR && (
            <Box className={classes.boxColumn} m={1}>
              {loading ? (
                <Skeleton className="w-full" height="3rem" />
              ) : (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <MuiKeyboardDatePicker
                    name="ll_invoice_sent_date"
                    label="LL Date Invoice Sent"
                    format="MM-dd-yyyy"
                    value={
                      (formik?.values?.ll_invoice_sent_date &&
                        formik?.values?.ll_invoice_sent_date?.split('T')
                          ?.length &&
                        parseISO(
                          formik?.values?.ll_invoice_sent_date?.slice(0, 10)
                        )) ||
                      null
                    }
                    onChange={(date, value) =>
                      formik?.setFieldValue(
                        'll_invoice_sent_date',
                        `${moment(date).format('YYYY-MM-DD')}`
                      )
                    }
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                      disabled: action === 'view',
                      style: { display: action === 'view' && 'none' },
                    }}
                    disabled={action === 'view'}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                </MuiPickersUtilsProvider>
              )}
            </Box>
          )}
          <Box className={classes.boxColumn} m={1}>
            {loading ? (
              <Skeleton className="w-full" height="3rem" />
            ) : (
              <Autocomplete
                name="manage_as_project"
                onChange={(event, value) => {
                  formik.setFieldValue('manage_as_project', value?.id);
                }}
                options={YesNoDropdown || []}
                getOptionLabel={option => option && option.value}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Manage as a Project"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                disabled={action === 'view'}
                value={
                  YesNoDropdown.find(
                    obj => obj.id == formik?.values?.manage_as_project
                  ) || ''
                }
              />
            )}
          </Box>
          {formik?.values?.source_system?.source_system_id ===
            SOURCE_SYSTEMS.LUMBER_LIQUIDATOR &&
            action !== 'add' && (
              <>
                <Box className={classes.boxColumn} m={1}>
                  {loading ? (
                    <Skeleton className="w-full" height="3rem" />
                  ) : (
                    <TextField
                      label="Job Status"
                      InputProps={{
                        readOnly: true,
                      }}
                      disabled={true}
                      value={
                        formik?.values?.project_additional_fields?.ll_job_status
                      }
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                </Box>
                <Box className={classes.boxColumn} m={1}>
                  {loading ? (
                    <Skeleton className="w-full" height="3rem" />
                  ) : (
                    <TextField
                      label="SR Job Status"
                      InputProps={{
                        readOnly: true,
                      }}
                      disabled={true}
                      value={
                        formik?.values?.project_additional_fields
                          ?.ll_sr_job_status
                      }
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                </Box>
                <Box className={classes.boxColumn} m={1}>
                  {loading ? (
                    <Skeleton className="w-full" height="3rem" />
                  ) : (
                    <TextField
                      label="Job Accepted By Vendor"
                      InputProps={{
                        readOnly: true,
                      }}
                      disabled={true}
                      value={
                        formik?.values?.project_additional_fields
                          ?.ll_job_accepted
                      }
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                </Box>
                <Box className={classes.boxColumn} m={1}>
                  {loading ? (
                    <Skeleton className="w-full" height="3rem" />
                  ) : (
                    <TextField
                      label="Measure Request Sent"
                      InputProps={{
                        readOnly: true,
                      }}
                      disabled={true}
                      value={
                        formik?.values?.project_additional_fields
                          ?.ll_measure_req_sent
                          ? format(
                              new Date(
                                formik?.values?.project_additional_fields?.ll_measure_req_sent
                              ),
                              'MM-dd-yyyy'
                            )
                          : ''
                      }
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                </Box>
                <Box className={classes.boxColumn} m={1}>
                  {loading ? (
                    <Skeleton className="w-full" height="3rem" />
                  ) : (
                    <TextField
                      label="Installation Paperwork Date"
                      InputProps={{
                        readOnly: true,
                      }}
                      disabled={true}
                      value={
                        formik?.values?.project_additional_fields
                          ?.ll_install_paper_date
                          ? format(
                              new Date(
                                formik?.values?.project_additional_fields?.ll_install_paper_date
                              ),
                              'MM-dd-yyyy'
                            )
                          : ''
                      }
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                </Box>
                <Box className={classes.boxColumn} m={1}>
                  {loading ? (
                    <Skeleton className="w-full" height="3rem" />
                  ) : (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <MuiKeyboardDatePicker
                        name="ll_pgi_invoice_date"
                        label="PGI Invoice Date"
                        format="MM-dd-yyyy"
                        value={
                          (formik?.values?.ll_pgi_invoice_date &&
                            formik?.values?.ll_pgi_invoice_date?.split('T')
                              ?.length &&
                            parseISO(
                              formik?.values?.ll_pgi_invoice_date?.slice(0, 10)
                            )) ||
                          null
                        }
                        onChange={(date, value) =>
                          formik?.setFieldValue(
                            'll_pgi_invoice_date',
                            `${moment(date).format('YYYY-MM-DD')}`
                          )
                        }
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                          disabled: action === 'view' || action === 'copy',
                          style: {
                            display:
                              (action === 'view' || action === 'copy') &&
                              'none',
                          },
                        }}
                        disabled={action === 'view' || action === 'copy'}
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                      />
                    </MuiPickersUtilsProvider>
                  )}
                </Box>
              </>
            )}
          <Box className={classes.boxColumn} m={1}>
            {loading ? (
              <Skeleton className="w-full" height="3rem" />
            ) : (
              <Autocomplete
                name="related_measurer"
                label="Related Technician"
                options={allUserList || []}
                getOptionLabel={option => {
                  return option ? option?.user_name : '';
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Related Technician"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                onChange={(event, value) => {
                  if (value) {
                    formik.setFieldValue('related_measurer', value?.user_id);
                  }
                }}
                disabled={action === 'view'}
                value={
                  allUserList?.find(
                    user => user?.user_id === formik?.values?.related_measurer
                  ) || ''
                }
                onInputChange={debounce(handleRelatedMeasurerChanges, 300)}
              ></Autocomplete>
            )}
          </Box>
          <Box className={classes.boxColumn} m={1}>
            {loading ? (
              <Skeleton className="w-full" height="3rem" />
            ) : (
              <TextField
                id="street1"
                label="PSE"
                onChange={event => {}}
                InputProps={{
                  readOnly: true,
                }}
                disabled={true}
                value={
                  storeDetails[0]?.pse_name || storeDetails?.pse_name || ''
                }
                InputLabelProps={{ shrink: true }}
              />
            )}
          </Box>
          {formik?.values?.source_system?.source_system_id !==
            SOURCE_SYSTEMS.LOWES && (
            <Box className={classes.boxColumn} m={1}>
              {loading ? (
                <Skeleton className="w-full" height="3rem" />
              ) : (
                <Autocomplete
                  name="referral_source_id"
                  onChange={(event, value) => {
                    formik.setFieldValue(
                      'referral_source_id',
                      value?.referral_source_id
                    );
                  }}
                  options={referralSourceOptions || []}
                  getOptionLabel={option =>
                    option ? option?.referral_source_name : ''
                  }
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Referral Source Name"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                  disabled={action === 'view'}
                  value={
                    referralSourceOptions.find(
                      source =>
                        source?.referral_source_id ===
                        formik?.values?.referral_source_id
                    ) || ''
                  }
                />
              )}
            </Box>
          )}
          {(formik?.values?.project_type?.project_type_id ===
            PROJECT_TYPE.MEASUREMENT ||
            formik?.values?.project_type?.project_type_id ===
              PROJECT_TYPE.INSTALLATION) && (
            <Box className={classes.boxColumn} m={1}>
              {loading ? (
                <Skeleton className="w-full" height="3rem" />
              ) : (
                <NumberFormat
                  name="estimated_labour_amount"
                  label="Estimated Labor Amount"
                  value={formik?.values?.estimated_labour_amount || '0.00'}
                  decimalScale={2}
                  customInput={TextField}
                  onChange={e => {
                    formik?.setFieldValue(
                      'estimated_labour_amount',
                      event?.target?.value
                    );
                  }}
                  inputmode="numeric"
                  disabled={
                    action === 'view' ||
                    formik?.values?.project_type?.project_type_id ===
                      PROJECT_TYPE.INSTALLATION
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        style={{ marginRight: '3px' }}
                      >
                        $
                      </InputAdornment>
                    ),
                  }}
                  isAllowed={values => {
                    const { value } = values;
                    if (value) {
                      return value < ESTIMATED_MAX_LIMIT;
                    }
                    return true;
                  }}
                ></NumberFormat>
              )}
            </Box>
          )}

          {CLIENT_DETAILS.client_id == CLIENT_ID.BM_FLOORING && (
            <Box className={classes.boxColumn} m={1}>
              {loading ? (
                <Skeleton className="w-full" height="3rem" />
              ) : (
                <NumberFormat
                  name="cushion_payout"
                  label="Cushion Payout"
                  value={formik.values?.cushion_payout}
                  decimalScale={2}
                  customInput={TextField}
                  onChange={e => {
                    formik?.setFieldValue(
                      'cushion_payout',
                      event?.target?.value
                    );
                  }}
                  inputmode="numeric"
                  disabled={action === 'view'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        style={{ marginRight: '3px' }}
                      >
                        $
                      </InputAdornment>
                    ),
                  }}
                ></NumberFormat>
              )}
            </Box>
          )}

          {formik.values?.source_system?.source_name === 'Lowes' &&
          formik.values?.project_type?.project_type === 'Measurement' ? (
            <Box className={classes.boxColumn} m={1}>
              {loading ? (
                <Skeleton className="w-full" height="3rem" />
              ) : (
                <TextField
                  id="digital_detail_url"
                  label="Link To Digital Detail"
                  InputLabelProps={{ shrink: true }}
                  value={
                    action !== 'view'
                      ? formik.values?.project_additional_fields
                          ?.digital_detail_url
                      : ''
                  }
                  onChange={event => {
                    formik.setFieldValue(
                      'project_additional_fields.digital_detail_url',
                      event.target.value
                    );
                  }}
                  InputProps={{
                    startAdornment: action === 'view' &&
                      formik.values?.project_additional_fields
                        ?.digital_detail_url && (
                        <a
                          href={
                            formik.values?.project_additional_fields
                              ?.digital_detail_url
                          }
                          target="_blank"
                          rel="noreferrer"
                          className="overflow-hidden text-overflow-ellipsis w-full no-underline absolute"
                        >
                          Click Here
                        </a>
                      ),
                  }}
                  disabled={action === 'view'}
                />
              )}
            </Box>
          ) : (
            ''
          )}

          {getConfigurationEntities?.[0]?.config?.field_groups?.[0]?.fields?.map(
            (response, index) => {
              let entityField = null;
              if (response.type === 'TEXT') {
                entityField = (
                  <Box key={index} width="24%" ml={1} mt={1}>
                    {loading ? (
                      <Skeleton className="w-full" height="3rem" />
                    ) : (
                      <TextField
                        type={'text'}
                        id={`meta.${response.slug}`}
                        name={`meta.${response.slug}`}
                        label={response.name}
                        onChange={(event, value) => {
                          formik.setFieldValue(
                            `meta.${response.slug}`,
                            event.target.value
                          );
                        }}
                        required={response.is_required}
                        value={
                          formik?.values?.meta[response.slug] &&
                          formik.values.meta[response.slug]
                        }
                        InputProps={{
                          readOnly: (action === 'view' && true) || false,
                        }}
                        defaultValue={response.default}
                        disabled={(action === 'view' && true) || false}
                        InputLabelProps={{ shrink: true }}
                        helperText={response.help_text}
                      />
                    )}
                  </Box>
                );
              } else if (response.type === 'EMAIL') {
                entityField = (
                  <Box key={index} width="24%" ml={1} mt={1}>
                    {loading ? (
                      <Skeleton className="w-full" height="3rem" />
                    ) : (
                      <TextField
                        type={'email'}
                        id={`meta.${response.slug}`}
                        name={`meta.${response.slug}`}
                        label={response.name}
                        onChange={(event, value) => {
                          formik.setFieldValue(
                            `meta.${response.slug}`,
                            event.target.value
                          );
                        }}
                        required={response.is_required}
                        value={
                          formik?.values?.meta[response.slug] &&
                          formik.values.meta[response.slug]
                        }
                        InputProps={{
                          readOnly: (action === 'view' && true) || false,
                        }}
                        defaultValue={response.default}
                        disabled={(action === 'view' && true) || false}
                        InputLabelProps={{ shrink: true }}
                        helperText={response.help_text}
                      />
                    )}
                  </Box>
                );
              } else if (response.type === 'URL') {
                entityField = (
                  <Box key={index} width="24%" ml={1} mt={1}>
                    {loading ? (
                      <Skeleton className="w-full" height="3rem" />
                    ) : (
                      <TextField
                        type={'text'}
                        id={`meta.${response.slug}`}
                        name={`meta.${response.slug}`}
                        label={response.name}
                        onChange={(event, value) => {
                          formik.setFieldValue(
                            `meta.${response.slug}`,
                            event.target.value
                          );
                        }}
                        required={response.is_required}
                        value={
                          formik?.values?.meta[response.slug] &&
                          formik.values.meta[response.slug]
                        }
                        InputProps={{
                          readOnly: (action === 'view' && true) || false,
                        }}
                        defaultValue={response.default}
                        disabled={(action === 'view' && true) || false}
                        InputLabelProps={{ shrink: true }}
                        helperText={response.help_text}
                      />
                    )}
                  </Box>
                );
              } else if (response.type === 'TEXTAREA') {
                entityField = (
                  <Box key={index} width="24%" ml={1} mt={1}>
                    {loading ? (
                      <Skeleton className="w-full" height="3rem" />
                    ) : (
                      <TextField
                        type={'text'}
                        id={`meta.${response.slug}`}
                        name={`meta.${response.slug}`}
                        label={response.name}
                        onChange={(event, value) => {
                          formik.setFieldValue(
                            `meta.${response.slug}`,
                            event.target.value
                          );
                        }}
                        required={response.is_required}
                        value={
                          formik?.values?.meta[response.slug] &&
                          formik.values.meta[response.slug]
                        }
                        InputProps={{
                          readOnly: (action === 'view' && true) || false,
                        }}
                        multiline
                        rows={3}
                        maxRows={5}
                        defaultValue={response.default}
                        disabled={(action === 'view' && true) || false}
                        InputLabelProps={{ shrink: true }}
                        helperText={response.help_text}
                      />
                    )}
                  </Box>
                );
              } else if (response.type === 'DATE') {
                entityField = (
                  <Box key={index} width="24%" ml={1} mt={1}>
                    {loading ? (
                      <Skeleton className="w-full" height="3rem" />
                    ) : (
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <MuiKeyboardDatePicker
                          id={`meta.${response.slug}`}
                          name={`meta.${response.slug}`}
                          label={response.name}
                          format="MM-dd-yyyy"
                          value={
                            (formik?.values?.meta[response.slug]?.split('T')
                              ?.length &&
                              parseISO(
                                formik?.values?.meta[response.slug]?.slice(
                                  0,
                                  10
                                )
                              )) ||
                            (action === 'add' && new Date()) ||
                            null
                          }
                          onChange={(date, value) =>
                            formik?.setFieldValue(
                              `meta.${response.slug}`,
                              `${moment(date).format('YYYY-MM-DD')}T00:00:00.000Z`
                            )
                          }
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                            disabled: action === 'edit' || action === 'view',
                            style: { display: action === 'view' && 'none' },
                          }}
                          disabled={(action === 'view' && true) || false}
                          required={response.is_required}
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          helperText={response.help_text && response.help_text}
                        />
                      </MuiPickersUtilsProvider>
                    )}
                  </Box>
                );
              } else if (response.type === 'TIMESTAMP') {
                entityField = (
                  <Box key={index} width="24%" ml={1} mt={1}>
                    {loading ? (
                      <Skeleton className="w-full" height="3rem" />
                    ) : (
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDateTimePicker
                          autoOk
                          id={`meta.${response.slug}`}
                          name={`meta.${response.slug}`}
                          label={response.name}
                          format="MM-dd-yyyy hh:mm a"
                          value={
                            formik?.values?.meta[response.slug] &&
                            formik.values.meta[response.slug]
                          }
                          onChange={(date, value) =>
                            formik?.setFieldValue(`meta.${response.slug}`, date)
                          }
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                            disabled: action === 'edit' || action === 'view',
                            style: { display: action === 'view' && 'none' },
                          }}
                          disabled={(action === 'view' && true) || false}
                          required={response.is_required}
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          helperText={response.help_text && response.help_text}
                        />
                      </MuiPickersUtilsProvider>
                    )}
                  </Box>
                );
              }
              return entityField;
            }
          )}
          {formik?.values?.opportunity_number && (
            <>
              <Box className={classes.boxColumn} m={1}>
                {loading ? (
                  <Skeleton className="w-full" height="3rem" />
                ) : (
                  <TextField
                    id="opportunity_number"
                    label="Lead Number"
                    InputProps={{
                      readOnly: true,
                    }}
                    disabled={true}
                    value={formik?.values?.opportunity_number || ''}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              </Box>
            </>
          )}
          {opportunityInfo?.leadOwner && (
            <>
              <Box className={classes.boxColumn} m={1}>
                {loading ? (
                  <Skeleton className="w-full" height="3rem" />
                ) : (
                  <TextField
                    id="leadOwner"
                    label="Sales Person"
                    InputProps={{
                      readOnly: true,
                    }}
                    disabled={true}
                    value={opportunityInfo?.leadOwner || ''}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              </Box>
            </>
          )}
          {formik?.values?.quote_number && (
            <>
              <Box className={classes.boxColumn} m={1}>
                {loading ? (
                  <Skeleton className="w-full" height="3rem" />
                ) : (
                  <TextField
                    id="opportunity_number"
                    label="Quote Number"
                    InputProps={{
                      readOnly: true,
                    }}
                    disabled={true}
                    value={formik?.values?.quote_number || ''}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              </Box>
            </>
          )}
          <Box className={classes.boxColumn} m={1}>
            {loading ? (
              <Skeleton className="w-full" height="3rem" />
            ) : (
              <Autocomplete
                name="project_color_config"
                onChange={(event, value) => {
                  if (value?.color_config_id === AddNewColor.ADDNEWCOLOR) {
                    window.open('/project-color-code', '_blank');
                  } else {
                    formik.setFieldValue(
                      'project_color_config',
                      value?.color_config_id
                    );
                  }
                }}
                options={colorConfigOptions || []}
                getOptionLabel={option => (option ? option?.color_name : '')}
                renderOption={(props, option) => {
                  return (
                    <div className="flex">
                      {props?.color_config_id === AddNewColor.ADDNEWCOLOR ? (
                        <span className="pl-1">{props?.color_name}</span>
                      ) : (
                        <>
                          <span>
                            <PFColorPicker
                              value={
                                isValidHex(props?.color_code)
                                  ? props?.color_code
                                  : '#5e5ba6'
                              }
                              disabled
                            />
                          </span>
                          <span className="pl-2 ">{props?.color_name}</span>
                        </>
                      )}
                    </div>
                  );
                }}
                renderInput={params => {
                  const foundColorCode = colorConfigOptions?.find(
                    config =>
                      config?.color_config_id ===
                      formik?.values?.project_color_config
                  )?.color_code;
                  return (
                    <TextField
                      {...params}
                      label="Color Code"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <PFColorPicker
                            value={
                              isValidHex(foundColorCode)
                                ? foundColorCode
                                : isValidHex(
                                      formik?.values?.project_color_config
                                    )
                                  ? formik?.values?.project_color_config
                                  : '#5e5ba6'
                            }
                            disabled
                          />
                        ),
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  );
                }}
                value={
                  colorConfigOptions?.find(
                    config =>
                      config?.color_config_id ===
                      formik?.values?.project_color_config
                  ) || { color_name: 'Default' }
                }
                disabled={action === 'view'}
              />
            )}
          </Box>
          {formik?.values?.source_system?.source_system_id ===
            SOURCE_SYSTEMS.LOWES && (
            <Box className={classes.boxColumn} m={1}>
              {loading ? (
                <Skeleton className="w-full" height="3rem" />
              ) : (
                <Autocomplete
                  name="Property Occupation"
                  onChange={(event, value) => {
                    formik.setFieldValue('property_occupation', value?.value);
                  }}
                  options={PropertyOccupationDropdown || []}
                  getOptionLabel={option => option && option.value}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Property Occupation"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                  disabled={action === 'view'}
                  value={
                    PropertyOccupationDropdown.find(
                      obj => obj.value == formik?.values?.property_occupation
                    ) || ''
                  }
                />
              )}
            </Box>
          )}
          {(action === 'add' || !projectTypeEnabled) && (
            <Box className={classes.boxColumn} m={1}>
              {loading ? (
                <Skeleton className="w-full" height="3rem" />
              ) : (
                <NumberFormat
                  name="total_sale_amount"
                  label="Total Sale Amount"
                  value={formik?.values?.total_sale_amount || '0.00'}
                  decimalScale={2}
                  customInput={TextField}
                  onChange={e => {
                    formik?.setFieldValue(
                      'total_sale_amount',
                      event?.target?.value
                    );
                  }}
                  inputmode="numeric"
                  disabled={action === 'view'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        style={{ marginRight: '3px' }}
                      >
                        $
                      </InputAdornment>
                    ),
                  }}
                ></NumberFormat>
              )}
            </Box>
          )}
        </Box>
      </Grid>
      <Dialog
        open={categoryPopupOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Add New Category'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {newCategoryLoading ? (
              <Grid
                container
                item
                spacing={2}
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress />
              </Grid>
            ) : (
              <>
                <div className={classes.newCateryError}>
                  {newCategoryError || ''}
                </div>
                <TextField
                  id="category_name"
                  label="Category"
                  onChange={event => {
                    setNewCategoryError(null);
                    setNewCategory(event.target.value);
                  }}
                  value={newCategory || ''}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ maxLength: 50 }}
                />
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={saveNewCategory}
            color="primary"
            variant="contained"
            autoFocus
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const AutoCompleteTitle = ({ option, inputValue }) => {
  const matches = match(option.store_number.toString(), inputValue);
  const parts = parse(option.store_number.toString(), matches);

  return (
    <div style={{ margin: 0, padding: 0 }}>
      {parts.map((part, index) => (
        <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
          {part.text}
        </span>
      ))}
      <br />
      <p>{option.store_name}</p>
    </div>
  );
};

export default ProjectInfo;
