import React, { useState } from 'react';
import { formateNumber } from '../helpers/Quotes';
import PFInputNumber from '../../../shared/PFPrime/PFInputNumber';
import PFFormikError from '../../../shared/PFPrime/PFFormikError';
const CostEachEditor = ({
  rowData,
  options,
  editingRows,
  submitAction,
  onBlurUnitPrice,
  retailerPercentage,
}) => {
  const { values, touched, errors, setFieldValue, setValues } = submitAction;
  const [value, setValue] = useState(formateNumber(values?.unitPrice || 0));
  const isEditing = editingRows[rowData.rowIndex];
  return isEditing ? (
    <div className="vertical-align-top">
      <span className="p-float-label">
        <PFInputNumber
          inputId="unitPrice"
          name="unitPrice"
          mode={'currency'}
          currency="USD"
          minFractionDigits={2}
          value={value}
          onChange={(name, value) => setValue(value)}
          onBlur={() => {
            setFieldValue('unitPrice', value);
            onBlurUnitPrice(value, values, setValues, retailerPercentage);
          }}
          className={touched?.unitPrice && errors?.unitPrice ? 'error' : ''}
          version={2}
          inputStyle={{ width: '100px' }}
        />
      </span>
      <PFFormikError touched={touched} errors={errors} field="unitPrice" />
    </div>
  ) : (
    <div className="pt-2">
      <div className="w-full">{`$${formateNumber(rowData?.unitPrice).toFixed(2)}`}</div>
      <div className="w-full">
        <span className="font-bold">{`$${formateNumber(rowData?.totalCost).toFixed(2)}`}</span>
      </div>
    </div>
  );
};

export default CostEachEditor;
