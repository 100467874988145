import React, { useState } from 'react';
import { Dropdown } from 'primereact/dropdown';

export default function PFDropdown({
  value,
  name,
  onChange,
  options,
  optionLabel,
  placeholder,
  filter,
  className,
  optionValue,
  disabled,
  appendTo,
  showClear,
  onKeyPress,
  onKeyUp,
  onKeyDown,
  filterValue,
  onFilterChange,
  onBlur,
  pt,
  ...props
}) {
  return (
    <Dropdown
      {...props}
      value={value || ''}
      name={name || ''}
      onChange={e => onChange(name, e.value)}
      options={options || []}
      optionLabel={optionLabel || ''}
      optionValue={optionValue || ''}
      placeholder={placeholder || ''}
      filter={filter || false}
      className={`w-full p-inputtext-sm ${className || ''}`}
      disabled={disabled || false}
      appendTo={appendTo || null}
      virtualScrollerOptions={false}
      showClear={showClear || false}
      onKeyPress={onKeyPress ? onKeyPress : () => {}}
      onKeyUp={onKeyUp ? onKeyUp : () => {}}
      onKeyDown={onKeyDown ? onKeyDown : () => {}}
      onBlur={onBlur ? onBlur : () => {}}
      filterValue={filterValue || ''}
      onFilterChange={onFilterChange ? onFilterChange : () => {}}
      resetFilterOnHide={true}
      pt={pt}
    />
  );
}
