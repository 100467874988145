import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

import { CONFIG } from '../../../../constants';
import { toTitleCase } from '../../../../utils/Helpers';
import ApiService from '../../../../services/api.service';

import {
  contactInitialValues,
  contactValidationSchema,
} from './OpportunityContact';
import {
  generalInformationInitialValues,
  generalInformationValidationSchema,
} from './OpportunityInfo';

export const opportunityBreadcrumb = (
  mode,
  opportunityNumber = '',
  customerId = '',
  customerName = ''
) => {
  return [
    {
      text: 'Project Management',
    },
    {
      text: 'Clients',
      link: `/project/clients`,
    },
    {
      text: toTitleCase(customerName),
      link: `/crm/view/customer/${customerId}`,
    },
    {
      text: `Lead`,
      link: `/crm/view/customer/${customerId}`,
    },
    ...(opportunityNumber && [
      {
        text: `${opportunityNumber}`,
      },
    ]),
  ];
};

const oppotunityInitialValues = {
  ...generalInformationInitialValues,
  ...contactInitialValues,
};

const oppotunityValidationSchema = Yup.object().shape({
  ...generalInformationValidationSchema,
  ...contactValidationSchema,
});

const createOpportunity = async (values, setLoading, toast, history) => {
  try {
    const response = await axios.post(
      `${CONFIG?.API_CRM_URL}/api/${values?.customerId}/opportunity`,
      values
    );
    if (response.status === 201) {
      toast.current.show({
        severity: 'success',
        summary: 'Done',
        detail: 'Lead has been added successfully.',
        life: 3000,
      });
      return history.push(`/crm/view/customer/${values?.customerId}`);
    }
  } catch (error) {
    toast.current.show({
      severity: 'error',
      summary: 'Error',
      detail: 'Something went wrong',
      life: 3000,
    });
  } finally {
    setLoading(false);
  }
};

const updateOpportunity = async (values, toast, setLoading, history) => {
  try {
    const response = await axios.patch(
      `${CONFIG?.API_CRM_URL}/api/${values?.customerId}/opportunity/${values?.opportunityId}`,
      values
    );
    if (response.status === 200) {
      toast.current.show({
        severity: 'success',
        summary: 'Done',
        detail: 'Lead has been updated successfully.',
        life: 3000,
      });
      return history.push(
        `/crm/${values?.customerId}/lead/view/${values?.opportunityId}`
      );
    }
  } catch (error) {
    toast.current.show({
      severity: 'error',
      summary: 'Error',
      detail: 'Something went wrong',
      life: 3000,
    });
  } finally {
    setLoading(false);
  }
};

export const opportunitysGridData = async (
  customerId,
  rows,
  page,
  sortBy,
  orderBy
) => {
  let queryParams = `limit=${rows || 10}`;
  if (page) {
    queryParams = `${queryParams}&page=${page + 1}`;
  }
  if (sortBy) {
    queryParams = `${queryParams}&sortBy=${sortBy}`;
  }
  if (orderBy) {
    queryParams = `${queryParams}&sortOrder=${orderBy == 1 ? 'asc' : 'desc'}`;
  }

  return await axios.get(
    `${CONFIG?.API_CRM_URL}/api/customer/opportunity/${customerId}?${queryParams}`
  );
};

export const fetchOpportunityById = async (
  opportunityId,
  customerId,
  setOppotunity,
  setLoading,
  history
) => {
  try {
    if (opportunityId) {
      const opportunity = await axios.get(
        `${CONFIG?.API_CRM_URL}/api/${customerId}/opportunity/${opportunityId}`
      );
      if (opportunity?.status === 200 && opportunity?.data) {
        if (
          opportunity?.data &&
          opportunity?.data?.follow_up_date &&
          typeof opportunity?.data?.follow_up_date === 'string'
        ) {
          opportunity.data.follow_up_date = new Date(
            opportunity.data.follow_up_date
          );
        }
        setOppotunity(opportunity?.data);

        return opportunity?.data;
      }
    }
  } catch (error) {
    if (error?.response?.status === 404) {
      return history.push('/page-not-found');
    }
    console.log('error', error);
  } finally {
    setLoading(false);
  }
};

export const getOpportunityById = async (customerId, opportunityId) => {
  let url = `${CONFIG?.API_CRM_URL}/api/${customerId}/opportunity/${opportunityId}`;
  return await axios.get(url);
};

export const leadSubmit = (
  setLoading,
  toast,
  history,
  opportunityDetails,
  mode
) => {
  const init = opportunityDetails
    ? opportunityDetails
    : oppotunityInitialValues;
  return useFormik({
    enableReinitialize: true,
    initialValues: init,
    validationSchema: oppotunityValidationSchema,
    onSubmit: async values => {
      setLoading(true);
      if (mode === 'create') {
        createOpportunity(values, setLoading, toast, history);
      }
      if (mode === 'edit') {
        updateOpportunity(values, toast, setLoading, history);
      }
    },
  });
};

export const getGroupName = async value => {
  try {
    const response = await ApiService.get(`/system/parent-category/list`);
    return response;
  } catch (error) {
    console.error(error);
  }
};
