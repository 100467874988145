import React, { useRef, useState, useEffect } from 'react';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { useParams } from 'react-router';
import moment from 'moment';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Card } from 'primereact/card';
import { Toast } from 'primereact/toast';
import axios from 'axios';
import { Menu } from 'primereact/menu';

import PageHeader from '../../../shared/PageHeader/PageHeader';
import {
  clientInformation,
  quotesBreadcrumb,
  clientInformationData,
  createQuote,
  getQuoteById,
  updateQuote,
  previewQuote,
  sendQuote,
  downloadQuote,
  formateNumber,
} from '../helpers/Quotes';
import PFDataTable from '../../../shared/PFPrime/PFDataTable';
import PFInputText from '../../../shared/PFPrime/PFInputText';
import PFButton from '../../../shared/PFPrime/PFButton';
import ClientInfomation from '../components/ClientInfomation';
import OpportunityInfomation from '../components/OpportunityInfomation';
import CommunicationsContainer from '../../Communications/CommunicationsContainer';
import { COMMUNICATION_TYPE } from '../../Communications/constants/constants';
import PFDialog from '../../../shared/PFPrime/PFDialog';
import { gridProjectsData } from '../../Projects/helpers/ProjectsListing';
import PFManuButton from '../../../shared/PFPrime/PFManuButton';
import {
  cloneProject,
  markAsFinal,
  pullProjectDocuments,
  pullProjectItems,
  sendProject,
} from '../../Projects/helpers/MeasureSquareQuotes';
import { isMeasureSquareEnabled } from '../../../../utils/Helpers';
import { CONFIG } from '../../../../constants';
import apiService from '../../../../services/api.service';
import {
  createLaborItem,
  createMerchandiseItem,
} from '../../../ProjectManagement/ProjectItemsNew/ProjectItemsService';

import MeasureSquareSummary from './MeasureSquareSummary';
import ItemResult from './ItemResult';
import QuoteTabs from './QuoteTabs';

const QuoteForm = () => {
  const history = useHistory();
  const queryParams = useParams();
  const toast = useRef(null);
  const { PFManuTemplate, items: manuItems } = PFManuButton();
  const { customerId, opportunityId, mode, actionId } = queryParams;
  const [laborItems, setLaborItems] = useState([]);
  const [merchandiseItems, setMerchandiseItems] = useState([]);
  const [items, setItems] = useState([]);
  const [initItems, setInitItems] = useState([]);
  const [expiryDate, setExpiryDate] = useState('');
  const [statusId, setStatusId] = useState('');
  const [quoteId, setQuoteId] = useState('');
  const [quoteCreateAt, setQuoteCreateAt] = useState('');
  const [sendBtn, setSendBtn] = useState(false);
  const [downloadBtn, setDownloadBtn] = useState(false);
  const [opportunityDetails, setOpportunityDetails] = useState(null);
  const [quoteProjectCount, setQuoteProjectCount] = useState(0);
  const [isQuoteActive, setIsQuoteActive] = useState(true);
  const [isQuoteLoading, setIsQuoteLoading] = useState(true);
  const [quoteDetails, setQuoteDetails] = useState(null);
  const userName = localStorage.getItem('client_name');
  const [loading, setLoading] = useState(false);
  const [retailerPercentage, setRetailerPercentage] = useState('0');
  const [mVendorId, setMVendorId] = useState('');
  const [msProjectId, setMsProjectId] = useState(false);
  const [isForm, setIsForm] = useState(false);
  const priviewMenuLeft = useRef(null);
  const downloadMenuLeft = useRef(null);
  const sendMenuLeft = useRef(null);
  const [retailRate, setRetailRate] = useState(0);

  const [formPayload, setFormPayload] = useState({
    customerId: customerId,
    opportunityId: opportunityId,
    amount: {},
  });
  const [aggregate, setAggregate] = useState({
    totalLaborRetail: 0,
    totalLaborRevenue: 0,
    totalLaborCost: 0,
    totalLaborDiscount: 0,
    totalLabortotaltax: 0,
    totalProductRetail: 0,
    totalProductRevenue: 0,
    totalProductCost: 0,
    totalProductDiscount: 0,
    totalProductTax: 0,
    totalRetail: 0,
    totalRevenue: 0,
    totalCost: 0,
    totalDiscount: 0,
    totaltax: 0,
  });

  const [customerDetails, setCustomerDetails] = useState(null);

  const findSum = (key, type) => {
    let itemObject = [];
    if (type === 2) {
      itemObject = items?.filter(i => i?.itemTypeId === 2);
    } else {
      itemObject = items?.filter(i => i?.itemTypeId != 2);
    }
    return itemObject?.reduce((sum, item) => {
      return sum + parseFloat(item[key] || 0);
    }, 0);
  };

  const calculateAgregator = pre => {
    pre.totalLaborRetail = findSum('totalPrice', 2);
    pre.totalLaborRevenue = findSum('totalRevenue', 2);
    pre.totalLaborCost = findSum('totalCost', 2);
    pre.totalLaborDiscount = findSum('totalDiscount', 2);
    pre.totalLabortotaltax = findSum('taxAmount', 2);
    pre.totalProductRetail = findSum('totalPrice');
    pre.totalProductRevenue = findSum('totalRevenue');
    pre.totalProductCost = findSum('totalCost');
    pre.totalProductDiscount = findSum('totalDiscount');
    pre.totalProductTax = findSum('taxAmount');

    pre.totalRetail =
      (pre?.totalLaborRetail || 0) + (pre?.totalProductRetail || 0);

    pre.totalRevenue =
      (pre?.totalLaborRevenue || 0) + (pre?.totalProductRevenue || 0);

    pre.totalCost = (pre?.totalLaborCost || 0) + (pre?.totalProductCost || 0);
    pre.totalDiscount =
      (pre?.totalLaborDiscount || 0) + (pre?.totalProductDiscount || 0);
    pre.totaltax = (pre?.totalLabortotaltax || 0) + (pre?.totalProductTax || 0);

    return pre;
  };

  useEffect(() => {
    setAggregate(pre => calculateAgregator(pre));

    setFormPayload(preValues => ({
      ...preValues,
      lineItems: { items: items },
      ...aggregate,
      mVendorId: mVendorId,
    }));
  }, [items, retailRate]);

  useEffect(() => {
    setFormPayload(preValues => ({ ...preValues, statusId: statusId }));
  }, [statusId]);

  useEffect(() => {
    setFormPayload(preValues => ({ ...preValues, mVendorId: mVendorId }));
  }, [mVendorId]);
  useEffect(() => {
    setFormPayload(preValues => ({
      ...preValues,
      retailerPercentage: retailerPercentage,
    }));
  }, [retailerPercentage]);

  useEffect(() => {
    setFormPayload(preValues => ({
      ...preValues,
      expiryDate: expiryDate
        ? moment.utc(expiryDate).local().format('YYYY-MM-DD')
        : '',
    }));
  }, [expiryDate]);

  const storeQuote = initItems => {
    if (!formPayload?.expiryDate) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please select valid until date for this quote.',
      });
    } else {
      const payloadObject = {
        items: formPayload?.lineItems?.items,
        paymentTerms: formPayload?.paymentTerms,
        termAndConditions: formPayload?.termAndConditions,
        retailerPercentage: formPayload?.retailerPercentage,
      };

      const isUpdateVersion =
        JSON.stringify(payloadObject) === JSON.stringify(initItems);

      formPayload['isUpdateVersion'] = false;
      if (mode !== 'create') {
        formPayload['isUpdateVersion'] = !isUpdateVersion;
      }

      const quote =
        mode === 'create'
          ? createQuote(formPayload)
          : updateQuote(formPayload, actionId);

      const message = quoteNumber => {
        return mode === 'create'
          ? `Quote ${quoteNumber} created successfully.`
          : `Quote ${quoteNumber} update successfully.`;
      };
      quote
        .then(res => {
          if (res?.status === 200) {
            toast.current.show({
              severity: 'success',
              summary: 'success',
              detail: message(res?.data?.data?.quoteNumber || ''),
            });
            return history.push(
              `/crm/${customerId}/${opportunityId}/quote/view/${res?.data?.data?.quoteId}`
            );
          }
        })
        .catch(err => {
          console.error(err);
        });
    }
  };

  const getQuoteApiById = () => {
    if (actionId) {
      setLoading(true);
      const quoteDetails = getQuoteById(customerId, opportunityId, actionId);
      quoteDetails
        .then(res => {
          if (res.status === 200) {
            const quote = res?.data?.data;
            const {
              expiryDate,
              createdAt,
              statusId,
              quoteNumber,
              termAndConditions,
              paymentTerms,
              projectCounts,
              mVendorId,
              retailerPercentage,
              ms_project_id,
            } = quote;
            setRetailerPercentage(retailerPercentage);
            setMVendorId(mVendorId);
            setMsProjectId(ms_project_id ? true : false);
            setQuoteDetails(quote);
            setIsQuoteActive(quote?.isActive);
            setIsQuoteLoading(false);
            setExpiryDate(expiryDate);
            setStatusId(statusId);
            setQuoteCreateAt(createdAt);
            setQuoteId(quoteNumber);
            setQuoteProjectCount(projectCounts || 0);

            const { items } = quote?.lineItems;
            const laborItems = items?.filter(
              item => item?.itemType === 'Labor'
            );
            setLaborItems(laborItems || []);
            const merchandiseItems = items?.filter(
              item => item?.itemType !== 'Labor'
            );
            setMerchandiseItems(merchandiseItems || []);
            setInitItems({
              items: [...laborItems, ...merchandiseItems],
              paymentTerms: paymentTerms,
              termAndConditions: termAndConditions,
              retailerPercentage: retailerPercentage,
            });

            setFormPayload(preValues => ({
              ...preValues,
              termAndConditions: termAndConditions,
              paymentTerms: paymentTerms,
              ms_project_id: ms_project_id || null,
            }));
            setLoading(false);
          }
        })
        .catch(error => {
          if (error?.response?.status === 404) {
            return history.push('/page-not-found');
          }
          setLoading(false);
          console.error('error', error?.response);
        });
    }
  };

  useEffect(() => {
    getQuoteApiById();
  }, [actionId]);

  const onPreview = type => {
    const preview = previewQuote(customerId, opportunityId, actionId, type);
    preview
      .then(res => {
        if (res.status === 200) {
          const newWindow = window.open('quote-preview', '_blank');
          newWindow.document.write(res?.data);
          newWindow.document.close();
        }
      })
      .catch(err => {
        console.error('err', err);
      });
  };

  const onSend = type => {
    setSendBtn(true);
    const send = sendQuote(customerId, opportunityId, actionId, type);
    send
      .then(res => {
        if (res?.status === 200) {
          toast.current.show({
            severity: 'success',
            summary: 'success',
            detail: res?.data?.message,
          });
          setSendBtn(false);
        }
      })
      .catch(err => {
        console.error('err', err);
      });
  };

  const onDownload = type => {
    setDownloadBtn(true);
    const download = downloadQuote(customerId, opportunityId, actionId, type);
    download
      .then(res => {
        if (res?.status === 200) {
          const pdfUrl = res?.data?.data;

          const link = document.createElement('a');
          link.href = pdfUrl;
          link.target = '_blank';
          link.download = 'your-file.pdf';
          document.body.appendChild(link);
          link.click();
          setTimeout(() => {
            link.remove();
          }, 2000);

          toast.current.show({
            severity: 'success',
            summary: 'success',
            detail: res?.data?.data?.message,
          });
          setDownloadBtn(false);
        }
      })
      .catch(error => {
        console.error('error', error);
      });
  };
  const accept = () => {
    return history.push(`/crm/${customerId}/quotes/${opportunityId}`);
  };

  const reject = () => false;

  const calcelConfirm = event => {
    confirmDialog({
      target: event.currentTarget,
      message: 'Do you want to cancel this?',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-primary p-button-sm',
      accept,
      reject,
      header: 'Confirmation',
      pt: { content: { className: 'py-2' } },
    });
  };

  const onConvertToProject = async () => {
    if (
      opportunityDetails?.source_system_id === 2 ||
      opportunityDetails?.source_system_id === 7
    ) {
      const createProjectObject = {
        source_system_id: opportunityDetails?.source_system_id || null,
        source_status_id: 206,
        customer_id: opportunityDetails?.customerId,
        store_id: opportunityDetails?.store_id,
        po_number: `${opportunityDetails?.opportunityNumber}_1`,
        project_number: `${opportunityDetails?.opportunityNumber}_1`,
        date_sold: new Date(),
        status_id: 1,
        project_type_id: 2,
        project_category_id: opportunityDetails?.categoryId || null,
        manage_as_project: 0,
        installation_address: [
          {
            address_id: customerDetails?.address?.address_id || null,
            address1: customerDetails?.address?.address1 || null,
            address2: customerDetails?.address?.address2 || '',
            city: customerDetails?.address?.city || null,
            state: customerDetails?.address?.state || null,
            zipcode: customerDetails?.address?.zipcode || null,
            occupant_type: 'Customer',
          },
        ],
        is_pull_permit_required: 0,
        move_up_on_schedule: false,
        quote_id: quoteDetails?.quoteId || null,
        opportunity_number: opportunityDetails.opportunityNumber,
        quote_number: quoteDetails.quoteNumber,
        opportunity_id: opportunityDetails?.opportunityId || null,
      };
      const response = await axios.post(
        `${CONFIG.API_BASE_URL}/projects`,
        createProjectObject
      );
      if (response && response?.data?.status) {
        const lineItems = quoteDetails?.lineItems?.items || [];
        if (lineItems?.length > 0) {
          lineItems.map(async lineItem => {
            if (lineItem?.itemTypeId == 2) {
              const postObject = {
                item_id: lineItem?.itemId,
                item_quantity: lineItem?.quantity,
                cost_ea: parseFloat(
                  (() => {
                    const totalRevenue = formateNumber(lineItem?.totalRevenue);
                    const quantity = formateNumber(lineItem?.quantity);

                    return formateNumber(totalRevenue / quantity);
                  })()
                ),
                edited_labor_cost_ea: msProjectId
                  ? parseFloat(lineItem?.unitPrice || 0)
                  : null,
                quote_id: quoteDetails?.quoteId,
                discount: parseFloat(lineItem?.discount || 0),
                discount_type: lineItem?.discountType || '',
                tax: parseFloat(lineItem?.tax || 0),
                sell_price: parseFloat(lineItem?.retailEach || 0),
                labor_cost_ea: parseFloat(lineItem?.unitPrice || 0),
                labor_cost_ea_value: parseFloat(lineItem?.totalCost || 0),
                project_item_desc: lineItem?.itemDescription || '',
                retail_each: parseFloat(lineItem?.retailEach || 0),
                total_retail: parseFloat(lineItem?.totalRetail || 0),
                total_discount: parseFloat(lineItem?.totalDiscount || 0),
                total_tax: parseFloat(lineItem?.taxAmount || 0),
                total_revenue: parseFloat(lineItem?.totalRevenue || 0),
                margin: parseFloat(lineItem?.margin || 0),
              };
              const itemDetails = await apiService.get(
                `/system/items/${lineItem?.itemId}`
              );

              createLaborItem(response.data?.project_id, postObject);
            } else {
              const postObject = {
                item_id: lineItem?.itemId,
                item_quantity: lineItem?.quantity,
                cost_ea: parseFloat(
                  formateNumber(
                    formateNumber(lineItem?.totalRevenue) /
                      formateNumber(lineItem?.quantity)
                  )
                ),
                sell_price: parseFloat(lineItem?.retailEach || 0),
                quote_id: quoteDetails?.quoteId,
                discount: parseFloat(lineItem?.discount || 0),
                discount_type: lineItem?.discountType || '',
                tax: parseFloat(lineItem?.tax || 0),
                sell_price: parseFloat(lineItem?.retailEach || 0),
                edited_labor_cost_ea: parseFloat(lineItem?.unitPrice || 0),
                project_item_desc: lineItem?.itemDescription || '',
                retail_each: parseFloat(lineItem?.retailEach || 0),
                total_retail: parseFloat(lineItem?.totalRetail || 0),
                total_discount: parseFloat(lineItem?.totalDiscount || 0),
                total_tax: parseFloat(lineItem?.taxAmount || 0),
                total_revenue: parseFloat(lineItem?.totalRevenue || 0),
                margin: parseFloat(lineItem?.margin || 0),
              };
              const itemDetails = await apiService.get(
                `/system/items/${lineItem?.itemId}`
              );
              if (
                itemDetails?.revenue_price &&
                parseFloat(`${itemDetails?.revenue_price}`) > 0
              ) {
                postObject.cost_ea = itemDetails.revenue_price;
              }

              createMerchandiseItem(response.data?.project_id, postObject);
            }
          });
        }

        history.push(
          `/crm/${opportunityDetails?.customerId}/${opportunityDetails?.opportunityId}/project/view/${response.data?.project_id}`
        );
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: response?.data?.message,
        });
      }
    } else {
      window.open(
        `/${opportunityId}/${actionId}/project/add?clientId=${customerId}`,
        '_blank'
      );
    }
  };

  const projectList = () => {
    gridProjectsData(customerId)
      .then(res => {
        if (res?.status === 200) {
          let projects = res?.data?.[0]?.project;

          if (projects?.length > 0) {
            if (actionId) {
              projects = projects.filter(
                item => item?.project_crm?.quote_id == actionId
              );
            }
          }
        }
      })
      .catch(error => {
        if (error?.response?.status === 404 && history) {
          return history.push('/page-not-found');
        }
        console.error('Error', error);
      });
  };
  useEffect(() => {
    if (mode === 'edit' && (!isQuoteActive || quoteProjectCount > 0))
      return history.push('/page-not-found');
  }, [isQuoteActive, mode, quoteProjectCount]);

  useEffect(() => {
    projectList();
  }, [quoteId]);
  const customerName = customerDetails
    ? `${customerDetails.first_name} ${customerDetails.last_name}`
    : '';

  useEffect(() => {
    manuItems([
      {
        label: 'Pull Line Items',
        icon: 'pi pi-arrow-down',
        command: async () => {
          setLoading(true);
          const { quoteId, ms_project_id } = quoteDetails;

          const data = {
            quote_id: quoteId || null,
            oppotunity_id: opportunityId || null,
            client_id: localStorage.getItem('client_id') || null,
            ms_project_id: ms_project_id || '',
            project_id: quoteDetails?.measureSquareProjectId || null,
          };
          const projectItems = pullProjectItems(
            data,
            toast,
            getQuoteApiById,
            setLoading
          );
        },
        disabled: quoteDetails?.pull_line_items ? true : false,
      },
      {
        label: 'Pull Cut Sheets',
        icon: 'pi pi-arrow-down',
        command: () => {
          setLoading(true);
          const { quoteId, opportunityId, customerId, ms_project_id } =
            quoteDetails;

          const data = {
            quote_id: quoteId || null,
            oppotunity_id: opportunityId || null,
            client_id: localStorage.getItem('client_id') || null,
            ms_project_id: ms_project_id || '',
            project_id: quoteDetails?.measureSquareProjectId || null,
          };

          const projectDocuments = pullProjectDocuments(
            data,
            toast,
            getQuoteApiById,
            setLoading
          );
        },
        disabled: quoteDetails?.pull_documents ? true : false,
      },

      ...(!quoteDetails?.mark_as_final
        ? [
            {
              label: 'Mark as Final',
              icon: 'pi pi-check-circle',
              command: () => {
                setLoading(true);
                const { opportunityId, quoteId } = quoteDetails;
                const final = markAsFinal(
                  { opportunityId, quoteId },
                  toast,
                  getQuoteApiById,
                  setLoading
                );
              },
            },
          ]
        : []),
      ...(quoteDetails?.mark_as_final
        ? [
            {
              label: 'Send to Source',
              icon: 'pi pi-send',
              command: () => {
                const { quoteId, opportunityId, customerId, ms_project_id } =
                  quoteDetails;
                const data = {
                  quote_id: quoteId || null,
                  oppotunity_id: opportunityId || null,
                  client_id: localStorage.getItem('client_id') || null,
                  ms_project_id: ms_project_id || '',
                  project_id: quoteDetails?.measureSquareProjectId || null,
                };

                const sendMSProject = sendProject(
                  data,
                  toast,
                  getQuoteApiById,
                  setLoading
                );
              },
            },
          ]
        : []),
    ]);
  }, [quoteDetails, loading]);

  const previewMenuItems = [
    {
      label: 'Options',
      items: [
        {
          label: 'Summary',
          icon: 'pi pi-tag',
          command: () => onPreview('summary'),
        },
        {
          label: 'Details Internal',
          icon: 'pi pi-tags',
          command: () => onPreview('internal'),
        },
        {
          label: 'Details Customer',
          icon: 'pi pi-tags',
          command: () => onPreview('customer'),
        },
      ],
    },
  ];

  const downloadMenuItems = [
    {
      label: 'Options',
      items: [
        {
          label: 'Summary',
          icon: 'pi pi-tag',
          command: () => onDownload('summary'),
        },
        {
          label: 'Details Internal',
          icon: 'pi pi-tags',
          command: () => onDownload('internal'),
        },
        {
          label: 'Details Customer',
          icon: 'pi pi-tags',
          command: () => onDownload('customer'),
        },
      ],
    },
  ];

  const sendMenuItems = [
    {
      label: 'Options',
      items: [
        {
          label: 'Summary',
          icon: 'pi pi-tag',
          command: () => onSend('summary'),
        },
        {
          label: 'Details',
          icon: 'pi pi-tags',
          command: () => onSend('customer'),
        },
      ],
    },
  ];

  useEffect(() => {
    const items = [...laborItems, ...merchandiseItems];

    setItems(items);
  }, [laborItems, merchandiseItems]);

  return (
    <>
      <ConfirmPopup />
      <div className="w-full crm">
        <Toast ref={toast}></Toast>
        {/* <ConfirmDialog /> */}

        <>
          <div className="grid w-full crm flex">
            <div className="col-12 md:col-12 lg:col-5 p-0 mt-1">
              <PageHeader
                pageTitle={`${_.capitalize(mode)} Quote`}
                breadCrumbArray={quotesBreadcrumb(
                  customerId,
                  opportunityId,
                  opportunityDetails?.opportunityNumber,
                  customerName,
                  quoteId
                )}
              />
            </div>
            <div className="col-12 md:col-12 lg:col-7 p-0 mt-1">
              <div className="mb-2 align-items-end justify-content-end flex">
                <PFButton
                  label={`Cancel`}
                  className="mr-1"
                  severity="primary"
                  outlined={true}
                  onClick={
                    mode !== 'view'
                      ? calcelConfirm
                      : () => window.history.back()
                  }
                />

                {mode === 'view' && !isQuoteLoading && (
                  <>
                    {(isQuoteActive || quoteDetails?.mark_as_final) &&
                      !quoteDetails?.crmProjects && (
                        <PFButton
                          label={`Convert to Project`}
                          severity="primary"
                          outlined={false}
                          onClick={onConvertToProject}
                          className="ml-1"
                        />
                      )}

                    {isQuoteActive && (
                      <>
                        <Menu
                          model={sendMenuItems}
                          popup
                          ref={sendMenuLeft}
                          id="popup_menu_left"
                        />
                        <PFButton
                          label={`Send`}
                          className="ml-2"
                          severity="primary"
                          icon="pi pi-align-left"
                          outlined={false}
                          onClick={event => {
                            customerDetails?.customer_emails?.length
                              ? sendMenuLeft.current.toggle(event)
                              : confirmPopup({
                                  target: event.currentTarget,
                                  message:
                                    'Kindly update the Customer profile with the Email ID to send this Quote.',
                                  icon: 'pi pi-info-circle',
                                  footer: <></>,
                                  pt: {
                                    content: { className: 'py-2' },
                                  },
                                });
                          }}
                          disabled={sendBtn}
                        />
                      </>
                    )}
                    <Menu
                      model={previewMenuItems}
                      popup
                      ref={priviewMenuLeft}
                      id="popup_menu_left"
                    />
                    <PFButton
                      label={`Preview`}
                      className={`${isQuoteActive ? 'ml-2' : 'ml-1'}`}
                      severity="primary"
                      outlined={false}
                      icon="pi pi-align-left"
                      onClick={event => {
                        priviewMenuLeft.current.toggle(event);
                      }}
                    />
                    <Menu
                      model={previewMenuItems}
                      popup
                      ref={priviewMenuLeft}
                      id="popup_menu_left"
                    />
                    <Menu
                      model={downloadMenuItems}
                      popup
                      ref={downloadMenuLeft}
                      id="popup_menu_left"
                    />
                    <PFButton
                      label={`Download`}
                      className="ml-2 mr-1"
                      severity="primary"
                      icon="pi pi-align-left"
                      outlined={false}
                      onClick={event => downloadMenuLeft.current.toggle(event)}
                      disabled={downloadBtn}
                    />
                  </>
                )}

                <>
                  {mode === 'view' ? (
                    <>
                      {isQuoteActive &&
                        !isQuoteLoading &&
                        quoteProjectCount === 0 && (
                          <PFButton
                            label={`Edit`}
                            className="ml-1"
                            outlined={false}
                            onClick={() =>
                              history.push(
                                `/crm/${customerId}/${opportunityId}/quote/edit/${actionId}`
                              )
                            }
                          />
                        )}
                    </>
                  ) : (
                    <PFButton
                      label={`Save`}
                      className="ml-1"
                      disabled={items?.length > 0 ? false : true}
                      onClick={() => storeQuote(initItems)}
                    />
                  )}
                </>
              </div>
            </div>
          </div>

          <div className="grid w-full crm flex py-2">
            <div className="col-12 md:col-12 lg:col-8 pl-0">
              <ClientInfomation
                customerId={customerId}
                globalCustomerDetails={setCustomerDetails}
              />
            </div>
            <div className="col-12 md:col-12 lg:col-4 pl-0 pr-1">
              <OpportunityInfomation
                opportunityId={opportunityId}
                customerId={customerId}
                globalOpportunityDetails={setOpportunityDetails}
              />
            </div>

            <div className="col-12 md:col-12 lg:col-10 pl-0">
              <Card style={{ boxShadow: 'none' }}>
                <QuoteTabs
                  setLaborItems={setLaborItems}
                  setMerchandiseItems={setMerchandiseItems}
                  laborItems={laborItems}
                  merchandiseItems={merchandiseItems}
                  setFormPayload={setFormPayload}
                  aggregate={aggregate}
                  formPayload={formPayload}
                  customerId={customerId}
                  opportunityId={opportunityId}
                  quoteId={actionId}
                  communicationType={COMMUNICATION_TYPE.quote}
                  communicationId={actionId}
                  mode={mode}
                  primaryEmailAddress={
                    opportunityDetails?.primaryEmail ||
                    customerDetails?.customer_emails?.find(
                      emailDetails => emailDetails.is_primary
                    )?.email
                  }
                  mainQuoteId={quoteDetails?.main_quote_id}
                  isVersionActive={isQuoteActive}
                  isMeasureSquareEnabled={isMeasureSquareEnabled(
                    opportunityDetails?.from_external_source
                  )}
                  retailerPercentage={retailerPercentage}
                  isForm={isForm}
                  setIsForm={setIsForm}
                  retailRate={retailRate}
                />
              </Card>
            </div>
            <div className="col-12 md:col-12 lg:col-2  pl-0 pr-1">
              <Card style={{ boxShadow: 'none' }}>
                <div className="mt-2">
                  <ItemResult
                    aggregate={aggregate}
                    setExpiryDate={setExpiryDate}
                    setStatusId={setStatusId}
                    expiryDate={expiryDate}
                    statusId={statusId}
                    quoteId={quoteId}
                    quoteCreateAt={quoteCreateAt}
                    retailerPercentage={retailerPercentage}
                    setRetailerPercentage={setRetailerPercentage}
                    mVendorId={mVendorId}
                    setMVendorId={setMVendorId}
                    isForm={isForm}
                    setRetailRate={setRetailRate}
                  />
                </div>
              </Card>
            </div>
          </div>
        </>
      </div>
    </>
  );
};

export default QuoteForm;
