import React, { useEffect, useState, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { AutoComplete } from 'primereact/autocomplete';
import { Column } from 'primereact/column';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Skeleton } from 'primereact/skeleton';

import { showFirst30Characters } from '../../../../utils/Helpers';
import {
  getLaborItems,
  getMerchandiseItems,
} from '../../ProjectItemsNewUI/ProjectItemsService';
import { ITEM_TYPES } from '../../../../constants';
import {
  IAggregatedProjectData,
  ILinkedItem,
  IProjectItem,
  IViewLinkedItemsProps,
  Item,
  IColumnData,
  IChildItemData,
  ITooltipData,
} from '../Interface/document.interface';

const ViewLinkedItems: React.FC<IViewLinkedItemsProps> = ({
  projectId,
  customerId,
  type,
  linkedProjectItems,
}) => {
  const [selectedRow, setSelectedRow] = useState<ILinkedItem | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filteredData, setFilteredData] = useState<
    IAggregatedProjectData | undefined
  >(undefined);
  const [selectedJob, setSelectedJob] = useState<
    { label: string; value: number } | undefined
  >();
  const [items, setItems] = useState<{ label: string; value: number }[]>([]);
  const [linkedItemsList, setLinkedItemsList] = useState<ILinkedItem[]>([]);
  const op = useRef<OverlayPanel>(null);

  const handleMouseEnter = (e: React.MouseEvent, rowData: ITooltipData) => {
    op?.current?.toggle(e);
    setSelectedRow({
      item_number: rowData?.item?.item_number,
      item_description: rowData?.item?.item_desc,
      quantity: rowData?.item_quantity,
    });
  };

  const handleMouseLeave = () => {
    op.current?.hide();
  };

  const getLinkedItems = async (projectId: number, type: number | string) => {
    setIsLoading(true);
    try {
      if (type === ITEM_TYPES?.product) {
        const res = await getMerchandiseItems(projectId);
        setLinkedItemsList(res?.data?.status ? res.data.data : []);
      } else if (type === ITEM_TYPES?.labor) {
        const res = await getLaborItems(projectId);
        setLinkedItemsList(res?.data?.data || []);
      }
    } catch (err) {
      console.error(`Error fetching type ${type} items: `, err);
      setLinkedItemsList([]);
    } finally {
      setIsLoading(false);
    }
  };

  const search = (event: { query: string }) => {
    if (filteredData?.child_projects) {
      const filteredItems = filteredData?.child_projects
        .filter((project: IProjectItem) =>
          project?.project_number
            ?.toLowerCase()
            .includes(event?.query?.toLowerCase())
        )
        .map((item: IProjectItem) => ({
          label: item?.project_number,
          value: item?.project_id,
        }));
      setItems(filteredItems);
    }
  };

  const handleJobSelection = (
    project: { value: number },
    type: string | number
  ) => {
    getLinkedItems(project?.value, type);
  };

  const filterDataForLinkedItemsDropdown = (
    filteredData: IAggregatedProjectData | undefined
  ) => {
    if (filteredData && filteredData?.child_projects?.length > 0) {
      const data = filteredData?.child_projects?.[0];
      setSelectedJob({
        label: data?.project_number,
        value: data?.project_id,
      });
      getLinkedItems(data?.project_id, type);
    }
  };

  useEffect(() => {
    filterDataForLinkedItemsDropdown(filteredData);
  }, [type]);

  const getItemsLinkedList = () => {
    if (customerId && linkedProjectItems?.status) {
      const projectsData = linkedProjectItems?.data?.data;

      const groupedProjects = Array.isArray(projectsData)
        ? projectsData.reduce((acc: IAggregatedProjectData[], item: Item) => {
            const parentProjectId = item?.data?.project_id;
            let existingParent = acc.find(
              (p: { parent_project_id: number }) =>
                p.parent_project_id === parentProjectId
            );
            const linkedChildData = projectsData.find(
              child =>
                child?.data?.child_linkage?.linked_project_id == projectId
            );

            if (linkedChildData?.data?.child_linkage?.project_id) {
              getLinkedItems(
                linkedChildData?.data?.child_linkage?.project_id,
                type
              );
            }

            if (!existingParent) {
              existingParent = {
                parent_project_id: parentProjectId,
                child_projects: [],
              };
              acc.push(existingParent);
            }

            if (item?.children) {
              item.children.forEach((child: IChildItemData) => {
                existingParent.child_projects.push({
                  project_id: child?.data?.project_id,
                  project_number: child?.data?.project_number,
                  project_type: child?.data?.project_type?.project_type,
                });
              });
            }

            return acc;
          }, [])
        : [];
      const project = groupedProjects?.find(
        (item: { parent_project_id: number }) =>
          Number(item.parent_project_id) === Number(projectId)
      );
      setFilteredData(project);
      filterDataForLinkedItemsDropdown(project);
    }
  };

  useEffect(() => {
    getItemsLinkedList();
  }, [customerId]);

  const columnData: IColumnData[] = [
    { field: 'item_number', header: 'Item#' },
    {
      field: 'item_description',
      header: 'Item Description',
      style: { minWidth: '150px', maxWidth: '150px' },
    },
    { field: 'quantity', header: 'Quantity' },
  ];

  return (
    <>
      <label className="text-base mb-2 mt-4">{`Linked ${
        type === ITEM_TYPES?.labor ? 'Labor' : 'Product'
      } Items :`}</label>
      {filteredData &&
        filteredData?.child_projects?.length > 1 &&
        (isLoading ? (
          <Skeleton height="3rem" width="12rem" className="mb-2"></Skeleton>
        ) : (
          <div className="flex mb-3">
            <AutoComplete
              value={selectedJob}
              suggestions={items}
              completeMethod={search}
              onChange={e => setSelectedJob(e.value)}
              onSelect={e => handleJobSelection(e.value, type)}
              dropdown
              field="label"
            />
          </div>
        ))}
      <div className="shadow-2 mb-2 max-h-17rem">
        <DataTable
          scrollable
          scrollHeight="flex"
          value={linkedItemsList}
          emptyMessage="No product items available"
        >
          {columnData.map((col, index) => (
            <Column
              key={index}
              field={col.field}
              header={col.header}
              style={col.style}
              body={rowData => (
                <div
                  className="white-space-nowrap overflow-hidden text-overflow-ellipsis cursor-pointer"
                  onMouseEnter={e => handleMouseEnter(e, rowData)}
                  onMouseLeave={handleMouseLeave}
                >
                  {(() => {
                    if (col.field === 'item_number') {
                      return showFirst30Characters(
                        rowData?.item?.item_number ?? 'N/A'
                      );
                    }
                    if (col.field === 'item_description') {
                      return showFirst30Characters(
                        rowData?.item?.item_desc ?? 'N/A'
                      );
                    }
                    if (col.field === 'quantity') {
                      return showFirst30Characters(
                        rowData?.item_quantity ?? '0'
                      );
                    }
                    return showFirst30Characters(rowData?.[col.field] ?? 'N/A');
                  })()}
                </div>
              )}
            />
          ))}
        </DataTable>
        <OverlayPanel
          ref={op}
          appendTo={null}
          className="min-w-300 max-w-25rem"
        >
          {selectedRow && (
            <div className="rounded-lg">
              <div className="flex justify-content-start">
                <div className="w-12rem font-bold">Item</div>
                <div className="mx-2">:</div>
                <div className="w-full word-break">
                  {selectedRow?.item_number}
                </div>
              </div>
              <div className="flex justify-content-start">
                <div className="w-12rem font-bold">Description</div>
                <div className="mx-2">:</div>
                <div className="w-full word-break">
                  {selectedRow?.item_description}
                </div>
              </div>
              <div className="flex justify-content-start">
                <div className="w-12rem font-bold">Quantity</div>
                <div className="mx-2">:</div>
                <div className="w-full word-break">{selectedRow?.quantity}</div>
              </div>
            </div>
          )}
        </OverlayPanel>
      </div>
    </>
  );
};

export default ViewLinkedItems;
