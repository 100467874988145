import '../css/index.css';
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  TimelineViews,
  ScheduleComponent,
  ViewsDirective,
  ViewDirective,
  ResourcesDirective,
  ResourceDirective,
  Inject,
  DragAndDrop,
  Day,
  Week,
  Month,
  TimelineMonth,
  TimelineYear,
  Resize,
} from '@syncfusion/ej2-react-schedule';
import { registerLicense } from '@syncfusion/ej2-base';
import moment from 'moment';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Grid,
  Tooltip,
  DialogContentText,
  TextField,
  Typography,
  Modal,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Calendar } from 'primereact/calendar';
import { Toast } from 'primereact/toast';
import { ConfirmDialog } from 'primereact/confirmdialog';

import GenericDialog from '../../shared/Dialog/GenericDialog';
import { EVENT_COLORS, EVENT_TYPES } from '../constants/constants';
import {
  checkPermission,
  ellipsisString,
  getPercentageValue,
  checkUrlString,
} from '../../../utils/Helpers';
import permissions from '../../../config/permissions';
import {
  PROJECT_REPORT,
  scheduleInfoUserCustomAction,
  scheduleInfoUserFillAction,
  scheduleInfoUserSkipAction,
  scheduleInfoUserWipeAction,
  CLIENT_DETAILS,
} from '../../../constants';
import { setInstallerViewCalendar } from '../../../redux/slices/installer-view-calendar.slice';
import GenericConfirmationDialog from '../../shared/GenericConfirmationDialog/GenericConfirmationDialog';
import { addNotes } from '../../ProjectManagement/Notes/Notes.service';
import PFInputNumber from '../../shared/PFPrime/PFInputNumber';
import { REACT_APP_SYNCFUSION_LICENCE_KEY } from '../../../constants/envConstants';
import { useAlerts } from '../../shared/Alerts/alertsService';
import { setNewSchedulerComponent } from '../../../redux/slices/new-scheduler.slice';
import { eventColorGenerator } from '../helpers/color-codes';
import JobDistanceOverlay from '../../Scheduler/JobDistanceOverlay';
import {
  getCalendarData,
  getCalendarResourceData,
  updateInstallerSchedule,
} from '../services/calendar';
import {
  getDefaultView,
  getPeriod,
  selectedRapidSlotSyncFusion,
  getCalendarDefaultView,
} from '../helpers/scheduler-calendar.helper';
import { useStyles } from '../css/CalendarContainer.styles';
import { mapProjectTypeToColor } from '../helpers/helpers';
import PFBlockUI from '../../shared/PFPrime/PFBlockUI';
import { validateReschedule } from '../../../utils/reschedule.helper';

import ProjectScheduleCard from './ProjectScheduleCard';
import ResourceTemplate from './ResourceTemplate';
import InstallerViewBlockCalendar from './InstallerViewBlockCalendar';
import ScheduleDropDialog from './ScheduleDropDialog';
import ProjectEditDialog from './ProjectEditDialog';

registerLicense(`${REACT_APP_SYNCFUSION_LICENCE_KEY}`);
const DEFAULT_DISABLED_TIME_SCALE = { enable: false };
const DEFAULT_WORK_DAYS = [1, 2, 3, 4, 5];
const ALL_DAYS = [0, 1, 2, 3, 4, 5, 6];
const NEW_EVENT_BUTTON = {
  id: 'newEventScheduler',
  align: 'Left',
  prefixIcon: 'e-plus',
  text: 'New Event',
  cssClass: 'e-active-view newEventScheduler',
};
const SEPARATOR_ICON = {
  id: 'separatorScheduler',
  align: 'Left',
  type: 'Separator',
  cssClass: 'e-schedule-seperator',
};
const REFRESH_ICON = {
  id: 'refreshIconScheduler',
  align: 'Right',
  prefixIcon: '',
  text: '',
  tooltipText: 'Refresh',
  cssClass:
    'material-icons-sharp md-28 md-refresh hover:text-xl px-2 refreshIconScheduler',
};
const FULLSCREEN_ICON = {
  id: 'fullscreenIconScheduler',
  align: 'Right',
  prefixIcon: '',
  text: '',
  tooltipText: 'Fullscreen',
  cssClass:
    'material-icons-sharp md-28 md-fullscreen hover:text-xl px-2 fullscreenIconScheduler',
};
const SchedulerCalendar = ({
  calendarRef,
  targetID,
  filter,
  data: parentData,
  loader,
  handleSetData,
  handleSetFilter,
  handleSetLoader,
  view,
  calendarData,
  setCalendarData,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isWeekendsVisible } = useSelector(state => state.newScheduler);
  const [defaultView, setDefaultView] = useState(
    view === PROJECT_REPORT.FULL_CALENDAR
      ? getCalendarDefaultView()
      : getDefaultView() || 'TimelineDay'
  );

  const [isNavigate, setIsNavigate] = useState(false);

  const [infoData, setInfoData] = useState();
  const [dropTimeOffRequestIds, setDropTimeOffRequestIds] = useState();
  const [confirmDialog, setConfirmDialog] = useState({
    header: '',
    title: '',
    subtitle: '',
  });
  const [dropModalValue, setDropModalValue] = useState(false);
  const [tableRowDropData, setTableRowDropData] = useState('');
  const [dropData, setDropData] = useState('');
  const [statusId, setStatusId] = useState(7);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isStartOrEndDateInvalid, setIsStartOrEndDateInvalid] = useState(false);
  const [schedulePercentage, setSchedulePercentage] = useState(
    parentData?.schedulePercentage
  );
  const [technicianAction, setTechnicianAction] = useState(
    parentData?.technicianAction
  );
  const toast = useRef(null);
  // Reschedule
  const [isOpen, setIsOpen] = useState(false);
  const [infoValue, setInfoValue] = useState('');
  const { setAlert } = useAlerts();
  const { projectStatusType } = useSelector(state => state.projectStatusType);
  const [isUpdateSchedule, setIsUpdateSchedule] = useState(false);
  const [refreshButton, setRefreshButton] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const [startDateStr, setStartDateStr] = useState('');

  const [endDateStr, setEndDateStr] = useState('');

  const [dialogSettings, setDialogSettings] = useState({
    title: 'Reschedule Confirmation',

    button1Text: 'Skip',

    button2Text: 'Continue',

    showButton1: true,

    showButton2: true,
  });
  const currentPath = window?.location?.href;
  const isScheduler = checkUrlString(currentPath, '/scheduler-beta');
  const jobDistancePermission = CLIENT_DETAILS?.is_capture_job_distance_enabled;

  const NotesFormikSchema = Yup.object().shape({
    note_text: Yup.string().trim(),
  });

  const handleSetCalendarData = params => {
    if (params && typeof params === 'object' && !Array.isArray(params)) {
      setCalendarData({
        ...calendarData,
        ...params,
      });
    }
  };
  const splitAndAddEvents = (StartTime, EndTime, eventData, eventDataLocal) => {
    if (StartTime && EndTime) {
      StartTime = new Date(StartTime);
      EndTime = new Date(EndTime);
      const isMultiDay =
        new Date(StartTime).toDateString() !== new Date(EndTime).toDateString();
      if (!isMultiDay || calendarRef?.current?.currentView /*  !== 'Day' */)
        eventDataLocal.push(eventData);
      else {
        let currentDate = new Date(StartTime);
        // Start day with specific start time until end of the start day
        eventDataLocal.push({
          ...eventData,
          StartTime: StartTime,
          EndTime: new Date(currentDate.setHours(23, 59, 59, 999)), // End of start day
          isOverlapping: true,
          actualStartTime: StartTime,
          actualEndTime: EndTime,
        });
        // Loop for full middle days
        currentDate = new Date(StartTime);
        currentDate.setDate(currentDate.getDate() + 1);
        currentDate.setHours(0, 0, 0, 0);
        while (currentDate.toDateString() !== EndTime.toDateString()) {
          const nextDate = new Date(currentDate);
          nextDate.setHours(23, 59, 59, 999);
          eventDataLocal.push({
            ...eventData,
            StartTime: new Date(currentDate),
            EndTime: new Date(nextDate),
            isOverlapping: true,
            actualStartTime: StartTime,
            actualEndTime: EndTime,
          });
          currentDate.setDate(currentDate.getDate() + 1);
        }

        // End day with specific end time
        eventDataLocal.push({
          ...eventData,
          StartTime: new Date(currentDate),
          EndTime: EndTime,
          isOverlapping: true,
          actualStartTime: StartTime,
          actualEndTime: EndTime,
        });
      }
    } else {
      eventDataLocal.push(eventData);
    }
  };
  const callGetCalendarDataApi = async (
    doLoadResource,
    localFilters = {},
    isHideShowBlockUI = false
  ) => {
    let tempCalendarData = {};

    try {
      const params = {
        ...calendarData.calendarFilter,
        ...filter.calendarFilter,
        date: calendarRef?.current?.selectedDate
          ? calendarRef.current.selectedDate
          : moment().format(),
        period: calendarRef?.current?.currentView
          ? getPeriod(calendarRef.current.currentView)
          : getPeriod(defaultView),
        ...(localFilters || {}),
      };
      if (!isHideShowBlockUI) {
        doLoadResource &&
          handleSetCalendarData({
            calendarBlock: true,
          });
      }

      if (view !== PROJECT_REPORT.FULL_CALENDAR && loader?.calendarLoader) {
        const resourceApiResponse = await getCalendarResourceData(params);
        if (
          Array.isArray(resourceApiResponse?.data) &&
          resourceApiResponse.data.length
        ) {
          tempCalendarData.resourceData = resourceApiResponse.data;
          const installerIds = resourceApiResponse.data
            .slice(0, 20)
            .map(({ installerUserId }) => installerUserId);
          params.installerIds = installerIds.join(',');
        } else {
          tempCalendarData.resourceData = [];
        }
      }
      const apiResponse = await getCalendarData(params);
      if (
        Array.isArray(apiResponse?.data?.items) &&
        apiResponse?.data?.items.length
      ) {
        const timeoffApprovedEventsLocal = {};
        const timeoffTentativeEventsLocal = {};
        const blockedEventsLocal = {};
        const eventDataLocal = [];
        let index = 1;
        for (const cal of apiResponse.data.items) {
          let timeoffApprovedEventsAvailability = {};
          let timeoffTentativeEvents = {};
          let blockedEvents = {};
          const key = `${cal.time_off_start_date}__${cal.time_off_end_date}__${cal?.installer_id}`;
          if (
            cal?.time_off_start_date &&
            cal?.time_off_end_date &&
            cal?.is_blocked_time !== 1
          ) {
            const time_off_start_date = cal.time_off_start_date;
            const time_off_end_date = cal.time_off_end_date;
            const unformattedEndDate = moment(time_off_start_date);
            const endDateHour = moment(time_off_end_date).get('hour');
            const endDateMinute = moment(time_off_end_date).get('minute');
            const endDate = unformattedEndDate.set({
              hour: endDateHour,
              minute: endDateMinute,
            });
            const startDate = moment(time_off_start_date);
            const durationInHour = moment.duration(endDate.diff(startDate));
            const splitHours = durationInHour.asHours().toString().split('.');
            const unformattedHours = splitHours[0];
            let minute = '00';

            if (splitHours.length > 1) {
              minute = Math.ceil(parseFloat('0.' + splitHours[1]) * 60);
            }
            const duration = unformattedHours
              .padStart(2, '0')
              .concat(':', minute);
            const availabilityUpdate = {
              title: cal?.time_off_note || '',
              resourceId: cal?.installer_id,
              border: 'none',
              installer_id: cal?.installer_id,
              project_id: cal?.project_id,
              start: cal?.time_off_start_date
                ? moment(cal?.time_off_start_date).format()
                : '',
              end: cal?.time_off_end_date
                ? moment(cal?.time_off_end_date).format()
                : '',
              allDay: cal?.full_day == 1 ? true : false,
              eventType: 'timeoff',
              isRecurring: cal?.is_recurring,
              rrule: {
                freq: 'weekly',
                interval: cal?.is_recurring_every_other_week,
                byweekday: [
                  ...(cal?.sun ? ['su'] : []),
                  ...(cal?.mon ? ['mo'] : []),
                  ...(cal?.tue ? ['tu'] : []),
                  ...(cal?.wed ? ['we'] : []),
                  ...(cal?.thus ? ['th'] : []),
                  ...(cal?.fri ? ['fr'] : []),
                  ...(cal?.sat ? ['sa'] : []),
                ],
                until: moment(cal?.time_off_end_date).format(),
                dtstart: moment(cal?.time_off_start_date).format(),
                byDay: [
                  ...(cal?.sun ? ['SU'] : []),
                  ...(cal?.mon ? ['MO'] : []),
                  ...(cal?.tue ? ['TU'] : []),
                  ...(cal?.wed ? ['WE'] : []),
                  ...(cal?.thus ? ['TH'] : []),
                  ...(cal?.fri ? ['FR'] : []),
                  ...(cal?.sat ? ['SA'] : []),
                ],
              },
              overlap: true,
              isDisabled: true,
              duration,
              timeOffRequestId: cal?.user_time_off_request_id,
            };

            if (cal?.status_id === 88 && !timeoffApprovedEventsLocal[key]) {
              const timeoffApprovedEventsColor =
                EVENT_COLORS[Math.floor(Math.random() * EVENT_COLORS.length)];
              timeoffApprovedEventsAvailability = {
                ...availabilityUpdate,
                backgroundColor: 'rgb(161, 161, 161, 0.4)',
                textColor: timeoffApprovedEventsColor.textColor,
              };
              timeoffApprovedEventsLocal[key] =
                timeoffApprovedEventsAvailability;
            }
            if (cal?.status_id === 86 && !timeoffTentativeEventsLocal[key]) {
              const timeoffApprovedEventsColor =
                EVENT_COLORS[Math.floor(Math.random() * EVENT_COLORS.length)];
              timeoffTentativeEvents = {
                ...availabilityUpdate,
                background:
                  'repeating-linear-gradient(135deg, #696969 10px,#696969 12px,transparent 12px,transparent 20px)',
                textColor: timeoffApprovedEventsColor.textColor,
                // classNames: [classes.tentativeTimeOff],
              };
              timeoffTentativeEventsLocal[key] = timeoffTentativeEvents;
            }
          }
          if (
            cal?.time_off_start_date &&
            cal?.time_off_end_date &&
            cal?.is_blocked_time === 1 &&
            !blockedEventsLocal[key]
          ) {
            const blockedEventsColor = EVENT_COLORS[4];
            blockedEvents = {
              title: cal?.time_off_note || '',
              resourceId: cal?.installer_id,
              backgroundColor: blockedEventsColor?.bgColor,
              textColor: blockedEventsColor?.textColor,
              start: cal?.time_off_start_date
                ? moment(cal?.time_off_start_date).format()
                : '',
              end: cal?.time_off_end_date
                ? moment(cal?.time_off_end_date).format()
                : '',
              allDay: cal?.full_day === 1 ? true : false,
              eventType: EVENT_TYPES.BLOCK,
              overlap: true,
              isDisabled: true,
              installerId: cal?.installer_id,
              timeOffRequestId: cal?.user_time_off_request_id,
              editable: false,
              durationEditable: false,
            };
            blockedEventsLocal[key] = blockedEvents;
          }
          let eventDataLocalEventColor = {
            bgColor: EVENT_COLORS[1]?.bgColor,
            textColor: EVENT_COLORS[1]?.textColor,
          };
          if (
            view !== PROJECT_REPORT.FULL_CALENDAR &&
            cal.project_type &&
            (cal?.config_color_code || cal?.project_color_code)
          ) {
            const mappedColor = mapProjectTypeToColor(
              cal?.config_color_code || cal?.project_color_code,
              cal?.project_type,
              cal?.project_status,
              cal?.color_code
            );

            eventDataLocalEventColor = {
              bgColor: mappedColor?.bgColor,
              textColor: mappedColor?.textColor,
            };
          } else if (
            view === PROJECT_REPORT.FULL_CALENDAR &&
            cal?.project_type &&
            cal?.installer_id
          ) {
            const generatedColor = eventColorGenerator(cal?.installer_id);

            eventDataLocalEventColor = {
              bgColor: generatedColor,
              textColor: '#21272A',
            };
          }

          const isTentativeTimeOff =
            timeoffTentativeEvents?.eventType === 'timeoff';
          const isApprovedEventsAvailabilityTimeOff =
            timeoffApprovedEventsAvailability?.eventType === 'timeoff';
          const isBlockedEvents = blockedEvents?.eventType === 'blockedTime';

          let StartTime = cal?.schedule_date ? cal?.schedule_date : null;
          let EndTime = cal?.schedule_end_date ? cal?.schedule_end_date : null;
          let RecurrenceRule = '';
          const eventLocalData = {
            Id: cal?.project_id,
            recordId: index,
            EndTime,
            StartTime,
            ResourceId: cal?.installer_id,
            IsAllDay: cal?.full_day === 1 ? true : false,
            RecurrenceRule,
            eventData: {
              installer_name: cal?.installer_name,
              id: cal?.project_id,
              title: cal?.project_desc || '',
              resourceId: cal?.installer_id,
              backgroundColor: eventDataLocalEventColor?.bgColor,
              textColor: eventDataLocalEventColor?.textColor,
              border: 'none',
              installer_id: cal?.installer_id,
              project_id: cal?.project_id,
              start: cal?.schedule_date,
              end: cal?.schedule_end_date,
              project_desc: cal?.project_desc,
              store_number: cal?.store_number,
              project_type: cal?.project_type,
              source_system_id: cal?.source_system_id,
              category: cal?.category,
              project_status: cal?.project_status,
              project_number: cal?.project_number,
              client_name: cal?.client_name,
              Time:
                moment(cal?.schedule_date).format('hh:mm A') +
                ' - ' +
                moment(cal?.schedule_end_date).format('hh:mm A'),
              mobile_phone: cal?.mobile_phone,
              address: cal?.address,
              timeoff_status: cal?.status_id,
              time_off_start_date: cal?.time_off_start_date,
              time_off_end_date: cal?.time_off_end_date,
              total_sale_amount: cal?.total_sale_amount,
              total_revenue: cal?.total_revenue,
              confirmation_status: cal?.confirmation_status,
              is_customer_requested: cal?.is_customer_requested,
              overlap: true,
              background:
                'repeating-linear-gradient(135deg, #696969 10px,#696969 12px,transparent 12px,transparent 20px)',

              schedule_date: cal?.schedule_date,
              schedule_end_date: cal?.schedule_end_date,
              installer_profile_image_url: cal?.installer_profile_image_url,
              basic_labor_sum: cal?.basic_labor_sum,
            },
          };

          if (StartTime && EndTime) {
            splitAndAddEvents(
              StartTime,
              EndTime,
              eventLocalData,
              eventDataLocal
            );
            index++;
          }
          if (isTentativeTimeOff) {
            StartTime = timeoffTentativeEvents?.start
              ? timeoffTentativeEvents?.start
              : null;
            EndTime = timeoffTentativeEvents?.isRecurring
              ? `${timeoffTentativeEvents?.start?.split('T')[0]}T${timeoffTentativeEvents?.end?.split('T')[1]}`
              : timeoffTentativeEvents?.end;
            RecurrenceRule = timeoffTentativeEvents?.isRecurring
              ? `FREQ=WEEKLY;BYDAY=${timeoffTentativeEvents?.rrule?.byDay?.join(',')};INTERVAL=${timeoffTentativeEvents?.rrule?.interval};UNTIL=${new Date(
                  `${timeoffTentativeEvents?.end}`
                )
                  ?.toISOString()
                  ?.replace(/[-:]/g, '')
                  ?.replace(/\.\d{3}Z$/, 'Z')}`
              : '';
            splitAndAddEvents(
              StartTime,
              EndTime,
              {
                ...eventLocalData,
                recordId: index,
                StartTime,
                EndTime,
                isTentativeTimeOff,
                eventData: timeoffTentativeEvents,
                RecurrenceRule,
              },
              eventDataLocal
            );
            index++;
          }
          if (isApprovedEventsAvailabilityTimeOff) {
            StartTime = timeoffApprovedEventsAvailability?.start
              ? timeoffApprovedEventsAvailability?.start
              : null;

            EndTime = timeoffApprovedEventsAvailability?.isRecurring
              ? `${timeoffApprovedEventsAvailability?.start?.split('T')[0]}T${timeoffApprovedEventsAvailability?.end?.split('T')[1]}`
              : timeoffApprovedEventsAvailability?.end;
            RecurrenceRule = timeoffApprovedEventsAvailability?.isRecurring
              ? `FREQ=WEEKLY;BYDAY=${timeoffApprovedEventsAvailability?.rrule?.byDay?.join(',')};UNTIL=${new Date(
                  `${timeoffApprovedEventsAvailability?.end}`
                )
                  ?.toISOString()
                  ?.replace(/[-:]/g, '')
                  ?.replace(/\.\d{3}Z$/, 'Z')}`
              : '';

            splitAndAddEvents(
              StartTime,
              EndTime,
              {
                ...eventLocalData,
                recordId: index,
                StartTime,
                EndTime,
                RecurrenceRule,
                isApprovedEventsAvailabilityTimeOff,
                eventData: timeoffApprovedEventsAvailability,
              },
              eventDataLocal
            );
            index++;
          }
          if (isBlockedEvents) {
            StartTime = blockedEvents?.start ? blockedEvents?.start : null;
            EndTime = blockedEvents?.end ? blockedEvents?.end : null;
            splitAndAddEvents(
              StartTime,
              EndTime,
              {
                ...eventLocalData,
                recordId: index,
                StartTime,
                EndTime,
                isBlockedEvents,
                eventData: blockedEvents,
              },
              eventDataLocal
            );
            index++;
          }
        }
        tempCalendarData.eventData = eventDataLocal;
      } else {
        tempCalendarData.eventData = [];
      }
    } catch (error) {
      console.log(error, '...error callGetCalendarDataApi');
    } finally {
      doLoadResource &&
        handleSetCalendarData({
          ...tempCalendarData,
          calendarBlock: false,
        });
    }
  };

  useEffect(() => {
    if (filter?.calendarFilter) {
      if (
        view === PROJECT_REPORT.FULL_CALENDAR &&
        !filter?.calendarFilter?.installerIds
      ) {
        handleSetCalendarData({
          eventData: [],
          resourceData: [],
        });
        handleSetLoader({ calendarLoader: false });
      } else {
        callGetCalendarDataApi(true).then(() => {
          handleSetLoader({ calendarLoader: false });
        });
      }
    }
  }, [filter?.calendarFilter, view]);

  useEffect(async () => {
    if (refreshButton) {
      await callGetCalendarDataApi(true).then(() => {
        handleSetLoader({ calendarLoader: false });
        setRefreshButton(false);
      });
    }
  }, [refreshButton]);

  const renderBlockTimeTooltip = eventInfo => {
    return <div>{eventInfo?.title}</div>;
  };
  const renderTooltipContent = (eventInfo, time) => {
    return (
      <>
        <table className="w-full vertical-align-top text-xs">
          {view == PROJECT_REPORT.FULL_CALENDAR && eventInfo?.installer_name ? (
            <tr>
              <td className="minWidth-130">
                <strong>Technician Name</strong>
              </td>
              <td>{eventInfo?.installer_name}</td>
            </tr>
          ) : null}
          {eventInfo?.basic_labor_sum ? (
            <tr>
              <td className="minWidth-130">
                <strong>Qty</strong>
              </td>
              <td>{eventInfo?.basic_labor_sum}</td>
            </tr>
          ) : null}
          {time ? (
            <tr>
              <td className="minWidth-130">
                <strong>Time</strong>
              </td>
              <td>{time}</td>
            </tr>
          ) : null}
          {eventInfo?.store_number ? (
            <tr>
              <td className="minWidth-130">
                <strong>Store #</strong>
              </td>
              <td>{eventInfo?.store_number}</td>
            </tr>
          ) : null}
          {eventInfo?.client_name ? (
            <tr>
              <td className="minWidth-130">
                <strong>Client Name</strong>
              </td>
              <td>{eventInfo?.client_name}</td>
            </tr>
          ) : null}
          {eventInfo?.category ? (
            <tr>
              <td className="minWidth-130">
                <strong>Category</strong>
              </td>
              <td className="word_break">{eventInfo?.category}</td>
            </tr>
          ) : null}
          {eventInfo?.address ? (
            <tr>
              <td className="minWidth-130 word_break">
                <strong>Address</strong>
              </td>
              <td>{eventInfo?.address}</td>
            </tr>
          ) : null}
          {eventInfo?.total_sale_amount &&
            checkPermission(
              permissions?.projectItems?.viewProjectLevelCostField
            ) && (
              <tr>
                <td className="minWidth-130">
                  <strong>Total Sale $</strong>
                </td>
                <td>{`$ ${eventInfo?.total_sale_amount}`}</td>
              </tr>
            )}
          {eventInfo?.total_revenue &&
            checkPermission(
              permissions?.projectItems?.viewProjectLevelCostField
            ) && (
              <tr>
                <td className="minWidth-130">
                  <strong>Total Revenue $</strong>
                </td>
                <td>{`$ ${eventInfo?.total_revenue}`} </td>
              </tr>
            )}
          {eventInfo?.project_desc ? (
            <tr>
              <td className="minWidth-130">
                <strong>Description</strong>
              </td>
              <td className="word_break">
                {ellipsisString(eventInfo?.project_desc, 45)}
              </td>
            </tr>
          ) : null}
          {eventInfo?.project_type ? (
            <tr>
              <td className="minWidth-130">
                <strong>Type</strong>
              </td>
              <td>{eventInfo?.project_type}</td>
            </tr>
          ) : null}
          {eventInfo?.project_status ? (
            <tr>
              <td className="minWidth-130">
                <strong>Status</strong>
              </td>
              <td>{eventInfo?.project_status}</td>
            </tr>
          ) : null}
          {eventInfo?.project_number ? (
            <tr>
              <td className="minWidth-130">
                <strong>Project No</strong>
              </td>
              <td>{eventInfo?.project_number}</td>
            </tr>
          ) : null}
          {eventInfo?.mobile_phone ? (
            <tr>
              <td className="minWidth-130">
                <strong>Phone</strong>
              </td>
              <td>{eventInfo?.mobile_phone}</td>
            </tr>
          ) : null}
          {eventInfo?.confirmation_status ? (
            <tr>
              <td className="minWidth-130">
                <strong>Confirmation Status</strong>
              </td>
              <td>{eventInfo?.confirmation_status}</td>
            </tr>
          ) : null}
        </table>
      </>
    );
  };

  const timelineEventTemplate = props => {
    let localProp = props?.eventData || {};
    if (
      props?.isTentativeTimeOff ||
      props?.isApprovedEventsAvailabilityTimeOff ||
      props?.isBlockedEvents
    ) {
      return (
        <Tooltip
          title={renderBlockTimeTooltip(localProp)}
          arrow
          placement="top"
        >
          <div
            style={{
              background: props?.isBlockedEvents
                ? localProp?.backgroundColor
                : props?.isTentativeTimeOff
                  ? localProp?.background
                  : props?.isApprovedEventsAvailabilityTimeOff
                    ? localProp?.backgroundColor
                    : undefined,
              height: '100%',
            }}
            className="scheduler-event-parent flex justify-content-center  align-items-center  flex-wrap w-12 text-800"
            data-record-id={props?.recordId}
          >
            <div
              className="scheduler-event-child-type text-center p-m-2"
              style={{ whiteSpace: 'nowrap' }}
              data-record-id={props?.recordId}
            >
              {localProp?.eventType !== 'timeoff' &&
                localProp?.eventType !== 'blockedTime' &&
                localProp?.timeText}
            </div>
            <div
              className="scheduler-event-child-text text-center p-m-2"
              style={{ whiteSpace: 'nowrap' }}
              data-record-id={props?.recordId}
            >
              {localProp?.title || ''}
            </div>
          </div>
        </Tooltip>
      );
    }

    return (
      <div
        className="grid w-full grid-nogutter h-full"
        style={{
          background: localProp?.backgroundColor,
          color: localProp?.textColor,
        }}
        data-record-id={props?.recordId}
      >
        <div
          className={`w-12 p-1 event-${props?.recordId}`}
          data-record-id={props?.recordId}
        >
          {jobDistancePermission && isScheduler ? (
            <>
              <JobDistanceOverlay
                selectedRow={filter?.selectedRow}
                eventInfo={null}
                localProp={localProp}
              />
            </>
          ) : null}

          <div className="flex justify-content-end">
            <span>
              <Tooltip
                title={renderTooltipContent(localProp, localProp?.Time)}
                arrow
                placement="right"
              >
                <i className="pi pi-exclamation-circle absolute right-0 mt-2 mr-3 z-5"></i>
              </Tooltip>
            </span>
          </div>
          <ProjectScheduleCard
            key={`ProjectScheduleCard_${props?.recordId}`}
            eventInfo={localProp}
            schedulerCardView={parentData?.schedulerCardView}
            cxSchedulerIcon={parentData?.cxSchedulerIcon}
            period={
              calendarRef
                ? getPeriod(calendarRef?.current?.currentView)
                : getPeriod(defaultView)
            }
            view={view}
          />
        </div>
      </div>
    );
  };
  const resourceTemplate = resourceInfo => {
    return <ResourceTemplate resourceInfo={resourceInfo} />;
  };
  const handleNewEvent = () => {
    dispatch(
      setInstallerViewCalendar({
        isSelectDialogOpen: true,
        newEventInfo: {
          start: new Date(),
          end: new Date(),
        },
      })
    );
  };
  const handleSelect = args => {
    if (!isOpen && args?.requestType === 'cellSelect' && args?.data) {
      const installerId = args?.data?.ResourceId;
      const { minutesDifference, startSlotTime } =
        selectedRapidSlotSyncFusion(args);
      dispatch(
        setInstallerViewCalendar({
          isSelectDialogOpen: true,
          newEventInfo: {
            start: args?.data?.StartTime,
            end: minutesDifference > 0 ? args?.data?.EndTime : startSlotTime,
          },
          initialInstaller: installerId,
        })
      );
    }
  };
  const handleEventClick = args => {
    args.cancel = true;
    if (args?.event?.isBlockedEvents) {
      dispatch(
        setInstallerViewCalendar({
          isSelectDialogOpen: true,
          newEventInfo: {
            start: args?.event?.StartTime,
            end: args?.event?.EndTime,
            timeOffRequestId: args?.event?.eventData?.timeOffRequestId,
            note: args?.event?.eventData?.title,
          },
          initialInstaller: args?.event?.ResourceId,
          mode: 'edit',
        })
      );
      return;
    }
    //Project Edit Popup will uncomment afterwards
    // dispatch(
    //   setNewSchedulerComponent({
    //     isProjectEditDialogOpen: true,
    //     selectedProject: args?.event?.eventData,
    //   })
    // );
    //Project details Page

    if (
      args?.event?.eventData?.project_id &&
      !args?.event?.eventData?.timeOffRequestId
    ) {
      window.open(
        `/project/view/${args?.event?.eventData?.project_id}`,
        '_blank'
      );
    }
  };
  const [dialogProps, setDialogProps] = useState({
    visible: false,
    props: {},
  });
  const continueScheduling = async () => {
    const projectData = infoData.rowData;
    const statusId =
      projectStatusType &&
      projectStatusType.length > 0 &&
      projectStatusType?.find(
        item => item?.status === projectData?.project_status
      );

    let updateObj = {
      installerId: parseInt(infoData.resourceDetails.resourceData.id),
      projectId: parseInt(infoData.rowData.project_id),
      projectStartDateTime: moment(infoData.startTime),
      projectEndDateTime: moment(infoData.endTime),
      old_installerId: null,
      old_startDateTime: null,
      old_endDateTime: null,
      status_id: statusId?.status_id || '',
      user_time_off_request_ids: dropTimeOffRequestIds,
    };
    setConfirmDialog({
      ...confirmDialog,
      button1TextDisabled: true,
      button2TextDisabled: true,
      button2Text: 'Please wait...',
    });

    const technicianName = calendarData.resourceData.find(
      ({ id }) => id == updateObj.installerId
    );
    validateReschedule(
      {
        ...updateObj,
        technician_name: technicianName?.title || '',
      },
      setDialogProps,
      setIsOpen,
      async action => {
        try {
          if (action) {
            await updateInstallerSchedule(updateObj);
          }
        } catch (error) {
          console.log(error, '...error continueScheduling');
        } finally {
          setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
            button1TextDisabled: false,
            button2TextDisabled: false,
            button2Text: '',
          });
          calenderRefreshHandler();
        }
      }
    );
  };
  const confirmScheduling = info => {
    setInfoData(info);
    setConfirmDialog({
      ...confirmDialog,
      isOpen: true,
      title:
        'This technician applied leave for this period. Do you still want to assign the job?',
      header: 'Confirm Scheduling',
      button2Text: 'Continue',
    });
  };
  const viewScheduleReport = projectId => {
    window.open(`/project/view/${projectId}`, '_blank');
  };
  const confirmBlockTimeWarning = info => {
    setInfoData(info);
    setConfirmDialog({
      ...confirmDialog,
      isOpen: true,
      title:
        'This slot is already blocked for the technician, Do you still want to assign the job?',
      header: 'Confirm Scheduling',
      button2Text: 'Continue',
    });
  };
  const handleDropModalClose = () => {
    setDropModalValue(false);
    setStartDate(null);
    setEndDate(null);
    setIsStartOrEndDateInvalid(false);
  };
  const saveDropCalendarData = async () => {
    let updateObj = {
      installerId: tableRowDropData?.resourceDetails?.resourceData
        ? parseInt(tableRowDropData?.resourceDetails?.resourceData?.id)
        : parseInt(tableRowDropData?.resourceDetails?.id),
      projectId: parseInt(tableRowDropData?.rowData?.project_id),
      projectStartDateTime: startDate ? startDate?.toISOString() : undefined,
      projectEndDateTime: endDate ? endDate?.toISOString() : undefined,
      old_installerId: null,
      old_startDateTime: null,
      old_endDateTime: null,
      status_id: statusId,
      userAction: technicianAction,
      percentage:
        technicianAction === scheduleInfoUserCustomAction
          ? schedulePercentage
          : 0,
    };
    setButtonDisabled(true);
    const technicianName = calendarData.resourceData.find(
      ({ id }) => id == updateObj.installerId
    );
    validateReschedule(
      {
        ...updateObj,
        technician_name: technicianName?.title || '',
      },
      setDialogProps,
      setDropModalValue,
      async action => {
        try {
          if (action) {
            const updateInstallerResponse =
              await updateInstallerSchedule(updateObj);
            if (updateInstallerResponse?.data?.status === 206) {
              toast.current.show({
                severity: 'warn',
                summary: 'Warning',
                detail: updateInstallerResponse?.data?.message,
              });
            }

            if (
              updateInstallerResponse?.data?.status === 200 ||
              updateInstallerResponse?.data?.status === true
            ) {
              toast.current.show({
                severity: 'success',
                summary: 'success',
                detail: updateInstallerResponse?.data?.message,
              });
            }
            setSchedulePercentage(0);
            setButtonDisabled(false);
            handleDropModalClose();
            calenderRefreshHandler();
          } else setButtonDisabled(false);
        } catch (error) {
          console.log(error, '...error saveDropCalendarData');
          setSchedulePercentage(0);
          setButtonDisabled(false);
          handleDropModalClose();
          calenderRefreshHandler();
        }
      }
    );
  };
  const add1Hour = input => {
    const inputDate = new Date(input);
    inputDate.setHours(inputDate.getHours() + 1);
    return inputDate;
  };
  const getInfoDateEnd = date => {
    let infoDateEnd;
    if (calendarRef?.current?.currentView === 'TimelineDay') {
      infoDateEnd = add1Hour(date);
    }
    return infoDateEnd;
  };
  const handleExternalDrop = async event => {
    try {
      const scheduleObj = calendarRef.current;
      if (
        scheduleObj &&
        // tableRef.current &&
        scheduleObj.element.contains(event.target)
      ) {
        const cellData = scheduleObj.getCellDetails(event.target);
        if (
          cellData &&
          typeof cellData === 'object' &&
          'groupIndex' in cellData &&
          typeof cellData.groupIndex === 'number'
        ) {
          const resourceDetails = scheduleObj.getResourcesByIndex(
            cellData.groupIndex
          );
          const startTime = new Date(cellData.startTime);
          const endTime = new Date(cellData.endTime);
          const IsAllDay = !!cellData?.IsAllDay;
          const dragData = JSON.parse(event.dataTransfer.getData('eventData'));
          let showWarningMessage = false;
          let showBlockTimeWarning = false;
          const eventList = calendarData.eventData.filter(
            ({
              isTentativeTimeOff = false,
              isApprovedEventsAvailabilityTimeOff = false,
            }) => isTentativeTimeOff || isApprovedEventsAvailabilityTimeOff
          );
          for (let i = 0; i < eventList.length; i++) {
            const timeoff = eventList[i];
            if (
              (timeoff.ResourceId == resourceDetails.resourceData.id &&
                (moment(startTime).isBetween(
                  moment(timeoff.StartTime),
                  moment(timeoff.EndTime)
                ) ||
                  moment(endTime).isBetween(
                    moment(timeoff.StartTime),
                    moment(timeoff.EndTime)
                  ))) ||
              moment(startTime).isSame(moment(timeoff.StartTime)) ||
              moment(startTime).isSame(moment(timeoff.EndTime)) ||
              moment(endTime).isSame(moment(timeoff.EndTime)) ||
              moment(endTime).isSame(moment(timeoff.EndTime))
            ) {
              if (timeoff?.eventData?.rrule) {
                if (
                  moment(startTime).isBetween(
                    moment(timeoff.eventData.rrule?.dtstart),
                    moment(timeoff.eventData.rrule?.until)
                  ) ||
                  moment(startTime).isSame(
                    moment(timeoff.eventData.rrule?.dtstart)
                  ) ||
                  moment(startTime).isSame(
                    moment(timeoff.eventData.rrule?.until)
                  ) ||
                  moment(endTime).isBetween(
                    moment(timeoff.eventData.rrule?.dtstart),
                    moment(timeoff.eventData.rrule?.until)
                  ) ||
                  moment(endTime).isSame(
                    moment(timeoff.eventData.rrule?.dtstart)
                  ) ||
                  moment(endTime).isSame(moment(timeoff.eventData.rrule?.until))
                ) {
                  if (
                    IsAllDay &&
                    (timeoff.eventData.rrule?.byweekday?.includes(
                      moment(startTime).format('dd').toLowerCase()
                    ) ||
                      timeoff.eventData.rrule?.byweekday?.includes(
                        moment(endTime).format('dd').toLowerCase()
                      ))
                  ) {
                    showWarningMessage = true;
                    break;
                  }
                  if (!IsAllDay) {
                    const newStartHour = moment(
                      timeoff.eventData.rrule.dtstart
                    ).get('hour');
                    const newStartMinute = moment(
                      timeoff.eventData.rrule.dtstart
                    ).get('minute');
                    const newEndHour = moment(
                      timeoff.eventData.rrule.until
                    ).get('hour');
                    const newEndMinute = moment(
                      timeoff.eventData.rrule.until
                    ).get('minute');
                    const newStartDate = moment(startTime).set({
                      hour: newStartHour,
                      minute: newStartMinute,
                    });
                    const newEndDate = moment(endTime).set({
                      hour: newEndHour,
                      minute: newEndMinute,
                    });
                    if (
                      (newStartDate < moment(startTime) &&
                        moment(endTime) < newEndDate) ||
                      (newStartDate < moment(startTime).add(1, 'hours') &&
                        moment(endTime).add(1, 'hours') < newEndDate)
                    ) {
                      showWarningMessage = true;
                      break;
                    }
                  }
                  if (IsAllDay && !timeoff.rrule?.byweekday.length) {
                    showWarningMessage = true;
                    break;
                  }
                }
              }
            }
          }
          const blockEventsOfDroppedInstaller = calendarData.eventData.filter(
            i =>
              i.isBlockedEvents &&
              i.ResourceId === parseInt(resourceDetails.resourceData.id)
          );
          const blockTimeRequests = [];
          for (const blockTimeEvent of blockEventsOfDroppedInstaller) {
            if (
              moment(startTime).isBetween(
                moment(new Date(blockTimeEvent?.StartTime)),
                moment(new Date(blockTimeEvent?.EndTime))
              ) ||
              moment(endTime).isBetween(
                moment(new Date(blockTimeEvent?.StartTime)),
                moment(new Date(blockTimeEvent?.EndTime))
              ) ||
              moment(getInfoDateEnd(startTime)).isBetween(
                moment(new Date(blockTimeEvent?.StartTime)),
                moment(new Date(blockTimeEvent?.EndTime))
              ) ||
              moment(getInfoDateEnd(endTime)).isBetween(
                moment(new Date(blockTimeEvent?.StartTime)),
                moment(new Date(blockTimeEvent?.EndTime))
              ) ||
              moment(startTime).isSame(
                moment(new Date(blockTimeEvent?.StartTime))
              ) ||
              moment(startTime).isSame(
                moment(new Date(blockTimeEvent?.EndTime))
              ) ||
              moment(endTime).isSame(
                moment(new Date(blockTimeEvent?.StartTime))
              ) ||
              moment(endTime).isSame(moment(new Date(blockTimeEvent?.EndTime)))
            ) {
              showBlockTimeWarning = true;
              blockTimeRequests.push(
                blockTimeEvent?.eventData?.timeOffRequestId
              );
            }
          }
          setDropTimeOffRequestIds(blockTimeRequests);
          if (showWarningMessage) {
            confirmScheduling({
              resourceDetails,
              rowData: dragData?.raw,
              startTime,
              endTime,
            });
          } else {
            if (showBlockTimeWarning) {
              confirmBlockTimeWarning({
                resourceDetails,
                rowData: dragData?.raw,
                startTime,
                endTime,
              });
            } else {
              setDropModalValue(true);
              setTableRowDropData({
                resourceDetails,
                rowData: dragData?.raw,
              });
              setStatusId(7);
              setDropData(dragData?.raw);
              const startDateObj = structuredClone(startTime);
              const endDateObj = structuredClone(endTime);
              switch (calendarRef?.current?.currentView) {
                case 'TimelineDay':
                  endDateObj.setMinutes(endDateObj.getMinutes() + 30);
                  setStartDate(startDateObj);
                  setEndDate(endDateObj);
                  break;
                case 'TimelineWeek':
                case 'TimelineMonth':
                  endDateObj.setDate(endDateObj.getDate());
                  setStartDate(startDateObj);
                  setEndDate(endDateObj);
                  break;
                default:
                  break;
              }
            }
          }
        } else if (
          view === PROJECT_REPORT.FULL_CALENDAR &&
          cellData?.startTime &&
          cellData?.endTime
        ) {
          const startTime = new Date(cellData.startTime);
          const endTime = new Date(cellData.endTime);
          const dragData = JSON.parse(event.dataTransfer.getData('eventData'));
          dispatch(
            setNewSchedulerComponent({
              dropDialogData: dragData?.raw,
              dropInfo: {
                start: startTime,
                end: endTime,
              },
              isDropDialogOpen: true,
            })
          );
        } else {
          let recordId = null;
          const parentElements = event.target.getElementsByClassName(
            'scheduler-event-parent'
          );
          if (parentElements && parentElements.length) {
            recordId = parentElements[0].getAttribute('data-record-id');
          } else {
            const childTypeElements = event.target.getElementsByClassName(
              'scheduler-event-child-type'
            );
            if (childTypeElements && childTypeElements.length) {
              recordId = childTypeElements[0].getAttribute('data-record-id');
            } else {
              const childTextElements = event.target.getElementsByClassName(
                'scheduler-event-child-text'
              );
              if (childTextElements && childTextElements.length) {
                recordId = childTextElements[0].getAttribute('data-record-id');
              } else {
                recordId = event.target.getAttribute('data-record-id');
              }
            }
          }
          if (recordId) {
            const eventData = calendarData.eventData.find(
              eachEvent => +eachEvent.recordId === +recordId
            );
            if (eventData) {
              const dragData = JSON.parse(
                event.dataTransfer.getData('eventData')
              );
              const startTime = new Date(eventData.StartTime);
              const endTime = new Date(eventData.EndTime);
              const resourceDetails = {
                resourceData: {
                  id: eventData.ResourceId,
                },
              };
              if (
                eventData?.isTentativeTimeOff ||
                eventData?.isApprovedEventsAvailabilityTimeOff
              ) {
                confirmScheduling({
                  resourceDetails,
                  rowData: dragData?.raw,
                  startTime,
                  endTime,
                });
              } else if (eventData?.isBlockedEvents) {
                setDropTimeOffRequestIds([
                  eventData?.eventData?.timeOffRequestId,
                ]);
                confirmBlockTimeWarning({
                  resourceDetails,
                  rowData: dragData?.raw,
                  startTime,
                  endTime,
                });
              } else if (
                view === PROJECT_REPORT.CALENDAR &&
                eventData?.ResourceId &&
                calendarData.resourceData.length
              ) {
                setDropModalValue(true);
                setTableRowDropData({
                  resourceDetails: calendarData.resourceData.find(
                    ({ id }) => id == eventData?.ResourceId
                  ),
                  rowData: dragData?.raw,
                });
                setStatusId(7);
                setDropData(dragData?.raw);
                const startDateObj = structuredClone(startTime);
                const endDateObj = structuredClone(endTime);
                switch (calendarRef?.current?.currentView) {
                  case 'TimelineDay':
                    endDateObj.setMinutes(endDateObj.getMinutes() + 30);
                    setStartDate(startDateObj);
                    setEndDate(endDateObj);
                    break;
                  case 'TimelineWeek':
                  case 'TimelineMonth':
                    endDateObj.setDate(endDateObj.getDate());
                    setStartDate(startDateObj);
                    setEndDate(endDateObj);
                    break;
                  default:
                    break;
                }
              } else {
                dispatch(
                  setNewSchedulerComponent({
                    dropDialogData: dragData?.raw,
                    dropInfo: {
                      start: startTime,
                      end: endTime,
                    },
                    isDropDialogOpen: true,
                  })
                );
              }
            }
          }
        }
      }
    } catch (error) {
      console.error(error, 'on drop');
    }
  };

  const notesFormik = useFormik({
    initialValues: {
      type: 6,

      note_text: '',

      follow_up_date: null,
    },

    validationSchema: NotesFormikSchema,

    onSubmit: async values => {
      setButtonDisabled(true);
      if (values?.note_text?.length > 0) {
        await addNotes(
          parseInt(infoValue?.currentDetails?.eventData?.project_id),

          values,

          () => {},

          setAlert,

          () => {},

          () => {}
        );
      }

      if (isUpdateSchedule) {
        const statusType = projectStatusType?.find(
          item =>
            item?.status ===
            infoValue?.currentDetails?.eventData?.project_status
        );

        let updateObj = {
          installerId: parseInt(infoValue.currentDetails.ResourceId),
          projectId: parseInt(infoValue.currentDetails.eventData.project_id),
          projectStartDateTime: moment(infoValue.startDateTime),
          projectEndDateTime: moment(infoValue.endDateTime),
          old_installerId:
            infoValue.currentDetails.eventData.installer_id.toString(),
          old_startDateTime: moment(
            infoValue.currentDetails.eventData.schedule_date
          ),
          old_endDateTime: moment(
            infoValue.currentDetails.eventData.schedule_end_date
          ),
          status_id: statusType?.status_id || '',
        };
        const technicianName = calendarData.resourceData.find(
          ({ id }) => id == updateObj.installerId
        );
        validateReschedule(
          {
            ...updateObj,
            technician_name: technicianName?.title || '',
          },
          setDialogProps,
          setIsOpen,
          async action => {
            try {
              if (action) {
                await updateInstallerSchedule(updateObj);
              }
            } catch (error) {
              console.log(error, '...error onSubmit');
            } finally {
              setIsOpen(false);
              notesFormik.handleReset();
              calenderRefreshHandler();
              setButtonDisabled(false);
            }
          }
        );
      }
    },

    enableReinitialize: true,
  });
  const updateSchedule = info => {
    if (
      info?.currentDetails?.isBlockedEvents ||
      info?.currentDetails?.isTentativeTimeOff ||
      info?.currentDetails?.isApprovedEventsAvailabilityTimeOff
    ) {
      return;
    }
    setIsUpdateSchedule(true);

    setStartDateStr(info?.startDateTime);

    setEndDateStr(info?.endDateTime);

    setInfoValue(info);

    notesFormik.setValues({
      type: '',

      note_text: '',

      follow_up_date: null,
    });

    setDialogSettings(prevState => ({
      ...prevState,

      title: 'Reschedule Confirmation',

      showButton2: true,
    }));

    setIsOpen(true);

    notesFormik?.handleReset();
  };
  const handleActionBegin = args => {
    if (args.requestType === 'eventChange') {
      handleWithinCalendarEventDragAndDrop(args);
    } else if (args.requestType === 'toolbarItemRendering') {
      args.items.unshift(SEPARATOR_ICON);
      view === PROJECT_REPORT.FULL_CALENDAR &&
        args.items.unshift(NEW_EVENT_BUTTON);
      args.items.push(REFRESH_ICON);
      args.items.push(FULLSCREEN_ICON);
    } else if (
      ['dateNavigate', 'viewNavigate'].includes(args.requestType) &&
      view === PROJECT_REPORT.FULL_CALENDAR
    ) {
      handleSetCalendarData({
        eventData: [],
        resourceData: [],
      });
    }
  };
  const handleActionComplete = async args => {
    const scheduleElement = document.getElementById(targetID);
    if (
      ['dateNavigate', 'viewNavigate'].includes(args.requestType) &&
      !isNavigate
    ) {
      setIsNavigate(true);
      setDefaultView(calendarRef?.current?.currentView);
      handleSetFilter({
        selectedDate: calendarRef?.current?.selectedDate || new Date(),
      });
      if (view != PROJECT_REPORT.FULL_CALENDAR) {
        await callGetCalendarDataApi(true, {
          installerIds:
            calendarRef?.current?.resourceCollection?.[0]?.dataSource
              ?.slice(0, 20)
              .map(record => record.installerUserId)
              .join(',') || undefined,
        });
      } else if (
        view === PROJECT_REPORT.FULL_CALENDAR &&
        filter?.calendarFilter?.installerIds
      ) {
        await callGetCalendarDataApi(true);
      } else if (
        view === PROJECT_REPORT.FULL_CALENDAR &&
        !filter?.calendarFilter?.installerIds
      ) {
        handleSetCalendarData({
          eventData: [],
          resourceData: [],
          calendarBlock: false,
        });
      }
      // setIsNavigate(false);
    } else if (args.requestType === 'toolBarItemRendered' && scheduleElement) {
      const newEventElement =
        scheduleElement.querySelector('.newEventScheduler');
      if (newEventElement) newEventElement.onclick = () => handleNewEvent();
      const refreshIconElement = scheduleElement.querySelector(
        '.refreshIconScheduler'
      );
      if (refreshIconElement)
        refreshIconElement.onclick = () => calenderRefreshHandler();

      const fullscreenIconElement = scheduleElement.querySelector(
        '.fullscreenIconScheduler'
      );
      if (fullscreenIconElement) {
        fullscreenIconElement.onclick = () => {
          const fullscreenIconElementLocal = scheduleElement.querySelector(
            '.fullscreenIconScheduler'
          );
          if (fullscreenIconElementLocal.classList) {
            if (
              fullscreenIconElementLocal.classList.contains(
                'md-fullscreen_exit'
              )
            ) {
              fullscreenIconElementLocal.classList.remove('md-fullscreen_exit');
              fullscreenIconElementLocal.classList.add('md-fullscreen');
              fullscreenIconElementLocal.setAttribute('title', 'Fullscreen');
              handleSetData({ fullscreen: false, screenSize: '650px' });
            } else if (
              fullscreenIconElementLocal.classList.contains('md-fullscreen')
            ) {
              fullscreenIconElementLocal.classList.remove('md-fullscreen');
              fullscreenIconElementLocal.classList.add('md-fullscreen_exit');
              fullscreenIconElementLocal.setAttribute(
                'title',
                'Exit Fullscreen'
              );
              handleSetData({
                fullscreen: true,
                screenSize: `${window.innerHeight}px`,
              });
            }
          }
        };
      }
    }
  };
  const handleWithinCalendarEventDragAndDrop = args => {
    updateSchedule({
      startDateTime: args.data.StartTime,
      endDateTime: args.data.EndTime,
      currentDetails: { ...args.data },
    });
  };
  // Reschedule
  const calenderRefreshHandler = async () => {
    if (
      view === PROJECT_REPORT.FULL_CALENDAR &&
      !filter?.calendarFilter?.installerIds
    ) {
      return;
    }
    setRefreshButton(true);
    handleSetLoader({ calendarLoader: true });
  };

  const handleScroll = async options => {
    const selectedIds = options.resourceData.map(val => val.id);

    if (selectedIds.length) {
      const ids = [];
      selectedIds.forEach(installerId => {
        if (filter.installerMap?.[installerId]?.user_id) {
          ids.push(filter.installerMap[installerId].user_id);
        }
      });
      if (ids.length || filter?.calendarFilter?.installerIds) {
        let parsedIds = '';
        if (filter?.calendarFilter?.installerIds) {
          parsedIds = filter?.calendarFilter?.installerIds;
        } else {
          parsedIds = ids.join(',');
        }
        await callGetCalendarDataApi(
          true,
          {
            installerIds: parsedIds,
          },
          true
        );
      }
    }
  };

  const eventSettings = {
    dataSource: calendarData?.eventData,
    template: timelineEventTemplate,
    spannedEventPlacement: 'TimeSlot',
  };

  const group = {
    enableCompactView: false,
    resources: ['Resources'],
  };
  const workHours = {
    highlight: true,
    start: '06:00',
    end: '20:59',
  };

  return (
    <div
      className={`w-full min-w-700 ${parentData?.fullscreen ? 'scheduler-calendar-parent calendar-view-fullscreen' : 'scheduler-calendar-inner mt-2'}`}
    >
      <PFBlockUI
        blocked={
          loader?.calendarLoader || calendarData?.calendarBlock || isNavigate
        }
      >
        <ScheduleComponent
          id={targetID}
          rowAutoHeight={true}
          ref={calendarRef}
          width="100%"
          {...(parentData.fullscreen
            ? { height: parentData.screenSize }
            : { height: '650px' })}
          currentView={defaultView}
          eventSettings={eventSettings}
          eventDragArea=".content-wrapper"
          cssClass="virtual-scrolling"
          actionComplete={handleActionComplete}
          eventClick={handleEventClick}
          eventDoubleClick={args => (args.cancel = true)}
          enableLazyLoading={true}
          enableVirtualization={true}
          allowVirtualScrolling={true}
          onDrop={handleExternalDrop}
          onDragOver={event => event.preventDefault()}
          //dragStop={handleWithinCalendarEventDragAndDrop}
          showHeaderBar={true}
          actionBegin={handleActionBegin}
          readOnly={true}
          select={handleSelect}
          cellClick={args => {
            args.cancel = true;
          }}
          virtualScrollStop={handleScroll}
          timezone={localStorage.getItem('client_timezone')}
          dataBound={() => setIsNavigate(false)}
          selectedDate={calendarData?.selectedDate}
          workHours={workHours}
          startHour="06:00"
          endHour="20:59"
        >
          {view === PROJECT_REPORT.FULL_CALENDAR ? (
            <ViewsDirective>
              <ViewDirective
                option="Day"
                showWeekend={isWeekendsVisible}
                workDays={isWeekendsVisible ? ALL_DAYS : DEFAULT_WORK_DAYS}
              />
              <ViewDirective
                option="Week"
                timeScale={DEFAULT_DISABLED_TIME_SCALE}
                showWeekend={isWeekendsVisible}
                workDays={isWeekendsVisible ? ALL_DAYS : DEFAULT_WORK_DAYS}
              />
              <ViewDirective
                option="Month"
                showWeekend={isWeekendsVisible}
                workDays={isWeekendsVisible ? ALL_DAYS : DEFAULT_WORK_DAYS}
              />
            </ViewsDirective>
          ) : (
            <ViewsDirective>
              <ViewDirective
                option="TimelineDay"
                displayName="Day"
                allowVirtualScrolling={true}
                enableLazyLoading={true}
                group={group}
                resourceHeaderTemplate={resourceTemplate}
              />
              <ViewDirective
                option="TimelineWeek"
                displayName="Week"
                allowVirtualScrolling={true}
                enableLazyLoading={true}
                timeScale={DEFAULT_DISABLED_TIME_SCALE}
                group={group}
                resourceHeaderTemplate={resourceTemplate}
              />
              <ViewDirective
                option="TimelineMonth"
                displayName="Month"
                allowVirtualScrolling={true}
                enableLazyLoading={true}
                group={group}
                resourceHeaderTemplate={resourceTemplate}
              />
            </ViewsDirective>
          )}
          {view !== PROJECT_REPORT.FULL_CALENDAR ? (
            <ResourcesDirective>
              <ResourceDirective
                field="ResourceId"
                title="Technicians"
                name="Resources"
                // allowMultiple={true}
                dataSource={calendarData?.resourceData}
                textField="title"
                idField="id"
              ></ResourceDirective>
            </ResourcesDirective>
          ) : null}
          <Inject
            services={
              view === PROJECT_REPORT.FULL_CALENDAR
                ? [Day, Week, Month, DragAndDrop, Resize]
                : [
                    TimelineViews,
                    TimelineMonth,
                    TimelineYear,
                    DragAndDrop,
                    Resize,
                  ]
            }
          />
        </ScheduleComponent>
        <Toast ref={toast} />
        {/* Confirmation dialog for delete */}
        <GenericConfirmationDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
          onConfirmDialog={continueScheduling}
        />
        {dropModalValue ? (
          <Modal
            open={dropModalValue}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            onClose={handleDropModalClose}
            className={classes.modal}
          >
            <Grid
              container
              item
              direction="row"
              xs={3}
              justifyContent="flex-end"
              spacing={2}
              className={classes.boxWidth}
            >
              <Grid container item xs={12} direction="row" m={4}>
                <Typography className={classes.detailLabelModal}>
                  Store #
                </Typography>
                <Typography className={classes.colon}>:</Typography>
                <Typography className={classes.detail}>
                  {dropData?.store_number}{' '}
                </Typography>
              </Grid>

              <Grid container item xs={12} direction="row" m={4}>
                <Typography className={classes.detailLabelModal}>
                  Client Name{' '}
                </Typography>
                <Typography className={classes.colon}>:</Typography>
                <Typography className={classes.detail}>
                  {dropData?.client_name}{' '}
                </Typography>
              </Grid>

              <Grid container item xs={12} direction="row" m={4}>
                <Typography className={classes.detailLabelModal}>
                  Category
                </Typography>
                <Typography className={classes.colon}>:</Typography>
                <Typography className={classes.detail}>
                  {dropData?.category}{' '}
                </Typography>
              </Grid>

              <Grid container item xs={12} direction="row" m={4}>
                <Typography className={classes.detailLabelModal}>
                  Address
                </Typography>
                <Typography className={classes.colon}>:</Typography>
                <Typography className={classes.detail}>
                  {dropData?.installation_address}{' '}
                </Typography>
              </Grid>

              <Grid container item xs={12} direction="row" m={4}>
                <Typography className={classes.detailLabelModal}>
                  Type
                </Typography>
                <Typography className={classes.colon}>:</Typography>
                <Typography className={classes.detail}>
                  {dropData?.project_type}{' '}
                </Typography>
              </Grid>
              <Grid
                container
                item
                xs={12}
                direction="row"
                m={4}
                className="align-items-center"
              >
                <Typography className={classes.detailLabelModal}>
                  Schedule Start Date
                </Typography>
                <Typography className={classes.colon}>:</Typography>
                <div className={classes.detail}>
                  <Calendar
                    id="startDate"
                    className="w-full"
                    value={startDate}
                    onChange={e => setStartDate(e.value)}
                    showTime
                    hourFormat="12"
                    appendTo="self"
                    stepMinute={15}
                  />
                </div>
              </Grid>
              <Grid
                container
                item
                xs={12}
                direction="row"
                m={4}
                className="align-items-center"
              >
                <Typography className={classes.detailLabelModal}>
                  Schedule End Date
                </Typography>
                <Typography className={classes.colon}>:</Typography>
                <div className={classes.detail}>
                  <Calendar
                    id="endDate"
                    className="w-full"
                    value={endDate}
                    onChange={e => setEndDate(e.value)}
                    showTime
                    hourFormat="12"
                    appendTo="self"
                    stepMinute={15}
                  />
                  {isStartOrEndDateInvalid && (
                    <div className="mt-1 text-red-600">
                      End date must be after start date
                    </div>
                  )}
                </div>
              </Grid>

              <Grid container item xs={12} direction="row" m={4}>
                <Typography className={classes.detailLabelModal}>
                  Status
                </Typography>
                <Typography className={classes.colon}>:</Typography>
                <Autocomplete
                  id="status"
                  disableListWrap
                  options={projectStatusType || []}
                  getOptionLabel={option => option && option.status}
                  onChange={(event, value) => {
                    setStatusId(value?.status_id);
                  }}
                  renderInput={params => (
                    <TextField {...params} InputLabelProps={{ shrink: true }} />
                  )}
                  classes={{ input: classes.statusInput }}
                  value={projectStatusType?.find(
                    status => status.status_id === statusId
                  )}
                />
              </Grid>

              <Grid
                container
                item
                xs={12}
                direction="row"
                m={4}
                className="grid"
              >
                <Typography className="col-3 pl-0">
                  Default Technician Action
                </Typography>
                <Typography className="col-1 text-center">:</Typography>
                <Typography className="col-8">
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={technicianAction}
                    name="radio-buttons-group"
                    onChange={e => setTechnicianAction(e?.target.value)}
                  >
                    <FormControlLabel
                      value={scheduleInfoUserWipeAction}
                      control={<Radio />}
                      label="Full Allocation"
                    />
                    <div className="flex align-items-center">
                      <FormControlLabel
                        value={scheduleInfoUserCustomAction}
                        control={<Radio />}
                        label="Custom Allocation"
                      />
                      {technicianAction === scheduleInfoUserCustomAction && (
                        <PFInputNumber
                          id="allocate_percentage"
                          value={schedulePercentage}
                          onChange={(event, value) => {
                            const percentage = getPercentageValue(value);
                            setSchedulePercentage(percentage);
                          }}
                          suffix={'%'}
                          placeholder="%"
                          className="w-36"
                          pt={{
                            root: {
                              className: 'h-2rem pb-2 pt-1',
                            },
                            input: {
                              root: {
                                className:
                                  'w-full border-noround border-bottom-1 border-top-none border-x-none p-0  shadow-none border-gray-500',
                              },
                            },
                          }}
                          min={0}
                          maxLength={6}
                          max={100}
                        />
                      )}
                    </div>

                    <FormControlLabel
                      value={scheduleInfoUserFillAction}
                      control={<Radio />}
                      label="Allocate Remaining"
                    />

                    <FormControlLabel
                      value={scheduleInfoUserSkipAction}
                      control={<Radio />}
                      label="Leave Unassigned"
                    />
                  </RadioGroup>
                </Typography>
              </Grid>

              <Grid container item xs={12} direction="row" m={4}>
                <Typography className={classes.detailLabelModal}>
                  Source Status
                </Typography>
                <Typography className={classes.colon}>:</Typography>
                <Typography className={classes.detail}>
                  {dropData?.IMS_status}{' '}
                </Typography>
              </Grid>

              <Grid container item xs={12} direction="row" m={4}>
                <Typography className={classes.detailLabelModal}>
                  Project No
                </Typography>
                <Typography className={classes.colon}>:</Typography>
                <Typography className={classes.detail}>
                  {dropData?.project_number}{' '}
                </Typography>
              </Grid>

              <Grid container item xs={12} direction="row" m={4}>
                <Typography className={classes.detailLabelModal}>
                  Mobile Number
                </Typography>
                <Typography className={classes.colon}>:</Typography>
                <Typography className={classes.detail}>
                  {dropData?.home_phone}{' '}
                </Typography>
              </Grid>

              <Grid container item xs={12} direction="row" m={4}>
                <Typography className={classes.detailLabelModal}>
                  Alternate Number
                </Typography>
                <Typography className={classes.colon}>:</Typography>
                <Typography className={classes.detail}>
                  {dropData?.mobile_phone}{' '}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => viewScheduleReport(dropData?.project_id)}
                >
                  GoTo Job
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={saveDropCalendarData}
                  disabled={isStartOrEndDateInvalid || buttonDisabled}
                >
                  {buttonDisabled ? <>...Please wait</> : <>Save</>}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleDropModalClose}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Modal>
        ) : null}

        <InstallerViewBlockCalendar
          calenderRefreshHandler={calenderRefreshHandler}
        />
        <ScheduleDropDialog
          calenderRefreshHandler={calenderRefreshHandler}
          validateReschedule={(body, cb) => {
            const technicianName = calendarData.resourceData.find(
              ({ id }) => id == body.installerId
            );
            body = {
              ...body,
              technician_name: technicianName?.title || '',
            };
            validateReschedule(
              body,
              setDialogProps,
              value =>
                dispatch(
                  setNewSchedulerComponent({
                    isDropDialogOpen: value,
                  })
                ),
              cb
            );
          }}
        />
        <ProjectEditDialog calenderRefreshHandler={calenderRefreshHandler} />
        {dialogProps.visible && (
          <ConfirmDialog visible={dialogProps.visible} {...dialogProps.props} />
        )}
        <form>
          <GenericDialog
            isOpen={isOpen}
            handleClose={() => {
              setIsOpen(false);
              notesFormik.handleReset();
              setButtonDisabled(false);
            }}
            disabledButton2={buttonDisabled}
            disabledButton1={buttonDisabled}
            dialogSettings={dialogSettings}
            handleSave={() => notesFormik.handleSubmit()}
            button2Text={buttonDisabled ? 'Updating...' : null}
          >
            <Grid container spacing={2} direction="column">
              <Grid item>
                <DialogContentText>
                  <b>
                    Please confirm, if you would like to reschedule this job for{' '}
                    {moment(startDateStr)?.format('YYYY-MM-DD hh:mm A')} -{' '}
                    {moment(endDateStr)?.format('YYYY-MM-DD hh:mm A')}
                  </b>
                </DialogContentText>
              </Grid>
              <Grid item>
                <TextField
                  id="note_text"
                  name="note_text"
                  label="Note"
                  multiline
                  disabled={buttonDisabled}
                  onChange={notesFormik.handleChange}
                  onBlur={notesFormik.handleBlur}
                  value={notesFormik?.values?.note_text}
                />
              </Grid>
            </Grid>
          </GenericDialog>
        </form>
      </PFBlockUI>
    </div>
  );
};
export default SchedulerCalendar;
