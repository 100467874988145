import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import PFDataTable from '../../../shared/PFPrime/PFDataTable';
import PFButton from '../../../shared/PFPrime/PFButton';
import {
  gridProjectsData,
  projectsGridColumns,
} from '../helpers/ProjectsListing';
import SkeletonLoader from '../../../shared/Loader/skeleton';
import TableColumnsLoader from '../../../shared/Loader/tableColumnsLoader';

const ProjectsListing = ({
  quoteId,
  opportunityId,
  quoteNumber,
  opportunityNumber,
}) => {
  const history = useHistory();
  const { customerId } = useParams();
  const [loading, setLoading] = useState(false);
  const [listOfProjects, setListOfProjects] = useState([]);

  const projectList = () => {
    setLoading(true);
    gridProjectsData(customerId)
      .then(res => {
        if (res?.status === 200) {
          let projects = res?.data?.[0]?.project;
          if (projects?.length > 0) {
            if (quoteId) {
              projects = projects.filter(
                item => item?.project_crm?.quote_id == quoteId
              );
            }
            projects = projects.sort((a, b) => b.project_id - a.project_id);
          }

          setListOfProjects(projects || []);
          setLoading(false);
        }
      })
      .catch(error => {
        if (error?.response?.status === 404 && history) {
          return history.push('/page-not-found');
        }
        console.log('Error', error);
      });
  };

  useEffect(() => {
    projectList();
  }, []);

  const projectTableLoader = useMemo(() => {
    return TableColumnsLoader(
      projectsGridColumns({
        quoteNumber,
        opportunityNumber,
        customerId,
        opportunityId,
        loading,
      }),
      {
        rows: 4,
        isValue: false,
      }
    );
  }, [quoteNumber, opportunityNumber, customerId, opportunityId]);

  return (
    <div className="px-3 py-1">
      <div className="col-12 md:col-12 lg:col-12">
        <div className="w-full flex justify-content-end">
          <PFButton
            icon="pi pi-refresh"
            label="Refresh"
            outlined={true}
            className="mr-2"
            onClick={projectList}
          />
          {!quoteId && (
            <PFButton
              label="Create"
              onClick={() =>
                window.open(`/project/add?clientId=` + customerId, '_blank')
              }
              icon="pi pi-plus"
            />
          )}
        </div>
      </div>

      <PFDataTable
        paginator={!loading}
        rows={5}
        columns={projectsGridColumns({
          quoteNumber,
          opportunityNumber,
          customerId,
          opportunityId,
          loading,
        })}
        rowsPerPageOptions={[5, 10, 25, 50]}
        data={loading ? projectTableLoader : listOfProjects}
        size="small"
      />
    </div>
  );
};

export default ProjectsListing;
