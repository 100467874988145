import { useMemo, useState } from 'react';
import { ListBox } from 'primereact';

import PFDataTable from '../../../shared/PFPrime/PFDataTable';
import PFButton from '../../../shared/PFPrime/PFButton';
import PFDialog from '../../../shared/PFPrime/PFDialog';
import SelectedJobCoverageValueList from '../SelectedJobCoverageValueList';
import { ACTION, COVERAGE_TYPE } from '../Helper';

const PreviewTable = ({
  data,
  isShowPreviousDetails,
  isJob,
  formik,
  selectedRecord,
  storeList,
  isCloneOrEdit,
  mode,
}) => {
  const [showCoverageValues, setShowCoverageValues] = useState([]);
  const [selectedCoverageValues, setSelectedCoverageValues] = useState();
  const [coverageDialogVisible, setCoverageDialogVisible] = useState(false);
  const [isOffline, setIsOffline] = useState(false);
  const isCoverageSelected = useMemo(() => {
    if (data?.length) {
      return data.some(({ coverageValues = [] }) => coverageValues?.length);
    }
    return false;
  }, [data]);
  const previewTableColumns = [
    {
      field: 'type',
      header: 'Type',
      body: record => {
        if (isShowPreviousDetails && record.previousType) {
          return (
            <span className="flex gap-2">
              {record.previousType} <i className="pi pi-arrow-right"></i>{' '}
              {record.type}
            </span>
          );
        }
        return record.type;
      },
    },
    {
      field: 'category',
      header: 'Category',
      body: record => {
        if (isShowPreviousDetails && record.previousCategory) {
          return (
            <span className="flex gap-2">
              {record.previousCategory} <i className="pi pi-arrow-right"></i>{' '}
              {record.category}
            </span>
          );
        }
        return record.category;
      },
    },
    {
      field: 'coverageBy',
      header: 'Coverage By',
      body: record => {
        if (isShowPreviousDetails && record.previousCoverageBy) {
          return (
            <span className="flex gap-2">
              {record.previousCoverageBy} <i className="pi pi-arrow-right"></i>{' '}
              {record.coverageBy}
            </span>
          );
        }
        return record.coverageBy;
      },
    },
    {
      field: 'coverageValues',
      header: 'Coverage Values',
      body: record => {
        return (
          <PFButton
            label={`${record.coverageValues?.length || 0} Selected`}
            size="small"
            text
            severity="Primary"
            className="mx-2 md:mb-2"
            onClick={() => {
              setShowCoverageValues(record.coverageValues);
            }}
          />
        );
      },
    },
    ...(!isCoverageSelected ||
    (mode &&
      isCloneOrEdit !== ACTION.GROUP_CLONE &&
      isCloneOrEdit !== ACTION.ROW_CLONE) ||
    isCloneOrEdit === ACTION.ROW_EDIT ||
    isCloneOrEdit === ACTION.GROUP_EDIT
      ? [{}]
      : [
          {
            field: 'Slots',
            header: 'Slots',
            body: record => {
              return (
                <div className="flex align-items-center gap-2">
                  <PFButton
                    label="Open"
                    size="small"
                    severity="Primary"
                    onClick={() => {
                      if (isJob) {
                        let data,
                          makeOffline = false;
                        if (selectedRecord?.data) {
                          if (selectedRecord?.rowEdit) {
                            data = {
                              ...(selectedRecord?.data || {}),
                              project_typeId: formik?.values?.type || undefined,
                            };
                          } else if (selectedRecord?.rowClone) {
                            data = {
                              ...(record?.raw?.data || record?.raw || {}),
                              project_typeId: formik?.values?.type || undefined,
                              coverage_values:
                                formik.values.coverageValues || [],
                            };
                            makeOffline = true;
                          } else {
                            data = {
                              ...(record?.raw?.data || record?.raw || {}),
                              project_typeId: formik?.values?.type || undefined,
                              coverage_values:
                                formik.values.coverageValues || [],
                            };
                            makeOffline = true;
                          }
                        } else {
                          data = record?.raw;
                          makeOffline = true;
                        }
                        if (data) {
                          let parts = {};
                          if (data?.coverage_by === COVERAGE_TYPE.STORE) {
                            const coverageValuesArray =
                              data?.coverage_values?.map(value =>
                                Number(value)
                              );
                            storeList?.forEach(item => {
                              if (
                                coverageValuesArray?.includes(item?.store_id)
                              ) {
                                parts[item.store_id] =
                                  `${item.store_number} - ${item.store_name}`;
                              }
                            });
                          } else if (
                            data?.coverage_by === COVERAGE_TYPE.ZIP_CODE
                          ) {
                            const coverageValue = Array.isArray(
                              data?.coverage_values
                            )
                              ? data?.coverage_values?.join(', ')
                              : data?.coverage_values;
                            coverageValue
                              .split(',')
                              .forEach(
                                part => (parts[part.trim()] = part.trim())
                              );
                          } else if (
                            data?.coverage_by === COVERAGE_TYPE.DISTRICT
                          ) {
                            data?.coverage_values?.forEach(
                              part => (parts[part.trim()] = part.trim())
                            );
                          }
                          if (makeOffline) {
                            if (Object.keys(parts).length) {
                              setIsOffline(true);
                              setSelectedCoverageValues({
                                parts,
                                raw: data,
                              });
                              setCoverageDialogVisible(true);
                            }
                          } else {
                            setShowCoverageValues(Object.keys(parts));
                          }
                        }
                      } else {
                        setShowCoverageValues(record.coverageValues);
                      }
                    }}
                  />
                </div>
              );
            },
          },
        ]),
  ];
  return (
    <>
      <PFDataTable
        columns={previewTableColumns}
        data={data}
        tableStyle={{ minWidth: '50rem' }}
        stripedRows={true}
      />
      {showCoverageValues.length ? (
        <PFDialog
          header="Coverage Values"
          show={true}
          style={{ width: '15vw' }}
          hide={() => setShowCoverageValues([])}
          BodyComponent={
            <div className="card flex justify-content-center">
              <ListBox
                options={showCoverageValues}
                className="w-full md:w-14rem"
              />
            </div>
          }
        ></PFDialog>
      ) : null}
      {isJob && coverageDialogVisible && selectedCoverageValues && (
        <SelectedJobCoverageValueList
          coverageDialogVisible={coverageDialogVisible}
          setCoverageDialogVisible={setCoverageDialogVisible}
          selectedCoverageValues={selectedCoverageValues}
          isOffline={isOffline}
          formik={formik}
        />
      )}
    </>
  );
};

export default PreviewTable;
