import React from 'react';
import { InputNumber } from 'primereact/inputnumber';

export default function PFInputNumber({
  name,
  min,
  max,
  value,
  onChange,
  readOnly,
  className,
  useGrouping,
  inputId,
  mode,
  currency,
  minFractionDigits,
  placeholder,
  prefix,
  suffix,
  disabled,
  inputStyle,
  pt = {},
  ...rest
}) {
  const minValue = typeof min === 'number' ? min : null;
  return (
    <>
      <InputNumber
        min={minValue}
        max={max || null}
        value={value || null}
        name={name || ''}
        onChange={e => onChange(name, e.value)}
        readOnly={readOnly || false}
        className={`w-full p-inputtext-sm ${className || ''}`}
        useGrouping={useGrouping || false}
        inputId={inputId || 'currency-us'}
        mode={mode || 'decimal'}
        currency={currency || 'USD'}
        minFractionDigits={minFractionDigits || ''}
        placeholder={placeholder || ''}
        pt={{
          input: {
            root: { autoComplete: 'off', className: 'w-full' },
          },
          ...pt,
        }}
        prefix={prefix || ''}
        suffix={suffix || ''}
        disabled={disabled}
        inputStyle={inputStyle || ''}
        {...rest}
      />
    </>
  );
}
