export const loadChatScript = () => {
  // Add the inline script first
  const inlineScript = document.createElement('script');
  inlineScript.text = `
      window.$zoho = window.$zoho || {};
      $zoho.salesiq = $zoho.salesiq || { ready: function() {} };
  `;
  document.body.appendChild(inlineScript);

  // Add the external script
  const externalScript = document.createElement('script');
  externalScript.id = 'zsiqscript';
  externalScript.src =
    'https://salesiq.zohopublic.com/widget?wc=siqc94e8dac30c77a9e24c27ea4df485ae005ad7bba9ce7e5d6b154ffa88a3a9e54';
  externalScript.defer = true;
  document.body.appendChild(externalScript);

  return () => {
    // Cleanup both scripts when no longer needed
    document.body.removeChild(inlineScript);
    document.body.removeChild(externalScript);
  };
};
