import React from 'react';
import { Panel } from 'primereact';

import FilterReport from '../NewConfiguration/SchedularReportConfig/components/FilterReports';

const UserFormSchedulerFiters = () => {
  return (
    <>
      <div className="col-12 mt-3 px-2">
        <Panel header="Global Filters Configuration">
          <FilterReport />
        </Panel>
      </div>
    </>
  );
};
export default UserFormSchedulerFiters;
