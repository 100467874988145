import axios from 'axios';

import companyConfigConstants from '../company-config.constant';
import apiService from '../../../../services/api.service';
import { URL_CONSTANTS } from '../../../../constants/urlConstants';

export const getClientData = async client_id => {
  try {
    const response = await apiService.post(
      URL_CONSTANTS.COMPANY_CONFIG.getById,
      {
        client_id,
      }
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const getClientDataBySearch = async searchText => {
  try {
    const response = await apiService.get(
      `${URL_CONSTANTS.COMPANY_CONFIG.getBySearch}?search=${searchText}`
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const getAllClientData = async (first, limit) => {
  try {
    const response = await apiService.get(
      `${URL_CONSTANTS.COMPANY_CONFIG.getAll}?offset=${first}&limit=${limit}`
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const multiPartUpdateTenantData = async (values, isPatch) => {
  const formData = new FormData();

  if (values?.client_id != undefined)
    formData.append('client_id', values.client_id);
  if (values?.client_name != undefined)
    formData.append('client_name', values.client_name);
  if (values?.phone_number != undefined)
    formData.append('phone_number', values.phone_number);
  if (values?.email != undefined) formData.append('email', values.email);
  if (values?.fax != undefined) formData.append('fax', values.fax);
  if (values?.contact_name != undefined)
    formData.append('contact_name', values.contact_name);
  if (values?.contact_email != undefined)
    formData.append('contact_email', values.contact_email);
  if (values?.contact_phone_number != undefined)
    formData.append('contact_phone_number', values.contact_phone_number);
  if (values?.logo != undefined) formData.append('logo', values.logo);
  if (values?.principal_name != undefined)
    formData.append('principal_name', values.principal_name);
  if (values?.installer_number_lowes != undefined)
    formData.append('installer_number_lowes', values.installer_number_lowes);
  if (values?.measure_square_opted != undefined)
    formData.append('measure_square_opted', values.measure_square_opted);
  if (values?.renovator_name != undefined)
    formData.append('renovator_name', values.renovator_name);
  if (values?.renovator_certification_number != undefined)
    formData.append(
      'renovator_certification_number',
      values.renovator_certification_number
    );
  if (values?.firm_certification_number != undefined)
    formData.append(
      'firm_certification_number',
      values.firm_certification_number
    );
  if (values?.client_timezone != undefined)
    formData.append('client_timezone', values.client_timezone);
  if (values?.client_geolocation != undefined)
    formData.append(
      'client_geolocation',
      JSON.stringify(values.client_geolocation)
    );
  if (values?.fixed_renovator_details != undefined)
    formData.append('fixed_renovator_details', values.fixed_renovator_details);
  if (values?.lrrp_activity != undefined)
    formData.append('lrrp_activity', values.lrrp_activity);
  if (values?.default_lead_response_reason != undefined)
    formData.append(
      'default_lead_response_reason',
      values.default_lead_response_reason
    );
  if (values?.quickbooks_refresh_token != undefined)
    formData.append(
      'quickbooks_refresh_token',
      values.quickbooks_refresh_token
    );
  if (values?.routing_module != undefined)
    formData.append('routing_module', values.routing_module);
  if (values?.crm_module != undefined)
    formData.append('crm_module', values.crm_module);
  if (values?.warranty_module != undefined)
    formData.append('warranty_module', values.warranty_module);
  if (values?.floorsoft_opted != undefined)
    formData.append('floorsoft_opted', values.floorsoft_opted);
  if (values?.is_measuresquare != undefined)
    formData.append('is_measuresquare', values.is_measuresquare);
  if (values?.create_project_private_lead != undefined)
    formData.append(
      'create_project_private_lead',
      values.create_project_private_lead
    );
  if (values?.lrrpPayload != undefined)
    formData.append('lrrpPayload', JSON.stringify(values.lrrpPayload));
  if (
    values?.allocation_percentage != undefined &&
    values?.tenant_permissions != undefined
  )
    values.tenant_permissions[0].allocation_percentage =
      values?.tenant_permissions[0].included_permissions.includes(
        'CUSTOM_ALLOCATION'
      )
        ? values.allocation_percentage
        : 0;

  if (values?.tenant_permissions != undefined)
    formData.append(
      'tenant_permissions',
      JSON.stringify(values.tenant_permissions)
    );
  if (values?.related_po_columns != undefined)
    formData.append(
      'related_po_columns',
      JSON.stringify(values.related_po_columns)
    );
  if (values?.is_line_item_visible != undefined)
    formData.append('is_line_item_visible', values.is_line_item_visible);
  if (values?.due_days != undefined)
    formData.append('due_days', values.due_days);
  if (values?.city != undefined) formData.append('city', values.city);
  if (values?.state != undefined) formData.append('state', values.state);
  if (values?.zipcode != undefined) formData.append('zipcode', values.zipcode);
  if (values?.address1 != undefined)
    formData.append('address1', values.address1);
  if (values?.address2 != undefined)
    formData.append('address2', values.address2);
  if (values?.state_id != undefined)
    formData.append('state_id', values.state_id);
  if (values?.latitude != undefined)
    formData.append('latitude', values.latitude);
  if (values?.longitude != undefined)
    formData.append('longitude', values.longitude);
  if (values?.occupant_type != undefined)
    formData.append('occupant_type', values.occupant_type);
  if (values?.address_id != undefined)
    formData.append('address_id', values.address_id);
  if (values?.project_linkage != undefined)
    formData.append('project_linkage', JSON.stringify(values.project_linkage));
  if (values?.sales_commission_opted != undefined)
    formData.append('sales_commission_opted', values.sales_commission_opted);
  if (values?.skip_technician_assignment_cloning != undefined)
    formData.append(
      'skip_technician_assignment_cloning',
      values.skip_technician_assignment_cloning
    );
  if (values?.sales_commission_criteria != undefined)
    formData.append(
      'sales_commission_criteria',
      values.sales_commission_criteria
    );
  if (values?.hd_default_reason != undefined)
    formData.append('hd_default_reason', values.hd_default_reason);
  if (values?.hd_lrrp_configuration != undefined)
    formData.append(
      'hd_lrrp_configuration',
      JSON.stringify(values.hd_lrrp_configuration)
    );
  if ([0, 1, true, false].includes(values?.cx_scheduling_module))
    formData.append('cx_scheduling_module', values.cx_scheduling_module);
  if (!isNaN(values?.default_service_time))
    formData.append('default_service_time', values.default_service_time);
  if (!isNaN(values?.future_scheduling_days_limit))
    formData.append(
      'future_scheduling_days_limit',
      values.future_scheduling_days_limit
    );
  if (!isNaN(values?.auto_scheduling_wait_time))
    formData.append(
      'auto_scheduling_wait_time',
      values.auto_scheduling_wait_time
    );
  if (values?.job_identifier != undefined)
    formData.append('job_identifier', values.job_identifier);
  if (values?.app_job_status != undefined)
    formData.append('app_job_status', values.app_job_status);
  if (values?.app_job_type != undefined)
    formData.append('app_job_type', values.app_job_type);
  if (values?.app_job_category != undefined)
    formData.append('app_job_category', values.app_job_category);
  if (!isNaN(values?.consecutive_jobs_break_time))
    formData.append(
      'consecutive_jobs_break_time',
      values.consecutive_jobs_break_time
    );
  if ([0, 1, true, false].includes(values?.capture_live_location))
    formData.append('capture_live_location', values.capture_live_location);

  if (
    ['boolean', 'number'].includes(
      typeof values?.is_capture_job_distance_enabled
    )
  )
    formData.append(
      'is_capture_job_distance_enabled',
      values.is_capture_job_distance_enabled
    );
  if (values?.group_source_category_opted != undefined)
    formData.append(
      'group_source_category_opted',
      values.group_source_category_opted
    );
  if (values?.default_calender_view != undefined)
    formData.append('default_calender_view', values.default_calender_view);
  if (values?.fixed_top_bar != undefined)
    formData.append('fixed_top_bar', values.fixed_top_bar);
  if (values?.fixed_project_bar != undefined)
    formData.append('fixed_project_bar', values.fixed_project_bar);
  if (values?.is_customer_portal != undefined) {
    formData.append('is_customer_portal', values.is_customer_portal);
  }
  if (values?.scheduler_config != undefined) {
    formData.append(
      'scheduler_config',
      JSON.stringify(values?.scheduler_config)
    );
  }
  if (values?.chargeback_approval_module != undefined)
    formData.append(
      'chargeback_approval_module',
      values.chargeback_approval_module
    );
  values?.reset_password_in_days &&
    formData.append('reset_password_in_days', values.reset_password_in_days);
  values?.mfa_expire_in_days &&
    formData.append('mfa_expire_in_days', values.mfa_expire_in_days);
  values?.short_url_generate_in_days &&
    formData.append(
      'short_url_generate_in_days',
      values.short_url_generate_in_days
    );
  values.admin_email && formData.append('admin_email', values.admin_email);
  if (values?.shipping_receiving != undefined)
    formData.append('shipping_receiving', values.shipping_receiving);

  if (values?.highlight_store_notes != undefined)
    formData.append('highlight_store_notes', values.highlight_store_notes);
  if (values?.copy_total_sales != undefined)
    formData.append('copy_total_sales', values.copy_total_sales);
  if (values?.do_not_override_labor_cost != undefined)
    formData.append(
      'do_not_override_labor_cost',
      values?.do_not_override_labor_cost
    );
  if (
    values?.document_auto_pull_retry &&
    typeof values.document_auto_pull_retry === 'object'
  )
    formData.append(
      'document_auto_pull_retry',
      JSON.stringify(values.document_auto_pull_retry)
    );
  if (values?.show_line_items_on_documents != undefined) {
    formData.append(
      'show_line_items_on_documents',
      values.show_line_items_on_documents
    );
  }
  if (values?.epa_guardrails_workflow != undefined)
    formData.append('epa_guardrails_workflow', values.epa_guardrails_workflow);

  if (values?.project_type_config != undefined) {
    formData.append(
      'project_type_config',
      JSON.stringify(values.project_type_config)
    );
  }
  if (values?.personalized_emails != undefined) {
    formData.append('personalized_emails', values.personalized_emails);
  }

  if (values?.is_personalized_private_project != undefined)
    formData.append(
      'is_personalized_private_project',
      values.is_personalized_private_project
    );

  if (values?.personalized_private_project_number != undefined)
    formData.append(
      'personalized_private_project_number',
      values.personalized_private_project_number
    );

  if (values?.assign_technician_on_revenue_excluded_items != undefined) {
    formData.append(
      'assign_technician_on_revenue_excluded_items',
      values.assign_technician_on_revenue_excluded_items
    );
  }
  if (values?.send_tentative_schedule_note_to_source != undefined)
    formData.append(
      'send_tentative_schedule_note_to_source',
      values.send_tentative_schedule_note_to_source
    );

  let headers = {
    'Content-Type': 'multipart/form-data',
  };

  const response = await axios[isPatch ? 'patch' : 'put'](
    `${companyConfigConstants.baseUrl}${companyConfigConstants.tenantDataUpdateEndpoint()}`,
    formData,
    { headers }
  );
  localStorage.setItem(
    'client_details',
    JSON.stringify(
      isPatch ? response?.data?.data : response?.data?.data?.client
    )
  );
  return response;
};

export const getColorList = async () => {
  try {
    const response = await apiService.get(
      `/admin-config/color-config/getAll?limit=1000&offset=0`
    );
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const updateColorType = async value => {
  try {
    const response = await apiService.post(
      '/admin-config/map-project-type-color',
      value
    );
    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getProjectTypeColour = async () => {
  try {
    const typeRequest = await apiService.get(
      `/admin-config/project-type-colors`
    );

    return typeRequest;
  } catch (error) {
    console.error(error);
    return null;
  }
};
