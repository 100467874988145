import React, { useEffect, useState, memo, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import PFButton from '../../shared/PFPrime/PFButton.js';
import PFDialog from '../../shared/PFPrime/PFDialog.js';
import {
  getSourceOptions,
  getTemplateConfigData,
  deleteEmailTempConfig,
} from '../template.service.js';
import { TemplateConfigurationPopup } from './TemplateConfigurationPopup.jsx';
import PFDataTable from '../../shared/PFPrime/PFDataTable.js';
import { SelectedValuesList } from './SelectedValueList.jsx';
import { COVERAGE_BY } from '../../../constants/email-template.constant.js';
import { Button, Dialog } from 'primereact';
import {
  TEMP_CONFIG,
  ACTIONS,
} from '../../../constants/email-template.constant.js';
import {
  COVERAGE_TYPE_OPTIONS,
  formAction,
  COVERAGE_TYPES,
} from '../../../constants.js';
import TableLoader from '../../shared/Loader/TableLoader.js';
import PFInputText from '../../shared/PFPrime/PFInputText.js';
import { debounce } from 'lodash';
import { Toast } from 'primereact/toast';
export const TemplateConfiguration = memo(() => {
  const toast = useRef(null);
  const [pagination, setPagination] = useState({
    limit: 10,
    offset: 0,
    first: 0,
  });
  const [tempConfigTotalCount, setTempConfigTotalCount] = useState(0);
  const { types } = useSelector(state => state.types);
  const { projectAllStores } = useSelector(state => state.projectAllStores);
  const { workrooms } = useSelector(state => state.workrooms);
  const { districts } = useSelector(state => state.districts);
  const { categories } = useSelector(state => state.categories);
  const { projectStatusType } = useSelector(state => state.projectStatusType);
  const { sourceStatusType } = useSelector(state => state.sourceStatusType);
  const [templateLoader, setTemplateLoader] = useState(true);
  const [openTempConfigDialog, setOpenTempConfigDialog] = useState(false);
  const [tempConfigAction, setTempConfigAction] = useState(ACTIONS.CREATE);
  const [templateConfigId, setTemplateConfigId] = useState(0);
  const [emailTemplateConfig, setEmailTemplateConfig] = useState([]);
  const [selectedConfigList, setSelectedConfigList] = useState({});
  const [showSelectedItems, setShowSelectedItems] = useState(false);
  const [source, setSourceOptions] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [tempConfgObj, setTempConfgObj] = useState(0);
  useEffect(() => {
    fetchSourceOptions();
    getEmailTemplateConfigData();
  }, []);

  useEffect(() => {
    getEmailTemplateConfigData();
  }, [pagination]);

  const handleConfigClosePopup = () => {
    setOpenTempConfigDialog(false);
    setTempConfigAction(ACTIONS.CREATE);
    setShowDeletePopup(false);
  };
  const handleSelectedValueClosePopup = () => {
    setShowSelectedItems(false);
  };

  const handleTemplateConfigEdit = val => {
    setOpenTempConfigDialog(true);
    setTempConfigAction(formAction.EDIT);
    setTemplateConfigId(val?.email_template_config_id);
  };

  const handleOnDelete = async val => {
    try {
      await deleteEmailTempConfig(val?.email_template_config_id);
      toast.current.show({
        severity: 'success',
        detail: 'Template configuration deleted successfully.',
      });
      setShowDeletePopup(false);
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: error?.message,
      });
    } finally {
      reloadEmailTemplateConfig();
    }
  };

  const handleOnView = val => {
    setOpenTempConfigDialog(true);
    setTempConfigAction(ACTIONS.VIEW);
    setTemplateConfigId(val?.email_template_config_id);
  };

  const getEmailTemplateConfigData = async () => {
    try {
      setTemplateLoader(true);
      const resp = await getTemplateConfigData(pagination);
      resp.success
        ? (setEmailTemplateConfig(resp.emailTemplates),
          setTempConfigTotalCount(resp?.totalTemplatesCount))
        : (setEmailTemplateConfig([]), setTempConfigTotalCount(0));
    } catch (error) {
      setTempConfigTotalCount(0);
      setEmailTemplateConfig([]);
    } finally {
      setTemplateLoader(false);
    }
  };

  const reloadEmailTemplateConfig = () => {
    getEmailTemplateConfigData();
  };

  let tempConfigData = emailTemplateConfig?.map(val => {
    return {
      ...val,
      action_buttons: (
        <div className="flex flex-row gap-2">
          <i
            className="pi pi-eye cursor-pointer text-blue-500 hover:text-blue-700"
            onClick={e => handleOnView(val)}
          ></i>
          <i
            className="pi pi-pencil cursor-pointer text-blue-500 hover:text-blue-700"
            onClick={e => {
              handleTemplateConfigEdit(val);
            }}
          ></i>
          <i
            className="pi pi-trash cursor-pointer text-blue-500 hover:text-blue-700"
            onClick={e => {
              setTempConfgObj(val);
              setShowDeletePopup(true);
            }}
          ></i>
        </div>
      ),
    };
  });

  const fetchSourceOptions = async () => {
    try {
      const resp = await getSourceOptions();
      setSourceOptions(resp || []);
    } catch (error) {
      setSourceOptions([]);
    }
  };
  const renderHeader = () => {
    return (
      <div className="flex justify-content-between">
        <h3 className="text-900 pt-2 text-lg">Templates</h3>
        <div className="flex gap-2">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <PFInputText
              value={globalFilterValue}
              onChange={e => {
                setGlobalFilterValue(e.target.value);
                debouncedKeywordSearch(e.target.value);
              }}
              placeholder="Keyword Search"
            />
          </span>
          <PFButton
            label="Create Configuration"
            onClick={() => {
              setOpenTempConfigDialog(true);
            }}
            outlined
          />
        </div>
      </div>
    );
  };

  let intervalVal = '';
  const handleSearch = searchInputVal => {
    const query = searchInputVal?.trim();

    clearTimeout(intervalVal);

    if (!query) {
      setPagination(prevState => ({
        ...prevState,
        offset: 0,
        query: '',
      }));
      return;
    }
    if (query.length < 3) return;
    if (query.length >= 3) {
      setPagination(prevState => ({
        ...prevState,
        offset: 0,
        query: query,
      }));
    }
  };

  const debouncedKeywordSearch = useCallback(debounce(handleSearch, 1000), []);

  const handleSelectedValue = (event, params, field) => {
    const selectedValuesDetail = {};
    const buttonText = event.target.innerText;
    let filterValues = [];
    let selectedValue = '';
    let selectedLabel = '';
    let selectedTitle = '';

    if (field === TEMP_CONFIG.SOURCE_STATUS) {
      if (params.source_status_id) {
        filterValues = sourceStatusType.filter(item =>
          params.source_status_id.includes(item.status_id)
        );
      } else {
        filterValues = sourceStatusType;
      }

      selectedValue = 'status_id';
      selectedLabel = 'status';
      selectedTitle = TEMP_CONFIG.SOURCE_STATUS;
    }
    if (field === TEMP_CONFIG.SOURCE) {
      if (params.source_id) {
        filterValues = source.filter(item =>
          params.source_id.includes(item.source_system_id)
        );
      } else {
        filterValues = source;
      }

      selectedValue = 'source_system_id';
      selectedLabel = 'source_name';
      selectedTitle = TEMP_CONFIG.SOURCE;
    }

    if (field === TEMP_CONFIG.TYPES) {
      if (params.project_type_id) {
        filterValues = types.filter(item =>
          params.project_type_id.includes(item.project_type_id)
        );
      } else {
        filterValues = types;
      }

      selectedValue = 'project_type_id';
      selectedLabel = 'project_type';
      selectedTitle = TEMP_CONFIG.TYPES;
    }

    if (field === TEMP_CONFIG.CATEGORY) {
      if (params.project_category_id) {
        filterValues = categories.filter(item =>
          params.project_category_id.includes(item.project_category_id)
        );
      } else {
        filterValues = categories;
      }

      selectedValue = 'project_category_id';
      selectedLabel = 'category';
      selectedTitle = TEMP_CONFIG.CATEGORY;
    }

    if (field === TEMP_CONFIG.INTERNAL_STATUS) {
      if (params.internal_status_id) {
        filterValues = projectStatusType.filter(item =>
          params.internal_status_id.includes(item.status_id)
        );
      } else {
        filterValues = projectStatusType;
      }

      selectedValue = 'status_id';
      selectedLabel = 'status';
      selectedTitle = TEMP_CONFIG.INTERNAL_STATUS;
    }

    if (field === TEMP_CONFIG.COVERAGE_BY) {
      filterValues = COVERAGE_TYPE_OPTIONS.filter(
        item => params.coverage_by === item.label
      );
      selectedValue = 'value';
      selectedLabel = 'label';
      selectedTitle = TEMP_CONFIG.COVERAGE_BY;
    }

    if (field === TEMP_CONFIG.STORES) {
      if (params.store_id) {
        filterValues = projectAllStores.filter(item =>
          params.store_id.includes(item.store_id)
        );
      } else {
        filterValues = projectAllStores;
      }

      selectedValue = 'store_id';
      selectedLabel = 'store_name';
      selectedTitle = TEMP_CONFIG.STORES;
    }

    if (field === TEMP_CONFIG.WORKROOM) {
      if (params.workroom_id) {
        filterValues = workrooms.filter(item =>
          params.workroom_id.includes(item.type_id)
        );
      } else {
        filterValues = workrooms;
      }

      selectedValue = 'type_id';
      selectedLabel = 'label';
      selectedTitle = TEMP_CONFIG.WORKROOM;
    }

    if (field === TEMP_CONFIG.DISTRICT) {
      if (params.district_id) {
        filterValues = districts.filter(item =>
          params.district_id.includes(item.district)
        );
      } else {
        filterValues = districts;
      }

      selectedValue = '';
      selectedLabel = 'district';
      selectedTitle = TEMP_CONFIG.DISTRICT;
    }
    selectedValuesDetail.title = selectedTitle;
    selectedValuesDetail.selectedLabel = selectedLabel;
    selectedValuesDetail.selectedValue = selectedValue;
    selectedValuesDetail.selectedValues = filterValues;

    setSelectedConfigList(selectedValuesDetail);
    setShowSelectedItems(true);
  };

  const templateConfigColumns = [
    {
      field: 'action_buttons',
      header: '',
      body: params => params?.action_buttons,
    },
    {
      header: 'Link To',
      body: params => 'Project',
    },
    {
      header: 'Template',
      body: params => params?.template?.template_name,
    },
    {
      header: 'Source',
      body: params =>
        !params.source_id || params.source_id.length > 0 ? (
          <div className="cursor-pointer text-blue-500 underline whitespace-nowrap w-12rem">
            <Button
              label={`${
                !params.source_id ? source.length : params.source_id.length
              } Selected`}
              size="small"
              text
              onClick={event =>
                handleSelectedValue(event, params, TEMP_CONFIG.SOURCE)
              }
              className="p-0"
            />
          </div>
        ) : (
          '-'
        ),
    },
    {
      header: 'Source Status',
      body: params =>
        !params.source_status_id || params.source_status_id.length > 0 ? (
          <div className="cursor-pointer text-blue-500 underline whitespace-nowrap w-12rem">
            <Button
              label={`${
                !params.source_status_id
                  ? sourceStatusType.length
                  : params.source_status_id.length
              } Selected`}
              size="small"
              text
              onClick={event =>
                handleSelectedValue(event, params, TEMP_CONFIG.SOURCE_STATUS)
              }
              className="p-0"
            />
          </div>
        ) : (
          '-'
        ),
    },

    {
      header: 'Type',
      body: params =>
        !params.project_type_id || params.project_type_id.length > 0 ? (
          <div className="cursor-pointer text-blue-500 underline whitespace-nowrap w-12rem">
            <Button
              label={`${
                !params.project_type_id
                  ? types.length
                  : params.project_type_id.length
              } Selected`}
              size="small"
              text
              onClick={event =>
                handleSelectedValue(event, params, TEMP_CONFIG.TYPES)
              }
              className="p-0"
            />
          </div>
        ) : (
          '-'
        ),
    },

    {
      header: 'Category',
      body: params =>
        !params.project_category_id || params.project_category_id.length > 0 ? (
          <div className="cursor-pointer text-blue-500 underline whitespace-nowrap w-12rem">
            <Button
              label={`${
                !params.project_category_id
                  ? categories.length
                  : params.project_category_id.length
              } Selected`}
              size="small"
              text
              onClick={event =>
                handleSelectedValue(event, params, TEMP_CONFIG.CATEGORY)
              }
              className="p-0"
            />
          </div>
        ) : (
          '-'
        ),
    },

    {
      header: 'Project Status',
      body: params =>
        !params.internal_status_id || params.internal_status_id.length > 0 ? (
          <div className="cursor-pointer text-blue-500 underline whitespace-nowrap w-12rem">
            <Button
              label={`${
                !params.internal_status_id
                  ? projectStatusType.length
                  : params.internal_status_id.length
              } Selected`}
              size="small"
              text
              onClick={event =>
                handleSelectedValue(event, params, TEMP_CONFIG.INTERNAL_STATUS)
              }
              className="p-0"
            />
          </div>
        ) : (
          '-'
        ),
    },

    {
      header: 'Coverage By',
      body: params => (params?.coverage_by ? params?.coverage_by : '-'),
    },

    {
      header: 'Stores',
      body: params =>
        !params.store_id || params.store_id.length > 0 ? (
          <div className="cursor-pointer text-blue-500 underline whitespace-nowrap w-12rem">
            <Button
              label={`${
                !params.store_id
                  ? projectAllStores.length
                  : params.store_id.length
              } Selected`}
              size="small"
              text
              onClick={event =>
                handleSelectedValue(event, params, TEMP_CONFIG.STORES)
              }
              className="p-0"
            />
          </div>
        ) : (
          '-'
        ),
    },

    {
      header: 'Workroom',
      body: params =>
        !params.workroom_id &&
        params?.coverage_by === COVERAGE_TYPES.WORKROOM ? (
          <div className="cursor-pointer text-blue-500 underline whitespace-nowrap w-12rem">
            <Button
              label={`${workrooms.length} Selected`}
              size="small"
              text
              onClick={event =>
                handleSelectedValue(event, params, TEMP_CONFIG.WORKROOM)
              }
              className="p-0"
            />
          </div>
        ) : params?.workroom_id &&
          params?.workroom_id.length > 0 &&
          params?.coverage_by === COVERAGE_TYPES.WORKROOM ? (
          <div className="cursor-pointer text-blue-500 underline whitespace-nowrap w-12rem">
            <Button
              label={`${params.workroom_id.length} Selected`}
              size="small"
              text
              onClick={event =>
                handleSelectedValue(event, params, TEMP_CONFIG.WORKROOM)
              }
              className="p-0"
            />
          </div>
        ) : (
          '-'
        ),
    },

    {
      header: 'District',
      body: params =>
        !params.district_id &&
        params?.coverage_by === COVERAGE_TYPES.DISTRICT ? (
          <div className="cursor-pointer text-blue-500 underline whitespace-nowrap w-7rem">
            <Button
              label={`${districts.length} Selected`}
              size="small"
              text
              onClick={event =>
                handleSelectedValue(event, params, TEMP_CONFIG.DISTRICT)
              }
              className="p-0"
            />
          </div>
        ) : params?.district_id &&
          params?.district_id.length > 0 &&
          params?.coverage_by === COVERAGE_TYPES.DISTRICT ? (
          <div className="cursor-pointer text-blue-500 underline whitespace-nowrap w-7rem">
            <Button
              label={`${params?.district_id.length} Selected`}
              size="small"
              text
              onClick={event =>
                handleSelectedValue(event, params, TEMP_CONFIG.DISTRICT)
              }
              className="p-0"
            />
          </div>
        ) : (
          '-'
        ),
    },
  ];
  return (
    <>
      <Toast
        ref={toast}
        pt={{
          content: {
            className: 'text-left',
          },
        }}
      />
      {Object.keys(selectedConfigList).length > 0 ? (
        <SelectedValuesList
          isOpen={showSelectedItems}
          onClose={handleSelectedValueClosePopup}
          selectedConfigList={selectedConfigList}
        />
      ) : null}
      <div className="flex justify-content-between mt-2 mb-2">
        <h3 className="text-900 pt-2 text-lg">Template configuration</h3>
        <div className="flex gap-2">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <PFInputText
              value={globalFilterValue}
              onChange={e => {
                setGlobalFilterValue(e.target.value);
                debouncedKeywordSearch(e.target.value);
              }}
              placeholder="Keyword Search"
            />
          </span>
          <PFButton
            label="Create Configuration"
            onClick={() => {
              setOpenTempConfigDialog(true);
            }}
            outlined
          />
        </div>
      </div>
      {templateLoader ? (
        <div
          className="flex flex-column align-items-center justify-content-center h-12rem"
          style={{ gap: '1rem' }}
        >
          <TableLoader columnCount={9} noOfRow={10} mTopBottom={0} />
        </div>
      ) : (
        <>
          <PFDataTable
            data={tempConfigData}
            columns={templateConfigColumns}
            paginator={true}
            rows={pagination?.limit}
            rowsPerPageOptions={[5, 10, 25, 50]}
            lazy={true}
            totalRecords={tempConfigTotalCount || 0}
            first={pagination?.first}
            onPage={event => {
              setPagination(prev => ({
                ...prev,
                offset: event.first,
                limit: event.rows,
                first: event.first,
              }));
            }}
          />
        </>
      )}
      {openTempConfigDialog && (
        <TemplateConfigurationPopup
          toast={toast}
          openTempConfigDialog={openTempConfigDialog}
          handleConfigClosePopup={handleConfigClosePopup}
          tempConfigAction={tempConfigAction}
          templateConfigId={templateConfigId}
          emailTemplateConfig={emailTemplateConfig}
          reloadEmailTemplateConfig={reloadEmailTemplateConfig}
        />
      )}
      {
        <PFDialog
          show={showDeletePopup}
          hide={handleConfigClosePopup}
          header="Delete Template Config"
          className="w-4"
          footer={
            <div>
              <PFButton
                label="No"
                text
                outlined
                onClick={() => setShowDeletePopup(false)}
              />
              <PFButton
                label="Yes"
                onClick={() => {
                  handleOnDelete(tempConfgObj);
                }}
              />
            </div>
          }
          BodyComponent={
            <div className="flex">
              <p>
                <i className="pi pi-exclamation-triangle"></i> Are you sure you
                want to delete this template configuration?
              </p>
            </div>
          }
        />
      }
    </>
  );
});
