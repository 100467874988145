import React, { useRef, useState, useEffect, memo } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import PFDialog from '../../shared/PFPrime/PFDialog';
import PFButton from '../../shared/PFPrime/PFButton';
import TextEditor from '../../shared/TextEditor/TemplateTextEditor';
import { formAction } from '../../../constants';
import PFDropdown from '../../shared/PFPrime/PFDropdown';
import { Tooltip } from 'primereact/tooltip';
import { showFirst30Characters } from '../../../utils/Helpers';
import {
  sendTemplateData,
  getTemplateById,
  updateTemplateData,
} from '../template.service';
import {
  PLACEHOLDERS,
  ACTIONS,
} from '../../../constants/email-template.constant';
export const CreateTemplateDialog = memo(
  ({
    templateId,
    handleClosePopup,
    reloadEmailTemplates,
    isOpen,
    action,
    toast,
  }) => {
    const logged_in_user = localStorage.getItem('logged_in_user') || '';
    const textEditorRef = useRef(null);
    const subjectRef = useRef(null);
    const inputFileUpload = useRef(null);
    const [placeholder, setPlaceholder] = useState('');
    const [messageBody, setMessageBody] = useState('');
    const [files, setFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubjectFocused, setIsSubjectFocused] = useState(false);
    const validationSchema = Yup.object({
      templateName: Yup.string().required('Template Name is required'),
    });
    const [responseData, setResponseData] = useState(null);

    const formik = useFormik({
      initialValues: {
        templateName: responseData ? responseData?.template_name : '',
        placeholder: responseData ? responseData?.placeholder : '',
        subject: responseData ? responseData?.subject : '',
        messageBody: responseData ? responseData?.body : '',
        isFileUploaded: 0,
        isTextChanged: 0,
        isFileDeleted: 0,
      },
      validationSchema,
      enableReinitialize: true,
      onSubmit: async values => {
        const { templateName, subject } = values;
        setIsLoading(true);
        const formData = new FormData();
        formData.append('template_name', templateName);
        formData.append('body', messageBody);
        formData.append('subject', subject);
        files.forEach(file => {
          if (file.file instanceof File) {
            formData.append('files', file.file);
          } else {
            try {
              const blob = new Blob([file.file], {
                type: 'application/octet-stream',
              });
              const newFile = new File(
                [blob],
                file.name || 'default-file-name',
                { type: blob.type }
              );
              formData.append('files', newFile);
            } catch (error) {}
          }
        });
        try {
          if (action === formAction.EDIT) {
            formData.append('modified_by', logged_in_user);
            const response = await updateTemplateData(formData, templateId);
            toast.current.show({
              severity: 'success',
              detail: 'Email template has been updated successfully.',
            });
          } else {
            formData.append('created_by', logged_in_user);
            const response = await sendTemplateData(formData);
            toast.current.show({
              severity: 'success',
              detail: 'Email template has been created successfully.',
            });
          }
          reloadEmailTemplates();
          handleClosePopup();
        } catch (error) {
          toast.current.show({
            severity: 'error',
            detail: error?.message,
          });
          reloadEmailTemplates();
          handleClosePopup();
        } finally {
          setIsLoading(false);
        }
      },
    });

    const fetchTemplateData = async templateId => {
      try {
        const response = await getTemplateById(templateId);
        if (response?.success) {
          const { template_name, subject, body, attachments } =
            response?.emailTemplate;
          setResponseData(response?.emailTemplate);
          setFiles(
            attachments.map(file => ({
              name: file?.originalName,
              file: file?.file,
              url: file?.url,
            }))
          );

          if (textEditorRef.current) {
            textEditorRef.current.setValue(body);
          }
          setMessageBody(body);
          setPlaceholder(placeholder);
        }
      } catch (error) {}
    };

    useEffect(() => {
      if (action === formAction.EDIT || action === ACTIONS.VIEW) {
        fetchTemplateData(templateId);
      }
    }, [action]);

    const handleFileUpload = event => {
      if (event.target.files?.[0]) {
        setFiles([
          ...files,
          {
            name: event.target.files[0].name,
            file: event.target.files[0],
          },
        ]);
      }
      formik.setFieldValue('isFileUploaded', 1);
    };
    const handlePlaceholderChange = selectedOption => {
      const selectedPlaceholder = selectedOption?.name || '';

      if (subjectRef.current && isSubjectFocused) {
        const subjectValue = formik.values.subject;

        const cursorPosition = subjectRef.current.selectionStart;

        const newSubject =
          subjectValue.slice(0, cursorPosition) +
          `{{${selectedPlaceholder}}}` +
          subjectValue.slice(cursorPosition);

        formik.setFieldValue('subject', newSubject);

        setTimeout(() => {
          subjectRef.current.setSelectionRange(
            cursorPosition + selectedPlaceholder.length + 4,
            cursorPosition + selectedPlaceholder.length + 4
          );
        }, 0);
      }

      if (textEditorRef.current && !isSubjectFocused) {
        textEditorRef.current.insertPlaceholder(selectedPlaceholder);
      }
    };

    const isViewMode = action === ACTIONS.VIEW;

    const handleTextEditiorChange = value => {
      setMessageBody(value);
      formik.setFieldValue('isTextChanged', 1);
    };

    return (
      <div>
        <PFDialog
          show={isOpen}
          hide={handleClosePopup}
          header={
            action === ACTIONS.CREATE
              ? 'Create Template'
              : action === ACTIONS.EDIT
                ? 'Edit Template'
                : action === ACTIONS.VIEW
                  ? 'View Template'
                  : ''
          }
          className="w-9"
          footer={
            <>
              {!isLoading && (
                <PFButton onClick={e => handleClosePopup()} label="Cancel" />
              )}
              {!isViewMode && (
                <PFButton
                  disabled={!formik.dirty || isLoading}
                  onClick={formik.handleSubmit}
                  label={isLoading ? 'Saving...' : 'Save'}
                  icon={isLoading && 'pi pi-spin pi-spinner'}
                />
              )}
            </>
          }
          BodyComponent={
            <form onSubmit={formik.handleSubmit}>
              <div class="grid">
                <div class="col">
                  <label>
                    Template Name{' '}
                    <span className="text-red-500 font-bold">*</span>
                  </label>
                  <InputText
                    id="templateName"
                    required
                    name="templateName"
                    placeholder="Template Name"
                    className="p-inputtext w-full border-noround shadow-none h-3rem mt-1"
                    value={formik.values.templateName}
                    onChange={formik.handleChange}
                    disabled={isViewMode}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.templateName &&
                    formik.touched.templateName && (
                      <div className="text-red-600">
                        {formik.errors.templateName}
                      </div>
                    )}
                </div>
                <div class="col">
                  <div class="border-round-sm">
                    <label>Field Markers</label>
                    <PFDropdown
                      value={formik.values.placeholder}
                      options={PLACEHOLDERS.sort((a, b) =>
                        a.name.localeCompare(b.name)
                      )}
                      optionLabel="name"
                      onChange={(event, value) => {
                        formik.setFieldValue('placeholder', value?.name || '');
                        handlePlaceholderChange(value);
                      }}
                      onBlur={formik.handleBlur}
                      placeholder="Select Field"
                      className="w-full border-noround h-3rem mt-1"
                      appendTo="self"
                      disabled={isViewMode}
                    />
                  </div>
                </div>
              </div>

              <InputText
                ref={subjectRef}
                required
                name="subject"
                placeholder="Subject"
                className="p-inputtext w-full border-noround shadow-none"
                value={formik.values.subject}
                onChange={formik.handleChange}
                disabled={isViewMode}
                onFocus={() => setIsSubjectFocused(true)}
                onBlur={formik.handleBlur}
              />

              <div
                className="shadow-1"
                onFocus={() => setIsSubjectFocused(false)}
              >
                <TextEditor
                  ref={textEditorRef}
                  handleTextEditiorChange={handleTextEditiorChange}
                  value={messageBody}
                  readOnly={isViewMode}
                />
              </div>

              <div className="p-d-flex p-ai-center p-jc-center mt-2">
                <Tooltip
                  target=".attach-file-btn"
                  content="Attach File"
                  position="top"
                />
                <PFButton
                  icon="pi pi-paperclip"
                  className="p-button-outlined p-button-sm attach-file-btn"
                  disabled={isViewMode}
                  onClick={() => inputFileUpload.current.click()}
                  aria-label="Attach File"
                />
              </div>
              <input
                type="file"
                id="emailFileUpload"
                name="emailFileUpload"
                ref={inputFileUpload}
                style={{ display: 'none' }}
                onChange={handleFileUpload}
                disabled={isViewMode}
                onBlur={formik.handleBlur}
              />
              {files.length > 0 &&
                files.map((file, index) => (
                  <React.Fragment key={index}>
                    {file.name.length > 30 && (
                      <Tooltip
                        target={`.full_file_name_${index}`}
                        content={file.name}
                        position="right"
                      />
                    )}
                    <div
                      key={index}
                      className="shadow-1 p-2 flex align-items-center mt-1"
                    >
                      {action !== ACTIONS.VIEW ? (
                        <span
                          className={`mt-2 mr-2 text-sm full_file_name_${index} cursor-pointer`}
                        >
                          {showFirst30Characters(file.name)}
                        </span>
                      ) : (
                        <a
                          href={file.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={`text-blue-500 full_file_name_${index} cursor-pointer mr-2`}
                        >
                          {showFirst30Characters(file.name)}
                        </a>
                      )}
                      {action !== ACTIONS.VIEW && (
                        <i
                          onClick={() => {
                            formik.setFieldValue('isFileDeleted', 1);
                            setFiles(files.filter((_, i) => i !== index));
                          }}
                          className="pi pi-times text-sm ml-auto cursor-pointer"
                        ></i>
                      )}
                    </div>
                  </React.Fragment>
                ))}
            </form>
          }
        />
      </div>
    );
  }
);
