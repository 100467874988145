import { formateNumber } from './Quotes';
export const itemAmountcalculation = (
  values,
  setCalculatedMargin,
  setValues,
  retailerPercentage
) => {
  const quantity = formateNumber(values?.quantity || 0);
  const unitPrice = formateNumber(values?.unitPrice || 0);
  const totalCost = formateNumber((quantity || 0) * (unitPrice || 0));
  const retailEach = formateNumber(values?.retailEach || 0);
  const totalRetail = formateNumber((quantity || 0) * (retailEach || 0));
  const discountType = values?.discountType || 'Percentage';
  const discount = formateNumber(values?.discount || 0);
  let totalDiscount = formateNumber(discount || 0);
  if (discountType === 'Percentage') {
    totalDiscount = formateNumber((totalRetail || 0) * ((discount || 0) / 100));
  }
  const tax = formateNumber(values?.tax || 0);
  const taxAmount = formateNumber(
    ((totalRetail || 0) - (totalDiscount || 0)) * ((tax || 0) / 100)
  );
  const totalPrice = formateNumber(
    (totalRetail || 0) - (totalDiscount || 0) + (taxAmount || 0)
  );

  const retailerRate = formateNumber(retailerPercentage || 0);
  let totalRevenue = formateNumber(totalPrice || 0);
  if (retailerRate > 0) {
    totalRevenue = formateNumber(
      ((totalPrice || 0) * (retailerRate || 0)) / 100
    );
  }
  const marginCal =
    (((totalRevenue || 0) - (totalCost || 0)) /
      (totalRevenue > 0 ? totalRevenue : 1)) *
    100;

  const margin = formateNumber(marginCal > 0 ? marginCal : 0);

  setCalculatedMargin(margin);
  setValues(pre => ({
    ...pre,
    totalCost: totalCost,
    totalRetail: totalRetail,
    totalDiscount: totalDiscount,
    taxAmount: taxAmount,
    totalPrice: totalPrice,
    margin: margin,
    totalRevenue: totalRevenue,
  }));
};

export const onQtyBlur = (value, values, setValues, retailerPercentage) => {
  const quantity = formateNumber(value || 0);
  let retailerRate = formateNumber(retailerPercentage || 0);
  retailerRate = retailerRate === 0 ? 100 : retailerRate;
  if (quantity !== values?.quantity) {
    const unitPrice = formateNumber(values?.unitPrice || 0);
    const totalCost = formateNumber(unitPrice * quantity);
    let retailEach = formateNumber(values?.retailEach || 0);

    const totalRetail = formateNumber(retailEach * quantity);
    const discountType = values?.discountType || 'Amount';
    const discount = formateNumber(values?.discount || 0);
    let totalDiscount = formateNumber(discount);
    if (discountType === 'Percentage') {
      totalDiscount = formateNumber(totalRetail * (discount / 100));
    }
    const tax = formateNumber(values?.tax || 0);
    const taxAmount = formateNumber(totalRetail * (tax / 100));

    const totalRevenue = formateNumber(totalRetail * (retailerRate / 100));
    const totalPrice = formateNumber(totalRetail - totalDiscount + taxAmount);
    const margin = formateNumber(
      ((totalRevenue - totalCost) / totalRevenue) * 100
    );
    setValues(pre => ({
      ...pre,
      quantity,
      unitPrice,
      totalCost,
      retailEach,
      totalRetail,
      discountType,
      discount,
      totalDiscount,
      tax,
      taxAmount,
      totalRevenue,
      totalPrice,
      margin,
    }));
  }
};

export const onBlurUnitPrice = (
  value,
  values,
  setValues,
  retailerPercentage
) => {
  const unitPrice = formateNumber(value || 0);
  let retailerRate = formateNumber(retailerPercentage || 0);
  retailerRate = retailerRate === 0 ? 100 : retailerRate;
  if (unitPrice !== values?.unitPrice) {
    const quantity = formateNumber(values?.quantity || 0);
    const unitPrice = formateNumber(value || 0);
    const totalCost = formateNumber(unitPrice * quantity);
    const totalRevenue = formateNumber(values?.totalRevenue || 0);
    const margin = formateNumber(
      ((totalRevenue - totalCost) / totalRevenue) * 100
    );

    let retailEach = formateNumber(values?.retailEach || 0);

    const totalRetail = formateNumber(values?.totalRetail || 0);
    const discountType = values?.discountType || 'Amount';
    const discount = formateNumber(values?.discount || 0);
    const totalDiscount = formateNumber(values?.totalDiscount || 0);

    const tax = formateNumber(values?.tax || 0);
    const taxAmount = formateNumber(values?.taxAmount || 0);

    const totalPrice = formateNumber(totalRetail - totalDiscount + taxAmount);

    setValues(pre => ({
      ...pre,
      quantity,
      unitPrice,
      totalCost,
      retailEach,
      totalRetail,
      discountType,
      discount,
      totalDiscount,
      tax,
      taxAmount,
      totalRevenue,
      totalPrice,
      margin,
    }));
  }
};

export const onBlurRetailEach = (
  value,
  values,
  setValues,
  retailerPercentage
) => {
  const retailEach = formateNumber(value || 0);
  let retailerRate = formateNumber(retailerPercentage || 0);
  retailerRate = retailerRate === 0 ? 100 : retailerRate;
  if (retailEach !== values?.retailEach) {
    const quantity = formateNumber(values?.quantity || 0);
    const unitPrice = formateNumber(values?.unitPrice || 0);
    const totalCost = formateNumber(values?.totalCost || 0);

    const totalRetail = formateNumber(retailEach * quantity);

    const discountType = values?.discountType || 'Amount';
    const discount = formateNumber(values?.discount || 0);
    let totalDiscount = formateNumber(discount || 0);
    if (discountType === 'Percentage') {
      totalDiscount = formateNumber(
        (totalRetail || 0) * ((discount || 0) / 100)
      );
    }

    const tax = formateNumber(values?.tax || 0);
    const taxAmount = formateNumber((totalRetail || 0) * ((tax || 0) / 100));

    const totalRevenue = formateNumber(totalRetail * (retailerRate / 100));
    const totalPrice = formateNumber(totalRetail - totalDiscount + taxAmount);
    const margin = formateNumber(
      ((totalRevenue - totalCost) / totalRevenue) * 100
    );

    setValues(pre => ({
      ...pre,
      quantity,
      unitPrice,
      totalCost,
      retailEach,
      totalRetail,
      discountType,
      discount,
      totalDiscount,
      tax,
      taxAmount,
      totalRevenue,
      totalPrice,
      margin,
    }));
  }
};

export const onBlurMargin = (value, values, setValues, retailerPercentage) => {
  const margin = formateNumber(value || 0);
  let retailerRate = formateNumber(retailerPercentage || 0);
  retailerRate = retailerRate === 0 ? 100 : retailerRate;
  if (margin !== values?.margin) {
    const quantity = formateNumber(values?.quantity || 0);
    const unitPrice = formateNumber(values?.unitPrice || 0);
    const totalCost = formateNumber(unitPrice * quantity);
    let retailEach = formateNumber(0);
    let totalRetail = formateNumber(0);
    const discountType = values?.discountType || 'Amount';
    const discount = formateNumber(values?.discount || 0);
    let totalDiscount = formateNumber(discount || 0);
    const tax = formateNumber(values?.tax || 0);
    let taxAmount = formateNumber(0);
    let totalRevenue = formateNumber(totalCost / (1 - margin / 100));
    totalRetail = formateNumber(totalRevenue / (retailerRate / 100));
    retailEach = formateNumber(totalRetail / quantity);
    let totalPrice = formateNumber(0);
    if (discountType === 'Percentage') {
      totalDiscount = formateNumber(totalRetail * (discount / 100));
    }

    taxAmount = formateNumber(totalRetail * (tax / 100));

    totalPrice = formateNumber(totalRetail - totalDiscount + taxAmount);

    retailEach = formateNumber(totalRetail / quantity);
    taxAmount = formateNumber(totalRetail * (tax / 100));
    totalPrice = formateNumber(totalRevenue / (retailerRate / 100) + taxAmount);

    setValues(pre => ({
      ...pre,
      quantity,
      unitPrice,
      totalCost,
      retailEach,
      totalRetail,
      discountType,
      discount,
      totalDiscount,
      tax,
      taxAmount,
      totalRevenue,
      totalPrice,
      margin,
    }));
  }
};

export const onBlurDiscountType = (
  value,
  values,
  setValues,
  retailerPercentage
) => {
  const discountType = value || 'Amount';
  let retailerRate = formateNumber(retailerPercentage || 0);
  retailerRate = retailerRate === 0 ? 100 : retailerRate;
  if (discountType !== values?.discountType) {
    const quantity = formateNumber(values?.quantity || 0);
    const unitPrice = formateNumber(values?.unitPrice || 0);
    const totalCost = formateNumber(unitPrice * quantity);
    let retailEach = formateNumber(values?.retailEach || 0);
    let discount = formateNumber(values?.discount || 0);

    const totalRetail = formateNumber(values?.totalRetail || 0);

    let totalDiscount = formateNumber(discount || 0);
    if (discountType === 'Percentage') {
      totalDiscount = formateNumber(totalRetail * (discount / 100));
    }
    const tax = formateNumber(values?.tax || 0);
    const taxAmount = formateNumber(totalRetail * (tax / 100));
    const totalRevenue = formateNumber(values?.totalRevenue || 0);
    const totalPrice = formateNumber(totalRetail - totalDiscount + taxAmount);
    const margin = formateNumber(values?.margin || 0);

    setValues(pre => ({
      ...pre,
      quantity,
      unitPrice,
      totalCost,
      retailEach,
      totalRetail,
      discountType,
      discount,
      totalDiscount,
      tax,
      taxAmount,
      totalRevenue,
      totalPrice,
      margin,
    }));
  }
};

export const onBlurDiscount = (
  value,
  values,
  setValues,
  retailerPercentage
) => {
  const discount = formateNumber(value || 0);
  let retailerRate = formateNumber(retailerPercentage || 0);
  retailerRate = retailerRate === 0 ? 100 : retailerRate;
  if (discount !== values?.discount) {
    const quantity = formateNumber(values?.quantity || 0);
    const unitPrice = formateNumber(values?.unitPrice || 0);
    const totalCost = formateNumber(unitPrice * quantity);
    let retailEach = formateNumber(values?.retailEach || 0);

    const totalRetail = formateNumber(retailEach * quantity);
    const discountType = values?.discountType || 'Amount';

    let totalDiscount = formateNumber(discount || 0);
    if (discountType === 'Percentage') {
      totalDiscount = formateNumber(totalRetail * (discount / 100));
    }
    const tax = formateNumber(values?.tax || 0);
    const taxAmount = formateNumber(totalRetail * (tax / 100));
    const totalRevenue = formateNumber(values?.totalRevenue || 0);
    const totalPrice = formateNumber(totalRetail - totalDiscount + taxAmount);
    const margin = formateNumber(values?.margin || 0);

    setValues(pre => ({
      ...pre,
      quantity,
      unitPrice,
      totalCost,
      retailEach,
      totalRetail,
      discountType,
      discount,
      totalDiscount,
      tax,
      taxAmount,
      totalRevenue,
      totalPrice,
      margin,
    }));
  }
};

export const onBlurTax = (value, values, setValues, retailerPercentage) => {
  const tax = formateNumber(value || 0);
  let retailerRate = formateNumber(retailerPercentage || 0);
  retailerRate = retailerRate === 0 ? 100 : retailerRate;
  if (tax !== values?.tax) {
    const quantity = formateNumber(values?.quantity || 0);
    const unitPrice = formateNumber(values?.unitPrice || 0);
    const totalCost = formateNumber(values?.totalCost || 0);
    let retailEach = formateNumber(values?.retailEach || 0);

    const totalRetail = formateNumber(values?.totalRetail || 0);
    const discountType = values?.discountType || 'Amount';
    const discount = formateNumber(values?.discount || 0);
    const totalDiscount = formateNumber(values?.totalDiscount || 0);

    const taxAmount = formateNumber(totalRetail * (tax / 100));
    const margin = formateNumber(values?.margin);
    const totalRevenue = formateNumber(values?.totalRevenue);
    const totalPrice = formateNumber(totalRetail - totalDiscount + taxAmount);

    setValues(pre => ({
      ...pre,
      quantity,
      unitPrice,
      totalCost,
      retailEach,
      totalRetail,
      discountType,
      discount,
      totalDiscount,
      tax,
      taxAmount,
      totalRevenue,
      totalPrice,
      margin,
    }));
  }
};
