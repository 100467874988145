import axios from 'axios';
import { Tooltip } from 'primereact/tooltip';
import { Tag } from 'primereact/tag';
import moment from 'moment';

import {
  formatDate,
  formatDateTime,
  formatDateTimeMDY,
  isCrmEnabled,
} from '../../../../utils/Helpers';
import { CONFIG } from '../../../../constants';
import PFButton from '../../../shared/PFPrime/PFButton';

export const projectsGridColumns = ({
  opportunityNumber,
  quoteNumber,
  customerId,
  opportunityId,
  loading,
}) => {
  quoteNumber = quoteNumber ? true : false;
  opportunityNumber = opportunityNumber ? true : false;
  let main = [
    {
      field: '',
      header: 'Actions',
      sortable: false,
      filter: false,
      ...(!loading
        ? {
            body: (rowData, option) => (
              <div className="flex w-4rem">
                <PFButton
                  icon="pi pi-eye"
                  outlined
                  text
                  className="text-white border-none"
                  severity="primary"
                  aria-label="View"
                  onClick={() => {
                    if (rowData?.project_crm?.opportunity_id) {
                      return window.open(
                        `/crm/${customerId}/${rowData?.project_crm?.opportunity_id}/project/view/` +
                          rowData.project_id,
                        '_blank'
                      );
                    } else {
                      return window.open(
                        `/project/view/` + rowData.project_id,
                        '_blank'
                      );
                    }
                  }}
                />

                <PFButton
                  icon="pi pi-pencil"
                  outlined
                  text
                  className="text-white border-none"
                  severity="primary"
                  aria-label="Edit"
                  onClick={() => {
                    if (rowData?.project_crm?.opportunity_id) {
                      return window.open(
                        `/crm/${customerId}/${rowData?.project_crm?.opportunity_id}/project/edit/` +
                          rowData.project_id,
                        '_blank'
                      );
                    } else {
                      return window.open(
                        `/project/edit/` + rowData.project_id,
                        '_blank'
                      );
                    }
                  }}
                />
              </div>
            ),
          }
        : {}),
    },
    {
      field: 'project_number',
      header: 'Project#',
      sortable: true,
      filter: false,
    },
    ...(isCrmEnabled() && opportunityNumber
      ? [
          {
            field: 'project_crm.opportunity_number',
            header: 'Lead Number',
            sortable: true,
            filter: false,
          },
        ]
      : []),
    ...(isCrmEnabled() && quoteNumber
      ? [
          {
            field: 'project_crm.quote_number',
            header: 'Quote Number',
            sortable: true,
            filter: false,
          },
        ]
      : []),
    {
      field: 'po_number',
      header: 'PO#',
      sortable: true,
      filter: false,
    },
    {
      field: 'store.store_number',
      header: 'Store#',
      sortable: true,
      filter: false,
    },
    {
      field: 'project_installation_address',
      header: 'Installation Address',
      sortable: true,
      filter: false,
    },
    {
      field: 'project_type.project_type',
      header: 'Type',
      sortable: true,
      filter: false,
    },
    {
      field: 'project_category.category',
      header: 'Category',
      sortable: true,
      filter: false,
    },
    {
      field: 'status.status',
      header: 'Status',
      sortable: true,
      filter: false,
    },
    {
      field: 'rts_follow_up_date',
      header: 'RTS Follow-Up',
      sortable: true,
      filter: false,
      ...(!loading
        ? {
            body: data =>
              data.rts_follow_up_date
                ? formatDateTimeMDY(data.rts_follow_up_date)
                : null,
          }
        : {}),
    },
    {
      field: 'date_sold',
      header: 'Date Sold',
      sortable: true,
      filter: false,
      ...(!loading
        ? {
            body: data => formatDateTimeMDY(data?.date_sold),
          }
        : {}),
    },

    {
      field: 'date_scheduled_start',
      header: 'Schedule Start',
      sortable: true,
      filter: false,
      ...(!loading
        ? {
            body: data =>
              data.date_scheduled_start
                ? formatDateTimeMDY(data.date_scheduled_start)
                : null,
          }
        : {}),
    },
    {
      field: 'date_scheduled_end',
      header: 'Schedule End',
      sortable: true,
      filter: false,
      ...(!loading
        ? {
            body: data =>
              data.date_scheduled_end
                ? formatDateTime(data.date_scheduled_end)
                : null,
          }
        : {}),
    },
    {
      field: 'completion_date',
      header: 'Completion On',
      sortable: true,
      filter: false,
      ...(!loading
        ? {
            body: data => formatDateTime(data?.completion_date, false),
          }
        : {}),
    },
  ];

  return main;
};

export const gridProjectsData = async customerId => {
  return await axios.get(`${CONFIG?.API_BASE_URL}/customers/${customerId}`);
};
