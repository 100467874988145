import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { WrapperPRDataTable } from '../shared/GenericDataTable/GenericDataTable';
import { addInventoryByWarehouse } from '../ProjectManagement/Inventory/reducers/WarehouseSlice';
import unitConverter from '../ProjectManagement/Inventory/helper';
import usePFDataTable from '../../hooks/PFDatatable';

import { fetchInventoryByWarehouse } from './Warehouse.service';

const WarehouseStockView = ({ wareHouseId }) => {
  const [warehouseProductsList, setWarehouseProductsList] = useState([]);
  const [loading, setLoading] = useState(false);

  const {
    layout: StockTable,
    data: stockTableData,
    columns: tableColumns,
    totalRecords,
    setDefaultTableProps,
  } = usePFDataTable();
  const columns = [
    {
      field: 'binCode',
      header: 'Bin Code',
      filter: true,
      showFilterOperator: false,
      showAddButton: false,
    },
    {
      field: 'binName',
      header: 'Bin Name',
      filter: true,
      showFilterOperator: false,
      showAddButton: false,
    },
    {
      field: 'productNumber',
      header: 'Item Number',
      filter: true,
      showFilterOperator: false,
      showAddButton: false,
    },
    {
      field: 'productName',
      header: 'Item Name',
      filter: true,
      showFilterOperator: false,
      showAddButton: false,
    },
    { field: 'availableQuantity', header: 'On Hand' },
    { field: 'actualQuantity', header: 'Available' },
    { field: 'allottedQuantity', header: 'Allocated' },
    { field: 'damagedQuantity', header: 'Damaged' },
    { field: 'lostQuantity', header: 'Lost' },
    { field: 'soldQuantity', header: 'Sold' },
    { field: 'deliveredQuantity', header: 'Delivered' },
  ];
  const dispatch = useDispatch();
  let { inventoryByWarehouse } = useSelector(
    state => state.inventoryManagment?.Warehouse
  );
  useEffect(() => {
    if (inventoryByWarehouse && !inventoryByWarehouse[wareHouseId]) {
      fetchInventory(wareHouseId);
    } else {
      setWarehouseProductsList(inventoryByWarehouse[wareHouseId]);
    }
  }, []);

  const fetchInventory = wareHouseId => {
    setLoading(true);
    fetchInventoryByWarehouse(wareHouseId)
      .then(response => {
        const result = response?.map(item => {
          let units = item?.units || [];

          units = _.cloneDeep(units);

          units?.map(item => {
            item['parentId'] = item.pId;
            item['fractionAble'] = false;
          });

          const baseUnitId =
            item?.units?.find(unit => unit.isLowest === 1)?.unitId || null;
          item.actualQuantity =
            item.actualQuantity !== 0 && units.length > 0 && baseUnitId
              ? unitConverter(item?.actualQuantity, units)
              : item.actualQuantity;

          item.allottedQuantity =
            item.allottedQuantity !== 0 && units.length > 0 && baseUnitId
              ? unitConverter(item?.allottedQuantity, units)
              : item.allottedQuantity;
          item.availableQuantity =
            item.availableQuantity !== 0 && units.length > 0 && baseUnitId
              ? unitConverter(item?.availableQuantity, units)
              : item.availableQuantity;
          item.damagedQuantity =
            item.damagedQuantity !== 0 && units.length > 0 && baseUnitId
              ? unitConverter(item?.damagedQuantity, units)
              : item.damagedQuantity;
          item.deliveredQuantity =
            item.deliveredQuantity !== 0 && units.length > 0 && baseUnitId
              ? unitConverter(item?.deliveredQuantity, units)
              : item.deliveredQuantity;
          item.lostQuantity =
            item.lostQuantity !== 0 && units.length > 0 && baseUnitId
              ? unitConverter(item?.lostQuantity, units)
              : item.lostQuantity;
          item.soldQuantity =
            item.soldQuantity !== 0 && units.length > 0 && baseUnitId
              ? unitConverter(item?.soldQuantity, units)
              : item.soldQuantity;
          return item;
        });

        setLoading(false);
        stockTableData(result);
        totalRecords(result?.length || 0);
        setWarehouseProductsList(result);
        dispatch(
          addInventoryByWarehouse({
            wareHouseId,
            inventoryByWarehouse: response,
          })
        );
      })
      .catch(error => {
        console.error(error);
      });
  };

  const rowClassName = rowData => {
    if (!rowData?.binName) {
      return 'bg-blue-100';
    }
  };

  useEffect(() => {
    wareHouseId && fetchInventory(wareHouseId);
  }, [wareHouseId]);

  useEffect(() => {
    setDefaultTableProps(preValue => ({
      ...preValue,
      loading: loading,
      rowClassName: rowClassName,
      rows: 25,
      lazy: false,
    }));
    tableColumns(columns);
  }, []);

  return (
    <>
      <div className="grid m-1">
        <div className="col-12 md:col-12 lg:col-12">
          <div className="col-12 md:col-12 lg:col-12 p-input-icon-right mb-3 m-0 p-0 mt-2">
            <i
              className="pi pi-refresh ml-1"
              onClick={() => fetchInventory(wareHouseId)}
              style={{ cursor: 'pointer' }}
            ></i>
          </div>
          <div
            className="mt-1"
            style={{ height: 'calc(100vh - 220px)', overflow: 'auto' }}
          >
            <StockTable />
          </div>
        </div>
      </div>
    </>
  );
};

export default WarehouseStockView;
