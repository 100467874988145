import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import moment from 'moment';
import { PanelMenu } from 'primereact/panelmenu';
import { Badge } from 'primereact/badge';

import PFInputText from '../../../shared/PFPrime/PFInputText';
import PFInputNumber from '../../../shared/PFPrime/PFInputNumber';
import useQuoteStatus from '../../../../hooks/useQuoteStatus';
import PFDropdown from '../../../shared/PFPrime/PFDropdown';
import PFCalendar from '../../../shared/PFPrime/PFCalendar';
import { mVendorList } from '../services/item.service';

const ItemResult = ({
  aggregate,
  setExpiryDate,
  setStatusId,
  expiryDate,
  statusId,
  quoteId,
  quoteCreateAt,
  retailerPercentage,
  setRetailerPercentage,
  mVendorId,
  setMVendorId,
  isForm,
  setRetailRate,
}) => {
  const { quoteStatus } = useQuoteStatus();
  const { customerId, opportunityId, mode } = useParams();
  const [selectedQuoteStatus, setSelectedQuoteStatus] = useState('');
  const [validUntil, setValidUntil] = useState('');
  const [mVendorOptions, setMVendorOptions] = useState([]);
  const mVendorListApiCall = async () => {
    const list = await mVendorList();

    if (list?.data?.status) {
      setMVendorOptions(list?.data?.data || []);
    }
  };

  useEffect(() => {
    mVendorListApiCall();
  }, []);

  const onChangeMvendorId = value => {
    if (value) {
      const selectedMvendor = mVendorOptions?.find(
        i => i.vendor_sku_id === value
      );

      selectedMvendor?.multiplier &&
        setRetailerPercentage(selectedMvendor?.multiplier);
      setRetailRate(selectedMvendor?.multiplier || 0);
    }
  };

  const onRetailerPercentage = (name, value) => {
    setRetailerPercentage(value);
  };

  const onQuoteStatus = (name, value) => {
    setSelectedQuoteStatus(value);
  };

  useEffect(() => {
    if (mode === 'create') {
      if (quoteStatus?.length > 0) {
        const newStatusId = quoteStatus.find(
          item => item.status.toLowerCase() === 'new'
        )?.status_id;
        setSelectedQuoteStatus(newStatusId);
      }
    }
  }, [quoteStatus]);

  const onValidUntil = e => {
    setValidUntil(e.value);
  };

  const modifiedDate = (days = 0) => {
    const extend = days * 24;
    const currentDate = new Date();
    const newDate = new Date(currentDate.getTime() + extend * 60 * 60 * 1000);
    return newDate;
  };

  useEffect(() => {
    setExpiryDate(validUntil);
  }, [validUntil]);

  useEffect(() => {
    selectedQuoteStatus && setStatusId(selectedQuoteStatus);
  }, [selectedQuoteStatus]);

  useEffect(() => {
    if (expiryDate && !validUntil) {
      const date = new Date(expiryDate);
      setValidUntil(date);
    }
  }, [expiryDate]);

  useEffect(() => {
    if (statusId && !selectedQuoteStatus) {
      setSelectedQuoteStatus(statusId);
    }
  }, [statusId]);

  const itemRenderer = (item, options) => (
    <a
      className="flex align-items-center px-3 py-2 cursor-pointer"
      onClick={options.onClick}
    >
      <span className={`${item.icon} text-primary`} />
      <span className={`mx-2 ${item.items && 'font-semibold'}`}>
        {item.label}
      </span>
      {item.badge && <Badge className="ml-auto" value={item.badge} />}
      {item.shortcut && (
        <span className="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">
          {item.shortcut}
        </span>
      )}
    </a>
  );

  const pitems = [
    {
      label: 'Labor Total',
      badge: `$${parseFloat(aggregate?.totalLaborRetail || 0).toFixed(2)}`,
      expanded: false,
      template: itemRenderer,
      items: [
        {
          template: () => {
            return (
              <div className="col-12 md:col-12 lg:col-12">
                <div className="w-full p-1 mt-2">
                  <span className="p-float-label">
                    <PFInputNumber
                      inputId="totalLaborRetail"
                      name="totalLaborRetail"
                      mode={'currency'}
                      currency="USD"
                      minFractionDigits={2}
                      value={aggregate?.totalLaborRetail || '0'}
                      version={2}
                      className="w-full"
                      disabled={true}
                    />
                    <label htmlFor="totalLaborRetail">Total Retail</label>
                  </span>
                </div>
                <div className="w-full p-1 mt-2">
                  <span className="p-float-label">
                    <PFInputNumber
                      inputId="totalLaborRevenue"
                      name="totalLaborRevenue"
                      mode={'currency'}
                      currency="USD"
                      minFractionDigits={2}
                      value={aggregate?.totalLaborRevenue || '0'}
                      version={2}
                      className="w-full"
                      disabled={true}
                    />
                    <label htmlFor="totalLaborRevenue">Total Revenue</label>
                  </span>
                </div>
                <div className="w-full p-1 mt-2">
                  <span className="p-float-label">
                    <PFInputNumber
                      inputId="totalLaborCost"
                      name="totalLaborCost"
                      mode={'currency'}
                      currency="USD"
                      minFractionDigits={2}
                      value={aggregate?.totalLaborCost || '0'}
                      version={2}
                      className="w-full"
                      disabled={true}
                    />
                    <label htmlFor="totalLaborCost">Total Cost</label>
                  </span>
                </div>
                <div className="w-full p-1 mt-2">
                  <span className="p-float-label">
                    <PFInputNumber
                      inputId="totalLaborDiscount"
                      name="totalLaborDiscount"
                      mode={'currency'}
                      currency="USD"
                      minFractionDigits={2}
                      value={aggregate?.totalLaborDiscount || '0'}
                      version={2}
                      className="w-full"
                      disabled={true}
                    />
                    <label htmlFor="totalLaborDiscount">Total Discount</label>
                  </span>
                </div>
                <div className="w-full p-1 mt-2">
                  <span className="p-float-label">
                    <PFInputNumber
                      inputId="totalLabortotaltax"
                      name="totalLabortotaltax"
                      mode={'currency'}
                      currency="USD"
                      minFractionDigits={2}
                      value={aggregate?.totalLabortotaltax || '0'}
                      version={2}
                      className="w-full"
                      disabled={true}
                    />
                    <label htmlFor="totalLabortotaltax">Total Tax</label>
                  </span>
                </div>
              </div>
            );
          },
        },
      ],
    },
    {
      label: 'Product Total',
      badge: `$${parseFloat(aggregate?.totalProductRetail || 0).toFixed(2)}`,
      expanded: false,
      template: itemRenderer,
      items: [
        {
          template: () => {
            return (
              <div className="col-12 md:col-12 lg:col-12">
                <div className="w-full p-1 mt-2">
                  <span className="p-float-label">
                    <PFInputNumber
                      inputId="totalProductRetail"
                      name="totalProductRetail"
                      mode={'currency'}
                      currency="USD"
                      minFractionDigits={2}
                      value={aggregate?.totalProductRetail || '0'}
                      version={2}
                      className="w-full"
                      disabled={true}
                    />
                    <label htmlFor="totalProductRetail">Total Retail</label>
                  </span>
                </div>
                <div className="w-full p-1 mt-2">
                  <span className="p-float-label">
                    <PFInputNumber
                      inputId="totalProductRevenue"
                      name="totalProductRevenue"
                      mode={'currency'}
                      currency="USD"
                      minFractionDigits={2}
                      value={aggregate?.totalProductRevenue || '0'}
                      version={2}
                      className="w-full"
                      disabled={true}
                    />
                    <label htmlFor="totalProductRevenue">Total Revenue</label>
                  </span>
                </div>

                <div className="w-full p-1">
                  <span className="p-float-label">
                    <PFInputNumber
                      inputId="totalProductCost"
                      name="totalProductCost"
                      mode={'currency'}
                      currency="USD"
                      minFractionDigits={2}
                      value={aggregate?.totalProductCost || '0'}
                      version={2}
                      className="w-full"
                      disabled={true}
                    />
                    <label htmlFor="totalProductCost">Total Cost</label>
                  </span>
                </div>

                <div className="w-full p-1 mt-2">
                  <span className="p-float-label">
                    <PFInputNumber
                      inputId="totalProductDiscount"
                      name="totalProductDiscount"
                      mode={'currency'}
                      currency="USD"
                      minFractionDigits={2}
                      value={aggregate?.totalProductDiscount || '0'}
                      version={2}
                      className="w-full"
                      disabled={true}
                    />
                    <label htmlFor="totalProductDiscount">Total Discount</label>
                  </span>
                </div>
                <div className="w-full p-1 mt-2">
                  <span className="p-float-label">
                    <PFInputNumber
                      inputId="totalProductTax"
                      name="totalProductTax"
                      mode={'currency'}
                      currency="USD"
                      minFractionDigits={2}
                      value={aggregate?.totalProductTax || '0'}
                      version={2}
                      className="w-full"
                      disabled={true}
                    />
                    <label htmlFor="totalProductTax">Total Tax</label>
                  </span>
                </div>
              </div>
            );
          },
        },
      ],
    },
    {
      label: 'Quote Total',
      badge: `$${parseFloat(aggregate?.totalRetail || 0).toFixed(2)}`,
      expanded: true,
      template: itemRenderer,
      items: [
        {
          template: () => {
            return (
              <div className="col-12 md:col-12 lg:col-12">
                <div className="w-full p-1 mt-2">
                  <span className="p-float-label">
                    <PFInputNumber
                      inputId="totalRetail"
                      name="totalRetail"
                      mode={'currency'}
                      currency="USD"
                      minFractionDigits={2}
                      value={aggregate?.totalRetail || '0'}
                      version={2}
                      className="w-full"
                      disabled={true}
                    />
                    <label htmlFor="totalRetail">Total Retail</label>
                  </span>
                </div>
                <div className="w-full p-1 mt-2">
                  <span className="p-float-label">
                    <PFInputNumber
                      inputId="totalRevenue"
                      name="totalRevenue"
                      mode={'currency'}
                      currency="USD"
                      minFractionDigits={2}
                      value={aggregate?.totalRevenue || '0'}
                      version={2}
                      className="w-full"
                      disabled={true}
                    />
                    <label htmlFor="totalRevenue">Total Revenue</label>
                  </span>
                </div>

                <div className="w-full p-1 ">
                  <span className="p-float-label">
                    <PFInputNumber
                      inputId="totalCost"
                      name="totalCost"
                      mode={'currency'}
                      currency="USD"
                      minFractionDigits={2}
                      value={aggregate?.totalCost || '0'}
                      version={2}
                      className="w-full"
                      disabled={true}
                    />
                    <label htmlFor="totalCost">Total Cost</label>
                  </span>
                </div>
                <div className="w-full p-1 mt-2">
                  <span className="p-float-label">
                    <PFInputNumber
                      inputId="totalDiscount"
                      name="totalDiscount"
                      mode={'currency'}
                      currency="USD"
                      minFractionDigits={2}
                      value={aggregate?.totalDiscount || '0'}
                      version={2}
                      className="w-full"
                      disabled={true}
                    />
                    <label htmlFor="totalDiscount">Total Discount</label>
                  </span>
                </div>
                <div className="w-full p-1 mt-2">
                  <span className="p-float-label">
                    <PFInputNumber
                      inputId="totaltax"
                      name="totaltax"
                      mode={'currency'}
                      currency="USD"
                      minFractionDigits={2}
                      value={aggregate?.totaltax || '0'}
                      version={2}
                      className="w-full"
                      disabled={true}
                    />
                    <label htmlFor="totaltax">Total Tax</label>
                  </span>
                </div>
              </div>
            );
          },
        },
      ],
    },
  ];

  return (
    <div htmlFor="Item Result">
      <div className="grid">
        {mode !== 'create' && (
          <div className="col-12 md:col-12 lg:col-12">
            <div className="w-full px-1">
              <span className="p-float-label">
                <PFInputText
                  id="quoteId"
                  name="quote_id"
                  type="text"
                  value={quoteId}
                  disabled={true}
                />
                <label htmlFor="clientFirstName">Quote ID</label>
              </span>
            </div>
          </div>
        )}

        <div className="col-12 md:col-12 lg:col-12">
          <div className="w-full px-1">
            <span className="p-float-label">
              <PFDropdown
                options={quoteStatus}
                optionLabel="status"
                optionValue="status_id"
                name="statusId"
                filter={true}
                value={selectedQuoteStatus}
                onChange={onQuoteStatus}
                disabled={mode === 'edit' ? false : true}
              />
              <label htmlFor="statusId">
                Quote Status <span style={{ color: 'red' }}>*</span>
              </label>
            </span>
          </div>
        </div>

        {mode !== 'create' && (
          <div className="col-12 md:col-12 lg:col-12">
            <div className="w-full px-1">
              <span className="p-float-label">
                <PFInputText
                  id="createdOn"
                  name="created_on"
                  type="text"
                  value={
                    quoteCreateAt &&
                    moment(quoteCreateAt, 'YYYY-MM-DD hh:mm A').format(
                      'MM-DD-YYYY'
                    )
                  }
                  disabled={true}
                />
                <label htmlFor="clientFirstName">Created On</label>
              </span>
            </div>
          </div>
        )}

        <div className="col-12 md:col-12 lg:col-12">
          <div className="w-full px-1">
            <span className="p-float-label">
              <PFCalendar
                id="validUntil"
                name="validUntil"
                value={validUntil}
                onChange={onValidUntil}
                minDate={modifiedDate(0)}
                disabled={mode === 'view' ? true : false}
              />
              <label htmlFor="clientFirstName">
                Valid Until <span style={{ color: 'red' }}>*</span>
              </label>
            </span>
          </div>
        </div>

        <div className="col-12 md:col-12 lg:col-12">
          <div className="w-full px-1">
            <span className="p-float-label">
              <PFDropdown
                options={mVendorOptions}
                optionLabel="vendor_number"
                optionValue="vendor_sku_id"
                name="mVendorId"
                filter={true}
                value={mVendorId}
                showClear={true}
                onChange={(name, value) => {
                  setMVendorId(value);
                  onChangeMvendorId(value);
                }}
                disabled={mode === 'view' || isForm ? true : false}
              />
              <label htmlFor="mVendorId">Mvendor number</label>
            </span>
          </div>
        </div>

        <div className="col-12 md:col-12 lg:col-12">
          <div className="w-full p-1 ">
            <span className="p-float-label">
              <PFInputNumber
                inputId="retailerPercentage"
                name="retailerPercentage"
                value={retailerPercentage || '0'}
                minFractionDigits={2}
                mode={'decimal'}
                version={2}
                className="w-full"
                suffix="%"
                onChange={onRetailerPercentage}
                onBlur={e => setRetailRate(retailerPercentage || 0)}
                disabled={mode === 'view' || isForm ? true : false}
              />
              <label htmlFor="retailerPercentage">Retailer %</label>
            </span>
          </div>
        </div>

        <div className="col-12 md:col-12 lg:col-12">
          <PanelMenu
            model={pitems}
            multiple
            pt={{
              headercontent: {
                className: 'bg-primary-50',
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ItemResult;
