import axios from 'axios';
import { URL_CONSTANTS } from '../../constants/urlConstants';

export const sendTemplateData = async data => {
  try {
    const response = await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/admin-config/email/template/create`,
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteEmailTemplate = async templateConfigId => {
  try {
    const response = await axios.delete(
      `${URL_CONSTANTS.API.BASE_URL}/admin-config/email/template/${templateConfigId}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createEmailTempConfig = async data => {
  try {
    const payload = {
      template_id: data.template,
      source_id: data.source,
      linkTo: data.linkTo || 'Project',
      project_type_id: data.type,
      project_category_id: data.category,
      internal_status_id: data.status,
      source_status_id: data.sourceStatus,
      coverage_by: data.coverageBy || '',
      workroom_id: data.workroom,
      district_id: data.district,
      store_id: data.store,
    };

    const response = await axios.post(
      `${URL_CONSTANTS.API.BASE_URL}/admin-config/email-template/configuration/create`,
      payload
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateEmailTempConfig = async (data, templateConfigId) => {
  try {
    const payload = {
      template_id: data.template,
      source_id: data.source,
      linkTo: data.linkTo || 'Project',
      project_type_id: data.type,
      project_category_id: data.category,
      internal_status_id: data.status,
      source_status_id: data.sourceStatus,
      coverage_by: data.coverageBy || '',
      workroom_id: data.workroom,
      district_id: data.district,
      store_id: data.store,
    };

    const response = await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/admin-config/email-template/configuration/${templateConfigId}`,
      payload
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteEmailTempConfig = async templateConfigId => {
  try {
    const response = await axios.delete(
      `${URL_CONSTANTS.API.BASE_URL}/admin-config/email-template/configuration/${templateConfigId}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const updateTemplateData = async (data, templateId) => {
  try {
    const response = await axios.patch(
      `${URL_CONSTANTS.API.BASE_URL}/admin-config/email/template/${templateId}`,
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getTemplateData = async (pagination = {}) => {
  try {
    const { limit, offset, query } = pagination;

    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/admin-config/email/template`,
      {
        params: {
          limit,
          offset,
          query,
        },
      }
    );

    return {
      success: true,
      ...response.data,
    };
  } catch (error) {
    return {
      success: false,
      error: error.message,
    };
  }
};

export const getTemplateConfigData = async (pagination = {}) => {
  try {
    const { limit, offset, query } = pagination;

    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/admin-config/email-template/configuration`,
      {
        params: {
          limit,
          offset,
          query,
        },
      }
    );

    return {
      success: true,
      ...response.data,
    };
  } catch (error) {
    return {
      success: false,
      error: error.message,
    };
  }
};

export const getTemplateConfigById = async templateConfigId => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/admin-config/email-template/configuration/${templateConfigId}`
    );
    return {
      success: true,
      ...response?.data,
    };
  } catch (error) {
    return {
      success: false,
      error: error.message,
    };
  }
};

export const getTemplateById = async templateId => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/admin-config/email/template/${templateId}`
    );
    return {
      success: true,
      ...response?.data,
    };
  } catch (error) {
    return {
      success: false,
      error: error.message,
    };
  }
};

export const getSourceOptions = async () => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/system/source-system`
    );
    return response?.data.length ? response?.data : [];
  } catch (error) {
    return {
      success: false,
      error: error.message,
    };
  }
};
