import { Skeleton } from 'primereact/skeleton';
const TableColumnsLoader = (columns, loaderProps) => {
  return Array.from({ length: loaderProps?.rows || 5 }).map(() => {
    const loaderObj = {};
    (columns || []).forEach(column => {
      const style = column.style || { width: '6rem' };
      const skeleton = (
        <Skeleton
          width={style.width || '6rem'}
          pt={{
            root: {
              className: 'min-h-0',
            },
          }}
        />
      );
      const loaderValue = loaderProps?.isValue ? { value: skeleton } : skeleton;
      loaderObj[column?.field || column?.name || column?.id] = loaderValue;
    });
    return loaderObj;
  });
};
export default TableColumnsLoader;
