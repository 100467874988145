import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import { Panel } from 'primereact/panel';
import { Tag } from 'primereact/tag';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { DomHandler } from 'primereact/utils';
import { TabView, TabPanel } from 'primereact/tabview';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { Toast } from 'primereact/toast';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { AutoComplete } from 'primereact/autocomplete';

import './style.css';
import SkeletonLoader from '../Inventory/Loader/skeleton';
import { INVENTORY_ADJUSTMENT_TYPES } from '../../Admin/Widget/constant/constant';

const PFButton = props => {
  return (
    <>
      <Button
        className={`p-inputtext-sm ${props?.className}`}
        label={props?.label || ''}
        icon={props?.icon || 'hidden'}
        onClick={props?.onClick}
        severity={props?.severity || 'primary'}
        disabled={props?.disabled || false}
        size={props?.size || 'small'}
        outlined={props?.outlined || 'outlined'}
        type="button"
      />
    </>
  );
};

const PFSidebar = props => {
  return (
    <>
      <Sidebar
        visible={props?.visible || false}
        onHide={props?.onHide}
        className={`${props?.className}`}
        fullScreen
        icons={props?.icons}
        pt={props?.pt}
        appendTo={props?.appendTo}
      >
        <Panel
          header={props?.header}
          pt={{
            title: 'w-12',
          }}
          className={`${props?.panelClass} inventory-sidebar-header`}
        >
          <>{props?.body && <props.body />}</>
        </Panel>
      </Sidebar>
    </>
  );
};

const PFTag = props => {
  return (
    <>
      <Tag
        value={props?.value || ''}
        severity={props?.severity || null}
        style={props?.styles}
        className={props?.className}
      ></Tag>
    </>
  );
};

const PFTabView = props => {
  const panels = props?.panels || [];
  return (
    <>
      <TabView className={props?.classes}>
        {panels.map((panel, i) => (
          <TabPanel key={i} header={panel?.header}>
            {panel?.body && <panel.body {...props} />}
          </TabPanel>
        ))}
      </TabView>
    </>
  );
};

const PFCalendar = props => {
  const [date, setDate] = useState(null);

  useEffect(() => {
    if (props?.value) {
      setDate(new Date(props?.value));
    }
  }, [props?.value]);

  useEffect(() => {
    if (date && props.onChange) {
      props?.onChange(new Date(date));
    }
  }, [date]);

  return (
    <Calendar
      value={date}
      onChange={e => setDate(e.value)}
      showIcon
      appendTo={props?.appendTo === null ? null : props?.appendTo || 'self'}
      dateFormat={props?.dateFormat || 'mm-dd-yy'}
      className={`w-full p-inputtext-sm ${props?.className}`}
    />
  );
};

const PFDropdown = props => {
  const [floatLabel, setFloatLabel] = useState(true);
  const [selectedOption, setSelectedOption] = useState(props?.value || null);
  const [filter, setFilter] = useState(false);
  useEffect(() => {
    if (selectedOption) {
      props?.onChange && props?.onChange(selectedOption);
    }
  }, [selectedOption]);

  useEffect(() => {
    setFilter(props?.filter);
  }, [props?.filter]);
  useEffect(() => {
    props?.floatLabel === false && setFloatLabel(props?.floatLabel);
  }, [props?.floatLabel]);

  return (
    <>
      <span
        className={`${floatLabel ? 'p-float-label' : ''}`}
        style={{ width: '100% !important' }}
      >
        <Dropdown
          value={selectedOption || {}}
          onChange={e => setSelectedOption(e.value)}
          options={props?.options || []}
          optionLabel={props?.optionLabel || ''}
          optionValue={props?.optionValue || ''}
          placeholder={props?.placeholder || ''}
          filter={filter}
          className={`w-full p-inputtext-sm ${props?.className}`}
          disabled={props?.disabled || false}
          appendTo={props?.appendTo === null ? null : props?.appendTo || null}
          style={{ zIndex: '9999999' }}
        />
        <label htmlFor={props?.label || ''}>{props?.label || ''}</label>
      </span>
    </>
  );
};

const PFTextBox = props => {
  const [value, setValue] = useState(props?.value || '');

  useEffect(() => {
    props?.onChange && props?.onChange(value);
  }, [value]);

  return (
    <>
      <span className="p-float-label">
        <InputText
          value={value}
          onChange={e => setValue(e.target.value)}
          readOnly={props?.readOnly || false}
          className={`w-full p-inputtext-sm ${props?.className}`}
          maxLength={props?.maxLength}
          disabled={props?.disabled || false}
        />
        <label htmlFor={props?.label || ''}>{props?.label || ''}</label>
      </span>
    </>
  );
};

const PFCheckbox = props => {
  const [value, setValue] = useState(props?.value || false);

  useEffect(() => {
    props?.onChange && props?.onChange(value);
  }, [value]);

  return (
    <>
      <span className="p-float-label flex align-items-center">
        <Checkbox
          onChange={e => setValue(e.target.checked)}
          checked={value}
          className={`${props?.className}`}
        ></Checkbox>
        <span className="ml-2">{props?.label}</span>
      </span>
    </>
  );
};

const PFNumberBox = props => {
  const [value, setValue] = useState(props?.value || null);

  useEffect(() => {
    props?.onChange && props?.onChange(value);
  }, [value]);

  return (
    <>
      <span className="p-float-label">
        <InputNumber
          min={0}
          value={value}
          onChange={e => setValue(e.value)}
          readOnly={props?.readOnly || false}
          className={`w-full  ${props?.className}`}
          useGrouping={props?.useGrouping || false}
          inputId="currency-us"
          mode={props?.mode || 'decimal'}
          currency="USD"
          minFractionDigits={props?.minFractionDigits || 0}
          maxFractionDigits={props?.maxFractionDigits || 0}
          maxLength={props?.maxLength || null}
          inputStyle={{ width: '100%' }}
          pt={{
            input: {
              root: { autoComplete: 'off' },
            },
          }}
        />
        <label htmlFor={props?.label || ''}>{props?.label || ''}</label>
      </span>
    </>
  );
};

const PFTextAreaBox = props => {
  const [value, setValue] = useState(props?.value || null);

  useEffect(() => {
    props?.onChange && props?.onChange(value);
  }, [value]);

  return (
    <>
      <span className="p-float-label">
        <InputTextarea
          value={value}
          onChange={e => setValue(e.value)}
          rows={props?.rows || 5}
          cols={props?.cols || 30}
          readOnly={props?.readOnly || false}
          className={`w-full surface-400 ${props?.className}`}
        />
        <label htmlFor={props?.label || ''}>{props?.label || ''}</label>
      </span>
    </>
  );
};

const PFConfirmDialog = props => {
  const [visible, setVisible] = useState(false);
  const toast = useRef(null);

  const accept = () => {
    props.confirmationAction();
  };

  const reject = () => {};

  return (
    <>
      <Toast ref={toast} />
      <ConfirmDialog
        visible={visible}
        onHide={() => setVisible(false)}
        message="Are you sure you want to delete it?"
        header="Confirmation"
        icon="pi pi-exclamation-triangle text-lg"
        accept={accept}
        reject={reject}
        acceptClassName="p-button-primary p-button-sm"
        rejectClassName="p-button-sm p-button-outlined"
        appendTo="self"
        style={{ zIndex: '9999999' }}
        pt={{ headerTitle: { className: 'text-left' } }}
      />
      <Button
        className={`border-none p-button-primary p-row-editor-init`}
        icon="pi pi-trash"
        text
        type="button"
        outlined
        onClick={() => setVisible(true)}
        style={{ marginTop: '-7px' }}
      />
    </>
  );
};

const DomHandlerCall = className => {
  const DomHandlerElement = DomHandler.find(document.body, `.${className}`);
  return { counts: DomHandlerElement.length, handler: DomHandlerElement };
};

const PFDataTableEdit = props => {
  const [editingRows, setEditingRows] = useState({});
  const [loading, setLoading] = useState(false);
  const [editRow, setEditRow] = useState(false);

  const onEdit = (e, rowData, options) => {
    editingRows[rowData.index] = true;
    setEditRow(true);

    setEditingRows(editingRows);
    return props?.data?.dataTableConfig?.onEdit(e, options);
  };

  const onSave = (e, rowData, options) => {
    delete editingRows[rowData.index];
    setEditRow(false);
    setEditingRows({});
    return props?.data?.dataTableConfig?.onSave(e, options);
  };

  const onDelete = (e, rowData, options) => {
    delete editingRows[rowData.index];
    setEditRow(false);
    setEditingRows({});
    return props?.data?.dataTableConfig?.onDelete(e, rowData, options);
  };

  const onCancel = (e, rowData, options) => {
    delete editingRows[rowData.index];
    setEditRow(false);
    setEditingRows({});
    return props?.data?.dataTableConfig?.onCancel(e, rowData, options);
  };

  useEffect(() => {
    setLoading(props?.loading || false);
  }, [props.loading]);

  useEffect(() => {
    if (props?.transferError) {
      props?.editDataTableRow('init-index-' + props?.transferError?.editId);
      props?.setTransferError(null);
    }
  }, [props?.transferError]);

  const onPageChange = e => {
    if (!props?.isDisabled) {
      props?.setRows && props?.setRows(e.rows);
      props?.setFirst && props?.setFirst(e.first);
    }
  };

  return (
    <>
      {loading ? (
        <SkeletonLoader />
      ) : (
        <DataTable
          value={props?.data?.listItems}
          editMode={props?.data?.dataTableConfig?.editMode}
          dataKey={props?.data?.dataTableConfig?.dataKey}
          onRowEditComplete={props?.data?.dataTableConfig?.onRowEditComplete}
          paginator={props.paginator || false}
          rows={props.rows || 10}
          stripedRows
          rowsPerPageOptions={[10, 20, 50, 100]}
          loading={loading}
          scrollable
          first={props?.first || 0}
          onPage={onPageChange}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        >
          <Column
            rowEditor
            style={{ verticalAlign: 'top' }}
            headerStyle={{ width: '5%', minWidth: '8rem' }}
            bodyStyle={{ textAlign: 'right' }}
            body={(rowData, options) => (
              <>
                {editingRows[rowData?.index] ? (
                  <>
                    <Button
                      className={`border-none p-row-editor-save text-white`}
                      icon="pi pi-check"
                      text
                      type="button"
                      outlined
                      onClick={e => onSave(e, rowData, options)}
                    />
                    {editingRows[rowData?.index] && rowData?.newRow ? (
                      <>
                        <Button
                          className={`border-none p-button-primary p-row-editor-init init-index-${rowData?.index}`}
                          icon="pi pi-trash"
                          text
                          type="button"
                          outlined
                          onClick={e => onDelete(e, rowData, options)}
                        />
                      </>
                    ) : (
                      <Button
                        className={`border-none p-button-primary  p-row-editor-init init-index-${rowData?.index}`}
                        icon="pi pi-times"
                        text
                        type="button"
                        outlined
                        onClick={e => onCancel(e, rowData, options)}
                        severity="primary"
                      />
                    )}
                  </>
                ) : (
                  <>
                    {props.isInventoryAdjustment &&
                      rowData?.adjustmentType ===
                        INVENTORY_ADJUSTMENT_TYPES.ALLOCATED && (
                        <Button
                          className={`border-none p-button-primary p-row-editor-init init-index-${rowData?.index}`}
                          icon="pi pi-refresh"
                          text
                          outlined
                          type="button"
                          tooltip="Dispatch"
                          onClick={e =>
                            props?.handleInventoryDispatch(e, rowData, options)
                          }
                          severity="primary"
                        />
                      )}
                    {props.isInventoryAdjustment &&
                      rowData?.adjustmentType ===
                        INVENTORY_ADJUSTMENT_TYPES.DISPATCHED && (
                        <Button
                          className={`border-none p-button-primary p-row-editor-init init-index-${rowData?.index}`}
                          icon="pi pi-undo"
                          text
                          outlined
                          type="button"
                          tooltip="Return to Inventory"
                          onClick={e =>
                            props?.handleInventoryUndoDispatch(
                              e,
                              rowData,
                              options
                            )
                          }
                          severity="primary"
                        />
                      )}
                    {props.isInventoryAdjustment &&
                      rowData?.adjustmentType ===
                        INVENTORY_ADJUSTMENT_TYPES.ON_ORDER && (
                        <Button
                          className={`border-none p-button-primary p-row-editor-init init-index-${rowData?.index}`}
                          icon="pi pi-undo"
                          text
                          outlined
                          type="button"
                          tooltip="Receive"
                          onClick={e =>
                            props?.handleInventoryOnOrderDispatch(
                              e,
                              rowData,
                              options
                            )
                          }
                          severity="primary"
                        />
                      )}

                    <Button
                      className={`border-none p-button-primary p-row-editor-init init-index-${rowData?.index}`}
                      icon="pi pi-pencil"
                      onClick={e => onEdit(e, rowData, options)}
                      severity="primary"
                      text
                      outlined
                      type="button"
                      disabled={editRow}
                    />
                    <PFConfirmDialog
                      confirmationAction={e => onDelete(e, rowData, options)}
                    />
                  </>
                )}
              </>
            )}
          ></Column>
          {props?.data?.columns?.map((col, index) => (
            <Column
              field={col?.field}
              header={col?.header}
              body={col?.body}
              editor={options => (col?.editor ? col?.editor(options) : {})}
              style={{ ...col?.style, verticalAlign: 'top' }}
              className={col?.className || ''}
              key={index}
            />
          ))}
        </DataTable>
      )}
    </>
  );
};
const PFDataTable = ({ columns, data, paginator, rows, ...rest }) => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(rest?.loading);
  }, [rest?.loading]);
  return (
    <div className={`${rest?.wrapperClass || ''}`}>
      <DataTable
        value={data}
        size={'small'}
        paginator={paginator}
        rows={rows || 8}
        stripedRows
        tableStyle={{ minWidth: '50rem' }}
        loading={loading}
      >
        {columns.map((item, index) => (
          <Column
            key={index}
            field={item.field}
            header={item.header}
            style={{ width: '200px', height: '60px', verticalAlign: 'middle' }}
          ></Column>
        ))}
      </DataTable>
    </div>
  );
};

const PFAutoComplete = props => {
  const [value, setValue] = useState(props?.value);
  const [items, setItems] = useState(props?.suggestions);

  const search = event => {
    setItems(props?.suggestions?.map(item => item));
  };

  useEffect(() => {
    props?.onChange && props?.onChange(value);
  }, [value]);

  return (
    <div className="card flex justify-content-center">
      <AutoComplete
        value={value}
        suggestions={items}
        completeMethod={search}
        onChange={e => setValue(e.value)}
      />
    </div>
  );
};

export {
  PFButton,
  PFTag,
  PFDataTableEdit,
  PFTabView,
  PFCalendar,
  PFDropdown,
  PFTextBox,
  DomHandlerCall,
  PFNumberBox,
  PFTextAreaBox,
  PFCheckbox,
  PFSidebar,
  PFConfirmDialog,
  PFDataTable,
  PFAutoComplete,
};
