import axios from 'axios';
import dayjs from 'dayjs';
import { Message } from 'primereact/message';

import { URL_CONSTANTS } from '../constants/urlConstants';

const AvailableSlotsComponent = ({ header, list }) => {
  return (
    <>
      <div className="flex w-full font-bold">{header}</div>
      <ul className="w-full pt-0 m-0">
        {list.map((slot, index) => {
          const date = dayjs(slot?.date).format('YYYY-MM-DD');
          const startTime = dayjs(`${date} ${slot?.start_time}`).format(
            'hh:mm A'
          );
          const endTime = dayjs(`${date} ${slot?.end_time}`).format('hh:mm A');
          return (
            <li key={`slot_${index}`}>
              {dayjs(slot?.date).format('MM-DD-YYYY')} {slot?.day} - {startTime}{' '}
              - {endTime}
            </li>
          );
        })}
      </ul>
    </>
  );
};
const validateSlotSchedule = async params => {
  return await axios.get(
    `${URL_CONSTANTS.API.BASE_URL}/scheduler/calendar-view/validate-slot`,
    { params }
  );
};
export const validateReschedule = async (
  args,
  setDialogProps = () => null,
  setIsOpen = () => null,
  cb = () => null
) => {
  try {
    if (
      dayjs(args.projectStartDateTime).format('YYYY-MM-DD') !==
      dayjs(args.projectEndDateTime).format('YYYY-MM-DD')
    ) {
      cb(true);
      return;
    }
    const result = await validateSlotSchedule({
      installerId: args.installerId,
      projectId: args.projectId,
      projectStartDateTime: dayjs(args.projectStartDateTime).format(
        'YYYY-MM-DD HH:mm:00'
      ),
      projectEndDateTime: dayjs(args.projectEndDateTime).format(
        'YYYY-MM-DD HH:mm:00'
      ),
    });
    if (
      result?.data &&
      Array.isArray(result?.data?.slots) &&
      result.data.slots.length
    ) {
      const isDayOff = result.data?.dayoff;
      let userSlotAvailableFlag = 0,
        userWorkScheduleSlotAvailableFlag = 0,
        projectNumber = null,
        poNumber = null;
      const fallingSlots = [],
        fallingWorkScheduleSlots = [];
      const partialFallingSlots = [],
        partialWorkScheduleFallingSlots = [],
        workScheduleTypeList = [];
      for (const element of result.data.slots) {
        const { availability_flag, type, ...rest } = element;
        if (!projectNumber && element?.project_number)
          projectNumber = element.project_number;
        if (!poNumber && element?.po_number) poNumber = element.po_number;
        if (!isDayOff) {
          if (
            'user_slot' === type &&
            rest.day &&
            rest.start_time &&
            rest.end_time
          ) {
            if (availability_flag === 1) {
              userSlotAvailableFlag = 1;
              break;
            } else if (userSlotAvailableFlag !== 1) {
              userSlotAvailableFlag = 2;
              if (availability_flag === 0) {
                fallingSlots.push({
                  ...rest,
                  date: args.projectStartDateTime,
                });
              } else {
                partialFallingSlots.push({
                  ...rest,
                  date: args.projectStartDateTime,
                });
              }
            }
          } else if (
            userSlotAvailableFlag === 0 &&
            'user_work_schedule' === type &&
            rest.day &&
            rest.start_time &&
            rest.end_time
          ) {
            workScheduleTypeList.push(element);
          }
        } else break;
      }
      for (const element of workScheduleTypeList) {
        const { availability_flag, ...rest } = element;
        if (availability_flag === 1) {
          userWorkScheduleSlotAvailableFlag = 1;
          break;
        } else if (userWorkScheduleSlotAvailableFlag !== 1) {
          userWorkScheduleSlotAvailableFlag = 2;
          if (availability_flag === 0) {
            fallingWorkScheduleSlots.push({
              ...rest,
              date: args.projectStartDateTime,
            });
          } else {
            partialWorkScheduleFallingSlots.push({
              ...rest,
              date: args.projectStartDateTime,
            });
          }
        }
      }
      if (
        isDayOff ||
        2 === userSlotAvailableFlag ||
        2 === userWorkScheduleSlotAvailableFlag
      ) {
        setIsOpen(false);
        setDialogProps({
          visible: true,
          props: {
            message: (
              <div className="flex flex-column gap-4 height-28rem max-h-28rem overflow-y-auto p-1">
                <Message
                  className="w-full"
                  severity="warn"
                  text={`Selected Technician is not ${isDayOff ? 'working on this day' : 'available for this slot'}. Do you still want to assign the job?`}
                />
                {projectNumber && (
                  <div className="flex w-full">
                    <div className="flex-1 w-10rem">Project #</div>
                    <div className="flex-1 mx-4">{projectNumber}</div>
                  </div>
                )}
                {poNumber && (
                  <div className="flex w-full">
                    <div className="flex-1 w-10rem">PO #</div>
                    <div className="flex-1 mx-4">{poNumber}</div>
                  </div>
                )}
                <div className="flex w-full">
                  <div className="flex-1 w-10rem">Selected Technician Name</div>
                  <div className="flex-1 mx-4">{args?.technician_name}</div>
                </div>
                <div className="flex w-full">
                  <div className="flex-1 w-10rem">Selected Start Date Time</div>
                  <div className="flex-1 mx-4">
                    {dayjs(args?.projectStartDateTime).format(
                      'MM-DD-YYYY hh:mm A'
                    )}
                  </div>
                </div>
                <div className="flex w-full">
                  <div className="flex-1 w-10rem">Selected End Date Time</div>
                  <div className="flex-1 mx-4">
                    {dayjs(args?.projectEndDateTime).format(
                      'MM-DD-YYYY hh:mm A'
                    )}
                  </div>
                </div>
                {userSlotAvailableFlag ? (
                  <>
                    {fallingSlots.length > 0 && (
                      <AvailableSlotsComponent
                        header="Available Slots:"
                        list={fallingSlots}
                      />
                    )}
                    {partialFallingSlots.length > 0 && (
                      <AvailableSlotsComponent
                        header="Partial Available Slots:"
                        list={partialFallingSlots}
                      />
                    )}
                  </>
                ) : (
                  <>
                    {fallingWorkScheduleSlots.length > 0 && (
                      <AvailableSlotsComponent
                        header="Available Slots:"
                        list={fallingWorkScheduleSlots}
                      />
                    )}
                    {partialWorkScheduleFallingSlots.length > 0 && (
                      <AvailableSlotsComponent
                        header="Partial Available Slots:"
                        list={partialWorkScheduleFallingSlots}
                      />
                    )}
                  </>
                )}
              </div>
            ),
            header: 'Warning',
            // icon: 'pi pi-info-circle',
            accept: () => {
              cb(true);
              setDialogProps({
                visible: false,
                props: {},
              });
            },
            rejectLabel: 'Cancel',
            acceptLabel: 'Continue',
            onHide: () => {
              setDialogProps({
                visible: false,
                props: {},
              });
              setIsOpen(true);
              cb(false);
            },
          },
        });
      } else {
        cb(true);
      }
    } else {
      cb(true);
    }
  } catch (error) {
    console.error(error, '...error validateReschedule');
    cb(true);
  }
};
