import dayjs from 'dayjs';

import { isStartTimeLessThanEndTime } from './Helpers';

export const validateNumber = input => {
  if (!isNaN(input)) {
    return true;
  } else {
    return false;
  }
};
export const slotStartAndEndTimeValidation = (formik, slots, currentSlots) => {
  // to check start time is not selected
  if (
    Array.isArray(slots) &&
    slots.length &&
    formik.values.start_time &&
    formik.values.end_time
  ) {
    let stLessThanAll = true,
      etGreaterThanAll = true,
      isCompared = false;
    const st = dayjs(formik.values.start_time).format('HH:mm:00');
    const et = dayjs(formik.values.end_time).format('HH:mm:00');
    for (const eachSlot of slots) {
      if (currentSlots?.slot_id != eachSlot?.slot_id) {
        isCompared = true;
        if (isStartTimeLessThanEndTime(eachSlot.start_time, st)) {
          stLessThanAll = false;
          break;
        }
        if (isStartTimeLessThanEndTime(et, eachSlot.end_time)) {
          etGreaterThanAll = false;
          break;
        }
      }
    }
    if (isCompared && stLessThanAll && etGreaterThanAll) {
      formik.setErrors({
        exist: 'Slot time already exist',
      });
      return false;
    }
  }
  return true;
};
export const validateSlotForm = (formik, slots, workHours, currentSlots) => {
  for (const type of ['slot_name', 'start_time', 'end_time']) {
    if ('slot_name' === type) {
      if (
        !formik.values[type] ||
        (typeof formik.values[type] === 'string' && !formik.values[type].trim())
      ) {
        formik.setErrors({
          slot_name: 'Required',
        });
        return false;
      }
    } else {
      if (!formik.values[type]) {
        formik.setErrors({
          [type]: 'Required',
        });
        return false;
      } else if (
        new Date(formik.values[type]).toDateString() === 'Invalid Date'
      ) {
        formik.setErrors({
          [type]: 'Invalid Date',
        });
        return false;
      } else if ('start_time' === type) {
        const formattedStartTime = dayjs(formik.values.start_time).format(
          'HH:mm:00'
        );
        if (Array.isArray(workHours) && workHours.length) {
          const time = workHours.find(
            eachDay => eachDay.day === currentSlots?.day
          );
          if (time && time.start_time) {
            if (
              isStartTimeLessThanEndTime(formattedStartTime, time.start_time)
            ) {
              formik.setErrors({
                start_time: `Start Time cannot be less than the Start Time of ${currentSlots?.day} Work Hour`,
              });
              return false;
            }
            if (time && time.end_time) {
              if (
                isStartTimeLessThanEndTime(time.end_time, formattedStartTime)
              ) {
                formik.setErrors({
                  start_time: `Start Time cannot be greater than the End Time of ${currentSlots?.day} Work Hour`,
                });
                return false;
              }
            }
            if (
              time?.break_start_time &&
              time?.break_end_time &&
              (isStartTimeLessThanEndTime(
                time.break_start_time,
                formattedStartTime
              ) ||
                time.break_start_time === formattedStartTime) &&
              (isStartTimeLessThanEndTime(
                formattedStartTime,
                time.break_end_time
              ) ||
                formattedStartTime === time.break_end_time)
            ) {
              formik.setErrors({
                start_time: `Start Time cannot lie between Break Time of ${currentSlots?.day} Work Hour`,
              });
              return false;
            }
          }
        }
        if (formik?.values?.end_time) {
          const et = dayjs(formik?.values?.end_time).format('HH:mm:00');
          if (isStartTimeLessThanEndTime(et, formattedStartTime)) {
            formik.setErrors({
              start_time: 'Start Time must be less than End Time',
            });
            return false;
          }
        }
        if (Array.isArray(slots) && slots.length) {
          for (const eachSlot of slots) {
            // when selected start time comes between existing slot time
            if (
              currentSlots?.slot_id !== eachSlot?.slot_id &&
              (eachSlot.start_time === formattedStartTime ||
                isStartTimeLessThanEndTime(
                  eachSlot.start_time,
                  formattedStartTime
                )) &&
              (formattedStartTime === eachSlot.end_time ||
                isStartTimeLessThanEndTime(
                  formattedStartTime,
                  eachSlot.end_time
                ))
            ) {
              formik.setErrors({
                start_time: `Start Time is coming between existing slot time of ${eachSlot.slot_name}`,
              });
              return false;
            }
          }
        }
      } else if ('end_time' === type) {
        const formattedEndTime = dayjs(formik.values.end_time).format(
          'HH:mm:00'
        );
        if (Array.isArray(workHours) && workHours.length) {
          const time = workHours.find(
            eachDay => eachDay.day === currentSlots?.day
          );
          if (time && time.end_time) {
            if (isStartTimeLessThanEndTime(time.end_time, formattedEndTime)) {
              formik.setErrors({
                end_time: `End Time cannot be greater than the End Time of ${currentSlots?.day} Work Hour`,
              });
              return false;
            }
            if (time && time.start_time) {
              if (
                isStartTimeLessThanEndTime(formattedEndTime, time.start_time)
              ) {
                formik.setErrors({
                  end_time: `End Time cannot be less than the Start Time of ${currentSlots?.day} work hour`,
                });
                return false;
              }
            }
            if (
              time?.break_start_time &&
              time?.break_end_time &&
              (isStartTimeLessThanEndTime(
                time.break_start_time,
                formattedEndTime
              ) ||
                time.break_start_time === formattedEndTime) &&
              (isStartTimeLessThanEndTime(
                formattedEndTime,
                time.break_end_time
              ) ||
                formattedEndTime === time.break_end_time)
            ) {
              formik.setErrors({
                end_time: `End Time cannot lie between Break Time of ${currentSlots?.day} Work Hour`,
              });
              return false;
            }
          }
        }
        if (formik?.values?.start_time) {
          const st = dayjs(formik?.values?.start_time).format('HH:mm:00');
          if (isStartTimeLessThanEndTime(formattedEndTime, st)) {
            formik.setErrors({
              end_time: 'End Time must be greater than Start Time',
            });
            return false;
          }
        }
        if (Array.isArray(slots) && slots.length) {
          for (const eachSlot of slots) {
            // when selected end time comes between existing slot time
            if (
              currentSlots?.slot_id !== eachSlot?.slot_id &&
              (eachSlot.start_time === formattedEndTime ||
                isStartTimeLessThanEndTime(
                  eachSlot.start_time,
                  formattedEndTime
                )) &&
              (formattedEndTime === eachSlot.end_time ||
                isStartTimeLessThanEndTime(formattedEndTime, eachSlot.end_time))
            ) {
              formik.setErrors({
                end_time: `End Time is coming between existing slot time of ${eachSlot.slot_name}`,
              });
              return false;
            }
          }
        }
      }
    }
  }
  return slotStartAndEndTimeValidation(formik, slots, currentSlots);
};
