import React, { useRef, useEffect } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Menu } from 'primereact/menu';
import { Checkbox } from 'primereact/checkbox';
import { Tooltip } from 'primereact/tooltip';
import { Tag } from 'primereact/tag';
import axios from 'axios';

import PFButton from '../../../shared/PFPrime/PFButton';
import { CONFIG } from '../../../../constants';
import PFManuButton from '../../../shared/PFPrime/PFManuButton';
import apiService from '../../../../services/api.service';
import { downloadQuote, previewQuote } from '../../Quotes/helpers/Quotes';
const items = [
  {
    label: 'Options',
    items: [
      {
        label: 'Refresh',
        icon: 'pi pi-refresh',
      },
      {
        label: 'Export',
        icon: 'pi pi-upload',
      },
    ],
  },
];

export const createExternalProject = async (
  data = null,
  toast,
  gridList,
  setLoading
) => {
  const result = apiService.postWithDiffBaseUrl(
    `/api/measure-square/create/project`,
    data,
    CONFIG?.API_CRM_URL
  );
  result
    ?.then(res => {
      toast.current.show({
        severity: 'success',
        summary: 'success',
        detail: res?.message || '',
      });

      gridList ? gridList() : setLoading(false);

      return result;
    })
    .catch(err => {
      setLoading(false);
      console.error(err);
    });
};

export const cloneProject = async (
  data = null,
  toast,
  gridList,
  setLoading
) => {
  const result = apiService.postWithDiffBaseUrl(
    `/api/measure-square/clone/project`,
    data,
    CONFIG?.API_CRM_URL
  );

  result
    ?.then(res => {
      toast.current.show({
        severity: 'success',
        summary: 'success',
        detail: res?.message || '',
      });

      gridList ? gridList() : setLoading(false);

      return result;
    })
    .catch(err => {
      setLoading(false);
      console.error(err);
    });
};

export const sendProject = async (data = null, toast, gridList, setLoading) => {
  const result = apiService.postWithDiffBaseUrl(
    `/api/measure-square/send/project`,
    data,
    CONFIG?.API_CRM_URL
  );

  result
    ?.then(res => {
      toast.current.show({
        severity: 'success',
        summary: 'success',
        detail: res?.message || '',
      });

      gridList ? gridList() : setLoading(false);

      return result;
    })
    .catch(err => {
      setLoading(false);
      console.error(err);
    });
};

export const pullProjectItems = async (
  data = null,
  toast,
  gridList = null,
  setLoading
) => {
  const result = apiService.postWithDiffBaseUrl(
    `/api/measure-square/pull/items`,
    data,
    CONFIG?.API_CRM_URL
  );

  result
    ?.then(res => {
      toast.current.show({
        severity: 'success',
        summary: 'success',
        detail: res?.message || '',
      });
      gridList ? gridList() : setLoading(false);
      return result;
    })
    .catch(err => {
      setLoading(false);
      console.error(err);
    });
};

export const pullProjectDocuments = async (
  data = null,
  toast,
  gridList = null,
  setLoading
) => {
  const result = apiService.postWithDiffBaseUrl(
    `/api/measure-square/pull/documents`,
    data,
    CONFIG?.API_CRM_URL
  );
  result
    ?.then(res => {
      toast.current.show({
        severity: 'success',
        summary: 'success',
        detail: res?.message || '',
      });
      gridList ? gridList() : setLoading(false);

      return result;
    })
    .catch(err => {
      setLoading(false);
      console.error(err);
    });
};

export const markAsFinal = async (
  data = null,
  toast,
  gridList = null,
  setLoading
) => {
  const result = apiService.postWithDiffBaseUrl(
    `/api/measure-square/mark-as-final`,
    data,
    CONFIG?.API_CRM_URL
  );
  result
    ?.then(res => {
      toast.current.show({
        severity: 'success',
        summary: 'success',
        detail: res?.message || '',
      });
      gridList ? gridList() : setLoading(false);

      return result;
    })
    .catch(err => {
      setLoading(false);
      console.error(err);
    });
};

export const quoteGridColumns = (
  toast,
  setLoading,
  gridList,
  isGlobalLevel = false
) => {
  let main = [
    {
      field: 'actions',
      header: 'Actions',
      sortable: false,
      filter: false,
      body: (rowData, { rowIndex, option }) => {
        const history = useHistory();
        const { PFManuTemplate, items } = PFManuButton();
        const menuLeft = useRef(null);

        useEffect(() => {
          items([
            {
              label: 'View',
              icon: 'pi pi-eye',
              command: () => {
                history.push(
                  `/crm/${rowData?.customerId}/${rowData?.opportunityId}/quote/view/${rowData?.quoteId}`
                );
              },
            },
            {
              label: 'Clone',
              icon: 'pi pi-clone',
              command: () => {
                setLoading(true);
                const data = {
                  quote_id: rowData?.quoteId,
                  oppotunity_id: rowData?.opportunityId,
                  client_id: localStorage.getItem('client_id') || null,
                  ms_project_id: rowData?.ms_project_id || '',
                  project_id: rowData?.measureSquareProjectId || null,
                };
                const copyProject = cloneProject(
                  data,
                  toast,
                  gridList,
                  setLoading
                );
              },
            },
            {
              label: 'Pull Line Items',
              icon: 'pi pi-arrow-down',
              command: () => {
                setLoading(true);
                const data = {
                  quote_id: rowData?.quoteId,
                  oppotunity_id: rowData?.opportunityId,
                  client_id: localStorage.getItem('client_id') || null,
                  ms_project_id: rowData?.ms_project_id || '',
                  project_id: rowData?.measureSquareProjectId || null,
                };
                const projectItems = pullProjectItems(
                  data,
                  toast,
                  gridList,
                  setLoading
                );
              },
              disabled: rowData?.pull_line_items ? true : false,
            },
            {
              label: 'Pull Cut Sheets',
              icon: 'pi pi-arrow-down',
              command: () => {
                setLoading(true);
                const data = {
                  quote_id: rowData?.quoteId,
                  oppotunity_id: rowData?.opportunityId,
                  client_id: localStorage.getItem('client_id') || null,
                  ms_project_id: rowData?.ms_project_id || '',
                  project_id: rowData?.measureSquareProjectId || null,
                };

                const projectDocuments = pullProjectDocuments(
                  data,
                  toast,
                  gridList,
                  setLoading
                );
              },
              disabled: rowData?.pull_documents ? true : false,
            },

            ...(!rowData?.mark_as_final
              ? [
                  {
                    label: 'Mark as Final',
                    icon: 'pi pi-check-circle',
                    command: () => {
                      setLoading(true);
                      const data = {
                        quoteId: rowData?.quoteId,
                        opportunityId: rowData?.opportunityId,
                        clientId: rowData?.customerId,
                      };

                      const final = markAsFinal(
                        data,
                        toast,
                        gridList,
                        setLoading
                      );
                    },
                  },
                ]
              : []),

            ...(rowData?.mark_as_final
              ? [
                  {
                    label: 'Send to Source',
                    icon: 'pi pi-send',
                    command: () => {
                      const data = {
                        quote_id: rowData?.quoteId,
                        oppotunity_id: rowData?.opportunityId,
                        client_id: localStorage.getItem('client_id') || null,
                        ms_project_id: rowData?.ms_project_id || '',
                        project_id: rowData?.measureSquareProjectId || null,
                      };
                      const sendMSProject = sendProject(
                        data,
                        toast,
                        gridList,
                        setLoading
                      );
                    },
                  },
                ]
              : []),
          ]);
        }, []);

        return (
          <div className="flex">
            {isGlobalLevel || !rowData?.ms_project_id ? (
              <>
                <div className="flex">
                  <PFButton
                    icon="pi pi-eye"
                    outlined
                    text
                    className="text-white border-none p-1"
                    severity="primary"
                    aria-label="View"
                    onClick={() =>
                      history.push(
                        `/crm/${rowData?.customerId}/${rowData.opportunityId}/quote/view/${rowData.quoteId}`
                      )
                    }
                  />
                  <PFButton
                    icon="pi pi-pencil"
                    outlined
                    text
                    className="text-white border-none"
                    severity="primary"
                    aria-label="Edit"
                    onClick={() => {
                      history.push(
                        `/crm/${rowData?.customerId}/${rowData.opportunityId}/quote/edit/${rowData.quoteId}`
                      );
                    }}
                  />

                  <PFButton
                    icon="pi pi-download"
                    outlined
                    text
                    className="text-white border-none"
                    severity="primary"
                    aria-label="Download"
                    onClick={e => {
                      e.target.disabled = true;
                      const { customerId, opportunityId, quoteId } = rowData;
                      const download = downloadQuote(
                        customerId,
                        opportunityId,
                        quoteId
                      );
                      download
                        .then(res => {
                          if (res?.status === 200) {
                            const pdfUrl = res?.data?.data;

                            const link = document.createElement('a');
                            link.href = pdfUrl;
                            link.target = '_blank';
                            link.download = 'your-file.pdf';
                            document.body.appendChild(link);
                            link.click();
                            setTimeout(() => {
                              link.remove();
                            }, 2000);

                            toast.current.show({
                              severity: 'success',
                              summary: 'success',
                              detail: res?.data?.data?.message,
                            });
                            e.target.disabled = false;
                          }
                        })
                        .catch(error => {
                          console.log('error', error);
                        });
                    }}
                  />
                  <PFButton
                    icon="pi pi-envelope"
                    outlined
                    text
                    className="text-white border-none"
                    severity="primary"
                    aria-label="Email"
                    onClick={e => {
                      const { customerId, opportunityId, quoteId } = rowData;

                      const preview = previewQuote(
                        customerId,
                        opportunityId,
                        quoteId
                      );
                      preview
                        .then(res => {
                          if (res.status === 200) {
                            const newWindow = window.open(
                              'quote-preview',
                              '_blank'
                            );
                            newWindow.document.write(res?.data);
                            newWindow.document.close();
                          }
                        })
                        .catch(err => {
                          console.log('err', err);
                        });
                    }}
                  />
                </div>
              </>
            ) : (
              <div className="flex w-max  align-items-center">
                <div className="cursor-pointer">
                  <PFManuTemplate
                    icon="pi pi-ellipsis-v"
                    aria-controls="popup_menu_left"
                    aria-haspopup
                    outlined
                    text
                    rounded
                    className="border-none mr-2"
                  />
                </div>
                {rowData?.mark_as_final && (
                  <div>
                    <Tag value="Final" severity="success"></Tag>
                  </div>
                )}
              </div>
            )}
          </div>
        );
      },
      className: 'w-1',
    },
    {
      field: 'opportunityNumber',
      header: 'Lead#',
      sortable: true,
      filter: false,
      body: data => <div>{data.opportunityNumber}</div>,
      className: 'w-1',
    },
    {
      field: 'quoteNumber',
      header: 'Quote #',
      sortable: true,
      filter: false,
      body: data => <div>{data.quoteNumber}</div>,
      className: 'w-1',
    },
    {
      field: 'createdAt',
      header: 'Created On',
      sortable: true,
      filter: false,
      body: data => {
        return data.createdAt
          ? moment(data.createdAt, 'YYYY-MM-DD hh:mm A').format('MM-DD-YYYY')
          : '';
      },
      className: 'w-1',
    },
    {
      field: 'expiryDate',
      header: 'Valid Until',
      sortable: true,
      filter: false,
      body: data => {
        return data?.expiryDate
          ? moment(data?.expiryDate, 'YYYY-MM-DD').format('MM-DD-YYYY')
          : '';
      },
      className: 'w-1',
    },
    {
      field: 'statusName',
      header: 'Status',
      sortable: true,
      filter: false,
      body: data => (
        <Tag value={data.statusName} severity="info" className="w-max"></Tag>
      ),
      className: 'w-1',
    },
    {
      header: 'Total Cost',
      sortable: true,
      filter: false,
      body: data =>
        `$${parseFloat(data?.lineItems?.subTotal || 0)?.toFixed(2)}`,
      className: 'w-1',
    },
    {
      header: 'Total Discount',
      sortable: true,
      filter: false,
      body: data =>
        `$${parseFloat(data?.lineItems?.discount || 0)?.toFixed(2)}`,
      className: 'w-1',
    },
    {
      header: 'Total Tax',
      sortable: true,
      filter: false,
      body: data =>
        `$${parseFloat(data?.lineItems?.totalTaxAmount || 0)?.toFixed(2)}`,
      className: 'w-1',
    },
    {
      header: 'Total Quote Amount',
      sortable: true,
      filter: false,
      body: data => `$${parseFloat(data?.lineItems?.total || 0)?.toFixed(2)}`,
      className: 'w-1',
    },
  ];

  return main;
};

export const quoteGridData = async (
  customerId,
  opportunityId,
  rows,
  page,
  sortBy,
  orderBy
) => {
  let queryParams = `limit=${rows || 10}`;
  if (page) {
    queryParams = `${queryParams}&page=${page + 1}`;
  }
  if (sortBy) {
    queryParams = `${queryParams}&sortBy=${sortBy}`;
  }
  if (orderBy) {
    queryParams = `${queryParams}&sortOrder=${orderBy == 1 ? 'asc' : 'desc'}`;
  }

  queryParams = `${queryParams}&isMeasureSquare=1`;

  let url = `${CONFIG?.API_CRM_URL}/api/${customerId}/quote/list?${queryParams}`;
  if (opportunityId) {
    url = `${CONFIG?.API_CRM_URL}/api/${customerId}/${opportunityId}/quote/list?${queryParams}`;
  }
  return await axios.get(url);
};
