import React from 'react';
import { Toast } from 'primereact/toast';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { ProgressBar } from 'primereact/progressbar';

import PageHeader from '../../shared/PageHeader/PageHeader';
import { convertToQueryParams, findTechnician } from '../../../utils/Helpers';
import PFButton from '../../shared/PFPrime/PFButton';
import { PayrollType, ReportDateDiff } from '../../../constants';

import SearchCard from './SearchCard';
import SearchResult from './SearchResult';
import {
  exportPayroll,
  generatePayrollReport,
  getPayrollSearchResult,
} from './services/PayrollService';

import './Payroll.css';

const payrollBreadcrumb = () => {
  return [
    {
      text: 'Admin',
    },

    {
      text: `Payroll`,
    },
  ];
};

const exportData = [];

const PayrollContainer = () => {
  const [searchString, setSearchString] = React.useState({
    offset: 0,
  });
  const [reportSearchString, setReportSearchString] = React.useState();
  const [revAppExcSearchObj, setRevAppExcSearchObj] = React.useState({});
  const [searchResult, setSearchResult] = React.useState([]);
  const [currentOffset, setCurrentOffset] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);
  const [refreshDropdowns, setRefreshDropdowns] = React.useState(true);
  const [downloadSearch, setDownloadSearch] = React.useState('');
  const toast = React.useRef(null);
  const [reportExported, setReportExported] = React.useState(false);
  const [first, setFirst] = React.useState(0);
  const [paginationRows, setPaginationRows] = React.useState(2);
  const [paginationPage, setPaginationPage] = React.useState(1);
  const [previousTechnician, setPreviousTechnician] = React.useState('');

  // Helper to fetch the payroll report
  const fetchSearchResult = async (
    offset = '',
    searchObject = '',
    apply = false,
    userId,
    page = 1,
    row,
    prevOffset
  ) => {
    try {
      setIsLoading(true);
      let updatedSearchString = {};
      if (searchObject) {
        updatedSearchString = { ...searchObject };
      } else if (offset) {
        updatedSearchString = { ...searchString, offset: offset };
      } else {
        updatedSearchString = { ...searchString };
      }
      if (apply) {
        // Remove keys from search criteria which is not required
        Object.keys(updatedSearchString).forEach(key => {
          if (PayrollType.includes(key) && !userId && userId === undefined) {
            delete updatedSearchString[key];
          }
        });
      }

      // Remove keys from search criteria which is not required
      Object.keys(updatedSearchString).forEach(key => {
        if (
          updatedSearchString[key] === null ||
          updatedSearchString[key] === undefined
        ) {
          delete updatedSearchString[key];
        }
      });

      let searchStringVal = {};

      if (userId && userId !== undefined) {
        if (findTechnician(updatedSearchString)) {
          searchStringVal = convertToQueryParams({
            ...updatedSearchString,
            offset: prevOffset,
            projectLimit: row && row !== undefined ? row : paginationRows,
            projectPage: page && page !== undefined ? page : paginationPage,
          });
        } else {
          searchStringVal = convertToQueryParams({
            ...updatedSearchString,
            offset: prevOffset,
            projectTechnician: userId,
            projectLimit: row && row !== undefined ? row : paginationRows,
            projectPage: page && page !== undefined ? page : paginationPage,
          });
        }
      } else {
        searchStringVal = convertToQueryParams({
          ...updatedSearchString,
          projectLimit: row && row !== undefined ? row : paginationRows,
          projectPage: page && page !== undefined ? page : paginationPage,
        });
      }

      setDownloadSearch(searchStringVal);
      let response = await getPayrollSearchResult(searchStringVal);
      setSearchResult(response);
    } catch (err) {
      toast.current.show({
        severity: 'error',
        summary: 'Something went wrong',
        life: 2000,
      });
    } finally {
      setIsLoading(false);
    }
  };
  const getPayroll = async value => {
    try {
      setReportExported(true);
      let response = await exportPayroll(value);
      if (response?.fileURL) {
        const payRollFile = response?.fileURL;
        const link = document.createElement('a');
        link.href = payRollFile;
        link.target = '_blank';
        link.download = 'file.xls';
        document.body.appendChild(link);
        link.click();
        setTimeout(() => {
          link.remove();
        }, 2000);
      }
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Something went wrong',
        life: 2000,
      });
    } finally {
      setReportExported(false);
    }
  };
  // Generate report and create report history
  const createReportHistory = async value => {
    try {
      // Remove keys from search criteria which is not required
      Object.keys(reportSearchString).forEach(key => {
        if (
          reportSearchString[key] === null ||
          reportSearchString[key] === undefined
        ) {
          delete reportSearchString[key];
        }
      });
      if (reportSearchString?.filter) {
        Object.keys(reportSearchString?.filter).forEach(key => {
          if (
            reportSearchString?.filter[key] === null ||
            reportSearchString?.filter[key] === undefined
          ) {
            delete reportSearchString?.filter[key];
          }
        });
      }
      let response = await generatePayrollReport(reportSearchString);
      if (response?.data?.report_request_id)
        toast.current.show({
          severity: 'info',
          summary: (
            <div>
              System is generating report. Kindly wait and check on the reports
              tab. Request ID:{' '}
              <Link target="_blank" to={`/admin/export/reports`}>
                {response?.data?.report_request_id}
              </Link>
            </div>
          ),
        });
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Something went wrong',
        life: 2000,
      });
    } finally {
      setReportExported(false);
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="col-12 md:col-6 lg:col-6">
        <PageHeader
          pageTitle={`Payroll`}
          breadCrumbArray={payrollBreadcrumb()}
        />
      </div>
      <div className="flex col-12 md:col-6 lg:col-6 gap-2  justify-content-end pb-3">
        <PFButton
          icon="pi pi-download"
          outlined
          disabled={!searchResult?.data?.length || reportExported}
          onClick={e => {
            if (searchResult?.data?.length) {
              if (
                dayjs(searchString?.dateTo).diff(searchString?.dateFrom, 'd') <=
                ReportDateDiff
              ) {
                getPayroll(downloadSearch);
              } else {
                createReportHistory();
              }
            }
          }}
        />
        <PFButton
          icon="pi pi-refresh"
          disabled={!searchResult?.data?.length}
          onClick={() => {
            fetchSearchResult();
            setRefreshDropdowns(true);
          }}
        />
      </div>
      {reportExported && (
        <div className="grid grid-nogutter w-full">
          <ProgressBar
            mode="indeterminate"
            style={{ width: '100rem', height: '12px' }}
          ></ProgressBar>
        </div>
      )}
      <div className="grid grid-nogutter w-full mt-2">
        <SearchCard
          setSearchString={setSearchString}
          searchString={searchString}
          setReportSearchString={setReportSearchString}
          fetchSearchResult={fetchSearchResult}
          searchResult={searchResult}
          setSearchResult={setSearchResult}
          isLoading={isLoading}
          setRevAppExcSearchObj={setRevAppExcSearchObj}
          refreshDropdowns={refreshDropdowns}
          setRefreshDropdowns={setRefreshDropdowns}
          setDownloadSearch={setDownloadSearch}
          setPaginationPage={setPaginationPage}
          setFirst={setFirst}
          setPreviousTechnician={setPreviousTechnician}
        />
      </div>
      {searchResult?.data !== undefined || currentOffset > 0 ? (
        <div className="grid grid-nogutter w-full mt-3">
          <SearchResult
            searchResult={searchResult}
            isLoading={isLoading}
            setSearchString={setSearchString}
            setReportSearchString={setReportSearchString}
            reportSearchString={reportSearchString}
            searchString={searchString}
            fetchSearchResult={fetchSearchResult}
            toast={toast}
            setIsLoading={setIsLoading}
            setCurrentOffset={setCurrentOffset}
            revAppExcSearchObj={revAppExcSearchObj}
            currentOffset={currentOffset}
            first={first}
            setFirst={setFirst}
            paginationRows={paginationRows}
            setPaginationRows={setPaginationRows}
            paginationPage={paginationPage}
            setPaginationPage={setPaginationPage}
            previousTechnician={previousTechnician}
          />
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default PayrollContainer;
